import React from "react";
import { UPDATE_REPORT_TEMPLETE_PROPS } from "../../redux/actions/types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { local } from "../../lang/local";
import icon_btn from "../../assets/img/icon_btn.png";
import { onSave } from "./ReportTempleteFunctions";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderWorkFSelect } from "../CompainesWorkFields/CompanyWFFunctions";
import { labelStyle } from "../../utils/GeneralStyle";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import { checkHasOneCompany } from "../../utils/GeneralFunctions";

const SecondTab = (props) => {
  const {
    general,
    reportForm,
    companyOptions,
    companyWfOptions,
    createORUpdateReport,
    reportTempleteObj,
    loadCompaniesOptions,
    loadCompaniesWFOptions,
    branchOptions,
    loadBranchesOptions,
  } = props;

  return (
    <CardBody className="trans">
      {checkHasOneCompany() === false ? (
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <span className="label_title" style={labelStyle}>
              {local.Company}
            </span>
            {renderCompanySelect(
              "reportForm.company_id",
              reportForm.company_id,
              UPDATE_REPORT_TEMPLETE_PROPS,
              general,
              local.Company,
              loadCompaniesOptions,
              companyOptions,
              null,
              [
                { p: "reportForm.formvalues.work_field_id", v: null },
                { p: "reportForm.branch_id", v: null },
              ],
              "4"
            )}
          </Col>
        </Row>
      ) : null}

      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className="label_title" style={labelStyle}>
            {local.Branch}
          </span>
          {renderBranchSelect(
            "reportForm.branch_id",
            reportForm.branch_id,
            UPDATE_REPORT_TEMPLETE_PROPS,
            general,
            local.Branch,
            loadBranchesOptions,
            branchOptions,
            reportForm.company_id,
            null,
            [
              {
                p: "pointForm.formvalues.work_field_id",
                v: null,
              },
            ],
            "4",
            reportForm.iscreate
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className="label_title" style={labelStyle}>
            {local.WorkPlaces}
          </span>
          {renderWorkFSelect(
            "reportForm.formvalues.work_field_id",
            reportForm.formvalues.work_field_id,
            UPDATE_REPORT_TEMPLETE_PROPS,
            general,
            local.WorkPlaces,
            loadCompaniesWFOptions,
            companyWfOptions,
            null,
            null,
            reportForm.branch_id,
            "4",
            null
          )}
        </Col>
      </Row>

      <center>
        <button
          type="submit"
          disabled={reportForm.isLoading}
          className="save_btn mt-5"
          onClick={(e) =>
            onSave(e, createORUpdateReport, reportForm, reportTempleteObj, true)
          }
        >
          {reportForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center>
    </CardBody>
  );
};

export default SecondTab;
