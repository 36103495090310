import { getLanguage } from "../lang/local";

export const placeTypeOptions = [
  {
    label: getLanguage() === "en" ? "Clothes" : "ملابس",
    value: 0,
  },
  {
    label: getLanguage() === "en" ? "Supermarket" : "سوبر ماركت",
    value: 1,
  },
  {
    label: getLanguage() === "en" ? "Pharmacy" : "صيدليات",
    value: 2,
  },
  {
    label: getLanguage() === "en" ? "Gold" : "محل مجوهرات",
    value: 3,
  },
  {
    label: getLanguage() === "en" ? "Hospital" : "مستتشفيات",
    value: 4,
  },
  {
    label: getLanguage() === "en" ? "Car care" : "عنايه السيارات",
    value: 5,
  },
  {
    label: getLanguage() === "en" ? "Car Showroom" : "معرض سيارات",
    value: 6,
  },
  {
    label: getLanguage() === "en" ? "Clincs" : "عيادات",
    value: 7,
  },
  {
    label: getLanguage() === "en" ? "Coffe shop" : "كافيه",
    value: 8,
  },
  {
    label: getLanguage() === "en" ? "Resturant" : "مطعم",
    value: 9,
  },
  {
    label: getLanguage() === "en" ? "Book store" : "كتب",
    value: 10,
  },
  {
    label: getLanguage() === "en" ? "kiosk" : "كشك",
    value: 11,
  },
  {
    label: getLanguage() === "en" ? "Hyper" : "هايبر",
    value: 12,
  },
  {
    label: getLanguage() === "en" ? "Cosmtics" : "مستحضرات تجميل",
    value: 13,
  },
  {
    label: getLanguage() === "en" ? "School" : "مدارس",
    value: 14,
  },
  {
    label: getLanguage() === "en" ? "Kids toys" : "محل العاب",
    value: 15,
  },
  {
    label: getLanguage() === "en" ? "Bakery" : "مخابز",
    value: 16,
  },
  {
    label: getLanguage() === "en" ? "Hotels" : "فنادق",
    value: 17,
  },
  {
    label: getLanguage() === "en" ? "Bazar" : "بازار",
    value: 18,
  },
  {
    label: getLanguage() === "en" ? "Mobile Shop" : "متجر للهواتف",
    value: 19,
  },
  {
    label: getLanguage() === "en" ? "Touristic Companies" : "شركات سياحية ",
    value: 20,
  },
  {
    label: getLanguage() === "en" ? "Beauty centers" : " مركز تجميل ",
    value: 21,
  },
  {
    label: getLanguage() === "en" ? "Electric shop" : "  متجر ادوات كهربائية ",
    value: 22,
  },
  {
    label: getLanguage() === "en" ? "Night clubs" : " ملهى ليلى ",
    value: 23,
  },
  {
    label: getLanguage() === "en" ? "Curtains" : " مراتب و مفروشات  ",
    value: 24,
  },
  {
    label: getLanguage() === "en" ? "Attar shop" : " محل عطاره ",
    value: 25,
  },
  {
    label: getLanguage() === "en" ? "Shoes shop" : " محل احذيه  ",
    value: 26,
  },
  {
    label: getLanguage() === "en" ? "Laboratory" : " معامل ",
    value: 27,
  },
  {
    label: getLanguage() === "en" ? "Law firm" : " مكتب محاماة ",
    value: 28,
  },
  {
    label: getLanguage() === "en" ? "Accounting office" : " مكتب محاسبة",
    value: 29,
  },
  {
    label: getLanguage() === "en" ? "Silver shop" : " محل فضيات",
    value: 30,
  },
  {
    label: getLanguage() === "en" ? "Perfume shop" : " محل عطور ",
    value: 31,
  },
  {
    label: getLanguage() === "en" ? "Leather bags" : " جلود (احذيه وشنط)",
    value: 32,
  },
  {
    label: getLanguage() === "en" ? "Drinks market" : "خمور و مشروبات",
    value: 33,
  },
  {
    label: getLanguage() === "en" ? "Spa" : "منتجع صحي",
    value: 34,
  },
  {
    label: getLanguage() === "en" ? "Driving school" : "مركز القيادة",
    value: 35,
  },
  {
    label: getLanguage() === "en" ? "Furniture shop" : "محل اثاث",
    value: 36,
  },
  {
    label: getLanguage() === "en" ? "Gift shop" : "محل هدايا",
    value: 37,
  },
  {
    label: getLanguage() === "en" ? "Pet  shop" : "محل حيوانات أليفه",
    value: 38,
  },
  {
    label: getLanguage() === "en" ? "Computer shop" : "مستلزمات كمبيوتر",
    value: 39,
  },
  {
    label: getLanguage() === "en" ? "AC shop" : "معرض تكيف وتبريد",
    value: 40,
  },
  {
    label:
      getLanguage() === "en"
        ? "Household and plastic shop"
        : "ادوات منزلية و بلاستيكية",
    value: 41,
  },
];
