import React, { Component } from 'react';
import { Card, Col, Row, Spinner, Form } from 'reactstrap';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { UPDATE_STATE_PROPS } from '../../redux/actions/types';
import { checkConnection, renderArraw } from '../../utils/GeneralFunctions';
import { leftlStyle } from '../../utils/GeneralStyle';
import ErrorCard from '../../utils/ErrorCard';
import FlieCreate from '../../utils/FlieCreate';
import FileDataList from '../../utils/FileDataList';
class AddStateFile extends Component {
  componentDidMount() {
    this.props.getStatesForDropDown();
    checkConnection(this.props.history);
  }

  componentWillUnmount() {
    this.unsetData();
  }

  unsetData = () => {
    const { general } = this.props;

    general(
      [
        {
          prop: 'fileOptions',
          value: {
            savedData: null,
            importedData: null,
            isLoading: false,
            errorData: null,
            headerData: null,
          },
        },
      ],
      UPDATE_STATE_PROPS
    );
  };

  onSaveDataFile = (e) => {
    const { saveDataFromFile, fileOptions } = this.props;
    e.preventDefault();
    saveDataFromFile(
      fileOptions.savedData,
      fileOptions.headerData,
      local.Governorate
    );
  };

  render() {
    const { general, history, fileOptions } = this.props;
    return (
      <div className='page-wrapper'>
        <div className='d-flex'>
          <Row style={{ height: '100%', flexGrow: 1 }}>
            <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-4'>
              <Form
                onSubmit={(e) => this.onSaveDataFile}
                style={{ height: '100%' }}
              >
                <Card className='cardCreate ml-2 mt-4 mr-2'>
                  <div className='mt-5 mr-5 mt-3 ml-5'>
                    <Row>
                      <Col>
                        <p style={leftlStyle}>{local.AddState}</p>
                      </Col>
                      {renderArraw(history)}
                    </Row>
                    <br />
                    <Row>
                      <Col>
                        <p className='page_title mt-3' style={leftlStyle}>
                          {local.ImportantNotes}
                        </p>
                      </Col>
                    </Row>
                    <Row className='mt-3'>
                      <Col>
                        <ul style={{ fontSize: '12px' }}>
                          <Row className='mt-2'>
                            <Col>
                              <li style={leftlStyle}>{local.ExcelFile}</li>
                            </Col>
                          </Row>
                          <Row className='mt-2'>
                            <Col>
                              <li style={leftlStyle}>
                                {local.EnglishNameColumn}
                              </li>
                            </Col>
                          </Row>
                          <Row className='mt-2'>
                            <Col>
                              <li style={leftlStyle}>
                                {local.ArabicNameColumn}
                              </li>
                            </Col>
                          </Row>
                        </ul>
                      </Col>
                    </Row>
                    {fileOptions.errorData !== null ? (
                      fileOptions.errorData.length > 0 ? (
                        <Row className='mt-5'>
                          <Col>
                            <ErrorCard errorMessage={fileOptions.errorData} />
                          </Col>
                        </Row>
                      ) : null
                    ) : null}
                    <Row className='mt-5'>
                      <Col xl={2}>
                        <FlieCreate
                          general={general}
                          actionType='state'
                          action={UPDATE_STATE_PROPS}
                        />
                      </Col>
                    </Row>
                    {fileOptions.importedData !== null ? (
                      fileOptions.importedData.length > 0 ? (
                        <Row className='mt-1'>
                          <Col>
                            <FileDataList
                              hasSelect={true}
                              selectOptions={[
                                null,
                                null,
                                // {
                                //   options: stateOptions,
                                //   title: local.Governorate,
                                //   isSingle: true,
                                // },
                              ]}
                              data={fileOptions.importedData}
                              header={fileOptions.headerData}
                              general={general}
                              action={UPDATE_STATE_PROPS}
                              select_indexs={fileOptions.select_indexs}
                              logo_index={4}
                            />
                          </Col>
                        </Row>
                      ) : null
                    ) : null}
                    {fileOptions.savedData !== null ? (
                      <center>
                        <button
                          disabled={fileOptions.isLoading}
                          className='save_btn mt-5'
                          onClick={(e) => this.onSaveDataFile(e)}
                        >
                          {fileOptions.isLoading === true ? (
                            <Spinner type='grow' color='dark' size='sm' />
                          ) : (
                            <>
                              <p style={{ display: 'inline' }}>{local.Save} </p>
                              <img
                                src={icon_btn}
                                width='20'
                                height='20'
                                alt='img'
                              />
                            </>
                          )}
                        </button>
                      </center>
                    ) : null}
                    <br /> <br />
                  </div>
                </Card>
              </Form>
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ StateR }) => {
  return {
    fileOptions: StateR.fileOptions,
    stateOptions: StateR.stateOptions,
  };
};

export default connect(mapStateToProps, actions)(AddStateFile);
