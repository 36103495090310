import React from "react";
import Dialog from "@material-ui/core/Dialog";
import Slide from "@material-ui/core/Slide";
import { Row, Col, Button } from "reactstrap";
import { local } from "../lang/local";
import stay from "../assets/img/stay.png";
import leave from "../assets/img/leave.png";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const AlertModel = (props) => {
  const { modale, general, action, formvalues, unsetForm } = props;

  const onClickStay = () => {
    general([{ prop: "modale.tobeConfirmed", value: false }], action);
  };

  const onClickLeave = () => {
    if (formvalues.id !== undefined) {
      unsetForm(general);
    } else {
      general(
        [
          { prop: "modale.tobeConfirmed", value: false },
          { prop: "modale.isOpen", value: false },
        ],
        action
      );
    }
  };

  return (
    <div style={{ zIndex: "1000" }}>
      <Dialog
        open={modale.tobeConfirmed}
        TransitionComponent={Transition}
        keepMounted
        aria-labelledby="alert-dialog-slide-title"
        aria-describedby="alert-dialog-slide-description"
      >
        <div
          style={{
            paddingTop: "5px",
            paddingBottom: "5px",
            paddingLeft: "80px",
            paddingRight: "80px",
          }}
        >
          <Row className="mt-5 text-center">
            <Col>
              <p style={{ color: "#00BEAC" }}>{local.AlertTitle}</p>
              <p>{local.alertConfirm}</p>
            </Col>
          </Row>
          <hr style={{ background: "#99E5DE", width: "100%", height: "1px" }} />

          <Row className="mt-3">
            <Col>
              <Button onClick={onClickStay} className="alert_btn">
                <Row>
                  <Col>
                    <img src={stay} width="50" height="50" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <p>{local.Stay}</p>
                  </Col>
                </Row>
              </Button>
            </Col>
            <Col>
              <Button onClick={onClickLeave} className="alert_btn">
                <Row>
                  <Col>
                    <img src={leave} width="50" height="50" />
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <p>{local.Leave}</p>
                  </Col>
                </Row>
              </Button>
            </Col>
          </Row>
        </div>
      </Dialog>
    </div>
  );
};

export default AlertModel;
