import Modal from "react-modal";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import { selectStyle3 } from "../../utils/GeneralStyle";
import { local } from "../../lang/local.js";
import "./styles/EditGiftsModal/edit-gifts-modal.css";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  checkHasOneCompany,
  getMyCompany,
  validateForm,
} from "../../utils/GeneralFunctions";
import React from "react";
import { useEffect } from "react";
import { UPDATE_GIFTS_PROPS } from "../../redux/actions/types";

const AddGiftsModal = ({
  getCompanyOptions,
  clearAndCloseAddGiftsModal,
  addGiftsModal,
  addGiftOrSample,
  setGiftsProps,
  general,
}) => {
  useEffect(() => {
    general(
      [
        {
          prop: "addGiftsModal.gift.companyId",
          value: getMyCompany(general),
        },
      ],
      UPDATE_GIFTS_PROPS
    );
  }, []);
  const customStyles = {
    content: {
      backgroundColor: "white",
      borderRadius: "10px",
    },
    overlay: {
      zIndex: 5000,
      backgroundColor: "rgba(30, 30, 30, .6)",
    },
  };

  return (
    <Modal
      isOpen={addGiftsModal.isOpen}
      style={customStyles}
      className="edit-gifts-modal"
    >
      <div>
        <div className="edit-gifts-modal_modal-title">
          <div onClick={clearAndCloseAddGiftsModal} id="exit-icon">
            <FaTimes />
          </div>
          {local.AddGiftOrSample}
        </div>
        <div className="d-flex flex-column mx-5 my-4">
          {checkHasOneCompany() === false ? (
            <React.Fragment>
              <div className="edit-gifts-modal_label mb-2">{local.Company}</div>
              <div className="mb-2">
                <AsyncPaginate
                  loadOptions={getCompanyOptions}
                  styles={selectStyle3}
                  placeholder={local.Company}
                  onChange={(e) => {
                    setGiftsProps([["addGiftsModal.gift.companyId", e.value]]);
                  }}
                  additional={{
                    page: 1,
                  }}
                />
              </div>
            </React.Fragment>
          ) : null}
          <div className="edit-gifts-modal_label mb-2">{local.Name}</div>
          <div className="mb-2">
            <input
              onChange={(e) => {
                setGiftsProps([["addGiftsModal.gift.name", e.target.value]]);
              }}
              value={addGiftsModal.gift.name}
              className="col-12"
            />
          </div>
          <div className="edit-gifts-modal_label mb-2">{local.Type}</div>
          <div className="mb-2">
            <Select
              onChange={(e) =>
                setGiftsProps([["addGiftsModal.gift.type", e.value]])
              }
              styles={selectStyle3}
              options={[
                { label: "Gift", value: 0 },
                { label: "Sample", value: 1 },
              ]}
              placeholder={local.Type}
            />
          </div>
          <div className="edit-gifts-modal_label mb-2">{local.Details}</div>
          <div className="mb-5">
            <input
              value={addGiftsModal.gift.details}
              onChange={(e) =>
                setGiftsProps([["addGiftsModal.gift.details", e.target.value]])
              }
              className="col-12"
            />
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={async () => {
                if (
                  validateForm([
                    {
                      label: local.Company,
                      value: addGiftsModal.gift.companyId,
                    },
                    {
                      label: local.Name,
                      value: addGiftsModal.gift.name,
                    },
                    {
                      label: local.Type,
                      value: addGiftsModal.gift.type,
                    },
                    {
                      label: local.Details,
                      value: addGiftsModal.gift.details,
                    },
                  ])
                ) {
                  await addGiftOrSample(
                    addGiftsModal.gift.companyId,
                    addGiftsModal.gift.name,
                    addGiftsModal.gift.type,
                    addGiftsModal.gift.details
                  );
                  clearAndCloseAddGiftsModal();
                }
                // await addGiftOrSample(addGiftsModal.gift.companyId, addGiftsModal.gift.name, addGiftsModal.gift.type, addGiftsModal.gift.details);
                // clearAndCloseAddGiftsModal()
              }}
              id="edit-gifts-modal_save-button"
            >
              {local.Save}
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddGiftsModal;
