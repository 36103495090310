import React, { Component } from "react";
import { Form, Card } from "reactstrap";

import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import FirstTab from "./FirstTab";
import SecondTab from "./SecondTab";
import ThirdTab from "./ThirdTab";
import { onSave } from "./WorkerFunctions";
import { getMyCompany } from "../../utils/GeneralFunctions";
import { UPDATE_WORKER_PROPS } from "../../redux/actions/types";

class CreateUpdateWorker extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { general, modale } = this.props;

    if (modale.actionType === "AddWorker") {
      general(
        [
          {
            prop: "workerForm.formvalues.company_id",
            value: getMyCompany(general),
          },
        ],
        UPDATE_WORKER_PROPS
      );
    }
  };

  renderTabs = () => {
    const {
      workerForm,
      general,
      companyOptions,
      modale,
      companyWfOptions,
      getCompainesWFForDropDown,
      getCompainesWFPointForDropDown,
      workerOptions,
      getWorkersForDropDown,
      createORUpdateWorker,
      workerObj,
      jobTitlesOptions,
      history,
      loadWorkerOptions,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      workerTypeOption,
      branchOptions,
      loadBranchesOptions,
    } = this.props;

    if (modale.activeIndex === 0) {
      return <FirstTab general={general} workerForm={workerForm} />;
    } else if (modale.activeIndex === 1) {
      return (
        <SecondTab
          general={general}
          workerForm={workerForm}
          workerTypeOption={workerTypeOption}
        />
      );
    } else {
      return (
        <ThirdTab
          {...this.props}
          general={general}
          workerForm={workerForm}
          companyOptions={companyOptions}
          getCompainesWFForDropDown={getCompainesWFForDropDown}
          workerOptions={workerOptions}
          getWorkersForDropDown={getWorkersForDropDown}
          jobTitlesOptions={jobTitlesOptions}
          history={history}
          createORUpdateWorker={createORUpdateWorker}
          workerObj={workerObj}
          getCompainesWFPointForDropDown={getCompainesWFPointForDropDown}
          companyWfOptions={companyWfOptions}
          loadCompaniesOptions={loadCompaniesOptions}
          loadWorkerOptions={loadWorkerOptions}
          loadCompaniesWFOptions={loadCompaniesWFOptions}
          iscreate={workerForm.iscreate}
          branchOptions={branchOptions}
          loadBranchesOptions={loadBranchesOptions}
        />
      );
    }
  };
  render() {
    const { modale, workerForm, createORUpdateWorker, workerObj } = this.props;
    return (
      <Form
        onSubmit={(e) =>
          onSave(e, createORUpdateWorker, workerForm, workerObj, true)
        }
      >
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({
  WorkerR,
  CompanyR,
  CompanyWorkFieldR,
  RequestsR,
  BranchR,
}) => {
  return {
    workerForm: WorkerR.workerForm,
    modale: WorkerR.modale,
    jobTitlesOptions: WorkerR.jobTitlesOptions,
    pointsOptions: WorkerR.pointsOptions,
    companyOptions: CompanyR.companyOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    workFieldsNewOptions: WorkerR.workFieldsNewOptions,
    pointsOptions: RequestsR.pointsOptions,
    workerOptions: WorkerR.workerOptions,
    workerObj: WorkerR.workerObj,
    searchOptions: WorkerR.searchOptions,
    workerTypeOption: WorkerR.workerTypeOption,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateWorker);
