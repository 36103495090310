import React, { useEffect } from "react";
import {
  MERGE_WORKER_PROPS,
  UPDATE_WORKER_PROPS,
} from "../../redux/actions/types";
import {
  renderGeneralInput,
  renderPhoneInput,
  getDeafultItem,
  promiseOptions,
  CreatableSelect,
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";
import AsyncCreatableSelect from "react-select/async-creatable";
import icon_btn from "../../assets/img/icon_btn.png";

import { Col, Row, CardBody, Spinner } from "reactstrap";
import { local } from "../../lang/local";
import { renderWorkerSeelct, renderWorkField } from "./WorkerFunctions";
import { selectStyle3, labelStyle } from "../../utils/GeneralStyle";
import { onSave } from "./WorkerFunctions";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import WorkersPointsList from "./WorkersPointsList";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
const ThirdTab = (props) => {
  const {
    general,
    workerForm,
    companyOptions,
    loadCompaniesOptions,
    workerOptions,
    jobTitlesOptions,
    createORUpdateWorker,
    workerObj,
    companyWfOptions,
    loadWorkerOptions,
    loadCompaniesWFOptions,
    iscreate,
    branchOptions,
    loadBranchesOptions,
    setWorkerProps,
  } = props;
  useEffect(() => {
    return () =>
      general([
        { prop: "workerForm.parent_branch", value: 0 },
        UPDATE_WORKER_PROPS,
      ]);
  }, []);
  return (
    <>
      <CardBody className="trans">
        <Row className={workerForm.formvalues.id === undefined ? null : "mt-4"}>
          {workerForm.formvalues.id === undefined ? (
            checkHasOneCompany() === false ? (
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <span style={{ color: "#00bebf" }}>{local.Company}</span>
                {renderCompanySelect(
                  "workerForm.formvalues.company_id",
                  workerForm.formvalues.company_id,
                  UPDATE_WORKER_PROPS,
                  general,
                  local.Company,
                  loadCompaniesOptions,
                  companyOptions,
                  null,
                  [
                    { p: "workerForm.formvalues.branch_id", v: null },
                    { p: "workerForm.formvalues.parent_id", v: null },

                    {
                      p: "workerForm.formvalues.work_field_id",
                      v: null,
                    },
                  ],
                  "4",
                  iscreate,
                  "isWorker",
                  true
                )}
              </Col>
            ) : null
          ) : null}
        </Row>
        <Row className={"mt-4"}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* <span className="label_title" style={labelStyle}>
              {local.Branch}
            </span> */}
            {/* {renderBranchSelect(
              "workerForm.formvalues.branch_id",
              workerForm.formvalues.branch_id,
              UPDATE_WORKER_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              workerForm.formvalues.company_id,
              null,
              [
                {
                  p: "pointForm.formvalues.work_field_id",
                  v: null,
                },
              ],
              "4",
              workerForm.iscreate
            )} */}

            <CustomAsyncPaginate
              fullWidth
              hasLabel
              placeholder={local.Regions}
              loadOptions={getBranchOptions(workerForm.formvalues.company_id, {
                parent_id: 0,
              })}
              cacheUniqsArr={[workerForm.formvalues.company_id]}
              value={workerForm.parent_branch}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  "workerForm.parent_branch",
                  setWorkerProps,
                  [
                    "workerForm.formvalues.work_field_id",
                    "workerForm.formvalues.points",
                    "workerForm.formvalues.branch_id",
                  ]
                )
              }
              additional={{
                page: 1,
              }}
              isDisabled={!!!workerForm.formvalues.company_id}
            />
            <CustomAsyncPaginate
              fullWidth
              hasLabel={true}
              placeholder={local.Cities}
              loadOptions={getBranchOptions(workerForm.formvalues.company_id, {
                parent_id: workerForm.parent_branch?.id,
              })}
              cacheUniqsArr={[workerForm.parent_branch]}
              value={workerForm.formvalues.branch_id}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  "workerForm.formvalues.branch_id",
                  setWorkerProps,
                  [
                    "workerForm.formvalues.work_field_id",
                    "workerForm.formvalues.points",
                  ]
                )
              }
              additional={{
                page: 1,
              }}
              isDisabled={!!!workerForm.parent_branch}
            />
          </Col>
        </Row>
        <Row className={workerForm.formvalues.id === undefined ? null : "mt-4"}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* {renderWorkField(
              "workerForm.formvalues.work_field_id",
              workerForm.formvalues.work_field_id,
              general,
              workerForm.formvalues.branch_id,
              companyWfOptions,
              loadCompaniesWFOptions,
              "4",
              {
                p: "workerForm.formvalues.work_field_id",
                v: [null],
              },
              iscreate
            )} */}
            <WorkersPointsList
              fullWidth={true}
              employeeID={workerForm.formvalues.id}
            />
          </Col>
        </Row>

        {/* <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderWorkerSeelct(
              "workerForm.formvalues.parent_id",
              workerForm.formvalues.parent_id,
              UPDATE_WORKER_PROPS,
              general,
              loadWorkerOptions,
              workerOptions,
              workerForm.formvalues.company_id,
              "4",
              iscreate,
              false,
              undefined,
              [],
              undefined,
              (option, rawInput) => option.data.job_title === "Supervisor",
              local.Supervisor,
              true
            )}
          </Col>
        </Row> */}

        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderPhoneInput(
              "workerForm.formvalues.mobile",
              workerForm.formvalues.mobile,
              UPDATE_WORKER_PROPS,
              general,
              null,
              null,
              workerForm.formvalues.mobile.substr(0, 2) === "20" ? false : true
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              "workerForm.formvalues.ssn",
              workerForm.formvalues.ssn,
              UPDATE_WORKER_PROPS,
              general,
              local.Ssn,
              undefined,
              14,
              /^\d+$/
            )}
          </Col>
        </Row>
      </CardBody>

      <center className="mt-5">
        <button
          type="submit"
          disabled={workerForm.isLoading}
          className="save_btn mt-5"
          onClick={(e) =>
            onSave(e, createORUpdateWorker, workerForm, workerObj, true)
          }
        >
          {workerForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center>
    </>
  );
};

export default ThirdTab;
