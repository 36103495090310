import { Request, Select_Request } from "../Api/index";
import {
  DELETE_STATE_WORK_FIELD_PROPS,
  MERGE_STATE_WORK_FIELD_PROPS,
  UPDATE_STATE_WORK_FIELD_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
} from "../../utils/GeneralFunctions";
export const getStatesWorkFields = (isSearching, searchForm) => (dispatch) => {
  let url = "/states/work_fields";
  dispatch({
    type: UPDATE_STATE_WORK_FIELD_PROPS,
    payload: [{ prop: "stateWFObj.stateWFItem", value: null }],
  });

  if (searchForm !== null) {
    let newSearchForm = Object.assign({}, searchForm);
    newSearchForm = removeUnsetValues(newSearchForm);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }
  if (isSearching === false) {
    dispatch({
      type: UPDATE_STATE_WORK_FIELD_PROPS,
      payload: [{ prop: "stateWFObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_STATE_WORK_FIELD_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_STATE_WORK_FIELD_PROPS,
            payload: [{ prop: "stateWFObj.stateWFItem", value: res.data.data }],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_STATE_WORK_FIELD_PROPS,
        payload: [
          { prop: "stateWFObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_STATE_WORK_FIELD_PROPS,
        payload: [
          { prop: "stateWFObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getStatesWFForDropDown = (item) => (dispatch) => {
  if (typeof item === "number") {
    Request()
      .get("/states/work_fields?state_id=", item)
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data !== null) {
            let result = [];
            res.data.data.data.map((r) => {
              result.push({
                label: getLanguage() === "en" ? r.name.en : r.name.ar,
                value: r.id,
              });
            });
            dispatch({
              type: UPDATE_STATE_WORK_FIELD_PROPS,
              payload: [{ prop: "stateWFOptions", value: result }],
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally();
  }
  // else {
  //   return item.map((entry) => {
  //     let pages = [];
  //     Request()
  //       .get("/states/work_fields?state_id=", entry.value)
  //       .then((res) => {
  //         if (res.data.status === true) {
  //           if (res.data.data !== null) {
  //             let result = [];
  //             res.data.data.data.map((r) => {
  //               result.push({
  //                 label: getLanguage() === "en" ? r.name.en : r.name.ar,
  //                 value: r.id,
  //               });
  //             });
  //             dispatch({
  //               type: UPDATE_STATE_WORK_FIELD_PROPS,
  //               payload: [{ prop: "stateWFOptions", value: result }],
  //             });
  //             for (
  //               let count = res.data.data.pagination.current_page + 1;
  //               count <= res.data.data.pagination.total_pages;
  //               count++
  //             ) {
  //               pages.push(count);
  //             }

  //             if (pages.length > 0) {
  //               return pages.map((page) => {
  //                 return Request()
  //                   .get("/states/work_fields?state_id=", state_id)
  //                   .then((res) => {
  //                     if (res.data.status === true) {
  //                       if (res.data.data !== null) {
  //                         let result = [];
  //                         res.data.data.data.map((r) => {
  //                           result.push({
  //                             label:
  //                               getLanguage() === "en" ? r.name.en : r.name.ar,
  //                             value: r.id,
  //                           });
  //                         });
  //                         dispatch({
  //                           type: UPDATE_STATE_WORK_FIELD_PROPS,
  //                           payload: [
  //                             { prop: "stateWFOptions", value: result },
  //                           ],
  //                         });
  //                       }
  //                     }
  //                   })
  //                   .catch((err) => {
  //                     console.log(err);
  //                   })
  //                   .finally();
  //               });
  //             }
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       })
  //       .finally();
  //   });
  // }
};

export const createORUpdateStateWF =
  (formValues, index, isModal) => (dispatch) => {
    dispatch({
      type: UPDATE_STATE_WORK_FIELD_PROPS,
      payload: [{ prop: "stateWFForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues.id === undefined) {
      url = "/states/work_fields/store";
    } else {
      url = "/states/work_fields/update/" + formValues.id;
    }

    Request()
      .post(url, formValues)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);

            if (isModal === true) {
              // dispatch({
              //   type: MERGE_STATE_WORK_FIELD_PROPS,
              //   payload: [
              //     { prop: "stateWFObj.stateWFItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);

            if (isModal === true) {
              dispatch({
                type: UPDATE_STATE_WORK_FIELD_PROPS,
                payload: [
                  {
                    prop: "stateWFObj.stateWFItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }
          dispatch({
            type: UPDATE_STATE_WORK_FIELD_PROPS,
            payload: [
              {
                prop: "stateWFForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    name: { ar: "", en: "" },
                    state_id: null,
                    address: "",
                    mobile: "",
                  },
                  index: null,
                },
              },
              { prop: "stateWFOptions", value: [] },
              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }

        dispatch({
          type: UPDATE_STATE_WORK_FIELD_PROPS,
          payload: [{ prop: "stateWFForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_STATE_WORK_FIELD_PROPS,
          payload: [{ prop: "stateWFForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getStateWorkField = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("/states/work_fields/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_STATE_WORK_FIELD_PROPS,
              payload: [
                {
                  prop: "stateWFForm.formvalues",
                  value: res.data.data,
                },
                {
                  prop: "stateWFForm.formvalues.state_id",
                  value: res.data.data.state.id,
                },
              ],
            });
          } else {
            let result = [
              {
                label:
                  getLanguage() === "en"
                    ? res.data.data.name.en
                    : res.data.data.name.ar,
                value: res.data.data.id,
              },
            ];

            dispatch({
              type: UPDATE_STATE_WORK_FIELD_PROPS,
              payload: [{ prop: "stateWFOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const saveStateWFDataFromFile =
  (items, headers, amanaText) => (dispatch) => {
    let data = items;
    let englishIndex = headers.indexOf("EnglishName");
    let arabicIndex = headers.indexOf("ArabicName");
    let parentIndex = headers.indexOf(amanaText);
    let addressIndex = headers.indexOf("Address");
    let mobileIndex = headers.indexOf("Mobile");

    dispatch({
      type: UPDATE_STATE_WORK_FIELD_PROPS,
      payload: [{ prop: "fileOptions.isLoading", value: true }],
    });
    data.map((row, index) => {
      let formValues = {
        name: { en: row[englishIndex], ar: row[arabicIndex] },

        address: row[addressIndex],
        mobile: row[mobileIndex],
        state_id: row[parentIndex],
      };

      Request()
        .post("/states/work_fields/store", formValues)
        .then((res) => {
          if (res.data.status === true) {
            dispatch({
              type: UPDATE_STATE_WORK_FIELD_PROPS,
              payload: [
                {
                  prop: "fileOptions.importedData." + index + "." + row.length,
                  value: true,
                },
              ],
            });

            dispatch({
              type: DELETE_STATE_WORK_FIELD_PROPS,
              payload: [{ prop: "fileOptions.savedData." + index }],
            });

            if (index === data.length - 1) {
              confirmSunccessed(local.SuccessfullyAdded);
              dispatch({
                type: UPDATE_STATE_WORK_FIELD_PROPS,
                payload: [
                  { prop: "fileOptions.savedData", value: null },
                  { prop: "fileOptions.isLoading", value: false },
                ],
              });
            }
          } else {
            dispatch({
              type: UPDATE_STATE_WORK_FIELD_PROPS,
              payload: [{ prop: "fileOptions.isLoading", value: false }],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_STATE_WORK_FIELD_PROPS,
            payload: [{ prop: "fileOptions.isLoading", value: false }],
          });
          console.log(err);
        })
        .finally();
    });
  };

export const loadStateWFOptions =
  (name, loadedOptions, page, state_id) => async (dispatch) => {
    let url = null;
    let options = [];
    let current_page = null;
    let total_page = null;

    if (state_id !== null) {
      if (name === undefined) {
        url =
          Select_Request.url +
          "/states/work_fields?page=" +
          page.page +
          "&state_id=" +
          state_id.value;
      } else {
        if (name !== "") {
          url =
            Select_Request.url +
            "/states/work_fields?name=" +
            name +
            "&page=" +
            page.page +
            "&state_id=" +
            state_id.value;
        } else {
          url =
            Select_Request.url +
            "/states/work_fields?page=" +
            page.page +
            "&state_id=" +
            state_id.value;
        }
      }
      let response = await fetch(url, Select_Request.options);
      let responseOK = response && response.ok;
      if (responseOK) {
        let result = await response.json();

        if (result.status === true) {
          current_page = result.data.pagination.current_page;
          total_page = result.data.pagination.total_pages;
          if (result.data.data !== null) {
            result.data.data.map((d) => {
              options.push({
                label: getLanguage() === "en" ? d.name.en : d.name.ar,
                value: d.id,
                state_id: state_id.value,
              });
            });
          }
        }
      }

      return {
        options: options,
        hasMore: current_page === total_page ? false : true,
        additional: {
          page: page.page + 1,
        },
      };
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };
