import React, { Component } from "react";
import { Form, Card, Row, Col, Spinner, Button } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { local } from "../../lang/local";
import { leftlStyle, rightStyle } from "../../utils/GeneralStyle";
import LightBoxFile from "../../utils/LightBoxFile";
import {
  MERGE_WORKER_PROPS,
  UPDATE_WORKER_PROPS,
} from "../../redux/actions/types";
import { FaFileExcel } from "react-icons/fa";
import AsyncCreatableSelect from "react-select/async-creatable";
import {
  renderGeneralInput,
  renderPasswordInput,
  renderPhoneInput,
  renderPhotoCard,
  promiseOptions,
  renderArraw,
  getDeafultItem,
  CreatableSelect,
  renderSingleSelect,
  getMyCompany,
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";

import { labelStyle, selectStyle3 } from "../../utils/GeneralStyle";
import icon_btn from "../../assets/img/icon_btn.png";

import {
  onSave,
  renderRemoveButton,
  renderWorkField,
  unsetWorkerListData,
  renderWorkerSeelct,
} from "./WorkerFunctions";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import WorkersPointsList from "./WorkersPointsList";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
class AddWorker extends Component {
  componentDidMount() {
    unsetWorkerListData(this.props.general);
    this.props.general(
      [
        {
          prop: "workerForm.formvalues.company_id",
          value: getMyCompany(this.props.general),
        },
      ],
      UPDATE_WORKER_PROPS
    );
  }

  renderField = () => {
    const {
      workerForm,
      general,
      companyWfOptions,
      loadCompaniesWFOptions,
      history,
    } = this.props;
    if (workerForm.formvalues.work_field_id.length > 0) {
      return workerForm.formvalues.work_field_id.map((wf, index) => {
        if (index !== 0) {
          return (
            <div>
              <br />
              {renderRemoveButton(index, general)}
              <Row className="mt-2">
                <Col xl={6} lg={6} md={6} sm={6} xs={6}>
                  <span className="label_title" style={labelStyle}>
                    {local.WorkPlaces}
                  </span>

                  {renderWorkField(
                    "workerForm.formvalues.work_field_id." + index,
                    workerForm.formvalues.work_field_id[index],
                    general,
                    workerForm.formvalues.branch_id,
                    history,
                    companyWfOptions,
                    loadCompaniesWFOptions,
                    "4",
                    { p: "workerForm.formvalues.points." + index, v: [] }
                  )}
                </Col>
              </Row>
            </div>
          );
        }
      });
    }
  };

  render() {
    const {
      workerForm,
      createORUpdateWorker,
      workerObj,
      general,
      lightbox,
      history,

      companyOptions,
      workerOptions,
      loadCompaniesOptions,
      loadWorkerOptions,
      jobTitlesOptions,
      workerTypeOption,

      setWorkerProps,
    } = this.props;
    return (
      <div className="conatiner">
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_WORKER_PROPS}
        />

        <Row style={{ height: "100%" }}>
          <Col className="body-container">
            <Form
              style={{ height: "100%" }}
              onSubmit={(e) =>
                onSave(e, createORUpdateWorker, workerForm, workerObj, false)
              }
            >
              <Card className="cardCreate ml-2 mt-4 mr-2">
                <div className="mt-5 mr-5 ml-5">
                  <Row>
                    <Col>
                      <p style={leftlStyle}>{local.AddWorker}</p>
                    </Col>
                    {renderArraw(history)}
                  </Row>
                  <br /> <br />
                  <Row>
                    <Col>
                      <p className="page_title" style={leftlStyle}>
                        {local.MainInfo}
                      </p>
                    </Col>
                    <Col>
                      <div style={rightStyle}>
                        <Button
                          className="place_btn"
                          onClick={(e) =>
                            history.push(".dashboard/add_worker_from_file")
                          }
                        >
                          <span style={{ fontSize: "10px" }}>
                            {local.AddFile}
                          </span>
                          &nbsp;
                          <span>
                            <FaFileExcel />
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xl={2}>
                      <div>
                        {renderPhotoCard(
                          general,
                          "workerForm.formvalues.image",
                          UPDATE_WORKER_PROPS,
                          workerForm.formvalues.image
                        )}
                      </div>
                    </Col>
                    <Col xl={3}>
                      <Row className="justify-content-center justify-content-lg-start">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mt-2"
                        >
                          {renderGeneralInput(
                            "workerForm.formvalues.name",
                            workerForm.formvalues.name,
                            UPDATE_WORKER_PROPS,
                            general,
                            local.Name
                          )}
                        </Col>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mt-2"
                        >
                          {renderPasswordInput(
                            "workerForm.formvalues.password_confirmation",
                            workerForm.formvalues.password_confirmation,
                            UPDATE_WORKER_PROPS,
                            general,
                            local.ConfirmPassword
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={3}>
                      <Row className="justify-content-center justify-content-lg-start">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mt-2"
                        >
                          {renderGeneralInput(
                            "workerForm.formvalues.user_name",
                            workerForm.formvalues.user_name,
                            UPDATE_WORKER_PROPS,
                            general,
                            local.UserName
                          )}
                          <input
                            type="text"
                            style={{ position: "absolute", zIndex: "-1" }}
                          />
                        </Col>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mt-2"
                        >
                          {renderGeneralInput(
                            "workerForm.formvalues.email",
                            workerForm.formvalues.email,
                            UPDATE_WORKER_PROPS,
                            general,
                            local.Email
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col xl={3}>
                      <Row className="justify-content-center justify-content-lg-start">
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mt-2"
                        >
                          <input
                            type="password"
                            style={{ position: "absolute", zIndex: "-1" }}
                          />
                          {renderPasswordInput(
                            "workerForm.formvalues.password",
                            workerForm.formvalues.password,
                            UPDATE_WORKER_PROPS,
                            general,
                            local.Password
                          )}
                        </Col>
                        <Col
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className="mt-2"
                        >
                          {renderGeneralInput(
                            "workerForm.formvalues.address",
                            workerForm.formvalues.address,
                            UPDATE_WORKER_PROPS,
                            general,
                            local.Address
                          )}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row className="mt-3 justify-content-center justify-content-lg-start">
                    <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
                      {renderPhoneInput(
                        "workerForm.formvalues.mobile",
                        workerForm.formvalues.mobile,
                        UPDATE_WORKER_PROPS,
                        general
                      )}
                    </Col>
                    <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
                      {renderGeneralInput(
                        "workerForm.formvalues.ssn",
                        workerForm.formvalues.ssn
                          ? workerForm.formvalues.ssn
                          : "",
                        UPDATE_WORKER_PROPS,
                        general,
                        local.Ssn,
                        undefined,
                        14,
                        /^\d+$/
                      )}
                    </Col>

                    {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
                      <span className='label_title' style={labelStyle}>
                        {local.JobTitle}
                      </span>
                      <AsyncCreatableSelect
                        className='mt-4'
                        placeholder={local.JobTitle}
                        styles={selectStyle3}
                        defaultOptions={jobTitlesOptions}
                        loadOptions={() => promiseOptions(jobTitlesOptions)}
                        value={getDeafultItem(
                          workerForm.formvalues.job_title,
                          jobTitlesOptions
                        )}
                        onChange={(e) =>
                          CreatableSelect(
                            e,
                            general,
                            'workerForm.formvalues.job_title',
                            'jobTitlesOptions',
                            jobTitlesOptions,
                            UPDATE_WORKER_PROPS,
                            MERGE_WORKER_PROPS
                          )
                        }
                        getOptionLabel={(option) => option.label}
                        getOptionValue={(option) => option.value}
                      />
                    </Col> */}
                    <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
                      {renderSingleSelect(
                        "workerForm.formvalues.worker_type",
                        workerForm.formvalues.worker_type,
                        UPDATE_WORKER_PROPS,
                        general,
                        local.WorkerType,
                        workerTypeOption
                      )}
                    </Col>
                  </Row>
                  <br /> <br /> <br />
                  <div className="page_title" style={leftlStyle}>
                    <p>{local.WorkPlaces}</p>
                  </div>
                  <br />
                  <Row className="mt-4 justify-content-center justify-content-lg-start">
                    {checkHasOneCompany() === false ? (
                      <Col
                        xl={3}
                        lg={3}
                        md={3}
                        sm={12}
                        xs={12}
                        className="mt-2"
                      >
                        <span className="label_title" style={labelStyle}>
                          {local.Company}
                        </span>
                        {renderCompanySelect(
                          "workerForm.formvalues.company_id",
                          workerForm.formvalues.company_id,
                          UPDATE_WORKER_PROPS,
                          general,
                          local.Company,
                          loadCompaniesOptions,
                          companyOptions,
                          null,
                          [
                            { p: "workerForm.formvalues.parent_id", v: null },
                            { p: "workerForm.formvalues.branch_id", v: null },

                            {
                              p: "workerForm.formvalues.work_field_id",
                              v: [],
                            },
                            {
                              p: "workerForm.formvalues.points",
                              v: [],
                            },
                          ],
                          "4",
                          workerForm.iscreate,
                          "isWorker"
                        )}
                      </Col>
                    ) : null}

                    {/* {renderBranchSelect(
                        "workerForm.formvalues.branch_id",
                        workerForm.formvalues.branch_id,
                        UPDATE_WORKER_PROPS,
                        general,
                        local.Branch,
                        loadBranchesOptions,
                        branchOptions,
                        workerForm.formvalues.company_id,
                        null,
                        [
                          {
                            p: "workerForm.formvalues.work_field_id",
                            v: [],
                          },
                          {
                            p: "workerForm.formvalues.points",
                            v: [],
                          },
                        ],
                        "4",
                        workerForm.iscreate
                      )} */}

                    <CustomAsyncPaginate
                      hasLabel={true}
                      placeholder={local.Regions}
                      loadOptions={getBranchOptions(
                        workerForm.formvalues.company_id,
                        { parent_id: 0 }
                      )}
                      cacheUniqsArr={[workerForm.formvalues.company_id]}
                      value={workerForm.parent_branch}
                      onChange={(e) =>
                        handleSelectChangeEnhanced(
                          e,
                          "workerForm.parent_branch",
                          setWorkerProps,
                          [
                            "workerForm.formvalues.work_field_id",
                            "workerForm.formvalues.points",
                            "workerForm.formvalues.branch_id",
                          ]
                        )
                      }
                      additional={{
                        page: 1,
                      }}
                      isDisabled={!!!workerForm.formvalues.company_id}
                    />
                    <CustomAsyncPaginate
                      hasLabel={true}
                      placeholder={local.Cities}
                      loadOptions={getBranchOptions(
                        workerForm.formvalues.company_id,
                        { parent_id: workerForm.parent_branch?.id }
                      )}
                      cacheUniqsArr={[workerForm.parent_branch]}
                      value={workerForm.formvalues.branch_id}
                      onChange={(e) =>
                        handleSelectChangeEnhanced(
                          e,
                          "workerForm.formvalues.branch_id",
                          setWorkerProps,
                          [
                            "workerForm.formvalues.work_field_id",
                            "workerForm.formvalues.points",
                          ]
                        )
                      }
                      additional={{
                        page: 1,
                      }}
                      isDisabled={!!!workerForm.parent_branch}
                    />

                    {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
                      <span className='label_title' style={labelStyle}>
                        {local.Supervisor}
                      </span>
                      {renderWorkerSeelct(
                        'workerForm.formvalues.parent_id',
                        workerForm.formvalues.parent_id,
                        UPDATE_WORKER_PROPS,
                        general,
                        loadWorkerOptions,
                        workerOptions,
                        workerForm.formvalues.company_id,
                        '4',
                        workerForm.iscreate,
                        false,
                        undefined,
                        [],
                        undefined,
                        (option, rawInput) =>
                          option.data.job_title === 'Supervisor',
                        local.Supervisor,
                        true
                      )}
                    </Col> */}
                  </Row>
                  <Row className="mt-4 justify-content-center justify-content-lg-start">
                    {/* <div className="react-select-div">
                      <span className="label_title" style={labelStyle}>
                        {local.WorkPlaces}
                      </span>

                      {renderWorkField(
                        "workerForm.formvalues.work_field_id",
                        workerForm.formvalues.work_field_id,
                        general,
                        workerForm.formvalues.branch_id,
                        companyWfOptions,
                        loadCompaniesWFOptions,
                        "4",
                        null,
                        workerForm.iscreate
                      )}
                    </div> */}
                    <WorkersPointsList />
                  </Row>
                  <center className="mt-5">
                    <button
                      type="submit"
                      disabled={workerForm.isLoading}
                      className="save_btn mt-5"
                      onClick={(e) =>
                        onSave(
                          e,
                          createORUpdateWorker,
                          workerForm,
                          workerObj,
                          false
                        )
                      }
                    >
                      {workerForm.isLoading === true ? (
                        <Spinner type="grow" color="dark" size="sm" />
                      ) : (
                        <>
                          <p style={{ display: "inline" }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width="20"
                            height="20"
                            alt="img"
                          />
                        </>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  WorkerR,
  CompanyR,
  CompanyWorkFieldR,
  RequestsR,
  BranchR,
}) => {
  return {
    workerForm: WorkerR.workerForm,
    modale: WorkerR.modale,
    pointsOptions: WorkerR.pointsOptions,
    jobTitlesOptions: WorkerR.jobTitlesOptions,
    companyOptions: CompanyR.companyOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    workFieldsNewOptions: WorkerR.workFieldsNewOptions,
    pointsOptions: RequestsR.pointsOptions,
    workerOptions: WorkerR.workerOptions,
    workerObj: WorkerR.workerObj,
    lightbox: WorkerR.lightbox,
    workerTypeOption: WorkerR.workerTypeOption,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(AddWorker);
