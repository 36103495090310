import { Doughnut } from 'react-chartjs-2';
import "./DoughnutChart.css";

// var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw: function() {
//     originalDoughnutDraw.apply(this, arguments);
    
//     var chart = this.chart.chart;
//     var ctx = chart.ctx;
//     var width = chart.width;
//     var height = chart.height;

//     var fontSize = (height / 114).toFixed(2);
//     ctx.font = fontSize + "em Verdana";
//     ctx.textBaseline = "middle";

//     var text = chart.config.data.text2,
//         textX = Math.round((width - ctx.measureText(text).width) / 2),
//         textY = height / 2;

//     ctx.fillText(text, textX, textY);
//   }
// });

const DoughnutChart = ({ doughnutLabel, doughnutData, colors }) => {

  const backgroundColor = [
    'rgba(255, 99, 132, 0.2)',
    'rgba(54, 162, 235, 0.2)',
    'rgba(255, 206, 86, 0.2)',
    'rgba(75, 192, 192, 0.2)',
    'rgba(153, 102, 255, 0.2)',
    'rgba(255, 159, 64, 0.2)'
  ]

  const borderColor = [
    'rgba(255, 99, 132, 1)',
    'rgba(54, 162, 235, 1)',
    'rgba(255, 206, 86, 1)',
    'rgba(75, 192, 192, 1)',
    'rgba(153, 102, 255, 1)',
    'rgba(255, 159, 64, 1)'
  ]

  const renderBackgroundColor = () => {
    let backgroundColorArr = [];
    for (let i = 0; i < doughnutData.length; i++) {
      backgroundColorArr.push(backgroundColor[i]);
    }
    if (backgroundColorArr.length > 0) {
      return backgroundColorArr;
    }
  }

  const renderBorderColor = () => {
    let borderColorArr = [];
    for (let i = 0; i < doughnutData.length; i++) {
      borderColorArr.push(borderColor[i]);
    }
    if (borderColorArr.length > 0) {
      return borderColorArr;
    }
  }

  const calculateTotalData = () => {
    let total = 0
    for (let i = 0; i < doughnutData.length; i++) {
      total += doughnutData[i];
    }
    return total.toString();
  }

  return (
    <div style={{"width": "10rem", "height": "8rem", "position": "relative"}}>
      <div className="chart-text-centered">50%</div>
      <Doughnut
        data={
          {
            datasets: [{
                label: '# of Votes',
                data: doughnutData,
                backgroundColor: colors,
                borderWidth: 1
            }],
            text: '50'
        }
        }
        options={{
          maintainAspectRatio: false,
          responsive: true,
          cutoutPercentage: 80,
          title: {
            display: true,
            text: 'Title'
          },
          legend: {
            display: false
          }
        }}
      />
    </div>
  )
}

export default DoughnutChart;