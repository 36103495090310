import React, { useState } from "react";
import { connect } from "react-redux";
import { Mapy } from "mapy_sdk";
import { local } from "../../../../lang/local";
import {
  pointObjectToWKT,
  WKTPolygonToGeoJson,
} from "../../../../components/GenericMapy/utils";
import { onWorkFieldChangeCB } from "../../RequestsFunctions";
import {
  isPointInPolygon,
  handleError,
  checkHasOneCompany,
  handleSelectChangeEnhanced,
  getCompanyOptions,
  getBranchOptions,
  workFieldPermissionedForDropDown,
  getWorkFieldOptions,
} from "../../../../utils/GeneralFunctions";
import { UPDATE_REQUESTS_PROPS } from "../../../../redux/actions/types";
import { SelectPointsMarkers } from "../../../../redux/selectors/CompanyWFPointSelector";
import * as actions from "../../../../redux/actions/index";
import Dropdown from "../../../../components/AsyncPaginate/Dropdown";
import Input from "../../../../components/Input/Input";
import GenericMapy from "../../../../components/GenericMapy/GenericMapy";
import "./step.css";
import BoxLink from "../../../../utils/BoxLink";
import { renderWorkFSelect } from "../../../CompainesWorkFields/CompanyWFFunctions";
import CustomAsyncPaginate from "../../../../components/CustomAsyncPaginate/CustomAsyncPaginate";

let map = new Mapy();

const ServiceLocation = (props) => {
  const {
    polygonItem,
    markers,
    general,
    pointForm,
    setRequestsProps,
    getAllPoints,
    companyWfOptions,
    loadCompaniesWFOptions,
  } = props;

  const [mapBounds, setMapBounds] = useState(undefined);

  const MapBounds = (mapBounds) => {
    setMapBounds(mapBounds);
  };

  const onMapClick = (latLng, e) => {
    const lng = latLng.lng;
    const lat = latLng.lat;

    const { polygonItem, general } = props;

    if (polygonItem) {
      const geoJsonPolygon = WKTPolygonToGeoJson(polygonItem);
      if (isPointInPolygon(lng, lat, geoJsonPolygon)) {
        const WKTPoint = pointObjectToWKT(latLng);

        general(
          [{ prop: "pointForm.formvalues.location", value: WKTPoint }],
          UPDATE_REQUESTS_PROPS
        );
      } else {
        handleError(local.PointOutside);
      }
    }
  };
  return (
    <div>
      <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto lg:w-2/3 gap-x-7 gap-y-12 mt-16 ">
        {/* <Dropdown
          label="Service Task City"
          placeholder="please select city"
        /> */}
        {pointForm.formvalues.id === undefined ? (
          <>
            {checkHasOneCompany() === false ? (
              <Dropdown
                loadOptions={getCompanyOptions}
                value={pointForm.company}
                onChange={(e) =>
                  handleSelectChangeEnhanced(
                    e,
                    "pointForm.company",
                    setRequestsProps,
                    [
                      "pointForm.branch_id",
                      "pointForm.formvalues.work_field_id",
                      "pointForm.work_fields_points",
                    ]
                  )
                }
                placeholder={local.companies}
                label={local.companies}
                additional={{
                  page: 1,
                }}
              />
            ) : null}
            <Dropdown
              loadOptions={getBranchOptions(
                typeof pointForm.company === "object"
                  ? pointForm.company?.id
                  : pointForm.company,
                { parent_id: 0 }
              )}
              value={pointForm.parent_branch}
              placeholder={local.Regions}
              cacheUniqsArr={[pointForm.company]}
              label={local.Regions}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  "pointForm.parent_branch",
                  setRequestsProps,
                  [
                    "pointForm.formvalues.work_field_id",
                    "pointForm.work_fields_points",
                    "pointForm.branch_id",
                  ]
                )
              }
              additional={{
                page: 1,
              }}
              isDisabled={!!!pointForm.company}
            />
            <Dropdown
              loadOptions={getBranchOptions(
                typeof pointForm.company === "object"
                  ? pointForm.company?.id
                  : pointForm.company,
                { parent_id: pointForm.parent_branch?.id }
              )}
              value={pointForm.branch_id}
              placeholder={local.Cities}
              label={local.Cities}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  "pointForm.branch_id",
                  setRequestsProps,
                  [
                    "pointForm.formvalues.work_field_id",
                    "pointForm.work_fields_points",
                  ]
                )
              }
              additional={{
                page: 1,
              }}
              isDisabled={!!!pointForm.parent_branch}
              cacheUniqsArr={[pointForm.parent_branch]}
            />
          </>
        ) : null}
        <div>
          <span
            className="mb-2 text-xs font-semibold"
            style={{ color: "rgb(122, 134, 154)" }}
          >
            {local.Zones}
          </span>
          {renderWorkFSelect(
            "pointForm.formvalues.work_field_id",
            pointForm.formvalues.work_field_id,
            UPDATE_REQUESTS_PROPS,
            general,
            local.Zones,
            loadCompaniesWFOptions,
            companyWfOptions,
            null,
            ["pointForm.formvalues.workers"],
            pointForm.branch_id?.id,
            null,
            "polygonItem",
            pointForm.iscreate,
            getAllPoints,
            (workField) => onWorkFieldChangeCB(workField, MapBounds),
            !!!pointForm.branch_id
          )}
        </div>
        {/* <Dropdown
          loadOptions={getWorkFieldOptions(pointForm.branch_id?.id)}
          value={pointForm.formvalues.work_field_id}
          placeholder={local.WorkPlaces}
          label={local.WorkPlaces}
          onChange={(e) =>
            handleSelectChangeEnhanced(
              e,
              "pointForm.formvalues.work_field_id",
              setRequestsProps,
              ["pointForm.formvalues.workers"]
            )
          }
          filterOption={(option, rawInput) =>
            workFieldPermissionedForDropDown({ option, rawInput })
          }
          additional={{
            page: 1,
          }}
        /> */}
        {/* <CustomAsyncPaginate
          label={local.WorkPlaces}
          loadOptions={getWorkFieldOptions(pointForm.branch_id?.id)}
          className=" mt-2"
          value={pointForm.formvalues.work_field_id}
          placeholder={local.WorkPlaces}
          cacheUniqsArr={[pointForm.branch_id?.id]}
          // isMulti={true}
          onChange={(e) =>
            handleSelectChangeEnhanced(
              e,
              "pointForm.formvalues.work_field_id",
              setRequestsProps,
              ["pointForm.formvalues.workers"]
            )
          }
          filterOption={(option, rawInput) =>
            workFieldPermissionedForDropDown({ option, rawInput })
          }
          additional={{
            page: 1,
          }}
        /> */}

        <Input
          label={local.MerchantAddress}
          value={pointForm.formvalues.properties.address}
          action={UPDATE_REQUESTS_PROPS}
          prop="pointForm.formvalues.properties.address"
          general={general}
        />

        <Input
          label={local.Landmark}
          value={pointForm.formvalues.properties.landmark}
          action={UPDATE_REQUESTS_PROPS}
          prop="pointForm.formvalues.properties.landmark"
          general={general}
        />
        <div className="col-span-2">
          <div
            className="mb-2 text-xs font-semibold"
            style={{ color: "rgb(122, 134, 154)" }}
          >
            {local.Comment}
          </div>
          <textarea
            rows={5}
            className="w-full border-0 p-3 outline-none"
            style={{ backgroundColor: "rgb(238, 238, 238)" }}
            value={pointForm.formvalues.properties.comment}
            onChange={(e) => {
              general(
                [
                  {
                    prop: "pointForm.formvalues.properties.comment",
                    value: e.target.value,
                  },
                ],
                UPDATE_REQUESTS_PROPS
              );
            }}
          ></textarea>
        </div>
        <div className="col-span-2">
          {/* <Input label="GPS - Location " /> */}
          <BoxLink
            general={general}
            prop="modale.mapItem.mapPoints"
            action={UPDATE_REQUESTS_PROPS}
            FormProp={"pointForm.formvalues.location"}
            work_field_id={pointForm.formvalues.work_field_id}
            polygonItem={polygonItem}
            mapZoomProp={"requestsObj.mapZoom"}
            saveLink="pointForm.formvalues.properties.link"
            valueLink={pointForm.formvalues.properties.link}
            map={map}
          />
        </div>
      </div>
      {/* <div className="h-72 md:h-96 mt-12 mx-auto lg:w-3/4"> */}
      <div
        className="h-72  mt-12 mx-auto"
        style={{ height: "30rem", zIndex: 0 }}
      >
        <GenericMapy
          map={map}
          onMapClick={onMapClick}
          mapID="addWorkFieldPointMap"
          singlePolygon={polygonItem}
          markers={markers}
          zoomToMarkers={false}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ RequestsR, CompanyWorkFieldR }) => {
  return {
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    polygonItem: RequestsR.polygonItem,
    pointForm: RequestsR.pointForm,
    markers: SelectPointsMarkers(
      RequestsR.pointForm.work_fields_points,
      RequestsR.pointForm.formvalues.location
    ),
  };
};

export default connect(mapStateToProps, actions)(ServiceLocation);
