import { setIsLoading, setIsLoading2 } from ".";
import {
  setterGenerator,
  handleError,
  confirmSunccessed,
  isArray,
  getAsyncSelectOptions,
} from "../../utils/GeneralFunctions";
import { local } from "../../lang/local";
import {
  addPointsToEmployeeCoverageList,
  getEmployeeCoverageList,
  removeEmployeePoint,
} from "../Api/apiCallsMethods";
import { CLEAR_COVERAGE_LIST_PROPS, UPDATE_COVERAGE_LIST_PROPS } from "./types";

export const setCoverageListProps = (propsArr) =>
  setterGenerator(UPDATE_COVERAGE_LIST_PROPS, propsArr);

export const clearCoverageListProps = () => ({
  type: CLEAR_COVERAGE_LIST_PROPS,
});

export const getCoverageList =
  (searchForm, callBackFunc, { stopSideEffects = false } = {}) =>
  async (dispatch) => {
    const queryParams = {
      point_id: searchForm.pointID,
      worker_id: searchForm.worker?.id,
      work_field_id: searchForm.workFieldID,
      page: searchForm.page,
    };

    try {
      !stopSideEffects && dispatch(setIsLoading(true));
      getEmployeeCoverageList(searchForm.companyID, queryParams).then(
        (res) => {
          console.log(res);
          if (callBackFunc) callBackFunc(res);
          !stopSideEffects && dispatch(setIsLoading(false));
          if (res.data.code === 200) {
            const coverageListData = res.data.data.data;
            if (coverageListData) {
              dispatch(setCoverageListProps([["coverageList", res.data.data]]));
              if (coverageListData.length === 0) {
                !stopSideEffects && confirmSunccessed(local.NoSearch);
              }
            }
          }
          return res;
        }
      );
    } catch (err) {
      handleError(err);
      dispatch(setIsLoading(false));
    }
  };

export const addPointsToEmployee =
  (coverageListReducer) => async (dispatch) => {
    const { selectedEmployee, addToCoverageListForm } = coverageListReducer;
    const { points } = addToCoverageListForm;
    const employeePoints = points.map((point) => {
      return {
        point_id: point.id,
        work_field_id: point.work_field.id,
      };
    });
    const requestBody = { points: employeePoints };

    try {
      dispatch(setIsLoading(true));
      addPointsToEmployeeCoverageList(selectedEmployee, requestBody).then(
        (res) => {
          dispatch(setIsLoading(false));
          if (res.data.code === 200) {
            dispatch(clearCoverageListProps());
            confirmSunccessed(local.SuccessfullyAdded);
          }
        }
      );
    } catch (err) {
      dispatch(clearCoverageListProps());
      dispatch(setIsLoading(false));
      console.log(err);
    }
  };

export const removePointsFromEmployee =
  ({ employeeID, employeePoints, workField }, callbackFunction) =>
  async (dispatch) => {
    const requestBody = {
      worker_id: employeeID,
      ids: employeePoints,
    };

    try {
      dispatch(setIsLoading2(true));
      removeEmployeePoint(workField, requestBody).then((res) => {
        dispatch(setIsLoading2(false));
        if (res.data.code === 200) {
          // callbackFunction that clears form or in case of removing from the page
          // or remove the row in case of removing from the table
          if (callbackFunction) callbackFunction();
          confirmSunccessed(local.SuccessfullyDeleted);
        } else handleError(local.SomethingWentWrong);
      });
    } catch (err) {
      // dispatch(clearCoverageListProps());
      handleError(local.SomethingWentWrong);
      dispatch(setIsLoading2(false));
      console.log(err);
    }
  };

export const getEmployeePointsOptions =
  (searchValue, loadedOptions, additional) => async (dispatch) => {
    let payload, hasMore;
    let employeePointsOptions = [];
    const { filters } = additional;
    const { workField, companyId } = filters;

    const searchForm = {
      name: searchValue,
      worker_id: filters.employeeID,
      work_field_id:workField,
      page: additional.page,
    };

    let workFields = additional.workFields;
    if (workFields && isArray(workFields)) {
      searchForm.ids = workFields.map((item) => item.value || item);
    } else if (workFields && !isArray(workFields)) {
      searchForm.ids = workFields.value;
    }

    try {
      await getEmployeeCoverageList(companyId, searchForm).then((response) => {
        const optionsObject = getAsyncSelectOptions(
          response,
          "point.name",
          "id"
        );
        employeePointsOptions = optionsObject.options;
        hasMore = optionsObject.hasMore;
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setCoverageListProps([
        [
          "employeeCoverageListOptions",
          [...loadedOptions, ...employeePointsOptions],
        ],
      ])
    );

    return {
      options: employeePointsOptions,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
        filters: additional.filters,
      },
    };
  };
