import { getQueryString } from "../../utils/GeneralFunctions";
import { Request } from "../Api/index";
import { setMapProps } from "./MapActions";

export const getRouting =
  (
    worker_id,
    from,
    to,
    update_Action,
    merge_Action,
    polylineProp,
    loadingProp,
    latProp,
    lngProp,
    firstRequest,
    scroll
  ) =>
  (dispatch) => {
    if (loadingProp !== null) {
      dispatch({
        type: update_Action,
        payload: [{ prop: loadingProp, value: true }],
      });
    }

    let url = "histories/worker/" + worker_id;
    let paths = [];
    if (firstRequest === true) {
      dispatch({
        type: update_Action,
        payload: [{ prop: polylineProp, value: [] }],
      });
      let newSearchForm = { from: from, to: to };
      let param = getQueryString(newSearchForm);
      url += param;
    } else {
      url = url + "?scroll_id=" + scroll;
    }
    Request()
      .get(url)
      .then((res) => {
        if (res.data.status === true) {
          if (res.data.data !== null) {
            if (res.data.data.data.length > 0) {
              dispatch(
                getRouting(
                  worker_id,
                  from,
                  to,
                  update_Action,
                  merge_Action,
                  polylineProp,
                  null,
                  null,
                  null,
                  false,
                  res.data.data.scroll_id
                )
              );
              let sortedResponse = res.data.data.data.sort(function (a, b) {
                return (
                  new Date(b._source.properties.stamp) -
                  new Date(a._source.properties.stamp)
                );
              });
              sortedResponse.map((d) => {
                paths.push({
                  lat: d._source.geometry.coordinates[1],
                  lng: d._source.geometry.coordinates[0],
                });
              });

              if (latProp !== null && paths.length > 0) {
                dispatch({
                  type: update_Action,
                  payload: [
                    { prop: latProp, value: paths[0].lat },
                    { prop: lngProp, value: paths[0].lng },
                  ],
                });
              }

              if (paths.length > 0) {
                dispatch({
                  type: merge_Action,
                  payload: [{ prop: polylineProp, value: paths }],
                });

                dispatch(
                  setMapProps([
                    ["mapBounds", [paths[0]]],
                    ["zoom", 12],
                  ])
                );
              }
            } else {
              // confirmSunccessed(local.NoSearch);
            }
          }
        }

        if (loadingProp !== null) {
          dispatch({
            type: update_Action,
            payload: [{ prop: loadingProp, value: false }],
          });
        }
      });
  };
