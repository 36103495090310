import { getLanguage } from "../../lang/local";
import {
  CLEAR_USER_STATE,
  UPDATE_USER_PROPS,
  DELETE_USER_PROPS,
  MERGE_USER_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  userObj: { userItem: null, isLoading: false },
  sorting: { column: "", order: "desc" },
  parent_branch: null,
  userForm: {
    isLoading: false,
    formvalues: {
      password: "",
      name: "",
      email: "",
      password_confirmation: "",
      companies: null,
      branches: null,
      work_fields: null,
      permissions: [],
    },
    arrayOptions: {
      companies: [],
      branch_parent: [],
      branches: [],
      work_fields: [],
      company: null,
      branch: null,
    },
    index: null,
    iscreate: "create",
  },

  userOptions: [],
  permissionsOptions: [],
  CityOwnerPermissions:[
    {permission:"home",isSelected:true},
          {permission:"city_requests",isSelected:true},
          {permission:"points/actions",isSelected:true},
          {permission:"workers_points/store/{id}",isSelected:true},
          {permission:"workers/show/{id}",isSelected:true},
          {permission:"workers/{company_id}",isSelected:true},
          {permission:"requests/closebycityowner",isSelected:true},
          {permission:"companies/work_fields/points/show/{id}",isSelected:true},
          {permission:"companies/work_fields/points/store",isSelected:true},
          {permission:"requests",isSelected:true},
          {permission:"requests/store",isSelected:true},
          {permission:"companies/work_fields/points",isSelected:true},
          {permission:"companies/branches/show/{id}",isSelected:true},
      {permission:"companies/{id}/work_fields",isSelected:true},

      {permission:"companies/branches/{company_id}",isSelected:true},
          {permission:"companies/show/{id}",isSelected:true},
          {permission:"companies",isSelected:true},
          {permission:"states/work_fields/show/{id}",isSelected:true},
      {permission:"states/work_fields",isSelected:true},
      {permission:"home",isSelected:true}


  ],
 RequesterPermissions:[
  {permission: "home",isSelected:true},
  {permission: "states/work_fields",isSelected:true},
{permission: "companies/branches/{company_id}",isSelected:true},
{permission: "companies/{id}/work_fields",isSelected:true},
{permission: "companies/work_fields/points",isSelected:true},
{permission: "companies/work_fields/points/store",isSelected:true},
{permission: "workers/{company_id}",isSelected:true},


  // {permission: "companies/work_fields/points/show/{id}",isSelected:true},
  // {permission: "workers_points/store/{id}",isSelected:true},
  // {permission: "points/actions",isSelected:true},




    {permission: "all_work_fields",isSelected:true},
     {permission: "requests",isSelected:true},
     {permission: "requests/store",isSelected:true},
     {permission: "city_requests",isSelected:true}




 ]
,

  searchForm: {
    from: "",
    to: "",
    company: null,
    page: 1,
  },
  isLoadingSearch: false,
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },

      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title:
          getLanguage() === "en" ? "System Data" : "البيانات الخاصة بالنظام",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],

    activeIndex: 0,
    tabAction: "next",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_USER_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_USER_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_USER_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_USER_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
