import React from "react";
import { Row } from "reactstrap";
import { renderPagination } from "../../utils/GeneralFunctions";
import ArchiveCard from "./ArchiveCard";
const ArchivesView = (props) => {
  const { archivesObj, general, lightbox, searchForm, getArchives } = props;

  const renderArchives = () => {
    if (archivesObj.archivesItem !== null) {
      return archivesObj.archivesItem.data.map((arch_item, index) => {
        return (
          <ArchiveCard
            arch_item={arch_item}
            key={index}
            lightbox={lightbox}
            general={general}
            {...props}
          />
        );
      });
    }
  };

  return (
    <>
      <Row>{renderArchives()}</Row>

      {renderPagination(archivesObj.archivesItem, searchForm, getArchives)}
    </>
  );
};

export default ArchivesView;
