export const visitTypeOptions = [
  { label: 'Exchange Battery', value: 0 },
  { label: 'Paper Roll', value: 1 },
  { label: 'Deployment', value: 2 },
  { label: 'Exchange POS', value: 3 },
  { label: 'Exchange sim Card (Vodafone)', value: 4 },
  { label: 'Cancellation without insurance ', value: 5 },
  { label: 'Cancellation with insurance ', value: 6 },
  { label: 'Activation', value: 7 },
  { label: 'Exchange sim Card (Orange)', value: 8 },
  { label: 'Exchange sim Card (Orange) + Paper Roll', value: 9 },
  { label: 'Exchange sim Card (Vodafone) + Paper Roll', value: 10 },
  { label: 'Branding (Door Sticker + Danglars )', value: 11 },
  { label: 'Visit & Update', value: 12 },
  { label: 'Technical issue & Visit', value: 13 },
  { label: 'APK Update', value: 14 },
  { label: 'FSA - Monthly visit', value: 15 },
];

export const Action_Types_Deployment = [
  {label:"_________________",value:0},

  {label:"Deployed",value:1},
  {label:"Postponed",value:2},
  {label:"Unreachable",value:3},
  {label:"Merchant Closed",value:4},
  {label:"Merchant Reject",value:5},
  {label:"Invalid - Data",value:6},
  {label:"Not Live",value:7},
  {label:"Already Done before",value:8},
  {label:"Not Qualified",value:9},
  {label:"Out of zone",value:10},
];

export const Action_Types_Cancellation = [
  {label:"_________________",value:0},

  {label:"Cancelled Now",value:1},
  {label:"Working Normally",value:2},
  {label:"New Activation",value:3},
  {label:"Docs",value:4},
  {label:"Balance",value:5},
  {label:"Insurance",value:6},
  {label:"Notice Period",value:7},
  {label:"Closed",value:8},
  {label:"Sales/DAM Reject",value:9},
  {label:"DAM Reject",value:10},
  {label:"Sales Reject",value:11},
  {label:"Didn\'t Receive It",value:12},
  {label:"Postponed",value:13},
  {label:"Stolen/Missing",value:14},
  {label:"Already Cancelled Before",value:15},
  {label:"Invalid - Data",value:16},
  {label:"Unreachable",value:17},
  {label:"Change Area",value:18},
  {label:"Change City",value:19},
  {label:"Seasonal Business",value:20},
  {label:"Merchant Closed", value:21}
]


export const Action_Types_Visit = [
  {label:"_________________",value:0},

  {label:"Done",value:1},
  {label:"Postponed",value:2},
  {label:"Unreachable",value:3},
  {label:"Merchant Closed",value:4},
  {label:"Merchant Reject",value:5},
  {label:"Invalid - Data",value:5},
  {label:"Already Done before",value:7}]


