import React, { Component } from "react";
import { Card, CardBody, Col, Row } from "reactstrap";
import Header from "../../routing/Layout/Header";
import { getUser } from "../../utils/GeneralFunctions";

const Sidebar = React.lazy(() => import("../../routing/Layout/Sidebar"));
class AboutUs extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { history } = this.props;
    return (
      <div className="page-wrapper" style={{ direction: "ltr" }}>
        {getUser() !== null ? (
          <>
            <Header history={history} />
            <div className="mt-4">
              <Sidebar />
            </div>
          </>
        ) : null}

        <Row className={getUser(history) === null ? "ml-3 mr-3 mb-3 mt-3" : ""}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Card
              className="mt-5"
              style={{
                borderRadius: "10px",
                border: "none",
              }}
            >
              <CardBody>
                <p
                  className="text-center mt-4"
                  style={{ textDecoration: "underline" }}
                >
                  Too locations privacy Policy
                </p>
                <div
                  className="mt-5"
                  style={{
                    fontSize: "10px",
                    lineHeight: "3em",
                    wordSpacing: "2px",
                    letterSpacing: "1px",
                  }}
                >
                  <ol>
                    <p> This policy is effective as of 2021-02-21</p>
                    <li style={{ fontSize: "12px" }}>Introduction</li>
                    <ul>
                      <li>
                        Al Tawasol Gulf Corporation built the Too Locations app
                        as a Free app its provide a brilliant Solutions for
                        (Security , Amana , Sales , Medical ) companies . This
                        SERVICE is provided by Al Tawasol Gulf Corporation at no
                        cost and is intended for use as is This page is used to
                        inform visitors regarding our policies with the
                        collection, use, and disclosure of Personal Information
                        if anyone decided to use our Service.
                      </li>
                      <li className="mt-2">
                        If you choose to use our Service, then you agree to the
                        collection and use of information in relation to this
                        policy. The Personal Information that we collect is used
                        for providing and improving the Service. We will not use
                        or share your information with anyone except as
                        described in this Privacy Policy.
                      </li>
                      <li className="mt-2">
                        The terms used in this Privacy Policy have the same
                        meanings as in our Terms and Conditions, which is
                        accessible at Too Locations unless otherwise defined in
                        this Privacy Policy.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Information Collection and Use
                    </li>
                    <ul>
                      <li className="mt-2">
                        For a better experience, while using our Service, we may
                        require you to provide us with certain personally
                        identifiable information, including but not limited to
                        Name, email, mobile , id number , location. The
                        information that we request will be retained by us and
                        used as described in this privacy policy.
                      </li>
                      <li className="mt-2">
                        The app does use third party services that may collect
                        information used to identify you.
                      </li>
                      <li className="mt-2">
                        Link to privacy policy of third party service providers
                        used by the app
                      </li>
                      <ol type="a">
                        <li>Google Play Services</li>
                        <li>Google Analytics for Firebase</li>
                        <li> Firebase Crashlytics</li>
                        <li> Facebook</li>
                        <li> Log Data</li>
                      </ol>
                      <li className="mt-2">
                        We want to inform you that whenever you use our Service,
                        in a case of an error in the app we collect data and
                        information (through third party products) on your phone
                        called Log Data. This Log Data may include information
                        such as your device Internet Protocol (“IP”) address,
                        device name, operating system version, the configuration
                        of the app when utilizing our Service, the time and date
                        of your use of the Service, and other statistics.
                        Cookies Cookies are files with a small amount of data
                        that are commonly used as anonymous unique identifiers.
                        These are sent to your browser from the websites that
                        you visit and are stored on your device's internal
                        memory. This Service does not use these “cookies”
                        explicitly. However, the app may use third party code
                        and libraries that use “cookies” to collect information
                        and improve their services. You have the option to
                        either accept or refuse these cookies and know when a
                        cookie is being sent to your device. If you choose to
                        refuse our cookies, you may not be able to use some
                        portions of this Service.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Service Providers
                    </li>
                    <ul>
                      <li className="mt-2">
                        We may employ third-party companies and individuals due
                        to the following reasons:
                      </li>
                      <ol type="a">
                        <li>To facilitate our Service</li>
                        <li>To provide the Service on our behalf</li>
                        <li>To perform Service-related services or</li>
                        <li>
                          To assist us in analyzing how our Service is used.
                        </li>
                      </ol>
                      <li className="mt-2">
                        We want to inform users of this Service that these third
                        parties have access to your Personal Information. The
                        reason is to perform the tasks assigned to them on our
                        behalf. However, they are obligated not to disclose or
                        use the information for any other purpose.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Location
                    </li>
                    <ul>
                      <li className="mt-2">
                        This app access user location when app used or or in
                        background , because the super visor must see your
                        location in time.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Security
                    </li>
                    <ul>
                      <li className="mt-2">
                        We value your trust in providing us your Personal
                        Information, thus we are striving to use commercially
                        acceptable means of protecting it. But remember that no
                        method of transmission over the internet, or method of
                        electronic storage is 100% secure and reliable, and we
                        cannot guarantee its absolute security.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Links to Other Sites
                    </li>
                    <ul>
                      <li className="mt-2">
                        This Service may contain links to other sites. If you
                        click on a third-party link, you will be directed to
                        that site. Note that these external sites are not
                        operated by us. Therefore, we strongly advise you to
                        review the Privacy Policy of these websites. We have no
                        control over and assume no responsibility for the
                        content, privacy policies, or practices of any
                        third-party sites or services.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Children’s Privacy
                    </li>
                    <ul>
                      <li className="mt-2">
                        These Services do not address anyone under the age of
                        13. We do not knowingly collect personally identifiable
                        information from children under 13 years of age. In the
                        case we discover that a child under 13 has provided us
                        with personal information, we immediately delete this
                        from our servers. If you are a parent or guardian and
                        you are aware that your child has provided us with
                        personal information, please contact us so that we will
                        be able to do necessary actions.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Changes to This Privacy Policy
                    </li>
                    <ul>
                      <li className="mt-2">
                        We may update our Privacy Policy from time to time.
                        Thus, you are advised to review this page periodically
                        for any changes. We will notify you of any changes by
                        posting the new Privacy Policy on this page.
                      </li>
                    </ul>
                    <li style={{ fontSize: "12px" }} className="mt-3">
                      Contact Us
                    </li>
                    <ul>
                      <li className="mt-2">
                        If you have any questions or suggestions about our
                        Privacy Policy, do not hesitate to contact us at
                        support@altawasol.app.
                      </li>
                    </ul>
                  </ol>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AboutUs;
