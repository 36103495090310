import React, { Component } from "react";
import Lightbox from "react-awesome-lightbox";

class LightBoxFile extends Component {
  prepareImage = () => {
    const { lightbox } = this.props;

    if (lightbox.image != null && lightbox.image !== undefined) {
      if (lightbox.image[0] instanceof File) {
        return [URL.createObjectURL(lightbox.image[0])];
      } else return lightbox.image;
    }
  };
  render() {
    const { general, action, lightbox } = this.props;

    return lightbox.isOpen === true ? (
      <Lightbox
        image={this.prepareImage()}
        onClose={() =>
          general(
            [{ prop: "lightbox", value: { isOpen: false, image: null } }],
            action
          )
        }
        allowZoom={true}
        keyboardInteraction={true}
      />
    ) : null;
  }
}

export default LightBoxFile;
