import { getLanguage } from "../../lang/local";
import {
  CLEAR_REPORT_TEMPLETE_STATE,
  UPDATE_REPORT_TEMPLETE_PROPS,
  MERGE_REPORT_TEMPLETE_PROPS,
  DELETE_REPORT_TEMPLETE_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  reportTempleteObj: {
    reportTempleteItem: null,
    isLoading: false,
    report_temp_col: [],
  },
  sorting: { column: "", order: "desc" },
  reportForm: {
    isLoading: false,
    formvalues: {
      work_field_id: null,
      template: [
        { key: "", name: "", type: "" },
        { key: "", name: "", type: "" },
        { key: "", name: "", type: "" },
      ],
    },
    index: null,
    company_id: null,
    branch_id: null,
  },
  reportTempleteOptions: [],
  templeteOptions: [
    { label: getLanguage() === "en" ? "text" : "نص", value: "text" },
    { label: getLanguage() === "en" ? "image" : "صورة", value: "image" },
    { label: getLanguage() === "en" ? "date" : "تاريخ", value: "date" },
  ],
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Report data" : "البيانات الخاصة بالتقرير",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title:
          getLanguage() === "en" ? "Contents of the report" : "محتويات التقرير",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],
    activeIndex: 0,
    tabAction: "next",
  },
  lightbox: { isOpen: false, image: null },

  searchForm: {
    company: null,
    branch_id: null,
    work_field_id: null,
    from: "",
    to: "",
    page: 1,
  },
  isLoadingSearch: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_REPORT_TEMPLETE_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_REPORT_TEMPLETE_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_REPORT_TEMPLETE_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_REPORT_TEMPLETE_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
