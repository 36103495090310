import React from 'react';
import { Col, Row, Spinner } from 'reactstrap';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { onSave } from './RequestsFunctions';
import { renderGeneralInput } from '../../utils/GeneralFunctions';
import { UPDATE_REQUESTS_PROPS } from '../../redux/actions/types';

const ThirdTab = (props) => {
  const {
    history,
    requestsObj,
    createORUpdatePoint,
    pointForm,
    general,
  } = props;

  return (
    <>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            'pointForm.formvalues.terminal_id',
            pointForm.formvalues.terminal_id,
            UPDATE_REQUESTS_PROPS,
            general,
            local.TerminalId,
            'number'
          )}
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            'pointForm.formvalues.merchant_id',
            pointForm.formvalues.merchant_id,
            UPDATE_REQUESTS_PROPS,
            general,
            local.MerchantId,
            'number'
          )}
        </Col>
      </Row>
      <center>
        <button
          type='submit'
          disabled={pointForm.isLoading}
          className='save_btn mt-3'
          onClick={(e) =>
            onSave(
              e,
              createORUpdatePoint,
              pointForm,
              requestsObj,
              true,
              history
            )
          }
        >
          {pointForm.isLoading === true ? (
            <Spinner type='grow' color='dark' size='sm' />
          ) : (
            <>
              <p style={{ display: 'inline' }}>{local.Save}</p>
              <img src={icon_btn} width='20' height='20' alt='img' />
            </>
          )}
        </button>
      </center>
    </>
  );
};
export default ThirdTab;
