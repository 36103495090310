import React, { Component } from "react";
import AsyncSelect from "react-select/async";
import { components } from "react-select";
import { FiSearch } from "react-icons/fi";
import { LatLngBounds } from "leaflet";

import { getLanguage, local } from "../lang/local";
import { selectStyleSearch } from "./GeneralStyle";
class SearchBox extends Component {
  filterOption = () => {
    if (this.props.searchOptions !== undefined) {
      if (this.props.searchOptions.length > 0) {
        return this.props.searchOptions.filter((i) => i.label.toLowerCase());
      }
    }
  };

  promiseOptions = () =>
    new Promise((resolve) => {
      setTimeout(() => {
        resolve(this.filterOption());
      }, 1000);
    });

  getSearchResult = (e) => {
    if (e === null) return null;
    const { endPoint, action } = this.props;
    endPoint(e, "searchOptions", action);
  };

  // getSearchResultDebounced = debounce(this.getSearchResult, 250);

  GetPlace = (e) => {
    const { general, action, map } = this.props;
    if (e !== null) {
      // general(
      //   [{ prop: "modale.mapItem.mapPoints", value: [e.value[1], e.value[0]] }],
      //   action
      // );
      if (e.data.bbox) {
        const boundBox = e.data.bbox;
        const firstPoint = boundBox.slice(0, 2);
        const secondPoint = boundBox.slice(2);
        const firstPointReversed = [firstPoint[1], firstPoint[0]];
        const secondPointReversed = [secondPoint[1], secondPoint[0]];
        const bounds = new LatLngBounds(
          firstPointReversed,
          secondPointReversed
        );
        map.fitBounds(bounds);
      } else {
        map.setCenter([e.value[1], e.value[0]], 15);
      }
    }
  };
  render() {
    const { searchOptions } = this.props;
    const DropdownIndicator = (props) => {
      return (
        components.DropdownIndicator && (
          <components.DropdownIndicator {...props}>
            <FiSearch style={{ color: "black" }} />
          </components.DropdownIndicator>
        )
      );
    };
    return (
      <AsyncSelect
        className="mt-2"
        components={{ DropdownIndicator }}
        styles={selectStyleSearch}
        defaultOptions={searchOptions}
        cacheOptions
        loadOptions={() => this.promiseOptions()}
        placeholder={local.SearchKeyword}
        onInputChange={this.getSearchResult}
        // onInputChange={this.getSearchResultDebounced}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isRtl={getLanguage() === "en" ? false : true}
        onChange={(e) => this.GetPlace(e)}
        isClearable={true}
      />
    );
  }
}

export default SearchBox;
