import React, { useEffect } from "react";
import { Col, Row, CardBody } from "reactstrap";
import { local } from "../../lang/local";
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from "../../redux/actions/types";
import {
  checkHasOneCompany,
  getAllWorkFields,
  getBranchOptions,
  handleSelectChangeEnhanced,
  renderArabicNameInput,
  renderEnglishNameInput,
  renderGeneralInput,
} from "../../utils/GeneralFunctions";
import { labelStyle } from "../../utils/GeneralStyle";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";

const FirstTab = (props) => {
  const {
    general,
    companyWfForm,
    companyOptions,
    loadCompaniesOptions,
    iscreate,
    loadBranchesOptions,
    branchOptions,
    setCompanyWorkFieldProps,
  } = props;
  return (
    <CardBody className="trans">
      {companyWfForm.formvalues.id !== undefined ? null : (
        <>
          {checkHasOneCompany() === false ? (
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <span className="label_title" style={labelStyle}>
                  {local.Company}
                </span>
                {renderCompanySelect(
                  "companyWfForm.company",
                  companyWfForm.company,
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  general,
                  local.Company,
                  loadCompaniesOptions,
                  companyOptions,
                  null,
                  [
                    { p: "companyWfForm.formvalues.branch_id", v: null },
                    { p: "companyWfForm.formvalues.parent_branch", v: null },
                  ],
                  "4",
                  iscreate
                )}
              </Col>
            </Row>
          ) : null}
          {/* <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <span className="label_title" style={labelStyle}>
                {local.Branch}
              </span>
              {renderBranchSelect(
                "companyWfForm.formvalues.branch_id",
                companyWfForm.formvalues.branch_id,
                UPDATE_COMPANY_WORK_FIELD_PROPS,
                general,
                local.Branch,
                loadBranchesOptions,
                branchOptions,
                companyWfForm.company,
                null,
                null,
                "4",
                companyWfForm.iscreate
                // {
                //   action: getAllWorkFields,
                //   action_create: setCompanyWorkFieldProps,
                //   prop: "allCompanyWorkFields",
                // }
              )}
            </Col>
          </Row> */}
          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomAsyncPaginate
                hasLabel
                placeholder={local.Regions}
                fullWidth
                loadOptions={getBranchOptions(companyWfForm.company, {
                  parent_id: 0,
                })}
                value={companyWfForm.formvalues.parent_branch}
                cacheUniqsArr={[companyWfForm.company]}
                onChange={(e) =>
                  handleSelectChangeEnhanced(
                    e,
                    "companyWfForm.formvalues.parent_branch",
                    setCompanyWorkFieldProps,
                    ["companyWfForm.formvalues.branch_id"]
                  )
                }
                additional={{
                  page: 1,
                }}
                isDisabled={!!!companyWfForm.company}
              />
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <CustomAsyncPaginate
                hasLabel
                placeholder={local.Cities}
                fullWidth
                loadOptions={getBranchOptions(companyWfForm.company, {
                  parent_id: companyWfForm.formvalues.parent_branch?.id,
                })}
                value={companyWfForm.formvalues.branch_id}
                cacheUniqsArr={[companyWfForm.formvalues.parent_branch]}
                onChange={(e) =>
                  handleSelectChangeEnhanced(
                    e,
                    "companyWfForm.formvalues.branch_id",
                    setCompanyWorkFieldProps,
                    []
                  )
                }
                additional={{
                  page: 1,
                }}
                isDisabled={!!!companyWfForm.formvalues.parent_branch}
              />
            </Col>
          </Row>
        </>
      )}
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "companyWfForm.formvalues.address",
            companyWfForm.formvalues.address,
            UPDATE_COMPANY_WORK_FIELD_PROPS,
            general,
            local.Address
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderEnglishNameInput(
            "companyWfForm.formvalues.name.en",
            companyWfForm.formvalues.name.en,
            UPDATE_COMPANY_WORK_FIELD_PROPS,
            general
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderArabicNameInput(
            "companyWfForm.formvalues.name.ar",
            companyWfForm.formvalues.name.ar,
            UPDATE_COMPANY_WORK_FIELD_PROPS,
            general
          )}
        </Col>
      </Row>
    </CardBody>
  );
};
export default FirstTab;
