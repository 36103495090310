import React from "react";
import { Container, Table, Row, Col, Button, Card } from "reactstrap";
import ModalView from "../../utils/ModalView";
import { local } from "../../lang/local";
import { UPDATE_COMPANY_PROPS } from "../../redux/actions/types";
import { renderDate, renderProfileRow } from "../../utils/GeneralFunctions";
import user_profile from "../../assets/img/user_profile.png";
import track_inactive from "../../assets/img/track_inactive.png";
import mobiles from "../../assets/img/mobiles.png";
import disk from "../../assets/img/disk.png";
const CompanyProfile = ({ modaleView, general }) => {
  const renderBody = () => {
    return (
      <div>
        {renderProfileRow(
          local.EnglishName,
          modaleView.data.name.en,
          user_profile,
          local.ArabicName,
          modaleView.data.name.ar,
          user_profile
        )}

        {renderProfileRow(
          local.Address,
          modaleView.data.address,
          track_inactive,
          local.Mobile,
          modaleView.data.mobile,
          mobiles
        )}

        {renderProfileRow(
          local.Identifier,
          modaleView.data.identifier,
          disk,
          null,
          null,
          null,
          null
        )}
      </div>
    );
  };

  const renderChildren = () => {
    if (modaleView.data !== null) {
      return (
        <div>
          {modaleView.data !== null ? (
            <>
              <Row>
                <Col xl={1}>
                  <p className="green_text">{local.Id}</p>
                </Col>
                <Col xl={1}>
                  <p className="blue_text">{modaleView.data.id}</p>
                </Col>
                <Col>
                  <Button className="sing_report_btn_blue">
                    <div>
                      <p style={{ display: "inline", fontSize: "10px" }}>
                        {renderDate(modaleView.data.created_at)}
                      </p>
                    </div>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xl={3} className="mt-4">
                  {modaleView.data.logo !== null ? (
                    <Card
                      style={{
                        border: "none",
                      }}
                    >
                      <a
                        onClick={() =>
                          general(
                            [
                              {
                                prop: "lightbox.image",
                                value: modaleView.data.logo,
                              },
                              { prop: "lightbox.isOpen", value: true },
                            ],
                            UPDATE_COMPANY_PROPS
                          )
                        }
                      >
                        <img
                          src={modaleView.data.logo}
                          alt="img"
                          style={{ width: "90%", height: "180px" }}
                          className="img-fluid rounded"
                        />
                      </a>
                    </Card>
                  ) : null}
                </Col>
                <Col>{renderBody()}</Col>
              </Row>

              <Row>
                <Col>
                  {modaleView.data.states !== undefined ? (
                    modaleView.data.states !== null ? (
                      <>
                        <p className="text-center mt-5">{local.Provinces}</p>

                        <Table
                          striped
                          responsive
                          className="table_list"
                          style={{
                            borderTopColor: "white",
                            border: "none",
                            position: "relative",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>{local.EnglishName}</th>
                              <th>{local.ArabicName}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {modaleView.data.states.map((st, i) => {
                              return (
                                <tr key={i}>
                                  <th>{st.name.en}</th>
                                  <th>{st.name.ar}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    ) : null
                  ) : null}
                </Col>

                <Col>
                  {modaleView.data.state_work_fields !== undefined ? (
                    modaleView.data.state_work_fields !== null ? (
                      <>
                        <p className="text-center mt-5">
                          {local.StateWorkFields}
                        </p>

                        <Table
                          striped
                          responsive
                          className="table_list"
                          style={{
                            borderTopColor: "white",
                            border: "none",
                            position: "relative",
                          }}
                        >
                          <thead>
                            <tr>
                              <th>{local.EnglishName}</th>
                              <th>{local.ArabicName}</th>
                              <th>{local.Address}</th>
                              <th>{local.Mobile}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {modaleView.data.state_work_fields.map((stw, i) => {
                              return (
                                <tr key={i}>
                                  <th>{stw.name.en}</th>
                                  <th>{stw.name.ar}</th>
                                  <th>{stw.address}</th>
                                  <th>{stw.mobile}</th>
                                </tr>
                              );
                            })}
                          </tbody>
                        </Table>
                      </>
                    ) : null
                  ) : null}
                </Col>
              </Row>
            </>
          ) : null}
        </div>
      );
    }
  };
  return (
    <ModalView
      children={renderChildren}
      modaleView={modaleView}
      general={general}
      action={UPDATE_COMPANY_PROPS}
    />
  );
};

export default CompanyProfile;
