import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ReportsView from './ReportsView';
import * as actions from '../../redux/actions/index';
import { kIsAuthorized, getMyCompany } from '../../utils/GeneralFunctions';
import ReportSearch from './ReportSearch';
import {
  CLEAR_REPORT_STATE,
  UPDATE_REPORT_PROPS,
} from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
class Reports extends Component {
  componentDidMount() {
    this.renderDailyReports();
  }

  renderDailyReports = () => {
    const { general,  } = this.props;
    general([], CLEAR_REPORT_STATE);
    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_REPORT_PROPS
    );
  };

  render() {
    const {
      history,
      general,
      reportObj,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getReports,
      getCompainesWFForDropDown,
      workerOptions,
      getWorkersForDropDown,
      loadCompaniesWFOptions,
      loadCompaniesOptions,
      loadWorkerOptions,
      loadBranchesOptions,
      branchOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_REPORT_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ReportSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getReports={getReports}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              general={general}
              workerOptions={workerOptions}
              getWorkersForDropDown={getWorkersForDropDown}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadCompaniesOptions={loadCompaniesOptions}
              loadWorkerOptions={loadWorkerOptions}
              loadBranchesOptions={loadBranchesOptions}
              branchOptions={branchOptions}
            />

            <ReportsView
              general={general}
              reportObj={reportObj}
              lightbox={lightbox}
              getReports={getReports}
              searchForm={searchForm}
              companyWfOptions={companyWfOptions}
              history={history}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  ReportR,
  CompanyWorkFieldR,
  CompanyR,
  WorkerR,
  BranchR,
}) => {
  return {
    reportObj: ReportR.reportObj,
    modale: ReportR.modale,
    lightbox: ReportR.lightbox,
    searchForm: ReportR.searchForm,
    isLoadingSearch: ReportR.isLoadingSearch,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    workerOptions: WorkerR.workerOptions,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(Reports);
