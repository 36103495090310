import { Request } from "../Api/index";
import { general } from "./index";
import {
  DELETE_VISITS_PROPS,
  UPDATE_VISITS_PROPS,
  CLEAR_STATE,
  UPDATE_REQUESTS_PROPS,
  MERGE_REQUESTS_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
  getCenterOfPolygon,
} from "../../utils/GeneralFunctions";

export const setVisitsProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for (let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1],
    });
  }

  dispatch({
    type: UPDATE_VISITS_PROPS,
    payload,
  });
};

export const clearVisitsProps = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};

export const getVisits = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_VISITS_PROPS,
    payload: [{ prop: "visitObj.visitItem", value: null }],
  });
  let url = "workers_points/";
  if (isSearching === true) {
    dispatch({
      type: UPDATE_VISITS_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_VISITS_PROPS,
      payload: [{ prop: "visitObj.isLoading", value: true }],
    });
  }

  if (searchForm !== null) {
    url += searchForm.company;
    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      worker_id: searchForm.worker_id,
      work_field_id: searchForm.work_field,
      page: searchForm.page,
      date: searchForm.date,
      to_date: searchForm.to_date,
      visit_type: searchForm.visitType,
    };
    let newSearchForm = removeUnsetValues(searchItems);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data) {
          dispatch({
            type: UPDATE_VISITS_PROPS,
            payload: [{ prop: "visitObj.visitItem", value: res.data.data }],
          });

          if (res.data.data.data.length > 0) {
            dispatch({
              type: UPDATE_VISITS_PROPS,
              payload: [
                {
                  prop: "visitObj.lat",
                  value: getCenterOfPolygon(
                    res.data.data.data[0].point.work_field.location
                  ).latitude,
                },
                {
                  prop: "visitObj.lng",
                  value: getCenterOfPolygon(
                    res.data.data.data[0].point.work_field.location
                  ).longitude,
                },
              ],
            });
          }
          if (isSearching === true && res.data.data.data.length === 0) {
            confirmSunccessed(local.NoSearch);
          }
        }
      }
      dispatch({
        type: UPDATE_VISITS_PROPS,
        payload: [
          { prop: "visitObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_VISITS_PROPS,
        payload: [
          { prop: "visitObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getVisitForDropDown = (name) => (dispatch) => {
  Request()
    .get("workers_points/")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: getLanguage() === "en" ? r.name.en : r.name.ar,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_VISITS_PROPS,
            payload: [{ prop: "visitsOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateVisit =
  (formValues, worker_id, index, isModal, workerName) => (dispatch) => {
    dispatch({
      type: UPDATE_VISITS_PROPS,
      payload: [{ prop: "visitForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues?.id === undefined) {
      url = "workers_points/store/" + worker_id;
    } else {
      url = "workers_points/update/" + formValues.id;
    }

    let newFormValues = Object.assign({}, formValues);
    let newPoints = [];

    if (newFormValues.points) {
      if (newFormValues.points.length > 0) {
        if (newFormValues.points[0]) {
          newFormValues.points.map((point) => {
            point.forEach((element) => {
              return newPoints.push({
                id: element.id,
                work_field_id: element.work_field_id,
                date: element.date,
                visit_type: element.visit_type,
                request_id: element.request_id,
                comment:element.comment
              });
            });
          });
        }
      }
      newFormValues.points = newPoints;
      newFormValues.date = newPoints[0].date;
      newFormValues.comment = newPoints[0].comment;
    }
    if (newFormValues.work_field_id) {
      if (newFormValues.work_field_id[0] !== null) {
        newFormValues.work_field_id = newFormValues.work_field_id.map(
          (wfp) => wfp.value
        );
      }
    }

    let finalForm = null;
    if (formValues.id !== undefined) {
      finalForm = {
        work_field_id: newFormValues.work_field_id[0],
        date: newFormValues.points[0].date,
        worker_id: worker_id,
        id: newFormValues.points[0].id,
        visit_type: newFormValues.points[0].visit_type,
      };
    } else {
      finalForm = newFormValues;
    }

    Request()
      .post(url, finalForm)
      .then((res) => {
        if (res.data.status === true) {
          dispatch(
            general(
              [
                {
                  prop: `requestsObj.requestsItem.data.${index}.status`,
                  value: "assigned",
                },
                {
                  prop: `requestsObj.requestsItem.data.${index}.worker.name`,
                  value: workerName,
                },
              ],
              UPDATE_REQUESTS_PROPS
            )
          );
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);
            window.location.reload("/addpoint")
            if (isModal === true) {
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);
            if (isModal === true) {
            }
          }
          // general(
          //   [
          //     {
          //       prop: `requestsObj.requestsItem.data.${index}.status`,
          //       value: "Done assign visit",
          //     },
          //   ],
          //   UPDATE_REQUESTS_PROPS
          // );
          dispatch({
            type: UPDATE_VISITS_PROPS,
            payload: [
              {
                prop: "visitForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    points: [null],
                    work_field_id: [null],
                  },
                  index: null,
                  company_id: null,
                  branch_id: null,
                  worker_id: null,
                  worker_type: null,
                  iscreate: "create",
                },
              },
              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],

                  mapItem: {
                    polygon: [],
                    mapPoints: [31.4177636, 30.00475],
                  },
                  activeIndex: 0,
                  tabAction: "next",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }

        dispatch({
          type: UPDATE_VISITS_PROPS,
          payload: [{ prop: "visitForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_VISITS_PROPS,
          payload: [{ prop: "visitForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getVisit = (id, dropDownItem, noParent) => (dispatch) => {
  Request()
    .get("workers_points/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_VISITS_PROPS,
              payload: [
                {
                  prop: "visitForm.formvalues",
                  value: res.data.data,
                },
              ],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const deleteVisit = (id, index) => (dispatch) => {
  Request()
    .get("workers_points/delete/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        confirmSunccessed(local.SuccessfulDeleted);

        dispatch({
          type: DELETE_VISITS_PROPS,
          payload: [
            {
              prop: "visitObj.visitItem.data." + index,
              value: res.data.data,
            },
          ],
        });
      } else {
        Object.values(res.data.error).map((msg) => {
          if (msg[0] === "messages.error you cant update point in past") {
            if (getLanguage() === "en") {
              confirmSunccessed(msg[0]);
            } else {
              confirmSunccessed("خطأ: لا يمكن تعديل نقطة فى الماضى");
            }
          } else {
            confirmSunccessed(msg[0]);
          }
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const changeVisitStatus =
  (visitId, visitStatus, visitIndex) => (dispatch) => {
    Request()
      .post(`workers_points/changeStatus/${visitId}`, { approved: visitStatus })
      .then((res) => {
        if (res.data.code === 200) {
          dispatch(
            setVisitsProps([
              [`visitObj.visitItem.data.${visitIndex}.approved`, visitStatus],
            ])
          );
        } else {
          toaster.notify(res.data.error.message[0], {
            duration: 2000,
          });
          console.log(res.data.error.message[0]);
        }
      })
      .catch((err) => console.log(err));
  };
