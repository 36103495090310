import React from "react";
import { connect } from "react-redux";
import { UPDATE_REQUESTS_PROPS } from "../../../../redux/actions/types";

import * as actions from "../../../../redux/actions/index";
import Input from "../../../../components/Input/Input";
import Dropdown from "../../../../components/AsyncPaginate/Dropdown";

import "./step.css";

const Requester = ({ general, pointForm }) => {
  return (
    <div className="grid grid-cols-1 lggrid-cols-2 mx-auto lg:w-2/3 gap-x-7 gap-y-12 mt-16 ">
      <Input
        label="Requester Name"
        // value={pointForm.formvalues.requester.name}
        // action={UPDATE_REQUESTS_PROPS}
        // prop="pointForm.formvalues.requester.name"
        // general={general}
      />
      <Input label="Email" />
      <Input label="Mobile" />
      {/* <Dropdown
        label="Requester Function"
        placeholder="Please Select (Department)"
        val
      />
      <Dropdown label="Requester City" placeholder="Please Select Your City" />
      <Dropdown
        label="Please Select Your HOD"
        placeholder="Please Select From Here"
      />
      <Dropdown
        label="Please Choose Your District Manager"
        placeholder="Please Select From Here"
      /> */}
    </div>
  );
};
const mapStateToProps = ({ RequestsR }) => {
  return {
    pointForm: RequestsR.pointForm.requester,
  };
};
export default connect(mapStateToProps, actions)(Requester);
