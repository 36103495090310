import { getRequest, Request, Select_Request } from "../Api/index";
import {
  MERGE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
  rmUnsetValsAndGetQryStr,
  getAsyncSelectOptions,
} from "../../utils/GeneralFunctions";

export const setCompanyWorkFieldProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for (let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1],
    });
  }

  await dispatch({
    type: UPDATE_COMPANY_WORK_FIELD_PROPS,
    payload,
  });
};

export const getCompanyWorkFieldOptions =
  (searchValue, loadedOptions, additional) => async (dispatch) => {
    let payload, hasMore;
    let workFieldOptions = [];

    const searchForm = {
      name: searchValue,
      page: additional.page,
    };

    try {
      await getRequest(
        `companies/${additional.branchId}/work_fields`,
        searchForm
      ).then((response) => {
        const optionsObject = getAsyncSelectOptions(
          response,
          "name",
          "id",
          true
        );
        workFieldOptions = optionsObject.options;
        hasMore = optionsObject.hasMore;
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setCompanyWorkFieldProps([
        ["companyWfOptions", [...loadedOptions, ...workFieldOptions]],
      ])
    );

    return {
      options: workFieldOptions,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
        branchId: additional.branchId,
      },
    };
  };

export const getCompainesWF = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_WORK_FIELD_PROPS,
    payload: [{ prop: "companyWfObj.companyWfItem", value: null }],
  });

  let url = "/companies/";

  if (searchForm !== null) {
    if (typeof searchForm.branch_id == "object") {
      url += searchForm.branch_id.id + "/work_fields";
    } else {
      url += searchForm.branch_id + "/work_fields";
    }

    let searchItems = {
      id: searchForm.id,
      from: searchForm.from,
      to: searchForm.to,
      mobile: searchForm.mobile,
      page: searchForm.page,
      name: searchForm.workFieldName,
    };
    let newSearchForm = removeUnsetValues(searchItems);
    let param = null;

    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      param = getQueryString(newSearchForm);
      url += param;
    }
  }

  if (isSearching === false) {
    dispatch({
      type: UPDATE_COMPANY_WORK_FIELD_PROPS,
      payload: [{ prop: "companyWfObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_COMPANY_WORK_FIELD_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }
  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_COMPANY_WORK_FIELD_PROPS,
            payload: [
              { prop: "companyWfObj.companyWfItem", value: res.data.data },
            ],
          });
        }

        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_COMPANY_WORK_FIELD_PROPS,
        payload: [
          { prop: "companyWfObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_COMPANY_WORK_FIELD_PROPS,
        payload: [
          { prop: "companyWfObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getCompainesWFForDropDown = (value, merge) => (dispatch) => {
  Request()
    .get("/companies/" + value + "/work_fields")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];

          if (merge === undefined) {
            res.data.data.data.map((r) => {
              result.push({
                label: getLanguage() === "en" ? r.name.en : r.name.ar,
                value: r.id,
                points: r.points,
                location: r.location,
              });
            });
            dispatch({
              type: UPDATE_COMPANY_WORK_FIELD_PROPS,
              payload: [{ prop: "companyWfOptions", value: result }],
            });
          } else {
            res.data.data.data.map((r) => {
              dispatch({
                type: MERGE_COMPANY_WORK_FIELD_PROPS,
                payload: [
                  {
                    prop: "companyWfOptions",
                    value: {
                      label: getLanguage() === "en" ? r.name.en : r.name.ar,
                      value: r.id,
                      points: r.points,
                      location: r.location,
                    },
                  },
                ],
              });
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateCompanyWF =
  (formValues, index, options, isModal) => (dispatch) => {
    dispatch({
      type: UPDATE_COMPANY_WORK_FIELD_PROPS,
      payload: [{ prop: "companyWfForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues.id === undefined) {
      url = "/companies/work_fields/store";
    } else {
      url = "/companies/work_fields/update/" + formValues.id;
    }

    if (typeof formValues.branch_id == 'object') {
      formValues.branch_id = formValues.branch_id.id
    }

    Request()
      .post(url, formValues)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            dispatch({
              type: UPDATE_COMPANY_WORK_FIELD_PROPS,
              payload: [
                {
                  prop: "companyWfObj.reloadDrawing",
                  value: true,
                },
              ],
            });

            confirmSunccessed(local.SuccessfullyAdded);

            if (index === null && options !== null && isModal === true) {
              // dispatch({
              //   type: MERGE_COMPANY_WORK_FIELD_PROPS,
              //   payload: [
              //     {
              //       prop: "companyWfObj.companyWfItem.data",
              //       value: res.data.data,
              //     },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyAdded);

            if (index !== null && options !== null && isModal === true) {
              dispatch({
                type: UPDATE_COMPANY_WORK_FIELD_PROPS,
                payload: [
                  {
                    prop: "companyWfObj.companyWfItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }
          dispatch({
            type: UPDATE_COMPANY_WORK_FIELD_PROPS,
            payload: [
              {
                prop: "companyWfForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    branch_id: null,
                    name: { ar: "", en: "" },
                    address: "",
                    location: null,
                  },
                  index: null,
                  iscreate: "create",
                  reloadDrawing: false,
                },
              },
              { prop: "companyWfOptions", value: [] },

              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                    {
                      title:
                        getLanguage() === "en" ? "Work Places" : "اماكن العمل",

                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],
                  activeIndex: 0,
                  tabAction: "next",

                  mapItem: {
                    polygon: [],
                    mapPoints: [31.4177636, 30.00475],
                  },
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }
        dispatch({
          type: UPDATE_COMPANY_WORK_FIELD_PROPS,
          payload: [{ prop: "companyWfForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_COMPANY_WORK_FIELD_PROPS,
          payload: [{ prop: "companyWfForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally(
        dispatch({
          type: UPDATE_COMPANY_WORK_FIELD_PROPS,
          payload: [
            {
              prop: "companyWfObj.reloadDrawing",
              value: false,
            },
          ],
        })
      );
  };

export const getCompanyWF = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("/companies/work_fields/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_COMPANY_WORK_FIELD_PROPS,
              payload: [
                {
                  prop: "companyWfForm.formvalues",
                  value: res.data.data,
                },
                {
                  prop: "companyWfForm.formvalues.company_id",
                  value: res.data.data.company.id,
                },
              ],
            });
          } else {
            let result = [
              {
                label:
                  getLanguage() === "en"
                    ? res.data.data.name.en
                    : res.data.data.name.ar,
                value: res.data.data.id,
              },
            ];

            dispatch({
              type: UPDATE_COMPANY_WORK_FIELD_PROPS,
              payload: [{ prop: "companyWfOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const loadCompaniesWFOptions =
  (name, loadedOptions, page, branch_id) => async (dispatch) => {
    let url = null;

    const searchForm = {
      name: name,
      page: page.page,
    };

    const searchParams = rmUnsetValsAndGetQryStr(searchForm);

    if (branch_id !== null) {
      url = `${Select_Request.url}/companies/${branch_id}/work_fields${searchParams}`;

      let response = await fetch(url, Select_Request.options);
      let responseOK = response && response.ok;
      if (responseOK) {
        let result = await response.json();

        let options = [];

        if (result.status === true) {
          if (result.data.data !== null) {
            result.data.data.map((d) => {
              options.push({
                label: getLanguage() === "en" ? d.name.en : d.name.ar,
                value: d.id,
                location: d.location,
                points: d.points,
                branch: d.branch.id,
              });
            });
          }

          // dispatch({
          //   type: UPDATE_COMPANY_WORK_FIELD_PROPS,
          //   payload: [{ prop: "companyWfOptions", value: options }],
          // });
        }

        let entry = {
          result: result.data.data,
          has_more:
            result.data.pagination.current_page ===
            result.data.pagination.total_pages
              ? false
              : true,
        };

        if (entry.has_more) {
          dispatch({
            type: UPDATE_COMPANY_WORK_FIELD_PROPS,
            payload: [{ prop: "companyWfOptions", value: loadedOptions }],
          });
        } else {
          dispatch({
            type: UPDATE_COMPANY_WORK_FIELD_PROPS,
            payload: [
              {
                prop: "companyWfOptions",
                value: [...loadedOptions, ...options],
              },
            ],
          });
        }

        return {
          options: options,
          hasMore: entry.has_more,
          additional: {
            page: page.page + 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };
