import { getLanguage } from "../../lang/local";
import React from "react";
import {
  CLEAR_REPORT_TEMPLETE_STATE,
  DELETE_REPORT_TEMPLETE_PROPS,
  MERGE_REPORT_TEMPLETE_PROPS,
  UPDATE_COMPANY_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_REPORT_TEMPLETE_PROPS,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import { local } from "../../lang/local";
import { FiPlus, FiXCircle } from "react-icons/fi";
import { Button, Input } from "reactstrap";
import { labelStyle, selectStyle3 } from "../../utils/GeneralStyle";
import { getDeafultItem } from "../../utils/GeneralFunctions";
import Select from "react-select";

export const unsetForm = (general) => {
  general(
    [
      {
        prop: "reportForm",
        value: {
          isLoading: false,
          formvalues: {
            work_field_id: null,
            template: [
              { key: "", name: "", type: "" },
              { key: "", name: "", type: "" },
              { key: "", name: "", type: "" },
            ],
          },
          index: null,
          company_id: null,
          branch_id: null,
        },
      },
      { prop: "reportOptions", value: [] },
      { prop: "lightbox", value: { isOpen: false, image: null } },
      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
          tabs_title: [
            {
              title:
                getLanguage() === "en"
                  ? "Report data"
                  : "البيانات الخاصة بالتقرير",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
            {
              title:
                getLanguage() === "en"
                  ? "Contents of the report"
                  : "محتويات التقرير",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
          ],
          activeIndex: 0,
          tabAction: "next",
        },
      },
    ],
    UPDATE_REPORT_TEMPLETE_PROPS
  );
};

export const validateInputs = (reportForm) => {
  if (
    reportForm.formvalues.template.find(
      (temp) => temp.name === "" || temp.type === ""
    )
  ) {
    toaster.notify(local.EnterData, {
      duration: 2000,
    });
  } else if (reportForm.formvalues.work_field_id === null) {
    toaster.notify(local.EnterData, {
      duration: 2000,
    });
  } else {
    return true;
  }
};

export const onSave = (
  e,
  createORUpdateReport,
  reportForm,
  reportTempleteObj,
  isModal
) => {
  e.preventDefault();
  if (validateInputs(reportForm) === true) {
    createORUpdateReport(
      reportForm.formvalues,
      reportForm.index,
      reportTempleteObj.reportTempleteItem,
      isModal
    );
  }
};

export const onAdd = (e, general) => {
  general(
    [
      {
        prop: "reportForm.formvalues.template",
        value: { key: "", name: "", type: "" },
      },
    ],
    MERGE_REPORT_TEMPLETE_PROPS
  );
};

export const rednerAddButton = (general) => {
  return (
    <Button
      onClick={(e) => onAdd(e, general)}
      style={{
        color: "#00beac",
        background: "white",
        borderColor: "#00beac",
        fontSize: "14px",
      }}
    >
      <FiPlus />
    </Button>
  );
};

export const renderTempleteInput = (prop, value, general) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {local.Item + " " + local.Name}
      </span>

      <Input
        placeholder={local.Item + " " + local.Name}
        className="inputStyle mt-4"
        value={value}
        onChange={(e) =>
          general(
            [
              {
                prop: prop,
                value: e.target.value,
              },
            ],
            UPDATE_REPORT_TEMPLETE_PROPS
          )
        }
      />
    </>
  );
};

export const renderRemoveButton = (index, general) => {
  return (
    <a
      style={{ cursor: "pointer" }}
      onClick={() =>
        general(
          [{ prop: "reportForm.formvalues.template." + index }],
          DELETE_REPORT_TEMPLETE_PROPS
        )
      }
    >
      <FiXCircle style={{ color: "#0cdbc7", fontSize: "17px" }} />
    </a>
  );
};

export const renderTempleteSelect = (
  prop,
  value,
  propKey,
  templeteOptions,
  general,
  index
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {local.Item + " " + local.Type}
      </span>
      <Select
        className="mt-4"
        placeholder={local.Item + " " + local.Type}
        styles={selectStyle3}
        isMulti={false}
        value={getDeafultItem(value, templeteOptions)}
        isClearable={false}
        onChange={(e) =>
          general(
            [
              {
                prop: prop,
                value: e.value,
              },
              {
                prop: propKey,
                value: e.value + index,
              },
            ],
            UPDATE_REPORT_TEMPLETE_PROPS
          )
        }
        options={templeteOptions}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
    </>
  );
};

export const unsetReportTempleteListData = (general) => {
  general([], CLEAR_REPORT_TEMPLETE_STATE);

  general(
    [
      {
        prop: "companyWfOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_WORK_FIELD_PROPS
  );
  general(
    [
      {
        prop: "companyOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};
