import React, { useState, useEffect } from "react";
import back_icon from "../../assets/img/logo_back.png";
import login_icon from "../../assets/img/logout.jpeg";
import logo from "../../assets/img/blue-logo.png";
// import notification from "../assets/img/notification.png";
import MENU from "../../assets/img/home-new-icons/menu.jpeg";
import avater from "../../assets/img/avater.png";
// import lang from "../../assets/img/lang.png";
import lang from "../../assets/img/languages.jpeg";
import { getLanguage, local, toogleLanguage } from "../../lang/local";
import { getUser } from "../../utils/GeneralFunctions";

const Header = (props) => {
  const {
    loginPage,
    displayMenuBtn = false,
    handleToggleSidebar,
    handleCollapsedChange,
    logout,
  } = props;

  const [authenticaed, setAuthenticated] = useState();

  useEffect(() => {
    setAuthenticated(localStorage.getItem("SERVICE_USER"));
  }, []);

  const handleMenuClick = (e) => {
    e.preventDefault();
    if (window.innerWidth > 760) {
      handleCollapsedChange();
    } else {
      handleToggleSidebar();
    }
  };

  const language = getLanguage();

  const logoStyles = {
    position: "absolute",
  };

  language === "en" ? (logoStyles.right = "20px") : (logoStyles.left = "10px");

  const changeLanguage = (e) => {
    e.preventDefault();

    getLanguage() === "en" ? toogleLanguage("ar") : toogleLanguage("en");
  };

  return (
    <>
      {authenticaed && (
        <nav
          className="navbar navbar-light flex justify-between relative" // uncomment this to get back the logo
          // className="navbar navbar-light"
          style={{
            background: "white",
            // boxShadow: '1px 1px 3px #003595',
            // width: "100%",
            direction: language === "en" ? "ltr" : "rtl",
          }}
        >
          <div
            className={`menuBtnDiv ${displayMenuBtn && "d-flex"}`}
            onClick={handleMenuClick}
          >
            <img src={MENU} width={25} alt="Menu Btn" />
            <div style={{ fontSize: "14px" }} className="mx-3">
              {local.Menu}
            </div>
          </div>
          <div className="absolute inset-1/2 -translate-x-1/2 rtl:translate-x-1/2 h-full w-[600px]">
            <img src={logo} alt="logo" />
          </div>
          <div className="flex">
            {getUser() !== null ? (
              <a className="navbar-brand" href onClick={logout}>
                <span className="login_word ">
                  {loginPage !== undefined ? (
                    <span style={{ fontSize: "14px" }}>
                      {local.LoginHeader}
                    </span>
                  ) : (
                    <span style={{ fontSize: "14px" }}>{local.Logout}</span>
                  )}
                  <img
                    src={login_icon}
                    alt=""
                    width="25"
                    height="25"
                    className="header_content_img block mx-auto"
                  />
                </span>
              </a>
            ) : null}
            <a className="navbar-brand" href onClick={(e) => changeLanguage(e)}>
              <span className="login_word ">
                <span style={{ fontSize: "14px" }}>
                  {getLanguage() === "en" ? local.Arabic : local.English}
                </span>
                <img
                  src={lang}
                  alt=""
                  width="25"
                  height="25"
                  className="header_content_img block mx-auto"
                />
              </span>
            </a>
            {loginPage === undefined ? (
              <>
                {/* <a className="navbar-brand" href="#" id="bill">
              <span className="login_word">
                <img src={notification} width="13" height="13" />
              </span>
            </a> */}
                <a className="navbar-brand" href id="user_icon">
                  <span className="login_word">
                    <div
                      style={{
                        display: "inline-block",
                      }}
                    >
                      <span style={{ fontSize: "14px", display: "block" }}>
                        {getUser() !== null ? getUser().name : "No Name"}
                      </span>
                    </div>

                    <img
                      src={avater}
                      alt=""
                      width="29"
                      height="29"
                      className="header_content_img block mx-auto"
                    />
                  </span>
                </a>
              </>
            ) : null}
          </div>

          {/* <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav mr-auto'></ul>

          <form style={logoStyles} className='form-inline my-2 my-lg-0 ml-3'>
            <img
              className='icon_app'
              alt=''
              src={back_icon}
              width='90'
              height='90'
            />
          </form>
        </div> */}
        </nav>
      )}
    </>
  );
};

export default Header;
