import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderDate,
  renderPagination,
  renderEditButton,
  getPermissionValue,
  renderCreateButton,
  renderEditTitle,
} from '../../utils/GeneralFunctions';
import { UPDATE_USER_PROPS } from '../../redux/actions/types';
import { renderMyPermissionInCreate } from './UserFunctions';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { leftlStyle } from '../../utils/GeneralStyle';

const UsersTable = (props) => {
  const { userObj, general, sorting, searchForm, history, getUsers } = props;

  const onEdit = (e, item, index) => {
    e.preventDefault();

    let permissions = renderMyPermissionInCreate(history);

    let newCompanies = [];
    let newBranches = [];
    let newWorkfields = [];
    console.log('item', item);
    item.companies.map((comp) => {
      newCompanies.push({
        label: comp.name || comp.label,
        value: comp.id || comp.value,
      });
    });

    if (item.branches.length > 0) {
      item.branches.map((bra) => {
        newBranches.push({
          label: bra.name,
          value: bra.id,
          company_id: bra.company_id,
        });
      });
      general(
        [
          {
            prop: 'userForm.arrayOptions.branches',
            value: newBranches.length > 0 ? newBranches : null,
          },
        ],
        UPDATE_USER_PROPS
      );
    }

    if (item.work_fields.length > 0) {
      const branchesHashMap = {};
      for (let i = 0; i < item.branches.length; i++) {
        branchesHashMap[item.branches[i].id] = i;
      }
      console.log('brances', item.branches);
      console.log('branchesHashMap', branchesHashMap);
      item.work_fields.map((wf) => {
        newWorkfields.push({
          label: wf.name,
          value: wf.id,
          branch: wf.branch_id,
          index: branchesHashMap[wf.branch_id],
        });
      });

      general(
        [
          {
            prop: 'userForm.arrayOptions.work_fields',
            value: newWorkfields.length > 0 ? newWorkfields : null,
          },
        ],
        UPDATE_USER_PROPS
      );
    }

    item.companies = newCompanies;

    permissions.map((per) => {
      if (item.permissions.find((p) => p.permission === per.permission)) {
        per.isSelected = true;
      } else {
        per.isSelected = false;
      }
    });

    // general(
    //   [{ prop: "companyOptions", value: item.companies }],
    //   UPDATE_COMPANY_PROPS
    // );

    general(
      [
        { prop: 'userForm.formvalues', value: item },
        { prop: 'userForm.formvalues.companies', value: null },
        { prop: 'userForm.formvalues.branches', value: null },
        { prop: 'userForm.formvalues.work_fields', value: null },
        { prop: 'userForm.isLoading', value: false },
        { prop: 'permissionsOptions', value: permissions },
        { prop: 'userForm.arrayOptions.companies', value: item.companies },
        { prop: 'userForm.index', value: index },
        { prop: 'modale.actionType', value: 'EditSupervisor' },
        { prop: 'modale.title', value: local.updateSupervisor },
        { prop: 'userForm.iscreate', value: 'update' },
        { prop: 'modale.isOpen', value: true },
      ],
      UPDATE_USER_PROPS
    );
  };

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: 'modale.title', value: local.AddUser },
        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddUser' },
        {
          prop: 'permissionsOptions',
          value: renderMyPermissionInCreate(history),
        },
      ],
      UPDATE_USER_PROPS
    );
  };

  const renderPermission = (permissions) => {
    if (permissions !== null) {
      if (permissions.length > 0) {
        return permissions.map((per, index) => {
          if (index === 0) {
            return local[per.permission] + ' | ';
          } else if (index === permissions.length - 1) {
            return local[per.permission];
          } else {
            let item = local[per.permission];
            // if (item === undefined) {
            //   debugger;
            // }

            return local[per.permission] + ' | ';
          }
        });
      }
    }
  };

  const renderBody = () => {
    if (userObj.userItem !== null) {
      return userObj.userItem.data.map((user, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(user.id)}</td>
            <td className='td_table'>{user.name}</td>
            <td className='td_table'>{user.email}</td>

            <td className='td_table'>{renderPermission(user.permissions)}</td>
            <td className='td_table'>{renderDate(user.created_at)}</td>

            {renderEditButton(
              getPermissionValue()['users/update/{id}'],
              onEdit,
              user,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      {renderCreateButton(
        getPermissionValue()['users/store'],
        onCreate,
        local.AddUser
      )}

      <Row>
        <Col xl={12}>
          <Table
            id='user_Table'
            responsive
            hover
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  userObj.userItem,
                  'id',
                  'userObj.userItem.data',
                  UPDATE_USER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Name,
                  userObj.userItem,
                  'name',
                  'userObj.userItem.data',
                  UPDATE_USER_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Email,
                  userObj.userItem,
                  'email',
                  'userObj.userItem.data',
                  UPDATE_USER_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Permissions,
                  userObj.userItem,
                  'address',
                  'userObj.userItem.data',
                  UPDATE_USER_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.DateAdded,
                  userObj.userItem,
                  'created_at',
                  'userObj.userItem.data',
                  UPDATE_USER_PROPS,
                  general,
                  sorting
                )}

                {renderEditTitle('users/update/{id}')}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {userObj.userItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='user_Table'
              filename={local.Users}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {userObj.userItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}

      {renderPagination(userObj.userItem, searchForm, getUsers)}
    </Card>
  );
};

export default UsersTable;
