import {
  CLEAR_REPORT_STATE,
  UPDATE_REPORT_PROPS,
  MERGE_REPORT_PROPS,
  DELETE_REPORT_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  reportObj: { reportItem: null, isLoading: false, single_report: null },

  lightbox: { isOpen: false, image: null },

  searchForm: {
    company: null,
    branch_id: null,
    ids: null,
    worker_id: null,
    from: "",
    to: "",
    page: 1,
  },
  isLoadingSearch: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_REPORT_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_REPORT_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_REPORT_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_REPORT_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
