import React from 'react';
import { connect } from 'react-redux';
import {
  Card,
  Row,
  Col,
  Table as ReactStrapTable,
  Badge,
  Button,
} from 'reactstrap';
import { Table } from 'antd';
import { getLanguage, local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderPagination,
  getDeafultItem,
  renderEditButton,
  getPermissionValue,
  renderCreateButton,
  renderEditTitle,
  onOpenProfile,
  renderAntdColumn,
} from '../../utils/GeneralFunctions';
import {
  DELETE_REQUESTS_PROPS,
  UPDATE_REQUESTS_PROPS,
  UPDATE_VISITS_PROPS,
} from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import PointProfile from './PointProfile';
import { leftlStyle } from '../../utils/GeneralStyle';
import { placeTypeOptions } from '../../utils/placeTypeOptions';

const RequestsTable = (props) => {
  const {
    requestsObj,
    general,
    sorting,
    searchForm,
    getRequests,
    companyOptions,
    modaleView,
    modale,
    map,
    history,
  } = props;

  const onEdit = (e, item, index) => {
    e.preventDefault();

    let company = getDeafultItem(searchForm.company, companyOptions);
    let lat = item.location.split('(')[1].split(')')[0].split(' ')[0];
    let lng = item.location.split('(')[1].split(')')[0].split(' ')[1];
    debugger;

    general(
      [{ prop: 'polygonItem', value: item.work_field.location }],
      UPDATE_REQUESTS_PROPS
    );

    if (company !== undefined) {
      if (company !== null) {
        general(
          [{ prop: 'pointForm.company', value: company.value }],
          UPDATE_REQUESTS_PROPS
        );
      }
    }

    const pointEmployees = item.workers.map((worker) => ({
      ...worker,
      label: worker.name,
      value: worker.id,
    }));
    let newItem = requestsObj.coordinates.find((e) => e.id === item.id);

    general(
      [
        // { prop: 'pointForm.formvalues', value: item },
        // { prop: 'pointForm.formvalues.workers', value: pointEmployees },

        {
          prop: 'pointForm.formvalues.work_field_id',
          value: +item.work_field_id,
        },

        {
          prop: 'pointForm.formvalues',
          value: {
            id: item.id,
            work_field_id: +item.work_field_id,
            location: item.location,
            terminal_id: newItem.terminal_id ? +newItem.terminal_id : null,
            place_type: +newItem.place_type,
            merchant_id: newItem.merchant_id ? +newItem.merchant_id : null,
            name: newItem.name,
            workers: pointEmployees,
            need_visit: newItem.need_visit,
            properties: {
              type: +newItem.properties.type,
              address: newItem.properties.address,
              point_radius: newItem.properties.point_radius,
              mobiles: newItem.properties.mobiles,
              link: '',
            },
          },
        },

        { prop: 'modale.mapItem.mapPoints', value: [lng, lat] },

        { prop: 'pointForm.isLoading', value: false },
        { prop: 'pointForm.index', value: index },
        { prop: 'modale.actionType', value: 'EditCompanyWFPoint' },
        {
          prop: 'pointForm.iscreate',
          value: 'update',
        },
        { prop: 'modale.title', value: local.UpdateCompanyWFPoint },

        { prop: 'modale.isOpen', value: true },
      ],
      UPDATE_REQUESTS_PROPS
    );
  };

  const onCreate = (e) => {
    e.preventDefault();

    general([{ prop: 'modale.polygonItem' }], DELETE_REQUESTS_PROPS);
    general(
      [
        { prop: 'modale.title', value: local.AddCompanyWFPoint },

        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddCompanyWFPoint' },
      ],
      UPDATE_REQUESTS_PROPS
    );
  };

  const onViewLocation = (e, item) => {
    e.preventDefault();

    let lat = item.location.split('(')[1].split(')')[0].split(' ')[1];
    let lng = item.location.split('(')[1].split(')')[0].split(' ')[0];
    // general(
    //   [
    //     { prop: "requestsObj.lat", value: lat },

    //     { prop: "requestsObj.lng", value: lng },
    //   ],
    //   UPDATE_REQUESTS_PROPS
    // );

    // setMapProps([["zoom", 22]]);

    // setMapProps([
    //   ["infoWindowIsShown", true],
    //   ["zoom", 20],
    //   ["selectedPlace", item],
    //   ["mapCenter", { lat, lng }],
    // ]);

    // map.zoomToFeatures([{ id: item.id, type: ZoomableShapes.marker }]);
    // map.setZoom(18);
    map.setCenter([lat, lng], 18);

    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });

    map.markers[item.id].shape.openPopup();
  };

  const renderTable = () => {
    if (requestsObj.requestsItem !== null) {
      let tableData = [];
      if (requestsObj.requestsItem.data) {
        const pointData = requestsObj.requestsItem.data;
        for (let i = 0; i < pointData.length; i++) {
          const currentPoint = pointData[i].point;
          const pointEmployees = currentPoint.workers;
          currentPoint.visit_type = pointData[i].visit_type;

          tableData.push({
            key: i + 1,
            item: currentPoint,
            id: renderNumber(currentPoint.id),
            comment: pointData[i].comment,
            status: pointData[i].status,
            request_id: pointData[i].id,
            name: currentPoint.name,
            work_field: currentPoint.work_field.name[getLanguage()],
            pointEmployees,
            address: currentPoint.properties.address,
            location: (
              <a
                href
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={(e) => onViewLocation(e, currentPoint)}
              >
                {local.View}
              </a>
            ),
            // edit: renderEditButton(
            //   getPermissionValue()['companies/work_fields/points/update/{id}'],
            //   onEdit,
            //   currentPoint,
            //   i
            // ),
          });
        }
      }
      return tableData;
    }
  };

  const renderPointName = (pointName, point, index) => {
    return (
      <div
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() => {
          onOpenProfile(
            point.item,
            local.WorkFieldName,
            general,
            UPDATE_REQUESTS_PROPS,
            true,
            'companies/work_fields/points/show/{id}'
          );
        }}
      >
        {pointName}
      </div>
    );
  };

  const renderPointEmployees = (pointEmployees) => {
    if (pointEmployees.length === 0) return '-';
    const employeesDiv = [];
    for (let i = 0; i < pointEmployees.length; i++) {
      const currentEmployee = pointEmployees[i];
      employeesDiv.push(<div>{currentEmployee.name}</div>);
    }
    return employeesDiv;
  };

  const renderVisitStatus = (status) => {
    return (
      <div className='center need_visit_container'>
        {local[status] ? local[status] : status}
      </div>
    );
    // return status === 'pending' ? (
    //   <div className='center need_visit_container'>
    //     <Badge color='light' href='#'>
    //       {local.Yes}
    //     </Badge>
    //   </div>
    // ) : (
    //   <div className='center no_need_visit_container'>
    //     <Badge color='light' href='#'>
    //       {local.No}
    //     </Badge>
    //   </div>
    // );
  };

  const renderPlaceType = (place_type, point) => {
    return (
      <div>
        {getDeafultItem(point.item['place_type'], placeTypeOptions)?.label}
      </div>
    );
  };

  const handleCreateVisit = (e, request_id, item) => {
    e.preventDefault();
    item.date = null;
    item.request_id = request_id;
    item.label = item.name;
    item.value = item.id;
    general(
      [
        {
          prop: 'visitForm.company_id',
          value: searchForm.company ? searchForm.company : null,
        },

        {
          prop: 'visitForm.branch_id',
          value: searchForm.branch_id ? searchForm.branch_id : null,
        },

        {
          prop: 'visitForm.formvalues.points',
          value: [[item]],
        },
        {
          prop: 'visitForm.formvalues.work_field_id',
          value: [
            {
              label: item.work_field.name[local.lang],
              value: item.work_field.id,
              location: item.work_field.location,
            },
          ],
        },
      ],
      UPDATE_VISITS_PROPS
    );
    history.push('/dashboard/add_visit');
  };

  const renderVisitButton = (request_id, entry) => {
    if (getPermissionValue()['workers_points/store/{id}']) {
      return (
        <div className='center'>
          <Button
            color='light'
            onClick={(e) => handleCreateVisit(e, request_id, entry.item)}
            className='create_visit_btn'
          >
            {local.CreateVisit}
          </Button>
        </div>
      );
    } else return null;
  };

  const renderAddOrderVisit = (id, entry) => {
    if (getPermissionValue()['requests/store']) {
      return (
        <div className='center'>
          <Button
            color='light'
            onClick={(e) => handleAddOrderVisit(e, entry.item)}
            className='create_visit_btn' 
            style={{backgroundColor:"yellow", color:"black"}}
          >
            {local.AddOrderVisit}
          </Button>
        </div>
      );
    } else return null;
  };

  const handleAddOrderVisit = (e, item) => {
    e.preventDefault();
    general([{ prop: 'modale.polygonItem' }], DELETE_REQUESTS_PROPS);

    general(
      [{ prop: 'requestForm.formvalues.point_id', value: item.id }],
      UPDATE_REQUESTS_PROPS
    );

    general(
      [
        { prop: 'modale.title', value: local.AddOrderVisit },

        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddOrderVisit' },
      ],
      UPDATE_REQUESTS_PROPS
    );
  };
  const columns = [
    renderAntdColumn(local.Id, 'id'),
    renderAntdColumn(local.PlaceType, 'place_type', renderPlaceType),
    renderAntdColumn(local.WorkFieldName, 'name', renderPointName),

    renderAntdColumn(local.Employees, 'pointEmployees', renderPointEmployees),
    renderAntdColumn(local.WorkField, 'work_field'),
    renderAntdColumn(local.OrderStatus, 'status', renderVisitStatus),
    renderAntdColumn(local.Comment, 'comment'),

    renderAntdColumn(local.Address, 'properties.address'),
    renderAntdColumn(local.PointOnMap, 'location', null, false),
    renderAntdColumn(local.AddVisit, 'request_id', renderVisitButton),
    renderAntdColumn(local.AddOrderVisit, 'id', renderAddOrderVisit),

    // renderAntdColumn(local.Edit, 'edit', null, false),
  ];

  const renderBody = () => {
    if (requestsObj.requestsItem !== null) {
      return requestsObj?.requestsItem?.data?.map((companyWf, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(companyWf.id)}</td>

            <td
              className='td_table'
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                onOpenProfile(
                  companyWf,
                  local.WorkFieldName,
                  general,
                  UPDATE_REQUESTS_PROPS,
                  true,
                  'companies/work_fields/points/show/{id}'
                )
              }
            >
              {companyWf.name}
            </td>

            <td className='td_table'>
              {getLanguage() === 'en'
                ? companyWf?.work_field?.name.en
                : companyWf?.work_field?.name.ar}
            </td>

            <td className='td_table'>
              {companyWf.point.properties.address !== undefined
                ? companyWf.point.properties.address !== ''
                  ? companyWf.point.properties.address
                  : null
                : null}
            </td>

            <td className='td_table'>
              <a
                href
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={(e) => onViewLocation(e, companyWf)}
              >
                {local.View}
              </a>
            </td>

            {renderEditButton(
              getPermissionValue()['companies/work_fields/points/update/{id}'],
              onEdit,
              companyWf,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <PointProfile
        modaleView={modaleView}
        general={general}
        searchForm={searchForm}
        modale={modale}
      />
      {renderCreateButton(
        getPermissionValue()['companies/work_fields/points/store'],
        onCreate,
        local.AddCompanyWFPoint
      )}

      <Row>
        <Col xl={12}>
          {/* This table is hidden but it exists only to download as Excel file */}
          <ReactStrapTable
            id='company_work_field_point'
            responsive
            hover
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
              display: 'none',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  requestsObj.requestsItem,
                  'id',
                  'requestsObj.requestsItem.data',
                  UPDATE_REQUESTS_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.WorkFieldType,
                  requestsObj.requestsItem,
                  'place_type',
                  'requestsObj.requestsItem.data',
                  UPDATE_REQUESTS_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.WorkFieldName,
                  requestsObj.requestsItem,
                  'name',
                  'requestsObj.requestsItem.data',
                  UPDATE_REQUESTS_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.NeedVisit,
                  requestsObj.requestsItem,
                  'need_visit',
                  'requestsObj.requestsItem.data',
                  UPDATE_REQUESTS_PROPS,
                  general,
                  sorting
                )}

                <th className='td_table'>{local.WorkField}</th>

                {renderTableHeadItem(
                  local.WorkFieldAddress,
                  requestsObj.requestsItem,
                  'properties.address',
                  'requestsObj.requestsItem.data',
                  UPDATE_REQUESTS_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.ViewonMap,
                  requestsObj.requestsItem,
                  'location',
                  'requestsObj.requestsItem.data',
                  UPDATE_REQUESTS_PROPS,
                  general,
                  sorting
                )}

                {renderEditTitle('companies/work_fields/points/update/{id}')}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </ReactStrapTable>
          <Table
            dataSource={renderTable() && renderTable()}
            // scroll={{ x: 'calc(800px + 50%)', y: 600 }}
            pagination={{
              pageSize:
                requestsObj.requestsItem &&
                +requestsObj.requestsItem.pagination?.per_page,
              position: ['none'],
            }}
            size='small'
            columns={columns}
          >
            {/* <Column title={local.Id} dataIndex="id" key="id" />
          <Column
            title={local.WorkFieldType}
            dataIndex="place_type"
            key="place_type"
            sorter={(a, b, sortOrder) =>
              antdSorter(a, b, sortOrder, "place_type")
            }
          />
          // <AntdCustomColumn title={local.WorkFieldType} prop="place_type" />
          <Column title={local.WorkFieldName} dataIndex="name" key="name" />
       
          <Column
            title={local.WorkField}
            dataIndex="work_field"
            key="work_field"
          />
          <Column
            title={local.WorkFieldAddress}
            dataIndex="address"
            key="address"
          />
          <Column title={local.ViewonMap} dataIndex="location" key="location" />
          <Column title={local.WorkFieldName} dataIndex="name" key="name" />
          <Column title={local.Edit} dataIndex="edit" key="edit" /> */}
          </Table>
        </Col>
      </Row>

      {requestsObj.requestsItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='company_work_field_point'
              filename={local.CompaniesPoint}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {requestsObj.requestsItem.pagination?.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}

      {renderPagination(requestsObj.requestsItem, searchForm, getRequests)}
    </Card>
  );
};

export default connect(null)(RequestsTable);
