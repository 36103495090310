import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "reactstrap";
import { Marker } from "google-maps-react";
import { getLanguage } from "../../lang/local";
import { renderGooglePolygon, isArray } from "../../utils/GeneralFunctions";
import marker from "../../assets/img/marker.svg";
import PERSON_PIN from "../../assets/img/MapPin/person-pin.png";
import config from "../../config.json";
import { UPDATE_LIVE_TRACKING_PROPS } from "../../redux/actions/types";
import GenericMapy from "../../components/GenericMapy/GenericMapy";

const TrackingMapy = (props) => {
  const {
    trackingObj,
    sidebarData,
    general,
    setLiveTrackingProps,
    map,
    workers,
    setWorkers,
    socketMessage,
    setSocketMessage,
    MapReducer,
    history,
  } = props;

  const { mapBounds } = MapReducer;

  const [showingInfoWindow, setShowInfo] = useState(true);
  const [activeMarker, setActiveMarker] = useState({});
  const [selectedPlace, setSelectedPlace] = useState({});
  // const [workers, setWorkers] = useState([]);

  useEffect(() => {
    if (workers.length === 0 && trackingObj.trackingWorkers !== null) {
      getWorkerLocationWithSocket();
      setWorkers(trackingObj.trackingWorkers);
    }
  }, [trackingObj.trackingWorkers, workers]);

  useEffect(() => {
    if (trackingObj.trackingWorkers) {
      getWorkerLocationWithSocket();
    }
  }, [socketMessage]);

  const onMarkerClick = (p, marker, e) => {
    setShowInfo(true);
    setActiveMarker(marker);
    setSelectedPlace(p);
  };

  const getWorkerLocationWithSocket = () => {
    let channles = null;
    if (trackingObj.trackingWorkers.length > 0) {
      let filteredWorkers = trackingObj.trackingWorkers.filter(
        (f) => f.work_field_id !== null && f.work_field_id !== 0
      );
      filteredWorkers.map((w, i) => {
        if (i === 0) {
          channles = w.channel;
        } else {
          channles += "," + w.channel;
        }
      });

      // var socketMessage = new WebSocket(config.socket_url + channles);

      // if (!socketMessage) {
      //   setSocketMessage(new WebSocket(config.socket_url + channles));
      // }

      socketMessage?.addEventListener("open", function (event) {
        console.log("done connect to socket of workers");
      });

      socketMessage?.addEventListener("message", function (event) {
        let data = JSON.parse(event.data);
        console.log(data);

        if (history.location.pathname === "/live_tracking") {
          if (data.type === "Feature") {
            if (data.properties.battery === 200) {
              // BATTERY === 200 MEANS THAT THE EMPLOYEE HAS ENDED HIS DAY
              clearWorkerLocation(data.properties.id, "ID");
            } else {
              updateWorkerLocation(
                data.geometry.coordinates[1],
                data.geometry.coordinates[0],
                data.properties
              );
            }
          } else if (data.type === "session-ended") {
            clearWorkerLocation(data.dists[0]);
          }
        }

        // let trackingWorkersSortedArr = trackingObj.trackingWorkers.sort(
        //   (a, b) => {
        //     if (a.new_location) {
        //       return -1;
        //     } else return 1;
        //   }
        // );

        // console.log("sorted array ", trackingWorkersSortedArr);
        // setLiveTrackingProps([
        //   ["trackingObj.trackingWorkers", trackingWorkersSortedArr],
        // ]);
      });

      socketMessage?.addEventListener("close", function (event) {
        console.log("socket closed");
        setTimeout(() => {
          setWorkers([]);
          getWorkerLocationWithSocket();
        }, 1000);
      });

      socketMessage?.addEventListener("error", function (err) {
        console.error(
          "Socket encountered error: ",
          err.message,
          "Closing socket"
        );
        socketMessage.close();
      });
    }
  };

  const updateWorkerLocation = (lat, lng, properties) => {
    let newWorkers = [...trackingObj.trackingWorkers];
    let index = newWorkers.indexOf(
      newWorkers.find((w) => w.id === properties?.id)
    );

    if (index !== null) {
      let point = "POINT(" + lng + " " + lat + ")";

      general(
        [
          {
            prop: "trackingObj.trackingWorkers." + index + ".new_location",
            value: "POINT(" + lng + " " + lat + ")",
          },
          {
            prop: "trackingObj.trackingWorkers." + index + ".has_location",
            value: "POINT(" + lng + " " + lat + ")",
          },
          {
            prop: "trackingObj.trackingWorkers." + index + ".featureProperties",
            value: properties,
          },
        ],
        UPDATE_LIVE_TRACKING_PROPS
      );

      newWorkers[index].new_location = point;
      newWorkers[index].has_location = point;

      newWorkers[index].featureProperties = properties;

      setWorkers(newWorkers);
    }
  };

  const clearWorkerLocation = (identifier, type = "channel") => {
    let newWorkers = [...trackingObj.trackingWorkers];
    // let index = newWorkers.findIndex((w) => w.channel === identifier);
    let index;
    if (type === "ID") index = newWorkers.findIndex((w) => w.id === identifier);
    else index = newWorkers.findIndex((w) => w.channel === identifier);

    if (index !== -1) {
      general(
        [
          {
            prop: "trackingObj.trackingWorkers." + index + ".new_location",
            value: null,
          },
          {
            prop: "trackingObj.trackingWorkers." + index + ".has_location",
            value: undefined,
          },
        ],
        UPDATE_LIVE_TRACKING_PROPS
      );

      newWorkers[index].new_location = null;
      newWorkers[index].has_location = undefined;

      delete newWorkers[index].featureProperties;

      setWorkers(newWorkers);
    }
  };

  // const clearWorkerLocation = (workerId) => {
  //   let newWorkers = [...trackingObj.trackingWorkers];
  //   let index = newWorkers.indexOf(newWorkers.find((w) => w.id === workerId));

  //   if (index !== null) {
  //     setLiveTrackingProps([
  //       [`trackingObj.trackingWorkers.${index}.new_location`, null],
  //     ]);

  //     setWorkers(newWorkers);
  //   }
  // };

  const renderWorkersMarkers = () => {
    if (workers.length > 0) {
      return workers.map((w, index) => {
        if (w.new_location !== undefined) {
          if (w.new_location !== null) {
            return {
              ...w,
              latLng: w.new_location,
              popup: w.name,
              markerIcon: {
                url: w.image,
                imgSize: [30, 43],
              },
            };
          }
        }
      });
    }
  };

  const renderWorkFieldPoints = () => {
    if (trackingObj.trackingItem !== null) {
      return trackingObj.trackingItem.map((wf, index) => {
        if (wf.points !== undefined) {
          if (wf.points.length > 0) {
            return wf.points.map((point) => {
              let lat = +point.location
                .split("(")[1]
                .split(")")[0]
                .split(" ")[1];
              let lng = +point.location
                .split("(")[1]
                .split(")")[0]
                .split(" ")[0];
              return (
                <Marker
                  key={index}
                  onClick={onMarkerClick}
                  name={point.name}
                  type="point"
                  id={point.id}
                  position={{ lat: lat, lng: lng }}
                  icon={{
                    url: marker,
                    scaledSize: new props.google.maps.Size(25, 25),
                  }}
                />
              );
            });
          }
        }
      });
    }
  };

  const drawPolygon = () => {
    if (trackingObj.trackingItem !== null) {
      if (trackingObj.trackingItem.length > 0) {
        return trackingObj.trackingItem.map((poly, index) => {
          let polygon = renderGooglePolygon(poly.location);
          return {
            ...poly,
            id: poly.id,
            boundaries: poly.location,
          };
        });
      }
    }
  };

  const renderHistoryPolyline = () => {
    if (sidebarData.polyline !== null) {
      if (sidebarData.polyline.length > 0) {
        return {
          points: sidebarData.polyline,
        };
      }
    }
  };

  return (
    <Card
      className="cardStyle"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Row className={getLanguage() === "en" ? "mr-3" : "ml-3"}>
        <Col style={{ height: "450px" }}>
          <GenericMapy
            map={map}
            mapID="liveTrackingMap"
            initialCenter={
              trackingObj.lat === null
                ? {
                    lat: 27.1946646,
                    lng: 33.1485576,
                  }
                : {
                    lat: trackingObj.lat,
                    lng: trackingObj.lng,
                  }
            }
            singlePolygon={drawPolygon() && drawPolygon()[0]}
            markers={renderWorkersMarkers()}
            zoomToMarkers={false}
            singlePolyLine={renderHistoryPolyline()}
          >
            {/* <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
              <div style={{ margin: "15px" }}>
                {selectedPlace.type === "point" ? (
                  <a
                    href={"/point-profile/" + selectedPlace.id}
                    target="_blank"
                  >
                    <p className="text-center">{selectedPlace.name}</p>
                  </a>
                ) : (
                  <a
                    href={"/worker-profile/" + selectedPlace.id}
                    target="_blank"
                  >
                    <p>{selectedPlace.name}</p>
                  </a>
                )}
              </div>
            </InfoWindow> */}
          </GenericMapy>
        </Col>
      </Row>
    </Card>
  );
};

export default TrackingMapy;
