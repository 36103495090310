import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { general } from "../../redux/actions";

import "./input.css";

const Input = ({
  prop,
  action,
  value,
  placeholder,
  label,
  children,
  className,
  type = "text",
  hasIcon,
  general,
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  return (
    <div>
      {label && (
        <div
          className="mb-2 text-xs font-semibold"
          style={{ color: "#7A869A" }}
        >
          {label}
        </div>
      )}
      <div className="relative">
        <input
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onBlur={() => {
            general(
              [
                {
                  prop: prop,
                  value: inputValue,
                },
              ],
              action
            );
          }}
          className={`bg-gray-100 focus:outline-none w-full ${
            className ? className : ""
          }`}
          style={{
            backgroundColor: "#EEEEEE",
            border: "none",
            padding: "1rem",
          }}
          placeholder={placeholder}
          type={type}
          dir="auto"
        />
        {hasIcon && (
          <div className="absolute inset-y-0 ltr:right-1 rtl:left-1">
            {children}
          </div>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(Input);
