import React, { Component } from "react";
import { Form, Card } from "reactstrap";
import { getMyCompany } from "../../utils/GeneralFunctions";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import FirstTab from "./FirstTab";
import SecondTab from "./SecondTab";
import { onSave } from "./CompanyWFFunctions";
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from "../../redux/actions/types";

class CreateUpdateCompainesWF extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { companyWfForm, modale, getCompany, general } = this.props;
    if (modale.actionType === "AddCompanyWF") {
      general(
        [
          {
            prop: "companyWfForm.company",
            value: getMyCompany(general),
          },
          {
            prop: "companyWfForm.formvalues.company",
            value: getMyCompany(general),
          },
        ],
        UPDATE_COMPANY_WORK_FIELD_PROPS
      );
    } else {
      if (
        modale.actionType === "EditCompanyWF" &&
        companyWfForm.formvalues.company_id !== null
      ) {
        getCompany(companyWfForm.formvalues.company_id, true);
      }
    }
  };

  renderTabs = () => {
    const {
      companyWfForm,
      general,
      modale,
      companyOptions,
      createORUpdateCompanyWF,
      companyWfObj,
      searchOptions,
      get_search,
      loadCompaniesOptions,
      loadBranchesOptions,
      branchOptions,
    } = this.props;

    if (modale.activeIndex === 0) {
      return (
        <FirstTab
          {...this.props}
          general={general}
          companyWfForm={companyWfForm}
          companyOptions={companyOptions}
          loadCompaniesOptions={loadCompaniesOptions}
          iscreate={companyWfForm.iscreate}
          loadBranchesOptions={loadBranchesOptions}
          branchOptions={branchOptions}
        />
      );
    } else {
      return (
        <SecondTab
          general={general}
          companyWfForm={companyWfForm}
          modale={modale}
          createORUpdateCompanyWF={createORUpdateCompanyWF}
          companyWfObj={companyWfObj}
          endPoint={get_search}
          searchOptions={searchOptions}
          {...this.props}
        />
      );
    }
  };
  render() {
    const { modale, createORUpdateCompanyWF, companyWfForm, companyWfObj } =
      this.props;
    return (
      <Form
        onSubmit={(e) =>
          onSave(e, createORUpdateCompanyWF, companyWfForm, companyWfObj, true)
        }
      >
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
  RequestsR,
}) => {
  return {
    companyWfForm: CompanyWorkFieldR.companyWfForm,
    modale: CompanyWorkFieldR.modale,
    companyOptions: CompanyR.companyOptions,
    companyWfObj: CompanyWorkFieldR.companyWfObj,
    searchOptions: CompanyWorkFieldR.searchOptions,
    branchOptions: BranchR.branchOptions,
    selectedCompanyWFPoints: RequestsR.requestsObj.requestsItem,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateCompainesWF);
