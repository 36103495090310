import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import CardItem from './CardItem';
import './NewPage.css';
import TableList from './TableList';
import * as actions from '../../redux/actions/index';

const NewPage = ({ general }) => {
  const renderFirstCards = () => {
    return [1, 2].map((item, index) => {
      return (
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-3'>
          <CardItem />
        </Col>
      );
    });
  };

  const renderSecondCards = () => {
    return [1, 2, 3, 1, 2, 3, 1, 2, 3].map((item, index) => {
      return (
        <Col xl={4} lg={4} md={4} sm={12} xs={12} className='mt-3'>
          <CardItem />
        </Col>
      );
    });
  };

  const renderThirdCards = () => {
    return [1, 2, 3, 4].map((item, index) => {
      return (
        <Col xl={6} lg={6} md={6} sm={12} xs={12} className='mt-3'>
          <CardItem />
        </Col>
      );
    });
  };
  return (
    <div className='page-wrapper'>
      <Row>{renderFirstCards()}</Row>

      <Row className='mt-5'>
        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Row>{renderSecondCards()}</Row>
        </Col>

        <Col xl={6} lg={6} md={6} sm={12} xs={12}>
          <Row>{renderThirdCards()}</Row>
          <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-2">
            <TableList general={general} />
          </Col>
        </Row>
        </Col>
        
      </Row>
    </div>
  );
};

export default connect(null, actions)(NewPage);
