import React, { useEffect } from "react";
import { UPDATE_USER_PROPS } from "../../redux/actions/types";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import icon_btn from "../../assets/img/icon_btn.png";
import { local } from "../../lang/local";
import { Tabs } from 'antd';

import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CityOwner from './CityOwner'
import   Requester from "./Requester";
import MainPermissions from './MainPermissions'

import { onSave } from "./UserFunctions";
const { TabPane } = Tabs;
const ThirdTab = (props) => {
  const {
    general,
    userForm,
    permissionsOptions,
    createORUpdateUser,
    CityOwnerPermissions,
    userObj,
    RequesterPermissions
  } = props;
// console.log("Permissions ",CityOwnerPermissions)
const X=()=>{
  for (let i = 0; i < permissionsOptions.length; i++) {
    permissionsOptions[i].isSelected = false;}
}
useEffect(()=>{
  // X()
},[])
  return (
    <CardBody className="trans">
      <Row>
      <Tabs defaultActiveKey="1"  >
      <TabPane   tab= {local.MainPermission} key="1">
        <MainPermissions 
         permissionsOptions={permissionsOptions}
         userObj={userObj}
         createORUpdateUser={createORUpdateUser}
         userForm={userForm}
          />
    {/* <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {permissionsOptions.length > 0
              ? permissionsOptions.map((per, index) => {
                  return (
                    <Col xl={4} lg={4} className="text-center">
                      <p>{per.permission}</p>
                      <FormControlLabel
                        checked={per.isSelected}
                        onChange={(e) =>{
                          console.log("e.target.checked",e.target.checked)
                          general(
                            [
                              {
                                prop:
                                  "permissionsOptions." + index + ".isSelected",
                                value: e.target.checked,
                              },
                            ],
                            UPDATE_USER_PROPS
                          )
                        }
                          
                        }
                        control={<Switch color="primary" />}
                        label={local[per.permission]}
                        labelPlacement="top"
                      />
                    </Col>
                  );
                })
              : null}
          </FormGroup>
        </FormControl>   */}
           </TabPane>
    <TabPane tab={local.CityOwner} key="2">
      
      <CityOwner
      
      permissionsOptions={permissionsOptions}
      userObj={userObj}
      createORUpdateUser={createORUpdateUser}
      userForm={userForm}
      CityOwnerPermissions={CityOwnerPermissions}/>
    
           </TabPane>
    <TabPane tab={local.Requester} key="3">
    <Requester
      permissionsOptions={permissionsOptions}
      userObj={userObj}
      createORUpdateUser={createORUpdateUser}
      userForm={userForm}
       
      RequesterPermissions={RequesterPermissions}/>
            </TabPane>
    
  </Tabs>
        
       
      </Row>

      {/* <center className="mt-5">
        <button
          type="submit"
          disabled={userForm.isLoading}
          className="save_btn mt-5"
          onClick={(e) =>
            onSave(
              e,
              createORUpdateUser,
              userForm,
              permissionsOptions,
              userObj,
              true,
              userForm.arrayOptions
            )
          }
        >
          {userForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center> */}
    </CardBody>
  );
};

export default ThirdTab;
