import { getLanguage } from "../../lang/local";
import {
  CLEAR_PRODUCTS_STATE,
  UPDATE_PRODUCTS_PROPS,
  DELETE_PRODUCTS_PROPS,
  MERGE_PRODUCTS_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  productObj: { productItem: null, isLoading: false },
  sorting: { column: "", order: "desc" },
  productForm: {
    isLoading: false,
    formvalues: {
      name: "",
      company_id: null,
      pharmaceutical_form: "",
      composition: "",
      pack: "",
      indication: "",
      price: "",
      image: null,
      pdf_file: null,
    },
    index: null,
    iscreate: "create",
  },
  productsOptions: [],

  searchForm: {
    from: "",
    to: "",
    company: null,
    page: 1,
  },
  isLoadingSearch: false,
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],

    activeIndex: 0,
    tabAction: "next",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_PRODUCTS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_PRODUCTS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_PRODUCTS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_PRODUCTS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
