import { getLanguage } from "../../lang/local";
import {
  CLEAR_STATE,
  UPDATE_COMPANY_PROPS,
  MERGE_COMPANY_PROPS,
  DELETE_COMPANY_PROPS,
  CLEAR_COMPANY_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  companyObj: { companyItem: null, isLoading: false },
  sorting: { column: "", order: "desc" },
  companyForm: {
    isLoading: false,
    formvalues: {
      states: null,
      work_fields: null,
      address: "",
      mobile: "",
      identifier: "",
      name: { ar: "", en: "" },
      logo: null,
      parent_id: null,
    },
    index: null,
    isCreate: "create",
  },
  companyOptions: [],
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },

      {
        title: getLanguage() === "en" ? "Work Places" : "الاشخاص المسئولون",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],
    activeIndex: 0,
    tabAction: "next",
  },
  stateValue: [],
  stateWFValue: [],
  lightbox: { isOpen: false, image: null },
  searchForm: {
    states: null,
    work_fields: null,
    mobile: "",
    active: null,
    from: "",
    to: "",
    name: "",
    page: 1,
  },

  stateusOption: [
    { label: getLanguage() === "en" ? "Active" : "مفعل", value: true },
    { label: getLanguage() === "en" ? "An Active" : "غير مفعل", value: false },
  ],
  fileOptions: {
    savedData: null,
    importedData: null,
    isLoading: false,
    errorData: null,
    headerData: null,
  },
  isLoadingSearch: false,
  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_COMPANY_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_COMPANY_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_COMPANY_PROPS: {
      return INITIAL_STATE;
    }
    case CLEAR_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
