import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderPagination,
  rednerImageInTable,
} from '../../utils/GeneralFunctions';
import { UPDATE_WORK_REPORT_PROPS } from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { leftlStyle } from '../../utils/GeneralStyle';

const HistoryTable = (props) => {
  const { historyObj, general, sorting, searchForm, getHistories } = props;

  const renderBody = () => {
    if (historyObj.historyItem !== null) {
      return historyObj.historyItem.data.map((hist, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(hist.id)}</td>
            <td
              className='td_table'
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {rednerImageInTable(
                hist.worker.image,
                UPDATE_WORK_REPORT_PROPS,
                general
              )}
            </td>
            <td
              className='td_table'
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
              {hist.worker.name}
            </td>

            <td className='td_table'>{hist.worker.mobile}</td>

            <td className='td_table'>{hist.start_at}</td>

            <td className='td_table'>{hist.end_at}</td>
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <Row>
        <Col xl={12}>
          <Table
            id='history_table'
            responsive
            hover
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  historyObj.historyItem,
                  'id',
                  'historyObj.historyItem.data',
                  UPDATE_WORK_REPORT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.worker_image,
                  historyObj.historyItem,
                  'worker.image',
                  'historyObj.historyItem.data',
                  UPDATE_WORK_REPORT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.worker_name,
                  historyObj.historyItem,
                  'worker.name',
                  'historyObj.historyItem.data',
                  UPDATE_WORK_REPORT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Mobile,
                  historyObj.historyItem,
                  'worker.mobile',
                  'historyObj.historyItem.data',
                  UPDATE_WORK_REPORT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.StartWork,
                  historyObj.historyItem,
                  'start_at',
                  'historyObj.historyItem.data',
                  UPDATE_WORK_REPORT_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.EndWork,
                  historyObj.historyItem,
                  'end_at',
                  'historyObj.historyItem.data',
                  UPDATE_WORK_REPORT_PROPS,
                  general,
                  sorting
                )}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {historyObj.historyItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='history_table'
              filename={local.CompaniesPoint}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {historyObj.historyItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}

      {renderPagination(historyObj.historyItem, searchForm, getHistories)}
    </Card>
  );
};

export default HistoryTable;
