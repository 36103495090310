import { getLanguage } from "../../lang/local";
import {
  CLEAR_ACTION_POINT_STATE,
  UPDATE_ACTION_POINT_PROPS,
  MERGE_ACTION_POINT_PROPS,
  DELETE_ACTION_POINT_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  actionPointObj: {
    actionPointObjItem: null,
    isLoading: false,
    lat: "",
    lng: "",
    visit_successed: null,
    visit_failed: null,
  },
  sorting: { column: "", order: "desc" },
  searchForm: {
    company: null,
    // work_field_id: null,
    branch_id: null,
    worker_id: null,
    point_id: null,
    from: "",
    to: "",
    job_title: "",
    action: "",
    page: 1,
    ids: [],
  },
  lightbox: { isOpen: false, image: null },

  visitStatusOptions: [
    {
      label: getLanguage() === "en" ? "Succeeded" : "ناجحة",
      value: "true",
    },
    {
      label: getLanguage() === "en" ? "Failed" : "فاشلة",
      value: "false",
    },
    {
      label: getLanguage() === "en" ? "Not done" : "لم تتم",
      value: "not_take",
    },
    {
      label: getLanguage() === "en" ? "All" : "الكل",
      value: "all",
    },
  ],

  isLoadingSearch: false,
  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ACTION_POINT_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_ACTION_POINT_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_ACTION_POINT_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_ACTION_POINT_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
