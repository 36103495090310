import React from 'react';
import { Card, Col, Row, Button, Spinner, Form } from 'reactstrap';
import { leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import {
  renderDateInput,
  renderArraw,
  checkHasOneCompany,
} from '../../utils/GeneralFunctions';
import { renderCompanySelect } from '../Compaines/CompainesFunction';

import { UPDATE_USER_PROPS } from '../../redux/actions/types';
import toaster from 'toasted-notes';
const UserSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    getUsers,
    general,
    companyOptions,
    history,
    loadCompaniesOptions,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();
    searchForm.page = 1;

    if (
      searchForm.from === '' &&
      searchForm.to === '' &&
      searchForm.company === null
    ) {
      toaster.notify(local.SearchValidation, {
        duration: 2000,
      });
    } else {
      getUsers(searchForm, true);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {local.Supervisors}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row c>
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
              {renderCompanySelect(
                'searchForm.company',
                searchForm.company,
                UPDATE_USER_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                null,
                null
              )}
            </Col>
          ) : null}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_USER_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_USER_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {' '}
            <Button
              style={leftlStyle}
              type='submit'
              disabled={isLoadingSearch}
              className='btn_search_style'
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default UserSearch;
