import LocalizedStrings from "react-localization";
import { SERVICE_LANGUAGE } from "../utils/StaticKeys";
import { localization } from "./localization";

const mergeLangsIntoOneObj = (firstLang, secondLang) => {
  const mergedObj = {};
  const slashRegex = /\//;
  for (let key in firstLang) {
    if (typeof firstLang[key] === "object") {
      mergedObj[key] = mergeLangsIntoOneObj(firstLang[key], secondLang[key]);
      continue;
    }
    if (slashRegex.test(key)) {
      mergedObj[`${key}`] = {
        en: firstLang[key],
        ar: secondLang[key],
      };
    } else {
      mergedObj[key] = {
        en: firstLang[key],
        ar: secondLang[key],
      };
    }
  }
  return mergedObj;
};

const constructLangObj = (source, lang) => {
  const langObj = {};
  for (let key in source) {
    if (typeof source[key][lang] !== "string") {
      langObj[key] = constructLangObj(source[key], lang);
      continue;
    }
    langObj[key] = source[key][lang];
  }
  return langObj;
};

let en = localization;
let ar = localization;

en = constructLangObj(localization, "en");
ar = constructLangObj(localization, "ar");

export const local = new LocalizedStrings({ en: en, ar: ar });

export const detectDashboardLanguage = (value) => {
  if (value === undefined) {
    localStorage.removeItem(SERVICE_LANGUAGE);
  } else {
    localStorage.setItem(SERVICE_LANGUAGE, value);
  }
};

export const getDashboardLanguage = () => {
  return localStorage.getItem(SERVICE_LANGUAGE);
};

export const toogleLanguage = (lang) => {
  if (lang === "en") {
    detectDashboardLanguage();

    local.setLanguage("en");
    detectDashboardLanguage("en");
  } else {
    detectDashboardLanguage();

    local.setLanguage("ar");
    detectDashboardLanguage("ar");
  }
  window.location.reload();

  window.location.href = "";
};

export const changeLanguage = () => {
  let lang = getDashboardLanguage();

  if (lang !== null) {
    if (lang === "en") {
      local.setLanguage("en");
      document.documentElement.setAttribute("dir", "ltr");
      document.documentElement.setAttribute("lang", "en");
      document.title = "Too Locations";
    } else {
      local.setLanguage("ar");
      document.documentElement.setAttribute("dir", "rtl");
      document.documentElement.setAttribute("lang", "ar");
      document.title = "مواقع توو";
    }
  } else {
    local.setLanguage("ar");
    detectDashboardLanguage("ar");

    document.documentElement.setAttribute("dir", "rtl");
    document.documentElement.setAttribute("lang", "ar");
    document.title = "too Locations";
  }
};

export const getLanguage = () => {
  let lang = getDashboardLanguage();
  if (lang !== null) {
    return lang;
  }
};

export const changeDirection = () => {
  let dir = getLanguage() === "en" ? "ltr" : "rtl";
  return dir;
};
