import React, { Component } from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import right_slide_img from "../../assets/img/right_slide_img.png";
import left_slide_img from "../../assets/img/left_slide_img.png";
import SignleReport from "./SignleReport";
import { Row, Col, Card } from "reactstrap";
import Header from "../../routing/Layout/Header";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { renderArraw } from "../../utils/GeneralFunctions";
const Sidebar = React.lazy(() => import("../../routing/Layout/Sidebar"));

class ShowReport extends Component {
  componentDidMount() {
    if (this.props.reportObj.reportItem === null) {
      this.props.history.push("/daily_report");
    }
  }

  renderItems = () => {
    const { reportObj, searchForm, companyWfOptions } = this.props;
    let items = [];
    if (reportObj.reportItem !== null) {
      reportObj.reportItem.data.map((d) => {
        items.push(
          <SignleReport
            report_item={d}
            searchForm={searchForm}
            companyWfOptions={companyWfOptions}
          />
        );
      });

      return items;
    }
  };
  render() {
    const { history, reportObj } = this.props;

    const items = this.renderItems();
    return (
      <div className="conatiner">
        <Header history={history} />
        {/* <div className="mt-2">
          <SideBar history={history} activeMenuItem="reports" />
        </div> */}
        {/* <LightBoxFile
        lightbox={lightbox}
        general={general}
        action={UPDATE_REPORT_PROPS}
      /> */}
        <Row style={{ height: "100%" }}>
          <div className="mt-2">
            <Sidebar />
          </div>

          <Col className="mt-2 body-container">
            <Card
              className="mt-5"
              style={{
                border: "none",
                background: "white",
                borderRadius: "10px",
                height: "100%",
              }}
            >
              <div className="mt-3 mr-3 ml-3">{renderArraw(history)}</div>

              <AliceCarousel
                activeIndex={reportObj.single_report}
                mouseTracking={false}
                items={items}
                touchTracking={true}
                renderPrevButton={() => (
                  <img src={left_slide_img} alt="" width="18" height="18" />
                )}
                renderNextButton={() => (
                  <img src={right_slide_img} alt="" width="20" height="20" />
                )}
              />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ ReportR, CompanyWorkFieldR }) => {
  return {
    reportObj: ReportR.reportObj,
    searchForm: ReportR.searchForm,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
  };
};

export default connect(mapStateToProps, actions)(ShowReport);
