import "./ProgressBar.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
export const ProgressBar = ({ value, pathColor }) => {
  return (
    <CircularProgressbar
      value={value}
      // text={`${value}%`}
      strokeWidth={25}
      // background=""
      // backgroundPadding="#fff"
      styles={buildStyles({
        strokeLinecap: "butt",
        textSize: "16px",
        // pathTransitionDuration: 0.5,
        // trailColor: pathColor,
        pathColor: pathColor,
        backgroundColor: "rgba(243, 243, 243,0.1)",
      })}
    />
  );
};
