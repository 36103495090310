import { connect } from 'react-redux';
import { Card, Col, Row, Form } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { renderArraw } from '../../utils/GeneralFunctions';
import { labelStyle, leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import {
  addPointsToEmployee,
  clearCoverageListProps,
  getCompanyWorkFieldOptions,
  getEmployeePointsOptions,
  getPointsOptions,
  loadBranchesOptions,
  loadCompaniesOptions,
  loadWorkerOptions,
  removePointsFromEmployee,
  setCoverageListProps,
  getCoverageList,
  general,
} from '../../redux/actions';
import { UPDATE_COVERAGE_LIST_PROPS } from '../../redux/actions/types';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { renderWorkerSeelct } from '../Workers/WorkerFunctions';
import AddToCoverageList from './AddToCoverageList';
import RemoveFromCoverageList from './RemoveFromCoverageList';
import { SelectEmployeeWorkFields } from '../../redux/selectors/CoverageListSelector';
import { useEffect } from 'react';

const AddOrRemoveFromCoverageList = (props) => {
  const {
    CoverageListReducer,
    history,
    loadCompaniesOptions,
    companyOptions,
    workerOptions,
    loadWorkerOptions,
    clearCoverageListProps,
    general,
  } = props;

  const { selectedCompany, selectedEmployee } = CoverageListReducer;

  useEffect(() => {
    return () => {
      clearCoverageListProps();
    };
  }, []);

  return (
    <div className='page-wrapper'>
      <Row>
        <Col className='body-container'>
          <Form>
            <Card className='cardCreate ml-2 mr-2'>
              <div className='mt-5 mr-5 ml-5'>
                <p style={leftlStyle}>{local.AddToOrRemoveFromCoverageList}</p>
                {renderArraw(history)}
              </div>
              <div className='mt-3 mr-5 ml-5'>
                <br /> <br />
                <p className='page_title mt-3' style={leftlStyle}>
                  {local.SelectEmployee}
                </p>
                <br />
                <Row className='justify-content-center justify-content-lg-start col-12'>
                  <div className='react-select-div mt-4'>
                    <span className='label_title' style={labelStyle}>
                      {local.Company}
                    </span>

                    {renderCompanySelect(
                      'selectedCompany',
                      selectedCompany,
                      UPDATE_COVERAGE_LIST_PROPS,
                      general,
                      local.Company,
                      loadCompaniesOptions,
                      companyOptions,
                      null,
                      [
                        { p: 'selectedEmployee', v: null },
                        // { p: "addToCoverageListForm.branch", v: null },
                        { p: 'addToCoverageListForm.workField', v: null },
                        { p: 'addToCoverageListForm.points', v: null },
                        // { p: "removeFromCoverageListForm.branch", v: null },
                        { p: 'removeFromCoverageListForm.workField', v: null },
                        { p: 'removeFromCoverageListForm.points', v: null },
                      ],
                      null
                    )}
                  </div>
                  <div className='react-select-div mt-4'>
                    <span className='label_title' style={labelStyle}>
                      {local.Worker}
                    </span>
                    {renderWorkerSeelct(
                      'selectedEmployee',
                      selectedEmployee,
                      UPDATE_COVERAGE_LIST_PROPS,
                      general,
                      loadWorkerOptions,
                      workerOptions,
                      selectedCompany,
                      null,
                      undefined,
                      false,
                      undefined,
                      [
                        'addToCoverageListForm.workField',
                        'addToCoverageListForm.points',
                        'removeFromCoverageListForm.workField',
                        'removeFromCoverageListForm.points',
                      ],
                      true
                    )}
                  </div>
                </Row>
                <AddToCoverageList {...props} />
                <RemoveFromCoverageList {...props} />
              </div>
            </Card>
          </Form>
          <br />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  CompanyR,
  BranchR,
  CompanyWorkFieldR,
  RequestsR,
  WorkerR,
  CoverageListReducer,
  SearchFormReducer,
}) => ({
  companyOptions: CompanyR.companyOptions,
  branchOptions: BranchR.branchOptions,
  companyWfOptions: CompanyWorkFieldR.companyWfOptions,
  pointsOptions: RequestsR.pointsOptions,
  workerOptions: WorkerR.workerOptions,
  CoverageListReducer,
  employeeWorkFields: SelectEmployeeWorkFields(CoverageListReducer, WorkerR),
  searchForm: SearchFormReducer,
});

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
      loadCompaniesOptions,
      loadWorkerOptions,
      loadBranchesOptions,
      getCompanyWorkFieldOptions,
      getPointsOptions,
      setCoverageListProps,
      addPointsToEmployee,
      removePointsFromEmployee,
      clearCoverageListProps,
      getEmployeePointsOptions,
      getCoverageList,
    },
    dispatch
  );
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AddOrRemoveFromCoverageList);
