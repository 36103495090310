import sortBy from 'lodash/sortBy';
import { getRequest, postRequest } from '../Api/index';
import { local } from '../../lang/local';
import {
  checkHasOneCompany,
  confirmSunccessed,
  getAsyncSelectOptions,
  getMyCompany,
  isArray,
  setterGenerator,
} from '../../utils/GeneralFunctions';
import { UPDATE_GIFTS_PROPS } from './types';

// export const setGiftsProps = (propsArr) => async (dispatch) => {
//   let payload = [];
//   for (let i = 0; i < propsArr.length; i++) {
//     payload.push({
//       prop: propsArr[i][0],
//       value: propsArr[i][1],
//     });
//   }

//   dispatch({
//     type: UPDATE_GIFTS_PROPS,
//     payload,
//   });
// };

export const setGiftsProps = (propsArr) =>
  setterGenerator(UPDATE_GIFTS_PROPS, propsArr);

export const getCompanies = (page) => async (dispatch) => {
  let payload;

  try {
    await getRequest('companies', { page: page }).then((response) => {
      payload = response.data.data.data;
      console.log(payload);
    });
  } catch (err) {
    console.log(err);
  }

  dispatch(setGiftsProps([['companies', payload]]));
};

export const getCompanyOptions =
  (searchValue, loadedOptions, additional) => async (dispatch) => {
    let hasMore;
    let companiesOptions = [];

    const searchForm = {
      name: searchValue,
      page: additional.page,
    };

    try {
      await getRequest('companies', searchForm).then((response) => {
        const optionsObject = getAsyncSelectOptions(
          response,
          'name',
          'id',
          true
        );
        companiesOptions = optionsObject.options;
        hasMore = optionsObject.hasMore;
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setGiftsProps([
        ['companiesOptionsObject', [...loadedOptions, ...companiesOptions]],
      ])
    );

    return {
      options: companiesOptions,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
      },
    };
  };

export const getBranchesOptions =
  (searchValue, loadedOptions, additional) => async (dispatch) => {
    let hasMore;
    let branchesOptions = [];

    const searchForm = {
      name: searchValue,
      page: additional.page,
    };

    try {
      await getRequest(
        `companies/branches/${additional.companyId}`,
        searchForm
      ).then((response) => {
        const optionsObject = getAsyncSelectOptions(
          response,
          'name',
          'id',
          true
        );
        branchesOptions = optionsObject.options;
        hasMore = optionsObject.hasMore;
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setGiftsProps([
        ['branchesOptions', [...loadedOptions, ...branchesOptions]],
      ])
    );

    return {
      options: branchesOptions,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
        companyId: additional.companyId,
      },
    };
  };

export const getWorkFieldOptions =
  (searchValue, loadedOptions, additional) => async (dispatch) => {
    let hasMore;
    let workFieldOptions = [];

    const searchForm = {
      name: searchValue,
      page: additional.page,
    };

    try {
      await getRequest(
        `companies/${additional.branchId}/work_fields`,
        searchForm
      ).then((response) => {
        const optionsObject = getAsyncSelectOptions(
          response,
          'name',
          'id',
          true
        );
        workFieldOptions = optionsObject.options;
        hasMore = optionsObject.hasMore;
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setGiftsProps([['areasOptions', [...loadedOptions, ...workFieldOptions]]])
    );

    return {
      options: workFieldOptions,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
        branchId: additional.branchId,
      },
    };
  };

export const getWorkFieldpointsOptions =
  (searchValue, loadedOptions, additional) => async (dispatch) => {
    let hasMore;
    let workFieldpointsOptions = [];

    const searchForm = {
      name: searchValue,
      page: additional.page,
    };

    let workFields = additional.workFields;
    if (workFields && isArray(workFields)) {
      searchForm.ids = workFields.map((item) => item.value);
    } else if (workFields && !isArray(workFields)) {
      searchForm.ids = workFields.value;
    }

    try {
      await getRequest('companies/work_fields/points', searchForm).then(
        (response) => {
          const optionsObject = getAsyncSelectOptions(response, 'name', 'id');
          workFieldpointsOptions = optionsObject.options;
          hasMore = optionsObject.hasMore;
        }
      );
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setGiftsProps([
        ['visitsSpotsOptions', [...loadedOptions, ...workFieldpointsOptions]],
      ])
    );

    return {
      options: workFieldpointsOptions,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
        companyId: additional.companyId,
        workFields: additional.workFields,
      },
    };
  };

export const getGiftsTableData = (searchForm, page) => async (dispatch) => {
  let payload;

  const searchFormObject = {
    type: searchForm.type !== null ? searchForm.type : '',
    from: searchForm.date.from,
    to: searchForm.date.to,
    page,
  };

  if (searchForm.company) {
    try {
      dispatch(setGiftsProps([['searchForm.isSearching', true]]));
      await getRequest(
        `companies/gifts/${searchForm.company}`,
        searchFormObject
      ).then((response) => {
        payload = response.data.data;
        dispatch(setGiftsProps([['searchForm.isSearching', false]]));
        console.log(payload);
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setGiftsProps([
        ['giftsTableData', payload],
        ['giftsTableSorting.sortedArray', payload?.data],
        [
          'lastCompanySearchedFor',
          payload.length > 0 ? payload[0].company.id : null,
        ],
      ])
    );
  }
};

export const getGiftsReportData = (searchForm, page) => async (dispatch) => {
  let payload;

  const searchFormObject = {
    type: searchForm.type,
    point_id: searchForm.workFieldsPoints?.id,
    from: searchForm.date.from,
    to: searchForm.date.to,
    page,
  };

  if (searchForm.workFields) {
    if (isArray(searchForm.workFields)) {
      searchFormObject.ids = searchForm.workFields.map((item) => item.value);
    } else {
      searchFormObject.ids = searchForm.workFields.value;
    }
  }

  // let queryStringForSearch = searchValue ? `&name=${searchValue}` : "";

  if (searchForm.workFields) {
    try {
      await getRequest('gifts/amount', searchFormObject).then((response) => {
        payload = response.data.data;
        console.log(payload);
      });
    } catch (err) {
      console.log(err);
    }

    dispatch(
      setGiftsProps([
        ['giftsReportData', payload],
        ['giftsReportSorting.sortedArray', payload?.data],
      ])
    );
  }
};

export const clearAndCloseEditGiftsModal = () => (dispatch) => {
  dispatch(
    setGiftsProps([
      [
        'editGiftsModal',
        {
          isOpen: false,
          gift: {
            id: null,
            name: '',
            type: null,
            details: '',
          },
          confirm: false,
        },
      ],
    ])
  );
};

export const editGiftOrSample =
  (giftId, giftName, giftType, giftDetails, getGiftsTableData) =>
  async (dispatch) => {
    let payload;
    try {
      const requestBody = {
        name: giftName,
        type: giftType,
        details: giftDetails,
      };
      await postRequest(`companies/gifts/update/${giftId}`, requestBody).then(
        (response) => {
          payload = response.data.data;
          console.log(payload);
          getGiftsTableData();
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

export const addGiftOrSample =
  (companyId, giftName, giftType, giftDetails) => async (dispatch) => {
    let payload;
    const defaultCompanyID = checkHasOneCompany() ? getMyCompany() : null;
    try {
      const requestBody = {
        company_id: companyId,
        name: giftName,
        type: giftType,
        details: giftDetails,
      };
      await postRequest('companies/gifts/store', requestBody).then(
        (response) => {
          payload = response.data.data;
          console.log(payload);
          confirmSunccessed(
            giftType === 0
              ? local.GiftAddedSuccessfuly
              : local.SampleAddedSuccessfuly
          );
          dispatch(
            setGiftsProps([
              ['addGiftPage.addGiftForm.companyId', defaultCompanyID],
              ['addGiftPage.addGiftForm.name', ''],
              ['addGiftPage.addGiftForm.type', ''],
              ['addGiftPage.addGiftForm.details', ''],
            ])
          );
        }
      );
    } catch (err) {
      console.log(err);
    }
  };

export const clearAndCloseAddGiftsModal = () => (dispatch) => {
  dispatch(
    setGiftsProps([
      [
        'addGiftsModal',
        {
          isOpen: false,
          gift: {
            companyId: null,
            name: '',
            type: null,
            details: '',
          },
          confirm: false,
        },
      ],
    ])
  );
};

export const openAddGiftsModal = () => (dispatch) => {
  dispatch(setGiftsProps([['addGiftsModal.isOpen', true]]));
};

export const clearAllGiftsAndSamples = () => (dispatch) => {
  dispatch({
    type: 'CLEAR_ALL',
  });
};

export const sortGiftsTable =
  (array, column = 'id', order = 'asc') =>
  (dispatch) => {
    let sortedArray = sortBy(array, [
      (o) => {
        try {
          return o[column].toLowerCase();
        } catch {
          return o[column];
        }
      },
    ]);

    if (order === 'desc') {
      sortedArray.reverse();
    }

    dispatch(
      setGiftsProps([
        ['giftsTableSorting.sortBy', column],
        ['giftsTableSorting.order', order],
        ['giftsTableSorting.sortedArray', sortedArray],
      ])
    );

    return sortedArray;
  };

export const sortGiftsReport =
  (array, column = ['id'], order = 'asc') =>
  (dispatch) => {
    let sortedArray = sortBy(array, [
      (o) => {
        let prop = o[column[0]];
        for (let i = 1; i < column.length; i++) {
          prop = prop[column[i]];
        }
        try {
          return prop.toLowerCase();
        } catch {
          return prop;
        }
      },
    ]);

    if (order === 'desc') {
      sortedArray.reverse();
    }

    dispatch(
      setGiftsProps([
        ['giftsReportSorting.sortBy', column],
        ['giftsReportSorting.order', order],
        ['giftsReportSorting.sortedArray', sortedArray],
      ])
    );

    return sortedArray;
  };
