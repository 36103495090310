import React, { Component } from 'react';
import { connect } from 'react-redux';
import {  Col, Row } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import VisitSearch from './VisitSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateVisit from './CreateUpdateVisit';
import { local } from '../../lang/local';
import { UPDATE_VISITS_PROPS } from '../../redux/actions/types';
import { unsetForm, unsetVisitListData } from './VisitFunction';
import DoughnutChartLabeled from '../../utils/DoughnutChartLabeled';
import DoughnutChart from '../../utils/DoughnutChart';
import BarChartStacked from '../../utils/BarChartStacked';
class Visits2 extends Component {
  componentDidMount() {
    this.renderVisit();
  }

  renderModalChildren = () => {
    return <CreateUpdateVisit history={this.props.history} />;
  };

  renderVisit = () => {
    const { history, general } = this.props;

    unsetVisitListData(general);
  };
  renderModal = () => {
    const { modale, general, visitForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={1}
        tabs_title={[{ title: local.MainInfo }]}
        modale={modale}
        general={general}
        action={UPDATE_VISITS_PROPS}
        formvalues={visitForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      searchForm,
      isLoadingSearch,
      getVisits,
      getCompainesWFForDropDown,
      getWorkersForDropDown,
      companyOptions,
      companyWfOptions,
      workerOptions,
      loadCompaniesWFOptions,
      loadWorkerOptions,
      loadCompaniesOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}

        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <VisitSearch
              history={history}
              general={general}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              getVisits={getVisits}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              getWorkersForDropDown={getWorkersForDropDown}
              companyOptions={companyOptions}
              companyWfOptions={companyWfOptions}
              workerOptions={workerOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadWorkerOptions={loadWorkerOptions}
              loadCompaniesOptions={loadCompaniesOptions}
            />

            <div className='row p-0'>
              <div className='col-12 col-md-6 p-0 row'>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Yellow', 'Grey']}
                      doughnutData={[12, 19, 3]}
                      colors={['rgba(18, 169, 204, 1)', '#ffc33c', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Yellow', 'Grey']}
                      doughnutData={[12, 19, 3]}
                      colors={['rgba(18, 169, 204, 1)', '#ffc33c', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 p-0'>
                <div className='row mb-5'>
                  <div className='col-4'>
                    <DoughnutChart
                      doughnutData={[55, 45]}
                      colors={['#09c0aa', '#cecece']}
                    />
                  </div>
                  <div className='col-4'>
                    <DoughnutChart
                      doughnutData={[55, 45]}
                      colors={['#09c0aa', '#cecece']}
                    />
                  </div>
                  <div className='col-4'>
                    <DoughnutChart
                      doughnutData={[55, 45]}
                      colors={['#09c0aa', '#cecece']}
                    />
                  </div>
                </div>
                <div className='row'>
                  <BarChartStacked
                    barThickness={30}
                    barLabels={[
                      'Jan',
                      'Feb',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'Aug',
                    ]}
                    datasetLabels={['Not Done', 'Failed', 'Succeeded', 'Total']}
                    datasets={[
                      [10, 20, 30, 40, 50, 45, 70, 80],
                      [10, 20, 30, 40, 50, 45, 70, 80],
                      [10, 20, 30, 40, 50, 45, 70, 80],
                      [10, 20, 30, 40, 50, 45, 70, 80],
                    ]}
                    colors={['#cecece', '#ffc33c', '#14a9cc', '#09c0aa']}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  VisitR,
  CompanyR,
  WorkerR,
  CompanyWorkFieldR,
  RequestsR,
}) => {
  return {
    visitObj: VisitR.visitObj,
    modale: VisitR.modale,
    visitForm: VisitR.visitForm,
    sorting: VisitR.sorting,
    searchForm: VisitR.searchForm,
    isLoadingSearch: VisitR.isLoadingSearch,
    companyOptions: CompanyR.companyOptions,
    workerOptions: WorkerR.workerOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    pointsOptions: RequestsR.pointsOptions,
  };
};

export default connect(mapStateToProps, actions)(Visits2);
