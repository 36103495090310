import { Table } from "reactstrap";
import StaticOptionsSelect from "../../components/StaticOptionsSelect/StaticOptionsSelect";
import {
  getBranchOptions,
  getCompanyOptions,
  getWorkFieldOptions,
} from "../../utils/GeneralFunctions";
import { local } from "../../lang/local";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";

const WorkerFileTable = ({
  employeeDataState,
  setEmployeeDataState,
  jobTitlesOptions,
  workerTypeOption,
}) => {
  const { tableData } = employeeDataState;

  if (!tableData) return null;

  const TableHeader = ({ title }) => {
    return (
      <th
        className="td_table"
        style={{ verticalAlign: "top", textAlign: "center" }}
      >
        <p style={{ display: "block" }}> {title}</p>
      </th>
    );
  };

  const renderCompany = (company, row, index) => {
    const onChange = (option) => {
      setEmployeeDataState(`tableData.${index}.company`, option);
      setEmployeeDataState(`tableData.${index}.branch`, null);
      setEmployeeDataState(`tableData.${index}.workFields`, null);
    };

    return (
      <CustomAsyncPaginate
        placeholder={local.Company}
        value={company}
        loadOptions={getCompanyOptions}
        onChange={onChange}
        additional={{
          page: 1,
        }}
      />
    );
  };

  const renderBranch = (branch, row, index) => {
    const company = row.company;

    const onChange = (option) => {
      setEmployeeDataState(`tableData.${index}.branch`, option);
      setEmployeeDataState(`tableData.${index}.workFields`, null);
    };

    return (
      <CustomAsyncPaginate
        placeholder={local.Branch}
        value={branch}
        loadOptions={getBranchOptions(company?.id)}
        onChange={onChange}
        cacheUniqsArr={[tableData[index].company]}
        additional={{
          page: 1,
        }}
      />
    );
  };

  const renderWorkFields = (workFields, row, index) => {
    const branch = row.branch;

    const onChange = (option) => {
      setEmployeeDataState(`tableData.${index}.workFields`, option);
    };

    return (
      <CustomAsyncPaginate
        placeholder={local.CompaniesWorkField}
        value={workFields}
        loadOptions={getWorkFieldOptions(branch?.id)}
        onChange={onChange}
        cacheUniqsArr={[tableData[index].branch]}
        isMulti={true}
        additional={{
          page: 1,
        }}
      />
    );
  };

  const renderJobTitle = (jobTitle, row, index) => {
    const onChange = (option) => {
      setEmployeeDataState(`tableData.${index}.jobTitle`, option);
    };

    return (
      <StaticOptionsSelect
        title={local.JobTitle}
        value={jobTitle?.value}
        options={jobTitlesOptions}
        onChange={onChange}
      />
    );
  };

  const renderEmployeeType = (employeeType, row, index) => {
    const onChange = (option) => {
      setEmployeeDataState(`tableData.${index}.employeeType`, option);
    };

    return (
      <StaticOptionsSelect
        title={local.WorkerType}
        value={employeeType?.value}
        options={workerTypeOption}
        onChange={onChange}
      />
    );
  };

  const renderBody = () => {
    try {
      if (tableData.length > 0) {
        const data = [];
        for (let i = 0; i < tableData.length; i++) {
          const currentEmployee = tableData[i];
          const {
            name,
            userName,
            email,
            address,
            password,
            confirmPassword,
            nationalID,
            mobile,
            company,
            branch,
            workFields,
            jobTitle,
            employeeType,
            status,
          } = currentEmployee;

          data.push(
            <tr key={i}>
              <td className="td_table">{i + 1}</td>
              <td className="td_table">{name}</td>
              <td className="td_table">{userName}</td>
              <td className="td_table">{email}</td>
              <td className="td_table">{address}</td>
              <td className="td_table">{password}</td>
              <td className="td_table">{confirmPassword}</td>
              <td className="td_table">{mobile}</td>
              <td className="td_table">{nationalID}</td>
              <td className="td_table">
                {renderCompany(company, currentEmployee, i)}
              </td>
              <td className="td_table">
                {renderBranch(branch, currentEmployee, i)}
              </td>
              <td className="td_table">
                {renderWorkFields(workFields, currentEmployee, i)}
              </td>
              <td className="td_table">
                {renderJobTitle(jobTitle, currentEmployee, i)}
              </td>
              <td className="td_table">
                {renderEmployeeType(employeeType, currentEmployee, i)}
              </td>
              <td className="td_table">{status}</td>
            </tr>
          );
        }
        return data;
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Table
      id="company_work_field_point"
      responsive
      hover
      className="table_list mt-4 mb-4 table"
      style={{
        borderTopColor: "white",
        border: "none",
        position: "relative",
      }}
    >
      <thead>
        <tr className="table_head">
          <TableHeader title={local.Id} />
          <TableHeader title={local.Name} />
          <TableHeader title={local.UserName} />
          <TableHeader title={local.Email} />
          <TableHeader title={local.Address} />
          <TableHeader title={local.Password} />
          <TableHeader title={local.ConfirmPassword} />
          <TableHeader title={local.Mobile} />
          <TableHeader title={local.Ssn} />
          <TableHeader title={local.Company} />
          <TableHeader title={local.Branch} />
          <TableHeader title={local.WorkField} />
          <TableHeader title={local.JobTitle} />
          <TableHeader title={local.WorkerType} />
          <TableHeader title={local.Status} />
        </tr>
      </thead>
      <tbody>{renderBody()}</tbody>
    </Table>
  );
};

export default WorkerFileTable;
