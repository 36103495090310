import {
    UPDATE_Ticket_PROPS,
    UPDATE_Ticket_PROPS_Action
  } from "../actions/types";
export const UPDATE_Ticket_Details=(Ticket_details)=>{
    return {
        type:UPDATE_Ticket_PROPS,
        payload:Ticket_details
    }
}

export const UPDATE_Ticket_Details_Actions=(Ticket_details)=>{
    return {
        type:UPDATE_Ticket_PROPS_Action,
        payload:Ticket_details
    }
}