import { Card, Table } from "reactstrap";

const TableList = () => {
  return (
   <Card className="card-wrapper"> 
   <Table responsive>
     <thead>
       <tr>
         <th>
           #
         </th>
         <th>
           Table heading
         </th>
         <th>
           Table heading
         </th>
         <th>
           Table heading
         </th>
         <th>
           Table heading
         </th>
         <th>
           Table heading
         </th>
         <th>
           Table heading
         </th>
       </tr>
     </thead>
     <tbody>
       <tr>
         <th scope="row">
           1
         </th>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
       </tr>
       <tr>
         <th scope="row">
           2
         </th>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
       </tr>
       <tr>
         <th scope="row">
           3
         </th>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
         <td>
           Table cell
         </td>
       </tr>
     </tbody>
   </Table>    </Card>
  );
};
export default TableList;
