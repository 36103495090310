import { getLanguage, local } from "../../lang/local";
import {
  CLEAR_COMPANY_WORK_FIELD_STATE,
  UPDATE_COMPANY_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_WORKER_PROPS,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import { AsyncPaginate } from "react-select-async-paginate";
import { getDeafultItem, workFieldPermissionedForDropDown } from "../../utils/GeneralFunctions";
import { selectStyle3, selectStyle4, selectStyle5 } from "../../utils/GeneralStyle";
export const unsetForm = (general) => {
  general(
    [
      {
        prop: "companyWfForm",
        value: {
          isLoading: false,
          formvalues: {
            parent_branch: null,
            branch_id: null,
            name: { ar: "", en: "" },
            address: "",
            location: null,
          },
          index: null,
          iscreate: "create",
          reloadDrawing: false,
          company: null,
        },
      },
      { prop: "companyWfOptions", value: [] },

      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
          tabs_title: [
            {
              title:
                getLanguage() === "en"
                  ? "Main Information"
                  : "البيانات الرئيسية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
            {
              title: getLanguage() === "en" ? "Work Places" : "اماكن العمل",

              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
          ],
          activeIndex: 0,
          tabAction: "next",

          mapItem: {
            polygon: [],
            mapPoints: [31.4177636, 30.00475],
          },
        },
      },
    ],
    UPDATE_COMPANY_WORK_FIELD_PROPS
  );
};

export const ValidateInputs = (companyWfForm) => {
  if (companyWfForm.formvalues.location === null) {
    toaster.notify(local.PolygonRequired, {
      duration: 2000,
    });
  }
  if (companyWfForm.formvalues.address === "") {
    toaster.notify(local.AddressRequired, {
      duration: 2000,
    });
  }
  if (companyWfForm.formvalues.name.en === "") {
    toaster.notify(local.EnglishNamequired, {
      duration: 2000,
    });
  }
  if (companyWfForm.formvalues.name.ar === "") {
    toaster.notify(local.ArabicNameRequired, {
      duration: 2000,
    });
  }
  if (companyWfForm.formvalues.company_id === null) {
    toaster.notify(local.CompanyRequired, {
      duration: 2000,
    });
  } else {
    return true;
  }
};
export const onSave = (
  e,
  createORUpdateCompanyWF,
  companyWfForm,
  companyWfObj,
  isModal
) => {
  e.preventDefault();
  if (ValidateInputs(companyWfForm) === true) {
    createORUpdateCompanyWF(
      companyWfForm.formvalues,
      companyWfForm.index,
      companyWfObj.companyWfItem,
      isModal
    );
  }
};

export const renderWorkFSelect = (
  prop,
  value,
  action,
  general,
  title,
  loadCompaniesWFOptions,
  companyWfOptions,
  isMutli,
  dependProps,
  id,
  col,
  drawProp,
  iscreate,
  getAllPoints,
  onChangeCallBack,
  isDisabled = false
) => {
  return (
    <>
      <AsyncPaginate
      isDisabled={isDisabled}
        options={
          iscreate !== undefined
            ? iscreate === "update"
              ? companyWfOptions
              : []
            : []
        }
        key={value}
        value={
          isMutli === null ? getDeafultItem(value, companyWfOptions) : value
        }
        placeholder={title}
        className={col !== null ? "mt-" + col : null}
        isMulti={isMutli === null ? false : true}
        closeMenuOnSelect={false}
        styles={{...selectStyle4, ...selectStyle5}}
        loadOptions={(name, loadedOptions, page) =>
          loadCompaniesWFOptions(name, loadedOptions, page, id)
        }
        filterOption={(option,rawInput)=>workFieldPermissionedForDropDown({ option, rawInput })}
        isClearable={true}
        onChange={(e) =>
          onChangeWorkField(
            e,
            isMutli,
            prop,
            action,
            general,
            dependProps,
            drawProp,
            getAllPoints,
            onChangeCallBack
          )
        }
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
        cacheUniqs={[id]}
        onFocus={() =>
          id === null
            ? toaster.notify(local.selectBranch, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const onChangeWorkField = (
  e,
  isMutli,
  prop,
  action,
  general,
  dependProps,
  drawProp,
  getAllPoints,
  onChangeCallBack
) => {
  if (dependProps !== null) {
    dependProps.map((prop) => {
      general([{ prop: prop, value: null }], action);
    });
  }

  if (e !== null) {
    if (getAllPoints !== undefined) {
      if (getAllPoints !== null) {
        getAllPoints(e.value);
      }
    }

    if (onChangeCallBack) onChangeCallBack(e);

    if (drawProp !== undefined) {
      if (drawProp !== null) {
        general([{ prop: drawProp, value: e.location }], action);
      }
    }

    if (isMutli === null) {
      general([{ prop: prop, value: e.value }], action);
    } else {
      general([{ prop: prop, value: e }], action);
    }
  } else {
    general(
      [{ prop: "companyWfOptions", value: [] }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );

    general([{ prop: "workerOptions", value: [] }], UPDATE_WORKER_PROPS);

    general([{ prop: prop, value: null }], action);
  }
};

export const unsetCompanyWFListData = (general) => {
  general([], CLEAR_COMPANY_WORK_FIELD_STATE);
  general(
    [
      {
        prop: "companyOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};
