import React, { useEffect } from 'react';

const ModelTab = (props) => {
  const { tabs_title, activeIndex, action, general } = props;

  useEffect(() => {
    general([{ prop: 'modale.activeIndex', value: 0 }], action);
  },[]);
  const updateActiveTab = (e, index) => {
    e.preventDefault();

    general([{ prop: 'modale.activeIndex', value: index }], action);
  };

  const renderTabs = () => {
    return tabs_title.map((tab, index) => {
      return (
        <div
          key={tab.title + index}
          onClick={(e) => updateActiveTab(e, index)}
          style={{
            background: activeIndex !== index ? '#fff' : '#05beac',
            borderTop: activeIndex !== index ? '1px solid #05beac' : 'none',
            width: `calc(100%/${tabs_title.length})`,
            height: '100%',
            color: activeIndex !== index ? '#000' : '#fff',
          }}
          className='center cursor'
        >
          {tab.title}
        </div>
      );
    });
  };

  return (
    <div
      className='ui red three item menu center'
      style={{
        minHeight: '7em',
        height: '7em',
        border: '1px solid #04B7B3',
        borderTopRightRadius: '10px',
        borderTopLeftRadius: '10px',
        fontSize: '10px',
      }}
    >
      {renderTabs()}
    </div>
  );
};

export default ModelTab;
