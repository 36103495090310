import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ReportTable from './ReportTable';
import * as actions from '../../redux/actions/index';
import { getMyCompany } from '../../utils/GeneralFunctions';
import ReportTempleteSearch from './ReportTempleteSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateReport from './CreateUpdateReport';
import { UPDATE_REPORT_TEMPLETE_PROPS } from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
import {
  unsetForm,
  unsetReportTempleteListData,
} from './ReportTempleteFunctions';
class ReportTemplete extends Component {
  componentDidMount() {
    this.renderTempleteReports();
  }

  renderModalChildren = () => {
    return <CreateUpdateReport />;
  };

  renderTempleteReports = () => {
    const {  general } = this.props;
    unsetReportTempleteListData(general);

    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_REPORT_TEMPLETE_PROPS
    );
  };
  renderModal = () => {
    const { modale, general, reportForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={2}
        tabs_title={modale.tabs_title}
        modale={modale}
        general={general}
        action={UPDATE_REPORT_TEMPLETE_PROPS}
        formvalues={reportForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      reportTempleteObj,
      sorting,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getTempleteReports,
      getCompainesWFForDropDown,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      loadBranchesOptions,
      branchOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_REPORT_TEMPLETE_PROPS}
        />
        <Row>
          <Col>
            <ReportTempleteSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getTempleteReports={getTempleteReports}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              general={general}
              loadCompaniesOptions={loadCompaniesOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadBranchesOptions={loadBranchesOptions}
              branchOptions={branchOptions}
            />

            <ReportTable
              general={general}
              reportTempleteObj={reportTempleteObj}
              sorting={sorting}
              lightbox={lightbox}
              searchForm={searchForm}
              getTempleteReports={getTempleteReports}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  ReportTempleteR,
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
}) => {
  return {
    reportTempleteObj: ReportTempleteR.reportTempleteObj,
    modale: ReportTempleteR.modale,
    reportForm: ReportTempleteR.reportForm,
    sorting: ReportTempleteR.sorting,
    lightbox: ReportTempleteR.lightbox,
    searchForm: ReportTempleteR.searchForm,
    isLoadingSearch: ReportTempleteR.isLoadingSearch,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(ReportTemplete);
