import {
  CLEAR_STATE,
  UPDATE_STATE_PROPS,
  MERGE_STATE_PROPS,
  DELETE_STATE_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  stateObj: { stateItem: null, isLoading: false, page: 1 },
  sorting: { column: "", order: "desc" },
  stateForm: {
    isLoading: false,
    formvalues: {
      name: { ar: "", en: "" },
      parent_id: null,
    },
    index: null,
    isCreate: "create",
  },
  stateOptions: [],
  modale: { isOpen: false, tobeConfirmed: false, title: "", actionType: "" },
  searchForm: { from: "", to: "", name: "", page: 1 },
  isLoadingSearch: false,

  fileOptions: {
    savedData: null,
    importedData: null,
    isLoading: false,
    errorData: null,
    headerData: null,
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_STATE_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_STATE_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_STATE_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
