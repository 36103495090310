import { Request, Select_Request } from "../Api/index";
import {
  UPDATE_MERCHANT_PROPS,
  MERGE_MERCHANT_PROPS,
  DELETE_MERCHANT_PROPS,
  CLEAR_MERCHANT_PROPS,
  UPDATE_REQUESTS_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  renderFormData,
  confirmSunccessed,
  rmUnsetValsAndGetQryStr,
} from "../../utils/GeneralFunctions";

export const setMerchantProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for (let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1],
    });
  }

  dispatch({
    type: UPDATE_MERCHANT_PROPS,
    payload,
  });
};

export const getMerchants =
  ({ workFields = [], merchant_id }) =>
  (dispatch) => {
    const queryObj = {
      merchant_id,
      ids: workFields,
    };
    const query = rmUnsetValsAndGetQryStr(queryObj);
    Request()
      .get("/companies/work_fields/points" + query)
      .then((res) => {
        if (res.data.data.data.length == 1 ) {
          dispatch({
            type: UPDATE_REQUESTS_PROPS,
            payload: [
              {
                prop: "pointForm.formvalues.location",
                value: res.data.data.data[0].location,
              },
              {
                prop: "pointForm.formvalues.merchant_id",
                value: res.data.data.data[0].merchant_id,
              },
              {
                prop: "pointForm.formvalues.merchant_username",
                value: res.data.data.data[0].merchant_username,
              },
              {
                prop: "pointForm.formvalues.name",
                value: res.data.data.data[0].name,
              },
              {
                prop: "pointForm.formvalues.properties.address",
                value: res.data.data.data[0].properties.address,
              },
              {
                prop: "pointForm.formvalues.properties.landmark",
                value: res.data.data.data[0].properties.landmark,
              },
              {
                prop: "pointForm.formvalues.properties.mobile",
                value: res.data.data.data[0].properties.mobile,
              },
              {
                prop: "pointForm.formvalues.properties.point_radius",
                value: res.data.data.data[0].properties.point_radius,
              },
              // {
              //   prop: "pointForm.formvalues.work_field_id",
              //   value: res.data.data.data[0].work_field.id,
              // },
              // {
              //   prop: "pointForm.formvalues.branch_id",
              //   value: res.data.data.data[0].work_field.branch.id,
              // },
              {
                prop: "pointForm.formvalues.company",
                value: res.data.data.data[0].work_field.branch.company.id,
              },
              {
                prop: "pointForm.formvalues.place_type",
                value: res.data.data.data[0].place_type,
              },
            ],
          });
        } else {
          confirmSunccessed(local.NoMerchant)
        }
      });
  };
