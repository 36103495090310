import {
  destroyerGenerator,
  setterGenerator,
} from "../../utils/GeneralFunctions";
import {
  CLEAR_TABLE_PROPS,
  DELETE_TABLE_PROPS,
  UPDATE_TABLE_PROPS,
} from "./types";

export const setTableProps = (propsArr) =>
  setterGenerator(UPDATE_TABLE_PROPS, propsArr);

export const deleteTableProps = (propsArr) =>
  destroyerGenerator(DELETE_TABLE_PROPS, propsArr);

export const clearTable = () => ({
  type: CLEAR_TABLE_PROPS,
});
