import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Header from "../../../routing/Layout/Header";
import { getUser } from "../../../utils/GeneralFunctions";
import { local, getLanguage } from "../../../lang/local";
import UsageHeader from "../UsageHeader";
import UsageLinkDiv from "./UsageLinkDiv";
const Sidebar = React.lazy(() => import("../../../routing/Layout/Sidebar"));

class UsageLinks extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { history } = this.props;
    return (
      <div className="conatiner " style={{ direction: "ltr" }}>
        {getUser() !== null ? (
          <div>
            <Header history={history} />
            {/* <div className="mt-4">
              <SideBar history={history} activeMenuItem="usage" />
            </div> */}
          </div>
        ) : null}

        <Row
          className={getUser() === null ? "ml-3 mr-3 mb-3 mt-3" : ""}
          style={{
            flexDirection: getLanguage() === "en" ? "row" : "row-reverse",
          }}
        >
          <div>
            <Sidebar />
          </div>

          <Col className="mt-4 body-container">
            <UsageHeader {...this.props} />

            <div
              id="usage-body-container"
              style={{ direction: getLanguage() === "ar" ? "rtl" : "ltr" }}
              className="row mx-3 mt-3 px-5 py-5"
            >
              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.SendANotification}
                link={`usage-video/${local.UsageVideos.SendANotification}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddADailyReport}
                link={`usage-video/${local.UsageVideos.AddADailyReport}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddAVisit}
                link={`usage-video/${local.UsageVideos.AddAVisit}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddACompany}
                link={`usage-video/${local.UsageVideos.AddACompany}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddAcompanyAndActivateIt}
                link={`usage-video/${local.UsageVideos.AddAcompanyAndActivateIt}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddASample}
                link={`usage-video/${local.UsageVideos.AddASample}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddABranch}
                link={`usage-video/${local.UsageVideos.AddABranch}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddACity}
                link={`usage-video/${local.UsageVideos.AddACity}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddAVisitPlace}
                link={`usage-video/${local.UsageVideos.AddAVisitPlace}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddAWorkPlace}
                link={`usage-video/${local.UsageVideos.AddAWorkPlace}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddAProduct}
                link={`usage-video/${local.UsageVideos.AddAProduct}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddARegion}
                link={`usage-video/${local.UsageVideos.AddARegion}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.AddAnEmployee}
                link={`usage-video/${local.UsageVideos.AddAnEmployee}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.GetABusinessReport}
                link={`usage-video/${local.UsageVideos.GetABusinessReport}`}
              />

              <UsageLinkDiv
                {...this.props}
                text={local.UsageVideos.GetAFastReport}
                link={`usage-video/${local.UsageVideos.GetAFastReport}`}
              />
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UsageLinks;
