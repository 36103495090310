import {
  CLEAR_STATE,
  UPDATE_SEARCH_FORM_PROPS,
  MERGE_SEARCH_FORM_PROPS,
  DELETE_SEARCH_FORM_PROPS,
  CLEAR_SEARCH_FORM_PROPS,
} from "../actions/types";
import * as general from "./methods";

import dotProp from "dot-prop-immutable";
import { SET_FILTER, SET_IS_LOADING } from "../actions/types";

export const SEARCH_FORM = "SEARCH_FORM/";

const INITIAL_STATE = {
  filters: {
    company: null,
    companyID: null,
    branchID: null,
    workFieldID: null,
    workFieldIDs: null,
    pointID: null,
    pointIDs: null,
    workerID: null,
    worker: null,
    workerIDs: null,
    from: "",
    to: "",
    year: null,
    page: 1,
  },
  isLoading: false,
  isLoading2: false,
};

export default (state = INITIAL_STATE, action) => {
  const setState = (prop, value) => {
    return dotProp.set(state, prop, value);
  };

  switch (action.type) {
    case UPDATE_SEARCH_FORM_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_SEARCH_FORM_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_SEARCH_FORM_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_SEARCH_FORM_PROPS: {
      return INITIAL_STATE;
    }
    case CLEAR_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    case SET_FILTER: {
      return setState(`filters.${action.filter}`, action.value);
    }
    case SET_IS_LOADING: {
      return setState("isLoading", action.isLoading);
    }
    case `${SET_IS_LOADING}2`: {
      return setState("isLoading2", action.isLoading);
    }
    default:
      return state;
  }
};
