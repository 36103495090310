import { isArray } from "../../components/GenericMapy/utils";
import CustomInfoWindow from "../../components/InfoWindow/InfoWindow";
import {
} from "../../utils/MapPinImg";
import GENERAL_MARKER from '../../assets/img/MapPin/general marker.png';

export const SelectPointsMarkers = (points, newPoint) => {
  if (!points) return null;
  if (!isArray(points)) return null;
  const markers = [];
  for (let i = 0; i < points.length; i++) {
    const point = points[i];
    const markerPopup = CustomInfoWindow({
      placeName: point.name,
    });

    const marker = {
      ...point,
      latLng: point.location,
      markerIcon: {
        url: GENERAL_MARKER,
        
        // renderAppropriatePin(point.name),
        imgSize: [30, 43],
      },
      popup: markerPopup,
    };
    markers.push(marker);
  }
  const length = markers.length;
  if (newPoint) {
    if (length > 0) {
      const lastPoint = markers[length - 1];
      if (lastPoint.hasOwnProperty("newPoint")) {
        lastPoint.latLng = newPoint;
      } else {
        markers[length] = {
          id: "newPoint",
          newPoint: true,
          latLng: newPoint,
          location: newPoint,
        };
      }
    } else {
      markers[length] = {
        id: "newPoint",
        newPoint: true,
        latLng: newPoint,
        location: newPoint,
      };
    }
  }
  return markers;
};
