import React from 'react';
import { Card, Col, Row, Button, Spinner, Form } from 'reactstrap';
import {
  renderDateInput,
  renderSearchSelcet,
  renderArraw,
  checkHasOneCompany,
  getWorkFieldOptions,
  workFieldPermissionedForDropDown,
  handleSelectChangeEnhanced,
} from '../../utils/GeneralFunctions';
import { leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import { UPDATE_ACTION_POINT_PROPS } from '../../redux/actions/types';
import toaster from 'toasted-notes';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { renderWorkerSeelct } from '../Workers/WorkerFunctions';
import { renderWorkFieldPointSelect } from '../Requests/RequestsFunctions';
import { renderBranchSelect } from '../Branches/BranchesFunction';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
import { ZoomableShapes } from 'mapy_sdk/lib/mapyTypes';

const ActionPointSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    companyOptions,
    getActionPoints,
    general,
    workerOptions,
    history,
    visitStatusOptions,
    pointsOptions,
    loadWorkerOptions,
    loadCompaniesOptions,
    loadpointsOptions,
    branchOptions,
    loadBranchesOptions,
    setActionPointsProps,
    map,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();
    searchForm.page = 1;
    if (searchForm.company !== undefined) {
      if (searchForm.company === null) {
        toaster.notify(local.ChooseCompany, {
          duration: 2000,
        });
      } else if (searchForm.branch_id && !!!searchForm.ids) {
        toaster.notify(local.ChooseCompanyWorkField, {
          duration: 2000,
        });
      } else {
        map.zoomToFeatures([{ id: 'workFields', type: ZoomableShapes.group }]);
        getActionPoints(true, searchForm);
      }
    } else {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {local.ActionPoints}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
              {renderCompanySelect(
                'searchForm.company',
                searchForm.company,
                UPDATE_ACTION_POINT_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: 'searchForm.ids', v: null },
                  { p: 'searchForm.worker_id', v: null },
                  { p: 'searchForm.branch_id', v: null },
                ],
                null
              )}
            </Col>
          ) : null}

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderBranchSelect(
              'searchForm.branch_id',
              searchForm.branch_id,
              UPDATE_ACTION_POINT_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              [{ p: 'searchForm.ids', v: null }],
              null
            )}
          </Col>
          {/* <div className="react-select-div mt-4">
          {renderWorkFSelect(
            "searchForm.ids",
            searchForm.ids,
            UPDATE_ACTION_POINT_PROPS,
            general,
            local.WorkPlaces,
            loadCompaniesWFOptions,
            companyWfOptions,
            true,
            ["searchForm.point_id"],
            searchForm.branch_id,
            null,
            null
          )}
        </div> */}

          <CustomAsyncPaginate
            // loadOptions={getWorkFieldOptions}
            loadOptions={getWorkFieldOptions(searchForm.branch_id)}
            value={searchForm.ids}
            placeholder={local.WorkField}
            cacheUniqsArr={[searchForm.branch_id]}
            // isMulti={true}
            onChange={(e) => {
              // handleSelectChange(
              //   e,
              //   "searchForm.ids",
              //   setActionPointsProps,
              //   ["searchForm.point_id"],
              //   true
              // )

              if (e) {
                handleSelectChangeEnhanced(
                  [e],
                  'searchForm.ids',
                  setActionPointsProps,
                  ['searchForm.point_id']
                );
              } else {
                handleSelectChangeEnhanced(
                  [],
                  'searchForm.ids',
                  setActionPointsProps,
                  ['searchForm.point_id']
                );
              }
              // handleSelectChange(
              //   e,
              //   "searchForm.ids",
              //   setActionPointsProps,
              //   ["searchForm.point_id"],
              //   true
              // )
            }}
            filterOption={(option, rawInput) =>
              workFieldPermissionedForDropDown({ option, rawInput })
            }
            additional={{
              page: 1,
              branchId: searchForm.branch_id,
            }}
          />

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkFieldPointSelect(
              'searchForm.point_id',
              searchForm.point_id,
              UPDATE_ACTION_POINT_PROPS,
              general,
              loadpointsOptions,
              pointsOptions,
              null,
              searchForm.ids,
              null,
              null
            )}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderSearchSelcet(
              local.VisitStatus,
              'searchForm.action',
              searchForm.action,
              UPDATE_ACTION_POINT_PROPS,
              general,
              visitStatusOptions,
              null,
              searchForm.company,
              undefined
            )}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkerSeelct(
              'searchForm.worker_id',
              searchForm.worker_id,
              UPDATE_ACTION_POINT_PROPS,
              general,
              loadWorkerOptions,
              workerOptions,
              searchForm.company,
              null
            )}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_ACTION_POINT_PROPS,
              general,
              local.FromDate,
              true,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_ACTION_POINT_PROPS,
              general,
              local.ToDate,
              true,
              searchForm.from
            )}
          </Col>
          <Col xl={3} className='mt-4'>
            <Button
              type='submit'
              style={leftlStyle}
              disabled={isLoadingSearch}
              className='btn_search_style'
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ActionPointSearch;
