import React, { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../../redux/actions/index";
import { local } from "../../../lang/local";
import { onSave } from "../RequestsFunctions";
import "./wizzardNav.css";
import { UPDATE_REQUESTS_PROPS } from "../../../redux/actions/types";

const WizardNav = ({
  general,
  lastStep,
  currentStep,
  goToStep,
  nextStep,
  activeStepHandler,
  createORUpdatePoint,
  pointForm,
  requestsObj,
  companyWfOptions,
}) => {
  useEffect(() => {
    console.log(currentStep);
    activeStepHandler(currentStep);
  }, [activeStepHandler, currentStep]);

  return (
    <div className="mx-auto">
      {currentStep === 3 ? (
        <button
          className="bg-main px-4 py-3 mt-8 bg-opacity-25 rounded-md text-white"
          onClick={(e) => {
            onSave(
              e,
              createORUpdatePoint,
              pointForm,
              requestsObj,
              false,
              companyWfOptions
            );
          }}
        >
          {local.Save}
        </button>
      ) : (
        <button
          className="bg-main px-4 py-3 mt-8 bg-opacity-25 rounded-md text-white"
          onClick={(e) => {
            nextStep();
          }}
        >
          {local.Next}
        </button>
      )}

      <ul className="list-none flex justify-evenly gap-x-2 mx-auto mt-10">
        {Array.apply(null, Array(3)).map((el, i) => (
          <li
            key={i}
            className={`${
              currentStep === i + 1 ? "bg-gray-600" : "bg-gray-400"
            } w-2 h-2  rounded-full cursor-pointer transition`}
            onClick={() => goToStep(i + 1)}
          ></li>
        ))}
      </ul>
    </div>
  );
};

const mapStateToProps = ({ RequestsR, CompanyWorkFieldR }) => {
  return {
    pointForm: RequestsR.pointForm,
    requestsObj: RequestsR.requestsObj,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
  };
};

export default connect(mapStateToProps, actions)(WizardNav);
