import React from 'react';
import { Card, Col, Row, Table } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderPagination,
  getPermissionValue,
  renderCreateButton,
  renderEditButton,
  renderEditTitle,
} from '../../utils/GeneralFunctions';
import { UPDATE_STATE_WORK_FIELD_PROPS } from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { leftlStyle } from '../../utils/GeneralStyle';

const StateWorkFieldsTable = (props) => {
  const { stateWFObj, general, sorting, searchForm, getStatesWorkFields } =
    props;

  const onEdit = (e, item, index) => {
    e.preventDefault();
    general(
      [
        { prop: 'stateWFForm.formvalues', value: item },
        { prop: 'stateWFForm.formvalues.state_id', value: item.state.id },
        { prop: 'stateWFForm.isLoading', value: false },
        { prop: 'modale.isOpen', value: true },
        { prop: 'stateWFForm.index', value: index },
        { prop: 'modale.actionType', value: 'EditStateWorkField' },
        { prop: 'modale.title', value: local.updateStateWorkfield },
        { prop: ' stateWFForm.isCreate', value: 'update' },
      ],
      UPDATE_STATE_WORK_FIELD_PROPS
    );
  };

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: 'modale.title', value: local.addStateWorkField },
        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddStateWorkField' },
      ],
      UPDATE_STATE_WORK_FIELD_PROPS
    );
  };
  const renderBody = () => {
    if (stateWFObj.stateWFItem !== null) {
      return stateWFObj.stateWFItem.data.map((stateWF, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(stateWF.id)}</td>
            <td className='td_table'>
              {typeof stateWF.name === 'object'
                ? stateWF.name[local.lang]
                : stateWF.name}
            </td>
            <td className='td_table'>{stateWF.state.name[local.lang]}</td>

            <td className='td_table'>{renderNumber(stateWF.mobile)}</td>
            <td className='td_table'>{stateWF.address}</td>

            {renderEditButton(
              getPermissionValue()['states/work_fields/update/{id}'],
              onEdit,
              stateWF,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      {renderCreateButton(
        getPermissionValue()['states/work_fields/store'],
        onCreate,
        local.AddStateWorkField
      )}

      <Row>
        <Table
          responsive
          id='stateWf_table'
          hover
          className='table_list mt-4 mb-4'
          style={{
            borderTopColor: 'white',
            border: 'none',
            position: 'relative',
          }}
        >
          <thead>
            <tr className='table_head'>
              {renderTableHeadItem(
                local.Id,
                stateWFObj.stateWFItem,
                'id',
                'stateWFObj.stateWFItem.data',
                UPDATE_STATE_WORK_FIELD_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.Name,
                stateWFObj.stateWFItem,
                'name',
                'stateWFObj.stateWFItem.data',
                UPDATE_STATE_WORK_FIELD_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.Province,
                stateWFObj.stateWFItem,
                'state.name',
                'stateWFObj.stateWFItem.data',
                UPDATE_STATE_WORK_FIELD_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.Mobile,
                stateWFObj.stateWFItem,
                'mobile',
                'stateWFObj.stateWFItem.data',
                UPDATE_STATE_WORK_FIELD_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.Address,
                stateWFObj.stateWFItem,
                'address',
                'stateWFObj.stateWFItem.data',
                UPDATE_STATE_WORK_FIELD_PROPS,
                general,
                sorting
              )}
              {renderEditTitle('states/work_fields/update/{id}')}
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </Table>
      </Row>
      {stateWFObj.stateWFItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='stateWf_table'
              filename={local.StateWorkFields}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {stateWFObj.stateWFItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}
      {renderPagination(
        stateWFObj.stateWFItem,
        searchForm,
        getStatesWorkFields
      )}
    </Card>
  );
};

export default StateWorkFieldsTable;
