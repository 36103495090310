import React, { Component } from "react";
import { Card, Col, Row, CardBody, Spinner, Form } from "reactstrap";
import { local } from "../../lang/local";
import icon_btn from "../../assets/img/icon_btn.png";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { UPDATE_STATE_WORK_FIELD_PROPS } from "../../redux/actions/types";
import {
  renderArabicNameInput,
  renderEnglishNameInput,
  renderPhoneInput,
  renderGeneralInput,
} from "../../utils/GeneralFunctions";
import { onSave } from "./StateWorkFieldFunctions";
import { renderStateSelect } from "../States/StateFunction";

class CreateUpdateStateWF extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { getStatesForDropDown, stateWFForm, getState, modale } = this.props;
    if (modale.actionType === "AddStateWorkField") {
      getStatesForDropDown();
    } else {
      if (
        modale.actionType === "EditStateWorkField" &&
        stateWFForm.formvalues.state_id !== null
      ) {
        getState(stateWFForm.formvalues.state_id, true);
      }
    }
  };
  render() {
    const {
      stateWFForm,
      general,
      stateOptions,
      modale,
      createORUpdateStateWF,
      loadStateOptions,
    } = this.props;

    return (
      <Form
        onSubmit={(e) => onSave(e, createORUpdateStateWF, stateWFForm, true)}
      >
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              <CardBody className="trans">
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderEnglishNameInput(
                      "stateWFForm.formvalues.name.en",
                      stateWFForm.formvalues.name.en,
                      UPDATE_STATE_WORK_FIELD_PROPS,
                      general
                    )}
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderArabicNameInput(
                      "stateWFForm.formvalues.name.ar",
                      stateWFForm.formvalues.name.ar,
                      UPDATE_STATE_WORK_FIELD_PROPS,
                      general
                    )}
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderGeneralInput(
                      "stateWFForm.formvalues.address",
                      stateWFForm.formvalues.address,
                      UPDATE_STATE_WORK_FIELD_PROPS,
                      general,
                      local.Address
                    )}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderPhoneInput(
                      "stateWFForm.formvalues.mobile",
                      stateWFForm.formvalues.mobile,
                      UPDATE_STATE_WORK_FIELD_PROPS,
                      general
                    )}
                  </Col>
                </Row>

                <Row className="mt-5">
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderStateSelect(
                      "stateWFForm.formvalues.state_id",
                      stateWFForm.formvalues.state_id,
                      UPDATE_STATE_WORK_FIELD_PROPS,
                      general,
                      loadStateOptions,
                      stateOptions,
                      stateWFForm.isCreate
                    )}
                  </Col>
                </Row>
              </CardBody>
              <center>
                <button
                  type="submit"
                  disabled={stateWFForm.isLoading}
                  className="save_btn mt-5"
                  onClick={(e) =>
                    onSave(e, createORUpdateStateWF, stateWFForm, true)
                  }
                >
                  {stateWFForm.isLoading === true ? (
                    <Spinner type="grow" color="dark" size="sm" />
                  ) : (
                    <>
                      <p style={{ display: "inline" }}>{local.Save} </p>
                      <img src={icon_btn} width="20" height="20" alt="img" />
                    </>
                  )}
                </button>
              </center>
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ StateWFR, StateR }) => {
  return {
    stateWFForm: StateWFR.stateWFForm,
    stateOptions: StateR.stateOptions,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateStateWF);
