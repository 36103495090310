import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
} from "../../utils/GeneralFunctions";
import { Request } from "../Api/index";
import { UPDATE_AMOUNT_PRODUCT_PROPS } from "./types";
import { local } from "../../lang/local";

export const getAmounts = (isSearching, searchForm) => (dispatch) => {
  let url = "/products/amounts";

  dispatch({
    type: UPDATE_AMOUNT_PRODUCT_PROPS,
    payload: [{ prop: "amountObj.amountItem", value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_AMOUNT_PRODUCT_PROPS,
      payload: [{ prop: "amountObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_AMOUNT_PRODUCT_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  if (searchForm !== null) {
    let searchItems = null;
    searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      worker_id: searchForm.workerID,
      point_id: searchForm.pointID,
      page: searchForm.page,
    };

    let newSearchForm = removeUnsetValues(searchItems);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      if (searchForm.action === "all") {
        param += "&action=false&action=not_take";
      }
      var values = "";
      searchForm.ids.map((d, i) => {
        if (i === 0) {
          values = "ids[]=" + d.value;
        } else {
          values += "&ids[]=" + d.value;
        }
      });
      url += param + "&" + values;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_AMOUNT_PRODUCT_PROPS,
            payload: [
              {
                prop: "amountObj.amountItem",
                value: res.data.data,
              },
            ],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_AMOUNT_PRODUCT_PROPS,
        payload: [
          { prop: "amountObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_AMOUNT_PRODUCT_PROPS,
        payload: [
          { prop: "amountObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};
