import React, { Component } from "react";
import { Form, Card, Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { onSave } from "./CompanyWFFunctions";
import { labelStyle, leftlStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from "../../redux/actions/types";
import BoxLink from "../../utils/BoxLink";
import icon_btn from "../../assets/img/icon_btn.png";
import {
  renderGeneralInput,
  renderArabicNameInput,
  renderEnglishNameInput,
  renderArraw,
  getMyCompany,
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";
import SearchBox from "../../utils/SearchBox";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { unsetCompanyWFListData } from "./CompanyWFFunctions";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import { Mapy } from "mapy_sdk/lib";
import GenericMapy from "../../components/GenericMapy/GenericMapy";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";

let map = new Mapy();

class AddCompainesWF extends Component {
  componentDidMount() {
    unsetCompanyWFListData(this.props.general);

    this.props.general(
      [
        {
          prop: "companyWfForm.company",
          value: getMyCompany(this.props.general),
        },
        {
          prop: "companyWfForm.formvalues.company",
          value: getMyCompany(this.props.general),
        },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  }

  componentWillUnmount() {
    this.props.setCompanyWorkFieldProps([["allCompanyWorkFields", null]]);
  }

  onPolygonDraw = (polygon) => {
    const { general } = this.props;
    general(
      [{ prop: "companyWfForm.formvalues.location", value: polygon }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  onPolygonEdit = (polygon) => {
    const { general } = this.props;
    general(
      [{ prop: "companyWfForm.formvalues.location", value: polygon }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  onPolygonDelete = () => {
    const { general } = this.props;
    general(
      [{ prop: "companyWfForm.formvalues.location", value: null }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  render() {
    const {
      createORUpdateCompanyWF,
      companyWfForm,
      companyWfObj,
      history,
      general,
      companyOptions,
      searchOptions,
      get_search,
      loadCompaniesOptions,
      loadBranchesOptions,
      branchOptions,
      setCompanyWorkFieldProps,
    } = this.props;
    return (
      <div className="page-wrapper">
        <Row style={{ height: "100%" }}>
          <Col className="body-container">
            <Form
              onSubmit={(e) =>
                onSave(
                  e,
                  createORUpdateCompanyWF,
                  companyWfForm,
                  companyWfObj,
                  false
                )
              }
              style={{ height: "100%" }}
            >
              <Card className="cardCreate ml-2 mr-2 mt-4">
                <div className="mt-5 mr-5 mt-3 ml-5">
                  <p style={leftlStyle}>{local.AddACompanyWF}</p>
                  {renderArraw(history)}
                  <br /> <br />
                  <p className="page_title mt-3" style={leftlStyle}>
                    {local.MainInfo}
                  </p>
                  <br />
                  <Row className="mt-5 justify-content-center justify-content-lg-start">
                    <div className="mt-4 react-select-div">
                      {renderGeneralInput(
                        "companyWfForm.formvalues.address",
                        companyWfForm.formvalues.address,
                        UPDATE_COMPANY_WORK_FIELD_PROPS,
                        general,
                        local.Address
                      )}
                    </div>
                    <div className="mt-4 react-select-div">
                      {renderEnglishNameInput(
                        "companyWfForm.formvalues.name.en",
                        companyWfForm.formvalues.name.en,
                        UPDATE_COMPANY_WORK_FIELD_PROPS,
                        general
                      )}
                    </div>
                    <div className="mt-4 react-select-div">
                      {renderArabicNameInput(
                        "companyWfForm.formvalues.name.ar",
                        companyWfForm.formvalues.name.ar,
                        UPDATE_COMPANY_WORK_FIELD_PROPS,
                        general
                      )}
                    </div>
                  </Row>
                  <p className="page_title mt-5" style={leftlStyle}>
                    {local.WorkPlaces}
                  </p>
                  <br />
                  <br />
                  <Row className="mt-5 justify-content-center justify-content-lg-start">
                    {checkHasOneCompany() === false ? (
                      <div className="mt-4 react-select-div">
                        <span className="label_title" style={labelStyle}>
                          {local.Company}
                        </span>
                        {renderCompanySelect(
                          "companyWfForm.company",
                          companyWfForm.company,
                          UPDATE_COMPANY_WORK_FIELD_PROPS,
                          general,
                          local.Company,
                          loadCompaniesOptions,
                          companyOptions,
                          null,
                          [
                            {
                              p: "companyWfForm.parent_branch",
                              v: null,
                            },
                            {
                              p: "companyWfForm.formvalues.branch_id",
                              v: null,
                            },
                          ],
                          "4",
                          companyWfForm.iscreate
                        )}
                      </div>
                    ) : null}

                    <div className="mt-4 react-select-div">
                      <CustomAsyncPaginate
                        hasLabel
                        placeholder={local.Regions}
                        fullWidth
                        loadOptions={getBranchOptions(companyWfForm.company, {
                          parent_id: 0,
                        })}
                        value={companyWfForm.parent_branch}
                        cacheUniqsArr={[companyWfForm.company]}
                        onChange={(e) =>
                          handleSelectChangeEnhanced(
                            e,
                            "companyWfForm.parent_branch",
                            setCompanyWorkFieldProps,
                            ["companyWfForm.formvalues.branch_id"]
                          )
                        }
                        additional={{
                          page: 1,
                        }}
                        isDisabled={!!!companyWfForm.company}
                      />
                    </div>
                    <div className="mt-4 react-select-div">
                      <CustomAsyncPaginate
                        hasLabel
                        isDisabled={!!!companyWfForm.parent_branch}
                        placeholder={local.Cities}
                        fullWidth
                        loadOptions={getBranchOptions(companyWfForm.company, {
                          parent_id: companyWfForm.parent_branch?.id,
                        })}
                        value={companyWfForm.formvalues.branch_id}
                        cacheUniqsArr={[companyWfForm.parent_branch]}
                        onChange={(e) =>
                          handleSelectChangeEnhanced(
                            e,
                            "companyWfForm.formvalues.branch_id",
                            setCompanyWorkFieldProps,
                            []
                          )
                        }
                        additional={{
                          page: 1,
                        }}
                      />
                    </div>
                    {/* {renderBranchSelect(
                        "companyWfForm.formvalues.branch_id",
                        companyWfForm.formvalues.branch_id,
                        UPDATE_COMPANY_WORK_FIELD_PROPS,
                        general,
                        local.Branch,
                        loadBranchesOptions,
                        branchOptions,
                        companyWfForm.company,
                        null,
                        null,
                        "4",
                        companyWfForm.iscreate
                        // {
                        //   action: getAllWorkFields,
                        //   action_create: setCompanyWorkFieldProps,
                        //   prop: "allCompanyWorkFields",
                        // }
                      )}
                    </div> */}

                    <div className="mt-4 react-select-div">
                      <SearchBox
                        searchOptions={searchOptions}
                        endPoint={get_search}
                        action={UPDATE_COMPANY_WORK_FIELD_PROPS}
                        general={general}
                        map={map}
                      />
                    </div>
                  </Row>
                  <Row>
                    <Col xl={12} className="mt-4">
                      <BoxLink
                        general={general}
                        prop="modale.mapItem.mapPoints"
                        action={UPDATE_COMPANY_WORK_FIELD_PROPS}
                        map={map}
                      />
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                      {/* remove polygon button */}
                      {/* {companyWfForm.formvalues.location !== null ? (
                        <a
                          style={{
                            cursor: "pointer",
                            fontSize: "13px",
                            color: "#00beac",
                          }}
                          onClick={() =>
                            general(
                              [
                                {
                                  prop: "companyWfForm.formvalues.location",
                                  value: null,
                                },
                              ],
                              UPDATE_COMPANY_WORK_FIELD_PROPS
                            )
                          }
                        >
                          <FiXCircle /> {" " + local.RemovePolygon}
                        </a>
                      ) : null} */}
                      <Card
                        className="mt-4"
                        style={{ borderRadius: "10px", height: "450px" }}
                      >
                        {/* <GeneralMap
                          {...this.props}
                          general={general}
                          formvalues={companyWfForm.formvalues}
                          isDrawing={true}
                          action={UPDATE_COMPANY_WORK_FIELD_PROPS}
                          modale={modale}
                          prop="companyWfForm.formvalues.location"
                          // drawingPolygon={companyWfForm.formvalues.location}
                          reloadDrawing={companyWfForm.reloadDrawing}
                          workerPolygons={allCompanyWorkFields}
                          page="addArea"
                          goToLinkCoordinates={modale.mapItem.mapPoints}
                        /> */}

                        <GenericMapy
                          map={map}
                          mapID="addWorkFieldMap"
                          singlePolygon={companyWfForm.formvalues.location}
                          drawingControls={true}
                          isPolygonEditable={true}
                          onPolygonDraw={this.onPolygonDraw}
                          onPolygonEdit={this.onPolygonEdit}
                          onPolygonDelete={this.onPolygonDelete}
                        />
                      </Card>
                    </Col>
                  </Row>
                  <center className="mt-5">
                    <button
                      type="submit"
                      disabled={companyWfForm.isLoading}
                      className="save_btn mt-3"
                      onClick={(e) =>
                        onSave(
                          e,
                          createORUpdateCompanyWF,
                          companyWfForm,
                          companyWfObj,
                          false
                        )
                      }
                    >
                      {companyWfForm.isLoading === true ? (
                        <Spinner type="grow" color="dark" size="sm" />
                      ) : (
                        <>
                          <p style={{ display: "inline" }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width="20"
                            height="20"
                            alt="img"
                          />
                        </>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
  MapReducer,
}) => {
  return {
    companyWfForm: CompanyWorkFieldR.companyWfForm,
    modale: CompanyWorkFieldR.modale,
    companyOptions: CompanyR.companyOptions,
    companyWfObj: CompanyWorkFieldR.companyWfObj,
    searchOptions: CompanyWorkFieldR.searchOptions,
    branchOptions: BranchR.branchOptions,
    allCompanyWorkFields: CompanyWorkFieldR.allCompanyWorkFields,
    MapReducer: MapReducer,
  };
};

export default connect(mapStateToProps, actions)(AddCompainesWF);
