import React from 'react';
import { Input } from 'reactstrap';
import readXlsxFile from 'read-excel-file';
import toaster from 'toasted-notes';
import { local } from '../lang/local';
import { validateEmail } from './GeneralFunctions';

const FlieCreate = (props) => {
  const { actionType, general, action } = props;

  const storeData = (data) => {
    let header = data[0];
    let verified = false;

    let select_indexs = [];

    data.shift();
    let columns = [];

    if (actionType === 'state') {
      let EnglishName = header.includes('EnglishName');
      let ArabicName = header.includes('ArabicName');
      columns.push(
        { prop: local.EnglishNameColumRequired, value: EnglishName },
        { prop: local.ArabicNameColumRequired, value: ArabicName }
      );
      if (
        validateColumn(columns) === true &&
        validateDataIsNotEmpty(data, header) === true
      ) {
        verified = true;
        // header[+header.length] = local.Province;

        data.map((dr) => {
          select_indexs.push(+dr.length);
          // dr[+dr.length] = null;
        });
      }
    } else if (actionType === 'stateWF') {
      let EnglishName = header.includes('EnglishName');
      let ArabicName = header.includes('ArabicName');
      let Address = header.includes('Address');
      let Mobile = header.includes('Mobile');

      columns.push(
        { prop: local.EnglishNameColumRequired, value: EnglishName },
        { prop: local.ArabicNameColumRequired, value: ArabicName },
        { prop: local.AddressColumRequired, value: Address },
        { prop: local.MobileColumRequired, value: Mobile }
      );
      if (
        validateColumn(columns) === true &&
        validateDataIsNotEmpty(data, header) === true
      ) {
        if (
          validateType(
            'number',
            data,
            header.indexOf('Mobile'),
            local.MobileTypedNumber
          ) === true
        ) {
          verified = true;
          header[+header.length] = local.Province;

          data.map((dr) => {
            select_indexs.push(+dr.length);
            dr[+dr.length] = null;
          });
        }
      }
    } else if (actionType === 'company') {
      let EnglishName = header.includes('EnglishName');
      let ArabicName = header.includes('ArabicName');
      let Address = header.includes('Address');
      let Mobile = header.includes('Mobile');
      let Tax = header.includes('Tax');

      columns.push(
        { prop: local.EnglishNameColumRequired, value: EnglishName },
        { prop: local.ArabicNameColumRequired, value: ArabicName },
        { prop: local.AddressColumRequired, value: Address },
        { prop: local.MobileColumRequired, value: Mobile },
        { prop: local.TaxColumRequired, value: Tax }
      );
      if (
        validateColumn(columns) === true &&
        validateDataIsNotEmpty(data, header) === true
      ) {
        if (
          validateType(
            'number',
            data,
            header.indexOf('Mobile'),
            local.MobileTypedNumber
          ) === true &&
          validateType(
            'number',
            data,
            header.indexOf('Tax'),
            local.TaxTypedNumber
          ) === true
        ) {
          verified = true;
          header[+header.length] = local.Logo;
          header[+header.length] = local.StateWorkFields;
          header[+header.length] = local.Governorate;

          data.map((dr) => {
            select_indexs.push(+dr.length, +dr.length + 1, +dr.length + 2);

            dr.push(false, null, null);
          });
        }
      }
    } else if (actionType === 'worker') {
      let Name = header.includes('Name');
      let Email = header.includes('Email');
      let Address = header.includes('Address');
      let Mobile = header.includes('Mobile');
      let UserName = header.includes('UserName');
      let NationalId = header.includes('NationalId');
      let Password = header.includes('Password');
      let ConfirmPassword = header.includes('ConfirmPassword');
      let JobTitle = header.includes('JobTitle');
      let WorkerType = header.includes('WorkerType');

      columns.push(
        { prop: local.NameColumRequired, value: Name },
        { prop: local.EmailColumRequired, value: Email },
        { prop: local.AddressColumRequired, value: Address },
        { prop: local.MobileColumRequired, value: Mobile },
        { prop: local.UserNameColumRequired, value: UserName },
        { prop: local.NationalIdColumnRequired, value: NationalId },
        { prop: local.PasswordColumnRequired, value: Password },
        { prop: local.ConfirmPasswordColumnRequired, value: ConfirmPassword },
        { prop: local.JobTitlesColumnRequired, value: JobTitle },
        { prop: local.WorkerTypeColumnRequired, value: WorkerType }
      );
      if (
        validateColumn(columns) === true &&
        validateDataIsNotEmpty(data, header) === true
      ) {
        if (
          validateType(
            'number',
            data,
            header.indexOf('Mobile'),
            local.MobileTypedNumber
          ) === true &&
          validateType(
            'number',
            data,
            header.indexOf('NationalId'),
            local.NationalTypedId
          ) === true &&
          validateType(
            'email',
            data,
            header.indexOf('Email'),
            local.InvalidEmail
          ) === true &&
          validateType(
            'password',
            data,
            header.indexOf('Password'),
            local.PasswordLength
          ) === true &&
          validateType(
            'WorkerType',
            data,
            header.indexOf('WorkerType'),
            local.WorkerTypeError
          ) === true &&
          validateType(
            'password',
            data,
            header.indexOf('ConfirmPassword'),
            local.ConfirmPasswordLength
          )
        ) {
          if (
            validateType(
              'password',
              data,
              header.indexOf('ConfirmPassword'),
              local.PasswordEquals,
              header.indexOf('Password')
            ) === true
          ) {
            verified = true;
            header[+header.length] = local.Logo;
            header[+header.length] = local.Company;
            header[+header.length] = local.Branch;
            header[+header.length] = local.Supervisor;
            header[+header.length] = local.WorkPlaces;
            header[+header.length] = local.WorkFieldPoint;

            data.map((dr) => {
              select_indexs.push(
                +dr.length,
                +dr.length + 1,
                +dr.length + 2,
                dr.length + 3,
                dr.length + 4,
                dr.length + 5
              );

              dr.push(false, null, null, null, null, null);
            });
          }
        }
      }
    }

    if (verified === true) {
      general(
        [
          {
            prop: 'fileOptions.select_indexs',
            value: select_indexs.filter((v, i, a) => a.indexOf(v) === i),
          },
          { prop: 'fileOptions.headerData', value: header },
          { prop: 'fileOptions.importedData', value: data },
          { prop: 'fileOptions.savedData', value: data },
          { prop: 'fileOptions.errorData', value: null },
        ],
        action
      );
    }
  };

  const validateColumn = (columns) => {
    if (columns.find((col) => col.value === false)) {
      let errors = [];

      columns.map((col) => {
        if (col.value === false) {
          errors.push(col.prop);
        }
      });
      general([{ prop: 'fileOptions.errorData', value: errors }], action);
    } else {
      return true;
    }
  };

  const validateDataIsNotEmpty = (data, header, type) => {
    if (data.length > 0) {
      if (data.find((d) => d.includes(null))) {
        let errors = [];
        data.map((row) => {
          row.map((col, index) => {
            if (col === null) {
              let item_name = header[index];
              if (type === undefined && item_name !== 'Mobile') {
                errors.push(local[item_name] + ' ' + local.EmptyColumn);
              }
            }
          });
        });
        general([{ prop: 'fileOptions.errorData', value: errors }], action);
      } else {
        return true;
      }
    } else {
      toaster.notify(local.DataEmpty, {
        duration: 2000,
      });
    }
  };
  const validateType = (type, data, index, errorMessage, index1) => {
    let checked_arr = [];

    data.map((r) => {
      if (type === 'number') {
        if (isNaN(r[index])) {
          checked_arr.push(r);
        }
      }
      if (type === 'email') {
        if (validateEmail(r[index] === false)) {
          checked_arr.push(r);
        }
      }
      if (type === 'password') {
        if (r[index].length < 6) {
          checked_arr.push(r);
        }
      }
      if (type === 'WorkerType') {
        if (r[index] === '1' || r[index] === '2') {
          checked_arr.push(r);
        }
      }

      if (index1 !== undefined) {
        if (r[index] !== r[index1]) {
          checked_arr.push(r);
        }
      }
    });

    if (checked_arr.length > 0) {
      general(
        [{ prop: 'fileOptions.errorData', value: [errorMessage] }],
        action
      );
    } else {
      return true;
    }
  };
  const onRead = (e) => {
    general(
      [
        { prop: 'fileOptions.select_indexs', value: null },
        { prop: 'fileOptions.headerData', value: null },
        { prop: 'fileOptions.importedData', value: null },
        { prop: 'fileOptions.savedData', value: null },
        { prop: 'fileOptions.errorData', value: null },
      ],
      action
    );
    if (e.target.value.includes('xlsx') || e.target.value.includes('xls')) {
      readXlsxFile(e.target.files[0]).then((rows) => {
        storeData(rows);
      });
    } else {
      toaster.notify(local.NotExcelFile, {
        duration: 2000,
      });
    }
  };
  return (
    <>
      <a
        class='btn btn-primary btn_link'
        style={{ color: 'white', cursor: 'pointer' }}
      >
        {local.ChooseFile}
        <Input
          type='file'
          style={{ height: '100%', cursor: 'pointer' }}
          className='file_create'
          onChange={(e) => onRead(e)}
          onClick={(e) => (e.target.value = null)}
        />
      </a>
      <span class='label label-info' id='upload-file-info'></span>
    </>
  );
};

export default FlieCreate;
