export const localization = {
  AboutUs: { en: "About Us", ar: "عنا" },
  AcademicRank: { en: "Academic Rank", ar: "الدرجة العلمية" },
  AcceptedVisits: { en: "Accepted", ar: "تم القبول" },
  ActionPoint: { en: "Action of Point", ar: "كود العمل" },
  ActionPoints: { en: "Business report", ar: "تقرير الاعمال" },
  ActionTaken: { en: "action taken for point", ar: "الإجراءات المتخذة للنقطة" },
  Active: { en: "Active", ar: "مفعل" },
  ActiveWorkers: { en: "Active Employees", ar: "الموظفون الناشطين" },
  Add: { en: "Add", ar: "اضافة" },
  AddACompanyWF: { en: "Add Zone", ar: "اضافة منطقة عمل" },
  AddCity: { en: "Add City", ar: "اضافة مدينة" },
  AddCommunicationChannel: {
    en: "Add Communication Channel",
    ar: "اضف وسيلة اتصال",
  },
  AddOrder: {
    en: "Add Order",
    ar: "إضافه طلب",
  },
  AddCompanyPlace: {
    en: "Add Company Place of Business",
    ar: "اضافة مكان عمل",
  },
  //tickt_localization
  Ticket_listening: {
    en: "listening",
    ar: "أستماع",
  },

  Ticket_show_File: {
    en: "Show File",
    ar: "عرض الملف",
  },

  Ticket_show_Image: {
    en: "Show Image",
    ar: "عرض الصوره",
  },

  Ticket_Machine_Serial: {
    en: "POS Serial",
    ar: "سيريال الماكينه",
  },

  Ticket_Email: {
    en: "Imei",
    ar: " الأيمى",
  },

  Ticket_Sim_SerialEmail: {
    en: "Sim Serial ",
    ar: "  سيريال السيم ",
  },

  Ticket_Merchant_number: {
    en: "Merchant ID",
    ar: " رقم التاجر ",
  },

  Ticket_Notes: {
    en: "Notes",
    ar: "ملاحظات",
  },

  agent_info: {
    en: "Agent Info",
    ar: " معلومات المندوب ",
  },

  Merchant: {
    en: "Merchant",
    ar: "التاجر",
  },
  Merchants: {
    en: "Merchants",
    ar: "التجار",
  },

  Requester_info: {
    en: "Requester Info",
    ar: "معلومات الطالب",
  },

  Ticket_id: {
    en: "Ticket ID",
    ar: "كود التذكره",
  },

  Ticket_name: {
    en: "Ticket",
    ar: "التذكره",
  },

  Submitting_Time: {
    en: " Submitting Time",
    ar: "وقت التقديم ",
  },

  Requester_Name: {
    en: "Requester Name",
    ar: "اسم الطالب",
  },

  Requester_City: {
    en: "Requester City",
    ar: "مدينة الطالب",
  },

  Requester_Functon: {
    en: "Requester Function",
    ar: "وسيله الطالب",
  },
   Requester_E_mail: {
    en: "Requester E-mail",
    ar: "إيميل الطالب",
  },
  Requester_Contact: {
    en: " Requester Contact ",
    ar: "هاتف الطالب",
  },

  TicketInfo_name: {
    en: "Name",
    ar: "الأسم",
  },
  Comments_s: {
    en: "Comments",
    ar: "الملاحظات",
  },
  TicketInfo_Phone: {
    en: "Phone",
    ar: "التليفون",
  },
  TicketInfo_District: {
    en: "District",
    ar: "منطقة",
  },
  TicketInfo_Email: {
    en: "Email",
    ar: "الأيميل",
  },
  TicketInfo_Content: {
    en: " Land Mark",
    ar: "علامه مميزه",
  },
  TicketInfo_Id: {
    en: "iD",
    ar: "هوية ",
  },
  TicketInfo_City: {
    en: "City",
    ar: "المدينه ",
  },
  //ticket

  AddCompanyWFPoint: { en: "Add Order", ar: "اضافة طلب" },
  AddCompanyWFPoints: { en: "Add Order", ar: "اضافة طلب" },
  AddFile: { en: "Add From File", ar: "اضافة عن طريق الملف" },
  AddGiftOrSample: { en: "Add Gift / Sample", ar: "أضف هدية / عينة" },
  AddMajorCompany: { en: "Add a major company", ar: "اضافة شركة" },
  AddMobile: { en: "Add Mobile", ar: "اضافة موبايل" },
  AddProduct: { en: "Add Product", ar: "اضافة منتج" },
  AddState: { en: "Add City", ar: "اضافة مدينة" },
  AddReportTemplete: { en: "Add Report Templete", ar: "إضافة نموذج تقرير" },
  AddUser: { en: "Add User", ar: "اضافة مستخدم" },
  AddToCoverageList: {
    en: "Add To Coverage List",
    ar: "اضف الى قائمة نقاط العمل",
  },
  AddToOrRemoveFromCoverageList: {
    en: "Add To Or Remove From Coverage List",
    ar: "اضف او احذف من قائمة نقاط العمل",
  },
  AddToTable: { en: "Add To Table", ar: "الاضافة الي الجدول" },
  AddVisit: { en: "Add Visit", ar: "اضافة زيارة" },
  AddWorker: { en: "Add Employee", ar: "اضافة موظف" },
  AddationalMobiles: {
    en: "If you want to add other phone numbers, the column name should be as follows",
    ar: "لو اردت ان تضيف ارقام تليفونات اخري يجب ان يكون اسم العمود كالاتي",
  },
  Addaworkplace: { en: "Add City", ar: "اضافة مدينة" },
  AddedBy: { en: "added by", ar: "مضافة  بواسطة" },
  Address: { en: "Address", ar: "العنوان" },
  AddressColumRequired: {
    en: "Address column does not exist",
    ar: "عمود العنوان غير موجود",
  },
  AddressColumn: {
    en: "Column for the Address Called (Address)",
    ar: "عمود للعنوان يسمى (Address)",
  },
  AddressRequired: { en: "address field is required", ar: "حقل العنوان مطلوب" },
  AdministrativeData: { en: "Administrative data", ar: "البيانات الإدارية" },
  AlertTitle: { en: "You are going to leave", ar: "ستقوم بالرحيل" },
  All: { en: "All", ar: "الكل" },
  AmanaTitle: {
    en: "System to follow up cleaning and operating processes",
    ar: "نظام متابعه عمليات النظافه والتشغيل",
  },
  Amount: { en: "Amount", ar: "الكمية" },
  AppointmentFees: { en: "Appointment Fees", ar: "سعر الكشف" },
  Approve: { en: "Approve", ar: "قبول" },
  Approved: { en: "Approved", ar: "مقبول" },
  April: { en: "April", ar: "أبريل" },
  Arabic: { en: "Arabic", ar: "عربي" },
  ArabicLang: {
    en: "Only Arabic Language is Allowed",
    ar: "مسموح اللغة العربية فقط",
  },
  ArabicName: { en: "Arabic Name", ar: "الاسم باللغة العربية" },
  ArabicNameColumRequired: {
    en: "Arabic name column does not exist",
    ar: "عمود الاسم باللغة العربية غير موجود",
  },
  ArabicNameColumn: {
    en: "Column for the name in English Called (ArabicName)",
    ar: "عمود للاسم باللغة العربية يسمى (ArabicName)",
  },
  ArabicNameRequired: {
    en: "arabic name field is required",
    ar: "حقل الاسم بالللغة العربية مطلوب",
  },
  Archives: { en: "Archives", ar: "الارشيف" },
  AreYouSure: { en: "Are you sure?", ar: "هل أنت متأكد" },
  Audio: { en: "Sound Recorder", ar: "مسجل الصوت" },
  August: { en: "August", ar: "أغسطس" },
  AvailableHours: { en: "Available Hours", ar: "الساعات المتاحة" },
  BirthDate: { en: "Birth Date", ar: "تاريخ الميلاد" },
  BirthDateColumn: {
    en: "Column for the Birth Day Called (BirthDay)",
    ar: "عمود لتاريخ الميلاد يسمى (BirthDay)",
  },
  BirthDay: { en: "Birth Day", ar: "تاريخ الميلاد" },
  BirthDayValidation: {
    en: "your age must be over 20 years old",
    ar: "سنك يجب ان يكون اكبر من 20 عام",
  },
  Branch: { en: "Cities", ar: "منطقة" },
  BranchSupervisor: { en: "Region Supervisor", ar: "مشرف المنطقة" },
  Branchcompany: { en: "Region", ar: "منطقة" },
  Branches: { en: "Regions", ar: "مناطق" },
  ChooseCompany: { en: "Please choose Company First", ar: "قم باختيار الشركة" },
  ChooseBranch: { en: "Please choose Region", ar: "قم باختيار المنطقة" },
  ChooseCompanyWorkField: {
    en: "Please choose City",
    ar: "قم باختيار المدينة",
  },
  ChooseFile: { en: "Choose File ...", ar: "اختار الملف ..." },
  Cities: { en: "Cities", ar: "المدن" },
  // Class: { en: "Grade", ar: "الدرجة" },
  Class: { en: "Class", ar: "التصنيف" },
  Clear: { en: "Clear", ar: "مسح البحث" },
  ClearFilters: { en: "Clear Filters", ar: "إلغاء الفلترة" },
  Clinic: { en: "Clinic", ar: "عيادة" },
  CommunicationChannels: { en: "Communication Channels", ar: "وسائل الاتصال" },
  SimpleExcelSheet: {
    en: "Download As Simple Excel File",
    ar: "تحميل ملف اكسل بسيط",
  },
  ExcelSheet: {
    en: "Download As Excel File",
    ar: "تحميل ملف اكسل",
  },
  OERATION:{
    en:"Operation",
  ar:"تشغيل"
  },
  LOAD_DATA: {
    en: "Loading ...",
    ar: "جار التحميل...",
  },
  History_Dates: {
    en: "Action History Dates",
    ar: "سجل التواريخ",
  },
  Updatedat: {
    en: "Updated at",
    ar: "تاريخ التحديث",
  },
  assign_At: {
    en: "Assign at",
    ar: "تاريخ التعيين",
  },
  visit_At: {
    en: "Visit at",
    ar: "تاريخ الزياره",
  },
  leaveAcommenT: {
    en: "Leave a Comment",
    ar: "اترك تعليقا",
  },

  reassign_requester_name: {
    en: "Requester Name",
    ar: "أسم الطالب",
  },
  reassign_worker_name: {
    en: "Worker Name",
    ar: "أسم العامل",
  },
  Reassign: {
    en: "Reassign Reason",
    ar: "سبب إعاده التعين",
  },
  Compaines: { en: "Compaines", ar: "الشركات" },
  CompaniesPoint: { en: "Orders", ar: "الطلبات" },
  CompaniesWorkField: { en: "Work Field", ar: "موقع عمل" },
  CompaniesWorkFieldPoint: { en: "Orders", ar: "الطلبات" },
  Company: { en: "Company", ar: "الشركة" },
  CompanyLogo: { en: "Company Logo", ar: "قم برفع الصورة الخاصة بالشركة" },
  CompanyName: { en: "Company Name", ar: "اسم الشركة" },
  CompanyNumber: { en: "Company Number", ar: "رقم الشركة" },
  CompanyRequired: { en: "select a company", ar: "يجب تحديد شركة" },
  CompanyStatus: { en: "Company Status", ar: "حالة الشركة" },
  Composition: { en: "Composition", ar: "التكوين" },
  CompositionRequired: { en: "Composition is Required", ar: "التكوين مطلوب" },
  ConfirmPassword: { en: "Confirm Password", ar: "تاكيد الرقم السري" },
  ConfirmPasswordColumn: {
    en: "Column for the Password Called (ConfirmPassword)",
    ar: "عمود لتاكيد الرقم السري يسمى (ConfirmPassword)",
  },
  ConfirmPasswordColumnRequired: {
    en: "Confrim Password column does not exist",
    ar: "عمود تاكيد الرقم السرس غير موجود",
  },
  ConfirmPasswordLength: {
    en: "confrim password length must be greater than 6 numbers or letters",
    ar: "طول تاكيد الرقم السري يجب ان يكون اكبر من 6 ارقام او حروف",
  },
  ContactDataError: {
    en: "Contact Data Is Not Valid",
    ar: "بيانات وسيلة التواصل غير صحيحة",
  },
  ContactHours: { en: "Contact Hours", ar: "الساعات المتاحة للتواصل" },
  CoverageList: { en: "Coverage List", ar: "قائمة نقاط العمل" },
  CreatedAt: { en: "Created At", ar: "تاريخ الانشاء" },
  Visit_date_dAt: { en: "Visit Date", ar: "تاريخ الزياره" },
  TakeAction:{en: "Take Action", ar: "أخذ موقف"},

  UpdateAt: { en: "Update At", ar: "تاريخ التحديث" },
  CurrencySuffix: { en: "Eg Pound", ar: "جنيه مصري" },
  DailyAppointmentsErrMsg: {
    en: "Daily appointments per day (from field) should be less than (to field)",
    ar: "خانة (من) للكشف اليومى يجب ان تكون اقل من خانة (الى)",
  },
  DailyAverageAppointmentFees: {
    en: "Daily average appointment fees",
    ar: "متوسط الكشف اليومى",
  },
  DailyDetectionAverage: { en: "Detection Average", ar: "الكشف اليومي" },
  DailyDetectionAverageColumn: {
    en: "Column for the Daily Detection Average(From) Number Called (DailyAverageFrom)",
    ar: "عمود لمتوسط عدد الكشف اليومي(من) يسمى (DailyAverageFrom)",
  },
  DailyDetectionAverageColumn2: {
    en: "Column for the Daily Detection Average(To) Number Called (DailyAverageTo)",
    ar: "عمود لمتوسط عدد الكشف اليومي(الي) يسمى (DailyAverageTo)",
  },
  DailyDetectionValidationErr: {
    en: "Daily Detection Data Is Not Valid",
    ar: "البيانات الخاصة بعدد الكشف اليومى غير صحيحة",
  },
  Dashboard: { en: "Dashboard", ar: "لوحة التحكم" },
  DataEmpty: { en: "data cannot be empty", ar: "لا يوجد بيانات" },
  DataStatus: { en: "Data status", ar: "حالة الداتا" },
  Date: { en: "Date", ar: "التاريخ" },
  DateAdded: { en: "Date Added", ar: "الاضافة" },
  DateFrom: { en: "Date From", ar: "من تاريخ" },
  DateTo: { en: "Date To", ar: "الى تاريخ" },
  DateVisit: { en: "Date of visit for", ar: "تاريخ الزيارة ل" },
  DateVisiting: { en: "Date of visit", ar: "تاريخ الزيارة" },
  Dated: { en: "Dated", ar: "بتاريخ" },
  DayDate: { en: "today,s date", ar: "تاريخ اليوم" },
  DayReport: { en: "daliy Report", ar: "تقرير يومي" },
  DayReports: { en: "Daily Reports", ar: "تقارير يومية" },
  December: { en: "December", ar: "ديسمبر" },
  DegreeColumn: {
    en: "Column for the Degree Called (Degree)",
    ar: "عمود للدرجة يسمى (Degree)",
  },
  DegreeRequired: { en: "Degree is Required", ar: "اختار الدرجة" },
  Details: { en: "Details", ar: "التفاصيل" },
  DidNotSucceed: { en: "Failed", ar: "لم تنجح" },
  Double: { en: "Double", ar: "مزدوجة" },
  DownloadAsPdfFile: { en: "Download As Pdf File", ar: "تحميل كملف pdf" },
  Edit: { en: "Edit", ar: "تعديل" },
  EditGiftOrSample: { en: "Edit Gift / Sample", ar: "عدل هدية / عينة" },
  EgPhoneLength: {
    en: "Phone number length must be equal 12 numbers",
    ar: "رقم الموبايل يجب ان يساوي 12 رقم",
  },
  Email: { en: "Email", ar: "البريد الالكتروني" },
  EmailAddress: { en: "Email Address", ar: "البريد الإلكترونى" },
  EmailColumRequired: {
    en: "Email column does not exist",
    ar: "عمود البريد الالكتروني غير موجود",
  },
  EmailColumn: {
    en: "Column for the Email Called (Email)",
    ar: "عمود للبريد الالكتروني يسمى (Email)",
  },
  EmailPasswordRequired: {
    en: "Email and Password is Required",
    ar: "ادخل البريد الالكتروني والرقم السري",
  },
  EmailRequired: { en: "email is required", ar: "حقل البريد الالكتروني مطلوب" },
  EmployeeImage: { en: "Employee Image", ar: "صورة الموظف" },
  EmployeeOutside: {
    en: "The number of employees outside the City",
    ar: "عدد الموظفين خارج المدينة",
  },
  EmployeePerformance: { en: "Employee Performance", ar: "اداء الموظف" },
  EmployeeRole: { en: "Employee Role", ar: "منصب الموظف" },
  EmployeeVisits: { en: "Employee Visits", ar: "زيارات الموظف" },
  Employees: { en: "Employees", ar: "موظفين" },
  EmptyColumn: {
    en: "It is not allowed to be empty in any row",
    ar: "لا يسمح بان يكون فارغ في اي صف ",
  },
  EndLocation: { en: "End Location", ar: "نقطة النهاية" },
  EndWork: { en: "End Work", ar: "نهاية العمل" },
  English: { en: "English", ar: "انجليزي" },
  EnglishLang: {
    en: "Only English Language is Allowed",
    ar: "مسموح اللغةالانجليزية فقط",
  },
  EnglishName: { en: "English Name", ar: "الاسم باللغة الانجليزية" },
  EnglishNameColumRequired: {
    en: "English name column does not exist",
    ar: "عمود الاسم باللغة الانجليزية غير موجود",
  },
  EnglishNameColumn: {
    en: "Column for the name in English Called (EnglishName)",
    ar: "عمود للاسم باللغة الإنجليزية يسمى (EnglishName)",
  },
  EnglishNamequired: {
    en: "english name field is required",
    ar: "حقل الاسم باللغة الانجليزية مطلوب",
  },
  EnterData: { en: "Enter all informations", ar: "ادخل المعلومات" },
  ErrandDetails: { en: "Errand Details", ar: "تفاصيل المهمة" },
  ExcelFile: { en: "Excel file only", ar: "ملف اكسل فقط" },
  Failed: { en: "Failed", ar: "فشلت" },
  Failedvisit: { en: "Failed Visit", ar: "زيارة فاشلة" },
  FastReport: { en: "Fast Report", ar: "تقرير سريع" },
  February: { en: "February", ar: "فبراير" },
  File: { en: "File", ar: "الملف" },
  From: { en: "From", ar: "من" },
  FromDate: { en: "From Date", ar: "من تاريخ" },
  Teams: { en: "Teams", ar: "الاقسام" },
  EranedType: { en: "EranedType", ar: "نوع المهمه" },
  FromToDateRequired: {
    en: "From and To Date is Required",
    ar: "اختار التاريخ",
  },
  Reset: { en: "Reset", ar: "اعاده تعيين" },
  GeneralSearch: { en: "General Search", ar: "بحث عام" },
  GeneralSupervision: { en: "General Supervision", ar: "اشراف عام" },
  Gift: { en: "Gift", ar: "هدية" },
  GiftAddedSuccessfuly: {
    en: "Gift Added Successfuly",
    ar: "تم اضافة الهدية بنجاح",
  },
  GiftOrSample: { en: "Gift / Sample", ar: "هدية / عينة" },
  Gifts_And_Samples: { en: "Gifts and Samples", ar: "الهدايا والعينات" },
  GiftsAndSamplesReport: {
    en: "Gifts and Samples Report",
    ar: "تقرير الهدايا والعينات",
  },
  Go: { en: "Go", ar: "اذهب" },
  Governorate: { en: "City", ar: "تابعة لمدينة " },
  Grade: { en: "Grade", ar: "التقييم" },
  HistoryTracking: { en: "History Tracking", ar: "التتبع بفترة زمنية" },
  Home: { en: "Home", ar: "الصفحة الرئيسية" },
  HowTo: { en: "How to", ar: "كيفية" },
  HowToUse: { en: "How To Use The", ar: "كيفية استخدام" },
  TicketId: { en: "Ticket Id", ar: "رقم التذكرة" },
  Id: { en: "Id", ar: "رقم" },
  Identifier: { en: "Tax Number", ar: "الرقم الضريبي" },
  IdentifierLengthRequired: {
    en: "length of tax number number must equal 9 numbers",
    ar: "طول الرقم الضريبى يجب ان يساوي 9 ارقام",
  },
  IdentifierRequired: {
    en: "tax number number field is required",
    ar: "حقل الرقم الضريبى مطلوب",
  },
  Image: { en: "Image", ar: "الصورة" },
  ImageFileRequired: {
    en: "Image and Pdf File is Required",
    ar: "الصورة والملف مطلوبين",
  },
  ImageRequired: { en: "image field is required", ar: "حقل الصورة مطلوب" },
  Images: { en: "Images", ar: "صور التقرير" },
  ImportantNotes: { en: "Important Notes", ar: "معلومات هامة" },
  InActive: { en: "An Active", ar: "غير مفعل" },
  InActiveWorkers: { en: "Inctive Employees", ar: "الموظفون الغير الناشطين" },
  Indication: { en: "Indication", ar: "دواعي الاستعمال" },
  IndicationRequired: {
    en: "Indication is Required",
    ar: "دواعي الاستعمال مطلوبة",
  },
  InvalidEmail: {
    en: "must enter a valid email in all rows",
    ar: "يجب إدخال بريد إلكتروني صالح في جميع الصفوف",
  },
  InvalidLogin: {
    en: "email or password is incorrect",
    ar: "البريد الالكتروني او الرقم السري غير صحيح",
  },
  Item: { en: "Item", ar: "عنصر" },
  January: { en: "January", ar: "يناير" },
  JobTitle: { en: "Job Title", ar: "المسمى الوظيفى" },
  JobTitleColumn: {
    en: "Column for the Job Title Called (JobTitle)",
    ar: "عمود للعنوان الوظيفي السري يسمى (JobTitle)",
  },
  JobTitleRequired: {
    en: "job title is required",
    ar: "حقل المسمي الوظيفي مطلوب",
  },
  JobTitlesColumnRequired: {
    en: "Job Title column does not exist",
    ar: "عمود العنوات الوطيفي غير موجود",
  },
  July: { en: "July", ar: "يوليو" },
  June: { en: "June", ar: "يونيو" },
  Leave: { en: "Leave", ar: "مغادرة" },
  Link: { en: "Link", ar: "الرابط" },
  LiveTracking: { en: "Live Tracking", ar: "تتبع مباشر" },
  Location: { en: "location", ar: "احدائيات المكان" },
  LocationOnMap: { en: "Location On the Map", ar: "الموقع علي الخريطة" },
  LocationPointRequired: {
    en: "choose the order on the map",
    ar: "اختار مكان الطلب من علي الخريطة",
  },
  LocationTimedOut: {
    en: "The request to get user location timed out",
    ar: "انتهت مهلة طلب الحصول على موقع المستخدم",
  },
  Login: { en: "Login", ar: "تسجيل الدخول" },
  LoginErrorMessage: {
    en: "UserName or Password is Not Correct",
    ar: "خطا في اسم المستخدم او الرقم السري",
  },
  LoginHeader: { en: "Log in", ar: "دخول" },
  Logo: { en: "logo", ar: "اللوجو" },
  Logout: { en: "Log Out", ar: "خروج" },
  MainInfo: { en: "Main Information", ar: "البيانات الرئيسية" },
  MainProvinces: { en: "Main City", ar: "شركة رئيسية" },
  MajorCompanies: { en: "Major Companies", ar: "الشركات الرئيسية" },
  MajorCompany: { en: "Major Company", ar: "شركة رئيسية" },
  ManagerName: { en: "Manager Name", ar: "اسم المدير" },
  March: { en: "March", ar: "مارس" },
  Marketing: { en: "Marketing", ar: "تسويق" },
  May: { en: "May", ar: "مايو" },
  Menu: { en: "Menu", ar: "القائمة" },
  Message: { en: "Message", ar: "الاشعار" },
  MessageRequired: { en: "message field is required", ar: "حقل الرسالة مطلوب" },
  Mobile: { en: "Mobile Number", ar: "الهاتف" },
  Mobile2: { en: "Mobile 2", ar: "رقم تليفون" },
  MobileApplication: { en: "Mobile App", ar: "تطبيق الموبايل" },
  MobileColumRequired: {
    en: "Mobile  column does not exist",
    ar: "عمود رقم الموبايل غير موجود",
  },
  MobileColumn: {
    en: "Column for the Mobile Number Called (Mobile)",
    ar: "عمود لرقم الموبايل يسمى (Mobile)",
  },
  MobileRequired: {
    en: "mobile field is required",
    ar: "حقل رقم التليفون مطلوب",
  },
  MobileTypedNumber: {
    en: "Mobile Number must be numbers only",
    ar: "رقم التليفون يجب ان يكون ارقام فقط",
  },
  Mobiles: { en: "Another Mobiles", ar: "ارقام تليفونات اخري" },
  MobilesEtc: {
    en: "Mobile1 , Mobile2 , Mobile3 .... etc",
    ar: "Mobile1 , Mobile2 , Mobile3 .... etc",
  },
  MorningVisit: { en: "Morning Visit", ar: "زيارة صباحية" },
  Name: { en: "Name", ar: "الاسم" },
  NameColumRequired: {
    en: "Name column does not exist",
    ar: "عمود الاسم غير موجود",
  },
  NameColumn: {
    en: "Column for the name Called (Name)",
    ar: "عمود للاسم يسمى (Name)",
  },
  NameRequired: { en: "name field is required", ar: "حقل الاسم مطلوب" },
  NationalIdColumn: {
    en: "Column for the National Id Called (NationalId)",
    ar: "عمود للرقم القومي يسمى (NationalId)",
  },
  NationalIdColumnRequired: {
    en: "National Id column does not exist",
    ar: "عمود الرقم القومي غير موجود",
  },
  NationalTypedId: {
    en: "National Id must be numbers only",
    ar: "الرقم القومي يجب ان يكون ارقام فقط",
  },
  Next: { en: "Next", ar: "التالي" },
  NightVisit: { en: "Night Visit", ar: "زيارة مسائية" },
  No: { en: "No", ar: "لا" },
  NoAddress: { en: "No Address is exist", ar: "لا يوجد عنوان" },
  NoAssignedEmployees: { en: "No Assigned Employees", ar: "بلا موظفين" },
  NoCode: { en: "No Code is exist", ar: "لا يوجد كود" },
  NoDecisionHasBeenTaken: {
    en: "No Decision Has Been Taken",
    ar: "لم يتخذ قرار",
  },
  NoEmail: { en: "No Email is exist", ar: "لا يوجد بريد الكتروني" },
  NoImage: { en: "No Image", ar: "لا يوجد صور" },
  NoInternet: {
    en: "There is no internet connection",
    ar: "لا يوجد اتصال بالانترنت",
  },
  NoJob: { en: "No Job title is exist", ar: "لا يوجد وظيفة" },
  NoLocation: { en: "No Location exist", ar: "لا يوجد موقع" },
  NoMobile: { en: "No Mobile is exist", ar: "لا يوجد موبايل" },
  NoName: { en: "No Name is exist", ar: "لا يوجد اسم" },
  NoParentState: { en: "No Parent City", ar: "لا توجد شركة ادوية" },
  NoProduct: { en: "No Product", ar: "لا منتج" },
  NoSearch: { en: "No Search Result Found", ar: "لا يوجد نتائج تطابقك بحثك" },
  NoSupervisor: { en: "No User", ar: "لا يوجد موظف" },
  NoTitle: { en: "Not Exist", ar: "لا يوجد" },
  NoType: { en: "No Type is exist", ar: "لا يوجد نوع" },
  NoWorkerOutside: {
    en: "There are no employees outside the work area",
    ar: "لا يوجد موظفون خارج مكان العمل",
  },
  NotAcceptedVisits: { en: "Not Accepted", ar: "رفضت" },
  NotActivited: {
    en: "This company is not active",
    ar: "هذه الشركة غير مفعلة",
  },
  NotExcelFile: { en: "This is not an excel file", ar: "هذا ليس ملف اكسيل" },
  NotSaved: { en: "Not saved", ar: "لم تحفظ" },
  NotSuccessful: { en: "Not Successful", ar: "غير ناجحة" },
  NotSuccessfulVisit: { en: "Failed Visit", ar: "زيارة غير ناجحة" },
  NotUrl: { en: "it is not Valid Url", ar: "يجب ادخال رابط موقع صحيح" },
  NotViewMap: { en: "Not shown on the map", ar: "غير ظاهر علي الخريطة" },
  NotVisited: { en: "Not Visited", ar: "زيارات لم تتم" },
  Notes: { en: "Notes", ar: "ملاحظات" },
  Notifications: { en: "Notifications", ar: "الاشعارات" },
  November: { en: "November", ar: "نوفمبر" },
  NumberOfMonthlyVisitsForProduct: {
    en: "# Of Monthly Visits For Product",
    ar: "عدد الزيارات الشهرية للمنتج",
  },
  October: { en: "October", ar: "اكتوبر" },
  Ok: { en: "Ok", ar: "موافق" },
  OptionalData: { en: "Optional Data", ar: "بيانات اختيارية" },
  OtherPhoneNumbers: { en: "Other Phone Numbers", ar: "الأرقام الأخرى" },
  Outside: { en: "Outside the work area", ar: " خارج مكان العمل" },
  Pack: { en: "pack", ar: "المواد المستعملة" },
  ParentState: { en: "Parent City", ar: "مدينة" },
  Password: { en: "Password", ar: "كلمة المرور" },
  PasswordColumn: {
    en: "Column for the Password Called (Password)",
    ar: "عمود للرقم السري يسمى (Password)",
  },
  PasswordColumnRequired: {
    en: "Password column does not exist",
    ar: "عمود الرقم السرس غير موجود",
  },
  PasswordConfrimRequired: {
    en: "Password Confrimation is required",
    ar: "حقل تاكيد الرقم السري مطلوب",
  },
  PasswordLength: {
    en: "password length must be greater than 6 numbers or letters",
    ar: "طول الرقم السري يجب ان يكون اكبر من 6 ارقام او حروف",
  },
  PasswordPlacholder: {
    en: "Passsword",
    ar: "تاكد من كلمة المرور وقم بادخالها",
  },
  PasswordRequired: {
    en: "password field is required",
    ar: "حقل الرقم السري مطلوب",
  },
  PdfFile: { en: "Pdf File", ar: "ملف Pdf" },
  Pending: { en: "Pending", ar: "معلقة" },
  Permissions: { en: "Permissions", ar: "الامتيازات" },
  PersonalProfile: { en: "Personal Profile", ar: "الملف الشخصى" },
  PhoneCall: { en: "Phone Call", ar: "مكالمة هاتفية" },
  PhoneNumber: { en: "Phone Number", ar: "رقم الهاتف" },
  PhoneNumberEnglish: {
    en: "phone number must be english numbers only",
    ar: "رقم التليفون يجب ان يكون ارقام انجليزي فقط",
  },
  Picture: { en: "Picture", ar: "الصورة" },
  PlaceDetails: { en: "Place Details", ar: "تفاصيل المكان" },
  PlaceTypeRequired: { en: "Place Type Required", ar: "اختار نوع المكان" },
  Places: { en: "Places", ar: "الاماكن" },
  PointAddress: { en: "Order Address", ar: "عنوان الطلب" },
  PointCode: { en: "Order Code ", ar: "كود الطلب" },
  PointOnMap: { en: "Order On Map", ar: "الموقع على الخريطة" },
  PointOutside: {
    en: "This order is outside the specified space",
    ar: "هذا الطلب خارج المساحة المحددة",
  },
  PointProfile: { en: "Order Profile", ar: "بيانات الطلب" },
  PointProperties: { en: "Order Properties", ar: "خصائص الطلب" },
  PointType: { en: "Order Category", ar: "نوع الطلب" },
  PointTypeRequired: { en: "select type of order", ar: "اختار نوع الطلب" },
  PointsRquired: { en: "Select Order", ar: "اختار الطلب" },
  PolygonRequired: {
    en: "draw the workspace on the map",
    ar: "قم برسم مساحة العمل علي الخريطة",
  },
  Preferable: { en: "Preferable", ar: "مٌفضلة" },
  PreferableCommunication: {
    en: "Preferable Communication Method",
    ar: "وسيلة الاتصال المفضلة",
  },
  Preview: { en: "Preview", ar: "عرض" },
  PreviousPlace: { en: "Previous Place", ar: "المكان السابق" },
  Price: { en: "Price", ar: "السعر" },
  PriceCurrancy: { en: "Currency", ar: "العملة" },
  PriceCurrancyColumn: {
    en: "Column for the Price Currancy Called (PriceCurrancy)",
    ar: "عمود للعملة يسمى (PriceCurrancy)",
  },
  PriceExamination: { en: "Price Examination", ar: "سعر الكشف" },
  PriceExaminationColumn: {
    en: "Column for the Price Examination Called (PriceExamination)",
    ar: "عمود لسعر الكشف يسمى (PriceExamination)",
  },
  Print: { en: "print", ar: "طباعة" },
  Product: { en: "Product", ar: "المنتج" },
  ProductAmount: { en: "Product Amount", ar: "كمية المنتجات" },
  ProductAvailabilityPerMonth: {
    en: "Product Availability per Month",
    ar: "اجمالى الاتاحة للمنتج على مدار الشهر",
  },
  ProductDistribution: {
    en: "Product Distribution",
    ar: "التوزيع الجغرافى للمنتج",
  },
  ProductDistributionAccordingToAvailability: {
    en: "Product Distribution According To Availability",
    ar: "التوزيع الجغرافى للمنتج وفقا للاتاحة",
  },
  ProductImage: { en: "Product Image", ar: "صورة المنتج" },
  ProductName: { en: "Product Name", ar: "اسم المنتج" },
  Products: { en: "Products", ar: "المنتجات" },
  ProductsAvailability: {
    en: "Products Availability",
    ar: "الكمية المتاحة من المنتجات",
  },
  ProfessionalTitle: { en: "Professional Title", ar: "المسمى الوظيفى" },
  Province: { en: "city", ar: "المدينة" },
  Provinces: { en: "Cities", ar: "المدن" },
  Quantity: { en: "Quantity", ar: "الكمية" },
  Radius: { en: "Radius", ar: "نصف قطر النقطة" },
  Refresh: { en: "Refresh", ar: "تحديث" },
  Reject: { en: "Reject", ar: "رفض" },
  Rejected: { en: "Rejected", ar: "مرفوض" },
  Remove: { en: "Remove", ar: "حذف" },
  RemoveFromCoverageList: {
    en: "Remove From Coverage List",
    ar: "احذف من قائمة نقاط العمل",
  },
  RemovePolygon: { en: "remove polygon", ar: "مسح المساحة المرسومة" },
  Reports: { en: "Reports", ar: "التقارير" },
  ReportContent: { en: "Contents of the report", ar: "محتويات التقرير" },
  ReportInfo: { en: "Report data", ar: "البيانات الخاصة بالتقرير" },
  ReportTemplete: { en: "Report Templete", ar: "نموذج التقرير" },
  FastReports: { en: "Fast Reports", ar: "التقارير السريعة" },
  Responsiblepersons: { en: "Responsible Persons", ar: "الاشخاص المسئولون" },
  SSNLengthRequired: {
    en: "National Id must equal 14 numbers",
    ar: "الرقم القومي يجب ان يساوي 14 رقما",
  },
  SSNRequired: { en: "national id is required", ar: "حقل الرقم القومي مطلوب" },
  Sales: { en: "Sales", ar: "مبيعات" },
  SalesManagement: { en: "Sales Management", ar: "مسول ادارة المبيعات" },
  Sample: { en: "Sample", ar: "عينة" },
  SampleAddedSuccessfuly: {
    en: "Sample Added Successfuly",
    ar: "تم اضافة العينة بنجاح",
  },
  Save: { en: "Save", ar: "حفظ" },
  Search: { en: "Search", ar: "بحث" },
  SearchKeyword: { en: "Search in Map", ar: "بحث في الخريطة" },
  SearchValidation: {
    en: "At least one field is required",
    ar: "قم بادخال او اختيار البيانات",
  },
  SecuirtTitle: {
    en: "Security and direct tracking system",
    ar: "نظام الامن والتتبع المباشر",
  },
  SelectEmployee: { en: "Select Employee", ar: "اختر موظف" },
  SelectProvince: { en: "select City", ar: "اختار المدينة" },
  SelectSupervisor: { en: "Select User", ar: "اختار الموظف" },
  SelectVisitType: { en: "Select Order Type", ar: "اختر نوع الطلب" },
  SelectWorkField: { en: "select a city", ar: "اختار المدينة" },
  SelectWorkFieldFirst: {
    en: "Select City First",
    ar: "اختار المدينة العمل اولا",
  },
  SelectWorkFieldPoint: { en: "Select Order", ar: "اختر الطلب" },
  SelectWorkers: { en: "Select Employees", ar: "اختار الموظفين" },
  Select_Amana_And_AmanaWorkField: {
    en: "You must choose the company and the City in each row",
    ar: "يجب ان تختار الشركة والمدينة في كل صف",
  },
  Send: { en: "Send", ar: "ارسال" },
  SendNotification: { en: "send a Notification", ar: "ارسال اشعار" },
  September: { en: "September", ar: "سبتمبر" },
  ShowLine: { en: "Show Line", ar: "عرض خط السير" },
  ShowLocation: { en: "Show Location", ar: "عرض الموقع" },
  ShowReport: { en: "show Report", ar: "عرض التقرير" },
  Single: { en: "Single", ar: "فردية" },
  SomethingWentWrong: { en: "Something Went Wrong", ar: "حدث خطأ ما" },
  SpecialPermissionsError: {
    en: "Select an area or select the first three permissions",
    ar: "اختر المدينة او اختر اول ثلاث تصاريح",
  },
  Ssn: { en: "National Id", ar: "الرقم القومي" },
  StartLocation: { en: "Start Location", ar: "نقطة االبداية" },
  StartTime: { en: "Start Time", ar: "وقت بدء العمل" },
  StartWork: { en: "Start Work", ar: "بداية العمل" },
  StateWorkFields: { en: "Regions", ar: "المناطق" },
  WorkFields: { en: "Work Fields", ar: "االمدن" },
  Status: { en: "Status", ar: "الحالة" },
  StatusScussfully: {
    en: "Status changed successfully",
    ar: "تم تغيير الحالة بنجاح",
  },
  Stay: { en: "Stay", ar: "البقاء" },
  SubProvinces: { en: "Sub City", ar: "مدينة فرعية" },
  Succeeded: { en: "Succeeded", ar: "نجحت" },
  Succeed: { en: "Succeed", ar: "ناجحة" },
  Successful: { en: "Successful", ar: "ناجحة" },
  SuccessfulDeleted: { en: "Successful Deleted", ar: "تم الحذف بنجاح" },
  SuccessfullyAdded: {
    en: "Item successfully added",
    ar: "تم اضافة العنصر بنجاح",
  },
  SuccessfullyDeleted: {
    en: "Item successfully deleted",
    ar: "تم الحذف بنجاح",
  },
  SuccessfullyEdit: {
    en: "The item has been modified successfully",
    ar: "تم تعديل العنصر بنجاح",
  },
  SuccessfullySend: {
    en: "The notification was sent successfully",
    ar: "تم ارسال الاشعار بنجاح",
  },
  Successfulvisit: { en: "Successful Visit", ar: "زيارة ناجحة" },
  Supervisor: { en: "Supervisor", ar: "المشرف" },
  Supervisors: { en: "Users", ar: "المستخدمين" },
  Surname: { en: "Surname", ar: "اللقب" },
  SystemData: { en: "System Data", ar: "البيانات الخاصة بالنظام" },
  TaxColumRequired: {
    en: "Tax column does not exist",
    ar: "عمود الرقم الضريبي غير موجود",
  },
  TaxNumber: { en: "Tax Number", ar: "الرقم الضريبي" },
  TaxNumberColumn: {
    en: "Column for the Tax Number Called (Tax)",
    ar: "عمود للرقم الضريبي يسمى (Tax)",
  },
  TaxTypedNumber: {
    en: "Tax Number must be numbers only",
    ar: "الرقم الضريبي يجب ان يكون ارقام فقط",
  },
  ThisPageDoesntExist: {
    en: "This Page Doesn't Exist!",
    ar: "لا وجود لهذه الصفحة!",
  },
  Time: { en: "Time", ar: "الوقت" },
  Title: { en: "Title", ar: "العنوان" },
  TitleColumRequired: {
    en: "Title column does not exist",
    ar: "عمود اللقب غير موجود",
  },
  TitleColumn: {
    en: "Column for the title Called (Title)",
    ar: "عمود للقب يسمى (Title)",
  },
  TitleRequired: { en: "Title field is required", ar: "حقل العنوان مطلوب" },
  To: { en: "To", ar: "الي" },
  ToDate: { en: "To Date", ar: "الي تاريخ" },
  Total: { en: "Total", ar: "المجموع" },
  TotalWorkers: { en: "Total employess", ar: "اجمالي الموظفين" },
  Employess: { en: "Employess", ar: "الموظفين" },
  Tracking: { en: "Tracking", ar: "التتبع" },
  TrackingAndLog: { en: "Tracking And Log", ar: "التتبع والسجل" },
  Type: { en: "type", ar: "نوع" },
  URL: { en: "Url", ar: "الرابط" },
  UnavailableLoc: {
    en: "Location information is unavailable",
    ar: "معلومات الموقع غير متوفرة",
  },
  UnknownError: { en: "An unknown error occurred", ar: "حدث خطأ غير معروف" },
  UpdateCity: { en: "Update City", ar: "تحديث المدينة" },
  UpdateCompanyWF: {
    en: "Update City",
    ar: "تحديث المدينة",
  },
  UpdateCompanyWFPoint: { en: "Update Order", ar: "تحديث الطلب" },
  UpdateProduct: { en: "Update Product", ar: "تعديل المنتج" },
  UpdateProvince: { en: "Update City", ar: "تحديث المدينة" },
  UpdateVisiting: { en: "Update Visiting", ar: "تعديل الزيارة" },
  UsageInstructions: { en: "Usage Instructions", ar: "تعليمات الاستخدام" },
  UsageVideos: {
    AddABranch: { en: "add a Region", ar: "اضافة منطقة" },
    AddACity: { en: "add a city", ar: "اضافة مدينة" },
    AddACompany: { en: "add a company", ar: "اضافة شركة" },
    AddADailyReport: { en: "add a daily report", ar: "اضافة تقرير يومى" },
    AddAProduct: { en: "add a product", ar: "اضافة منتج" },
    AddARegion: { en: "add a region", ar: "اضافة منطقة" },
    AddASample: { en: "add a sample", ar: "اضافة عينة" },
    AddAVisit: { en: "add a visit", ar: "اضافة زيارة" },
    AddAVisitPlace: { en: "add Order", ar: "اضافة طلب" },
    AddAWorkPlace: { en: "add City", ar: "اضافة مدينة" },
    AddAcompanyAndActivateIt: {
      en: "add a company and activate it",
      ar: "اضافة شركة وتفعيلها",
    },
    AddAnEmployee: {
      en: "add an employee and activate him / her",
      ar: "اضافة موظف وتفعيله",
    },
    GetABusinessReport: {
      en: "get a business report",
      ar: "الحصول على تقرير عمل",
    },
    GetAFastReport: { en: "get a fast report", ar: "الحصول على تقرير سريع" },
    SendANotification: { en: "send a notification", ar: "ارسال اشعار" },
  },
  UserDenied: {
    en: "User denied the request for Geolocation",
    ar: "رفض المستخدم طلب تحديد الموقع الجغرافي",
  },
  UserName: { en: "UserName", ar: "اسم المستخدم" },
  UserNameColumRequired: {
    en: "UserName column does not exist",
    ar: "عمود اسم المستخدم غير موجود",
  },
  UserNameColumn: {
    en: "Column for the UserName Called (UserName)",
    ar: "عمود لاسم المستخدم يسمى (UserName)",
  },
  UserNamePlacholder: { en: "UserName", ar: "قم بادخال الاسم المسجل لدينا" },
  UserNameRequired: {
    en: "user name field is required",
    ar: "حقل اسم المستخدم مطلوب",
  },
  Users: { en: "Users", ar: "المستخدمين" },
  View: { en: "View", ar: "عرض" },
  ViewBranches: { en: "View Regions", ar: "عرض المناطق" },
  ViewCompaines: { en: "View Compaines", ar: "عرض الشركات" },
  ViewCompany: { en: "View Company", ar: "عرض الشركة" },
  ViewEmployees: { en: "View Employees", ar: "عرض الموظفين" },
  ViewMainProvinces: { en: "Main City", ar: "المدن الرئيسية" },
  ViewProducts: { en: "View Products", ar: "عرض المنتجات" },
  ViewStates: { en: "View Cities", ar: "عرض المدن" },
  ViewReportTemplete: { en: "View Report Templete", ar: "عرض نماذج التقارير" },
  ViewStateWorkFields: { en: "View Regions", ar: "عرض المناطق" },
  ViewSubProvinces: { en: "Sub City", ar: "المدن الفرعية" },
  ViewUser: { en: "View Users", ar: "عرض المستخدمين" },
  ViewVisits: { en: "View Visits", ar: "عرض الزيارات" },
  ViewVisitsPlan: { en: "View Visits Plan", ar: "عرض خطة الزيارات" },
  ViewWorker: { en: "View Worker Profile", ar: "عرض الملف الشخصي للموظف" },
  ViewonMap: { en: "Shown on the map", ar: "ظاهر علي الخريطة" },
  VisitDateTime: { en: "Visit date and time", ar: "تاريخ وتوقيت الزيارة" },
  VisitDate: { en: "Visit Date", ar: "تاريخ الزيارة" },
  VisitDetails: { en: "Details of Visit", ar: "تفاصيل الزيارة" },
  VisitSpotsReport: { en: "Visit Spots Report", ar: "تقرير نقاط العمل" },
  VisitStatus: { en: "Visit Status", ar: "حالة الزيارة" },
  VisitTime: { en: "Visit Time", ar: "توقيت الزيارة" },
  VisitTimeRequired: {
    en: "All visits times is required",
    ar: "جميع أوقات الزيارات مطلوبة",
  },
  VisitType: { en: "Order Type", ar: "نوع الطلب" },
  Visited: { en: "Visited", ar: "زيارات تمت" },
  Visits: { en: "Visits", ar: "الزيارات" },
  VisitsForProduct: { en: "Visits For Product", ar: "زيارات المنتج" },
  VisitsPlan: { en: "Visits Plan", ar: "خطة الزيارات" },
  VisitsPointsFilters: {
    en: "Visits Points Filters",
    ar: "الفلترة بأماكن الزيارات",
  },
  Whatsapp: { en: "Whatsapp", ar: "واتساب" },
  WorkDay: { en: "Visit Day", ar: "يوم المقابلة" },
  WorkDays: { en: "Work Days", ar: "ايام العمل" },
  WorkField: { en: "Cities", ar: "المدن" },
  WorkFieldAddress: { en: "City Address", ar: "عنوان المدينة" },
  WorkFieldName: { en: "Place Name", ar: "اسم المكان" },
  MerchantName: { en: "Merchant Name", ar: " اسم المكان" },
  MercantDetails: { en: "Merchant Details", ar: "تفاصيل التاجر" },
  RequesterName: { en: "Requester Name", ar: "اسم الطالب" },
  RequesterEmail: { en: "Requester Email", ar: "ايميل الطالب" },
  ServiceLocation: { en: "Service Location & Address", ar: "مكان الخدمة" },
  WorkFieldPoint: { en: "Order", ar: "الطلب" },
  WorkFieldType: { en: "Place Type", ar: "نوع المكان" },
  WorkHours: { en: "Work Hours", ar: "ساعات العمل" },
  WorkPlaces: { en: "Cities", ar: "مدن" },
  WorkPlacesCompanies: { en: "Cities", ar: "مدن" },
  WorkReport: {
    en: "Attendance and departure report",
    ar: "تقرير الحضور والانصراف",
  },
  Worker: { en: "Employee", ar: "الموظف" },
  WorkerTimesOut: {
    en: "The number of times out of work",
    ar: "عدد مرات الخروج من المكان العمل",
  },
  WorkerType: { en: "Worker Type", ar: "نوع الموظف" },
  WorkerTypeColumnRequired: {
    en: "WorkerType column does not exist",
    ar: "عمود نوع الموظف غير موجود",
  },
  WorkerTypeError: {
    en: "Worker Type should be either 1 or 2",
    ar: "قيمة نوع الموظف يجب ان تساوى 1 او 2",
  },
  WorkerTypeRequired: { en: "Worker Type is Required", ar: "اختار نوع الموظف" },
  Worker_Number: { en: "Worker Number", ar: "رقم الموظف" },
  Workers: { en: "Employees", ar: "الموظفين" },
  WorkingHours: { en: "Working Hours", ar: "ساعات العمل" },
  Year: { en: "Year", ar: "السنة" },
  Yes: { en: "Yes", ar: "نعم" },
  ZoomToMyLocation: { en: "Zoom To My Location", ar: "التقريب الى موقعى" },
  activiteCompany: {
    en: "you must activite company first",
    ar: "من فضلك قم بتنشيط الشركة اولا",
  },
  SomeDataIsMissing: { en: "Some Data Is Missing", ar: "بعض البيانات مفقودة" },
  ArrivalTime: { en: "Arrival Time", ar: "توقيت الوصول" },
  Battery: { en: "Battery", ar: "البطارية" },
  FirstVisit: { en: "First Visit", ar: "الزيارة الاولى" },
  SecondVisit: { en: "Second Visit", ar: "الزيارة الثانية" },
  ThirdVisit: { en: "Third Visit", ar: "الزيارة الثالثة" },
  AddBranch: { en: "Add Region", ar: "اضافة منطقة" },
  AddCompany: { en: "add a Company", ar: "اضافة شركة" },
  addItem: { en: "add Item", ar: "اضافة عنصر" },
  addReport: { en: "Add Report", ar: "اضافة تقرير" },
  AddStateWorkField: { en: "Add Region", ar: "اضافة منطقة" },
  alertConfirm: { en: "are you sure?", ar: "هل أنت متأكد؟" },
  all_branches: {
    en: "Control of all Regions on the system",
    ar: "التحكم في جميع الفروع الموجودة علي السيستم",
  },
  all_companies: {
    en: "Control of all companies on the system",
    ar: "التحكم في جميع الشركات الموجودة علي السيستم",
  },
  MerchantAddress: { en: "Merchant Address", ar: "عنوان التاجر" },
  Landmark: { en: "Landmark", ar: "علامة مميزة" },
  all_work_fields: {
    en: "Control of all cities on the system",
    ar: "التحكم في جميع مدن الموجودة علي السيستم",
  },
  archives: { en: "view fast reports", ar: "عرض التقارير السريعة" },
  companies: { en: "view companies", ar: "عرض الشركات" },
  "companies/branches/show/{id}": {
    en: "View Branch Profile",
    ar: "عرض الملف الشخصي للمنطقة",
  },
  "companies/branches/store": { en: "Add Region", ar: "اضافة منطقة" },
  "companies/branches/update/{id}": {
    en: "Update Region",
    ar: "تحديث المنطقة",
  },
  "companies/branches/{company_id}": { en: "View Regions", ar: "عرض المناطق" },
  "companies/changeStatus/{id}": {
    en: "allow to change status of company",
    ar: "السماح بتغيير حالة الشركة",
  },

  "companies/gifts/show/{id}": {
    en: "View Gift by Id",
    ar: "عرض الملف الشخصي للهدية",
  },
  "companies/gifts/store": { en: "Add Gift", ar: "اضافة هدية" },
  "companies/gifts/update/{id}": {
    en: "Update Gift",
    ar: "تحديث بيانات الهدية",
  },
  "companies/gifts/{company_id}": { en: "View Gifts", ar: "عرض الهدايا" },
  "companies/products/delete/{id}": { en: "Delete Product", ar: "مسح المنتج" },
  "companies/products/show/{id}": {
    en: "View Product Profile",
    ar: "عرض الملف الشخصي للمنتج",
  },
  "companies/products/store": { en: "Add Product", ar: "اضافة منتج" },
  "companies/products/update/{id}": {
    en: "Update Product",
    ar: "تعديل بيانات المنتج",
  },
  "companies/products/{company_id}": { en: "View Products", ar: "عرض الادوية" },
  "companies/show/{id}": { en: "view company", ar: "عرض الملف الشخصي للشركة" },
  "companies/store": { en: "add company", ar: "اضافة شركة" },
  "companies/update/{id}": { en: "update company", ar: "تحديث الشركة" },
  "companies/work_fields/points": {
    en: "view work field",
    ar: "عرض نقاط العمل",
  },
  "companies/work_fields/points/list/{id}": {
    en: "Show all orders when adding an order to visit",
    ar: "عرض جميع الطلبات عند اضافة طلب",
  },
  "companies/work_fields/points/show/{id}": {
    en: "view order profile",
    ar: "عرض الملف الشخصي للطلب",
  },
  "companies/work_fields/points/store": {
    en: "add order",
    ar: "اضافة طلب",
  },
  "companies/work_fields/points/update/{id}": {
    en: "update order",
    ar: "تحديث الطلب",
  },
  "companies/work_fields/show/{id}": {
    en: "view city profile",
    ar: "عرض الملف الشخصي للمدينة",
  },
  "companies/work_fields/store": { en: "add city", ar: "اضافة مدينة" },
  "companies/work_fields/update/{id}": {
    en: "update City",
    ar: "تحديث المدينة",
  },
  "companies/work_fields/{id}/reports": {
    en: "view templete reports",
    ar: "عرض نماذج التقارير",
  },
  "companies/work_fields/{id}/reports/store": {
    en: "add a report templete",
    ar: "اضافة نموذج تقرير جديد",
  },
  "companies/{id}/work_fields": {
    en: "view cities",
    ar: "عرض اماكن العمل",
  },
  "companies/{id}/work_fields/all": {
    en: "View All cities",
    ar: "عرض جميع المدن",
  },
  date: { en: "by date", ar: "عن يوم" },
  downloadAsEcelFile: { en: "Download As Excel File", ar: "تحميل كملف اكسيل" },
  downloadas: { en: "download as", ar: "تحميل ك" },
  generalsupervision: { en: "general supervision", ar: "الاشراف العام" },
  "gifts/amount": { en: "View Amount of Gifts", ar: "عرض تقرير الهدايا" },
  histories: {
    en: "View the Attendance Report",
    ar: "عرض تقرير الحضور والانصراف",
  },
  "histories/active/{work_field_id}": {
    en: "view live tracking",
    ar: "عرض التتبع المباشر",
  },
  "histories/worker/{worker_id}": {
    en: "View Histroy Tracking",
    ar: "عرض التتبع بفترة زمنية",
  },
  home: { en: "View Home Page", ar: "عرض الصفحة الرئيسية" },
  lang: { en: "en", ar: "ar" },
  locatonPlacholder: {
    en: "Write the location coordinates from the map",
    ar: "قم بكتابة احداثيات المكان من الخريطة",
  },
  mail_validation: { en: "Mail Validation", ar: "التحقق من البريد الالكترونى" },
  packRequired: { en: "Pack is Required", ar: "المواد المستعملة مطلوبة" },
  place_address: { en: "work place", ar: "مكان العمل" },
  place_photo: { en: "Place Photo", ar: "صورة المكان" },
  "points/actions": { en: "business report", ar: "تقرير الأعمال" },
  "products/amounts": { en: "View Products Amount", ar: "عرض كميةالمنتجات" },
  "products/sales/report": { en: "Sales Report", ar: "تقرير المبيعات" },
  report_desc: { en: "Description", ar: "الوصف" },
  reports: { en: "view daily report", ar: "عرض التقارير اليومية" },
  saved: { en: "Saved", ar: "تم الحفظ" },
  selectBranch: { en: "select Region", ar: "اختار المنطقة" },
  selectRegion: { en: "select Region", ar: "اختار المنطقة" },
  selectCity: { en: "select City", ar: "اختار مدينة" },
  send_mails: { en: "Send Mails", ar: "ارسال بريد الكترونى" },
  send_sms: { en: "Send SMS", ar: "ارسال رسائل نصية" },
  states: { en: "view cities", ar: "عرض المدن" },
  Mark: { en: "Mark", ar: "تحديد" },
  "states/show/{id}": { en: "view city", ar: "عرض الملف الشخصي للمدينة" },
  "states/store": { en: "Add city", ar: "اضافة مدينة" },
  "states/update/{id}": { en: "edit city", ar: "تحديث بيانات المدينة" },
  "states/work_fields": { en: "view regions", ar: "عرض المناطق" },
  "states/work_fields/show/{id}": {
    en: "view regions",
    ar: "عرض الملف الشخصي للمناطق",
  },
  "states/work_fields/store": { en: "add regions", ar: "اضافة منطقة" },
  "states/work_fields/update/{id}": {
    en: "update regions",
    ar: "تحديث المنطقة",
  },
  updateBranch: { en: "update Region", ar: "تحديث المنطقة" },
  updateComapny: { en: "update Company", ar: "تحديث بيانات الشركات" },
  updateReport: { en: "Update report", ar: "تحديث التقرير" },
  updateStateWorkfield: { en: "update Region", ar: "تحديث المنطقة" },
  updateSupervisor: { en: "update User", ar: "تحديث المستخدم" },
  updateWorker: { en: "update employee", ar: "تحديث الموظف" },
  "users/show/{id}": {
    en: "view user profile",
    ar: "عرض الملف الشخصي للمستخدم",
  },
  "users/store": { en: "add user", ar: "اضافة مستخدم" },
  "users/update/{id}": { en: "update user", ar: "نحديث المستخدم" },
  "users/{company_id}": { en: "view users", ar: "عرض المستخدمين" },
  "visit-reports/{company_id}": {
    en: "Visit Spots Reports",
    ar: "تقارير نقاط العمل",
  },
  "work_fields/{id}/workers": {
    en: "View Employees of a specific area",
    ar: "عرض موظفين تابعين لمكان عمل",
  },
  worker_image: { en: "Employee Image", ar: "صورة الموظف" },
  worker_name: { en: "employee Name", ar: "اسم الموظف" },
  "workers-work-points/store/{id}": {
    en: "Add To Work Point Covarge List",
    ar: "اضافة الى قائمة نقاط العمل",
  },
  "workers-work-points/update/{id}": {
    en: "Update Work Point Covarge List",
    ar: "تعديل قائمة نقاط العمل",
  },
  "workers-work-points/delete/{id}": {
    en: "Remove From Work Point Covarge List",
    ar: "حذف من قائمة نقاط العمل",
  },
  "workers-work-points/{work_field}": {
    en: "Work Point Covarge List",
    ar: "قائمة نقاط العمل",
  },
  "workers/changeStatus/{id}": {
    en: "allow to change status of employees",
    ar: "السماح بتغيير حالة الموظف",
  },
  "workers/notify": {
    en: "allow to send notifications to employees",
    ar: "السماح بارسال تنبيه للموظفين",
  },
  "workers/show/{id}": {
    en: "view employee profile",
    ar: "عرض الملف الشخصي للموظفين",
  },
  "workers/store": { en: "add employee", ar: "اضافة موظف" },
  "workers/update/{id}": { en: "update employee", ar: "تحديث الموظف" },
  "workers/{company_id}": { en: "view employees", ar: "عرض الموظفين" },
  "workers/{company_id}/{work_field_Id}/count": {
    en: "View Total Numbers of Workers in tracking live",
    ar: "عرض عدد الموظفين في صفحة التتبع المباشر",
  },
  "workers_points/changeStatus/{id}": {
    en: "Approve Visits",
    ar: "الموافقة على الزيارات",
  },
  "workers_points/delete/{id}": { en: "Delete Visit", ar: "حذف الزيارة" },
  "workers_points/store/{id}": { en: "Add Visit", ar: "اضافة زيارة" },
  "workers_points/update/{id}": { en: "Update Visit", ar: "تعديل الزيارة" },
  "workers_points/{company_id}": { en: "View Vists", ar: "عرض الزيارات" },
  TerminalId: { en: "Terminal ID", ar: "رقم الفرع" },
  MercantName: { en: "Merchant Name", ar: "اسم التاجر" },
  PlaceType: { en: "MCC", ar: "نوع المكان" },
  MerchantId: { en: "Merchant Id", ar: "رقم التاجر" },
  MerchantRequired: { en: "Merchant ID is Required", ar: "رقم التاجر مطلوب" },
  TerminalRequired: { en: "Terminal ID is Required", ar: "رقم الفرع مطلوب" },
  Activatevisit: { en: "Activate visit", ar: "تفعيل زياره" },
  NeedVisit: { en: "Need a Visit", ar: "تحتاج الزيارة" },
  CreateVisit: { en: "Create a Visit", ar: "انشاء زيارة" },
  ViewCompaniesWorkFieldPoint: {
    en: "View All Orders",
    ar: "عرض  جميع الطلبات",
  },
  MyOrders: { en: "My Orders", ar: "طلباتي" },
  ViewMyOrders: { en: "View My Orders", ar: "عرض طلباتي" },
  AddMyOrder: { en: "Add Order", ar: "اضافة طلب" },
  OrderStatus: { en: "Order Status", ar: "حالة الطلب" },

  "requests/store": { en: "Add Request", ar: "اضافة طلب" },
  requests: { en: "Requests", ar: "الطلبات" },
  "specializations/update/{id}": {
    en: "Update Specialization",
    ar: "تعديل التخصص",
  },
  "specializations/store": { en: "Add Specialization", ar: "اضافة التخصص" },
  specializations: { en: "Specializations", ar: "عرض التخصصات" },
  "points/actions/export": {
    en: "Export Points",
    ar: "تنزيل تقرير نقاط العمل",
  },
  "workers/report": { en: "Workers Reports", ar: "عرض تقرير الموظفين" },
  all_requests: { en: "View All Requests", ar: "عرض جميع الطلبات" },
  city_requests: { en: "View City requests", ar: "عرض طلبات المدن" },
  DeepSearch: { en: "Deep Search", ar: "بحث عميق" },
  AddOrderVisit: { en: "Add Order Visit", ar: "اضافة طلب زيارة" },
  VisitTypeRequired: { en: "Visit Type is Required", ar: "نوع الزيارة مطلوب" },
  Comment: { en: "Comment", ar: "التعليقات" },
  "done assign visit": { en: "Assigned", ar: "تم انشاء زيارة" },
  pending: { en: "Not Assigned", ar: "معلق" },
  TotalRevenue: {
    en: "Total Revenue",
    ar: "إجمالي الإيرادات",
  },
  Yearly: {
    en: "Yearly",
    ar: "سنوي",
  },
  Montly: {
    en: "Montly",
    ar: "شهري",
  },
  Daily: {
    en: "Daily",
    ar: "يومي",
  },
  Marchant: {
    en: "Marchant",
    ar: "التجار",
  },
  PropertyReferrals: { en: "Property Referrals", ar: "إحالات الممتلكات" },
  Overview: { en: "Overview", ar: "الملخص" },
  Deploy: { en: "Deploy", ar: "تم العاقد" },
  Cancel: { en: "Cancel", ar: "الغاء" },
  Visit: { en: "Visit", ar: "زيارة" },
  Positive: { en: "Positive", ar: "ايجابي" },
  Negative: { en: "Negative", ar: "سلبي" },
  Closed: { en: "Closed", ar: "منتهي" },
  //Request table 2
  Request_City: {
    en: "City",
    ar: " المدينه ",
  },
  Zone:{
    en: "Zone",
    ar: " المنطقه العامله ",

    },
  Request_errand_channel: {
    en: "Errand channel ",
    ar: "قناة مهمة",
  },
  ErrandType: {
    en: "Errand Type",
    ar: "نوع المهمة",
  },
  AgentName: {
    en: "Agent Name",
    ar: "اسم الوكيل",
  },
  DropDown_Assign: {
    en: "Assign To",
    ar: "تعيين ل",
  },
  DropDown_AssignAgain: {
    en: "assign again",
    ar: "تعيين مرة أخرى",
  },
  MainPermission: {
    en: "MainPermission",
    ar: "الصلاحيات العامه",
  },
  CityOwner: {
    en: "CityOwner",
    ar: "صلاحيات مسؤول المدينه",
  },
  Requester: {
    en: "Requester",
    ar: "صلاحيات  مقدم الطلب  ",
  },

  service_Location: {
    en: "Service Location",
    ar: "موقع الخدمة",
  },

  App_FeedBack: {
    en: "App Feedback",
    ar: "ملاحظات التطبيق",
    History_Dates: {
      en: "Action History Dates",
      ar: "سجل التواريخ",
    },
    User___Name: {
      en: "user name",
      ar: "أسم المستخدم",
    },
    Merchant_address: {
      en: "Merchant address",
      ar: "عنوان التاجر",
    },
    cities: {
      en: "cities",
      ar: "المدن",
    },
    regions: {
      en: "reigions",
      ar: "المناطق",
    },
    loading: {
      en: "Loading",
      ar: "تحميل",
    },
    Updatedat: {
      en: "Updated at",
      ar: "تاريخ التحديث",
    },
    TerminalId: {
      en: "terminal id ",
      ar: "هويه الماكينه",
    },
    Profile: {
      en: "Profile",
      ar: "الملف الشخصي",
    },

    assign_at: {
      en: "Assign at",
      ar: "تاريخ التعيين",
    },
    visit_at: {
      en: "Visit at",
      ar: "تاريخ الزياره",
    },
    MerchantIdRequired: {
      en: "Merchant Id is required",
      ar: "رقم التاجر مطلوب",
    },
    MerchantNameRequired: {
      en: "Merchant name is required",
      ar: "اسم التاجر مطلوب",
    },
    is_loading: {
      en: "Loading",
      ar: "جارِ التحميل",
    },
    leaveAcomment: {
      en: "Leave a Comment",
      ar: "اترك تعليقا",
    },
  },
  NoMerchant: {
    en: "No Merchant Founded",
    ar: "لا يوجد تاجر",
  },
  Regions: {
    en: "Regions",
    ar: "المناطق",
  },
  States: { en: "Cities", ar: "المدن" },
  Zones: {
    en: "Zones",
    ar: "مناطق العمل",
  },
};
