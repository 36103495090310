import { Request } from "../Api/index";
import {
  MERGE_REPORT_TEMPLETE_PROPS,
  UPDATE_REPORT_TEMPLETE_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
} from "../../utils/GeneralFunctions";
export const getTempleteReports = (isSearching, searchForm) => (dispatch) => {
  let url = "/companies/work_fields/";
  dispatch({
    type: UPDATE_REPORT_TEMPLETE_PROPS,
    payload: [{ prop: "reportTempleteObj.reportTempleteItem", value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_REPORT_TEMPLETE_PROPS,
      payload: [{ prop: "reportTempleteObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_REPORT_TEMPLETE_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  if (searchForm !== null) {
    url += searchForm.work_field_id + "/reports";
    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      page: searchForm.page,
    };
    let newSearchForm = removeUnsetValues(searchItems);

    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_REPORT_TEMPLETE_PROPS,
            payload: [
              {
                prop: "reportTempleteObj.reportTempleteItem",
                value: res.data.data,
              },
            ],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_REPORT_TEMPLETE_PROPS,
        payload: [
          { prop: "reportTempleteObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_REPORT_TEMPLETE_PROPS,
        payload: [
          { prop: "reportTempleteObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getReportsForDropDown = (name) => (dispatch) => {
  Request()
    .get("/companies/work_fields/reports")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: r.name,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_REPORT_TEMPLETE_PROPS,
            payload: [{ prop: "reportOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateReport = (formValues, index, options, isModal) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_REPORT_TEMPLETE_PROPS,
    payload: [{ prop: "reportForm.isLoading", value: true }],
  });
  let url = null;

  if (formValues.id === undefined) {
    url =
      "/companies/work_fields/" + formValues.work_field_id + "/reports/store";
  } else {
    url = "/companies/work_fields/update/" + formValues.id;
  }

  let newFormValues = Object.assign({}, formValues);
  newFormValues.work_field_id = newFormValues.work_field_id;
  Request()
    .post(url, newFormValues)
    .then((res) => {
      if (res.data.status === true) {
        if (formValues.id === undefined) {
          confirmSunccessed(local.SuccessfullyAdded);

          if (options !== null && isModal === true) {
            dispatch({
              type: MERGE_REPORT_TEMPLETE_PROPS,
              payload: [
                {
                  prop: "reportTempleteObj.reportTempleteItem.data",
                  value: res.data.data,
                },
              ],
            });
          }
        } else {
          confirmSunccessed(local.SuccessfullyEdit);

          if (options !== null && isModal === true) {
            dispatch({
              type: UPDATE_REPORT_TEMPLETE_PROPS,
              payload: [
                {
                  prop: "reportTempleteObj.reportTempleteItem.data." + index,
                  value: res.data.data,
                },
              ],
            });
          }
        }
        dispatch({
          type: UPDATE_REPORT_TEMPLETE_PROPS,
          payload: [
            {
              prop: "reportForm",
              value: {
                isLoading: false,
                formvalues: {
                  work_field_id: null,
                  template: [
                    { key: "", name: "", type: "" },
                    { key: "", name: "", type: "" },
                    { key: "", name: "", type: "" },
                  ],
                },
                index: null,
                company_id: null,
                branch_id: null,
              },
            },
            { prop: "reportOptions", value: [] },
            { prop: "lightbox", value: { isOpen: false, image: null } },
            {
              prop: "modale",
              value: {
                isOpen: false,
                tobeConfirmed: false,
                title: "",
                actionType: "",
                tabs_title: [
                  {
                    title:
                      getLanguage() === "en"
                        ? "Report data"
                        : "البيانات الخاصة بالتقرير",
                    active: "active",
                    dots_pagination_active: "dots_pagination_active",
                  },
                  {
                    title:
                      getLanguage() === "en"
                        ? "Contents of the report"
                        : "محتويات التقرير",
                    active: "active",
                    dots_pagination_active: "dots_pagination_active",
                  },
                ],
                activeIndex: 0,
                tabAction: "next",
              },
            },
          ],
        });
      } else {
        Object.values(res.data.error).map((msg) => {
          toaster.notify(msg[0], {
            duration: 2000,
          });
        });
      }
      dispatch({
        type: UPDATE_REPORT_TEMPLETE_PROPS,
        payload: [{ prop: "reportForm.isLoading", value: false }],
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_REPORT_TEMPLETE_PROPS,
        payload: [{ prop: "reportForm.isLoading", value: false }],
      });
      console.log(err);
    })
    .finally();
};

export const getReport = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("/companies/work_fields/reports/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_REPORT_TEMPLETE_PROPS,
              payload: [
                {
                  prop: "reportForm.formvalues",
                  value: res.data.data,
                },
              ],
            });
          } else {
            let result = [
              { label: res.data.data.name, value: res.data.data.id },
            ];

            dispatch({
              type: UPDATE_REPORT_TEMPLETE_PROPS,
              payload: [{ prop: "reportOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};
