import {
  CLEAR_HISTORIES_STATE,
  UPDATE_HISTORIES_PROPS,
  MERGE_HISTORIES_PROPS,
  DELETE_HISTORIES_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  historyObj: {
    isLoading: false,
    lat: "",
    lng: "",
    polyline: [],
  },

  lightbox: { isOpen: false, image: null },

  searchForm: {
    company: null,
    worker_id: null,
    from: "",
    to: "",
    page: 1,
  },
  isLoadingSearch: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_HISTORIES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_HISTORIES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_HISTORIES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_HISTORIES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
