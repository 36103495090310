import { CardHeader, CardBody } from "reactstrap";
import React from "react";
import {
  Chart,
  Tooltip,
  Interval,
  Interaction,
  registerShape,
  Axis,
  Legend,
} from "bizcharts";

import { local } from "../../lang/local";

const Revenue = ({ requestschart }) => {
  const [value, setValue] = React.useState("3");
  const colors = ["#0181f9", "#6f5bd4", "#20c18e", "#b5110f"];

  const getValue = (key) => {
    if (requestschart.length > 0) {
      let total_all = requestschart
        .map((req) => req.all)
        .reduce((ite, a) => ite + a, 0);
      let total_close = requestschart
        .map((req) => req.done)
        .reduce((ite, a) => ite + a, 0);
      let total_pos = requestschart
        .map((req) => req.positive)
        .reduce((ite, a) => ite + a, 0);
      let total_neg = requestschart
        .map((req) => req.negative)
        .reduce((ite, a) => ite + a, 0);

      switch (key) {
        case "All":
          return total_all;
        case "Closed":
          return total_close;
        case "Succeed":
          return total_pos;

        case "Failed":
          return total_neg;
        case "Total":
          return total_all + total_close + total_pos + total_neg;

        default:
          return 0;
      }
    } else {
      return 0;
    }
  };

  const getData = () => {
    let newItems = [];
    let items = [...requestschart];
    if (items.length > 0) {
      items.map((item) => {
        let date = item.day;
        delete item.day;
        Object.entries(item).map((entry) => {
          newItems.push({ date: date, name: entry[0], value: entry[1] });
        });
      });
    }
    return newItems;
  };
  // const scale = {
  //   month: {
  //     alias: "月份",
  //   },
  //   avgRainfall: {
  //     alias: "月均降雨量",
  //   },
  // };

  registerShape("interval", "border-radius", {
    draw(cfg, container) {
      const { points } = cfg;
      let path = [];
      path.push(["M", points[0].x, points[0].y]);
      path.push(["L", points[1].x, points[1].y]);
      path.push(["L", points[2].x, points[2].y]);
      path.push(["L", points[3].x, points[3].y]);
      path.push("Z");
      path = this.parsePath(path);

      const group = container.addGroup();

      group.addShape("rect", {
        attrs: {
          x: path[1][1],
          y: path[1][2],
          width: 10,
          height: path[0][2] - path[1][2],
          fill:
            cfg.data.name === "all"
              ? "#0181f9"
              : cfg.data.name === "done"
              ? "#6f5bd4"
              : cfg.data.name === "positive"
              ? "#20c18e"
              : cfg.data.name === "negative"
              ? "#b5110f"
              : cfg.color,
          radius: 10 / 2,
        },
      });

      return group;
    },
  });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="inner-container">
      <CardHeader className="header">
        <div>
          <h5>{local.TotalRevenue}</h5>
        </div>
        <div>
          <ul className="flex alignItem list">
            {[
              { label: "All", color: colors[0] },
              { label: "Closed", color: colors[1] },
              { label: "Succeed", color: colors[2] },
              { label: "Failed", color: colors[3] },
            ].map((item, index) => {
              return (
                <li
                  key={index}
                  className="list-element alignItem"
                  style={{ display: "flex !important" }}
                >
                  <div className="circle" style={{ background: item.color }} />
                  <span className="title">{local[item.label]}</span>
                  <span className="value" style={{ color: item.color }}>
                    {getValue(item.label)}
                  </span>
                </li>
              );
            })}
            {/* <li className="list-item flex alignItem">
              <span>{getValue("Total")}</span>
            </li> */}
          </ul>
        </div>
      </CardHeader>

      <CardBody>
        <div style={{ direction: "ltr", position: "relative" }}>
          <Chart
            // scale={scale}
            // height={250}
            padding="auto"
            data={getData()}
            autoFit
            filter={[["value", (val) => val != null]]}
            limitInPlot={true}
            forceUpdate={false}
            placeholder=""
            animate={true}
            pure={true}
          >
            <Interval
              adjust={[
                {
                  type: "dodge",
                  marginRatio: 0,
                },
              ]}
              color="name"
              position="date*value"
              shape={[
                "name*value",
                (type, val) => {
                  return "border-radius";
                },
              ]}
            />

            <Legend visible={false} />
            <Axis name="value" tickLine={[0, 10, 20, 30]} />

            <Tooltip shared />
            <Interaction type="active-region" />
          </Chart>
        </div>
      </CardBody>
    </div>
  );
};

export default Revenue;
