import React, { Component } from "react";
import { Form, Card } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import FirstTab from "./FirstTab";
import SecondTab from "./SecondTab";
import { onSave } from "./CompainesFunction";

class CreateUpdateCompany extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { getStatesForDropDown, modale } = this.props;
    if (modale.actionType === "AddCompany") {
      getStatesForDropDown();
    }
  };

  renderTabs = () => {
    const {
      companyForm,
      general,
      stateOptions,
      modale,
      stateWFOptions,
      createORUpdateCompany,
      companyOptions,
      loadStateOptions,
      loadStateWFOptions,
      loadCompaniesOptions,
      stateValue,
      stateWFValue,
    } = this.props;

    if (modale.activeIndex === 0) {
      return <FirstTab general={general} companyForm={companyForm} />;
    } else {
      return (
        <SecondTab
          general={general}
          companyForm={companyForm}
          stateOptions={stateOptions}
          stateWFOptions={stateWFOptions}
          createORUpdateCompany={createORUpdateCompany}
          companyOptions={companyOptions}
          loadStateOptions={loadStateOptions}
          loadStateWFOptions={loadStateWFOptions}
          loadCompaniesOptions={loadCompaniesOptions}
          stateValue={stateValue}
          stateWFValue={stateWFValue}
        />
      );
    }
  };
  render() {
    const {
      modale,
      createORUpdateCompany,
      companyForm,
      stateValue,
      stateWFValue,
    } = this.props;
    return (
      <Form
        onSubmit={(e) =>
          onSave(
            e,
            createORUpdateCompany,
            companyForm,
            true,
            false,
            stateValue,
            stateWFValue
          )
        }
      >
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ CompanyR, StateWFR, StateR }) => {
  return {
    companyForm: CompanyR.companyForm,
    modale: CompanyR.modale,
    stateOptions: StateR.stateOptions,
    stateWFOptions: StateWFR.stateWFOptions,
    companyObj: CompanyR.companyObj,
    companyOptions: CompanyR.companyOptions,
    stateValue: CompanyR.stateValue,
    stateWFValue: CompanyR.stateWFValue,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateCompany);
