import React, { useEffect } from 'react';
import { geoJsonToWKT, isArray } from '../../utils/GeneralFunctions';
import marker from '../../assets/img/marker.svg';
import { EditableableShapes } from 'mapy_sdk/lib/mapyTypes';
import {
  renderSingleMapyPolygon,
  renderSingleMapyMarker,
  renderSingleMapyPolyline,
  renderMapyMarkers,
  renderMapyPolygons,
} from './MapyFunctions';

const GenericMapy = ({
  map,
  mapID,
  children,
  drawingControls = false,
  onPolygonDraw,
  onPolygonEdit,
  onPolygonDelete,
  polygons,
  polygonsGroupName,
  markers,
  zoomToMarkers = true,
  zoomToMarker = true,
  zoomToPolygons = true,
  zoomToPolyline = true,
  markerIcon = marker,
  clusterMarkers = true,
  markersGroupName,
  singlePolygon,
  singleMarker,
  singlePolyLine,
  clusterMaxZoom,
  initialCenter = [30.1946646, 31.5485576],
  initialZoom = 5,
  editableFeatureID,
  isPolygonEditable = false,
  onMapClick,
}) => {
  useEffect(() => {
    console.log('general mapy iD', mapID);
    const defaultOptions = {
      center: initialCenter,
      zoom: initialZoom,
      scrollWheelZoom: true,
      fullscreencontrol: true,
    };
    // if (clusterMaxZoom) {
    //   defaultOptions.maxZoom = clusterMaxZoom || 18;
    // }
    defaultOptions.maxZoom = clusterMaxZoom || 22;
    const mapContainer = document.getElementById(mapID);
    map.initMap(mapContainer, defaultOptions);
    console.log(`map`, map);
  }, [mapID]);

  useEffect(() => {
    return () => {
      map.deleteAllShapes();
      // map.removeBaseLayer();
      map.popMap();
    };
  }, []);

  const drawPolygon = (e) => {
    const poly = e.layer._latlngs[0];
    const polygon = geoJsonToWKT(poly);
    if (polygon && onPolygonDraw) {
      onPolygonDraw(polygon);
    }
  };

  const editPolygon = (e) => {
    if (Object.values(e.sourceTarget._targets)[1] !== undefined) {
      let poly = Object.values(e.layers._layers)[0]._latlngs[0];

      if (poly !== undefined && onPolygonEdit) {
        let polygon = geoJsonToWKT(poly);
        onPolygonEdit(polygon);
      }
    }
  };

  const deletePolygon = (e) => {
    onPolygonDelete();
  };

  useEffect(() => {
    if (drawingControls) {
      map.addDrawPlugin(drawPolygon, editPolygon, deletePolygon);
    }
  }, [drawingControls, map]);

  useEffect(() => {
    if (polygons) {
      if (isArray(polygons)) {
        renderMapyPolygons(map, polygons, {
          groupName: polygonsGroupName,
          zoom: zoomToPolygons,
        });
      }
    }
  }, [polygons, map, polygonsGroupName]);

  useEffect(() => {
    map.deleteAllPolygons();
    if (singlePolygon) {
      renderSingleMapyPolygon(map, singlePolygon, {
        isEditable: isPolygonEditable,
      });
    }
  }, [singlePolygon, map]);

  useEffect(() => {
    if (markers) {
      renderMapyMarkers(map, markers, markerIcon, {
        groupName: markersGroupName,
        clusterMarkers,
        zoom: zoomToMarkers,
      });
    }
  }, [markers, map, markersGroupName]);

  useEffect(() => {
    if (editableFeatureID) {
      // map.enableEditToFeatures(editableFeatureID);
      map.enableEditToFeatures([
        { id: editableFeatureID, type: EditableableShapes.polygon },
      ]);
    }
  }, [editableFeatureID, map]);

  useEffect(() => {
    map.deleteAllMarkers();
    if (singleMarker) {
      renderSingleMapyMarker({
        map,
        marker: singleMarker,
        markerIcon,
        zoom: zoomToMarker,
      });
    }
  }, [singleMarker]);

  useEffect(() => {
    if (singlePolyLine) {
      map.deleteAllPolylines();
      renderSingleMapyPolyline(map, singlePolyLine, { zoom: zoomToPolyline });
    }
  }, [singlePolyLine]);

  useEffect(() => {
    if (onMapClick) {
      map.addOnMapClick(onMapClick);
    }
  }, [onMapClick]);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
      }}
    >
      <div
        id={mapID}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: '10px',
          position: 'relative',
          zIndex: '0',
        }}
      >
        {children && children}
      </div>
    </div>
  );
};

export default GenericMapy;
