import { getLanguage } from "../../lang/local";
import {
  CLEAR_WORKER_STATE,
  UPDATE_WORKER_PROPS,
  MERGE_WORKER_PROPS,
  DELETE_WORKER_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  workerObj: { workerItem: null, isLoading: false, newWorkers: null },
  sorting: { column: "", order: "desc" },
  workerForm: {
    isLoading: false,
    formvalues: {
      company_id: null,
      branch_id: null,
      work_field_id: [],
      points: [],
      user_name: "",
      password: "",
      name: "",
      ssn: "",
      mobile: "",
      email: "",
      image: null,
      job_title: null,
      password_confirmation: "",
      address: "",
      parent_id: null,
      worker_type: null,
    },
    index: null,
    isLodingEdit: false,
    iscreate: "create",
  },
  workerOptions: [],
  workFieldsNewOptions: [],

  fileOptions: {
    savedData: null,
    importedData: null,
    isLoading: false,
    errorData: null,
    headerData: null,
  },

  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
  lightbox: { isOpen: false, image: null },
  searchOptions: [],
  connectedWorker: [],

  searchForm: {
    parent_branch: null,
    company: null,
    work_field_id: null,
    branch_id: null,
    from: "",
    to: "",
    mobile: "",
    job_title: "",
    page: 1,
  },
  isLoadingSearch: false,
  
  workerTypeOption: [
    {
      label: getLanguage() === "en" ? "Operation" : "تشغيل",
      value: 1,
    },
    // { label: getLanguage() === "en" ? "Sales" : "مبيعات", value: 2 },
  ],
  jobTitlesOptions: [
    { label: getLanguage() === "en" ? "Employee" : "موظف", value: "Worker" },
    {
      label: getLanguage() === "en" ? "Supervisor" : "مشرف",
      value: "Supervisor",
    },
    {
      label: getLanguage() === "en" ? "Region Head" : "مدير منطقة",
      value: "Region Head",
    },
  ],
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",

    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title:
          getLanguage() === "en" ? "System Data" : "البيانات الخاصة بالنظام",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },

      {
        title:
          getLanguage() === "en" ? "Administrative data" : "البيانات الإدارية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],

    mapItem: {
      polygon: [],
      mapPoints: [31.4177636, 30.00475],
    },
    activeIndex: 0,
    tabAction: "next",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_WORKER_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_WORKER_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_WORKER_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_WORKER_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
