import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  getDeafultItem,
  WKTPointToGeoJson,
} from '../../utils/GeneralFunctions';
import { UPDATE_LIVE_TRACKING_PROPS } from '../../redux/actions/types';
import PaginationComponent from '../../utils/Pagination';

const TrackingTable = ({
  trackingObj,
  sorting,
  general,
  searchForm,
  getAllWorkers,
  companyWfOptions,
  socketMessage,
  setSocketMessage,
  setWorkers,
  setMapProps,
}) => {
  const EmployeeStatus = ({ employee }) => {
    const { new_location, work_field_id } = employee;
    if (new_location !== null) {
      return (
        <td
          className='td_table'
          style={{
            color: 'green',
            cursor: 'pointer',
          }}
          onClick={() => {
            const geoJsonPoint = WKTPointToGeoJson(new_location);
            const latLngLiteral = {
              lat: geoJsonPoint[0],
              lng: geoJsonPoint[1],
            };
            setMapProps([['mapBounds', [latLngLiteral]]]);
          }}
        >
          {work_field_id !== null && work_field_id !== 0
            ? local.ViewonMap
            : local.NotWork}
        </td>
      );
    } else {
      return (
        <td
          className='td_table'
          style={{
            color: 'red',
            cursor: 'default',
          }}
        >
          {work_field_id !== null && work_field_id !== 0
            ? local.NotViewMap
            : local.NotWork}
        </td>
      );
    }
  };

  const renderBody = () => {
    if (trackingObj.trackingWorkers !== null) {
      return trackingObj.trackingWorkers.map((w, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(w.id)}</td>

            <td className='td_table'>{w.name}</td>
            <td className='td_table'>{w.mobile}</td>
            <td className='td_table'>{w.address}</td>
            <td className='td_table'>{w.user_name}</td>
            {/* <td className="td_table">
              {getLanguage() === "en"
                ? new Date(w.start_at).toLocaleString()
                : new Date(w.start_at).toLocaleString("eg")}
            </td> */}
            {/* <td
              className="td_table"
              style={{
                color: w.new_location === null ? "red" : "green",
                cursor: w.new_location === null ? "default" : "pointer",
              }}
              onClick={() => {
                if (w.new_location !== null) {
                  const geoJsonPoint = WKTPointToGeoJson(w.new_location);
                  const latLngLiteral = {
                    lat: geoJsonPoint[0],
                    lng: geoJsonPoint[1],
                  };
                  setMapProps([["mapBounds", [latLngLiteral]]]);
                }
              }}
            >
              {w.work_field_id !== null && w.work_field_id !== 0
                ? w.new_location === null
                  ? local.NotViewMap
                  : local.ViewonMap
                : local.NotWork}
            </td> */}
            <EmployeeStatus employee={w} />

            <td className='td_table'>
              {w.featureProperties ? `${w.featureProperties.battery}%` : '-'}
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <Row>
        <Col xl={12}>
          <Table
            responsive
            hover
            id='job_table'
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Worker_Number,
                  trackingObj.trackingWorkers,
                  'worker.id',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.worker_name,
                  trackingObj.trackingWorkers,
                  'worker.name',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Mobile,
                  trackingObj.trackingWorkers,
                  'worker.mobile',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Address,
                  trackingObj.trackingWorkers,
                  'worker.address',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.UserName,
                  trackingObj.trackingWorkers,
                  'worker.user_name',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}

                {/* {renderTableHeadItem(
                local.StartTime,
                trackingObj.trackingWorkers,
                "worker.start_at",
                "trackingObj.trackingWorkers",
                UPDATE_LIVE_TRACKING_PROPS,
                general,
                sorting
              )} */}

                {renderTableHeadItem(
                  local.Status,
                  trackingObj.trackingWorkers,
                  'worker.start_at',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Battery,
                  trackingObj.trackingWorkers,
                  'worker.start_at',
                  'trackingObj.trackingWorkers',
                  UPDATE_LIVE_TRACKING_PROPS,
                  general,
                  sorting
                )}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {trackingObj.trackingWorkers?.length > 0 && (
        <PaginationComponent
          onPageChange={(pageNo) => {
            setWorkers([]);

            if (socketMessage) {
              socketMessage.close();
              setSocketMessage(null);
            }

            getAllWorkers(
              searchForm,
              getDeafultItem(searchForm.work_field, companyWfOptions),
              pageNo
            );
          }}
          count={trackingObj.workersResponse?.pagination.total_pages || 1}
          currentPage={trackingObj.workersResponse.pagination.current_page}
        />
      )}

      {trackingObj.trackingWorkers !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <span>{local.TotalWorkers + ' : '}</span>
            <span className='green_text'>
              {/* {renderNumber(trackingObj.totalWorkers)} */}
              {trackingObj.trackingWorkers.length > 20
                ? renderNumber(trackingObj.totalWorkers)
                : renderNumber(trackingObj.trackingWorkers.length)}
            </span>
          </Col>
        </Row>
      ) : null}
    </Card>
  );
};

export default TrackingTable;
