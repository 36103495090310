import React from "react";
import { Card, Col, Row, Button, Badge } from "reactstrap";
import report_noimage from "../../assets/img/report_noimage.png";
import report_icon from "../../assets/img/report_icon.png";
import logo_card_report from "../../assets/img/logo_card_report.png";
import eye_report from "../../assets/img/eye_report.png";
import { getLanguage, local } from "../../lang/local";
import { getDeafultItem, renderDate } from "../../utils/GeneralFunctions";
import { UPDATE_REPORT_PROPS } from "../../redux/actions/types";

const ReportCard = (props) => {
  const {
    repo_item,
    searchForm,
    companyWfOptions,
    history,
    general,
    report_index,
  } = props;

  const renderPhoto = (report, length) => {
    let items = Object.entries(report);

    let images = items.filter(
      (im) => im[0].includes("image") || im[0].includes("photo")
    );
    if (length === undefined) {
      if (images.length > 0) {
        return images[0][1];
      } else {
        return report_noimage;
      }
    }
    if (length !== undefined) {
      if (images.length > 0) {
        return images.length;
      }
      {
        return 0;
      }
    }
  };

  const onView = (e) => {
    e.preventDefault();
    general(
      [{ prop: "reportObj.single_report", value: report_index }],
      UPDATE_REPORT_PROPS
    );
    history.push("/view_daily_report");
  };

  return (
    <Col xl={3} className="mb-5 mt-5">
      <Card className="report_card">
        <center>
          <div className="report_div">
            <img
              src={
                repo_item.worker.image !== null
                  ? repo_item.worker.image
                  : report_noimage
              }
              className="report_img"
            />
          </div>
        </center>
        <Badge className="report_btn mt-2">
          <div className="mt-1 mb-1 ml-1 mr-1">
            <p style={{ display: "inline" }}>{local.DayReport}</p>&nbsp;
            <img src={report_icon} style={{ width: "20%", height: "20%" }} />
          </div>
        </Badge>
        <div>
          <Row className="mt-4">
            <Col className="text-center">
              <p className="report_text_label green_text ">
                {local.worker_name}
              </p>
            </Col>
            <Col className="text-center">
              <p className="report_text_label blue_text">
                {repo_item.worker.name}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <p className="report_text_label green_text">{local.DateAdded}</p>
            </Col>
            <Col className="text-center">
              <p className="report_text_label blue_text green_text">
                {renderDate(repo_item.created_at)}
              </p>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="text-center">
              <p className="report_text_label green_text">
                {local.place_address}
              </p>
            </Col>
            <Col className="text-center">
              <p className="report_text_label blue_text">
                {repo_item.work_field.name[getLanguage()]}
              </p>
            </Col>
          </Row>
          <Row className="mt-4 mr-2 ml-2 mb-3">
            <Col className="text-center">
              {/* <p className="desc_report">{renderColumnItem("report_desc")}</p> */}
            </Col>
          </Row>
        </div>
        <br /> <br />
        <Badge className="report_btn_bottom">
          <div className="mt-1 mb-1 ml-1 mr-1">
            <p style={{ display: "inline" }}>
              {renderPhoto(repo_item.report, true)}
            </p>
            &nbsp;
            <img
              src={logo_card_report}
              style={{ width: "20%", height: "20%" }}
            />
          </div>
        </Badge>
        <center>
          <div className="report_div_bottom">
            <Button
              className="report_btn_view"
              style={{ marginLeft: "-10%" }}
              onClick={(e) => onView(e)}
            >
              <p style={{ display: "inline", fontSize: "10px" }}>
                {local.ShowReport}
              </p>
              &nbsp;
              <img src={eye_report} style={{ width: "20%", height: "20%" }} />
            </Button>
          </div>
        </center>
      </Card>
    </Col>
  );
};

export default ReportCard;
