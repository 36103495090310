import { Request } from "../Api/index";
import {
  UPDATE_REQUESTS_PROPS,
  CLEAR_REQUESTS_FORM,
  CLEAR_REQUESTS_MODAL,
} from "./types";
import toaster from "toasted-notes";
import { local } from "../../lang/local";
import {
  confirmSunccessed,
  getQueryString,
  removeUnsetValues,
  setterGenerator,
} from "../../utils/GeneralFunctions";
import { general } from "./GeneralAction";

export const setRequestsProps = (propsArr) =>
  setterGenerator(UPDATE_REQUESTS_PROPS, propsArr);

export const clearRequestsForm = () => ({
  type: CLEAR_REQUESTS_FORM,
});

export const clearRequestsModal = () => ({
  type: CLEAR_REQUESTS_MODAL,
});

export const getRequests = (formValues) => (dispatch) => {
  dispatch({
    type: UPDATE_REQUESTS_PROPS,
    payload: [
      { prop: "requestsObj.requestsItem.data", value: [] },
      { prop: "requestsObj.requestsItem.pagination", value: {} },
    ],
  });
  let queries;
  let url = "/requests";
  let newFormValues = { ...formValues };
  if (formValues) {
    if (formValues.work_field_id) {
      newFormValues.work_field_id = formValues.work_field_id.id;
    }
    if (formValues.worker_id) {
      newFormValues.worker_id = formValues.worker_id.id;
    }
    if (typeof formValues?.order_status == 'number' && formValues.order_status !== 28) {
      newFormValues.visit_action = formValues.order_status;
    } else if(typeof formValues?.order_status == 'number' && formValues.order_status !== 28 && formValues.order_status == 0 ){
      newFormValues.visit_action = formValues.order_status;
    } else if (formValues.order_status == 28) {
      newFormValues.assigned = 1;
    }
    if (formValues?.branch_id && typeof formValues?.branch_id == "object") {
      newFormValues.branch_id = formValues.branch_id.id;
    }

    delete newFormValues.order_status;
  }
  queries = removeUnsetValues(newFormValues);
  queries = getQueryString(queries);
  url += queries;

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (res.data.data.data.length == 0) {
            confirmSunccessed(local.NoSearch);
          }
          const workerPropToRequests = res.data.data.data.map((request) => {
            if (request.worker?.length == 0) {
              delete request.worker;
              const newRequest = {
                ...request,
                worker: {
                  name: "",
                },
              };
              return newRequest;
            } else {
              return request;
            }
          });
          dispatch({
            type: UPDATE_REQUESTS_PROPS,
            payload: [
              {
                prop: "requestsObj.requestsItem.data",
                value: workerPropToRequests,
              },
              {
                prop: "requestsObj.requestsItem.pagination",
                value: res.data.data.pagination,
              },
              {
                prop: "requestsObj.pointsItem",
                value: null,
              },
            ],
          });
        }
      } else {
        Object.values(res.data.error).map((msg) => {
          toaster.notify(msg[0], {
            duration: 2000,
          });
        });
      }
      dispatch({
        type: UPDATE_REQUESTS_PROPS,
        payload: [{ prop: "requestsObj.isLoading", value: false }],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_REQUESTS_PROPS,
        payload: [{ prop: "requestsObj.isLoading", value: false }],
      });
    })
    .finally();
};

export const addRequest = (formValues, index, options) => (dispatch) => {
  dispatch({
    type: UPDATE_REQUESTS_PROPS,
    payload: [{ prop: "requestForm.isLoading", value: true }],
  });

  let newFormValues = Object.assign({}, formValues);

  Request()
    .post("/requests/store", newFormValues)
    .then((res) => {
      if (res.data.status === true) {
        if (formValues.id === undefined) {
          confirmSunccessed(local.SuccessfullyAdded);

          if (index === null && options !== null) {
            // dispatch({
            //   type: MERGE_REQUESTS_PROPS,
            //   payload: [
            //     {
            //       prop: "requestsObj.requestsItem.data",
            //       value: res.data.data,
            //     },
            //   ],
            // });
          }
        } else {
          confirmSunccessed(local.SuccessfullyEdit);

          if (index !== null && options !== null) {
            dispatch({
              type: UPDATE_REQUESTS_PROPS,
              payload: [
                {
                  prop: "requestsObj.requestsItem.data." + index,
                  value: res.data.data,
                },
              ],
            });
          }
        }

        dispatch(
          setRequestsProps([["lastCompanyWFPointAdded", res.data.data.data]])
        );

        dispatch(clearRequestsForm());
        dispatch(clearRequestsModal());

        // dispatch({
        //   type: DELETE_REQUESTS_PROPS,
        //   payload: [{ prop: "polygonItem" }],
        // });
      } else {
        Object.values(res.data.error).map((msg) => {
          toaster.notify(msg[0], {
            duration: 2000,
          });
        });
      }

      dispatch({
        type: UPDATE_REQUESTS_PROPS,
        payload: [{ prop: "requestForm.isLoading", value: false }],
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_REQUESTS_PROPS,
        payload: [{ prop: "requestForm.isLoading", value: false }],
      });
      console.log(err);
    })
    .finally();
};
