import { camelCase } from "lodash";
import React from "react";
import { Card, Col, Row } from "reactstrap";

const ErrorCard = (props) => {
  const { errorMessage } = props;
  return (
    <Card
      style={{
        border: "none",
        boxShadow: "1px 1px 3px red",
        borderRadius: "10px",
      }}
    >
      <div style={{ margin: "10px" }}>
        {errorMessage.map((error) => {
          return (
            <Row className="mt-3 mr-3 ml-3 mb-3">
              <Col>
                <p style={{ fontSize: "11px" }}>{error}</p>
              </Col>
            </Row>
          );
        })}
      </div>
    </Card>
  );
};

export default ErrorCard;
