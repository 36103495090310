import * as general from "./methods";

const INITIAL_STATE = {
  areYouSureModal: {
    isOpen: false,
    action: null,
  },
};

const ModalsReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_MODALS_PROPS": {
      return general.updateProps(state, action);
    }

    case "CLEAR_MODALS_PROPS": {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default ModalsReducer;
