import { connect } from 'react-redux';
import { Card, Col, Row, Form, Input } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import { local } from '../../lang/local';
import './styles/GiftsAndSamples/gifts-and-samples.css';
import { AsyncPaginate } from 'react-select-async-paginate';
import Select from 'react-select';
import { labelStyle, leftlStyle, selectStyle3 } from '../../utils/GeneralStyle';
import '../../assets/general-style/general-style.css';
import icon_btn from '../../assets/img/icon_btn.png';
import {
  checkHasOneCompany,
  getMyCompany,
  renderArraw,
  validateForm,
} from '../../utils/GeneralFunctions';
import { useEffect } from 'react';
import { UPDATE_GIFTS_PROPS } from '../../redux/actions/types';

const AddGiftPage = (props) => {
  const {
    history,
    getCompanyOptions,
    addGiftOrSample,
    addGiftForm,
    setGiftsProps,
    general,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
    general(
      [
        {
          prop: 'addGiftPage.addGiftForm.companyId',
          value: getMyCompany(general),
        },
      ],
      UPDATE_GIFTS_PROPS
    );
  }, []);

  return (
    <div className='page-wrapper'>
      <Row style={{ height: '100%' }}>
        <Col className='body-container'>
          <Form style={{ height: '100%' }}>
            <Card className='cardCreate ml-2 mr-2 mt-4'>
              <div className='mt-5 mr-5 ml-5'>
                <p style={leftlStyle}>{local.AddGiftOrSample}</p>
                {renderArraw(history)}
              </div>
              <div className='mt-3 mr-5 ml-5'>
                <br /> <br />
                <p className='page_title mt-3' style={leftlStyle}>
                  {local.MainInfo}
                </p>
                <br />
                <Row className='mt-5 justify-content-center justify-content-lg-start'>
                  <div className='react-select-div mt-4'>
                    <span className='label_title' style={labelStyle}>
                      {local.Name}
                    </span>
                    <Input
                      className='inputStyle mt-4'
                      placeholder={local.Name}
                      value={addGiftForm.name}
                      onChange={(e) => {
                        setGiftsProps([
                          ['addGiftPage.addGiftForm.name', e.target.value],
                        ]);
                      }}
                    />
                  </div>
                  <div className='react-select-div mt-4'>
                    <span className='label_title' style={labelStyle}>
                      {local.Details}
                    </span>
                    <Input
                      className='inputStyle mt-4'
                      placeholder={local.Details}
                      value={addGiftForm.details}
                      onChange={(e) => {
                        setGiftsProps([
                          ['addGiftPage.addGiftForm.details', e.target.value],
                        ]);
                      }}
                    />
                  </div>
                </Row>
                <Row className='justify-content-center justify-content-lg-start'>
                  {checkHasOneCompany() === false ? (
                    <div className='react-select-div mt-4'>
                      <span className='label_title' style={labelStyle}>
                        {local.Company}
                      </span>

                      <AsyncPaginate
                        loadOptions={getCompanyOptions}
                        value={
                          addGiftForm.companyId === null
                            ? null
                            : {
                                label: addGiftForm.companyLabel,
                                value: addGiftForm.companyId,
                              }
                        }
                        styles={selectStyle3}
                        className='mt-4'
                        placeholder={local.Company}
                        onChange={(e) => {
                          setGiftsProps([
                            ['addGiftPage.addGiftForm.companyId', e.value],
                          ]);
                          setGiftsProps([
                            ['addGiftPage.addGiftForm.companyLabel', e.label],
                          ]);
                        }}
                        additional={{
                          page: 1,
                        }}
                      />
                    </div>
                  ) : null}
                  <div className='react-select-div mt-4'>
                    <span className='label_title' style={labelStyle}>
                      {local.Type}
                    </span>
                    <Select
                      options={[
                        { label: local.Gift, value: 0 },
                        { label: local.Sample, value: 1 },
                      ]}
                      value={
                        addGiftForm.type === ''
                          ? null
                          : {
                              label:
                                addGiftForm.type === 0
                                  ? local.Gift
                                  : local.Sample,
                              value: addGiftForm.type,
                            }
                      }
                      className='mt-4'
                      styles={selectStyle3}
                      placeholder={local.Type}
                      onChange={(e) => {
                        setGiftsProps([
                          ['addGiftPage.addGiftForm.type', e.value],
                        ]);
                      }}
                      additional={{
                        page: 1,
                      }}
                    />
                  </div>
                </Row>
                <br />
                <center>
                  <button
                    type='submit'
                    className='save_btn mt-5'
                    onClick={(e) => {
                      e.preventDefault();
                      if (
                        validateForm([
                          {
                            label: local.Company,
                            value: addGiftForm.companyId,
                          },
                          {
                            label: local.Name,
                            value: addGiftForm.name,
                          },
                          {
                            label: local.Type,
                            value: addGiftForm.type,
                          },
                          {
                            label: local.Details,
                            value: addGiftForm.details,
                          },
                        ])
                      ) {
                        addGiftOrSample(
                          addGiftForm.companyId,
                          addGiftForm.name,
                          addGiftForm.type,
                          addGiftForm.details
                        );
                      }
                    }}
                  >
                    <>
                      <p style={{ display: 'inline' }}>
                        {local.AddGiftOrSample}
                      </p>
                      <img src={icon_btn} width='20' height='20' alt='img' />
                    </>
                  </button>
                </center>
              </div>
            </Card>
          </Form>
          <br />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ VisitR, GiftsAndSamplesReducer }) => {
  return {
    visitObj: VisitR.visitObj,
    modale: VisitR.modale,
    visitForm: VisitR.visitForm,
    sorting: VisitR.sorting,
    isLoadingSearch: VisitR.isLoadingSearch,
    searchForm: GiftsAndSamplesReducer.searchForm,
    giftsTableData: GiftsAndSamplesReducer.giftsTableData,
    giftsReportData: GiftsAndSamplesReducer.giftsReportData,
    editGiftsModal: GiftsAndSamplesReducer.editGiftsModal,
    addGiftsModal: GiftsAndSamplesReducer.addGiftsModal,
    lastCompanySearchedFor: GiftsAndSamplesReducer.lastCompanySearchedFor,
    addGiftForm: GiftsAndSamplesReducer.addGiftPage.addGiftForm,
  };
};

export default connect(mapStateToProps, actions)(AddGiftPage);
