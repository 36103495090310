import { getLanguage, local } from "../../lang/local";
import {
  DELETE_COMPANY_PROPS,
  MERGE_COMPANY_PROPS,
  UPDATE_BRANCHES_PROPS,
  UPDATE_COMPANY_PROPS,
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_STATE_PROPS,
  UPDATE_STATE_WORK_FIELD_PROPS,
  UPDATE_WORKER_PROPS,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import { labelStyle, selectStyle3, selectStyle4 ,selectStyle5 } from "../../utils/GeneralStyle";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getDeafultItem,
  validatePhoneLength,
} from "../../utils/GeneralFunctions";
import { Table, Button } from "reactstrap";
import { FiXSquare } from "react-icons/fi";
export const unsetForm = (general) => {
  general(
    [
      {
        prop: "companyForm",
        value: {
          isLoading: false,
          formvalues: {
            states: null,
            work_fields: null,
            address: "",
            mobile: "",
            identifier: "",
            name: { ar: "", en: "" },
            logo: null,
            active: false,
            parent_id: null,
          },
          index: null,
          isCreate: "create",
        },
      },
      { prop: "companyOptions", value: [] },
      { prop: "lightbox", value: { isOpen: false, image: null } },
      { prop: "stateValue", value: [] },
      { prop: "stateWFValue", value: [] },

      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
          tabs_title: [
            {
              title:
                getLanguage() === "en"
                  ? "Main Information"
                  : "البيانات الرئيسية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },

            {
              title:
                getLanguage() === "en" ? "Work Places" : "الاشخاص المسئولون",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
          ],
          activeIndex: 0,
          tabAction: "next",
        },
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};

export const validateInputs = (companyForm, stateValue, stateWFValue) => {
  if (companyForm.formvalues.identifier === "") {
    toaster.notify(local.IdentifierRequired, {
      duration: 2000,
    });
  }
  if (companyForm.formvalues.identifier) {
    if (companyForm.formvalues.identifier.toString().length !== 9)
      toaster.notify(local.IdentifierLengthRequired, {
        duration: 2000,
      });
  }
  if (companyForm.formvalues.mobile === "") {
    toaster.notify(local.MobileRequired, {
      duration: 2000,
    });
  }
  if (companyForm.formvalues.mobile !== "") {
    if (validatePhoneLength(companyForm.formvalues.mobile) === false)
      toaster.notify(local.EgPhoneLength, {
        duration: 2000,
      });
  }
  if (companyForm.formvalues.address === "") {
    toaster.notify(local.AddressRequired, {
      duration: 2000,
    });
  }
  if (companyForm.formvalues.name.en === "") {
    toaster.notify(local.EnglishNamequired, {
      duration: 2000,
    });
  }
  if (companyForm.formvalues.name.ar === "") {
    toaster.notify(local.ArabicNameRequired, {
      duration: 2000,
    });
  }
  if (stateValue.length === 0) {
    toaster.notify(local.SelectProvince, {
      duration: 2000,
    });
  }
  if (stateWFValue.length === 0) {
    toaster.notify(local.SelectWorkField, {
      duration: 2000,
    });
  }

  if (
    stateValue.length > 0 &&
    stateWFValue.length > 0 &&
    companyForm.formvalues.name.ar !== "" &&
    companyForm.formvalues.name.en !== "" &&
    companyForm.formvalues.address !== "" &&
    companyForm.formvalues.mobile !== "" &&
    validatePhoneLength(companyForm.formvalues.mobile) === true &&
    companyForm.formvalues.identifier !== "" &&
    companyForm.formvalues.identifier.toString().length === 9
  ) {
    return true;
  }
};

export const onSave = (
  e,
  createORUpdateCompany,
  companyForm,
  isModal,
  stateValue,
  stateWFValue
) => {
  e.preventDefault();
  if (validateInputs(companyForm, stateValue, stateWFValue) === true) {
    if (
      companyForm.formvalues.parent_id === null &&
      companyForm.formvalues.id !== undefined
    ) {
      companyForm.formvalues.parent_id = companyForm.formvalues.id;
    }

    createORUpdateCompany(
      companyForm.formvalues,
      companyForm.index,
      isModal,
      stateValue,
      stateWFValue
    );
  }
};

export const renderMultStateSelect = (
  prop,
  value,
  action,
  general,
  companyOptions,
  loadcompanyOptions,
  depend_prop,
  isCreate,
  stateValue
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {local.Province}
      </span>

      <AsyncPaginate
        options={
          isCreate !== undefined
            ? isCreate === "update"
              ? companyOptions
              : null
            : null
        }
        value={value}
        placeholder={local.Province}
        className="mt-4"
        styles={{...selectStyle4, ...selectStyle5}}
        loadOptions={(name, loadedOptions, page) =>
          loadcompanyOptions(name, loadedOptions, page, stateValue)
        }
        isMulti={false}
        isClearable={true}
        onChange={(e) => onChangeState(prop, e, general, action, depend_prop)}
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

export const onChangeState = (prop, e, general, action, depend_prop) => {
  if (e !== null) {
    general([{ prop: prop, value: e }], action);
  } else {
    general(
      [{ prop: "stateWFOptions", value: [] }],
      UPDATE_STATE_WORK_FIELD_PROPS
    );
    general(
      [
        { prop: prop, value: null },
        { prop: depend_prop, value: null },
      ],
      action
    );
  }
};
export const promiseOptions = (name, loadedOptions, page, endPoint, state_id) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(endPoint(name, loadedOptions, page, state_id));
    }, 0);
  });
export const renderMultStateWFSelect = (
  prop,
  value,
  action,
  general,
  stateWFOptions,
  loadStateWFOptions,
  state_id,
  isCreate,
  stateWFValue
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {local.StateWorkFields}
      </span>

      <AsyncPaginate
        value={value}
        options={
          isCreate !== undefined
            ? isCreate === "update"
              ? stateWFOptions
              : null
            : null
        }
        isClearable={true}
        key={value}
        isMulti={true}
        placeholder={local.StateWorkFields}
        className="mt-4"
        styles={selectStyle3}
        loadOptions={(name, loadedOptions, page) =>
          loadStateWFOptions(name, loadedOptions, page, state_id, stateWFValue)
        }
        onChange={(e) => general([{ prop: prop, value: e }], action)}
        menuIsOpen={state_id === null ? false : null}
        cacheUniqs={[() => {}, state_id]}
        onFocus={() =>
          state_id === null
            ? toaster.notify(local.SelectProvince, {
                duration: 2000,
              })
            : null
        }
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

export const renderCompanySelect = (
  prop,
  value,
  action,
  general,
  title,
  loadCompaniesOptions,
  companyOptions,
  isMutli,
  dependProps,
  col,
  isCreate,
  type,
) => {
  return (
    <>
      <AsyncPaginate
        key={value}
        options={
          isCreate !== undefined
            ? isCreate === "update"
              ? companyOptions
              : null
            : null
        }
        value={isMutli === null ? getDeafultItem(value, companyOptions) : value}
        placeholder={title}
        className={col !== null ? "mt-" + col : null}
        isMulti={isMutli === null ? false : true}
        loadOptions={loadCompaniesOptions}
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
        styles={{...selectStyle4, ...selectStyle5}}
        isClearable={true}
        onChange={(e) =>
          onChangeCompany(e, isMutli, prop, action, general, dependProps, type)
        }
      />
    </>
  );
};

export const onChangeCompany = (
  e,
  isMutli,
  prop,
  action,
  general,
  dependProps,
  type
) => {
  if (e !== null) {
    if (type !== undefined) {
      if (type === "isWorker") {
        if (e.active === false) {
          toaster.notify(local.NotActivited, {
            duration: 2000,
          });
        } else {
          saveCompanyValue(dependProps, general, action, isMutli, prop, e);
        }
      }
    } else {
      saveCompanyValue(dependProps, general, action, isMutli, prop, e);
    }
  } else {
    unsetProps(dependProps, general, action);
    general([{ prop: "branchOptions", value: [] }], UPDATE_BRANCHES_PROPS);

    general([{ prop: "workerOptions", value: [] }], UPDATE_WORKER_PROPS);

    general(
      [{ prop: "companyWfOptions", value: [] }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
    general(
      [{ prop: "pointsOptions", value: [] }],
      UPDATE_REQUESTS_PROPS
    );


    general([{ prop: "companyOptions", value: [] }], UPDATE_COMPANY_PROPS);

    general([{ prop: prop, value: null }], action);
  }
};

export const saveCompanyValue = (
  dependProps,
  general,
  action,
  isMutli,
  prop,
  e
) => {
  unsetProps(dependProps, general, action);

  general([{ prop: "workerOptions", value: [] }], UPDATE_WORKER_PROPS);
  general([{ prop: "branchOptions", value: [] }], UPDATE_BRANCHES_PROPS);

  general(
    [{ prop: "companyWfOptions", value: [] }],
    UPDATE_COMPANY_WORK_FIELD_PROPS
  );
  general(
    [{ prop: "pointsOptions", value: [] }],
    UPDATE_REQUESTS_PROPS
  );

  if (isMutli === null) {
    general([{ prop: prop, value: e.value }], action);
  } else {
    general([{ prop: prop, value: e }], action);
  }
};
export const unsetProps = (dependProps, general, action) => {
  if (dependProps !== null) {
    dependProps.map((prop) => {
      general([{ prop: prop.p, value: prop.v }], action);
    });
  }
};

export const unsetCompanyListData = (general) => {
  general(
    [
      { prop: "companyObj", value: { companyItem: null, isLoading: false } },

      {
        prop: "isLoadingSearch",
        value: false,
      },
      {
        prop: "searchForm",
        value: {
          states: null,
          work_fields: null,
          mobile: "",
          active: null,
          from: "",
          to: "",
          name: "",
          page: 1,
        },
      },

      {
        prop: "companyOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
  general(
    [
      {
        prop: "stateWFOptions",
        value: [],
      },
    ],
    UPDATE_STATE_WORK_FIELD_PROPS
  );

  general([{ prop: "stateOptions", value: [] }], UPDATE_STATE_PROPS);
};

export const renderAddButton = (
  stateValue,
  stateWFValue,
  general,
  stateItem,
  stateWFItem
) => {
  return (
    <Button
      style={{
        backgroundColor: "#003595",
        border: "none",
        borderRadius: "10px",
        float: getLanguage() === "ar" ? "right" : "left",
      }}
      className="mt-4"
      onClick={(e) => {
        onAdd(e, stateValue, stateWFValue, general, stateItem, stateWFItem);
      }}
    >
      <p style={{ margin: "4px" }}>{local.AddToTable}</p>
    </Button>
  );
};

export const onAdd = (
  e,
  stateValue,
  stateWFValue,
  general,
  stateItem,
  stateWFItem
) => {
  e.preventDefault();
  if (stateItem !== null) {
    if (stateValue.length === 0) {
      general(
        [
          { prop: "stateValue", value: [stateItem] },
          { prop: "companyForm.formvalues.states", value: null },
        ],
        UPDATE_COMPANY_PROPS
      );
    } else {
      let item = stateValue.find((v) => v.value === stateItem.value);
      if (item === undefined) {
        general(
          [{ prop: "stateValue", value: stateItem }],
          MERGE_COMPANY_PROPS
        );
      }

      general(
        [{ prop: "companyForm.formvalues.states", value: null }],
        UPDATE_COMPANY_PROPS
      );
    }
  }

  if (stateWFItem !== null) {
    if (stateWFValue.length === 0) {
      general(
        [
          { prop: "stateWFValue", value: stateWFItem },
          { prop: "companyForm.formvalues.work_fields", value: null },
        ],
        UPDATE_COMPANY_PROPS
      );
    } else {
      stateWFItem.map((stw, i) => {
        let item = stateWFValue.find((v) => v.value === stw.value);

        if (item === undefined) {
          general([{ prop: "stateWFValue", value: stw }], MERGE_COMPANY_PROPS);
        }

        if (i === stateWFItem.length - 1) {
          general(
            [{ prop: "companyForm.formvalues.work_fields", value: null }],
            UPDATE_COMPANY_PROPS
          );
        }
      });
    }
  }
};

export const renderTable = (stateValue, stateWFValue, general) => {
  return (
    <Table
      responsive
      id="stateWf_table"
      hover
      className="table_list mt-4 mb-4"
      style={{
        borderTopColor: "white",
        border: "none",
        position: "relative",
      }}
    >
      <thead>
        <tr className="table_head">
          <td>{local.Province}</td>
          <td>{local.StateWorkFields}</td>
          <td>{local.Remove}</td>
        </tr>
      </thead>

      {stateValue.length > 0
        ? stateValue.map((state, index) => {
            return (
              <tr key={index}>
                <td className="td_table">{state.label}</td>
                <td className="td_table">
                  {renderStateWf(state.value, stateWFValue)}
                </td>
                <td className="td_table">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) =>
                      onDelete(e, general, index, stateValue, stateWFValue)
                    }
                  >
                    <FiXSquare style={{ color: "red", fontSize: "25px" }} />
                  </a>
                </td>
              </tr>
            );
          })
        : null}
    </Table>
  );
};

export const renderStateWf = (state, work_fields) => {
  if (work_fields.length > 0) {
    let filterd_work_fields = work_fields.filter((wf) => wf.state_id === state);

    if (filterd_work_fields.length > 0) {
      return filterd_work_fields.map((fwf, index) => {
        if (index === 0) {
          return fwf.label;
        } else {
          return " | " + fwf.label;
        }
      });
    }
  }
};

export const onDelete = (e, general, index, stateValue, stateWFValue) => {
  e.preventDefault();
  let item = stateValue[index].value;
  stateWFValue.map((st) => {
    if (st.state_id === item) {
      let item_index = stateWFValue.findIndex((x) => x.value === st.value);
      if (item_index !== undefined) {
        if (item_index !== null) {
          general(
            [{ prop: "stateWFValue." + item_index }],
            DELETE_COMPANY_PROPS
          );
        }
      }
    }
  });

  general([{ prop: "stateValue." + index }], DELETE_COMPANY_PROPS);
};
