import React from "react";
import { Col, Row, CardBody } from "reactstrap";
import { local } from "../../lang/local";
import { UPDATE_USER_PROPS } from "../../redux/actions/types";
import GenericPhoneInput from "../../components/GenericPhoneInput/GenericPhoneInput";
import {
  renderGeneralInput,
  renderPasswordInput,
} from "../../utils/GeneralFunctions";

const FirstTab = (props) => {
  const { general, userForm } = props;
  return (
    <CardBody className="trans">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "userForm.formvalues.name",
            userForm.formvalues.name,
            UPDATE_USER_PROPS,
            general,
            local.Name
          )}
        </Col>
      </Row>
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <GenericPhoneInput
            prop="userForm.formvalues.mobile"
            value={userForm.formvalues.mobile}
            action={UPDATE_USER_PROPS}
            title={local.Mobile2}
            general={general}
            fullWidth
          />
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "userForm.formvalues.email",
            userForm.formvalues.email,
            UPDATE_USER_PROPS,
            general,
            local.Email
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderPasswordInput(
            "userForm.formvalues.password",
            userForm.formvalues.password,
            UPDATE_USER_PROPS,
            general,
            local.Password
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderPasswordInput(
            "userForm.formvalues.password_confirmation",
            userForm.formvalues.password_confirmation,
            UPDATE_USER_PROPS,
            general,
            local.ConfirmPassword
          )}
        </Col>
      </Row>
    </CardBody>
  );
};
export default FirstTab;
