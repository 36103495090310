import axios from "axios";
import { SERVICE_USER } from "../../utils/StaticKeys";
import config from "../../config.json";
import {
  checkLanguageIsArabic,
  rmUnsetValsAndGetQryStr,
} from "../../utils/GeneralFunctions";
import { getLanguage } from "../../lang/local";

export const getToken = () => {
  let item = JSON.parse(localStorage.getItem(SERVICE_USER));
  if (item !== null) {
    return item.api_token;
  }
};

export const HEADERS = {
  Authorization: "Bearer " + getToken(),
  contentType: "application/json",
  "Accept-Language": getLanguage() === "en" ? "en-US,en;q=0.9,ar;q=0.8" : "ar",
};

export const Request = (params = {}, resType) => {
  const configObj = {
    baseURL: config.api_url,
    headers: HEADERS,
    params: params,
  }
  if (resType) {
    configObj.responseType = resType
  }
  const instance = axios.create(configObj);

  instance.interceptors.request.use((config) => {
    config.params = {
      lang: config.params.name
        ? checkLanguageIsArabic(decodeURI(config.params.name))
          ? "ar"
          : "en"
        : getLanguage(),
      ...config.params,
    };
    return config;
  });

  return instance;
};

export const RequestLogin = () => {
  return axios.create({
    baseURL: config.api_url,
    contentType: "application/json",
    "Accept-Language":
      getLanguage() === "en" ? "en-US,en;q=0.9,ar;q=0.8" : "ar",
    params: {
      lang: getLanguage(),
    },
  });
};

export const Select_Request = {
  url: config.api_url,
  options: {
    method: "GET",
    mode: "cors",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json;charset=UTF-8",
      Authorization: "Bearer " + getToken(),
      "Accept-Language":
        getLanguage() === "en" ? "en-US,en;q=0.9,ar;q=0.8" : "ar",
    },
    params: {
      lang: getLanguage(),
    },
  },
};

export const getRequest = async (url, searchForm = {}, page = 1) => {
  if (searchForm.hasOwnProperty("page") === false) {
    searchForm.page = page;
  }
  const queryString = rmUnsetValsAndGetQryStr(searchForm);
  return await axios.get(`${config.api_url}/${url}${queryString}`, {
    headers: HEADERS,
  });
};

export const postRequest = async (url, body) => {
  return await axios.post(`${config.api_url}/${url}`, body, {
    headers: HEADERS,
  });
};
