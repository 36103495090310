import React from 'react';
import { Card, Col, Row, Button, Form, Spinner,  } from 'reactstrap';
import { leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';

import {
  renderDateInput,
  renderGeneralInputSearch,
  renderArraw,
} from '../../utils/GeneralFunctions';
import { UPDATE_STATE_PROPS } from '../../redux/actions/types';
import toaster from 'toasted-notes';

const StateSearch = (props) => {
  const { history, general, searchForm, isLoadingSearch, getStates } = props;
  const onSearch = (e) => {
    e.preventDefault();
    if (
      searchForm.name === '' &&
      searchForm.from === '' &&
      searchForm.to === ''
    ) {
      toaster.notify(local.SearchValidation, {
        duration: 2000,
      });
    } else {
      getStates(true, searchForm);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {local.Provinces}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderGeneralInputSearch(
              'searchForm.name',
              searchForm.name,
              UPDATE_STATE_PROPS,
              general,
              local.Name
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_STATE_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_STATE_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            <Button
              style={leftlStyle}
              type='submit'
              className='btn_search_style'
              disabled={isLoadingSearch}
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default StateSearch;
