import React, { Component } from 'react';
import { Form, Card, Row, Col, Spinner } from 'reactstrap';
import icon_btn from '../../assets/img/icon_btn.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { onSave, unsetReportTempleteListData } from './ReportTempleteFunctions';
import { labelStyle, leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import {
  rednerAddButton,
  renderTempleteInput,
  renderRemoveButton,
  renderTempleteSelect,
} from './ReportTempleteFunctions';
import { UPDATE_REPORT_TEMPLETE_PROPS } from '../../redux/actions/types';

import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { renderWorkFSelect } from '../CompainesWorkFields/CompanyWFFunctions';
import { renderBranchSelect } from '../Branches/BranchesFunction';
import { checkHasOneCompany, getMyCompany } from '../../utils/GeneralFunctions';
class AddReport extends Component {
  componentDidMount() {
    unsetReportTempleteListData(this.props.general);
    this.props.general(
      [
        {
          prop: 'reportForm.company_id',
          value: getMyCompany(this.props.general),
        },
      ],
      UPDATE_REPORT_TEMPLETE_PROPS
    );
  }
  render() {
    const {
      createORUpdateReport,
      reportForm,
      reportTempleteObj,
      general,
      templeteOptions,
      companyWfOptions,
      companyOptions,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      branchOptions,
      loadBranchesOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <Row style={{ height: '100%' }}>
          <Col className='body-container'>
            <Form
              style={{ height: '100%' }}
              onSubmit={(e) =>
                onSave(
                  e,
                  createORUpdateReport,
                  reportForm,
                  reportTempleteObj,
                  false
                )
              }
            >
              <Card className='cardCreate ml-2 mt-4 mr-2'>
                <div className='mt-5 mr-5 ml-5'>
                  <p style={leftlStyle}>{local.addReport}</p>
                  <br /> <br />
                  <p className='page_title mt-3' style={leftlStyle}>
                    {local.MainInfo}
                  </p>
                  <br /> <br /> <br />
                  {rednerAddButton(general)}
                  {reportForm.formvalues.template.length > 0
                    ? reportForm.formvalues.template.map((temp, index) => {
                        return (
                          <>
                            <div className='mt-4'>
                              {index !== 0 && index !== 1 && index !== 2
                                ? renderRemoveButton(index, general)
                                : null}
                            </div>
                            <Row className='justify-content-center justify-content-lg-start'>
                              <div className='react-select-div'>
                                {renderTempleteInput(
                                  'reportForm.formvalues.template.' +
                                    index +
                                    '.name',
                                  reportForm.formvalues.template[index].name,
                                  general
                                )}
                              </div>
                              <div className='react-select-div'>
                                {renderTempleteSelect(
                                  'reportForm.formvalues.template.' +
                                    index +
                                    '.type',
                                  reportForm.formvalues.template[index].type,
                                  'reportForm.formvalues.template.' +
                                    index +
                                    '.key',
                                  templeteOptions,
                                  general,
                                  +index
                                )}
                              </div>
                            </Row>
                          </>
                        );
                      })
                    : null}
                  <p className='page_title mt-5' style={leftlStyle}>
                    {local.ReportContent}
                  </p>
                  <br />
                  <br />
                  <Row className='mt-5 justify-content-center justify-content-lg-start'>
                    {checkHasOneCompany() === false ? (
                      <div className='react-select-div'>
                        <span className='label_title' style={labelStyle}>
                          {local.Company}
                        </span>
                        {renderCompanySelect(
                          'reportForm.company_id',
                          reportForm.company_id,
                          UPDATE_REPORT_TEMPLETE_PROPS,
                          general,
                          local.Company,
                          loadCompaniesOptions,
                          companyOptions,
                          null,
                          [
                            {
                              p: 'reportForm.formvalues.work_field_id',
                              v: null,
                            },
                            { p: 'reportForm.branch_id', v: null },
                          ],
                          '4'
                        )}
                      </div>
                    ) : null}

                    <div className='react-select-div'>
                      <span className='label_title' style={labelStyle}>
                        {local.Branch}
                      </span>
                      {renderBranchSelect(
                        'reportForm.branch_id',
                        reportForm.branch_id,
                        UPDATE_REPORT_TEMPLETE_PROPS,
                        general,
                        local.Branch,
                        loadBranchesOptions,
                        branchOptions,
                        reportForm.company_id,
                        null,
                        [
                          {
                            p: 'pointForm.formvalues.work_field_id',
                            v: null,
                          },
                        ],
                        '4',
                        reportForm.iscreate
                      )}
                    </div>
                    <div className='react-select-div'>
                      <span className='label_title' style={labelStyle}>
                        {local.WorkPlaces}
                      </span>
                      {renderWorkFSelect(
                        'reportForm.formvalues.work_field_id',
                        reportForm.formvalues.work_field_id,
                        UPDATE_REPORT_TEMPLETE_PROPS,
                        general,
                        local.WorkPlaces,
                        loadCompaniesWFOptions,
                        companyWfOptions,
                        null,
                        null,
                        reportForm.branch_id,
                        '4',
                        null
                      )}
                    </div>
                  </Row>
                  <center className='mt-5'>
                    <button
                      type='submit'
                      disabled={reportForm.isLoading}
                      className='save_btn mt-5'
                      onClick={(e) =>
                        onSave(
                          e,
                          createORUpdateReport,
                          reportForm,
                          reportTempleteObj,
                          false
                        )
                      }
                    >
                      {reportForm.isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <>
                          <p style={{ display: 'inline' }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  ReportTempleteR,
  CompanyR,
  CompanyWorkFieldR,
  BranchR,
}) => {
  return {
    reportForm: ReportTempleteR.reportForm,
    reportTempleteObj: ReportTempleteR.reportTempleteObj,
    companyOptions: CompanyR.companyOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    templeteOptions: ReportTempleteR.templeteOptions,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(AddReport);
