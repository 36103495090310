import { UPDATE_GIFTS_PROPS } from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  searchForm: {
    company: null,
    branch: null,
    workFields: null,
    workFieldsPoints: null,
    type: null,
    date: {
      from: "",
      to: "",
    },
    isSearching: false,
  },
  companies: null,
  companiesOptionsObject: {
    options: null,
    hasMore: null,
    additional: {
      page: 1,
    },
    pagination: {
      count: 1,
      currentPage: 1,
      perPage: null,
      total: null,
      totalPages: null,
    },
  },
  branchesOptions: [],
  areasOptions: [],
  visitsSpotsOptions: [],
  giftsTableSorting: {
    sortBy: "id",
    order: "asc",
    sortedArray: [],
  },
  giftsReportSorting: {
    sortBy: "id",
    order: "asc",
    sortedArray: [],
  },
  giftsTableData: null,
  giftsReportData: null,
  editGiftsModal: {
    isOpen: false,
    gift: {
      id: null,
      name: "",
      type: null,
      details: "",
    },
    confirm: false,
  },
  lastCompanySearchedFor: null,
  addGiftsModal: {
    isOpen: false,
    gift: {
      companyId: null,
      name: "",
      type: null,
      details: "",
    },
    confirm: false,
  },
  addGiftPage: {
    addGiftForm: {
      companyId: null,
      name: "",
      type: "",
      details: "",
      companyLabel: "",
      selectedCompanyObject: {
        label: "",
        value: null,
      },
    },
  },
};

const GiftsAndSamplesReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_GIFTS_PROPS: {
      return general.updateProps(state, action);
    }

    case "CLEAR_ALL": {
      state = INITIAL_STATE;
      return state;
    }

    default:
      return state;
  }
};

export default GiftsAndSamplesReducer;
