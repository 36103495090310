import dotProp from "dot-prop-immutable";
import { getLanguage, local } from "../../lang/local";
import {
  CLEAR_REQUESTS_STATE,
  UPDATE_REQUESTS_PROPS,
  MERGE_REQUESTS_PROPS,
  DELETE_REQUESTS_PROPS,
  CLEAR_REQUESTS_FORM,
  CLEAR_REQUESTS_MODAL,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  parent_branch: null,
  requestsObj: {
    requestsItem: {
      data: [],
      pagination: {},
    },
    pointsItem: null,
    isLoading: false,
    lat: null,
    lng: null,
    polygon: null,
    mapZoom: 4,
    coordinates: [],
  },
  sorting: { column: "", order: "desc" },

  requestForm: {
    isLoading: false,
    formvalues: {
      visit_type: null,
      point_id: null,
    },
  },
  pointForm: {
    isLoading: false,
    formvalues: {
      work_field_id: null,
      location: null,
      terminal_id: null,
      place_type: null,
      merchant_id: null,
      merchant_username: null,
      num_of_pos: "",
      requester_function: "",
      name: "",
      workers: null,
      visit_type: null,
      properties: {
        type: null,
        address: "",
        point_radius: "",
        landmark: "",
        mobile: "",
        requester_name: "",
        requester_email: "",
        link: "",
        errand_channel: "",
        pos_type: "",
        comment: "",
      },
      requester: { name: "" },
      data: [], // array of {id: number, name: string, workingDays: {day: {from: string, to: string}}} I can change this to whatever I want
    },
    index: null,
    company: null,
    branch_id: null,
    iscreate: "create",
    work_fields_points: [],
  },
  pointsOptions: [],
  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
  lastCompanyWFPointAdded: null,

  dayOptions: [
    {
      label: getLanguage() === "en" ? "Monday" : "الاثنين",
      value: "Monday",
    },
    {
      label: getLanguage() === "en" ? "Tuesday" : "الثلاثاء",
      value: "Tuesday",
    },
    {
      label: getLanguage() === "en" ? "Wednesday" : "الاربعاء",
      value: "Wednesday",
    },
    {
      label: getLanguage() === "en" ? "Thursday" : "الخميس",
      value: "Thursday",
    },
    {
      label: getLanguage() === "en" ? "Friday" : "الجمعة",
      value: "Friday",
    },
    {
      label: getLanguage() === "en" ? "Saturday" : "السبت",
      value: "Saturday",
    },

    {
      label: getLanguage() === "en" ? "Sunday" : "الحد",
      value: "Sunday",
    },
  ],

  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",

    tabs_title: [
      {
        title: local.MainInfo,
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title: local.WorkPlaces,
        active: "",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title: getLanguage() === "en" ? "Point Properties" : "خصائص النقطة",

        active: "",
        dots_pagination_active: "dots_pagination_active",
      },
    ],

    mapItem: {
      polygon: [],
      mapPoints: [31.4177636, 30.00475],
    },
    activeIndex: 0,
    tabAction: "next",
  },
  lightbox: { isOpen: false, image: null },

  searchForm: {
    merchant_username: null,
    requester_function: null,
    errand_channel: null,
    pos_type: null,
    mobile: null,
    visit_action: "",
    company: null,
    branch_id: null,
    work_field_id: null,
    visit_id: null,
    visit_action: null,
    from: "",
    to: "",
    id: "",
    place_type: null,
    order_status: null,
    page: 1,
    ids: null,
    name: "",
    title: null,
    mobile: "",
    assigned: null,
    withoutEmployees: null,
    merchant_id: null,
    terminal_id: null,
    markedTickets: [],
  },
  isLoadingSearch: false,
  searchOptions: [],
  wizardPage: 1,
  parent_branch: null,
};
export default (state = INITIAL_STATE, action) => {
  const setState = (prop, value) => {
    return dotProp.set(state, prop, value);
  };

  switch (action.type) {
    case UPDATE_REQUESTS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_REQUESTS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_REQUESTS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_REQUESTS_FORM: {
      setState("requestForm.formvalues", INITIAL_STATE.requestForm.formvalues);
      return setState(
        "pointForm.formvalues",
        INITIAL_STATE.pointForm.formvalues
      );
    }
    case CLEAR_REQUESTS_MODAL: {
      return setState("modale", INITIAL_STATE.modale);
    }
    case CLEAR_REQUESTS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};

// export default RequestsR;
