import React, { Component } from 'react';
import { Form, Card, Spinner, Col, Row, Button } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import {
  onSave,
  renderAddButton,
  renderCompanySelect,
  renderMultStateSelect,
  renderMultStateWFSelect,
  renderTable,
} from './CompainesFunction';
import { getLanguage, local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { UPDATE_COMPANY_PROPS } from '../../redux/actions/types';
import {
  renderArabicNameInput,
  renderEnglishNameInput,
  renderPhotoCard,
  renderGeneralInput,
  renderPhoneInput,
  renderArraw,
} from '../../utils/GeneralFunctions';
import LightBoxFile from '../../utils/LightBoxFile';
import { labelStyle, leftlStyle, rightStyle } from '../../utils/GeneralStyle';
import { FaFileExcel } from 'react-icons/fa';
import { unsetCompanyListData } from './CompainesFunction';
class AddCompany extends Component {
  componentDidMount() {
    unsetCompanyListData(this.props.general);
    this.props.getStatesForDropDown();

    this.props.getCompainesForDropDown();
  }

  render() {
    const {
      createORUpdateCompany,
      companyForm,
      general,
      stateWFOptions,
      stateOptions,
      lightbox,
      history,
      companyOptions,
      loadStateOptions,
      loadStateWFOptions,
      loadCompaniesOptions,
      stateWFValue,
      stateValue,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_COMPANY_PROPS}
        />

        <Row style={{ height: '100%' }}>
          <Col className='mt-4 body-container'>
            <Form
              onSubmit={(e) =>
                onSave(
                  e,
                  createORUpdateCompany,
                  companyForm,
                  false,
                  false,
                  stateValue,
                  stateWFValue
                )
              }
              style={{ height: '100%' }}
            >
              <Card className='cardCreate ml-2 mr-2 mt-4'>
                <div className='mt-5 mr-5 ml-5'>
                  <Row>
                    <Col>
                      <p style={leftlStyle}>{local.AddCompany}</p>
                    </Col>
                    {renderArraw(history)}
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <p className='page_title' style={leftlStyle}>
                        {local.MainInfo}
                      </p>
                    </Col>
                    <Col>
                      <div style={rightStyle}>
                        <Button
                          className='place_btn'
                          onClick={(e) =>
                            history.push('/dashboard/add_company_from_file')
                          }
                        >
                          <span style={{ fontSize: '10px' }}>
                            {local.AddFile}
                          </span>
                          &nbsp;
                          <span>
                            <FaFileExcel />
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-5 justify-content-center justify-content-lg-start'>
                    <div className='mt-4 mx-3'>
                      <div
                        className={
                          getLanguage() === 'en'
                            ? 'photo_float_left'
                            : 'photo_float_right'
                        }
                      >
                        {renderPhotoCard(
                          general,
                          'companyForm.formvalues.logo',
                          UPDATE_COMPANY_PROPS,
                          companyForm.formvalues.logo
                        )}
                      </div>
                    </div>
                    <Col
                      xl={3}
                      className={getLanguage() === 'en' ? 'mt-4' : 'mt-4'}
                    >
                      <Row className='justify-content-center justify-content-lg-start'>
                        <div className='react-select-div'>
                          {renderEnglishNameInput(
                            'companyForm.formvalues.name.en',
                            companyForm.formvalues.name.en,
                            UPDATE_COMPANY_PROPS,
                            general
                          )}
                        </div>
                        <div className='react-select-div'>
                          {renderArabicNameInput(
                            'companyForm.formvalues.name.ar',
                            companyForm.formvalues.name.ar,
                            UPDATE_COMPANY_PROPS,
                            general
                          )}
                        </div>
                      </Row>
                    </Col>
                    <Col xl={3} className='mt-4'>
                      <Row className='justify-content-center justify-content-lg-start'>
                        <div className='react-select-div'>
                          {renderGeneralInput(
                            'companyForm.formvalues.address',
                            companyForm.formvalues.address,
                            UPDATE_COMPANY_PROPS,
                            general,
                            local.Address
                          )}
                        </div>
                        <div className='react-select-div'>
                          {renderPhoneInput(
                            'companyForm.formvalues.mobile',
                            companyForm.formvalues.mobile,
                            UPDATE_COMPANY_PROPS,
                            general
                          )}
                        </div>
                      </Row>
                    </Col>
                    <Col xl={3} className='mt-4'>
                      <Row className='justify-content-center justify-content-lg-start'>
                        <div className='react-select-div'>
                          {renderGeneralInput(
                            'companyForm.formvalues.identifier',
                            companyForm.formvalues.identifier,
                            UPDATE_COMPANY_PROPS,
                            general,
                            local.TaxNumber,
                            undefined,
                            9
                          )}
                        </div>
                        <div className='react-select-div'>
                          <span className='label_title' style={labelStyle}>
                            {local.MajorCompany}
                          </span>
                          {renderCompanySelect(
                            'companyForm.formvalues.parent_id',
                            companyForm.formvalues.parent_id,
                            UPDATE_COMPANY_PROPS,
                            general,
                            local.MajorCompany,
                            loadCompaniesOptions,
                            companyOptions,
                            null,
                            null,
                            '4'
                          )}
                        </div>
                      </Row>
                    </Col>
                  </Row>
                  <br />

                  <p className='page_title mt-5' style={leftlStyle}>
                    {local.WorkPlaces}
                  </p>
                  <br />
                  <br />
                  <Row className='mt-5 justify-content-center justify-content-lg-start'>
                    <div className='mt-4 react-select-div'>
                      {renderMultStateSelect(
                        'companyForm.formvalues.states',
                        companyForm.formvalues.states,
                        UPDATE_COMPANY_PROPS,
                        general,
                        stateOptions,
                        loadStateOptions,
                        'companyForm.formvalues.work_fields',
                        companyForm.isCreate,
                        stateValue
                      )}
                    </div>
                    <div className='mt-4 react-select-div'>
                      {renderMultStateWFSelect(
                        'companyForm.formvalues.work_fields',
                        companyForm.formvalues.work_fields,
                        UPDATE_COMPANY_PROPS,
                        general,
                        stateWFOptions,
                        loadStateWFOptions,
                        companyForm.formvalues.states,
                        companyForm.isCreate,
                        stateWFValue
                      )}
                    </div>
                    <Col xl={2} className='mt-4'>
                      {renderAddButton(
                        stateValue,
                        stateWFValue,
                        general,
                        companyForm.formvalues.states,
                        companyForm.formvalues.work_fields
                      )}
                    </Col>
                  </Row>
                  <Row className='mt-5'>
                    <Col xl={12}>
                      {renderTable(stateValue, stateWFValue, general)}
                    </Col>
                  </Row>
                  <br />
                  <br />
                  <br />
                  <center className='mt-5'>
                    <button
                      type='submit'
                      disabled={companyForm.isLoading}
                      className='save_btn mt-5'
                      onClick={(e) =>
                        onSave(
                          e,
                          createORUpdateCompany,
                          companyForm,
                          false,
                          stateValue,
                          stateWFValue
                        )
                      }
                    >
                      {companyForm.isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <div>
                          <p style={{ display: 'inline' }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </div>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ CompanyR, StateWFR, StateR }) => {
  return {
    companyForm: CompanyR.companyForm,
    modale: CompanyR.modale,
    stateOptions: StateR.stateOptions,
    stateWFOptions: StateWFR.stateWFOptions,
    companyObj: CompanyR.companyObj,
    lightbox: CompanyR.lightbox,
    companyOptions: CompanyR.companyOptions,
    stateValue: CompanyR.stateValue,
    stateWFValue: CompanyR.stateWFValue,
  };
};

export default connect(mapStateToProps, actions)(AddCompany);
