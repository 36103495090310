import { AiOutlineClose } from "react-icons/ai";
import { getLanguage, local } from "../../lang/local";

const PointDetailsWindow = ({ point, isDisplayed, setIsDisplayed }) => {
  const lang = getLanguage();

  const DetailsItem = ({ label, value }) => {
    return (
      <div style={{ textAlign: "start" }}>
        <span className="text-main mx-2">{label}:</span>
        {value}
      </div>
    );
  };

  const containerStyles = {
    backgroundColor: "white",
    padding: "10px",
    paddingTop: "0px",
    borderRadius: "10px",
    marginTop: "10px",
    display: isDisplayed ? "block" : "none",
    position: "absolute",
    top: 0,
    left: "50%",
    transform: "translateX(-50%)",
    zIndex: 2,
  };

  const closeBtnStyles = {
    textAlign: lang === "en" ? "end" : "start",
    marginBottom: "-10px",
  };

  if (lang === "en") {
    closeBtnStyles.marginRight = "-20px";
    containerStyles.paddingRight = "25px";
  } else {
    closeBtnStyles.marginLeft = "-20px";
    containerStyles.paddingLeft = "25px";
  }

  return point ? (
    <div style={containerStyles}>
      <div
        style={closeBtnStyles}
        role="button"
        onClick={() => setIsDisplayed(false)}
      >
        <AiOutlineClose />
      </div>
      <DetailsItem label={local.WorkFieldName} value={point.name} />

     

      <DetailsItem
        label={local.WorkPlaces}
        value={point.work_field.name[getLanguage()]}
      />

      <DetailsItem label={local.Address} value={point.work_field.address} />
    </div>
  ) : null;
};

export default PointDetailsWindow;
