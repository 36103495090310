import { Row, Spinner } from 'reactstrap';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
import { handleError, handleSelectChange } from '../../utils/GeneralFunctions';
import { labelStyle, leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import { UPDATE_COVERAGE_LIST_PROPS } from '../../redux/actions/types';
import StaticOptionsSelect from '../../components/StaticOptionsSelect/StaticOptionsSelect';
import { useState } from 'react';

const AddToCoverageList = ({
  CoverageListReducer,
  getPointsOptions,
  setCoverageListProps,
  addPointsToEmployee,
  employeeWorkFields,
  getCoverageList,
  searchForm,
  general,
}) => {
  const {
    selectedCompany,
    selectedEmployee,
    fullCoverageList,
    addToCoverageListForm,
  } = CoverageListReducer;

  const [isFilteredPointsDisabled, setIsFilteredPointsDisabled] =
    useState(true);

  const validateAddingToList = () => {
    const { workField, points } = addToCoverageListForm;

    if (!selectedEmployee) {
      handleError(local.SelectEmployee);
    } else if (!workField) {
      handleError(local.SelectWorkField);
    } else if (!points) {
      handleError(local.SelectWorkFieldPoint);
    }

    if ((!selectedEmployee || !workField, !points)) return false;
    if (points.length === 0) return false;
    else return true;
  };

  const addPointsToEmployeeList = () => {
    if (validateAddingToList()) {
      addPointsToEmployee(CoverageListReducer);
    }
  };

  const filterPointsByCoverageList = (option, rawInput) => {
    if (fullCoverageList.some((item) => item.point.id === option.data.id))
      return false;
    else return true;
  };

  const getEmployeeFullCoverageList = async (option) => {
    setIsFilteredPointsDisabled(false);
    setCoverageListProps([['fullCoverageList', []]]);
    const filters = {
      workFieldID: option.id,
      worker: { id: selectedEmployee },
      companyID: selectedCompany,
    };
    getCoverageList(
      filters,
      (res) => {
        if (res.data.status === true) {
          const coverageListData = res.data.data.data;
          const pagination = res.data.data.pagination;
          const { total_pages } = pagination;
          if (total_pages > 1) {
            for (let i = 2; i <= total_pages; i++) {
              getCoverageList(
                { ...filters, page: i },
                (res) => {
                  if (res.data.status === true) {
                    coverageListData.push(...res.data.data.data);
                  }
                },
                { stopSideEffects: true }
              );
            }
          }
          setCoverageListProps([['fullCoverageList', coverageListData]]);
          setIsFilteredPointsDisabled(false);
        }
      },
      { stopSideEffects: true }
    );
  };

  return (
    <div className='page-wrapper'>
      <div>
        <p className='page_title mt-3' style={leftlStyle}>
          {local.AddToCoverageList}
        </p>
      </div>
      <Row className='justify-content-center justify-content-lg-start col-12'>
        <StaticOptionsSelect
          title={local.WorkField}
          prop='addToCoverageListForm.workField'
          value={addToCoverageListForm.workField}
          action={UPDATE_COVERAGE_LIST_PROPS}
          options={employeeWorkFields}
          hasLabel={true}
          dependentProps={['addToCoverageListForm.points']}
          className='mt-4'
          onChangeCB={getEmployeeFullCoverageList}
        />

        <div className='mt-4'>
          <span className='label_title' style={labelStyle}>
            {local.WorkFieldPoint}
          </span>
          <CustomAsyncPaginate
            loadOptions={getPointsOptions}
            value={addToCoverageListForm.points}
            placeholder={local.WorkFieldPoint}
            isMulti={true}
            onChange={(e) =>
              handleSelectChange(
                e,
                'addToCoverageListForm.points',
                setCoverageListProps,
                [],
                true
              )
            }
            cacheUniqsArr={[addToCoverageListForm.workField]}
            additional={{
              page: 1,
              companyId: selectedCompany,
              workFields: [addToCoverageListForm.workField],
            }}
            filterOption={filterPointsByCoverageList}
            isDisabled={isFilteredPointsDisabled}
          />
        </div>
      </Row>
      <br />
      <center>
        <button
          type='button'
          className='save_btn mt-5'
          onClick={addPointsToEmployeeList}
          disabled={searchForm.isLoading || searchForm.isLoading2}
        >
          <>
            {searchForm.isLoading ? (
              <Spinner type='grow' color='dark' size='sm' />
            ) : (
              <p style={{ display: 'inline' }}>{local.Add}</p>
            )}
          </>
        </button>
      </center>
    </div>
  );
};

export default AddToCoverageList;
