import React, { Component } from "react";
import { Form, Card, Spinner, Col, Row } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
import { onSave } from "./BranchesFunction";

import { local } from "../../lang/local";
import icon_btn from "../../assets/img/icon_btn.png";
import { UPDATE_BRANCHES_PROPS } from "../../redux/actions/types";
import {
  renderArabicNameInput,
  renderEnglishNameInput,
  renderPhoneInput,
  renderArraw,
  renderGeneralInput,
  getMyCompany,
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";
import { labelStyle, leftlStyle } from "../../utils/GeneralStyle";
import { unsetBranchListData } from "./BranchesFunction";
class AddBranch extends Component {
  componentDidMount() {
    unsetBranchListData(this.props.general);

    this.props.general(
      [
        {
          prop: "branchForm.formvalues.company_id",
          value: getMyCompany(this.props.general),
        },
      ],
      UPDATE_BRANCHES_PROPS
    );
  }

  render() {
    const {
      createORUpdateBranch,
      branchForm,
      general,
      history,
      companyOptions,
      loadCompaniesOptions,
      setBranchProps,
    } = this.props;
    return (
      <div className="page-wrapper">
        <Row style={{ height: "100%" }}>
          <Col className="mt-4 body-container">
            <Form
              onSubmit={(e) =>
                onSave(e, createORUpdateBranch, branchForm, false, false)
              }
              style={{ height: "100%" }}
            >
              <Card className="cardCreate ml-2 mr-2 mt-4">
                <div className="mt-5 mr-5 ml-5">
                  <Row>
                    <Col>
                      <p style={leftlStyle}>{local.AddBranch}</p>
                    </Col>
                    {renderArraw(history)}
                  </Row>
                  <br />
                  <Row>
                    <Col>
                      <p className="page_title" style={leftlStyle}>
                        {local.MainInfo}
                      </p>
                    </Col>
                  </Row>
                  <Row className="justify-content-center justify-content-lg-start">
                    <div className="mt-5 react-select-div">
                      {renderEnglishNameInput(
                        "branchForm.formvalues.name.en",
                        branchForm.formvalues.name.en,
                        UPDATE_BRANCHES_PROPS,
                        general
                      )}
                    </div>
                    <div className="mt-5 react-select-div">
                      {renderArabicNameInput(
                        "branchForm.formvalues.name.ar",
                        branchForm.formvalues.name.ar,
                        UPDATE_BRANCHES_PROPS,
                        general
                      )}
                    </div>
                    <div className="mt-5 react-select-div">
                      {renderGeneralInput(
                        "branchForm.formvalues.address",
                        branchForm.formvalues.address,
                        UPDATE_BRANCHES_PROPS,
                        general,
                        local.Address
                      )}
                    </div>
                  </Row>
                  <Row className="justify-content-center justify-content-lg-start">
                    <div className="mt-5 react-select-div">
                      {renderPhoneInput(
                        "branchForm.formvalues.mobile",
                        branchForm.formvalues.mobile,
                        UPDATE_BRANCHES_PROPS,
                        general
                      )}
                    </div>

                    <div className="mt-5 react-select-div">
                      {renderGeneralInput(
                        "branchForm.formvalues.manager_name",
                        branchForm.formvalues.manager_name,
                        UPDATE_BRANCHES_PROPS,
                        general,
                        local.ManagerName
                      )}
                    </div>

                    {checkHasOneCompany() === false ? (
                      <div className="mt-5 react-select-div">
                        <span className="label_title" style={labelStyle}>
                          {local.Company}
                        </span>
                        {renderCompanySelect(
                          "branchForm.formvalues.company_id",
                          branchForm.formvalues.company_id,
                          UPDATE_BRANCHES_PROPS,
                          general,
                          local.Company,
                          loadCompaniesOptions,
                          companyOptions,
                          null,
                          null,
                          "4"
                        )}
                      </div>
                    ) : null}
                    <div className="mt-5 react-select-div">
                      <CustomAsyncPaginate
                        fullWidth
                        hasLabel
                        loadOptions={getBranchOptions(
                          branchForm.formvalues.company_id,
                          { parent_id: 0 }
                        )}
                        onChange={(e) => {
                          handleSelectChangeEnhanced(
                            e,
                            "branchForm.formvalues.parent_id",
                            setBranchProps
                          );
                        }}
                        value={branchForm.formvalues.parent_id}
                        placeholder={local.Regions}
                        additional={{
                          page: 1,
                        }}
                        isDisabled={!!!branchForm.formvalues.company_id}
                      />
                    </div>
                  </Row>
                  <center className="mt-5">
                    <button
                      type="submit"
                      disabled={branchForm.isLoading}
                      className="save_btn mt-5"
                      onClick={(e) =>
                        onSave(e, createORUpdateBranch, branchForm, false)
                      }
                    >
                      {branchForm.isLoading === true ? (
                        <Spinner type="grow" color="dark" size="sm" />
                      ) : (
                        <>
                          <p style={{ display: "inline" }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width="20"
                            height="20"
                            alt="img"
                          />
                        </>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ BranchR, CompanyR }) => {
  return {
    branchForm: BranchR.branchForm,
    modale: BranchR.modale,
    branchObj: BranchR.branchObj,
    companyOptions: CompanyR.companyOptions,
  };
};

export default connect(mapStateToProps, actions)(AddBranch);
