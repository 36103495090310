import React from "react";
import { Row, Col, Button, Table } from "reactstrap";
import ModalView from "../../utils/ModalView";
import { local } from "../../lang/local";
import { UPDATE_BRANCHES_PROPS } from "../../redux/actions/types";
import { renderDate, renderProfileRow } from "../../utils/GeneralFunctions";
import user_profile from "../../assets/img/user_profile.png";
import track_inactive from "../../assets/img/track_inactive.png";
import mobiles from "../../assets/img/mobiles.png";

const BranchProfile = ({ modaleView, general }) => {
  const renderBody = () => {
    return (
      <div>
        {renderProfileRow(
          local.EnglishName,
          modaleView.data.name.en,
          user_profile,
          local.ArabicName,
          modaleView.data.name.ar,
          user_profile
        )}

        {renderProfileRow(
          local.Address,
          modaleView.data.address,
          track_inactive,
          local.Mobile,
          modaleView.data.mobile,
          mobiles
        )}

        {renderProfileRow(
          local.ManagerName,
          modaleView.data.manager_name,
          user_profile,
          null,
          null,
          null,
          null
        )}

        {modaleView.data.states !== undefined ? (
          modaleView.data.states !== null ? (
            <>
              <p className="text-center mt-5">{local.Provinces}</p>

              <Table
                striped
                responsive
                className="table_list"
                style={{
                  borderTopColor: "white",
                  border: "none",
                  position: "relative",
                }}
              >
                <thead>
                  <tr>
                    <th>{local.EnglishName}</th>
                    <th>{local.ArabicName}</th>
                  </tr>
                </thead>
                <tbody>
                  {modaleView.data.states.map((st, i) => {
                    return (
                      <tr key={i}>
                        <th>{st.name.en}</th>
                        <th>{st.name.ar}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : null
        ) : null}
      </div>
    );
  };
  const renderChildren = () => {
    if (modaleView.data !== null) {
      return (
        <div>
          {modaleView.data !== null ? (
            <>
              <Row>
                <Col xl={1}>
                  <p className="green_text">{local.Id}</p>
                </Col>
                <Col xl={1}>
                  <p className="blue_text">{modaleView.data.id}</p>
                </Col>
                <Col>
                  <Button className="sing_report_btn_blue">
                    <div>
                      <p style={{ display: "inline", fontSize: "10px" }}>
                        {renderDate(modaleView.data.created_at)}
                      </p>
                    </div>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col>{renderBody()}</Col>
              </Row>
            </>
          ) : null}
        </div>
      );
    }
  };
  return (
    <ModalView
      children={renderChildren}
      modaleView={modaleView}
      general={general}
      action={UPDATE_BRANCHES_PROPS}
    />
  );
};

export default BranchProfile;
