import { setTableProps } from "../../../redux/actions/TableActions";
import { setIsLoading } from "../../../redux/actions/SearchFormActions";
import { getVisitsSpotsReports as getVisitsSpotsReportsData } from "../../../redux/Api/apiCallsMethods";
import { handleError } from "../../../utils/GeneralFunctions";
import { local } from "../../../lang/local";

export const getVisitsSpotsReports =
  (companyID, searchForm = {}, { successCB } = {}) =>
  (dispatch) => {
    dispatch(setIsLoading(true));
    const { page } = searchForm;
    const filters = {
      page,
    };
    getVisitsSpotsReportsData(companyID, filters)
      .then((res) => {
        console.log("res", res);
        if (res.data.code === 200) {
          dispatch(setTableProps([["fullData", res.data.data]]));
          if (successCB) successCB(res);
        } else {
          handleError(local.SomethingWentWrong);
        }
      })
      .catch((err) => {
        handleError(local.SomethingWentWrong);
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };

export const openReportDetailsModal = (reportData) => (dispatch) => {
  dispatch(setTableProps([["modaleView", { isOpen: true, data: reportData }]]));
};

export const closeReportDetailsModal = () => (dispatch) => {
  dispatch(setTableProps([["modaleView", { isOpen: false, data: null }]]));
};
