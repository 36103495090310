import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mapy } from 'mapy_sdk';
import { Card, Col, Row } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import {  getMyCompany } from '../../utils/GeneralFunctions';
import TrackingSearch from './TrackingSearch';
import {
  CLEAR_LIVE_TRACKING_STATE,
  UPDATE_LIVE_TRACKING_PROPS,
} from '../../redux/actions/types';
import TrackingMenu from './TrackingMenu';
import TrackingTable from './TrackingTable';
import TrackingMapy from './TrackingMapy';

let map = new Mapy();

class LiveTracking extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarOpen: true,
      test: '',
      workers: [],
      socketMessage: null,
    };
    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this);
  }

  setWorkers = (value) => {
    this.setState({ workers: value });
  };

  setSocketMessage = (value) => {
    this.setState({ socketMessage: value });
  };

  onSetSidebarOpen(open) {
    this.setState({ sidebarOpen: open });
  }
  componentDidMount() {
    this.renderLiveTracking();
  }

  renderLiveTracking = () => {
    const { general,  } = this.props;
    general([], CLEAR_LIVE_TRACKING_STATE);

    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_LIVE_TRACKING_PROPS
    );
  };

  componentWillUnmount() {
    this.props.general([], CLEAR_LIVE_TRACKING_STATE);
  }

  render() {
    const {
      history,
      general,
      trackingObj,
      sorting,
      searchForm,
      isLoadingSearch,
      companyOptions,
      companyWfOptions,
      getCompainesWFForDropDown,
      getAllWorkers,
      connectedWorker,
      loadCompaniesWFOptions,
      loadCompaniesOptions,
      loadWorkerOptions,
      workerOptions,
      sidebarData,
      getRouting,
      loadBranchesOptions,
      branchOptions,
      MapReducer,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <div className='mt-2'>
          <TrackingMenu
            sidebarData={sidebarData}
            general={general}
            loadCompaniesOptions={loadCompaniesOptions}
            companyOptions={companyOptions}
            workerOptions={workerOptions}
            loadWorkerOptions={loadWorkerOptions}
            getRouting={getRouting}
            {...this.props}
          />
        </div>
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <TrackingSearch
                general={general}
                searchForm={searchForm}
                companyOptions={companyOptions}
                getCompainesWFForDropDown={getCompainesWFForDropDown}
                companyWfOptions={companyWfOptions}
                isLoadingSearch={isLoadingSearch}
                getAllWorkers={getAllWorkers}
                connectedWorker={connectedWorker}
                trackingObj={trackingObj}
                history={history}
                loadCompaniesWFOptions={loadCompaniesWFOptions}
                loadCompaniesOptions={loadCompaniesOptions}
                loadWorkerOptions={loadWorkerOptions}
                workerOptions={workerOptions}
                loadBranchesOptions={loadBranchesOptions}
                branchOptions={branchOptions}
                setWorkers={this.setWorkers}
                socketMessage={this.state.socketMessage}
                setSocketMessage={this.setSocketMessage}
                {...this.props}
              />

              <TrackingTable
                general={general}
                trackingObj={trackingObj}
                sorting={sorting}
                socketMessage={this.state.socketMessage}
                setSocketMessage={this.setSocketMessage}
                setWorkers={this.setWorkers}
                {...this.props}
              />

              <Card className='cardStyle mt-4'>
                {/* <TrackingMap
                  history={history}
                  general={general}
                  trackingObj={trackingObj}
                  sorting={sorting}
                  sidebarData={sidebarData}
                  companyWfOptions={companyWfOptions}
                  searchForm={searchForm}
                  mapState={MapReducer}
                  workers={this.state.workers}
                  setWorkers={this.setWorkers}
                  socketMessage={this.state.socketMessage}
                  setSocketMessage={this.setSocketMessage}
                  {...this.props}
                /> */}
                <TrackingMapy
                  history={history}
                  map={map}
                  general={general}
                  trackingObj={trackingObj}
                  sorting={sorting}
                  sidebarData={sidebarData}
                  companyWfOptions={companyWfOptions}
                  searchForm={searchForm}
                  mapState={MapReducer}
                  workers={this.state.workers}
                  setWorkers={this.setWorkers}
                  socketMessage={this.state.socketMessage}
                  setSocketMessage={this.setSocketMessage}
                  {...this.props}
                />
              </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  LiveTrackingR,
  CompanyR,
  CompanyWorkFieldR,
  WorkerR,
  BranchR,
  MapReducer,
}) => {
  return {
    trackingObj: LiveTrackingR.trackingObj,
    mapPop: LiveTrackingR.mapPop,
    searchForm: LiveTrackingR.searchForm,
    sorting: LiveTrackingR.sorting,
    connectedWorker: LiveTrackingR.connectedWorker,
    isLoadingSearch: LiveTrackingR.isLoadingSearch,
    sideBarSearch: LiveTrackingR.sideBarSearch,
    companyOptions: CompanyR.companyOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    workerModale: WorkerR.modaleView,
    sidebarData: LiveTrackingR.sidebarData,
    branchOptions: BranchR.branchOptions,
    workerOptions: WorkerR.workerOptions,
    MapReducer,
  };
};

export default connect(mapStateToProps, actions)(LiveTracking);
