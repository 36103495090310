import { local } from "../../lang/local";
import {
  CLEAR_ARCHIVES_STATE,
  UPDATE_ARCHIVES_PROPS,
  MERGE_ARCHIVES_PROPS,
  DELETE_ARCHIVES_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  archivesObj: { archivesItem: null, isLoading: false },
  sorting: { column: "", order: "desc" },

  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title: local.ReportInfo,
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title: local.ReportContent,
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],
    activeIndex: 0,
    tabAction: "next",
  },
  lightbox: { isOpen: false, image: null, title: null },

  searchForm: {
    company: null,
    branch_id: null,
    ids: null,
    worker_id: null,
    from: "",
    to: "",
    page: 1,
  },
  isLoadingSearch: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_ARCHIVES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_ARCHIVES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_ARCHIVES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_ARCHIVES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
