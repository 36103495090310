import React from "react";
import { connect } from "react-redux";
import ErrandDetails from "./steps/ErrandDetails";
import MerchantDetails from "./steps/MerchantDetails";
import * as actions from "../../../redux/actions/index";
import { UPDATE_REQUESTS_PROPS } from "../../../redux/actions/types";
import { onSave } from "../RequestsFunctions";
import Requester from "./steps/Requester";
import ServiceLocation from "./steps/ServiceLocation";
import "./wizzard.css";
import WizardNav from "./WizardNav";
import { local } from "../../../lang/local";

const Wizard = ({
  page,
  general,
  createORUpdatePoint,
  pointForm,
  requestsObj,
  companyWfOptions,
  clearRequestsForm,
}) => {
  const nav = [
    // { label: "Requester Info.", val: 1 },
    { label: local.MercantDetails, val: 1 },
    { label: local.ErrandDetails, val: 2 },
    { label: local.ServiceLocation, val: 3 },
  ];

  const activePageHandler = (page) => () => {
    general([{ prop: "wizardPage", value: page }], UPDATE_REQUESTS_PROPS);
  };

  return (
    <div
      className="container mt-16 min-h-70 p-1"
      style={{ background: "#fff" }}
    >
      <div className="flex border-b border-gray-400 justify-between lg:w-4/5 mx-auto cursor-default">
        {nav.map((el, index) => (
          <div
            className={`transition cursor-pointer border-b-2 font-bold text-lg  py-4 ${
              page === el.val
                ? "border-main text-main"
                : "border-gray-500 text-[#42526E]"
            }`}
            onClick={activePageHandler(index + 1)}
          >
            {el.label}
          </div>
        ))}
      </div>
      {/* <Requester stepName={"Requester"} /> */}
      {page == 1 && <MerchantDetails stepName={"Merchant"} />}
      {page == 2 && <ErrandDetails stepName={"Errand"} />}
      {page == 3 && <ServiceLocation stepName={"Service"} />}
      <div className="mx-auto w-fit">
        {page == 3 ? (
          <button
            className="bg-main px-4 py-3 mt-8 bg-opacity-25 rounded-md text-white"
            onClick={(e) => {
              onSave(
                e,
                createORUpdatePoint,
                pointForm,
                requestsObj,
                false,
                companyWfOptions
              );
            }}
          >
            {local.Save}
          </button>
        ) : (
          <button
            className="bg-main px-4 py-3 mt-8 bg-opacity-25 rounded-md text-white"
            onClick={activePageHandler(page + 1)}
          >
            {local.Next}
          </button>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = ({ RequestsR, CompanyWorkFieldR }) => ({
  page: RequestsR.wizardPage,
  pointForm: RequestsR.pointForm,
  requestsObj: RequestsR.requestsObj,
  companyWfOptions: CompanyWorkFieldR.companyWfOptions,
});

export default connect(mapStateToProps, actions)(Wizard);
