import React from "react";
import { Col, Row, CardBody } from "reactstrap";
import { local } from "../../lang/local";
import { UPDATE_WORKER_PROPS } from "../../redux/actions/types";
import {
  renderGeneralInput,
  renderPhotoCard,
} from "../../utils/GeneralFunctions";

const FirstTab = (props) => {
  const { general, workerForm } = props;
  return (
    <CardBody className="trans">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderPhotoCard(
            general,
            "workerForm.formvalues.image",
            UPDATE_WORKER_PROPS,
            workerForm.formvalues.image
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "workerForm.formvalues.name",
            workerForm.formvalues.name,
            UPDATE_WORKER_PROPS,
            general,
            local.Name
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "workerForm.formvalues.address",
            workerForm.formvalues.address,
            UPDATE_WORKER_PROPS,
            general,
            local.Address
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "workerForm.formvalues.email",
            workerForm.formvalues.email,
            UPDATE_WORKER_PROPS,
            general,
            local.Email
          )}
        </Col>
      </Row>
    </CardBody>
  );
};
export default FirstTab;
