import { setterGenerator } from "../../utils/GeneralFunctions";

let gisMessage;
function openGisSocket() {
  gisMessage = new WebSocket(
    "wss://places.altawasol.app/gis?key=BpLnfgDsc2WD8F2q"
  );
  gisMessage.addEventListener("open", function (event) {
    console.log("done connect to socket of search");
  });
}

openGisSocket();

export const setMapProps = (propsArr) =>
  setterGenerator("UPDATE_MAP_PROPS", propsArr);

export const clearMapProps = () => (dispatch) => {
  dispatch({
    type: "CLEAR_MAP_PROPS",
  });
};

export const get_search = (query, prop, action) => (dispatch) => {
  let arregex = /[\u0600-\u06FF]/;
  let lang = "en";
  if (arregex.test(query) === true) {
    lang = "ar";
  } else {
    lang = "en";
  }
  let body = {
    uuid: "search",
    query: query,
    limit: 20,
    point: {
      type: "Point",
      coordinates: [31.235712, 30.04442],
    },
    deep: false,
    radius: 1000,
    action: "search",
    lang: lang,
  };
  gisMessage.addEventListener("message", function (event) {
    event.preventDefault();

    let entry = JSON.parse(event.data);
    let places = [];

    if (entry.msg.features !== undefined) {
      if (entry.msg.features !== null) {
        entry.msg.features.map((place) => {
          places.push({
            label: place.properties.label,
            value: place.geometry.coordinates,
            data: place,
          });
        });
      }
    }
    if (places.length > 0) {
      dispatch({
        type: action,
        payload: [
          {
            prop: prop,
            value: places,
          },
        ],
      });
    }
  });

  if (gisMessage.readyState !== undefined) {
    if (gisMessage.readyState === 1) {
      gisMessage.send(JSON.stringify(body));
    }
  }
};
