import React from "react";
import { Col, Row, CardBody } from "reactstrap";
import { local } from "../../lang/local";
import { UPDATE_COMPANY_PROPS } from "../../redux/actions/types";
import {
  renderArabicNameInput,
  renderEnglishNameInput,
  renderGeneralInput,
  renderPhotoCard,
} from "../../utils/GeneralFunctions";

const FirstTab = (props) => {
  const { general, companyForm } = props;
  return (
    <CardBody className="trans">
      <Row>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderPhotoCard(
            general,
            "companyForm.formvalues.logo",
            UPDATE_COMPANY_PROPS,
            companyForm.formvalues.logo
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderEnglishNameInput(
            "companyForm.formvalues.name.en",
            companyForm.formvalues.name.en,
            UPDATE_COMPANY_PROPS,
            general
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderArabicNameInput(
            "companyForm.formvalues.name.ar",
            companyForm.formvalues.name.ar,
            UPDATE_COMPANY_PROPS,
            general
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "companyForm.formvalues.identifier",
            companyForm.formvalues.identifier,
            UPDATE_COMPANY_PROPS,
            general,
            local.Identifier,
            null,
            9
          )}
        </Col>
      </Row>
    </CardBody>
  );
};
export default FirstTab;
