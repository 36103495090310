import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderDate,
  renderPagination,
  getCenterOfPolygon,
  renderEditButton,
  getPermissionValue,
  renderCreateButton,
  renderEditTitle,
  onOpenProfile,
} from '../../utils/GeneralFunctions';
import {
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
} from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import WorkFieldProfile from './WorkFieldProfile';
import { ZoomableShapes } from 'mapy_sdk/lib/mapyTypes';
import { leftlStyle } from '../../utils/GeneralStyle';

const CompainesWorkFieldsTable = (props) => {
  const {
    companyWfObj,
    general,
    sorting,
    searchForm,
    getCompainesWF,
    modaleView,
    getAllPoints,
    mapy,
  } = props;

  const onEdit = async (e, item, index) => {
    e.preventDefault();

    // getAllWorkFields(
    //   item.branch.id,
    //   setCompanyWorkFieldProps,
    //   "allCompanyWorkFields"
    // );

    let reshapedSearchForm = { ...searchForm, ids: [{ value: item.id }] };

    // getRequests(true, reshapedSearchForm);
    await getAllPoints(
      item.id,
      'requestsObj.requestsItem',
      UPDATE_REQUESTS_PROPS
    );

    let center = getCenterOfPolygon(item.location);

    general(
      [
        {
          prop: 'modale.mapItem.mapPoints',
          value: [center.latitude, center.longitude],
        },

        { prop: 'companyWfForm.formvalues', value: item },
        {
          prop: 'companyWfForm.formvalues.branch_id',
          value: item.branch.id,
        },

        {
          prop: 'companyWfForm.formvalues.company_id',
          value: item.branch.company.id,
        },

        { prop: 'companyWfForm.isLoading', value: false },
        { prop: 'modale.isOpen', value: true },
        { prop: 'companyWfForm.index', value: index },
        { prop: 'modale.actionType', value: 'EditCompanyWF' },
        { prop: 'modale.title', value: local.UpdateCompanyWF },
        { prop: 'companyWfForm.iscreate', value: 'update' },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: 'modale.title', value: local.AddACompanyWF },
        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddCompanyWF' },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };
  const onViewOnMap = (e, companyWf) => {
    e.preventDefault();

    let center = getCenterOfPolygon(companyWf.location);
    general(
      [
        {
          prop: 'companyWfObj.lat',
          value: center.latitude,
        },
        {
          prop: 'companyWfObj.lng',
          value: center.longitude,
        },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
    window.scroll({
      top: document.body.offsetHeight,
      left: 0,
      behavior: 'smooth',
    });
    mapy.zoomToFeatures([{ id: companyWf.id, type: ZoomableShapes.polygon }]);
  };
  const renderBody = () => {
    if (companyWfObj.companyWfItem !== null) {
      return companyWfObj.companyWfItem.data.map((companyWf, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(companyWf.id)}</td>
            <td
              className='td_table'
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                onOpenProfile(
                  companyWf,
                  local.WorkPlaces,
                  general,
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  true,
                  'companies/work_fields/show/{id}'
                )
              }
            >
              {companyWf.name[local.lang]}
            </td>
            <td className='td_table'>{companyWf.address}</td>
            <td className='td_table'>{companyWf.branch.name[local.lang]}</td>

            <td className='td_table'>{renderDate(companyWf.created_at)}</td>
            <td className='td_table view-workField'>
              <a
                href
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={(e) => onViewOnMap(e, companyWf)}
              >
                {local.View}
              </a>
            </td>

            {renderEditButton(
              getPermissionValue()['companies/work_fields/update/{id}'],
              onEdit,
              companyWf,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <WorkFieldProfile modaleView={modaleView} general={general} />

      {renderCreateButton(
        getPermissionValue()['companies/work_fields/store'],
        onCreate,
        local.AddACompanyWF
      )}

      <Row>
        <Col xl={12}>
          <Table
            responsive
            hover
            id='company_work_field'
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  companyWfObj.companyWfItem,
                  'id',
                  'companyWfObj.companyWfItem.data',
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Name,
                  companyWfObj.companyWfItem,
                  'name',
                  'companyWfObj.companyWfItem.data',
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Address,
                  companyWfObj.companyWfItem,
                  'address',
                  'companyWfObj.companyWfItem.data',
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Branch,
                  companyWfObj.companyWfItem,
                  'branch.name',
                  'companyWfObj.companyWfItem.data',
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.DateAdded,
                  companyWfObj.companyWfItem,
                  'created_at',
                  'companyWfObj.companyWfItem.data',
                  UPDATE_COMPANY_WORK_FIELD_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(local.LocationOnMap)}

                {renderEditTitle('companies/work_fields/update/{id}')}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {companyWfObj.companyWfItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='company_work_field'
              filename={local.WorkPlaces}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {companyWfObj.companyWfItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}

      {renderPagination(companyWfObj.companyWfItem, searchForm, getCompainesWF)}
    </Card>
  );
};

export default CompainesWorkFieldsTable;
