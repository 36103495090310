import React from "react";
import { Row, Col } from "reactstrap";
import { makeStyles, createStyles } from "@material-ui/core/styles";
import { Pagination } from "@mui/material";

const PaginationEntry = (props) => {
  const { paginationItem, searchForm, endPoint } = props;
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        "& .MuiPaginationItem-page": {
          backgroundColor: "#0091da",
          color: "white",
        },
        "& .Mui-selected": {
          backgroundColor: "white",
          color: "#0091da",
        },
      },
    })
  );

  const classes = useStyles();

  const handleNextPage = (e, page_number) => {
    // e.preventDefault();
    e.isDefaultPrevented();

    if (page_number !== searchForm.page) {
      searchForm.page = page_number;
      console.log("searchForm", searchForm);
      endPoint(searchForm);
    }
  };

  const renderPageNumbers = () => {
    if (paginationItem !== null) {
      let items = [];
      for (let page = 1; page <= paginationItem.total_pages; page++) {
        items.push(page);
      }

      if (items.length === 1) {
        return (
          <li className="page-item" id="li_activeing">
            <a
              className="page-link"
              href="#"
              id="a_paginateActive"
              onClick={(e) => handleNextPage(e, items[0])}
            >
              {items[0]}
            </a>
          </li>
        );
      } else {
        return items.map((item, index) => {
          if (index === 0) {
            return (
              <li
                className="page-item"
                id={
                  searchForm.page === item
                    ? "li_active_left_active"
                    : "li_active_left"
                }
              >
                <a
                  className="page-link"
                  id={
                    searchForm.page === item
                      ? "pag_link_start_active"
                      : "pag_link_start"
                  }
                  href="#"
                  onClick={(e) => handleNextPage(e, item)}
                >
                  {item}
                </a>
              </li>
            );
          }
          if (index + 1 === paginationItem.total_pages) {
            return (
              <li
                className="page-item"
                id={
                  searchForm.page === item
                    ? "li_active_right_active"
                    : "li_active_right"
                }
              >
                <a
                  className="page-link"
                  id={
                    searchForm.page === item
                      ? "pag_link_end_active"
                      : "pag_link_end"
                  }
                  href="#"
                  onClick={(e) => handleNextPage(e, item)}
                >
                  {item}
                </a>
              </li>
            );
          }
          if (index + 1 !== paginationItem.total_pages && index !== 0) {
            return (
              <li
                className="page-item"
                id={item === searchForm.page ? "li_activeing" : "li_active"}
              >
                <a
                  className="page-link"
                  href="#"
                  id={
                    item === searchForm.page ? "a_paginateActive" : "a_paginate"
                  }
                  onClick={(e) => handleNextPage(e, item)}
                >
                  {item}
                </a>
              </li>
            );
          }
        });
      }
    }
  };
  return (
    <Row>
      <Col>
        <div className="mb-3 mt-3">
          <Pagination
            count={paginationItem.total_pages}
            variant="outlined"
            className={classes.root}
            onChange={handleNextPage}
            defaultPage={searchForm.page}
            page={searchForm.page}
            hidePrevButton={false}
            hideNextButton={false}
            showFirstButton={true}
            showLastButton={true}
          />
        </div>
      </Col>
    </Row>
  );
};

export default PaginationEntry;
