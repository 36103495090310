import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import { FiX } from "react-icons/fi";
import { Button } from "reactstrap";
import { getLanguage } from "../lang/local";
const useStyles = makeStyles((theme) => ({
  root: {
    direction: getLanguage() === "en" ? "ltr" : "rtl",
  },
}));
const ModalView = (props) => {
  // isFunctionalComponent to check if children prop is passed as regular function or functional component
  const {
    children,
    modaleView,
    general,
    action,
    isFunctionalComponent = false,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const classes = useStyles();

  const handleClose = (e) => {
    e.preventDefault();

    general([{ prop: "modaleView.isOpen", value: false }], action);
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modaleView.isOpen}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
        fullWidth
        maxWidth="sm"
      >
        <Button className="button_close" onClick={(e) => handleClose(e)}>
          <FiX />
        </Button>

        <DialogContent className="mt-3 mb-5">
          {isFunctionalComponent ? children : children()}
        </DialogContent>
      </Dialog>
    </div>
  );
};
export default ModalView;
