import React from 'react';
import { Card, Col, Row, Button, Spinner, Form } from 'reactstrap';
import { local } from '../../lang/local';
import {
  getEmployeeOptions,
  getValueOrNull,
  handleSelectChange,
  handleSelectChangeEnhanced,
  renderArraw,
} from '../../utils/GeneralFunctions';
import toaster from 'toasted-notes';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { leftlStyle } from '../../utils/GeneralStyle';
import { UPDATE_SEARCH_FORM_PROPS } from '../../redux/actions/types';
import { renderBranchSelect } from '../Branches/BranchesFunction';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
const CoverageListSearch = (props) => {
  const {
    searchForm,
    general,
    companyOptions,
    history,
    loadCompaniesOptions,
    loadBranchesOptions,
    branchOptions,
    companyWfOptions,
    getCompanyWorkFieldOptions,
    getPointsOptions,
    pointsOptions,
    setSearchFormProps,
    getCoverageList,
  } = props;

  const { filters } = searchForm;

  const onSearch = (e) => {
    console.log('searchForm', searchForm);
    e.preventDefault();
    filters.page = 1;

    if (filters.companyID === null) {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    } else if (filters.branchID && !filters.workFieldID) {
      toaster.notify(local.ChooseCompanyWorkField, {
        duration: 2000,
      });
    } else {
      getCoverageList(filters);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
    <Form onSubmit={onSearch} className="mt-4 mb-4">
      <Row>
        <Col>
          <p className='page_title' style={leftlStyle}>
            {local.CoverageList}
          </p>
        </Col>
        {renderArraw(history)}
      </Row>
      <Row>
      <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderCompanySelect(
            'filters.companyID',
            filters.companyID,
            UPDATE_SEARCH_FORM_PROPS,
            general,
            local.Company,
            loadCompaniesOptions,
            companyOptions,
            null,
            [
              { p: 'filters.branchID', v: null },
              { p: 'filters.workFieldID', v: null },
            ],
            null
          )}
        </Col>
      <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderBranchSelect(
            'filters.branchID',
            filters.branchID,
            UPDATE_SEARCH_FORM_PROPS,
            general,
            local.Branch,
            loadBranchesOptions,
            branchOptions,
            filters.companyID,
            null,
            [{ p: 'filters.workFieldID', v: null }],
            null
          )}
        </Col>

        <CustomAsyncPaginate
          loadOptions={getCompanyWorkFieldOptions}
          value={getValueOrNull(filters.workFieldID, companyWfOptions)}
          placeholder={local.WorkField}
          cacheUniqsArr={[filters.branchID]}
          isMulti={false}
          onChange={(e) =>
            handleSelectChange(
              e,
              'filters.workFieldID',
              setSearchFormProps,
              ['filters.pointID'],
              false
            )
          }
          additional={{
            page: 1,
            branchId: filters.branchID,
          }}
        />

        <CustomAsyncPaginate
          loadOptions={getPointsOptions}
          value={getValueOrNull(filters.pointID, pointsOptions)}
          placeholder={local.WorkFieldPoint}
          isMulti={false}
          onChange={(e) =>
            handleSelectChange(
              e,
              'filters.pointID',
              setSearchFormProps,
              [],
              false
            )
          }
          cacheUniqsArr={[filters.workFieldID]}
          additional={{
            page: 1,
            companyId: filters.companyID,
            workFields: [filters.workFieldID],
          }}
        />

        <CustomAsyncPaginate
          loadOptions={getEmployeeOptions(filters.companyID)}
          value={filters.worker}
          placeholder={local.Worker}
          onChange={(e) =>
            handleSelectChangeEnhanced(
              e,
              'filters.worker',
              setSearchFormProps,
              []
            )
          }
          cacheUniqsArr={[filters.companyID]}
          additional={{
            page: 1,
            companyId: filters.companyID,
          }}
        />

        {/* <div className="react-select-div mt-4">
          {renderWorkerSeelct(
            "filters.workerID",
            filters.workerID,
            UPDATE_SEARCH_FORM_PROPS,
            general,
            loadWorkerOptions,
            workerOptions,
            filters.companyID,
            null
          )}
        </div> */}

        {/* <DateInput
          prop="filters.from"
          value={filters.from}
          action={UPDATE_SEARCH_FORM_PROPS}
          title={local.FromDate}
          maximumDate={filters.to ? filters.to : new Date()}
        />
        <DateInput
          prop="filters.to"
          value={filters.to}
          action={UPDATE_SEARCH_FORM_PROPS}
          title={local.ToDate}
          minimumDate={filters.from ? filters.from : null}
          maximumDate={new Date()}
        /> */}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          <Button
            style={leftlStyle}
            type='submit'
            disabled={searchForm.isLoading}
            className='btn_search_style'
            onClick={(e) => onSearch(e)}
          >
            {searchForm.isLoading === true ? (
              <Spinner type='grow' color='dark' size='sm' />
            ) : (
              local.Search
            )}
          </Button>
        </Col>
      </Row>
      </Form>
    </Card>
  );
};

export default CoverageListSearch;
