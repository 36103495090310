import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mapy } from 'mapy_sdk/lib';
import { Col, Row } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import {  getMyCompany } from '../../utils/GeneralFunctions';
import ActionPointSearch from './ActionPointSearch';
import {
  CLEAR_ACTION_POINT_STATE,
  UPDATE_ACTION_POINT_PROPS,
} from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
import AactionPointTable from './AactionPointTable';
import ActionPointMapy from './ActionPointMapy';

let map = new Mapy();

class ActionPoints extends Component {
  componentDidMount() {
    this.renderActionPoints();
  }

  componentWillUnmount() {
    this.props.clearAllGiftsAndSamples();
  }

  onMarkerClick = null;

  markerClickSetter = (onMarkerClickFn) => {
    this.onMarkerClick = onMarkerClickFn;
  };

  renderActionPoints = () => {
    const { general,  } = this.props;
    general([], CLEAR_ACTION_POINT_STATE);

    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_ACTION_POINT_PROPS
    );
  };

  render() {
    const {
      history,
      general,
      actionPointObj,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getActionPoints,
      workerOptions,
      sorting,
      jobTitleOptions,
      visitStatusOptions,
      pointsOptions,
      loadCompaniesWFOptions,
      loadWorkerOptions,
      loadCompaniesOptions,
      loadpointsOptions,
      modaleView,
      branchOptions,
      loadBranchesOptions,
      // setActionPointsProps,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_ACTION_POINT_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ActionPointSearch
              map={map}
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getActionPoints={getActionPoints}
              general={general}
              workerOptions={workerOptions}
              jobTitleOptions={jobTitleOptions}
              visitStatusOptions={visitStatusOptions}
              pointsOptions={pointsOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadWorkerOptions={loadWorkerOptions}
              loadCompaniesOptions={loadCompaniesOptions}
              loadpointsOptions={loadpointsOptions}
              branchOptions={branchOptions}
              loadBranchesOptions={loadBranchesOptions}
              {...this.props}
            />
            <AactionPointTable
              {...this.props}
              general={general}
              actionPointObj={actionPointObj}
              sorting={sorting}
              getActionPoints={getActionPoints}
              searchForm={searchForm}
              companyWfOptions={companyWfOptions}
              modaleView={modaleView}
              map={map}
              onMarkerClick={this.onMarkerClick}
            />
            <br /> <br />
            {/* <ActionPointMap
                  history={history}
                  actionPointObj={actionPointObj}
                  searchForm={searchForm}
                  companyWfOptions={companyWfOptions}
                  searchForm={searchForm}
                  setActionPointsProps={setActionPointsProps}
                /> */}
            <ActionPointMapy
              {...this.props}
              map={map}
              markerClickSetter={this.markerClickSetter}
            />
            {/* <ActionPointView
                  general={general}
                  actionPointObj={actionPointObj}
                  lightbox={lightbox}
                  getActionPoints={getActionPoints}
                  searchForm={searchForm}
                  companyWfOptions={companyWfOptions}
                /> */}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  ActionPointR,
  CompanyWorkFieldR,
  CompanyR,
  WorkerR,
  RequestsR,
  BranchR,
  GiftsAndSamplesReducer,
  AmountR,
  MapReducer,
}) => {
  return {
    actionPointObj: ActionPointR.actionPointObj,
    visitStatusOptions: ActionPointR.visitStatusOptions,
    jobTitleOptions: RequestsR.jobTitleOptions,
    sorting: ActionPointR.sorting,
    modale: ActionPointR.modale,
    lightbox: ActionPointR.lightbox,
    searchForm: ActionPointR.searchForm,
    isLoadingSearch: ActionPointR.isLoadingSearch,
    modaleView: ActionPointR.modaleView,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    workerOptions: WorkerR.workerOptions,
    pointsOptions: RequestsR.pointsOptions,
    branchOptions: BranchR.branchOptions,
    giftsReportData: GiftsAndSamplesReducer.giftsReportData,
    MapReducer,
    AmountR,
  };
};

export default connect(mapStateToProps, actions)(ActionPoints);
