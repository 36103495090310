import "./NewHome.css";
import { Col, Row } from "reactstrap";
import { CustomCard } from "../../components/CustomCard/CustomCard";
import Logo from "../../assets/img/blue-logo.png";

import Revenue from "./Revenue";
import { EranedType } from "./EranedType";
import { Referrals } from "./Referrals";
import { useEffect } from "react";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import { Statistics } from "./Statistics";

const NewHome = ({ fetchStatistics, data, isLoading }) => {
  const {
    regions,
    cities,
    workers,
    requests,
    merchants,
    appfeedback,
    requestschart,
    departments,
    errandtypes,
  } = data;
  const colors = [
    "#f77601",
    "#eb545a",
    "#4c5165",
    "#0182fe",
    "#44d0ef",
    "#043887",
    "#0c0d10",
    "#2cb688",
    "#afaaaf",
    "#6ccbe3",
    "#57ba76",
    "#e2be90",
    "#c9807e",
    "#a5acaf",
    "#964B00",
    "#FFFF00",
  ];
  useEffect(() => {
    fetchStatistics();
  }, []);
  return (
    <div className="page-wrapper">
      <div className="container">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto">
            <CustomCard className="logo-card mt-2">
              <div className="main-logo">
                <img src={Logo} alt="" className="imgFull" />
              </div>
            </CustomCard>
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto">
            <Statistics
              regions={regions}
              cities={cities}
              workers={workers}
              requests={requests}
              merchants={merchants}
              appfeedback={appfeedback || []}
            />
          </Col>
        </Row>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto mt-5">
            <CustomCard className="revenue-card">
              <Revenue requestschart={requestschart ? requestschart : []} />
            </CustomCard>
          </Col>
        </Row>

        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mx-auto">
            <Row>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
                <CustomCard className="referrals-card">
                  <Referrals
                    colors={colors}
                    departments={departments ? departments : []}
                  />
                </CustomCard>
              </Col>
              <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
                <CustomCard className="referrals-card">
                  <EranedType
                    errandtypes={errandtypes ? errandtypes : []}
                    colors={colors}
                  />
                </CustomCard>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
};

const mapStateToProps = ({ HomeR }) => {
  return {
    data: HomeR.homeObj.data,
    isLoading: HomeR.homeObj.isLoading,
  };
};

export default connect(mapStateToProps, actions)(NewHome);
