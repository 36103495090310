import React, { Component } from "react";
import { Row, Card } from "reactstrap";
import { checkConnection } from "../../utils/GeneralFunctions";
import "./home.css";
import "./homePage.scss";
import { local } from "../../lang/local";
import * as actions from "../../redux/actions/index";
import { connect } from "react-redux";
import WORK_FILEDS from "../../assets/img/home-new-icons/areas.png";
import CALENDAR from "../../assets/img/home-new-icons/Calender 1.png";
import COVERAGE_LIST from "../../assets/img/home-new-icons/coverage-list.png";
import BUSINESS_REPORT from "../../assets/img/home-new-icons/Document Justify  Right 2.png";
import USERS from "../../assets/img/home-new-icons/Group 3.png";
import BRANCHES from "../../assets/img/home-new-icons/Home 8.png";
import LOCATION from "../../assets/img/home-new-icons/Location.png";
import HomeCard2 from "./HomeCard2";

class Home extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    checkConnection(this.props.history);
    this.props.fetchStatistics();
  }

  componentWillUnmount() {
    this.props.clearHomeState();
  }

  render() {
    const { history, homeObj } = this.props;
    return (
      <div className="page-wrapper">
        <div className="w-100 px-2 mt-3" style={{ height: "100%" }}>
          <Card className="home_card_main">
            <div className="div_home">
              <Row className="justify-content-center">
                <HomeCard2
                  title={local.Branches}
                  count="-"
                  photo={BRANCHES}
                  route_name="/branches"
                  history={history}
                />

                <HomeCard2
                  title={local.CompaniesWorkField}
                  count={homeObj.data !== null ? homeObj.data.work_fields : 0}
                  photo={WORK_FILEDS}
                  route_name="/company_work_fields"
                  history={history}
                />

                <HomeCard2
                  title={local.CompaniesWorkFieldPoint}
                  count="-"
                  photo={LOCATION}
                  route_name="/place-visit"
                  history={history}
                />

                <HomeCard2
                  title={local.Employees}
                  count={homeObj.data !== null ? homeObj.data.workers : 0}
                  photo={USERS}
                  route_name="/workers"
                  history={history}
                />

                <HomeCard2
                  title={local.VisitsPlan}
                  count="-"
                  photo={CALENDAR}
                  route_name="/visits_plan"
                  history={history}
                />

                <HomeCard2
                  title={local.ActionPoints}
                  count="-"
                  photo={BUSINESS_REPORT}
                  route_name="/action_points"
                  history={history}
                />

                {/* <HomeCard2
                  title={local.Products}
                  count='-'
                  photo={CART}
                  route_name='/products'
                  history={history}
                /> */}

                {/* <HomeCard2
                  title={local.Gifts_And_Samples}
                  count='-'
                  photo={GIFT}
                  route_name='/gifts-and-samples'
                  history={history}
                /> */}

                <HomeCard2
                  title={local.CoverageList}
                  count="-"
                  photo={COVERAGE_LIST}
                  route_name="/coverage-list"
                  history={history}
                />
              </Row>
            </div>
          </Card>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ HomeR }) => {
  return {
    homeObj: HomeR.homeObj,
  };
};

export default connect(mapStateToProps, actions)(Home);
