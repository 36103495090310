import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import { local } from '../../lang/local';
import GiftsSearch from './GiftsSearch';
import GiftsAndSamplesTable from './GiftsAndSamplesTable';
import GiftsAndSamplesReport from './GiftsAndSamplesReport';
import PaginationComponent from '../../utils/Pagination';
import EditGiftsModal from './EditGiftsModal';
import './styles/GiftsAndSamples/gifts-and-samples.css';
import AddGiftsModal from './AddGiftsModal';
import {
  getPermissionValue,
  renderCreateButton,
  getMyCompany,
} from '../../utils/GeneralFunctions';
const GiftsAndSamples = (props) => {
  const {
    clearAllGiftsAndSamples,
    openAddGiftsModal,
    giftsTableData,
    giftsReportData,
    getGiftsTableData,
    getGiftsReportData,
    searchForm,
    general,
  } = props;

  useEffect(() => {
    window.scrollTo(0, 0);

    searchForm.company = getMyCompany(general);
    getGiftsTableData(searchForm, 1);
    getGiftsReportData(searchForm, 1);

    return clearAllGiftsAndSamples;
  });

  return (
    <div className='page-wrapper'>
      <Row className='mt-2'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <GiftsSearch {...props} />

          <div id='pharmacies-container' className='mt-2 bg-white p-3'>
            <div>
              {renderCreateButton(
                getPermissionValue()['companies/gifts/store'],
                openAddGiftsModal,
                local.AddGiftOrSample
              )}
            </div>

            <GiftsAndSamplesTable
              {...props}
              tableHeaders={[
                local.GiftOrSample,
                local.Name,
                local.Details,
                local.Company,
                local.CreatedAt,
                local.Edit,
              ]}
            />

            {giftsTableData?.data?.length > 0 && (
              <PaginationComponent
                onPageChange={(pageNo) => getGiftsTableData(searchForm, pageNo)}
                count={giftsTableData?.pagination.total_pages || 1}
                currentPage={giftsTableData.pagination.current_page}
              />
            )}

            <GiftsAndSamplesReport
              {...props}
              tableHeaders={[
                local.GiftOrSample,
                local.Name,
                local.Details,
                local.Company,
                local.WorkField,
                local.WorkFieldPoint,
                local.Quantity,
              ]}
            />

            <EditGiftsModal {...props} />

            <AddGiftsModal {...props} />

            {giftsReportData?.data?.length > 0 && (
              <PaginationComponent
                onPageChange={(pageNo) =>
                  getGiftsReportData(searchForm, pageNo)
                }
                count={giftsReportData?.pagination.total_pages || 1}
                currentPage={giftsReportData.pagination.current_page}
              />
            )}
          </div>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({ VisitR, GiftsAndSamplesReducer }) => {
  return {
    visitObj: VisitR.visitObj,
    modale: VisitR.modale,
    visitForm: VisitR.visitForm,
    sorting: VisitR.sorting,
    isLoadingSearch: VisitR.isLoadingSearch,
    searchForm: GiftsAndSamplesReducer.searchForm,
    giftsTableData: GiftsAndSamplesReducer.giftsTableData,
    giftsReportData: GiftsAndSamplesReducer.giftsReportData,
    editGiftsModal: GiftsAndSamplesReducer.editGiftsModal,
    addGiftsModal: GiftsAndSamplesReducer.addGiftsModal,
    lastCompanySearchedFor: GiftsAndSamplesReducer.lastCompanySearchedFor,
    giftsTableSorting: GiftsAndSamplesReducer.giftsTableSorting,
    giftsReportSorting: GiftsAndSamplesReducer.giftsReportSorting,
    companiesOptionsObject: GiftsAndSamplesReducer.companiesOptionsObject,
    branchesOptions: GiftsAndSamplesReducer.branchesOptions,
    areasOptions: GiftsAndSamplesReducer.areasOptions,
    visitsSpotsOptions: GiftsAndSamplesReducer.visitsSpotsOptions,
  };
};

export default connect(mapStateToProps, actions)(GiftsAndSamples);
