import {
  confirmSunccessed,
  getQueryString,
  removeUnsetValues,
} from "../../utils/GeneralFunctions";
import { Request } from "../Api/index";
import { UPDATE_WORK_REPORT_PROPS } from "./types";
import { local } from "../../lang/local";
export const getHistories = (isSearching, searchForm) => (dispatch) => {
  let url = "/histories/";

  dispatch({
    type: UPDATE_WORK_REPORT_PROPS,
    payload: [{ prop: "historyObj.historyItem", value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_WORK_REPORT_PROPS,
      payload: [{ prop: "historyObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_WORK_REPORT_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  if (searchForm !== null) {
    var values = "";
    searchForm.ids.map((d, i) => {
      if (i === 0) {
        values = "ids[]=" + d.value;
      } else {
        values += "&ids[]=" + d.value;
      }
    });
    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      worker_id: searchForm.worker_id,
      page: searchForm.page,
    };
    let newSearchForm = removeUnsetValues(searchItems);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param + "&" + values;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_WORK_REPORT_PROPS,
            payload: [{ prop: "historyObj.historyItem", value: res.data.data }],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_WORK_REPORT_PROPS,
        payload: [
          { prop: "historyObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_WORK_REPORT_PROPS,
        payload: [
          { prop: "historyObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};
