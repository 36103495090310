import * as general from "./methods";

const INITIAL_STATE = {
  mapInstance: null,
  googleObject: null,
  zoom: 4,
  markerFilter: null,
  markers: [],
  markerFilter: null,
  renderMapMarkers: null,
  markersGuidIsShown: true,
  infoWindowIsShown: false,
  selectedPlace: {},
  activeMarker: {},
  mapCenter: { lat: 30.1117, lng: 31.3425 },
  mapBounds: [],
};

const MapReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "UPDATE_MAP_PROPS": {
      return general.updateProps(state, action);
    }

    case "CLEAR_MAP_PROPS": {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default MapReducer;
