import { Request, Select_Request } from "../Api/index";
import {
  CLEAR_COMPANY_PROPS,
  DELETE_COMPANY_PROPS,
  MERGE_COMPANY_PROPS,
  UPDATE_COMPANY_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  renderFormData,
  confirmSunccessed,
  setterGenerator,
  rmUnsetValsAndGetQryStr,
  checkHasOneCompany,
} from "../../utils/GeneralFunctions";
import { SERVICE_USER } from "../../utils/StaticKeys";

export const setCompanyProps = (propsArr) =>
  setterGenerator(UPDATE_COMPANY_PROPS, propsArr);

export const clearCompanyState = () => ({
  type: CLEAR_COMPANY_PROPS,
});

export const getCompaines = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_COMPANY_PROPS,
    payload: [{ prop: "companyObj.companyItem", value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_COMPANY_PROPS,
      payload: [{ prop: "companyObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_COMPANY_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  let url = "/companies";
  if (searchForm !== null) {
    let searchValues = Object.assign({}, searchForm);
    let newSearchForm = removeUnsetValues(searchValues);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }
  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_COMPANY_PROPS,
            payload: [{ prop: "companyObj.companyItem", value: res.data.data }],
          });
        }

        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_COMPANY_PROPS,
        payload: [
          { prop: "companyObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });

      if (checkHasOneCompany()) {
        const user = JSON.parse(localStorage.getItem(SERVICE_USER));
        user.companies = res.data.data.data;
        localStorage.setItem(SERVICE_USER, JSON.stringify(user));
      }
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_COMPANY_PROPS,
        payload: [
          { prop: "companyObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getCompainesForDropDown = (name) => (dispatch) => {
  Request()
    .get("/companies")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: getLanguage() === "en" ? r.name.en : r.name.ar,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_COMPANY_PROPS,
            payload: [{ prop: "companyOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateCompany =
  (formValues, index, isModal, stateValue, stateWFValue) => (dispatch) => {
    dispatch({
      type: UPDATE_COMPANY_PROPS,
      payload: [{ prop: "companyForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues.id === undefined) {
      url = "/companies/store";
    } else {
      url = "/companies/update/" + formValues.id;
    }

    let newFormValues = Object.assign({}, formValues);
    newFormValues = removeUnsetValues(newFormValues);
    if (stateValue !== null && typeof stateValue === "object") {
      newFormValues.states = stateValue.map((state) => state.value);
    }

    let newFormData = null;

    if (stateWFValue !== null && typeof stateWFValue === "object") {
      newFormValues.work_fields = stateWFValue.map((wf) => wf.value);
    }

    if (newFormValues.logo !== undefined) {
      if (newFormValues.logo !== null) {
        if (newFormValues.parent_id === null) {
          newFormValues.parent_id = "";
        }
        if (newFormValues.logo[0] instanceof File) {
          newFormData = renderFormData(newFormValues);
        } else {
          delete newFormValues.logo;
          newFormData = newFormValues;
        }
      } else {
        delete newFormValues.logo;
        newFormData = newFormValues;
      }
    } else {
      newFormData = newFormValues;
    }
    Request()
      .post(url, newFormData)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);

            if (isModal === true) {
              // dispatch({
              //   type: MERGE_COMPANY_PROPS,
              //   payload: [
              //     { prop: "companyObj.companyItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);
            if (isModal === true) {
              dispatch({
                type: UPDATE_COMPANY_PROPS,
                payload: [
                  {
                    prop: "companyObj.companyItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }

          dispatch({
            type: UPDATE_COMPANY_PROPS,
            payload: [
              {
                prop: "companyForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    states: null,
                    work_fields: null,
                    address: "",
                    mobile: "",
                    identifier: "",
                    name: { ar: "", en: "" },
                    logo: null,
                    active: false,
                    parent_id: null,
                  },
                  index: null,
                },
              },
              { prop: "companyOptions", value: [] },
              { prop: "lightbox", value: { isOpen: false, image: null } },
              { prop: "stateValue", value: [] },
              { prop: "stateWFValue", value: [] },
              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },

                    {
                      title:
                        getLanguage() === "en"
                          ? "Work Places"
                          : "الاشخاص المسئولون",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],
                  activeIndex: 0,
                  tabAction: "next",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }
        dispatch({
          type: UPDATE_COMPANY_PROPS,
          payload: [{ prop: "companyForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_COMPANY_PROPS,
          payload: [{ prop: "companyForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getCompany = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("/companies/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_COMPANY_PROPS,
              payload: [
                {
                  prop: "companyForm.formvalues",
                  value: res.data.data,
                },
                {
                  prop: "companyForm.formvalues.states",
                  value: res.data.data.states.map((state) => state.id),
                },
                {
                  prop: "companyForm.formvalues.work_fields",
                  value: res.data.data.state_work_fields.map((wf) => wf.id),
                },
              ],
            });
          } else {
            let result = [
              {
                label:
                  getLanguage() === "en"
                    ? res.data.data.name.en
                    : res.data.data.name.ar,
                value: res.data.data.id,
              },
            ];

            dispatch({
              type: UPDATE_COMPANY_PROPS,
              payload: [{ prop: "companyOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const updateStatus = (id, active, index) => (dispatch) => {
  Request()
    .post("companies/changeStatus/" + id, { active: active })
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_COMPANY_PROPS,
            payload: [
              {
                prop: "companyObj.companyItem.data." + index + ".active",
                value: active,
              },
            ],
          });

          confirmSunccessed(local.StatusScussfully);
        }
      }
    });
};

export const saveCompanyDataFromFile =
  (items, headers, logo, stateWF, state) => (dispatch) => {
    let data = items;
    let englishIndex = headers.indexOf("EnglishName");
    let arabicIndex = headers.indexOf("ArabicName");
    let addressIndex = headers.indexOf("Address");
    let mobileIndex = headers.indexOf("Mobile");
    let taxIndex = headers.indexOf("Tax");
    let logoIndex = headers.indexOf(logo);
    let statendex = headers.indexOf(state);
    let stateWFIndex = headers.indexOf(stateWF);

    dispatch({
      type: UPDATE_COMPANY_PROPS,
      payload: [{ prop: "fileOptions.isLoading", value: true }],
    });
    let errors = [];

    data.map((row, index) => {
      let states = row[statendex];
      if (states !== null) {
        states = states.map((st) => st.value);
      }

      let statesWF = row[stateWFIndex];
      if (statesWF !== null) {
        statesWF = statesWF.map((stw) => stw.value);
      }

      let image = row[logoIndex];
      let formData = null;
      let formValues = {
        states: states,
        work_fields: statesWF,
        address: row[addressIndex],
        mobile: row[mobileIndex],
        identifier: row[taxIndex],
        name: { en: row[englishIndex], ar: row[arabicIndex] },
        logo: null,
      };

      if (image === null || image === false) {
        formData = formValues;
      } else {
        formValues.logo = image;
        formData = renderFormData(formValues);
      }
      Request()
        .post("/companies/store", formData)
        .then((res) => {
          if (res.data.status === true) {
            dispatch({
              type: UPDATE_COMPANY_PROPS,
              payload: [
                {
                  prop: "fileOptions.importedData." + index + "." + row.length,
                  value: true,
                },
              ],
            });

            dispatch({
              type: DELETE_COMPANY_PROPS,
              payload: [{ prop: "fileOptions.savedData." + index }],
            });

            if (index === data.length - 1) {
              confirmSunccessed(local.SuccessfullyAdded);
              dispatch({
                type: UPDATE_COMPANY_PROPS,
                payload: [
                  { prop: "fileOptions.savedData", value: null },
                  { prop: "fileOptions.isLoading", value: false },
                ],
              });
            }
          } else {
            Object.values(res.data.error).map((msg) => {
              dispatch({
                type: MERGE_COMPANY_PROPS,
                payload: [
                  {
                    prop: "fileOptions.errorData",
                    value: [msg[0] + local.Name + " : " + formValues.name],
                  },
                ],
              });
            });

            dispatch({
              type: UPDATE_COMPANY_PROPS,
              payload: [{ prop: "fileOptions.isLoading", value: false }],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_COMPANY_PROPS,
            payload: [{ prop: "fileOptions.isLoading", value: false }],
          });
          console.log(err);
        })
        .finally();
    });
  };

export const loadCompaniesOptions =
  (name, loadedOptions, page) => async (dispatch) => {
    let url = null;

    const searchForm = {
      name: name,
      page: page.page,
    };

    const searchParams = rmUnsetValsAndGetQryStr(searchForm);

    console.log("searchParams", searchParams);

    url = Select_Request.url + `/companies${searchParams}`;

    let response = await fetch(url, Select_Request.options);
    let responseOK = response && response.ok;
    if (responseOK) {
      let result = await response.json();

      let options = [];

      if (result.status === true) {
        if (result.data.data !== null) {
          result.data.data.map((d) => {
            options.push({
              label: getLanguage() === "en" ? d.name.en : d.name.ar,
              value: d.id,
              active: d.active,
            });
          });
        }
      }

      // dispatch({
      //   type: UPDATE_COMPANY_PROPS,
      //   payload: [{ prop: "companyOptions", value: options }],
      // });
      let entry = {
        result: result.data.data,
        has_more:
          result.data.pagination.current_page ===
          result.data.pagination.total_pages
            ? false
            : true,
      };

      dispatch({
        type: UPDATE_COMPANY_PROPS,
        payload: [
          { prop: "companyOptions", value: [...loadedOptions, ...options] },
        ],
      });

      return {
        options: options,
        hasMore: entry.has_more,
        additional: {
          page: page.page + 1,
        },
      };
    }
  };
