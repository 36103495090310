import React, { Component } from "react";
import "./login.css";
import { Row, Col } from "reactstrap";
// import login_logo from '../../assets/img/login_logo.png';
import logo from "../../assets/img/footer.png";
import map_login from "../../assets/img/map_login.png";
import paymobWhite from "../../assets/img/white-logo.png";
import Header from "../../routing/Layout/Header";
import LoginForm from "./LoginForm";
import * as actions from "../../redux/actions/index";
import { connect } from "react-redux";
import { UPDATE_LOGIN_PROPS } from "../../redux/actions/types";
import { getUser } from "../../utils/GeneralFunctions";
class Login extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkNotAuthorized();
  }

  checkNotAuthorized = () => {
    const { history } = this.props;
    if (getUser() !== null) {
      history.push("/dashboard/Home");
    }
  };
  componentWillUnmount() {
    this.props.general(
      [
        {
          prop: "loginObj",
          value: {
            isViewPassword: false,
            isLoading: false,
            formValue: { email: "", password: "" },
            errorMessage: { email: "", password: "" },
          },
        },
      ],
      UPDATE_LOGIN_PROPS
    );
  }
  render() {
    const { loginObj, history, login, general } = this.props;
    return (
      <div className="login_container">
        <Header loginPage={true} />
        <div className="login_parent ">
          {/* <Row>
            <Col
              xl={12}
              lg={12}
              md={12}
              sm={12}
              xs={12}
              className='text-center'
            >
              <img src={login_logo} width='220' height='200' alt='img'  className='mx-auto'/>
            </Col>
          </Row> */}
          <img src={paymobWhite} style={{ maxWidth: "700px" }} />
          <div>
            <img />
          </div>
          <LoginForm
            loginObj={loginObj}
            login={login}
            history={history}
            general={general}
          />
        </div>
        <footer className="flex flex-row-reverse">
          <div className="w-80" style={{ height: "10vh" }}>
            <img src={logo} alt="logo" className="w-full opacity-30" />
          </div>
        </footer>
        {/* <img
          src={map_login}
          alt='img'
          className='img_bottom mt-5'
          style={{ left: '0', bottom: 50, position: 'relative' }}
        /> */}
      </div>
    );
  }
}
const mapStateToProps = ({ LoginR }) => {
  return {
    loginObj: LoginR.loginObj,
  };
};
export default connect(mapStateToProps, actions)(Login);
