import React, { Component } from 'react';
import { Card, Col, Row, CardBody, Spinner, Form } from 'reactstrap';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { UPDATE_STATE_PROPS } from '../../redux/actions/types';
import {
  checkConnection,
  renderArabicNameInput,
  renderEnglishNameInput,
} from '../../utils/GeneralFunctions';
import { onSave } from './StateFunction';

class CreateUpdateState extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { getStatesForDropDown, stateForm, getState, modale, history } =
      this.props;
    checkConnection(history);

    if (modale.actionType === 'AddProvince') {
      getStatesForDropDown();
    } else {
      if (stateForm.formvalues.parent_id !== null) {
        getState(stateForm.formvalues.parent_id, true);
      }
    }
  };
  render() {
    const { stateForm, general, modale, createORUpdateState } = this.props;
    return (
      <Form onSubmit={(e) => onSave(e, createORUpdateState, stateForm, true)}>
        <div className='conatiner'>
          <Card className='cardStyle'>
            <div className='ml-3 mr-3'>
              <p className='page_title text-center'>{modale.title}</p>
              <CardBody className='trans'>
                <Row>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderEnglishNameInput(
                      'stateForm.formvalues.name.en',
                      stateForm.formvalues.name.en,
                      UPDATE_STATE_PROPS,
                      general
                    )}
                  </Col>
                </Row>
                <Row className='mt-5'>
                  <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                    {renderArabicNameInput(
                      'stateForm.formvalues.name.ar',
                      stateForm.formvalues.name.ar,
                      UPDATE_STATE_PROPS,
                      general
                    )}
                  </Col>
                </Row>
              </CardBody>
              <center>
                <button
                  disabled={stateForm.isLoading}
                  className='save_btn mt-5'
                  onClick={(e) =>
                    onSave(e, createORUpdateState, stateForm, true)
                  }
                >
                  {stateForm.isLoading === true ? (
                    <Spinner type='grow' color='dark' size='sm' />
                  ) : (
                    <>
                      <p style={{ display: 'inline' }}>{local.Save} </p>
                      <img src={icon_btn} width='20' height='20' alt='img' />
                    </>
                  )}
                </button>
              </center>
              <br /> <br />
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ StateR }) => {
  return {
    stateForm: StateR.stateForm,
    modale: StateR.modale,
    stateOptions: StateR.stateOptions,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateState);
