import { Card, Row, Col } from "reactstrap";
import { getLanguage } from "../../lang/local";
import {
  renderAppropriatePin,
  renderAppropriatePlaceType,
} from "../../utils/MapPinImg";
import MarkersGuide from "../../components/MarkersGuide/MarkersGuide";
import CustomInfoWindow from "../../components/InfoWindow/InfoWindow";
import PointDetailsWindow from "./PointDetailsWindow";
import { useState } from "react";
import GenericMapy from "../../components/GenericMapy/GenericMapy";
import { useEffect } from "react";

const ActionPointMapy = (props) => {
  const [point, setPoint] = useState(null);
  const [pointDetailsWindowIsDisplayed, setPointDetailsWindowIsDisplayed] =
    useState(false);

  const {
    searchForm,
    actionPointObj,
    MapReducer,
    map,
    markerClickSetter,
  } = props;
  const { markerFilter } = MapReducer;

  const onMarkerClick = (pointID, point) => {
    setPoint(point);
    setPointDetailsWindowIsDisplayed(true);
    if (map.markers[pointID]) {
      map.markers[pointID].shape.openPopup();
    }
  };

  useEffect(() => {
    markerClickSetter(onMarkerClick);
  }, []);

  const renderPointsMarkers = () => {
    if (actionPointObj.actionPointObjItem !== null) {
      let filteredPointsArr = [];
      actionPointObj.actionPointObjItem.data.map((item) => {
        if (filteredPointsArr.every((i) => i.point.id !== item.point.id)) {
          filteredPointsArr.push(item);
        }
      });
      const pointsFilteredByType = [];

      const generatePointMarker = (point) => {
        const { location, name, place_type } = point;

        const markerPopup = CustomInfoWindow({
          placeName: name,
          placeType: renderAppropriatePlaceType(place_type),
        });

        const pointMarker = {
          ...point,
          id: point.id,
          latLng: location,
          markerIcon: {
            url: renderAppropriatePin(place_type),
            imgSize: [30, 43],
          },
          popup: markerPopup,
          onClick: onMarkerClick,
        };
        return pointMarker;
      };

      // to avoid checking for filter in every iteration
      if (!markerFilter) {
        for (let i = 0; i < filteredPointsArr.length; i++) {
          const currentItem = filteredPointsArr[i];
          const { point } = currentItem;

          const pointMarker = generatePointMarker(point);
          pointsFilteredByType.push(pointMarker);
        }
      } else {
        for (let i = 0; i < filteredPointsArr.length; i++) {
          const currentItem = filteredPointsArr[i];
          const { point } = currentItem;

          if (point.place_type === markerFilter) {
            const pointMarker = generatePointMarker(point);
            pointsFilteredByType.push(pointMarker);
          }
        }
      }

      return pointsFilteredByType;
    }
  };

  const drawPolygons = () => {
    if (searchForm.ids !== undefined) {
      if (searchForm.ids !== null) {
        return searchForm.ids.map((workField, index) => {
          const { id, location } = workField;
          const workFieldPoly = {
            ...workField,
            boundaries: location,
            id,
          };
          return workFieldPoly;
        });
      }
    }
  };

  return (
    <Card
      className="cardStyle"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Row className={getLanguage() === "en" ? "mr-3" : "ml-3"}>
        <Col style={{ height: "450px" }}>
          <GenericMapy
            map={map}
            mapID="actionPointMap"
            polygons={drawPolygons()}
            polygonsGroupName="workFields"
            markers={renderPointsMarkers()}
            markersGroupName="workFieldsPoints"
            zoomToMarkers={false}
            zoomToPolygons={false}
          >
            <MarkersGuide />
            <PointDetailsWindow
              point={point}
              isDisplayed={pointDetailsWindowIsDisplayed}
              setIsDisplayed={setPointDetailsWindowIsDisplayed}
            />
          </GenericMapy>
        </Col>
      </Row>
    </Card>
  );
};

export default ActionPointMapy;
