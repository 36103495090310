import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Col, Row } from "reactstrap";
import { Mapy } from "mapy_sdk/lib";
import RequestsTable from "./RequestsTable";
import RequestsTable2 from "./RequestsTable2";
import * as actions from "../../redux/actions/index";
import { getMyCompany } from "../../utils/GeneralFunctions";
import RequestSearch from "./RequestSearch";
import GeneralModel from "../../utils/GeneralModel";
import CreateUpdatePoint from "./CreateUpdatePoint";
import {
  UPDATE_REQUESTS_PROPS,
  CLEAR_REQUESTS_STATE,
} from "../../redux/actions/types";
import { unsetForm, unsetCompanyWFPListData } from "./RequestsFunctions";
import LightBoxFile from "../../utils/LightBoxFile";
import RequestMapy from "./RequestMapy";
import AddOrderVisit from "./AddOrderVisit";
import PointsTable from "./PointsTable";

let map = new Mapy();

class Requests extends Component {
  componentDidMount() {
    this.renderCompainesPoints();
  }

  componentWillUnmount() {
    this.props.general([], CLEAR_REQUESTS_STATE);
  }

  renderModalChildren = () => {
    const { modale, history } = this.props;

    if (modale.actionType === "AddOrderVisit") {
      return <AddOrderVisit history={history} />;
    } else {
      return <CreateUpdatePoint history={history} />;
    }
  };

  renderCompainesPoints = () => {
    const { general, getRequests } = this.props;
    // unsetCompanyWFPListData(general);
    general(
      [{ prop: "searchForm.company", value: getMyCompany(general) }],
      UPDATE_REQUESTS_PROPS
    );
    getRequests();
  };

  renderModal = () => {
    const { modale, general, pointForm, requestForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={modale.actionType === "AddOrderVisit" ? 1 : 3}
        tabs_title={
          modale.actionType === "AddOrderVisit"
            ? [modale.tabs_title[0]]
            : modale.tabs_title
        }
        modale={modale}
        general={general}
        action={UPDATE_REQUESTS_PROPS}
        formvalues={
          modale.actionType === "AddOrderVisit"
            ? requestForm.formvalues
            : pointForm.formvalues
        }
        unsetForm={unsetForm}
      />
    );
  };

  state = {
    renderMarkersFunction: null,
  };

  setRenderMarkersFunction = (func) => {
    this.setState({
      renderMarkersFunction: func,
    });
  };

  render() {
    const {
      history,
      general,
      requestsObj,
      sorting,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      getRequests,
      companyOptions,
      getCompainesWFForDropDown,
      modaleView,
      modale,
      pointForm,
      polygonItem,
      jobTitleOptions,
      titleOptions,
      degreeOptions,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      lightbox,
      loadBranchesOptions,
      branchOptions,
      getAllPoints,
      getCompainesWFPoint,
    } = this.props;
    return (
      <div className="page-wrapper">
        {this.renderModal()}
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_REQUESTS_PROPS}
        />
        <Row className="mt-2">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <RequestSearch
              {...this.props}
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              general={general}
              getRequests={getRequests}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              jobTitleOptions={jobTitleOptions}
              titleOptions={titleOptions}
              degreeOptions={degreeOptions}
              loadCompaniesOptions={loadCompaniesOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadBranchesOptions={loadBranchesOptions}
              branchOptions={branchOptions}
              getAllPoints={getAllPoints}
              requestsObj={requestsObj}
              renderMapMarkers={this.state.renderMarkersFunction}
            />

            {requestsObj.pointsItem ? (
              <PointsTable {...this.props} />
            ) : (
              // <RequestsTable {...this.props} />
              <RequestsTable2 {...this.props} />
            )}
            {/* <Card
              style={{
                borderRadius: '10px',
                border: 'none',
                height: '450px',
              }}
              className='mt-3'
            >
              <RequestMapy {...this.props} map={map} />
            </Card> */}
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  RequestsR,
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
  MapReducer,
}) => {
  return {
    parent_branch: RequestsR.parent_branch,
    pointForm: RequestsR.pointForm,
    requestForm: RequestsR.requestForm,
    modale: RequestsR.modale,
    requestsObj: RequestsR.requestsObj,
    sorting: RequestsR.sorting,
    searchForm: RequestsR.searchForm,
    isLoadingSearch: RequestsR.isLoadingSearch,
    modaleView: RequestsR.modaleView,
    polygonItem: RequestsR.polygonItem,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    lightbox: RequestsR.lightbox,
    branchOptions: BranchR.branchOptions,
    companyWFPointsTotals: RequestsR.totals,
    // mapZoomLevel: MapReducer.zoom,
    // mapMarkerFilter: MapReducer.markerFilter,
    // mapInstance: MapReducer.mapInstance,
    // markers: MapReducer.markers,
    // markersGuidIsShown: MapReducer.markersGuidIsShown,
    MapReducer: MapReducer,
  };
};

export default connect(mapStateToProps, actions)(Requests);
