import { setterGenerator } from "../../utils/GeneralFunctions";
import {
  CLEAR_SEARCH_FORM_PROPS,
  SET_FILTER,
  SET_IS_LOADING,
  UPDATE_SEARCH_FORM_PROPS,
} from "./types";

export const setSearchFormProps = (propsArr) =>
  setterGenerator(UPDATE_SEARCH_FORM_PROPS, propsArr);

export const clearSearchForm = () => ({
  type: CLEAR_SEARCH_FORM_PROPS,
});

export const setFilter = (filter, value) => ({
  type: SET_FILTER,
  payload: {
    filter,
    value,
  },
});

export const setIsLoading = (isLoading) => ({
  type: SET_IS_LOADING,
  isLoading,
});

export const setIsLoading2 = (isLoading) => ({
  type: `${SET_IS_LOADING}2`,
  isLoading,
});
