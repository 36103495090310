import React, { useEffect, useState } from "react";
import { Input, Button, Row, Col } from "reactstrap";
import toaster from "toasted-notes";
import { local } from "../lang/local";
import { isPointInPolygon } from "./GeneralFunctions";

const BoxLink = (props) => {
  const {
    general,
    prop,
    action,
    work_field_id,
    FormProp,
    polygonItem,
    mapZoomProp,
    saveLink,
    valueLink,
    map,
  } = props;

  const [link, setLink] = useState("");

  useEffect(() => {
    setLink(valueLink);
  }, [valueLink]);
  const renderPolygon = () => {
    if (polygonItem !== undefined) {
      if (polygonItem !== null) {
        let newPolygons = [];
        let polygon = polygonItem;
        let removedBracts = polygon.split("(")[2].split(")")[0].split(",");
        removedBracts.map((poly) => {
          newPolygons.push([+poly.split(" ")[1], +poly.split(" ")[0]]);
        });
        return newPolygons;
      } else {
        return null;
      }
    }
  };
  const handelLink = (e) => {
    console.log("mapZoomProp", mapZoomProp);
    e.preventDefault();
    if (work_field_id === null) {
      toaster.notify(local.SelectWorkFieldFirst, {
        duration: 2000,
      });
    } else {
      if (link !== "") {
        let urlRegex =
          /^(?:(?:https?|ftp):\/\/)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:\/\S*)?$/;
        let pointRegex = /^\d{1,2}.\d{1,10},\s{0,1}\d{1,2}.\d{1,10}$/;

        let lat, lng;

        if (urlRegex.test(link)) {
          lat = link.split("@")[1].split(",")[1];
          lng = link.split("@")[1].split(",")[0];
        } else if (pointRegex.test(link)) {
          lat = link.split(", ")[1];
          lng = link.split(", ")[0];
        }

        if (urlRegex.test(link) || pointRegex.test(link)) {
          // uncomment this on adding back mapy
          map?.map.setView([lng, lat], 20);
          if (saveLink !== undefined) {
            general([{ prop: saveLink, value: link }], action);
          }
          // let lat = link.split("@")[1].split(",")[1];
          // let lat = link.split(", ")[1];
          // let lng = link.split("@")[1].split(",")[0];
          // let lng = link.split(", ")[0];
          // let zoom = link
          //   .split("@")[1]
          //   .split("/")[0]
          //   .split(",")[2]
          //   .split("z")[0];

          // if (mapZoomProp !== undefined) {
          //   general([{ prop: mapZoomProp, value: +zoom }], action);
          // }

          if (FormProp !== undefined) {
            let point = "POINT(" + lat + " " + lng + ")";

            if (
              isPointInPolygon(Number(lat), Number(lng), renderPolygon()) ===
              true
            ) {
              general(
                [
                  {
                    prop: FormProp,
                    value: point,
                  },
                  {
                    prop: prop,
                    value: [lng, lat],
                  },
                ],
                action
              );
            } else {
              toaster.notify(local.PointOutside, {
                duration: 2000,
              });
            }
          } else {
            general(
              [
                {
                  prop: prop,
                  value: [lng, lat],
                },
              ],
              action
            );
          }
        } else {
          alert(local.NotUrl);
        }
      }
    }
  };

  return (
    <Row style={{alignItems: "center"}}>
      <Col xl={11}>
        <Input
          // className="inputStyle mt-4"
          className="bg-gray-100 focus:outline-none"
          style={{ backgroundColor: "#EEEEEE", border:"none", padding:"1rem" }}
          placeholder={local.Link}
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />
      </Col>
      <Col xl={1}>
        <Button
          onClick={(e) => handelLink(e)}
          style={{ background: "#0091da", border: "none", fontSize: "10px" }}
          className="inputStyle"
        >
          {local.Go}
        </Button>
      </Col>
    </Row>
  );
};

export default BoxLink;
