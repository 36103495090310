import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Row } from "reactstrap";
import StateTable from "./StateTable";
import * as actions from "../../redux/actions/index";
import StateSearch from "./StateSearch";
import GeneralModel from "../../utils/GeneralModel";
import CreateUpdateState from "./CreateUpdateState";
import { local } from "../../lang/local";
import { UPDATE_STATE_PROPS } from "../../redux/actions/types";
import { unsetForm, unsetStateListData } from "./StateFunction";
class States extends Component {
  componentDidMount() {
    unsetStateListData(this.props.general);
    this.renderState();
  }

  renderModalChildren = () => {
    return <CreateUpdateState history={this.props.history} />;
  };

  renderState = () => {
    const { getStates, searchForm } = this.props;
    getStates(false, false, searchForm);
  };
  renderModal = () => {
    const { modale, general, stateForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={1}
        tabs_title={[{ title: local.MainInfo }]}
        modale={modale}
        general={general}
        action={UPDATE_STATE_PROPS}
        formvalues={stateForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      stateObj,
      sorting,
      searchForm,
      isLoadingSearch,
      getStates,
    } = this.props;
    return (
      <div className="page-wrapper">
        {this.renderModal()}

        <Row className="mt-2">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <StateSearch
              history={history}
              general={general}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              getStates={getStates}
            />
            <StateTable
              general={general}
              stateObj={stateObj}
              sorting={sorting}
              searchForm={searchForm}
              getStates={getStates}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ StateR }) => {
  return {
    stateObj: StateR.stateObj,
    modale: StateR.modale,
    stateForm: StateR.stateForm,
    sorting: StateR.sorting,
    searchForm: StateR.searchForm,
    isLoadingSearch: StateR.isLoadingSearch,
  };
};

export default connect(mapStateToProps, actions)(States);
