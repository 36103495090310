import toaster from 'toasted-notes';
import { local, getLanguage } from '../../lang/local';
import {
  UPDATE_VISITS_PROPS,
  MERGE_VISITS_PROPS,
  DELETE_VISITS_PROPS,
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_COMPANY_PROPS,
  CLEAR_VISITS_STATE,
} from '../../redux/actions/types';
import Flatpickr from 'react-flatpickr';
import { Button, Col, Row } from 'reactstrap';
import add_field from '../../assets/img/add_field.png';
import AsyncSelect from 'react-select/async';
import { labelStyle, selectStyle3 } from '../../utils/GeneralStyle';
import {
  flatpickrDateFormat,
  getDeafultItem,
  promiseOptions,
  storeDate,
} from '../../utils/GeneralFunctions';
import { FiXCircle } from 'react-icons/fi';
import { AsyncPaginate } from 'react-select-async-paginate';

export const validateInputs = (visitForm) => {
  if (
    visitForm.formvalues.points[0] === null &&
    visitForm.formvalues.work_field_id[0] === null &&
    visitForm.company_id === null &&
    visitForm.worker_id === null
  ) {
    toaster.notify(local.EnterData, {
      duration: 2000,
    });
  } else {
    if (visitForm.formvalues.points[0] === null) {
      toaster.notify(local.PointsRquired, {
        duration: 2000,
      });
    }
    if (visitForm.formvalues.work_field_id[0] === null) {
      toaster.notify(local.SelectWorkField, {
        duration: 2000,
      });
    }

    if (visitForm.company_id === null) {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    }

    if (visitForm.worker_id === null) {
      toaster.notify(local.SelectWorkers, {
        duration: 2000,
      });
    }

    if (
      visitForm.formvalues.points[0] !== null &&
      visitForm.worker_id !== null &&
      visitForm.company_id !== null &&
      visitForm.formvalues.work_field_id[0] !== null
    ) {
      return true;
    }
  }
};

export const onSave = (e, createORUpdateVisit, visitForm, isModal) => {
  e.preventDefault();
  if (validateInputs(visitForm) === true) {
  createORUpdateVisit(
    visitForm.formvalues,
    visitForm.worker_id,
    visitForm.index,
    isModal
  );
  }
};

export const unsetForm = (general) => {
  general(
    [
      {
        prop: 'visitForm',
        value: {
          isLoading: false,
          formvalues: {
            points: [null],
            work_field_id: [null],
          },
          index: null,
          company_id: null,
          branch_id: null,
          worker_id: null,
          worker_type: null,
          iscreate: 'create',
        },
      },
      { prop: 'visitsOptions', value: [] },
      {
        prop: 'modale',
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: '',
          actionType: '',
          tabs_title: [
            {
              title:
                getLanguage() === 'en'
                  ? 'Main Information'
                  : 'البيانات الرئيسية',
              active: 'active',
              dots_pagination_active: 'dots_pagination_active',
            },
          ],

          mapItem: {
            polygon: [],
            mapPoints: [31.4177636, 30.00475],
          },
          activeIndex: 0,
          tabAction: 'next',
        },
      },
    ],
    UPDATE_VISITS_PROPS
  );
};

export const addNewWorkField = (e, general) => {
  e.preventDefault();
  general(
    [
      { prop: 'visitForm.formvalues.work_field_id', value: null },
      {
        prop: 'visitForm.formvalues.points',
        value: null,
      },
    ],
    MERGE_VISITS_PROPS
  );
};

export const renderAddButton = (general) => {
  return (
    <Button
      className='btn_add_wfield mt-4'
      onClick={(e) => addNewWorkField(e, general)}
      style={{ float: getLanguage() === 'en' ? 'left' : 'right' }}
    >
      <div className='mt-1 mb-1 ml-3 mr-3'>
        <p style={{ display: 'inline', fontSize: '10px' }}>
          {local.Addaworkplace}
        </p>
        &nbsp;
        <img
          src={add_field}
          style={{ width: '20px', height: '20px', display: 'inline' }}
          alt=''
        />
      </div>
    </Button>
  );
};

export const renderWorkFieldSelects = (
  index,
  value,
  general,
  options,
  endPoint,
  id,
  errorMessage,
  worker_type
) => {
  return (
    <>
      <span className='label_title' style={labelStyle}>
        {local.WorkPlaces}
      </span>
      <AsyncSelect
        className='mt-4 react-select-div'
        placeholder={local.WorkPlaces}
        styles={selectStyle3}
        isMulti={false}
        value={value}
        isClearable={false}
        onChange={(e) =>
          onChangeWorkField(e, endPoint, index, general, worker_type)
        }
        cacheOptions
        defaultOptions={options}
        loadOptions={() => promiseOptions(options)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onFocus={() =>
          id === null
            ? toaster.notify(errorMessage, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const onChangeWorkField = (e, endPoint, index, general, worker_type) => {
  if (e !== null) {
    general(
      [
        {
          prop: 'visitForm.formvalues.work_field_id.' + index,
          value: e,
        },
      ],
      UPDATE_VISITS_PROPS
    );
    endPoint(e.value, worker_type);
  } else {
    general(
      [
        {
          prop: 'visitForm.formvalues.work_field_id.' + index,
          value: null,
        },
      ],
      UPDATE_VISITS_PROPS
    );
  }
};

export const renderPoints = (
  index,
  value,
  general,
  options,
  id,
  errorMessage,
  visit_id,
  getCompainesWFPointForDropDown,
  worker_type
) => {
  return (
    <>
      <span className='label_title' style={labelStyle}>
        {local.WorkFieldPoint}
      </span>
      <AsyncSelect
        className='mt-4'
        placeholder={local.WorkFieldPoint}
        styles={selectStyle3}
        isMulti={visit_id === undefined ? true : false}
        value={value}
        isClearable={false}
        onInputChange={(e) =>
          getCompainesWFPointForDropDown(id.value, worker_type, e)
        }
        onChange={(e) => onChangePoint(e, general, index, visit_id)}
        cacheOptions
        defaultOptions={options}
        loadOptions={() => promiseOptions(options)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onFocus={() =>
          id === null
            ? toaster.notify(errorMessage, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const onChangePoint = (e, general, index, visit_type) => {
  if (e !== null) {
    let entry = [];

    if (visit_type === undefined) {
       e.map((p) => {
        p.date = null;
        p.visitType = p.visit_type;

        entry.push(p);
      });
      general(
        [
          {
            prop: 'visitForm.formvalues.points.' + index,
            value: entry,
          },
        ],
        UPDATE_VISITS_PROPS
      );
    } else {
      entry = e;
      general(
        [
          {
            prop: 'visitForm.formvalues.points.' + 0 + '.' + 0 + '.id',
            value: entry.id,
          },
          {
            prop: 'visitForm.formvalues.points.' + 0 + '.' + 0 + '.location',
            value: entry.location,
          },
          {
            prop:
              'visitForm.formvalues.points.' + 0 + '.' + 0 + '.work_field_id',
            value: entry.work_field_id,
          },
          {
            prop: 'visitForm.formvalues.points.' + 0 + '.' + 0 + '.value',
            value: entry.value,
          },
          {
            prop: 'visitForm.formvalues.points.' + 0 + '.' + 0 + '.label',
            value: entry.label,
          },
        ],
        UPDATE_VISITS_PROPS
      );
    }
  } else {
    general(
      [
        {
          prop: 'visitForm.formvalues.points.' + index,
          value: null,
        },
      ],
      UPDATE_VISITS_PROPS
    );
  }
};

export const renderVisitDate = (prop, value, general, name, minDate = null) => {
  return (
    <>
      <span className='label_title' style={labelStyle}>
        {local.DateVisit + ' ' + name}
      </span>
      <Flatpickr
        className='dateStyle mt-4'
        data-enable-time
        value={
          value !== null
            ? value.date !== undefined
              ? value.date
              : false
            : false
        }
        onChange={(date) => {
          console.log(date);
          storeDate(prop, date, UPDATE_VISITS_PROPS, general);
        }}
        // local={getLanguage() === "en" ? null : Arabic}
        local={null}
        placeholder={local.DateVisiting}
        options={{
          enableTime: false,
          altInput: true,
          altFormat: 'F j, Y',
          dateFormat: 'Y-m-d',
          minDate: flatpickrDateFormat(minDate),
        }}
      />
    </>
  );
};

export const renderRemoveButton = (index, general) => {
  let localizedStyling =
    getLanguage() === 'en'
      ? {
          direction: 'ltr',
          right: 0,
          transform: 'translateX(100%)',
        }
      : {
          direction: 'rtl',
          left: 0,
          transform: 'translateX(-100%)',
        };
  return (
    <span
      style={{
        position: 'absolute',
        // direction: getLanguage() === "en" ? "ltr" : "rtl",
        // right: 0,
        transform: 'translateX(100%)',
        ...localizedStyling,
      }}
    >
      <a
        style={{ cursor: 'pointer' }}
        onClick={() =>
          general(
            [
              { prop: 'visitForm.formvalues.work_field_id.' + index },
              { prop: 'visitForm.formvalues.points.' + index },
            ],
            DELETE_VISITS_PROPS
          )
        }
      >
        <FiXCircle style={{ color: '#0cdbc7', fontSize: '17px' }} />
      </a>
    </span>
  );
};

export const renderNewfieldsandPoints = (
  visitForm,
  general,
  companyWfOptions,
  getCompainesWFPointForDropDown,
  pointsOptions
) => {
  return visitForm.formvalues.work_field_id.map((workId, i) => {
    if (i !== 0) {
      return (
        <Row className='justify-content-center justify-content-lg-start'>
          <Col
            xl={3}
            lg={3}
            md={3}
            sm={12}
            xs={12}
            className='mt-2 mx-0 position-relative'
          >
            {renderRemoveButton(i, general)}

            {renderWorkFieldSelects(
              i,
              visitForm.formvalues.work_field_id[i],
              general,
              companyWfOptions,
              getCompainesWFPointForDropDown,
              visitForm.company_id,
              local.SelectWorkers
            )}
          </Col>
          {visitForm.formvalues.work_field_id[i] !== undefined ? (
            visitForm.formvalues.work_field_id[i] !== null ? (
              <>
                <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
                  {renderPoints(
                    i,
                    visitForm.formvalues.points[i],
                    general,
                    pointsOptions,
                    visitForm.formvalues.work_field_id[i],
                    local.SelectWorkField,
                    visitForm.formvalues.id,
                    getCompainesWFPointForDropDown
                  )}
                </Col>
                {visitForm.formvalues.points[i] !== undefined
                  ? visitForm.formvalues.points[i] !== null
                    ? visitForm.formvalues.points[i].map((d, index) => {
                        return (
                          <Col
                            xl={3}
                            lg={3}
                            md={3}
                            sm={12}
                            xs={12}
                            className='mt-2'
                          >
                            {renderVisitDate(
                              'visitForm.formvalues.points.' +
                                i +
                                '.' +
                                index +
                                '.date',
                              visitForm.formvalues.points[i][index],
                              general,
                              d.label,
                              new Date()
                            )}
                          </Col>
                        );
                      })
                    : null
                  : null}
              </>
            ) : null
          ) : null}
        </Row>
      );
    }
  });
};

export const renderWorkerSelect = (
  general,
  workerOptions,
  value,
  id,
  col,
  loadWorkerOptions,
  iscreate
) => {
  return (
    <>
      <span className='label_title' style={labelStyle}>
        {local.Worker}
      </span>

      <AsyncPaginate
        options={
          iscreate !== undefined
            ? iscreate === 'update'
              ? workerOptions
              : null
            : null
        }
        key={value}
        value={getDeafultItem(value, workerOptions)}
        placeholder={local.Worker}
        className={col !== null ? 'mt-' + col : null}
        styles={selectStyle3}
        loadOptions={(name, loadedOptions, page) =>
          loadWorkerOptions(name, loadedOptions, page, id)
        }
        isClearable={true}
        onChange={(e) => onChangeWorker(e, general)}
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
        cacheUniqs={[id]}
        onFocus={() =>
          id === null
            ? toaster.notify(local.ChooseCompany, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const onChangeWorker = (e, general) => {
  let workFields = [];

  if (e !== null) {
    e.work_fields.map((wf) => {
      workFields.push({
        label: getLanguage() === 'en' ? wf.name.en : wf.name.ar,
        value: wf.id,
        location: wf.location,
      });
    });
    general(
      [
        {
          prop: 'visitForm.worker_id',
          value: e.value,
        },
        {
          prop: 'visitForm.worker_type',
          value: e.worker_type,
        },
      ],
      UPDATE_VISITS_PROPS
    );
    general(
      [
        {
          prop: 'companyWfOptions',
          value: workFields,
        },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  } else {
    general(
      [
        {
          prop: 'visitForm.worker_id',
          value: null,
        },
        {
          prop: 'visitForm.worker_type',
          value: null,
        },

        {
          prop: 'visitForm.formvalues.work_field_id',
          value: [null],
        },
        {
          prop: 'visitForm.formvalues.points',
          value: [null],
        },
      ],
      UPDATE_VISITS_PROPS
    );
    general(
      [
        {
          prop: 'companyWfOptions',
          value: [],
        },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  }
};

export const unsetVisitListData = (general) => {
  general([], CLEAR_VISITS_STATE);
  general(
    [
      {
        prop: 'pointsOptions',
        value: [],
      },
    ],
    UPDATE_REQUESTS_PROPS
  );
  general(
    [
      {
        prop: 'companyWfOptions',
        value: [],
      },
    ],
    UPDATE_COMPANY_WORK_FIELD_PROPS
  );
  general(
    [
      {
        prop: 'companyOptions',
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};
