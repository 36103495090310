import { Request, Select_Request } from "../Api/index";
import {
  DELETE_WORKER_PROPS,
  MERGE_WORKER_PROPS,
  UPDATE_WORKER_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  renderFormData,
  confirmSunccessed,
  rmUnsetValsAndGetQryStr,
} from "../../utils/GeneralFunctions";

export const setWorkerProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for (let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1],
    });
  }

  dispatch({
    type: UPDATE_WORKER_PROPS,
    payload,
  });
};

export const getWorkers = (searchForm, isSearching) => (dispatch) => {
  dispatch({
    type: UPDATE_WORKER_PROPS,
    payload: [{ prop: "workerObj.workerItem", value: null }],
  });
  let url = "/workers/";

  if (searchForm !== null) {
    url += searchForm.company;
    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      mobile: searchForm.mobile,
      job_title: searchForm.job_title,
      // work_field_id: searchForm.work_field_id,
      work_field_id: searchForm.work_field_id?.id,
      page: searchForm.page,
      branch_id: searchForm.branch_id?.id,
    };
    let newSearchForm = removeUnsetValues(searchItems);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }
  if (isSearching === false) {
    dispatch({
      type: UPDATE_WORKER_PROPS,
      payload: [{ prop: "workerObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_WORKER_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }
  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_WORKER_PROPS,
            payload: [{ prop: "workerObj.workerItem", value: res.data.data }],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_WORKER_PROPS,
        payload: [
          { prop: "workerObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_WORKER_PROPS,
        payload: [
          { prop: "workerObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getWorkersForDropDown = (value) => (dispatch) => {
  Request()
    .get("/workers/" + value)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: r.name,
              value: r.id,
              work_fields: r.work_fields,
            });
          });
          dispatch({
            type: UPDATE_WORKER_PROPS,
            payload: [{ prop: "workerOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateWorker =
  (formValues, index, options, isModal) => (dispatch) => {
    dispatch({
      type: UPDATE_WORKER_PROPS,
      payload: [{ prop: "workerForm.isLoading", value: true }],
    });
    let url = null;
    if (formValues.id === undefined) {
      url = "/workers/store";
    } else {
      url = "/workers/update/" + formValues.id;
    }

    let newFormValues = Object.assign({}, formValues);
    newFormValues = removeUnsetValues(newFormValues);

    if (newFormValues.work_field_id !== undefined) {
      if (newFormValues.work_field_id !== null) {
        newFormValues.work_field_id = newFormValues.work_field_id.map(
          (wfp) => wfp.value
        );
      }
    }

    if (newFormValues.branch_id) {
      newFormValues.branch_id = newFormValues.branch_id.id;
    }

    if (newFormValues.points) {
      newFormValues.points = newFormValues.points.map((point) => ({
        work_field_id: point.work_field.id,
        point_id: point.id,
      }));
    }

    if (newFormValues.parent_id) {
      newFormValues.parent_id = newFormValues.parent_id.id;
    } else {
      newFormValues.parent_id = newFormValues.id;
    }

    let newFormData = null;
    if (newFormValues.image) {
      if (newFormValues.image[0] instanceof File) {
        if (newFormValues.parent_id === null) {
          newFormValues.parent_id = "";
        }

        newFormData = renderFormData(newFormValues);
      } else {
        delete newFormValues.image;
        newFormData = newFormValues;
      }
    } else {
      newFormData = newFormValues;
    }
    Request()
      .post(url, newFormData)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);
            window.location.reload("/add_worker");

            if (options !== null && isModal === true) {
              // dispatch({
              //   type: MERGE_WORKER_PROPS,
              //   payload: [
              //     { prop: "workerObj.workerItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);

            if (options !== null && isModal === true) {
              dispatch({
                type: UPDATE_WORKER_PROPS,
                payload: [
                  {
                    prop: "workerObj.workerItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }

          dispatch({
            type: UPDATE_WORKER_PROPS,
            payload: [
              {
                prop: "workerForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    company_id: null,
                    branch_id: null,
                    work_field_id: [],
                    points: [],
                    user_name: "",
                    password: "",
                    name: "",
                    ssn: "",
                    mobile: "",
                    email: "",
                    image: null,
                    job_title: null,
                    password_confirmation: "",
                    address: "",
                    parent_id: null,
                    worker_type: null,
                  },
                  index: null,
                  isLodingEdit: false,
                },
              },
              { prop: "workerOptions", value: [] },

              { prop: "lightbox", value: { isOpen: false, image: null } },
              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                    {
                      title:
                        getLanguage() === "en"
                          ? "System Data"
                          : "البيانات الخاصة بالنظام",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },

                    {
                      title:
                        getLanguage() === "en"
                          ? "Administrative data"
                          : "البيانات الإدارية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],

                  mapItem: {
                    polygon: [],
                    mapPoints: [31.4177636, 30.00475],
                  },
                  activeIndex: 0,
                  tabAction: "next",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }
        dispatch({
          type: UPDATE_WORKER_PROPS,
          payload: [{ prop: "workerForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_WORKER_PROPS,
          payload: [{ prop: "workerForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getWorker = (id, dropDownItem, index, company) => (dispatch) => {
  Request()
    .get("/workers/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            let workFields = [];
            res.data.data.work_fields.map((wf) => {
              workFields.push({
                ...wf,
                location: wf.location,
                value: wf.id,
                label: getLanguage() === "en" ? wf.name.en : wf.name.ar,
                address: wf.address,
              });

              let points = res.data.data.points.filter(
                (p) => p.work_field_id === wf.id
              );
              debugger;

              points.map((pa, index) => {
                pa.label = pa.name;
              });
            });
            res.data.data.company_id = company;

            if (res.data.data.supervisors.length > 0) {
              res.data.data.parent_id = [];
              res.data.data.supervisors.map((sup) => {
                res.data.data.parent_id.push({
                  label: sup.name,
                  value: sup.value,
                });
              });
            }

            dispatch({
              type: UPDATE_WORKER_PROPS,
              payload: [
                {
                  prop: "workerForm.formvalues",
                  value: res.data.data,
                },

                {
                  prop: "workerForm.formvalues.work_field_id",
                  value: workFields,
                },

                { prop: "modale.isOpen", value: true },
                { prop: "workerForm.index", value: index },
                { prop: "modale.actionType", value: "EditWorker" },
                { prop: "modale.title", value: local.updateWorker },
                { prop: "workerForm.isLoading", value: false },

                // {
                //   prop: "workerForm.formvalues.branch_id",
                //   value: res.data.data.branch.id,
                // },
                {
                  prop: "workerForm.formvalues.branch_id",
                  value: {
                    ...res.data.data.branch,
                    label: res.data.data.branch.name[getLanguage()],
                    value: res.data.data.branch.id,
                  },
                },
                {
                  prop: "workerForm.formvalues.work_field_id",
                  value: workFields,
                },
                {
                  prop: "workerForm.formvalues.points",
                  value: null,
                },
              ],
            });
          } else {
            let result = [
              { label: res.data.data.name, value: res.data.data.id },
            ];

            dispatch({
              type: UPDATE_WORKER_PROPS,
              payload: [{ prop: "workerOptions", value: result }],
            });
          }
        }
      }

      dispatch({
        type: UPDATE_WORKER_PROPS,
        payload: [{ prop: "workerForm.isLodingEdit", value: false }],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_WORKER_PROPS,
        payload: [{ prop: "workerForm.isLodingEdit", value: false }],
      });
    })
    .finally();
};

export const updateWorkerStatus = (id, active, index) => (dispatch) => {
  Request()
    .post("workers/changeStatus/" + id, { active: active })
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_WORKER_PROPS,
            payload: [
              {
                prop: "workerObj.workerItem.data." + index + ".active",
                value: active,
              },
            ],
          });
          confirmSunccessed(local.StatusScussfully);
        }
      }
    });
};

export const saveWorkerDataFromFile =
  (
    items,
    headers,
    logo,
    company,
    branch,
    supervisor,
    workPlaces,
    workFieldPoint
  ) =>
  (dispatch) => {
    let data = items;
    let nameIndex = headers.indexOf("Name");
    let emailIndex = headers.indexOf("Email");
    let userNameIndex = headers.indexOf("UserName");
    let addressIndex = headers.indexOf("Address");
    let mobileIndex = headers.indexOf("Mobile");
    let passwordIndex = headers.indexOf("Password");
    let confirmPasswordIndex = headers.indexOf("ConfirmPassword");
    let nationalIdIndex = headers.indexOf("NationalId");
    let JobTitleIndex = headers.indexOf("JobTitle");
    let workerTypeIndex = headers.indexOf("WorkerType");
    let logoIndex = headers.indexOf(logo);
    let Companyndex = headers.indexOf(company);
    let supervisorIndex = headers.indexOf(supervisor);
    let workPlaceIndex = headers.indexOf(workPlaces);
    let pointsIndex = headers.indexOf(workFieldPoint);
    let branchIndex = headers.indexOf(branch);

    dispatch({
      type: UPDATE_WORKER_PROPS,
      payload: [{ prop: "fileOptions.isLoading", value: true }],
    });

    data.map((row, index) => {
      let workFields = row[workPlaceIndex];
      if (workFields !== null) {
        workFields = workFields.map((st) => st.value);
      }
      let newPoints = [];
      let points = row[pointsIndex];
      if (points !== null) {
        points.forEach((element) => {
          return newPoints.push({
            id: element.id,
            work_field_id: element.work_field_id,
          });
        });
      }

      let image = row[logoIndex];
      let formData = null;
      let formValues = {
        company_id: row[Companyndex],
        work_field_id: workFields,
        user_name: row[userNameIndex],
        password: row[passwordIndex],
        name: row[nameIndex],
        ssn: row[nationalIdIndex],
        mobile: row[mobileIndex],
        email: row[emailIndex],
        image: null,
        job_title: row[JobTitleIndex],
        points: newPoints,
        password_confirmation: row[confirmPasswordIndex],
        address: row[addressIndex],
        parent_id: row[supervisorIndex],
        branch_id: row[branchIndex],
        worker_type: row[workerTypeIndex],
      };
      if (image === null || image === false) {
        formData = formValues;
      } else {
        formValues.image = image;
        formData = renderFormData(formValues);
      }
      Request()
        .post("/workers/store", formData)
        .then((res) => {
          if (res.data.status === true) {
            dispatch({
              type: UPDATE_WORKER_PROPS,
              payload: [
                {
                  prop: "fileOptions.importedData." + index + "." + row.length,
                  value: true,
                },
              ],
            });

            dispatch({
              type: DELETE_WORKER_PROPS,
              payload: [{ prop: "fileOptions.savedData." + index }],
            });

            if (index === data.length - 1) {
              confirmSunccessed(local.SuccessfullyAdded);
              dispatch({
                type: UPDATE_WORKER_PROPS,
                payload: [
                  { prop: "fileOptions.savedData", value: null },
                  { prop: "fileOptions.isLoading", value: false },
                ],
              });
            }
          } else {
            Object.values(res.data.error).map((msg) => {
              dispatch({
                type: MERGE_WORKER_PROPS,
                payload: [
                  {
                    prop: "fileOptions.errorData",
                    value: [
                      msg[0] + local.EnglishName + " : " + formValues.name.en,
                    ],
                  },
                ],
              });
            });

            dispatch({
              type: UPDATE_WORKER_PROPS,
              payload: [{ prop: "fileOptions.isLoading", value: false }],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_WORKER_PROPS,
            payload: [{ prop: "fileOptions.isLoading", value: false }],
          });
          console.log(err);
        })
        .finally();
    });
  };

export const loadWorkerOptions =
  (name, loadedOptions, page, company_id, pushToken, active) =>
  async (dispatch) => {
    let url = null;
    const searchForm = {
      page: page.page,
      name: name,
      push_token: pushToken,
      active,
    };

    const searchParams = rmUnsetValsAndGetQryStr(searchForm);
    if (company_id !== null) {
      // if (name == undefined) {
      //   url =
      //     // Select_Request.url + "/workers/" + company_id + "?page=" + page.page;
      //     Select_Request.url + `/workers/${company_id}${searchParams}`;
      // } else {
      //   if (name !== "") {
      //     url =
      //       Select_Request.url +
      //       "/workers/" +
      //       company_id +
      //       "?name=" +
      //       name +
      //       "&page=" +
      //       page.page;
      //   } else {
      //     url =
      //       Select_Request.url +
      //       "/workers/" +
      //       company_id +
      //       "?page=" +
      //       page.page;
      //   }
      // }

      url = Select_Request.url + `/workers/${company_id}${searchParams}`;

      let response = await fetch(url, Select_Request.options);
      let responseOK = response && response.ok;
      if (responseOK) {
        let result = await response.json();

        let options = [];

        if (result.status === true) {
          if (result.data.data !== null) {
            result.data.data.map((d) => {
              options.push({
                ...d,
                label: d.name,
                value: d.id,
                location: d.location,
                points: d.points,
                work_fields: d.work_fields,
                worker_type: d.worker_type === 1 ? "Marketing" : "Sales",
              });
            });
          }

          // dispatch({
          //   type: UPDATE_WORKER_PROPS,
          //   payload: [{ prop: "workerOptions", value: options }],
          // });
        }

        let entry = {
          result: result.data.data,
          has_more:
            result.data.pagination.current_page ===
            result.data.pagination.total_pages
              ? false
              : true,
        };

        dispatch({
          type: UPDATE_WORKER_PROPS,
          payload: [
            { prop: "workerOptions", value: [...loadedOptions, ...options] },
          ],
        });

        return {
          options: options,
          hasMore: entry.has_more,
          additional: {
            page: page.page + 1,
          },
        };
      }
    } else {
      return {
        options: [],
        hasMore: false,
        additional: {
          page: 1,
        },
      };
    }
  };

export const getWorkerProfile = (id) => (dispatch) => {
  Request()
    .get("/workers/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_WORKER_PROPS,
            payload: [
              {
                prop: "modaleView.data",
                value: res.data.data,
              },
            ],
          });
        }
      }

      dispatch({
        type: UPDATE_WORKER_PROPS,
        payload: [{ prop: "workerForm.isLodingEdit", value: false }],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_WORKER_PROPS,
        payload: [{ prop: "workerForm.isLodingEdit", value: false }],
      });
    })
    .finally();
};
