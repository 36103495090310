import * as React from "react";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { local } from "../../../lang/local";
import routes from "../../routes";

export const BreadCrumbs = ({ history }) => {
  const getItem = () => {
    let url = history.location.pathname;
    let newUrl = null;
    if (url !== "/dashboard/home") {
      let item = routes.find((e) => e.url === url);
      if (item) {
        newUrl = item;
      }
    }
    return newUrl;
  };
  const handleClick = (event, pathName) => {
    event.preventDefault();
    history.push(pathName);
  };
  return (
    <div role="presentation" className="breadcrumb mt-3 ml-3 mr-3">
      <Breadcrumbs aria-label="breadcrumb">
        <Link
          className="cursor"
          underline="hover"
          color="inherit"
          onClick={(e) => handleClick(e, "/dashboard/home")}
          disabled={history.location.pathname === "/dashboard/home"}
        >
          {local.Dashboard}
        </Link>
        {getItem() && (
          <Link
            className="cursor"
            underline="hover"
            color="inherit"
            onClick={(e) => handleClick(e, getItem().url)}
            disabled={history.location.pathname === getItem().url}
          >
            {local[getItem().name]}
          </Link>
        )}
      </Breadcrumbs>
    </div>
  );
};
