import React, { useState, useEffect } from "react";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { local, getLanguage } from "../../lang/local";
import { UPDATE_BRANCHES_PROPS } from "../../redux/actions/types";
import {
  renderArabicNameInput,
  renderEnglishNameInput,
  renderGeneralInput,
  renderPhoneInput,
} from "../../utils/GeneralFunctions";
import { labelStyle } from "../../utils/GeneralStyle";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import icon_btn from "../../assets/img/icon_btn.png";
import { onSave } from "./BranchesFunction";
import {
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";

const FirstTab = (props) => {
  const {
    general,
    branchForm,
    companyOptions,
    loadCompaniesOptions,
    createORUpdateBranch,
    setBranchProps,
  } = props;

  // const [companyId, setCompanyId] = useState();
  useEffect(() => {
    if (branchForm.formvalues.parent) {
      general(
        [
          {
            prop: "branchForm.formvalues.parent_id",
            value: {
              label:
                getLanguage() == 'en'
                  ? branchForm.formvalues.parent.name.en
                  : branchForm.formvalues.parent.name.ar,
              value: branchForm.formvalues.parent.id,
            },
          },
        ],
        UPDATE_BRANCHES_PROPS
      );
    }
    return () =>
      general(
        [
          {
            prop: "branchForm.formvalues.parent_id",
            value: null,
          },
        ],
        UPDATE_BRANCHES_PROPS
      );
  }, []);
  return (
    <>
      <CardBody className="trans">
        {branchForm.formvalues.id !==
        undefined ? null : checkHasOneCompany() === false ? (
          <Row>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <span className="label_title" style={labelStyle}>
                {local.Company}
              </span>
              {renderCompanySelect(
                "branchForm.formvalues.company_id",
                branchForm.formvalues.company_id,
                UPDATE_BRANCHES_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [{ p: "branchForm.formvalues.parent_id", value: null }],
                "4"
              )}
            </Col>
          </Row>
        ) : null}

        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderEnglishNameInput(
              "branchForm.formvalues.name.en",
              branchForm.formvalues.name?.en,
              UPDATE_BRANCHES_PROPS,
              general
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderArabicNameInput(
              "branchForm.formvalues.name.ar",
              branchForm.formvalues.name?.ar,
              UPDATE_BRANCHES_PROPS,
              general
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              "branchForm.formvalues.address",
              branchForm.formvalues.address,
              UPDATE_BRANCHES_PROPS,
              general,
              local.Address
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderPhoneInput(
              "branchForm.formvalues.mobile",
              branchForm.formvalues.mobile,
              UPDATE_BRANCHES_PROPS,
              general
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              "branchForm.formvalues.manager_name",
              branchForm.formvalues.manager_name,
              UPDATE_BRANCHES_PROPS,
              general,
              local.ManagerName
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <CustomAsyncPaginate
            fullWidth
            hasLabel
            loadOptions={getBranchOptions(
              branchForm.formvalues.company_id ||
                branchForm.formvalues.company?.id,
              {
                parent_id: 0,
              }
            )}
            onChange={(e) => {
              handleSelectChangeEnhanced(
                e,
                "branchForm.formvalues.parent_id",
                setBranchProps
              );
            }}
            value={
              !branchForm.formvalues.parent_id
                ? branchForm.formvalues.parent?.id
                : branchForm.formvalues.parent_id
            }
            placeholder={local.Regions}
            additional={{
              page: 1,
            }}
            isDisabled={
              // "company_id" in branchForm.formvalues
              branchForm.formvalues.hasOwnProperty("company_id") &&
              !!branchForm.formvalues?.company_id
                ? !!!branchForm.formvalues.company_id
                : !!!branchForm.formvalues.company?.id
            }
          />
        </Row>
      </CardBody>
      <center>
        <button
          type="submit"
          disabled={branchForm.isLoading}
          className="save_btn mt-5"
          onClick={(e) => onSave(e, createORUpdateBranch, branchForm, true)}
        >
          {branchForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center>
    </>
  );
};
export default FirstTab;
