import {
  CLEAR_STATE,
  UPDATE_HOME_PROPS,
  MERGE_HOME_PROPS,
  DELETE_HOME_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  homeObj: { isLoading: false, data: {} },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_HOME_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_HOME_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_HOME_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
