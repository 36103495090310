import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { setMapProps } from "../../redux/actions/MapActions";

const MarkersGuideItem = ({
  markerIcon,
  title,
  action,
  filterValue,
  isVisible = true,
  setMapProps,
}) => {
  const itemStyles = {
    backgroundColor: "white",
    marginBottom: "10px",
    marginLeft: "5px",
    marginRight: "5px",
    padding: "5px",
    borderRadius: "10px",
    display: isVisible ? "block" : "none",
    transition: "display 2s",
    textAlign: "start",
  };

  const onClick = action
    ? action
    : () => {
        setMapProps([["markerFilter", filterValue]]);
      };

  return (
    <div style={itemStyles} role="button" onClick={onClick}>
      <img className="mx-2" src={markerIcon} width={15} />
      {title}
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      setMapProps,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(MarkersGuideItem);
