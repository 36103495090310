import { SEARCH_FORM } from '../reducers/SearchFormReducer';

export const CLEAR_STATE = 'CLEAR_STATE';

// // Home
export const UPDATE_HOME_PROPS = 'UPDATE_HOME_PROPS';
export const MERGE_HOME_PROPS = 'MERGE_HOME_PROPS';
export const DELETE_HOME_PROPS = 'DELETE_HOME_PROPS';

// // LOGIN
export const UPDATE_LOGIN_PROPS = 'UPDATE_LOGIN_PROPS';
export const MERGE_LOGIN_PROPS = 'MERGE_LOGIN_PROPS';
export const DELETE_LOGIN_PROPS = 'DELETE_LOGIN_PROPS';

// // STATE
export const UPDATE_STATE_PROPS = 'UPDATE_STATE_PROPS';
export const MERGE_STATE_PROPS = 'MERGE_STATE_PROPS';
export const DELETE_STATE_PROPS = 'DELETE_STATE_PROPS';

// // STATE_WORK_FIELD
export const UPDATE_STATE_WORK_FIELD_PROPS = 'UPDATE_STATE_WORK_FIELD_PROPS';
export const MERGE_STATE_WORK_FIELD_PROPS = 'MERGE_STATE_WORK_FIELD_PROPS';
export const DELETE_STATE_WORK_FIELD_PROPS = 'DELETE_STATE_WORK_FIELD_PROPS';

// // COMPANY
export const UPDATE_COMPANY_PROPS = 'UPDATE_COMPANY_PROPS';
export const MERGE_COMPANY_PROPS = 'MERGE_COMPANY_PROPS';
export const DELETE_COMPANY_PROPS = 'DELETE_COMPANY_PROPS';
export const CLEAR_COMPANY_PROPS = 'CLEAR_COMPANY_PROPS';

// // COMPANY_WORK_FIELD
export const UPDATE_COMPANY_WORK_FIELD_PROPS =
  'UPDATE_COMPANY_WORK_FIELD_PROPS';
export const MERGE_COMPANY_WORK_FIELD_PROPS = 'MERGE_COMPANY_WORK_FIELD_PROPS';
export const DELETE_COMPANY_WORK_FIELD_PROPS =
  'DELETE_COMPANY_WORK_FIELD_PROPS';
export const CLEAR_COMPANY_WORK_FIELD_STATE = 'CLEAR_COMPANY_WORK_FIELD_STATE';

// // REQUESTS
export const UPDATE_REQUESTS_PROPS = 'UPDATE_REQUESTS_PROPS';
export const MERGE_REQUESTS_PROPS = 'MERGE_REQUESTS_PROPS';
export const DELETE_REQUESTS_PROPS = 'DELETE_REQUESTS_PROPS';
export const CLEAR_REQUESTS_STATE = 'CLEAR_REQUESTS_STATE';
export const CLEAR_REQUESTS_FORM = 'CLEAR_REQUESTS_FORM';
export const CLEAR_REQUESTS_MODAL = 'CLEAR_REQUESTS_MODAL';

// // WORKER
export const UPDATE_WORKER_PROPS = 'UPDATE_WORKER_PROPS';
export const MERGE_WORKER_PROPS = 'MERGE_WORKER_PROPS';
export const DELETE_WORKER_PROPS = 'DELETE_WORKER_PROPS';
export const CLEAR_WORKER_STATE = 'CLEAR_WORKER_STATE';

// // Ticket
export const UPDATE_Ticket_PROPS = 'UPDATE_Ticket_PROPS';
export const UPDATE_Ticket_PROPS_Action='UPDATE_Ticket_PROPS_Action'


// // REPORT
export const UPDATE_REPORT_PROPS = 'UPDATE_REPORT_PROPS';
export const MERGE_REPORT_PROPS = 'MERGE_REPORT_PROPS';
export const DELETE_REPORT_PROPS = 'DELETE_REPORT_PROPS';
export const CLEAR_REPORT_STATE = 'CLEAR_REPORT_STATE';

// // USER
export const UPDATE_USER_PROPS = 'UPDATE_USER_PROPS';
export const MERGE_USER_PROPS = 'MERGE_USER_PROPS';
export const DELETE_USER_PROPS = 'DELETE_USER_PROPS';
export const CLEAR_USER_STATE = 'CLEAR_USER_STATE';

// // ARCHIVES
export const UPDATE_ARCHIVES_PROPS = 'UPDATE_ARCHIVES_PROPS';
export const MERGE_ARCHIVES_PROPS = 'MERGE_ARCHIVES_PROPS';
export const DELETE_ARCHIVES_PROPS = 'DELETE_ARCHIVES_PROPS';
export const CLEAR_ARCHIVES_STATE = 'CLEAR_ARCHIVES_STATE';

// // LIVE_TRACKING
export const UPDATE_LIVE_TRACKING_PROPS = 'UPDATE_LIVE_TRACKING_PROPS';
export const MERGE_LIVE_TRACKING_PROPS = 'MERGE_LIVE_TRACKING_PROPS';
export const DELETE_LIVE_TRACKING_PROPS = 'DELETE_LIVE_TRACKING_PROPS';
export const CLEAR_LIVE_TRACKING_STATE = 'CLEAR_LIVE_TRACKING_STATE';

// // REPORT_TEMPLETE
export const UPDATE_REPORT_TEMPLETE_PROPS = 'UPDATE_REPORT_TEMPLETE_PROPS';
export const MERGE_REPORT_TEMPLETE_PROPS = 'MERGE_REPORT_TEMPLETE_PROPS';
export const DELETE_REPORT_TEMPLETE_PROPS = 'DELETE_REPORT_TEMPLETE_PROPS';
export const CLEAR_REPORT_TEMPLETE_STATE = 'CLEAR_REPORT_TEMPLETE_STATE';

// // NOTIFICATIONS
export const UPDATE_NOTIFICATIONS_PROPS = 'UPDATE_NOTIFICATIONS_PROPS';
export const MERGE_NOTIFICATIONS_PROPS = 'MERGE_NOTIFICATIONS_PROPS';
export const DELETE_NOTIFICATIONS_PROPS = 'DELETE_NOTIFICATIONS_PROPS';
export const Clear_NOTIFICATIONS_PROPS = 'Clear_NOTIFICATIONS_PROPS';

// // HISTORIES
export const UPDATE_HISTORIES_PROPS = 'UPDATE_HISTORIES_PROPS';
export const MERGE_HISTORIES_PROPS = 'MERGE_HISTORIES_PROPS';
export const DELETE_HISTORIES_PROPS = 'DELETE_HISTORIES_PROPS';
export const CLEAR_HISTORIES_STATE = 'CLEAR_HISTORIES_STATE';

// // ACTION_POINT
export const UPDATE_ACTION_POINT_PROPS = 'UPDATE_ACTION_POINT_PROPS';
export const MERGE_ACTION_POINT_PROPS = 'MERGE_ACTION_POINT_PROPS';
export const DELETE_ACTION_POINT_PROPS = 'DELETE_ACTION_POINT_PROPS';
export const CLEAR_ACTION_POINT_STATE = 'CLEAR_ACTION_POINT_STATE';

// // VISITS
export const UPDATE_VISITS_PROPS = 'UPDATE_VISITS_PROPS';
export const MERGE_VISITS_PROPS = 'MERGE_VISITS_PROPS';
export const DELETE_VISITS_PROPS = 'DELETE_VISITS_PROPS';
export const CLEAR_VISITS_STATE = 'CLEAR_VISITS_STATE';

// // WORK_HOURS
export const UPDATE_WORK_HOURS_PROPS = 'UPDATE_WORK_HOURS_PROPS';
export const MERGE_WORK_HOURS_PROPS = 'MERGE_WORK_HOURS_PROPS';
export const DELETE_WORK_HOURS_PROPS = 'DELETE_WORK_HOURS_PROPS';

// // PRODUCTS
export const UPDATE_PRODUCTS_PROPS = 'UPDATE_PRODUCTS_PROPS';
export const MERGE_PRODUCTS_PROPS = 'MERGE_PRODUCTS_PROPS';
export const DELETE_PRODUCTS_PROPS = 'DELETE_PRODUCTS_PROPS';
export const CLEAR_PRODUCTS_STATE = 'CLEAR_PRODUCTS_STATE';

// // AMOUNT_PRODUCT
export const UPDATE_AMOUNT_PRODUCT_PROPS = 'UPDATE_AMOUNT_PRODUCT_PROPS';
export const MERGE_AMOUNT_PRODUCT_PROPS = 'MERGE_AMOUNT_PRODUCT_PROPS';
export const DELETE_AMOUNT_PRODUCT_PROPS = 'DELETE_AMOUNT_PRODUCT_PROPS';
export const CLEAR_AMOUNT_PRODUCT_STATE = 'CLEAR_AMOUNT_PRODUCT_STATE';

// // BRANCHES
export const UPDATE_BRANCHES_PROPS = 'UPDATE_BRANCHES_PROPS';
export const MERGE_BRANCHES_PROPS = 'MERGE_BRANCHES_PROPS';
export const DELETE_BRANCHES_PROPS = 'DELETE_BRANCHES_PROPS';
export const CLEAR_BRANCHES_STATE = 'CLEAR_BRANCHES_STATE';

// // WORK_REPORT
export const UPDATE_WORK_REPORT_PROPS = 'UPDATE_HISTORIES_PROPS';
export const MERGE_WORK_REPORT_PROPS = 'MERGE_HISTORIES_PROPS';
export const DELETE_WORK_REPORT_PROPS = 'DELETE_HISTORIES_PROPS';
export const CLEAR_WORK_REPORT_STATE = 'CLEAR_WORK_REPORT_STATE';

// // GIFTS_AND_SAMPLES
export const UPDATE_GIFTS_PROPS = 'UPDATE_GIFTS_PROPS';

// // SEARCH_FORM
export const UPDATE_SEARCH_FORM_PROPS = 'UPDATE_SEARCH_FORM_PROPS';
export const MERGE_SEARCH_FORM_PROPS = 'MERGE_SEARCH_FORM_PROPS';
export const DELETE_SEARCH_FORM_PROPS = 'DELETE_SEARCH_FORM_PROPS';
export const CLEAR_SEARCH_FORM_PROPS = 'CLEAR_SEARCH_FORM_PROPS';

export const SET_FILTER = `${SEARCH_FORM}SET_FILTER`;
export const SET_IS_LOADING = `${SEARCH_FORM}SET_IS_LOADING`;

// // COVERAGE_LIST
export const UPDATE_COVERAGE_LIST_PROPS = 'UPDATE_COVERAGE_LIST_PROPS';
export const MERGE_COVERAGE_LIST_PROPS = 'MERGE_COVERAGE_LIST_PROPS';
export const DELETE_COVERAGE_LIST_PROPS = 'DELETE_COVERAGE_LIST_PROPS';
export const CLEAR_COVERAGE_LIST_PROPS = 'CLEAR_COVERAGE_LIST_PROPS';

// // TABLE
export const UPDATE_TABLE_PROPS = 'UPDATE_TABLE_PROPS';
export const MERGE_TABLE_PROPS = 'MERGE_TABLE_PROPS';
export const DELETE_TABLE_PROPS = 'DELETE_TABLE_PROPS';
export const CLEAR_TABLE_PROPS = 'CLEAR_TABLE_PROPS';

// // MERCHANT
export const UPDATE_MERCHANT_PROPS = 'UPDATE_MERCHANT_PROPS';
export const MERGE_MERCHANT_PROPS = 'MERGE_MERCHANT_PROPS';
export const DELETE_MERCHANT_PROPS = 'DELETE_MERCHANT_PROPS';
export const CLEAR_MERCHANT_PROPS = 'CLEAR_MERCHANT_PROPS';
