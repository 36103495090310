import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import CompainesTable from './CompainesTable';
import * as actions from '../../redux/actions/index';
import CompanySearch from './CompanySearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateCompany from './CreateUpdateCompany';
import { UPDATE_COMPANY_PROPS } from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
import { unsetForm, unsetCompanyListData } from './CompainesFunction';
class Compaines extends Component {
  componentDidMount() {
    unsetCompanyListData(this.props.general);
    this.renderCompaines();
    console.log(this.props.companyObj);
  }

  componentWillUnmount() {
    this.props.clearCompanyState();
  }

  renderModalChildren = () => {
    const { history } = this.props;
    return <CreateUpdateCompany unsetForm={unsetForm} history={history} />;
  };

  renderCompaines = () => {
    const {
      getCompaines,
      getStatesWFForDropDown,
      getStatesForDropDown,
      searchForm,
    } = this.props;
    getStatesForDropDown();
    getStatesWFForDropDown();
    getCompaines(false, searchForm);
  };
  renderModal = () => {
    const { modale, general, companyForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={2}
        tabs_title={modale.tabs_title}
        modale={modale}
        general={general}
        action={UPDATE_COMPANY_PROPS}
        formvalues={companyForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };

  render() {
    const {
      history,
      general,
      companyObj,
      sorting,
      lightbox,
      updateStatus,
      searchForm,
      isLoadingSearch,
      stateOptions,
      stateWFOptions,
      getCompaines,
      stateusOption,
      modaleView,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_COMPANY_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <CompanySearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              stateOptions={stateOptions}
              stateWFOptions={stateWFOptions}
              general={general}
              getCompaines={getCompaines}
              stateusOption={stateusOption}
            />

            <CompainesTable
              general={general}
              companyObj={companyObj}
              sorting={sorting}
              updateStatus={updateStatus}
              history={history}
              getCompaines={getCompaines}
              searchForm={searchForm}
              modaleView={modaleView}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ CompanyR, StateWFR, StateR }) => {
  return {
    companyObj: CompanyR.companyObj,
    modale: CompanyR.modale,
    companyForm: CompanyR.companyForm,
    sorting: CompanyR.sorting,
    lightbox: CompanyR.lightbox,
    searchForm: CompanyR.searchForm,
    modaleView: CompanyR.modaleView,
    isLoadingSearch: CompanyR.isLoadingSearch,
    stateusOption: CompanyR.stateusOption,
    stateOptions: StateR.stateOptions,
    stateWFOptions: StateWFR.stateWFOptions,
  };
};

export default connect(mapStateToProps, actions)(Compaines);
