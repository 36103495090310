import { Card } from "@material-ui/core";
import React, { Component } from "react";
import { connect } from "react-redux";
import { Col, Button, Row, Table } from "reactstrap";
import { renderProfileRow, renderDate } from "../../utils/GeneralFunctions";
import Header from "../../routing/Layout/Header";
import { getLanguage, local } from "../../lang/local";
import * as actions from "../../redux/actions/index";
import { UPDATE_WORKER_PROPS } from "../../redux/actions/types";
import ModalView from "../../utils/ModalView";
import user_profile from "../../assets/img/user_profile.png";
import report_icon_inactive from "../../assets/img/report_icon_inactive.png";
import track_inactive from "../../assets/img/track_inactive.png";
import mobiles from "../../assets/img/mobiles.png";
import ticket from "../../assets/img/ticket.png";
import disk from "../../assets/img/disk.png";

const Sidebar = React.lazy(() => import("../../routing/Layout/Sidebar"));

class WorkerProfile extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.history !== undefined) {
      this.props.getWorkerProfile(
        this.props.history.location.pathname.split("/")[2]
      );
    }
  }

  getData = () => {
    const { history } = this.props;
    if (history !== undefined) {
      return history.location.pathname.split("/")[2];
    } else {
      return undefined;
    }
  };

  renderBody = () => {
    const { modaleView } = this.props;
    return (
      <div>
        {renderProfileRow(
          local.Name,
          modaleView.data.name,
          user_profile,
          local.UserName,
          modaleView.data.user_name,
          user_profile
        )}
        {renderProfileRow(
          local.Mobile,
          modaleView.data.mobile,
          mobiles,
          local.Address,
          modaleView.data.address,
          track_inactive
        )}
        {renderProfileRow(
          local.Ssn,
          modaleView.data.ssn,
          report_icon_inactive,
          local.Email,
          modaleView.data.email,
          report_icon_inactive
        )}
        {modaleView.data.active === true
          ? renderProfileRow(
              local.Status,
              local.Active,
              ticket,
              local.JobTitle,
              modaleView.data.job_title,
              disk
            )
          : renderProfileRow(
              local.Status,
              local.InActive,
              ticket,
              local.JobTitle,
              modaleView.data.job_title,
              disk
            )}
        {renderProfileRow(
          local.Type,
          modaleView.data.worker_type,
          report_icon_inactive,
          null,
          null,
          null,
          null
        )}
        {modaleView.data.work_fields.length > 0 ? (
          <>
            <p className="text-center mt-5">{local.WorkPlaces}</p>

            <Table
              striped
              responsive
              className="table_list"
              style={{
                borderTopColor: "white",
                border: "none",
                position: "relative",
              }}
            >
              <thead>
                <tr>
                  <th>{local.Name}</th>
                  <th>{local.Address}</th>
                </tr>
              </thead>
              <tbody>
                {modaleView.data.work_fields.map((wf, index) => {
                  return (
                    <tr key={index}>
                      <th>
                        {getLanguage() === "en" ? wf.name.en : wf.name.ar}
                      </th>
                      <th>{wf.address}</th>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : null}
      </div>
    );
  };
  renderChildren = () => {
    const { modaleView, general } = this.props;

    return (
      <div>
        {modaleView.data !== null ? (
          <>
            <Row>
              <Col xl={1}>
                <p className="green_text">{local.Id}</p>
              </Col>
              <Col xl={1}>
                <p className="blue_text">{modaleView.data.id}</p>
              </Col>
              <Col>
                <Button className="sing_report_btn_blue">
                  <div>
                    <p style={{ display: "inline", fontSize: "10px" }}>
                      {renderDate(modaleView.data.created_at)}
                    </p>
                  </div>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xl={3} className="mt-4">
                {modaleView.data.image !== null ? (
                  <Card
                    style={{
                      border: "none",
                    }}
                  >
                    <a
                      href
                      onClick={() =>
                        general(
                          [
                            {
                              prop: "lightbox.image",
                              value: modaleView.data.image,
                            },
                            { prop: "lightbox.isOpen", value: true },
                          ],
                          UPDATE_WORKER_PROPS
                        )
                      }
                    >
                      <img
                        src={modaleView.data.image}
                        alt="img"
                        style={{ width: "90%", height: "180px" }}
                        className="img-fluid rounded"
                      />
                    </a>
                  </Card>
                ) : null}
              </Col>
              <Col>{this.renderBody()}</Col>
            </Row>
          </>
        ) : null}
      </div>
    );
  };

  render() {
    const { modaleView, general, history } = this.props;

    return this.getData() === undefined ? (
      <ModalView
        children={this.renderChildren}
        modaleView={modaleView}
        general={general}
        action={UPDATE_WORKER_PROPS}
      />
    ) : (
      <div className="conatiner">
        <Header history={history} />

        <Row>
          <div className="mt-2">
            <Sidebar />
          </div>
          <Col className="mt-2">
            <Card className="cardCreate ml-2 mr-2 mt-5">
              <div className="mt-5 mr-5 ml-5">{this.renderChildren()}</div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ WorkerR }) => {
  return {
    modaleView: WorkerR.modaleView,
  };
};
export default connect(mapStateToProps, actions)(WorkerProfile);
