import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme, makeStyles } from "@material-ui/core/styles";
import ModelTab from "./ModelTab";
import { FiX } from "react-icons/fi";
import { Button } from "reactstrap";
import { getLanguage, local } from "../lang/local";
import next from "../assets/img/next.png";
import next_en from "../assets/img/next_en.png";
import StepModel from "./StepModel";
import AlertModel from "./AlertModel";
const useStyles = makeStyles((theme) => ({
  root: {
    direction: getLanguage() === "en" ? "ltr" : "rtl",
  },
}));

const GeneralModel = (props) => {
  const {
    children,
    number_of_tabs,
    tabs_title,
    modale,
    general,
    action,
    formvalues,
    unsetForm,
  } = props;

  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const classes = useStyles();

  const handleClose = (e) => {
    e.preventDefault();

    general([{ prop: "modale.tobeConfirmed", value: true }], action);
  };

  const goToNext = (e) => {
    e.preventDefault();
    if (modale.activeIndex === 0) {
      general(
        [
          { prop: "modale.activeIndex", value: modale.activeIndex + 1 },
          { prop: "modale.tabAction", value: "next" },
        ],
        action
      );
    }
    if (modale.activeIndex === modale.tabs_title.length - 1) {
      general(
        [
          { prop: "modale.activeIndex", value: modale.activeIndex - 1 },
          { prop: "modale.tabAction", value: "prev" },
        ],
        action
      );
    } else {
      if (modale.tabAction === "next") {
        general(
          [{ prop: "modale.activeIndex", value: modale.activeIndex + 1 }],
          action
        );
      } else {
        if (modale.activeIndex === 1) {
          general(
            [
              { prop: "modale.activeIndex", value: modale.activeIndex - 1 },
              { prop: "modale.tabAction", value: "next" },
            ],
            action
          );
        } else {
          general(
            [{ prop: "modale.activeIndex", value: modale.activeIndex - 1 }],
            action
          );
        }
      }
    }
  };

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={modale.isOpen}
        aria-labelledby="responsive-dialog-title"
        className={classes.root}
        fullWidth
        maxWidth="sm"
      >
        <Button className="button_close" onClick={(e) => handleClose(e)}>
          <FiX />
        </Button>

        <ModelTab
          tabs_title={tabs_title}
          activeIndex={modale.activeIndex}
          action={action}
          general={general}
        />
        <DialogContent className="mt-3 mb-5">
          <AlertModel
            modale={modale}
            general={general}
            action={action}
            formvalues={formvalues}
            unsetForm={unsetForm}
          />
          {children()}
        </DialogContent>
        {number_of_tabs > 1 && modale.activeIndex !== tabs_title.length - 1 ? (
          <center>
            <Button
              onClick={(e) => goToNext(e)}
              color="primary"
              className="save_btn mb-3"
              style={{
                fontSize: "10px",
              }}
            >
              {getLanguage() === "ar" ? (
                <img src={next} alt="" width="15" height="15" />
              ) : null}
              {local.Next + " "}
              {getLanguage() === "en" ? (
                <img src={next_en} alt="" width="15" height="15" />
              ) : null}
            </Button>
            <StepModel
              tabs_title={tabs_title}
              activeIndex={modale.activeIndex}
            />
          </center>
        ) : null}
      </Dialog>
    </div>
  );
};
export default GeneralModel;
