import { getLanguage } from "../../lang/local";
import {
  DELETE_WORKER_PROPS,
  UPDATE_WORKER_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_PROPS,
  CLEAR_WORKER_STATE,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import { local } from "../../lang/local";
import { FiXCircle } from "react-icons/fi";
import { selectStyle3 } from "../../utils/GeneralStyle";
import {
  getDeafultItem,
  validatePhoneLength,
} from "../../utils/GeneralFunctions";
import { AsyncPaginate } from "react-select-async-paginate";
import { addWorker } from "../../redux/Api/apiCallsMethods";

export const unsetForm = (general) => {
  general(
    [
      {
        prop: "workerForm",
        value: {
          isLoading: false,
          formvalues: {
            company_id: null,
            branch_id: null,
            work_field_id: [],
            user_name: "",
            password: "",
            name: "",
            ssn: "",
            mobile: "",
            email: "",
            image: null,
            job_title: null,
            password_confirmation: "",
            address: "",
            parent_id: null,
            worker_type: null,
          },
          index: null,

          isLodingEdit: false,
        },
      },
      { prop: "workerOptions", value: [] },

      { prop: "lightbox", value: { isOpen: false, image: null } },
      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
          tabs_title: [
            {
              title:
                getLanguage() === "en"
                  ? "Main Information"
                  : "البيانات الرئيسية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
            {
              title:
                getLanguage() === "en"
                  ? "System Data"
                  : "البيانات الخاصة بالنظام",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },

            {
              title:
                getLanguage() === "en"
                  ? "Administrative data"
                  : "البيانات الإدارية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
          ],

          mapItem: {
            polygon: [],
            mapPoints: [31.4177636, 30.00475],
          },
          activeIndex: 0,
          tabAction: "next",
        },
      },
    ],
    UPDATE_WORKER_PROPS
  );
};
function getlength(number) {
  return number.toString().length;
}
export const validateInputs = (workerForm) => {
  if (
    workerForm.formvalues.company_id === null &&
    workerForm.formvalues.user_name === "" &&
    workerForm.formvalues.password === "" &&
    workerForm.formvalues.name === "" &&
    // workerForm.formvalues.ssn === "" &&
    workerForm.formvalues.mobile === "" &&
    workerForm.formvalues.email === "" &&
    // workerForm.formvalues.job_title === null &&
    workerForm.formvalues.password_confirmation === "" &&
    workerForm.formvalues.address === "" &&
    workerForm.formvalues.worker_type === null
  ) {
    toaster.notify(local.EnterData, {
      duration: 2000,
    });
  } else {
    // if (workerForm.formvalues.image === null) {
    //   toaster.notify(local.ImageRequired, {
    //     duration: 2000,
    //   });
    // }

    if (workerForm.formvalues.worker_type === null) {
      toaster.notify(local.WorkerTypeRequired, {
        duration: 2000,
      });
    }

    if (workerForm.formvalues.company_id === null) {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    }

    if (workerForm.formvalues.user_name === "") {
      toaster.notify(local.UserNameRequired, {
        duration: 2000,
      });
    }
    if (workerForm.formvalues.password === "") {
      toaster.notify(local.PasswordRequired, {
        duration: 2000,
      });
    }
    if (workerForm.formvalues.name === "") {
      toaster.notify(local.NameRequired, {
        duration: 2000,
      });
    }
    // if (workerForm.formvalues.ssn === "") {
    //   toaster.notify(local.SSNRequired, {
    //     duration: 2000,
    //   });
    // }

    // if (workerForm.formvalues.ssn !== "") {
    //   if (getlength(workerForm.formvalues.ssn) !== 14) {
    //     toaster.notify(local.SSNLengthRequired, {
    //       duration: 2000,
    //     });
    //   }
    // }
    if (workerForm.formvalues.mobile === "") {
      toaster.notify(local.MobileRequired, {
        duration: 2000,
      });
    }

    if (workerForm.formvalues.mobile !== "") {
      if (validatePhoneLength(workerForm.formvalues.mobile) === false)
        toaster.notify(local.EgPhoneLength, {
          duration: 2000,
        });
    }
    if (workerForm.formvalues.email === "") {
      toaster.notify(local.EmailRequired, {
        duration: 2000,
      });
    }
    // if (workerForm.formvalues.job_title === null) {
    //   toaster.notify(local.JobTitleRequired, {
    //     duration: 2000,
    //   });
    // }

    if (workerForm.formvalues.password_confirmation === "") {
      toaster.notify(local.PasswordConfrimRequired, {
        duration: 2000,
      });
    }
    if (workerForm.formvalues.address === "") {
      toaster.notify(local.AddressRequired, {
        duration: 2000,
      });
    }

    if (
      workerForm.formvalues.company_id !== null &&
      workerForm.formvalues.user_name !== "" &&
      workerForm.formvalues.password !== "" &&
      workerForm.formvalues.name !== "" &&
      // workerForm.formvalues.ssn !== "" &&
      // getlength(workerForm.formvalues.ssn) === 14 &&
      workerForm.formvalues.mobile !== "" &&
      validatePhoneLength(workerForm.formvalues.mobile) === true &&
      workerForm.formvalues.email !== "" &&
      // workerForm.formvalues.job_title !== null &&
      workerForm.formvalues.password_confirmation !== "" &&
      workerForm.formvalues.address !== "" &&
      // workerForm.formvalues.image !== null &&
      workerForm.formvalues.worker_type !== null
    ) {
      return true;
    }
  }
};

export const onSave = (
  e,
  createORUpdateWorker,
  workerForm,
  workerObj,
  isModal
) => {
  e.preventDefault();

  if (validateInputs(workerForm) === true) {
    createORUpdateWorker(
      workerForm.formvalues,
      workerForm.index,
      workerObj.workerItem,
      isModal
    );
  }
};

export const onChangePoint = (e, prop, general) => {
  if (e !== null) {
    general(
      [
        {
          prop: prop,
          value: e,
        },
      ],
      UPDATE_WORKER_PROPS
    );
  } else {
    general(
      [
        {
          prop: prop,
          value: [],
        },
      ],
      UPDATE_WORKER_PROPS
    );
  }
};

export const renderRemoveButton = (index, general) => {
  return (
    <a
      style={{ cursor: "pointer" }}
      onClick={() =>
        general(
          [
            { prop: "workerForm.formvalues.work_field_id." + index },
            { prop: "workerForm.formvalues.points." + index },
          ],
          DELETE_WORKER_PROPS
        )
      }
    >
      <FiXCircle style={{ color: "#0cdbc7", fontSize: "17px" }} />
    </a>
  );
};

export const renderWorkField = (
  prop,
  value,
  general,
  id,
  companyWfOptions,
  loadCompaniesWFOptions,
  col,
  emptyProp,
  isCreate
) => {
  return (
    <AsyncPaginate
      options={
        isCreate !== undefined
          ? isCreate === "update"
            ? companyWfOptions
            : null
          : null
      }
      key={value}
      placeholder={local.WorkPlaces}
      className={col !== null ? "mt-" + col : null}
      isMulti={true}
      styles={selectStyle3}
      loadOptions={(name, loadedOptions, page) =>
        loadCompaniesWFOptions(name, loadedOptions, page, id)
      }
      value={value}
      isClearable={true}
      onChange={(e) =>
        e !== null
          ? general([{ prop: prop, value: e }], UPDATE_WORKER_PROPS)
          : general(
              [
                { prop: prop, value: null },
                { prop: emptyProp.p, value: emptyProp.v },
              ],
              UPDATE_WORKER_PROPS
            )
      }
      getOptionLabel={(op) => op.label}
      getOptionValue={(op) => op.value}
      additional={{
        page: 1,
      }}
      cacheUniqs={[id]}
      onFocus={() =>
        id === null
          ? toaster.notify(local.ChooseCompany, {
              duration: 2000,
            })
          : null
      }
    />
  );
};

export const renderWorkerSeelct = (
  prop,
  value,
  action,
  general,
  loadWorkerOptions,
  workerOptions,
  id,
  col,
  iscreate,
  isMultiple = false,
  pushTokenFilter,
  dependentProps = [],
  active = undefined,
  filterOption, // (option, rawInput) => Boolean
  placeholder,
  exactValue = false
) => {
  return (
    <>
      <AsyncPaginate
        options={
          iscreate !== undefined
            ? iscreate === "update"
              ? workerOptions
              : null
            : null
        }
        key={value}
        value={
          exactValue
            ? value
            : isMultiple
            ? value
            : getDeafultItem(value, workerOptions)
        }
        placeholder={placeholder || local.Worker}
        className={col !== null ? "mt-" + col : null}
        styles={selectStyle3}
        loadOptions={(name, loadedOptions, page) =>
          loadWorkerOptions(
            name,
            loadedOptions,
            page,
            id,
            pushTokenFilter,
            active
          )
        }
        isMulti={isMultiple}
        isClearable={true}
        onChange={(e) => {
          if (dependentProps.length > 0) {
            let convertDependentPropToDispatch = [];
            for (let i = 0; i < dependentProps.length; i++) {
              convertDependentPropToDispatch.push({
                prop: dependentProps[i],
                value: null,
              });
            }
            general([...convertDependentPropToDispatch], action);
          }
          console.log(e);
          e !== null
            ? general(
                [
                  {
                    prop: prop,
                    value: isMultiple ? e : exactValue ? e : e.value,
                  },
                ],
                action
              )
            : general([{ prop: prop, value: null }], action);
        }}
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
        filterOption={filterOption}
        cacheUniqs={[id]}
        onFocus={() =>
          id === null
            ? toaster.notify(local.ChooseCompany, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const unsetWorkerListData = (general) => {
  general([], CLEAR_WORKER_STATE);

  general(
    [
      {
        prop: "pointsOptions",
        value: [],
      },
    ],
    UPDATE_REQUESTS_PROPS
  );
  general(
    [
      {
        prop: "companyWfOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_WORK_FIELD_PROPS
  );
  general(
    [
      {
        prop: "companyOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};

export const addWorkerFromFile = (
  workerData,
  setWorkerDataState,
  workersDataState
) => {
  const {
    company,
    branch,
    workFields,
    name,
    userName,
    email,
    address,
    password,
    confirmPassword,
    mobile,
    nationalID,
    jobTitle,
    employeeType,
    index,
  } = workerData;

  const workFieldIDs = workFields.map((workField) => workField.id);

  const formattedWorkerData = {
    company_id: company.id,
    branch_id: branch.id,
    work_field_id: workFieldIDs,
    name,
    user_name: userName,
    email,
    address,
    mobile,
    password,
    password_confirmation: confirmPassword,
    ssn: nationalID,
    job_title: jobTitle.value,
    worker_type: employeeType.value,
  };

  setWorkerDataState(`tableData.${index}.status`, local.Pending);

  addWorker(formattedWorkerData)
    .then((res) => {
      if (res.data.code === 200) {
        setWorkerDataState(`tableData.${index}.status`, local.saved);
      } else {
        const errors = Object.values(res.data.error);
        setWorkerDataState(`tableData.${index}.status`, errors[0]);
      }
    })
    .catch((err) => {
      setWorkerDataState(`tableData.${index}.status`, local.SomethingWentWrong);
    })
    .finally(() => {
      const incrementIsLoading = (prevState) => {
        return prevState.isLoading + 1;
      };

      setWorkerDataState("isLoading", incrementIsLoading);
    });
};

const validateWorkerData = (data) => {
  const {
    company,
    branch,
    workFields,
    name,
    userName,
    address,
    email,
    password,
    confirmPassword,
    nationalID,
    mobile,
    jobTitle,
    employeeType,
  } = data;
  if (
    company &&
    branch &&
    workFields &&
    name &&
    userName &&
    address &&
    email &&
    password &&
    confirmPassword &&
    nationalID &&
    mobile &&
    jobTitle &&
    employeeType
  )
    return true;
  else return false;
};

export const addWorkersData = (workersDataState, setWorkersDataState) => {
  const { tableData } = workersDataState;
  if (tableData.length > 0) {
    for (let i = 0; i < tableData.length; i++) {
      const currentWorker = tableData[i];
      const { index } = currentWorker;
      if (validateWorkerData(currentWorker)) {
        addWorkerFromFile(currentWorker, setWorkersDataState, workersDataState);
      } else
        setWorkersDataState(
          `tableData.${index}.status`,
          local.SomeDataIsMissing
        );
    }
  }
};
