import { FaAngleRight, FaAngleLeft } from 'react-icons/fa';

import { getLanguage, local } from "../../../lang/local";

const UsageLinkDiv = ({text, link, history}) => {

  const language = getLanguage() === "ar" ? "arabic" : "english"

  return (
    <div
      className={`col-12 col-lg-8 usage-link-container usage-link-container-shadow-${language}`}
      onClick={() => history.push(link)}
    >
      <div>{local.HowTo} {text}</div>
      <div className="navigate-to-link-icon"> 
        {getLanguage() === "ar" ? <FaAngleLeft/> : <FaAngleRight/>}
      </div>
    </div>
  )
}

export default UsageLinkDiv;