import React, { Component } from "react";
import "./App.css";
import "toasted-notes/src/styles.css";
import "antd/dist/antd.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-phone-input-2/lib/style.css";
import "react-awesome-lightbox/build/style.css";
import "flatpickr/dist/themes/airbnb.css";
import "leaflet/dist/leaflet.css";
import "react-leaflet-fullscreen/dist/styles.css";
import "react-tippy/dist/tippy.css";
import "react-confirm-alert/src/react-confirm-alert.css";
import "./assets/style.css";

import { changeLanguage } from "./lang/local";
import { Switch, Route } from "react-router-dom";
import Login from "./pages/Login/Login";
import AboutUs from "./pages/AboutUs/AboutUs";
import UsageHome from "./pages/Usage/UsageHome/UsageHome";
import NoConnection from "./utils/NoConnection";
import UsageLinks from "./pages/Usage/UsageLinks/UsageLinks";
import UsageVideo from "./pages/Usage/UsageVideo/UsageVideo";
import GiftsAndSamples from "./pages/GiftsAndSamples/GiftsAndSamples";
import AddGiftPage from "./pages/GiftsAndSamples/AddGiftPage";
import WorkerProfile from "./pages/Workers/WorkerProfile";
import PointProfile from "./pages/Requests/PointProfile";
import AuthenticatedRoute from "./routing/AuthenticatedRoute";
import Layout from "./routing/Layout/Layout";
//Route Ticket
import Ticket from "./pages/Requests/Ticket";

class App extends Component {
  constructor(props) {
    super(props);
    changeLanguage();
  }
  render() {
    return (
      <Switch>
        <AuthenticatedRoute path="/dashboard" component={Layout} />
        <Route
          exact
          path="/no_connection"
          render={(props) => <NoConnection {...props} />}
        />
        <Route
          exact
          path="/point-profile/:id"
          render={(props) => <PointProfile {...props} />}
        />

        <Route exact path="/Ticket">
          <Ticket />
        </Route>

        <Route
          exact
          path="/worker-profile/:id"
          render={(props) => <WorkerProfile {...props} />}
        />

        <Route exact path="/" render={(props) => <Login {...props} />} />
        <Route
          exact
          path="/privacy_policy"
          render={(props) => <AboutUs {...props} />}
        />

        <Route
          exact
          path="/usage"
          render={(props) => <UsageHome {...props} />}
        />

        <Route
          exact
          path="/usage-links-dashboard"
          render={(props) => <UsageLinks {...props} />}
        />

        <Route
          exact
          path="/usage-links-mobile_app"
          render={(props) => <UsageLinks {...props} />}
        />

        <Route
          exact
          path="/usage-video/:id"
          render={(props) => <UsageVideo {...props} />}
        />

        <Route
          exact
          path="/gifts-and-samples"
          render={(props) => <GiftsAndSamples {...props} />}
        />

        <Route
          exact
          path="/add-gifts-and-samples"
          render={(props) => <AddGiftPage {...props} />}
        />
      </Switch>
    );
  }
}

export default App;
