import { Bar } from "react-chartjs-2";

const BarChartStacked = ({
  barThickness,
  barLabels,
  datasetLabels,
  datasets,
  colors,
}) => {
  const renderData = () => {
    let setOfData = [];
    for (let i = 0; i < datasets.length; i++) {
      let borderColorArr = [];
      for (let j = 0; j < barLabels.length; j++) {
        borderColorArr.push(colors[i]);
      }
      setOfData.push({
        label: datasetLabels[i],
        fill: false,
        barPercentage: 0.5,
        barThickness: barThickness,
        data: datasets[i],
        backgroundColor: colors[i],
        borderColor: borderColorArr,
        borderWidth: 1,
      });
    }
    let data = {
      labels: barLabels,
      datasets: setOfData,
    };
    return data;
  };

  // const data = renderData();

  // const data = {
  //   labels: ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug'],
  //   datasets: [{
  //     label: datasetLabels[0],
  //     fill: false,
  //     barPercentage: 0.5,
  //     barThickness: barThickness,
  //     data: [10, 20, 30, 40, 50, 45, 70, 80],
  //     backgroundColor: '#cecece',
  //     borderColor: ['#cecece', '#cecece', '#cecece', '#cecece', '#cecece', '#cecece', '#cecece', '#cecece'],
  //     borderWidth: 1
  //   },
  //   {
  //     label: datasetLabels[1],
  //     fill: false,
  //     barPercentage: 0.5,
  //     barThickness: barThickness,
  //     data: [10, 20, 30, 40, 50, 45, 70, 80],
  //     backgroundColor: '#ffc33c',
  //     borderColor: ['#ffc33c', '#ffc33c', '#ffc33c', '#ffc33c', '#ffc33c', '#ffc33c', '#ffc33c', '#ffc33c'],
  //     borderWidth: 1
  //   },
  //   {
  //     label: datasetLabels[2],
  //     fill: false,
  //     barPercentage: 0.5,
  //     barThickness: barThickness,
  //     data: [10, 20, 30, 40, 50, 45, 70, 80],
  //     backgroundColor: '#14a9cc',
  //     borderColor: ['#14a9cc', '#14a9cc', '#14a9cc', '#14a9cc', '#14a9cc', '#14a9cc', '#14a9cc', '#14a9cc'],
  //     borderWidth: 1
  //   },
  //   {
  //     label: datasetLabels[3],
  //     fill: false,
  //     barPercentage: 0.5,
  //     barThickness: barThickness,
  //     data: [10, 20, 30, 40, 50, 45, 70, 80],
  //     backgroundColor: '#09c0aa',
  //     borderColor: ['#09c0aa', '#09c0aa', '#09c0aa', '#09c0aa', '#09c0aa', '#09c0aa', '#09c0aa', '#09c0aa'],
  //     borderWidth: 1
  //   }]
  // }

  return (
    <div style={{ width: "100%", height: "25rem" }}>
      <Bar
        data={renderData}
        width={"100%"}
        height={"100%"}
        options={{
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {
                stacked: true,
              },
            ],
            yAxes: [
              {
                stacked: true,
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            position: "bottom",
            labels: {
              usePointStyle: true,
              boxWidth: 10,
            },
          },
          title: {
            display: true,
            text: "Daily Visits",
            position: "top",
          },
        }}
      />
    </div>
  );
};

export default BarChartStacked;
