import React, { Component } from 'react';
import { Row, Col, Card, CardBody } from 'reactstrap';
import { Alert } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { local } from '../../lang/local';
import OuterWorkerSearch from './OuterWorkerSearch';
class OuterWorker extends Component {
  render() {
    const { history, trackingObj, general } = this.props;
    return (
      <div className='conatiner'>
        <Row style={{ height: '100%' }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-4'>
            <Card className='cardCreate ml-2 mr-2'>
              <div className='mt-2 mr-5 ml-5'>
                <CardBody className='trans'>
                  <OuterWorkerSearch
                    general={general}
                    trackingObj={trackingObj}
                    history={history}
                  />
                  <br /> <br />
                  <Row className='mt-2'>
                    {trackingObj.outerWorkers !== null ? (
                      trackingObj.outerWorkers.length > 0 ? (
                        trackingObj.outerWorkers.map((worker) => {
                          if (trackingObj.isOuterWorkerSearch === true) {
                            if (worker.isFound !== undefined) {
                              return (
                                <Col xl={6} className='mt-3'>
                                  <Alert
                                    style={{
                                      color: '#00beac',
                                      background: 'rgba(0, 190, 172,0.1)',
                                      borderRadius: '10px',
                                      border: 'none',
                                    }}
                                  >
                                    <p
                                      style={{ margin: '5px' }}
                                      className='text-center'
                                    >
                                      {worker.name + ' - ' + local.Outside}
                                    </p>
                                  </Alert>
                                </Col>
                              );
                            }
                          } else {
                            return (
                              <Col xl={6} className='mt-3'>
                                <Alert
                                  style={{
                                    color: '#00beac',
                                    background: 'rgba(0, 190, 172,0.1)',
                                    borderRadius: '10px',
                                    border: 'none',
                                  }}
                                >
                                  <p
                                    style={{ margin: '5px' }}
                                    className='text-center'
                                  >
                                    {worker.name + ' - ' + local.Outside}
                                  </p>
                                </Alert>
                              </Col>
                            );
                          }
                        })
                      ) : (
                        <Col className='text-center'>
                          <p>{local.NoWorkerOutside}</p>
                        </Col>
                      )
                    ) : (
                      <Col className='text-center'>
                        <p>{local.NoWorkerOutside}</p>
                      </Col>
                    )}
                  </Row>
                </CardBody>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ LiveTrackingR }) => {
  return {
    trackingObj: LiveTrackingR.trackingObj,
  };
};

export default connect(mapStateToProps, actions)(OuterWorker);
