import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';

import * as actions from '../../redux/actions/index';
import CoverageListSearch from './CoverageListSearch';
import { useEffect } from 'react';
import CoverageListTable from './CoverageListTable';

const CoverageList = (props) => {
  const { clearSearchForm } = props;

  useEffect(() => {
    return clearSearchForm;
  }, []);

  return (
    <div className='page-wrapper'>
      <Row className='mt-2'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <CoverageListSearch {...props} />

          <CoverageListTable {...props} />
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  SearchFormReducer,
  CompanyR,
  BranchR,
  CompanyWorkFieldR,
  RequestsR,
  WorkerR,
  CoverageListReducer,
}) => ({
  searchForm: SearchFormReducer,
  companyOptions: CompanyR.companyOptions,
  branchOptions: BranchR.branchOptions,
  companyWfOptions: CompanyWorkFieldR.companyWfOptions,
  pointsOptions: RequestsR.pointsOptions,
  workerOptions: WorkerR.workerOptions,
  CoverageListReducer,
});

export default connect(mapStateToProps, actions)(CoverageList);
