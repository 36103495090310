import { getLanguage } from "../../lang/local";
import {
  CLEAR_BRANCHES_STATE,
  UPDATE_BRANCHES_PROPS,
  MERGE_BRANCHES_PROPS,
  DELETE_BRANCHES_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  branchObj: { branchItem: null, isLoading: false },
  sorting: { column: "", order: "desc" },
  branchForm: {
    isLoading: false,
    formvalues: {
      parent_id: null,
      name: { ar: "", en: "" },
      address: "",
      mobile: "",
      manager_name: "",
      company_id: null,
    },
    index: null,
    isCreate: "create",
  },
  branchOptions: [],
  parentBranchOptions:[],
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],
    activeIndex: 0,
    tabAction: "next",
  },

  searchForm: {
    company_id: null,
    mobile: "",
    name: "",
    page: 1,
    from: "",
    to: "",
  },

  fileOptions: {
    savedData: null,
    importedData: null,
    isLoading: false,
    errorData: null,
    headerData: null,
  },
  isLoadingSearch: false,
  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_BRANCHES_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_BRANCHES_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_BRANCHES_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_BRANCHES_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
