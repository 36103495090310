import React from "react";
import { Card, Row, Table, Col } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderTableHeadItem,
  renderDate,
  renderNumber,
  renderPagination,
  renderCreateButton,
  getPermissionValue,
  renderEditButton,
  renderEditTitle,
} from "../../utils/GeneralFunctions";
import { UPDATE_STATE_PROPS } from "../../redux/actions/types";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

import { leftlStyle } from "../../utils/GeneralStyle";

const StateTable = (props) => {
  const { stateObj, general, sorting, searchForm, getStates } = props;

  const onEdit = (e, item, index) => {
    e.preventDefault();
    general(
      [
        { prop: "stateForm.formvalues.name", value: item.name },
        { prop: "stateForm.isLoading", value: false },
        { prop: "stateForm.formvalues.id", value: item.id },
        { prop: "modale.isOpen", value: true },
        { prop: "stateForm.index", value: index },
        { prop: "modale.title", value: local.UpdateProvince },
        { prop: "modale.actionType", value: "EditProvince" },
      ],
      UPDATE_STATE_PROPS
    );

    if (item.parent !== null) {
      general(
        [{ prop: "stateForm.formvalues.parent_id", value: item.parent.id }],
        UPDATE_STATE_PROPS
      );
    }
  };

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: "modale.title", value: local.AddState },
        { prop: "modale.isOpen", value: true },
        { prop: "modale.actionType", value: "AddState" },
      ],
      UPDATE_STATE_PROPS
    );
  };
  const renderBody = () => {
    if (stateObj.stateItem !== null) {
      return stateObj.stateItem.data.map((state, index) => {
        return (
          <tr key={index}>
            <td className="td_table">{renderNumber(state.id)}</td>
            <td className="td_table">{state.name[local.lang]}</td>

            <td className="td_table">{renderDate(state.created_at)}</td>

            {renderEditButton(
              getPermissionValue()["states/update/{id}"],
              onEdit,
              state,
              index
            )}
          </tr>
        );
      });
    }
  };
  return (
    <Card className="cardStyle mt-2">
      {renderCreateButton(
        getPermissionValue()["states/store"],
        onCreate,
        local.AddState
      )}
      <Row>
        <Col xl={12}>
          <Table
            responsive
            className="table_list mt-4 mb-4"
            hover
            // style={{ borderTopColor: 'white', border: 'none' }}
            id="state_table"
          >
            <thead>
              <tr className="table_head">
                {renderTableHeadItem(
                  local.Id,
                  stateObj.stateItem,
                  "id",
                  "stateObj.stateItem.data",
                  UPDATE_STATE_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Name,
                  stateObj.stateItem,
                  "name",
                  "stateObj.stateItem.data",
                  UPDATE_STATE_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.DateAdded,
                  stateObj.stateItem,
                  "state.created_at",
                  "stateObj.stateItem.data",
                  UPDATE_STATE_PROPS,
                  general,
                  sorting
                )}
                {renderEditTitle("states/update/{id}")}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>
      {stateObj.stateItem !== null ? (
        <Row className="mt-3 mb-1 alignItem" style={{ direction: "ltr" }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              style={leftlStyle}
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="state_table"
              filename={local.States}
              sheet="tablexls"
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + " : "}</span>
              <span className="green_text">
                {stateObj.stateItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}
      {renderPagination(stateObj.stateItem, searchForm, getStates)}
    </Card>
  );
};

export default StateTable;
