import { getLanguage } from "../lang/local";
import { PLACE_TYPE_OPTIONS } from "../components/StaticOptionsSelect/staticOptions";

const convertOptionsArrToObj = (arr = [], indexedBy = "id") => {
  const obj = {};
  for (let i = 0; i < arr.length; i++) {
    const option = arr[i];
    obj[option[indexedBy]] = option;
  }
  return obj;
};

const optionsIndexedByTypeValue = convertOptionsArrToObj(
  PLACE_TYPE_OPTIONS,
  "value"
);

export const renderAppropriatePin = (type) => {
  if (!type) return null;
  return optionsIndexedByTypeValue[type].icon;
};

export const renderAppropriatePlaceType = (type) => {
  if (!type) return null;
  const lang = getLanguage();
  return optionsIndexedByTypeValue[type].label[lang];
};
