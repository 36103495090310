import React from "react";
import { Container, Row, Col, Button, Badge } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import report_icon from "../../assets/img/report_icon.png";
import logo_card_report from "../../assets/img/logo_card_report.png";
import ImageSlider from "./ImageSlider";
import { getDeafultItem, renderDate } from "../../utils/GeneralFunctions";
import report_noimage from "../../assets/img/report_noimage.png";
import { numberToArabic } from "number-to-arabic";

const SignleReport = (props) => {
  const { report_item, searchForm, companyWfOptions } = props;
  const renderPhoto = (report, length) => {
    let items = Object.entries(report);

    let images = items.filter(
      (im) => im[0].includes("image") || im[0].includes("photo")
    );
    if (length === undefined) {
      if (images.length > 0) {
        return images[0][1];
      } else {
        return report_noimage;
      }
    }
    if (length !== undefined) {
      if (images.length > 0) {
        return images.length;
      }
      {
        return 0;
      }
    }
  };

  const renderPhotos = (report) => {
    let items = Object.entries(report);
    let images = items.filter(
      (im) => im[0].includes("image") || im[0].includes("photo")
    );
    if (images.length > 0) {
      return images;
    }
  };

  return (
    <Container>
      <Row className="ml-3 mr-3">
        <Col xl={2} lg={2}>
          <div>
            <img
              src={
                report_item.worker.image !== null
                  ? report_item.worker.image
                  : report_noimage
              }
              style={{ width: "70%", height: "70%" }}
            />
          </div>
        </Col>
        <Col>
          <Row className="mt-2">
            <Col xl={2} lg={2}>
              <p className="green_text">{local.worker_name}</p>
            </Col>
            <Col xl={2} lg={2}>
              <p className="blue_text">{report_item.worker.name}</p>
            </Col>
            <Col xl={2} lg={2}>
              <p className="green_text">{local.date}</p>
            </Col>
            <Col xl={3} lg={3}>
              <p className="blue_text">{renderDate(report_item.created_at)}</p>
            </Col>
            <Col xl={3} lg={3}>
              <Button className="sing_report_btn_blue">
                <div>
                  <p style={{ display: "inline", fontSize: "10px" }}>
                    {getLanguage() === "en"
                      ? renderPhoto(report_item.report, true)
                      : numberToArabic(renderPhoto(report_item.report, true))}
                  </p>
                  &nbsp;
                  <img
                    src={logo_card_report}
                    style={{ width: "20%", height: "20%" }}
                  />
                </div>
              </Button>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col xl={2} lg={2}>
              <p className="green_text">work place</p>
            </Col>

            <Col xl={2} lg={2}>
              <p className="blue_text">
                {report_item.work_field.name[getLanguage()]}
              </p>
            </Col>
            <Col xl={3} lg={3}>
              <Badge className="sing_report_btn_green">
                <div>
                  <p style={{ display: "inline", fontSize: "10px" }}>
                    {local.DayReport}
                  </p>
                  &nbsp;
                  <img
                    src={report_icon}
                    style={{ width: "20%", height: "20%" }}
                  />
                </div>
              </Badge>
            </Col>
          </Row>
          <Row>
            <Col className="mt-5" xl={8}>
              <Container>
                {Object.entries(report_item.report).map((re, index) => {
                  return !re[0].includes("image") &&
                    !re[0].includes("photo") ? (
                    <Row className="mt-3" style={{ fontSize: "11px" }}>
                      <Col xl={2}>
                        {/* <p className="green_text">{re[0]}</p> */}
                        <p className="green_text">
                          {report_item.template.template[index].name}
                        </p>
                      </Col>
                      <Col xl={10}>
                        <p className="blue_text">{re[1]}</p>
                      </Col>
                    </Row>
                  ) : null;
                })}
              </Container>
            </Col>
            <Col
              xl={4}
              style={{
                padding: "0px",
                margin: "0px",
              }}
            >
              <ImageSlider images={renderPhotos(report_item.report)} />
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  );
};

export default SignleReport;
