import React from "react";
import AsyncSelect from "react-select/async";
import { Table } from "reactstrap";
import { local } from "../lang/local";
import toaster from "toasted-notes";

import {
  getDeafultItem,
  promiseOptions,
  renderPhotoCard,
} from "./GeneralFunctions";
import { selectStyle3 } from "./GeneralStyle";
const FileDataList = (props) => {
  const {
    selectOptions,
    data,
    header,
    general,
    action,
    select_indexs,
    hasSelect,
    logo_index,
  } = props;
  const renderSelect = (row, value, index, col_index, Options) => {
    let h = header;
    let company = local.Company;

    if (Options !== undefined) {
      if (Options !== null) {
        return (
          <div style={{ minWidth: "200px" }}>
            <AsyncSelect
              placeholder={Options.title}
              styles={selectStyle3}
              isDisabled={row[row.length - 1] === true ? true : false}
              value={
                Options.isSingle === true
                  ? getDeafultItem(data[index][col_index], Options.options)
                  : data[index][col_index]
              }
              loadOptions={() => promiseOptions(Options.options)}
              defaultOptions={Options.options}
              onChange={(e) => onChangeSelect(e, index, col_index, Options)}
              getOptionLabel={(op) => op.label}
              getOptionValue={(va) => va.value}
              isClearable={true}
              isSearchable={true}
              isMulti={Options.isSingle === true ? false : true}
              onFocus={() =>
                Options.depend_id !== undefined
                  ? row[h.indexOf(company)] === null
                    ? toaster.notify(Options.errorMessage, {
                        duration: 2000,
                      })
                    : null
                  : null
              }
            />
          </div>
        );
      }
    }
  };

  const onChangeSelect = (e, index, col_index, Options) => {
    if (e !== null) {
      general(
        [
          {
            prop: "fileOptions.savedData." + index + "." + col_index,
            value: Options.isSingle === true ? e.value : e,
          },
          {
            prop: "fileOptions.importedData." + index + "." + col_index,
            value: Options.isSingle === true ? e.value : e,
          },
        ],
        action
      );
      if (Options.endPoint !== undefined) {
        if (typeof Options.endPoint === "object") {
          return Object.entries(Options.endPoint).map((func) => {
            if (Array.isArray(e)) {
              return func[1](e[e.length - 1].value, true);
            } else {
              return func[1](e.value, true);
            }
          });
        } else {
          if (Array.isArray(e)) {
            Options.endPoint(e[e.length - 1].value, true);
          } else {
            Options.endPoint(e.value);
          }
        }
      }
    } else {
      general(
        [
          {
            prop: "fileOptions.savedData." + index + "." + col_index,
            value: null,
          },
          {
            prop: "fileOptions.importedData." + index + "." + col_index,
            value: null,
          },
        ],
        action
      );
    }
  };

  const renderItems = (row, index) => {
    return row.map((col, col_index) => {
      if (col !== true) {
        if (select_indexs !== undefined) {
          if (
            select_indexs.find((i) => i === col_index) &&
            hasSelect !== null &&
            (col === null ||
              typeof col === "number" ||
              typeof col === "object" ||
              (Array.isArray(col) && !col[0] instanceof File)) &&
            logo_index !== undefined &&
            logo_index !== col_index
          ) {
            return (
              <td className="td_table" style={{ width: "40em !imporant" }}>
                {renderSelect(
                  row,
                  col,
                  +index,
                  col_index,
                  selectOptions[col_index]
                )}
              </td>
            );
          } else if (
            select_indexs.find((i) => i === col_index) &&
            (col === false || col[0] instanceof File)
          ) {
            return renderPhotoCard(
              general,
              "fileOptions.importedData." + index + "." + col_index,
              action,
              col,
              "fileOptions.savedData." + index + "." + col_index,
              true
            );
          } else {
            return <td className="td_table">{col}</td>;
          }
        } else {
          return <td className="td_table">{col}</td>;
        }
      }
    });
  };

  return (
    <Table
      responsive
      className="table_list mt-4 mb-4"
      hover
      style={{ borderTopColor: "white", border: "none" }}
      style={{ height: "300px" }}
    >
      <thead>
        <tr className="table_head">
          {header.map((head) => {
            return <th className="td_table">{head}</th>;
          })}

          <th className="td_table">{local.DataStatus}</th>
        </tr>
      </thead>

      <tbody>
        {data.map((row, index) => {
          return (
            <tr
              style={{
                background: row[row.length - 1] === true ? "#eee" : "none",
              }}
            >
              {renderItems(row, index)}

              <td
                className={
                  row[row.length - 1] === true
                    ? "td_table green_text"
                    : "td_table blue_tex"
                }
              >
                {row[row.length - 1] === true ? local.saved : local.NotSaved}
              </td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

export default FileDataList;
