import React, { useEffect, useState } from 'react';
import { Card, Row, Col } from 'reactstrap';
import { renderGooglePolygon } from '../../utils/GeneralFunctions';
import { ZoomableShapes } from 'mapy_sdk/lib/mapyTypes';

const WorkFieldMapMapy = (props) => {
  const { companyWfObj, workFieldMapZoom, mapy } = props;

  useEffect(() => {
    const mapContainer = document.getElementById('mapy');
    mapy.initMap(mapContainer, {
      center:
        companyWfObj.lat === null
          ? [30.1946646, 31.5485576]
          : [companyWfObj.lat, companyWfObj.lng],
      zoom: 5,
      scrollWheelZoom: true,
      fullscreencontrol: true,
    });
    viewWorkFieldEvent();
  }, []);

  useEffect(() => {
    return () => {
      mapy.popMap();
    };
  }, []);

  useEffect(() => {
    drawPolygons();
  }, [companyWfObj.companyWfItem]);

  const [mapZoom, setMapZoom] = useState(companyWfObj.lat === null ? 4 : 12);

  const viewWorkFieldEvent = () => {
    const viewElements = document.getElementsByClassName('view-workField');

    for (let i = 0; i < viewElements.length; i++) {
      viewElements[i].addEventListener(
        'click',
        function (e) {
          setMapZoom(14);
        },
        false
      );
    }
  };

  const drawPolygons = () => {
    if (companyWfObj.companyWfItem !== null) {
      if (companyWfObj.companyWfItem.data.length > 0) {
        mapy.removeGroup('areas');
        companyWfObj.companyWfItem.data.map((poly, index) => {
          let polygon = renderGooglePolygon(poly.location);
          console.log(
            '%cWorkFieldMapMapy.js line:78 poly',
            'color: #007acc;',
            poly
          );
          mapy.AddPolygon(
            {
              boundaries: polygon,
              color: '#00FFE7',
              fillColor: '#009F90',
              fillOpacity: 0.6,
              weight: 1,
              groupName: 'areas',
            },
            poly.id,
            false
          );
        });
        mapy.zoomToFeatures([{ id: 'areas', type: ZoomableShapes.group }]);
      }
    }
  };

  return (
    <Card
      className='cardStyle'
      style={{
        position: 'relative',
        width: '100%',
        height: '100%',
      }}
    >
      <Row>
        <Col>
          <div
            id='mapy'
            style={{
              width: '100%',
              height: '40em',
              borderRadius: '10px',
            }}
          ></div>
        </Col>
      </Row>
    </Card>
  );
};

export default WorkFieldMapMapy;
