import React from "react";

import { ProgressBar } from "../../components/ProgressBar/ProgressBar";
import { Paper } from "@mui/material";
import { local } from "../../lang/local";
import region from "../../assets/img/home/region.png";
import city from "../../assets/img/home/cities.png";
import orders from "../../assets/img/home/orders.png";
import employees from "../../assets/img/home/employees.png";
import marchant from "../../assets/img/home/marchants.png";
import { Row, Col } from "reactstrap";

export const Statistics = ({
  regions,
  cities,
  workers,
  requests,
  merchants,
  appfeedback,
}) => {
  const getValue = (key) => {
    if (appfeedback.length > 0) {
      if (appfeedback[0][key]) {
        return +appfeedback[0][key];
      } else {
        return 0;
      }
    } else return 0;
  };
  return (
    <React.Fragment>
      <Row>
        {[
          {
            items: [{ label: "StateWorkFields", value: regions ? regions : 0 }],
            pathColor: null,
            photo: region,
          },
          {
            items: [{ label: "WorkFields", value: cities ? cities : 0 }],
            pathColor: null,
            photo: city,
          },
          {
            items: [
              { label: "CompaniesPoint", value: requests ? requests : 0 },
            ],
            pathColor: null,
            photo: orders,
          },
          {
            items: [{ label: "Employess", value: workers ? workers : 0 }],
            pathColor: null,
            photo: employees,
          },
          {
            items: [{ label: "Merchants", value: merchants ? merchants : 0 }],
            pathColor: null,
            photo: marchant,
          },
          {
            items: [
              {
                label: "Deploy",
                value: getValue("deploypositive") + getValue("deploynegative"),
              },
              {
                label: "Positive",
                value: getValue("deploypositive"),
              },
              { label: "Negative", value: getValue("deploynegative") },
            ],
            pathColor: "#0180fa",
            photo: null,
          },
          {
            items: [
              {
                label: "Cancel",
                value: getValue("cancelpositive") + getValue("cancelnegative"),
              },
              {
                label: "Positive",
                value: getValue("cancelpositive"),
              },
              {
                label: "Negative",
                value: getValue("cancelnegative"),
              },
            ],

            pathColor: "#313131",
            photo: null,
          },
          {
            items: [
              {
                label: "Visit",
                value: getValue("visitpositive") + getValue("visitnegative"),
              },
              {
                label: "Positive",
                value: getValue("visitpositive"),
              },
              { label: "Negative", value: getValue("visitnegative") },
            ],
            pathColor: "#b7100f",
            photo: null,
          },
        ].map((item, index) => {
          return (
            <Col
              xl={4}
              lg={4}
              md={6}
              sm={12}
              xs={12}
              className="mt-4"
              key={index}
            >
              <Paper elevation={0} className="prograss-card">
                <div className="prograss-card-container">
                  <div className="item item-text">
                    <ui className="progress-list">
                      {item.items.map((e, i) => {
                        return (
                          <li className="progress-list-item" key={i}>
                            <p className="label">{local[e.label]}</p>
                            <p>{e.value}</p>
                          </li>
                        );
                      })}
                    </ui>
                  </div>
                  <div className="item">
                    {item.photo ? (
                      <div className="prograss-img-container">
                        <img src={item.photo} alt="" className="imgFull" />
                      </div>
                    ) : (
                      <ProgressBar
                        value={item.value}
                        pathColor={item.pathColor}
                      />
                    )}
                  </div>
                </div>
              </Paper>
            </Col>
          );
        })}
      </Row>
    </React.Fragment>
  );
};
