import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import BranchTable from './BranchTable';
import * as actions from '../../redux/actions/index';
import {
  getMyCompany,
  checkHasOneCompany,
} from '../../utils/GeneralFunctions';
import BranchSearch from './BranchSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateBranch from './CreateUpdateBranch';
import { UPDATE_BRANCHES_PROPS } from '../../redux/actions/types';
import { unsetForm, unsetBranchListData } from './BranchesFunction';

class Branches extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { general, searchForm, getBranches, branchForm } = this.props;
    unsetBranchListData(general);
    if (checkHasOneCompany() === true) {
      searchForm.company_id = getMyCompany(general);
      branchForm.formvalues.company_id = getMyCompany(general);
      getBranches(false, searchForm);
    }
  };

  renderModalChildren = () => {
    const { history } = this.props;
    return <CreateUpdateBranch unsetForm={unsetForm} history={history} />;
  };

  renderModal = () => {
    const { modale, general, branchForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={1}
        tabs_title={modale.tabs_title}
        modale={modale}
        general={general}
        action={UPDATE_BRANCHES_PROPS}
        formvalues={branchForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      branchObj,
      sorting,
      searchForm,
      isLoadingSearch,
      getBranches,
      stateusOption,
      modaleView,
      loadCompaniesOptions,
      companyOptions, 
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <BranchSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              general={general}
              getBranches={getBranches}
              stateusOption={stateusOption}
              loadCompaniesOptions={loadCompaniesOptions}
              companyOptions={companyOptions}
            />

            <BranchTable
              general={general}
              branchObj={branchObj}
              sorting={sorting}
              history={history}
              getBranches={getBranches}
              searchForm={searchForm}
              modaleView={modaleView}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ CompanyR, BranchR }) => {
  return {
    branchObj: BranchR.branchObj,
    modale: BranchR.modale,
    branchForm: BranchR.branchForm,
    sorting: BranchR.sorting,
    searchForm: BranchR.searchForm,
    modaleView: BranchR.modaleView,
    isLoadingSearch: BranchR.isLoadingSearch,
    stateusOption: BranchR.stateusOption,
    companyOptions: CompanyR.companyOptions,
  };
};

export default connect(mapStateToProps, actions)(Branches);
