import React, { Component } from 'react';
import point2 from '../assets/img/point2.svg';
import { isPointInPolygon } from './GeneralFunctions';
import toaster from 'toasted-notes';
import { local } from '../lang/local';
import { Map, Polygon, Marker, Popup } from 'react-leaflet';
import { GoogleLayer } from 'react-leaflet-google-v2';
import FullscreenControl from 'react-leaflet-fullscreen';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import L from 'leaflet';
import MapDrawing from './MapDrawing';
import { GOOGLE_KEY } from './StaticKeys';
import new_marker from '../assets/img/new_marker.svg';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import { renderAppropriatePin, renderAppropriatePlaceType } from './MapPinImg';
import CustomInfoWindow from '../components/InfoWindow/InfoWindow';
import GENERAL_MARKER from '../assets/img/MapPin/general marker.png';

class GeneralMap extends Component {
  componentWillUnmount() {
    this.props.clearMapProps();
  }

  renderVisitngPolygon = () => {
    const { type, formvalues } = this.props;
    if (type !== undefined) {
      if (type === 'visitPage') {
        if (formvalues.work_field_id[0] !== null) {
          if (formvalues.work_field_id.length > 0) {
            return formvalues.work_field_id.map((poly) => {
              if (poly !== null) {
                let polygon = poly.location;
                let newPolygons = [];

                let removedBracts = polygon
                  .split('(')[2]
                  .split(')')[0]
                  .split(',');
                removedBracts.map((poly) => {
                  newPolygons.push([+poly.split(' ')[1], +poly.split(' ')[0]]);
                });

                return <Polygon positions={newPolygons} color='#00beac' />;
              }
            });
          }
        }
      }
    }
  };
  renderWorkerPolygon = () => {
    const { workerPolygons } = this.props;
    console.log('renderWorkerPolygons: ', workerPolygons);
    if (workerPolygons !== undefined) {
      if (workerPolygons !== null) {
        if (workerPolygons.length > 0) {
          return workerPolygons.map((poly) => {
            if (poly !== null) {
              let polygon = poly.location;
              let newPolygons = [];

              let removedBracts = polygon
                .split('(')[2]
                .split(')')[0]
                .split(',');
              removedBracts.map((poly) => {
                newPolygons.push([+poly.split(' ')[1], +poly.split(' ')[0]]);
              });

              return <Polygon positions={newPolygons} color='#00beac' />;
            }
          });
        }
      }
    }
  };
  renderPolygon = (location) => {
    const { drawingPolygon } = this.props;
    if (drawingPolygon !== undefined) {
      if (drawingPolygon !== null) {
        let newPolygons = [];
        let polygon = drawingPolygon;
        let removedBracts = polygon.split('(')[2].split(')')[0].split(',');
        removedBracts.map((poly) => {
          newPolygons.push([+poly.split(' ')[1], +poly.split(' ')[0]]);
        });
        return newPolygons;
      } else {
        return null;
      }
    }
  };
  rendervisitngPoints = () => {
    const { type, formvalues } = this.props;
    const pointerIcon = new L.Icon({
      iconUrl: point2,

      iconSize: [20, 20],
    });
    if (type !== undefined) {
      if (type === 'visitPage') {
        if (formvalues.points !== null) {
          if (formvalues.points[0] !== null) {
            if (formvalues.points.length > 0) {
              return formvalues.points.map((point) => {
                if (point !== null) {
                  if (point.length > 0) {
                    return point.map((p) => {
                      let lat = p.location
                        .split('(')[1]
                        .split(')')[0]
                        .split(' ')[0];
                      let lng = p.location
                        .split('(')[1]
                        .split(')')[0]
                        .split(' ')[1];
                      return (
                        <Marker
                          position={[lng, lat]}
                          anchor='bottom'
                          icon={pointerIcon}
                        ></Marker>
                      );
                    });
                  }
                }
              });
            }
          }
        }
      }
    }
  };

  renderGoToLinkMarker = () => {
    const { goToLinkCoordinates } = this.props;

    if (goToLinkCoordinates) {
      if (
        goToLinkCoordinates[0] !== 31.4177636 ||
        goToLinkCoordinates[1] !== 30.00475
      ) {
        const markerIcon = new L.Icon({
          iconUrl: GENERAL_MARKER,
          iconSize: [30, 47],
        });
        return (
          <Marker
            position={[+goToLinkCoordinates[0], +goToLinkCoordinates[1]]}
            icon={markerIcon}
          />
        );
      }
    }
  };

  renderWorkerPoints = () => {
    const { workerPoints, MapReducer } = this.props;
    const { zoom } = MapReducer;
    // const pointerIcon = new L.Icon({
    //   iconUrl: point2,
    //   iconSize: [20, 20],
    // });
    if (workerPoints !== undefined) {
      if (workerPoints !== null) {
        if (workerPoints[0] !== null) {
          if (workerPoints.length > 0) {
            let markers = workerPoints.map((wk) => {
              return wk.map((w) => {
                let lat = w.location.split('(')[1].split(')')[0].split(' ')[0];
                let lng = w.location.split('(')[1].split(')')[0].split(' ')[1];
                let pointerIcon = new L.Icon({
                  iconUrl: renderAppropriatePin(w.place_type),
                  iconSize: [30, 43],
                });
                return (
                  <Marker
                    position={[lng, lat]}
                    anchor='bottom'
                    icon={pointerIcon}
                  >
                    <Popup>
                      <CustomInfoWindow
                        placeName={w.name}
                        placeType={renderAppropriatePlaceType(w.place_type)}
                      />
                    </Popup>
                  </Marker>
                );
              });
            });
            // if (zoom <= 11) {
            //   return <MarkerClusterGroup>{markers}</MarkerClusterGroup>;
            // } else {
            //   return markers;
            // }

            return <MarkerClusterGroup>{markers}</MarkerClusterGroup>;
          }
        }
      }
    }
  };
  renderPointsInCreatePoint = () => {
    const { work_fields_points, general, action } = this.props;

    if (work_fields_points !== undefined) {
      if (work_fields_points.length > 0) {
        return work_fields_points.map((wk) => {
          let lat = wk.location.split('(')[1].split(')')[0].split(' ')[0];
          let lng = wk.location.split('(')[1].split(')')[0].split(' ')[1];
          let pointerIcon = new L.Icon({
            iconUrl: renderAppropriatePin(wk.place_type),
            // iconUrl: new_marker,
            iconSize: [30, 43],
          });
          return (
            <Marker position={[lng, lat]} anchor='bottom' icon={pointerIcon}>
              {/* <Popup>
                <center>
                  <Table striped responsive>
                    <thead>
                      <tr>
                        <th> {local.Name}</th>
                        <th> {local.Type}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <th> {wk.name}</th>
                      <th> {renderPlaceType(wk.place_type)}</th>
                    </tbody>
                  </Table>
                </center>
              </Popup> */}
              <Popup>
                <CustomInfoWindow
                  placeName={wk.name}
                  placeType={renderAppropriatePlaceType(wk.place_type)}
                />
              </Popup>
            </Marker>
          );
        });
      }
    }
  };

  renderCompanyWFPointMarker = () => {
    const { formvalues, type } = this.props;
    const pointerIcon = new L.Icon({
      iconUrl: renderAppropriatePin(formvalues.place_type),
      iconUrl: formvalues.place_type
        ? renderAppropriatePin(formvalues.place_type)
        : new_marker,
      iconSize: [30, 43],
      shadowAnchor: [20, 92],
    });
    if (type !== undefined) {
      if (formvalues.location !== null && type === 'companywfpoint') {
        let lat = formvalues.location.split('(')[1].split(')')[0].split(' ')[0];
        let lng = formvalues.location.split('(')[1].split(')')[0].split(' ')[1];
        return (
          <Marker
            position={[lng, lat]}
            anchor='bottom'
            icon={pointerIcon}
          ></Marker>
        );
      }
    }
  };

  onClick = (e) => {
    const { action, prop, general, type } = this.props;
    let lat = +e.latlng.lat;
    let lng = +e.latlng.lng;
    let point = 'POINT(' + lng + ' ' + lat + ')';

    if (type !== undefined) {
      if (type === 'companywfpoint') {
        if (this.renderPolygon()) {
          if (isPointInPolygon(lng, lat, this.renderPolygon()) === true) {
            general(
              [
                { prop: prop, value: point },
                { prop: 'modale.mapItem.mapPoints', value: [lat, lng] },
              ],
              action
            );
          } else {
            toaster.notify(local.PointOutside, {
              duration: 2000,
            });
          }
        } else {
          toaster.notify(local.SelectWorkField, {
            duration: 2000,
          });
        }
      }
    } else {
      general(
        [
          { prop: prop, value: point },
          { prop: 'modale.mapItem.mapPoints', value: [lng, lat] },
        ],
        action
      );
    }
  };

  drawPolygon = () => {
    let points = this.renderPolygon();
    if (points !== undefined) {
      if (points !== null) {
        return <Polygon positions={points} color='#00beac' />;
      }
    }
  };

  renderSearchCircle = () => {
    const { searchCircle } = this.props;

    if (searchCircle !== undefined) {
      if (searchCircle !== null) {
      }
    }
  };

  render() {
    console.log('map is rendered');
    const {
      general,
      formvalues,
      isDrawing,
      action,
      modale,
      icClickable,
      prop,
      isPolygon,
      reloadDrawing,
      mapZoom,
      editPolygon,
      MapReducer,
      setMapProps,
      goToLinkCoordinates,
      bounds,
    } = this.props;

    const { zoom } = MapReducer;

    console.log(this.props);

    return (
      <div
        style={{
          height: '100%',
          width: '100%',
          direction: 'ltr',
        }}
      >
        <Map
          style={{
            width: '100%',
            height: '100%',
            borderRadius: '10px',
            zIndex: '0',
            direction: 'ltr',
          }}
          center={modale.mapItem.mapPoints}
          bounds={bounds}
          zoom={
            mapZoom === undefined
              ? modale.mapItem.mapPoints[0] === 31.4177636
                ? 4
                : 18
              : mapZoom
          }
          // onzoomend={(e) => setMapProps([["zoom", e.target._zoom]])}
          // zoom={console.log("zoom changed")}
          onClick={icClickable === undefined ? null : this.onClick}
          onzoomlevelschange={(e) => console.log('changed')}
        >
          <FullscreenControl />
          <GoogleLayer googlekey={GOOGLE_KEY} maptype={'ROADMAP'} />
          {this.renderCompanyWFPointMarker()}
          {editPolygon === undefined ? this.drawPolygon() : null}

          {goToLinkCoordinates && this.renderGoToLinkMarker()}

          {this.renderWorkerPoints()}
          {this.renderWorkerPolygon()}
          {this.rendervisitngPoints()}
          {this.renderPointsInCreatePoint()}
          {this.renderVisitngPolygon()}
          {this.props.page === 'addArea' ? (
            <MapDrawing
              general={general}
              reloadDrawing={reloadDrawing}
              renderPolygon={this.drawPolygon}
              {...this.props}
            />
          ) : isDrawing === undefined ? null : (
            <MapDrawing
              general={general}
              reloadDrawing={reloadDrawing}
              renderPolygon={this.drawPolygon}
              {...this.props}
            />
          )}
        </Map>
      </div>
    );
  }
}

export default GeneralMap;
