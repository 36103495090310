import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Card, Col, Row } from 'reactstrap';
import CompainesWorkFieldsTable from './CompainesWorkFieldsTable';
import * as actions from '../../redux/actions/index';
import {  getMyCompany } from '../../utils/GeneralFunctions';
import CompainesWorkFieldsSearch from './CompainesWorkFieldsSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateCompainesWF from './CreateUpdateCompainesWF';
import {
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  CLEAR_COMPANY_WORK_FIELD_STATE,
} from '../../redux/actions/types';
import { unsetForm, unsetCompanyWFListData } from './CompanyWFFunctions';
// import WorkFieldMap from "./WorkFieldMap";
import WorkFieldMapy from './WorkFieldMapy.js';
import { Mapy } from 'mapy_sdk';

let mapy = new Mapy();

class CompainesWorkFields extends Component {
  componentDidMount() {
    this.renderCompaines();
  }

  componentWillUnmount() {
    this.props.general([], CLEAR_COMPANY_WORK_FIELD_STATE);
  }
  renderModalChildren = () => {
    const {
      companyWfForm,
      modale,
      general,
      createORUpdateCompany,
      companyOptions,
      getCompainesForDropDown,
    } = this.props;
    return (
      <CreateUpdateCompainesWF
        companyWfForm={companyWfForm}
        general={general}
        modale={modale}
        companyOptions={companyOptions}
        createORUpdateCompany={createORUpdateCompany}
        getCompainesForDropDown={getCompainesForDropDown}
        {...this.props}
      />
    );
  };

  renderCompaines = () => {
    const { general,  } = this.props;
    unsetCompanyWFListData(general);

    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };
  renderModal = () => {
    const { modale, general, companyWfForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={2}
        tabs_title={modale.tabs_title}
        modale={modale}
        general={general}
        action={UPDATE_COMPANY_WORK_FIELD_PROPS}
        formvalues={companyWfForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };

  render() {
    const {
      history,
      general,
      companyWfObj,
      sorting,
      searchForm,
      isLoadingSearch,
      companyOptions,
      getCompainesWF,
      loadCompaniesOptions,
      modaleView,
      loadBranchesOptions,
      branchOptions,
      setCompanyWorkFieldProps,
      companyWfForm
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}

        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <CompainesWorkFieldsSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              general={general}
              getCompainesWF={getCompainesWF}
              companyOptions={companyOptions}
              loadCompaniesOptions={loadCompaniesOptions}
              loadBranchesOptions={loadBranchesOptions}
              branchOptions={branchOptions}
              companyWfForm={companyWfForm}
              {...this.props}
            />

            <CompainesWorkFieldsTable
              general={general}
              companyWfObj={companyWfObj}
              sorting={sorting}
              searchForm={searchForm}
              getCompainesWF={getCompainesWF}
              modaleView={modaleView}
              setCompanyWorkFieldProps={setCompanyWorkFieldProps}
              mapy={mapy}
              {...this.props}
            />

            <Card
              style={{ borderRadius: '10px', border: 'none' }}
              className='mt-3'
            >
              {/* <WorkFieldMap companyWfObj={companyWfObj} /> */}
              <WorkFieldMapy mapy={mapy} companyWfObj={companyWfObj} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
  RequestsR,
  MapReducer,
}) => {
  return {
    companyWfObj: CompanyWorkFieldR.companyWfObj,
    modale: CompanyWorkFieldR.modale,
    companyWfForm: CompanyWorkFieldR.companyWfForm,
    sorting: CompanyWorkFieldR.sorting,
    searchForm: CompanyWorkFieldR.searchForm,
    modaleView: CompanyWorkFieldR.modaleView,
    isLoadingSearch: CompanyWorkFieldR.isLoadingSearch,
    companyOptions: CompanyR.companyOptions,
    branchOptions: BranchR.branchOptions,
    allCompanyWorkFields: CompanyWorkFieldR.allCompanyWorkFields,
    selectedCompanyWFPoints: RequestsR.requestsObj.requestsItem,
    MapReducer: MapReducer,
  };
};

export default connect(mapStateToProps, actions)(CompainesWorkFields);
