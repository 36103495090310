import React, {useState, useEffect} from "react";
import {v4 as uuidv4} from "uuid";
import config from '../../config.json'
import {connect} from "react-redux";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import {
    Table,
    Menu,
    Dropdown,
    Button,
    Space,
    Badge,
    Select,
    Spin, Checkbox,
} from "antd";
import EllipsisDropdown from "../../components/SharedComponents";
// import {Request} from "../../redux/Api";
import { Request} from '../../redux/Api/index'
import axios from "axios";
import * as actions from "../../redux/actions/index";
import "antd/dist/antd.css";
import {getLanguage, local} from "../../lang/local";
import Config from "../../config";
import {
    renderTableHeadItem,
    renderNumber,
    renderPagination,
    getDeafultItem,
    renderEditButton,
    getPermissionValue,
    renderCreateButton,
    renderEditTitle,
    onOpenProfile,
    renderAntdColumn,
    renderTime,
    getEmployeeOptions,
    renderDateInput,
    handleSelectChangeEnhanced,
    renderDate,
    getQueryStringEnhanced,
    removeUnsetValues,
    confirmSunccessed,

} from "../../utils/GeneralFunctions";
import {errandChannel} from "../../utils/staticOptions";
import {visitTypeOptions,Action_Types_Deployment,Action_Types_Cancellation, Action_Types_Visit} from "../../utils/visitTypeOptions";
import {
    UPDATE_Ticket_Details,
    UPDATE_Ticket_Details_Actions,
} from "../../redux/actions/TicketAction";

import Ticket from "./Ticket";
import {
    DELETE_REQUESTS_PROPS,
    MERGE_REQUESTS_PROPS,
    UPDATE_REQUESTS_PROPS,
    UPDATE_VISITS_PROPS,
} from "../../redux/actions/types";
import {filterEmployeesByWorkField} from "./RequestsFunctions";
import {renderBranchSelect} from "../Branches/BranchesFunction";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
import {setRequestsProps} from "../../redux/actions";
//React Modal{
import ReactDOM from "react-dom";
import Modal from "react-modal";
import {Link} from "react-router-dom";
import ReasonModal from "./ReasonModal";

//}React Modal
//modal_Style{
// const style = {
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// };
const customStyles = {
    position: "absolute",
    width: "80rem",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    height: "92%",
    // bgcolor: 'background.paper',
    border: "1px solid gray",
    // boxShadow: 24,
    zIndex: 99,
    borderRadius: "15px",
    boxShadow: "0px 0px 15px 15px gray",
    //  p: 4,
};
const {Option} = Select;

//}modal_Style
const RequestsTable2 = (props) => {
    const {
        createORUpdateVisit,
        requestsObj,
        general,
        sorting,
        searchForm,
        getRequests,
        companyOptions,
        modaleView,
        modale,
        map,
        history,
        pointForm,
    } = props;

    const [markedTicket, setMarkedTicket] = useState([]);
    const [haveAssignPermission, setHaveAssignPermission] = useState(false);
    const [haveClosePermission, sethaveClosePermission] = useState(false);

    const [ticketData, setTicketData] = useState();
    const [requestData, setRequestData] = useState();
    const [Updatedat, setUpdatedat] = useState();
    const [pointData, setPointData] = useState();
    const [pointId, setPointId] = useState();
    const [head, setHead] = useState([
        local.Mark,
        local.Ticket_name,
        local.CreatedAt,

        local.UpdateAt,
        local.Request_City,
        local.Zone,
        local.ErrandType,
        local.Request_errand_channel,
        local.AgentName,
        local.OrderStatus,


    ]);
    const [company, setCompany] = useState();
    const [LoadSpiner, setLoadSpiner] = useState(false);
    const [IsDAtaTicketLoad, setIsDAtaTicketLoad] = useState(false);
    const [getTicketDetails_Actions, setgetTicketDetails_Actions] = useState();
    const additionalTh = [local.Workers, local.DateVisiting, local.Save];
    const TakeActions=[local.TakeAction]  ;
    const [Reason_MOdal, setReason_MOdal] = useState(false)
    const [Reason, setReason] = useState("")
    const [isAvtive,setisAvtive]=useState(true)
    const RequesterPermision=JSON.parse(localStorage.getItem("SERVICE_USER"))?.permissions
    const [DATA, setDAta] = useState({
        formValues: "",
        employeeId: "",
        index: "",
        null: "",
        workerName: "",
    })
    const [TicketCheck,setTicketCheck]=useState()
const Take_Action_Row=(target,item)=> {
        console.log("item",target,item)
    Request().post("requests/closebycityowner",{
        id:item,
        visit_action:target
    })
        .then(res=> {
            confirmSunccessed(local.SuccessfullyEdit )
            window.location.reload("/requests")

        })
}
    useEffect(() => {

        setHaveAssignPermission(getPermissionValue()["workers_points/store/{id}"]);
        sethaveClosePermission(getPermissionValue()["requests/closebycityowner"]);
    let CheckTicket_exist=RequesterPermision.find(dta=>dta.permission=="points/actions")
        setTicketCheck(CheckTicket_exist)

        setCompany(
            JSON.parse(localStorage.getItem("SERVICE_USER"))?.companies[0]?.id
        );
    }, []);

    useEffect(() => {
        if (haveAssignPermission) {
            setHead((head) => [...head, ...additionalTh]);
        }

    }, [haveAssignPermission,haveClosePermission]);

    useEffect(()=>{
        if(haveClosePermission){
            setHead((head)=>[...head,...TakeActions])
        }
    },[haveClosePermission])
    const [modalIsOpen, setIsOpen] = useState(false);

    const downloadExcel = ({e}) => {
        e.preventDefault();

        let tableQueries = {...searchForm};
        if (tableQueries.work_field_id) {
            tableQueries.work_field_id = searchForm.work_field_id.id;
        }

        const cleanForm = removeUnsetValues(tableQueries);
        let newForm = {
            ...cleanForm,
            export: 1,
        };
        if (markedTicket.length > 0) {
            newForm.ids = markedTicket;
        }
        const queries = getQueryStringEnhanced(newForm);
        setLoadSpiner(true);
        Request({}, "blob")
            .get(
                `${Config.api_url}/requests${queries}`
            )
            .then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", "tickets.xls");
                document.body.appendChild(link);
                link.click();
                window.URL.revokeObjectURL(url);
                setLoadSpiner(false);
                setMarkedTicket([])
            });
    };

    const renderVisitStatus = (status) => {
        const assigned = status === "done assign visit" ? true : false;
        const assignedStyle = {
            backgroundColor: "#00875A",
            padding: ".2rem 0.5rem",
            borderRadius: ".3rem",
            color: "white",
            textAlign: "center",
            width: "68%",
        };
        const notAssignedStyle = {
            backgroundColor: "#F2C94C",
            padding: ".2rem 0.5rem",
            borderRadius: ".3rem",
            color: "white",
            textAlign: "center",
            width: "85%",
        };
        return (
            <div style={assigned ? assignedStyle : notAssignedStyle}>
                {local[status] ? local[status] : status}
            </div>
        );
    };

    const renderPointName = (pointName, point, index) => {
        return (
            <div
                // style={{ cursor: "pointer", textDecoration: "underline" }}
                // onClick={() => {
                //   onOpenProfile(
                //     point.item,
                //     local.WorkFieldName,
                //     general,
                //     UPDATE_REQUESTS_PROPS,
                //     true,
                //     "companies/work_fields/points/show/{id}"
                //   );
                // }}
            >
                {pointName}
            </div>
        );
    };
    //Table Id onCLick
    const renderPointID = (item) => {
        setTicketData(item.item);
        setRequestData(item.submit);
        setUpdatedat(item.updated_at);

        setLoadSpiner(true);
        // Request()
        //   .get(`/companies/work_fields/points/show/${item.id}`)
        //   .then((res) => {
        //     setTicketData(res.data.data);
        //     dispatch action
        //     props.getTicketDetails(res.data.data)
        //   });

        Request()
            .get(
                `/points/actions?ids[]=${item.companyId}&point_id=${item.id}&visit_id=${item.visit_id}`
            )
            .then((res) => {
                setPointData(res.data.data.data);
                setPointId(item);
                setgetTicketDetails_Actions(res.data.data.data);
                setIsDAtaTicketLoad(true);
                setLoadSpiner(false);
                openModal();
            });
    };

    //Open_Modal
    const openModal = () => {
        setIsOpen(true);
    };
    //Close_Modal
    const closeModal = () => {
        setIsOpen(false);
    };

    const renderCreatedAt = (time) => {
        return (
            <div>
                <p>{renderDate(time)}</p>
                <p>{renderTime(time)}</p>
            </div>
        );
    };

    const renderPointEmployees = (pointEmployees) => {
        if (pointEmployees.length === 0) return "-";
        const employeesDiv = [];
        for (let i = 0; i < pointEmployees.length; i++) {
            const currentEmployee = pointEmployees[i];
            employeesDiv.push(<div>{currentEmployee.name}</div>);
        }
        return employeesDiv;
    };

    const renderEmployeDropdown = (item, obj, index) => {
        // console.log("item", item);
        // const index = obj.key - 1;
        // console.log("obj", obj);
        return (
            <div className="my-5 w-40">
                <CustomAsyncPaginate
                    fullWidth
                    loadOptions={getEmployeeOptions(company)}
                    onChange={(e) => {
                        // handleSelectChangeEnhanced(
                        //   e,
                        //   `requestsObj.requestsItem.data[${index}].employee`,
                        //   setRequestsProps
                        // );
                        general(
                            [
                                {
                                    prop: `requestsObj.requestsItem.data.${index}.employee`,
                                    value: e,
                                },
                            ],
                            MERGE_REQUESTS_PROPS
                        );
                        debugger;
                        // console.log(requestsObj.requestsItem.data[index]);
                        // setRequestsProps([[`pointForm.formvalues.workers`, e]]);
                    }}
                    cacheUniqsArr={[company]}
                    additional={{
                        page: 1,
                        companyId: company,
                    }}
                    value={requestsObj.requestsItem.data[index].employee}
                    filterOption={
                        pointForm.formvalues.work_field_id
                            ? (option, rawInput) =>
                                filterEmployeesByWorkField(
                                    option,
                                    rawInput,
                                    obj.item.work_field_id
                                )
                            : undefined
                    }
                    className=""
                    // hasLabel={true}
                    placeholder={local.Worker}
                />
            </div>
        );
    };

    const renderVisitDate = (index) => {
        return renderDateInput(
            `requestsObj.requestsItem.data.${index}.visit_date`,
            requestsObj.requestsItem.data[index]?.visit_date,
            UPDATE_REQUESTS_PROPS,
            general,
            local.VisitDate,
            true,
            new Date(),
            undefined,
            undefined,
            MERGE_REQUESTS_PROPS
        );
    };

    const renderSubmitBtn = (item, index, workerName) => {
        if (haveAssignPermission) {
            const sava = () => {
                console.log("after i clicked button ", item)

                let employeeId;
                let point = item?.submit.point;
                let points = [];
                let formPont = [];
                let formValues = {};
                let workFieldId = [];
                if (item && requestsObj) {
                    employeeId = requestsObj?.requestsItem?.data[index]?.employee?.id;
                    const workFId = item?.submit.work_field.id;
                    formPont.push({
                        id: point.id,
                        request_id: item.request_id,
                        visit_type: item.submit.visit_type,
                        work_field_id: workFId,
                        date: requestsObj.requestsItem.data[index].visit_date,
                    });
                    workFieldId.push({value: workFId});
                    points.push(formPont);
                    formValues.points = points;
                    formValues.work_field_id = workFieldId;
                }
                if (item.status == "assigned") {
                    setReason_MOdal(true)
                    setDAta({
                        formValues: formValues,
                        employeeId: employeeId,
                        index: index,
                        workerName: workerName

                    })
                } else
                    createORUpdateVisit(formValues, employeeId, index, null, workerName);
            };

            return (
                <button
                    className="cursor-pointer text-white py-[10px] px-3 transition bg-blue-500 hover:bg-blue-700 mx-2"
                    onClick={(e) => {
                        sava();
                    }}
                >
                    {local.Save}
                </button>
            );
        }
    };


    // const columns = [
    //   renderAntdColumn(local.Id, "id", renderPointID),
    //   renderAntdColumn(local.MerchantName, "name", renderPointName),
    //   renderAntdColumn(local.RequesterName, "req_name"),
    //   renderAntdColumn(local.RequesterEmail, "req_email"),
    //   renderAntdColumn(local.CreatedAt, "submitting_date", renderCreatedAt),

    //   // renderAntdColumn(local.Employees, "pointEmployees", renderPointEmployees),
    //   renderAntdColumn(local.WorkField, "work_field"),
    //   renderAntdColumn(local.OrderStatus, "status", renderVisitStatus),
    //   // renderAntdColumn(local.Comment, "comment"),

    //   renderAntdColumn(local.Workers, "employee", renderEmployeDropdown),
    //   renderAntdColumn(local.CreateVisit, "date", renderVisitDate),
    //   renderAntdColumn(local.Save, "submit", renderSubmitBtn),

    //   // renderAntdColumn(local.Edit, 'edit', null, false),
    // ];

    // const dropdowNMenu = (row) => {

    //   return (
    //     <Menu>
    //       <span className=" d-flex align-items-center justify-content-center">
    //         {/* <h5 className="mt-1">Status</h5> */}
    //       </span>

    //       <Menu.Item>
    //         <Badge status="processing" />
    //         <span className="ml-2">{"mohab"}</span><br />

    //       </Menu.Item>

    //       <Menu.Item>
    //         <Badge status="processing" />
    //         <span className="ml-2">{"mohab"}</span><br />

    //       </Menu.Item>
    //     </Menu>
    //   )
    // }
    // const MY_Data_table = [
    //   {
    //     title: `${local.Ticket_name}`,
    //     dataIndex: 'id',
    //     render: (obj, item) => <span onClick={() => renderPointID(item)}>{item.id}</span>
    //   },
    //   {
    //     title: `${local.CreatedAt}`,
    //     dataIndex: 'point',
    //     render: (obj, item) => `${item.created_at.slice(0, 10)}`
    //   },
    //   {
    //     title: `${local.Request_City}`,
    //     dataIndex: 'City',
    //     render: (obj, item) => `${item.point.work_field.name[getLanguage()]}`
    //   },
    //   {
    //     title: `visit action`,
    //     dataIndex: 'City',
    //     render: (obj, item) => `${item.point.work_field.name[getLanguage()]}`
    //   },

    //   {
    //     title: `erraned type`,
    //     dataIndex: 'City',
    //     render: (obj, item) => `${item.point.work_field.name[getLanguage()]}`
    //   }, {
    //     title: `${local.Request_errand_channel}`,
    //     dataIndex: 'City',
    //     render: (obj, item) => `${item.point.properties.errand_channel}`
    //   },
    //   {
    //     title: `agent name`,
    //     dataIndex: 'City',
    //     render: (obj, item) => `${item.point.properties.errand_channel}`
    //   },
    //   {
    //     title: `${local.OrderStatus}`,
    //     dataIndex: 'City',
    //     render: (obj, item) => renderVisitStatus(item.status)
    //   },
    //   {
    //     title: `${local.Workers}`,
    //     dataIndex: 'City',
    //     render: (obj, item, index) => <Select defaultValue="lucy"
    //       style={{ width: 120, backgroundColor: "#0052CC" }}
    //     // onChange={handleChange}
    //     >
    //       <Option value="jack">Jack</Option>
    //       <Option value="lucy">Lucy</Option>
    //       <Option value="disabled" disabled>
    //         Disabled
    //       </Option>
    //       <Option value="Yiminghe">yiminghe</Option>
    //     </Select>

    //     //   <div className="">
    //     //   <EllipsisDropdown menu={dropdowNMenu(item)} />
    //     // </div>
    //     // renderEmployeDropdown(obj,item,index)

    //     //    <Dropdown overlay={menu}>
    //     //   <Button>
    //     //     <Space>
    //     //       Button
    //     //      </Space>
    //     //   </Button>
    //     // </Dropdown>
    //   },

    //   {
    //     title: `${local.OrderStatus}`,
    //     dataIndex: 'City',
    //     render: (obj, item) => renderVisitDate(obj, item)
    //   },

    //   // {
    //   //   title: 'Address',
    //   //   dataIndex: 'address',
    //   //   key: 'address',
    //   // },
    // ]
    // console.log("tersyssdfsf", requestsObj.requestsItem?.data);
    // const renderTable = () => {
    //   if (requestsObj.requestsItem !== null) {
    //     let tableData = [];
    //     if (requestsObj.requestsItem.data) {
    //       const pointData = requestsObj.requestsItem.data;
    //       for (let i = 0; i < pointData.length; i++) {
    //         const currentPoint = pointData[i].point;
    //         const pointEmployees = currentPoint.workers;
    //         currentPoint.visit_type = pointData[i].visit_type;

    //         tableData.push({
    //           key: i + 1,
    //           item: currentPoint,
    //           req_name: pointData[i].user.name,
    //           req_email: pointData[i].user.email,
    //           submitting_date: requestsObj.requestsItem.data[i].created_at,
    //           id: renderNumber(requestsObj.requestsItem.data[i].id),
    //           comment: pointData[i].comment,
    //           status: pointData[i].status,
    //           request_id: pointData[i].id,
    //           name: currentPoint.name,
    //           work_field: currentPoint.work_field.name[getLanguage()],
    //           pointEmployees,
    //           address: currentPoint.properties.address,
    //           employee: currentPoint[i],
    //           visitDate: currentPoint[i],
    //           submit: requestsObj.requestsItem.data[i],
    //         });
    //       }
    //     }
    //     return tableData;
    //   }
    // };

    const onChangeCheckbox = (e, item, index) => {
        if (e.target.checked) {
            setMarkedTicket((pre) => {
                if (getLanguage() == "ar") {
                    const numberToEnglish = (string) =>
                        string.replace(/[\u0660-\u0669]/g, function (c) {
                            return c.charCodeAt(0) - 0x0660;
                        });
                    const number = numberToEnglish(item.ticketId);
                    pre.push(number);
                    return pre;
                } else {
                    pre.push(item.ticketId);
                    return pre;
                }
            });
        } else {
            const marketIndex = markedTicket.indexOf(item.ticketId);
            setMarkedTicket((pre) => {
                pre.splice(marketIndex, 1);
                return pre;
            });
        }
    };
    const handleCancelMOdal_reason = () => {
        setReason_MOdal(false);
    };
    const body = () => {
        if (requestsObj.requestsItem !== null) {
            let tableData = [];
            if (requestsObj.requestsItem.data) {
                const pointData = requestsObj.requestsItem.data;
                for (let i = 0; i < pointData.length; i++) {
                    const currentPoint = pointData[i].point;
                    const pointEmployees = currentPoint.workers;
                    currentPoint.visit_type = pointData[i].visit_type;

                    tableData.push({
                        index: i,
                        id: requestsObj.requestsItem.data[i].point.id,
                        ticketId: renderNumber(requestsObj.requestsItem.data[i].id),
                        submitting_date: requestsObj.requestsItem.data[i].created_at,
                        updated_at: requestsObj.requestsItem.data[i].updated_at,
                        visitDate: currentPoint[i],
                        work_field:
                            requestsObj.requestsItem.data[i].work_field.name[getLanguage()],
                        Zone:
                            requestsObj.requestsItem.data[i].work_field.branch.name[getLanguage()],
                        work_field_id:
                        requestsObj.requestsItem.data[i].work_field.id,
                        item: currentPoint,
                        req_name: pointData[i].user.name,
                        req_email: pointData[i].user.email,
                        comment: pointData[i].comment,
                        status: pointData[i].status,
                        request_id: pointData[i].id,
                        name: currentPoint.name,
                        companyId: pointData[i].work_field.branch.company.id,
                        agentName: requestsObj.requestsItem.data[i].worker.name,
                        pointEmployees,
                        visit_id: requestsObj.requestsItem.data[i].visit_id,
                        address: currentPoint.properties.address,
                        employee: currentPoint[i],
                        submit: requestsObj.requestsItem.data[i],
                        errand_channel:
                        requestsObj.requestsItem.data[i].properties?.errand_channel,
                        errand_Type: requestsObj.requestsItem.data[i].visit_type,
                        ClosedByCityOwner:requestsObj.requestsItem.data[i].close_city_owner
                    });
                }
            }
            return tableData;
        }
    };
    const Status_change = (status) => {
        if (status == "pending") {
            return (
                <span
                    style={{
                        backgroundColor: "#F2C94C",
                        padding: "11px",
                        width: "100%",
                        color: "white",
                        // borderRadius: "7px",
                        display: "block",
                        textAlign: "center",
                    }}
                >
          pending
        </span>
            );
        } else if (status == "Done") {
            return (
                <span
                    style={{
                        backgroundColor: "#003595",
                        padding: "11px",
                        width: "100%",
                        color: "white",
                        // borderRadius: "7px",
                        display: "block",
                        textAlign: "center",
                    }}
                >
          Done
        </span>
            );
        } else if (
            status == "done assign visit" ||
            status == "assigned" ||
            status == "Deployed"
        ) {
            return (
                <span
                    style={{
                        backgroundColor: "#003595",
                        padding: "11px",
                        width: "100%",
                        color: "white",
                        // borderRadius: "7px",
                        display: "block",
                        textAlign: "center",
                        whiteSpace: "nowrap",
                    }}
                >
          {status}
        </span>
            );
        } else {
            return (
                <span
                    style={{
                        backgroundColor: "red",
                        width: "100%",
                        padding: "11px",
                        color: "white",
                        // borderRadius: "7px",
                        display: "block",
                        textAlign: "center",
                    }}
                >
          {status}
        </span>
            );
        }
    };
    const Take_Action=(Visit_Type,isAvtive,item)=>{
         if(Visit_Type==2)
        {
            return(
                <select   onChange={(e)=>Take_Action_Row(e.target.value,item.submit.id)} style={{border: "2px solid black",
                    width: "100%",
                    height: "42px",
                    backgroundColor: "#0052cc",
                    color: "white"}}>
                    {Action_Types_Deployment.map((ele,index)=>(
                             <option defaultValue={null} style={{backgroundColor: "white",color:"black",border:"1px solid black"}} value={ele.value}
                             >{ele.label}</option>

                     ))}

                </select>
                // <CustomAsyncPaginate
                //     my_style={true}
                //     fullWidth
                //     // isDisabled={!(item.status === "pending" || item.status === "assigned")}
                //     loadOptions={Action_Types_Deployment}
                //     // onChange={(e) => {
                //     //
                //     // }}
                //     // cacheUniqsArr={[company]}
                //     additional={{
                //         page: 1,
                //         companyId: company,
                //     }}
                //     value={
                //         "mohab "
                //         // requestsObj.requestsItem.data[index].employee
                //     }
                //     // filterOption={(option, rawInput) =>
                //     //     item.submit.work_field.id
                //     //         ? filterEmployeesByWorkField(
                //     //             option?.data,
                //     //             rawInput,
                //     //             item.submit.work_field.id
                //     //         )
                //     //         : undefined
                //     // }
                //     className=""
                //     // hasLabel={true}
                //     placeholder={local.TakeAction}
                // />
            )
        }
           if(Visit_Type==5||Visit_Type==6){
             {
                 return(
                     <select  onChange={(e)=>Take_Action_Row(e.target.value,item.submit.id)} style={{border: "2px solid black",
                         width: "100%",
                         height: "42px",
                         backgroundColor: "#0052cc",
                         color: "white"}}>
                         {Action_Types_Cancellation.map((ele,index)=>(
                             <option defaultValue={null} style={{backgroundColor: "white",color:"black",border:"1px solid black"}} value={ele.value}
                             >{ele.label}</option>

                         ))}

                     </select>
                     // <CustomAsyncPaginate
                     //     my_style={true}
                     //     fullWidth
                     //     // isDisabled={!(item.status === "pending" || item.status === "assigned")}
                     //     loadOptions={Action_Types_Deployment}
                     //     // onChange={(e) => {
                     //     //
                     //     // }}
                     //     // cacheUniqsArr={[company]}
                     //     additional={{
                     //         page: 1,
                     //         companyId: company,
                     //     }}
                     //     value={
                     //         "mohab "
                     //         // requestsObj.requestsItem.data[index].employee
                     //     }
                     //     // filterOption={(option, rawInput) =>
                     //     //     item.submit.work_field.id
                     //     //         ? filterEmployeesByWorkField(
                     //     //             option?.data,
                     //     //             rawInput,
                     //     //             item.submit.work_field.id
                     //     //         )
                     //     //         : undefined
                     //     // }
                     //     className=""
                     //     // hasLabel={true}
                     //     placeholder={local.TakeAction}
                     // />
                 )
             }

         }

         else {


             return(
                 <select    onChange={(e)=>Take_Action_Row(e.target.value,item.submit.id)} style={{border: "2px solid black",
                     width: "100%",
                     height: "42px",
                     backgroundColor: "#0052cc",
                     color: "white"}}>
                     {Action_Types_Visit.map((ele,index)=>(
                         <option defaultValue={null} style={{backgroundColor: "white",color:"black",border:"1px solid black"}} value={ele.value}
                         >{ele.label}</option>

                     ))}

                 </select>
                 // <CustomAsyncPaginate
                 //     my_style={true}
                 //     fullWidth
                 //     // isDisabled={!(item.status === "pending" || item.status === "assigned")}
                 //     loadOptions={Action_Types_Deployment}
                 //     // onChange={(e) => {
                 //     //
                 //     // }}
                 //     // cacheUniqsArr={[company]}
                 //     additional={{
                 //         page: 1,
                 //         companyId: company,
                 //     }}
                 //     value={
                 //         "mohab "
                 //         // requestsObj.requestsItem.data[index].employee
                 //     }
                 //     // filterOption={(option, rawInput) =>
                 //     //     item.submit.work_field.id
                 //     //         ? filterEmployeesByWorkField(
                 //     //             option?.data,
                 //     //             rawInput,
                 //     //             item.submit.work_field.id
                 //     //         )
                 //     //         : undefined
                 //     // }
                 //     className=""
                 //     // hasLabel={true}
                 //     placeholder={local.TakeAction}
                 // />
             )
         }

    }

    return (
        // <h1>mohab</h1>
        <>
            {LoadSpiner ? (
                <Spin
                    tip={`${local.LOAD_DATA}`}
                    style={{
                        margin: "auto",
                        marginBottom: "20px",
                        padding: "30px 50px",
                        textAlign: "center",
                        borderRadius: "4px",
                        display: "block",
                    }}
                ></Spin>
            ) : (
                <>
                    {requestsObj.requestsItem == null ? (
                        <Spin
                            tip={`${local.LOAD_DATA}`}
                            style={{
                                margin: "auto",
                                marginBottom: "20px",
                                padding: "30px 50px",
                                textAlign: "center",
                                borderRadius: "4px",
                                display: "block",
                            }}
                        ></Spin>
                    ) : (
                        <table
                            className="table-auto overflow-x-auto md:inline-table lg:w-full"
                            style={{display: "block"}}
                            id="req_table"
                        >
                            <thead>
                            <tr className="" style={{backgroundColor: "#0d6efd26"}}>
                                {head.map((item, index) => {
                                    if (index == 9) {
                                        return (
                                            <th
                                                key={uuidv4()}
                                                className="px-6 py-2"
                                                style={{minWidth: "200px"}}
                                            >
                                                {item}
                                            </th>
                                        );
                                    } else if (index == 10) {
                                        return (
                                            <th
                                                key={uuidv4()}
                                                className="px-6 py-2"
                                                style={{minWidth: "300px"}}
                                            >
                                                {item}
                                            </th>
                                        );
                                    }
                                    else if (index == 11) {
                                        return (
                                            <th
                                                key={uuidv4()}
                                                className="px-6 py-2"
                                                style={{minWidth: "300px"}}
                                            >
                                                {item}
                                            </th>
                                        );
                                    }
                                    else {
                                        return (
                                            <th key={uuidv4()} className="px-6 py-2" >
                                                {item}
                                            </th>
                                        );
                                    }
                                })}
                            </tr>
                            </thead>
                            {requestsObj.requestsItem.data==""?
                                <Spin
                                    tip={`${local.LOAD_DATA}`}
                                    style={{
                                        margin: "auto",
                                        marginBottom: "20px",
                                        padding: "30px 50px",
                                        textAlign: "center",
                                        borderRadius: "4px",
                                        display: "block",
                                    }}
                                ></Spin>:
                            <tbody className="" style={{zIndex: 9}}>
                            {body()?.map((item, index) => (
                                <tr
                                    key={uuidv4()}
                                    className="border-b border-gray-200 text-sm text-gray-800"
                                >
                                    <td className="p-4">
                                        {" "}
                                        <Checkbox
                                            onChange={(e) => onChangeCheckbox(e, item, index)}
                                        />
                                    </td>
                                    <td className="p-4">
                      <span
                          className="underline"
                          style={TicketCheck==undefined ?{cursor: "not-allowed",
                              pointerEvents: "none"}:{cursor: "pointer"}}
                          onClick={() => renderPointID(item)}
                      >
                        {item.ticketId}
                      </span>
                                    </td>
                                    <td className="p-4">
                                        {" "}
                                        {renderCreatedAt(item.submitting_date)}
                                    </td>
                                    <td className="p-4"> {renderCreatedAt(item.updated_at)}</td>
                                    <td className="p-4"> {item.Zone}</td>
                                    <td className="p-4"> {item.work_field}</td>


                                    <td className="p-4">
                                        {" "}
                                        {visitTypeOptions[item.errand_Type]?.label}
                                    </td>

                                    <td className="p-4">
                                        {" "}
                                        {errandChannel[item.errand_channel]?.label}
                                    </td>

                                    <td style={{textAlign:"center"}} className="p-4">{item.ClosedByCityOwner?
                                        <abbr style={{cursor:"pointer",
                                             color: "#dd3333"

                                            }} title="Taken Action From  DashBoard">
                                            {item.ClosedByCityOwner.name}

                                        </abbr>
                                        :item.agentName}</td>

                                    <td className="p-4"> {Status_change(item.status)}</td>


                                    {haveAssignPermission && (
                                        <>
                                            <td className="p-4">
                                                {" "}
                                                <CustomAsyncPaginate
                                                    my_style={true}
                                                    fullWidth
                                                    isDisabled={!(item.status === "pending" || item.status === "assigned")}
                                                    loadOptions={getEmployeeOptions(item.companyId, item.work_field_id)}
                                                    onChange={(e) => {
                                                        // handleSelectChangeEnhanced(
                                                        //   e,
                                                        //   `requestsObj.requestsItem.data[${index}].employee`,
                                                        //   setRequestsProps
                                                        // );
                                                        general(
                                                            [
                                                                {
                                                                    prop: `requestsObj.requestsItem.data.${index}.employee`,
                                                                    value: e,
                                                                },
                                                            ],
                                                            MERGE_REQUESTS_PROPS
                                                        );
                                                        // setRequestsProps([[`pointForm.formvalues.workers`, e]]);
                                                    }}
                                                    cacheUniqsArr={[company]}
                                                    additional={{
                                                        page: 1,
                                                        companyId: company,
                                                    }}
                                                    value={
                                                        requestsObj.requestsItem.data[index].employee
                                                    }
                                                    filterOption={(option, rawInput) =>
                                                        item.submit.work_field.id
                                                            ? filterEmployeesByWorkField(
                                                                option?.data,
                                                                rawInput,
                                                                item.submit.work_field.id
                                                            )
                                                            : undefined
                                                    }
                                                    className=""
                                                    // hasLabel={true}
                                                    placeholder={
                                                        item.agentName
                                                            ? local.DropDown_AssignAgain
                                                            : local.DropDown_Assign
                                                    }
                                                />{" "}
                                            </td>
                                            <td className="p-4">{renderVisitDate(index)}</td>
                                            <td className="p-4">
                                                {renderSubmitBtn(
                                                    item,
                                                    index,
                                                    requestsObj.requestsItem.data[index]?.employee?.name
                                                )}
                                            </td>
                                            {/*//take action*/}
                                            {haveClosePermission &&(
                                                <td className="p-4">
                                                    {item.status === "pending"?Take_Action(item.errand_Type,isAvtive,item):
                                                        <select  disabled={true}
                                       // onChange={(e)=>Take_Action_Row(e,item)}
                                                                 style={{border: "2px solid black",
                                                                     width: "100%",
                                                                     height: "42px",
                                                                     backgroundColor: "#bababa",
                                                                     cursor: "not-allowed",
                                                                     color: "white"}}>
                                                            {Action_Types_Deployment.map((ele,index)=>(
                                                                <option  style={{backgroundColor: "white",color:"black",border:"1px solid black"}} value={ele.value}
                                                                >{ele.label}</option>

                                                            ))}

                                                        </select>}
                                                </td>

                                            )}
                                            {/*//take action*/}
                                        </>
                                    )}
                                    {/* <td className='p-4'> "" </td> */}
                                    {/* {edit && (
<td className='py-2 px-4'>
<div className='mx-auto max-w-max cursor-pointer'>
  <img src={editIcon} alt='edit' />
</div>
</td>
)}
{chat && (
<td className='p-2 '>
<div className='mx-auto max-w-max cursor-pointer'>
  <img src={chatIcon} alt='chat' />
</div>
</td>
)} */}
                                </tr>
                            ))}
                            </tbody>}
                        </table>
                    )}
                    {/* <Table
        style={{ border: "none" }}
        // dataSource={renderTable() && renderTable()}
    dataSource={requestsObj.requestsItem?.data}
// rowClassName={requestsObj.requestsItem.data.id}
        // scroll={{ x: 'calc(800px + 50%)', y: 600 }}
        pagination={{
          pageSize:
            requestsObj.requestsItem &&
            +requestsObj.requestsItem.pagination?.per_page,
          position: ["none"],
        }}
        size="small"
        columns={MY_Data_table}
      /> */}
                    {/* <ReactHTMLTableToExcel
            id="test-table-xls-button"
            className="download-table-xls-button"
            table="req_table"
            filename={local.requests}
            sheet="tablexls"
            buttonText={local.SimpleExcelSheet}
          /> */}
                    <div className="flex gap-x-2">
                        <button
                            className="download-table-xls-button"
                            onClick={(e) => downloadExcel({e})}
                        >
                            {local.ExcelSheet}
                        </button>
                    </div>
                    {renderPagination(requestsObj.requestsItem, searchForm, getRequests)}
                    <Modal
                        isOpen={modalIsOpen}
                        // onAfterOpen={afterOpenModal}
                        onRequestClose={closeModal}
                        style={{content: customStyles}}
                        contentLabel="Example Modal"
                    >
                        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>Hello</h2> */}
                        <Ticket
                            ticketData={ticketData}
                            requestData={requestData}
                            Updatedat={Updatedat}
                            pointData={pointData}
                            pointId={pointId}
                            getTicketDetails_Actions={getTicketDetails_Actions}
                        />
                    </Modal>
                </>
            )}
            {/* Modal Reason */}
            <ReasonModal
                handleCancelMOdal_reason={handleCancelMOdal_reason}
                createORUpdateVisit={createORUpdateVisit}
                setReason={setReason}
                Reason={Reason}
                DATA={DATA}
                // createORUpdateVisit(formValues, employeeId, index, null, workerName,comment);
                modalIsOpen={Reason_MOdal}/>
        </>
    );
};
const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
    return {
        getTicketDetails: (number) => dispatch(UPDATE_Ticket_Details(number)),
        getTicketDetails_Actions: (number) =>
            dispatch(UPDATE_Ticket_Details_Actions(number)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RequestsTable2);
