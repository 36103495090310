export const setModalsProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for (let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1],
    });
  }

  dispatch({
    type: "UPDATE_MODALS_PROPS",
    payload,
  });
};

export const clearModalsProps = () => (dispatch) => {
  dispatch({
    type: "CLEAR_MODALS_PROPS",
  });
};
