import { Request } from "../Api/index";
import { CLEAR_STATE, UPDATE_HOME_PROPS } from "./types";

export const fetchStatistics = () => (dispatch) => {
  Request()
    .get("/home")
    .then((res) => {
      if (res.data.status === true) {
        dispatch({
          type: UPDATE_HOME_PROPS,
          payload: [
            {
              prop: "homeObj.data",
              value: res.data.data,
            },
          ],
        });
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};

export const clearHomeState = () => (dispatch) => {
  dispatch({
    type: CLEAR_STATE,
  });
};
