import { getRequest, postRequest } from ".";

export const getCompanies = async (searchForm) => {
  return await getRequest("companies", searchForm);
};

export const getCompanyBranches = async (companyId, searchForm) => {
  return await getRequest(`companies/branches/${companyId}`, searchForm);
};

export const getBranchWorkFields = async (branchId, searchForm) => {
  return await getRequest(`companies/${branchId}/work_fields`, searchForm);
};

export const getWorkFieldPoints = async (searchForm) => {
  return await getRequest("companies/work_fields/points", searchForm);
};


export const getPointActions = async (searchForm) => {
  return await getRequest("points/actions", searchForm);
};

// // GIFTS AND SAMPLES
export const getCompanyGifts = async (companyId, searchForm) => {
  return await getRequest(`companies/gifts/${companyId}`, searchForm);
};

export const getPointGiftsReportData = async (searchForm) => {
  return await getRequest("gifts/amount", searchForm);
};

export const updateGift = async (giftId, requestBody) => {
  return await postRequest(`companies/gifts/update/${giftId}`, requestBody);
};

export const addGift = async (requestBody) => {
  return await postRequest("companies/gifts/store", requestBody);
};

// // WORKERS
export const addWorker = async (requestBody) => {
  return await postRequest("workers/store", requestBody);
};

// // COVERAGE LIST
export const getEmployeeCoverageList = async (workFieldID, searchForm) => {
  return await getRequest(`workers-work-points/${workFieldID}`, searchForm);
};

export const addPointsToEmployeeCoverageList = async (
  employeeID,
  requestBody
) => {
  // // REQUEST BODY: { points: [{ point_id: pointID, work_field_id: workFieldID }] }
  return await postRequest(
    `workers-work-points/store/${employeeID}`,
    requestBody
  );
};

export const removeEmployeePoint = async (workFieldID, requestBody) => {
  // // REQUEST BODY: { worker_id: workerID, worker_point_ids: workerPointIDs }
  return await postRequest(
    `workers-work-points/delete/${workFieldID}`,
    requestBody
  );
};

// // REPORTS
export const getVisitsSpotsReports = async (companyID, searchForm) => {
  return await getRequest(`visit-reports/${companyID}`, searchForm);
};
