import {getRequest, Request, Select_Request} from "../Api/index";
import {UPDATE_REQUESTS_PROPS} from "./types";
import toaster from "toasted-notes";
import {local} from "../../lang/local";
import {
    removeUnsetValues,
    confirmSunccessed,
    getDeafultItem,
    getAsyncSelectOptions,
    isArray,
} from "../../utils/GeneralFunctions";
import {
    clearRequestsForm,
    clearRequestsModal,
    setRequestsProps,
} from "./RequestsActions";
import {data} from "browserslist";

export const getPointsOptions =
    (searchValue, loadedOptions, additional) => async (dispatch) => {
        let hasMore;
        let workFieldpointsOptions = [];

        const searchForm = {
            name: searchValue,
            page: additional.page,
        };

        let workFields = additional.workFields;
        if (workFields && isArray(workFields)) {
            searchForm.ids = workFields.map((item) => item.value || item);
        } else if (workFields && !isArray(workFields)) {
            searchForm.ids = workFields.value;
        }

        try {
            await getRequest("companies/work_fields/points", searchForm).then(
                (response) => {
                    const optionsObject = getAsyncSelectOptions(response, "name", "id");
                    workFieldpointsOptions = optionsObject.options;
                    hasMore = optionsObject.hasMore;
                }
            );
        } catch (err) {
            console.log(err);
        }

        dispatch(
            setRequestsProps([
                ["pointsOptions", [...loadedOptions, ...workFieldpointsOptions]],
            ])
        );

        return {
            options: workFieldpointsOptions,
            hasMore: hasMore,
            additional: {
                page: hasMore ? additional.page + 1 : additional.page,
                companyId: additional.companyId,
                workFields: additional.workFields,
            },
        };
    };

export const getSpecificPlaceTypeTotal = (
    typeProp,
    typeValue,
    workFieldIds,
    dispatch
) => {
    const searchForm = {
        page: 1,
        place_type: typeValue,
        ids: workFieldIds,
    };

    return getRequest(`companies/work_fields/points`, searchForm).then(
        (response) => {
            console.log(response.data.data);
            dispatch(
                setRequestsProps([
                    [`totals.${typeProp}`, response.data?.data?.pagination?.total],
                ])
            );
        }
    );
};

export const getCompainesWFPoint = (isSearching, searchForm) => (dispatch) => {
    dispatch({
        type: UPDATE_REQUESTS_PROPS,
        payload: [
            {prop: "requestsObj.requestsItem", value: null},
            {
                prop: "requestsObj.pointsItem",
                value: null,
            },
            // { prop: "requestsObj.coordinates", value: [] },
        ],
    });
    if (isSearching === false) {
        dispatch({
            type: UPDATE_REQUESTS_PROPS,
            payload: [{prop: "requestsObj.isLoading", value: true}],
        });
    } else {
        dispatch({
            type: UPDATE_REQUESTS_PROPS,
            payload: [{prop: "isLoadingSearch", value: true}],
        });
    }

    let url = "/companies/work_fields/";
    let newSearchForm;
    if (searchForm !== null) {
        var values = "";
        searchForm.ids.map((d, i) => {
            if (i === 0) {
                values = "ids[]=" + d.value;
            } else {
                values += "&ids[]=" + d.value;
            }
        });

        url += "points";

        let searchItems = {
            ids: searchForm.ids.map((id) => id.value),
            id: searchForm.id,
            page: searchForm.page,
            from: searchForm.from,
            to: searchForm.to,
            place_type: searchForm.place_type,
            name: searchForm.name,
            title: searchForm.title,
            merchant_id: searchForm.merchant_id,
        };

        if (searchForm.withoutEmployees) searchItems.without_workers = true;
        newSearchForm = removeUnsetValues(searchItems);
        if (
            Object.keys(newSearchForm).length !== 0 &&
            newSearchForm.constructor === Object
        ) {
        }
    }
    Request(newSearchForm)
        .get(url)
        .then((res) => {
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    dispatch({
                        type: UPDATE_REQUESTS_PROPS,
                        payload: [
                            {
                                prop: "requestsObj.pointsItem",
                                value: res.data.data,
                            },
                        ],
                    });
                }
                if (isSearching === true && res.data.data.data.length === 0) {
                    confirmSunccessed(local.NoSearch);
                }
            } else {
                Object.values(res.data.error).map((msg) => {
                    toaster.notify(msg[0], {
                        duration: 2000,
                    });
                });
            }
            dispatch({
                type: UPDATE_REQUESTS_PROPS,
                payload: [
                    {prop: "requestsObj.isLoading", value: false},
                    {prop: "isLoadingSearch", value: false},
                ],
            });
        })
        .catch((err) => {
            console.log(err);
            dispatch({
                type: UPDATE_REQUESTS_PROPS,
                payload: [
                    {prop: "requestsObj.isLoading", value: false},
                    {prop: "isLoadingSearch", value: false},
                ],
            });
        })
        .finally();
};

export const getCompainesWFPointForDropDown =
    (value, worker_type, name) => (dispatch) => {
        let url = "/companies/work_fields/points?ids[]=" + value;
        if (name !== undefined) {
            url = url + "&name=" + name;
        }
        Request()
            .get(url)
            .then((res) => {
                if (res.data.status === true) {
                    if (res.data.data !== null) {
                        let result = [];
                        let filterData = null;
                        if (worker_type === "Sales") {
                            filterData = res.data.data.data.filter(
                                (item) => item.place_type === 4
                            );
                        } else {
                            filterData = res.data.data.data;
                        }

                        filterData.map((r) => {
                            result.push({
                                label: r.name,
                                value: r.id,
                                id: r.id,
                                properties: r.properties,
                                location: r.location,
                                work_field_id: value,
                                ...r,
                            });
                        });
                        dispatch({
                            type: UPDATE_REQUESTS_PROPS,
                            payload: [{prop: "pointsOptions", value: result}],
                        });
                    }
                }
            })
            .catch((err) => {
                console.log(err);
            })
            .finally();
    };

export const createORUpdatePoint =
    (formValues, index, options, isModal, companyWFpointsOptions) =>
        (dispatch) => {
            dispatch({
                type: UPDATE_REQUESTS_PROPS,
                payload: [{prop: "pointForm.isLoading", value: true}],
            });
            const reqName = JSON.parse(localStorage.getItem("SERVICE_USER")).name;
            const reqEmail = JSON.parse(localStorage.getItem("SERVICE_USER")).email;


            let url = null;
            if (formValues.id === undefined) {
                url = "/companies/work_fields/points/store";
            } else {
                url = "/companies/work_fields/points/update/" + formValues.id;
            }

            let newFormValues = Object.assign({}, formValues);
            let errandValues = JSON.parse(JSON.stringify(formValues));
            console.log("newFormValues", newFormValues);
            if (!newFormValues.properties.point_radius) {
                newFormValues.properties.point_radius = "500";
            }

            if (newFormValues.properties.point_radius !== null) {
                newFormValues.properties.point_radius = Number(
                    newFormValues.properties.point_radius
                );
            }
            newFormValues.properties.requester_name = reqName;
            newFormValues.properties.requester_email = reqEmail;
            delete newFormValues.properties.errand_channel
            delete newFormValues.properties.pos_type
            delete newFormValues.properties.num_of_pos
            delete newFormValues.properties.requester_function
            newFormValues.properties = removeUnsetValues(newFormValues.properties);
            if (
                newFormValues.work_field_id !== null &&
                typeof newFormValues.work_field_id === "object"
            ) {
                newFormValues.work_field_id = newFormValues.work_field_id.value;
            }

            if (newFormValues.workers) {
                newFormValues.workers = newFormValues.workers.map((worker) => ({
                    worker_id: worker.id,
                }));

                // if (newFormValues.errand_channel) {
                //   newFormValues.
                // }
            }

            Request()
                .post(url, newFormValues)
                .then((res) => {
                    if (res.data.status === true) {
                        Request()
                            .post("/requests/store", {
                                properties: {
                                    mobile: errandValues.properties.mobile,
                                    errand_channel: errandValues.properties.errand_channel,
                                    pos_type: errandValues.properties.pos_type,
                                    num_of_pos: errandValues.properties.num_of_pos,
                                    requester_function: errandValues.properties.requester_function,
                                    requester_name: newFormValues.properties.requester_name,
                                    requester_email: newFormValues.properties.requester_email,
                                    address: newFormValues.properties.address,
                                    landmark: newFormValues.properties.landmark,
                                    comment: newFormValues.properties.comment
                                },
                                work_field_id: newFormValues.work_field_id,
                                terminal_id: errandValues.terminal_id,
                                location: errandValues.location,
                                point_id: res.data.data.id,
                                visit_type: newFormValues.visit_type,
                            })
                            .then((data) => {
                                if (formValues.id === undefined) {
                                    confirmSunccessed(local.SuccessfullyAdded);
                                    window.location.reload('/addpoint')
                                    if (index === null && options !== null) {
                                        // dispatch({
                                        //   type: MERGE_REQUESTS_PROPS,
                                        //   payload: [
                                        //     {
                                        //       prop: "requestsObj.requestsItem.data",
                                        //       value: res.data.data,
                                        //     },
                                        //   ],
                                        // });
                                    }
                                } else {
                                    confirmSunccessed(local.SuccessfullyEdit);

                                    if (index !== null && options !== null) {
                                        dispatch({
                                            type: UPDATE_REQUESTS_PROPS,
                                            payload: [
                                                {
                                                    prop: "requestsObj.requestsItem.data." + index,
                                                    value: {point: res.data.data},
                                                },
                                            ],
                                        });
                                    }
                                }
                            });


                        dispatch(
                            setRequestsProps([["lastCompanyWFPointAdded", res.data.data]])
                        );

                        dispatch(clearRequestsForm());
                        dispatch(clearRequestsModal());

                        // dispatch({
                        //   type: DELETE_REQUESTS_PROPS,
                        //   payload: [{ prop: "polygonItem" }],
                        // });
                        let previousPlaceName = companyWFpointsOptions.find(
                            (i) => i.value === formValues.work_field_id
                        );

                        dispatch(
                            setRequestsProps([
                                [
                                    "lastCompanyWFPointAdded.previousPlace",
                                    previousPlaceName.label,
                                ],
                            ])
                        );
                    } else {
                        Object.values(res.data.error).map((msg) => {
                            toaster.notify(msg[0], {
                                duration: 2000,
                            });
                        });
                    }

                    dispatch({
                        type: UPDATE_REQUESTS_PROPS,
                        payload: [{prop: "pointForm.isLoading", value: false}],
                    });
                })
                .catch((err) => {
                    dispatch({
                        type: UPDATE_REQUESTS_PROPS,
                        payload: [{prop: "pointForm.isLoading", value: false}],
                    });
                    console.log(err);
                })
                .finally();
        };

export const getPoint = (id, dropDownItem, profile) => (dispatch) => {
    Request()
        .get("/companies/work_fields/points/show/" + Number(id))
        .then((res) => {
            if (res.data.status === true) {
                if (res.data.data !== null) {
                    if (dropDownItem === false) {
                        if (profile === undefined) {
                            dispatch({
                                type: UPDATE_REQUESTS_PROPS,
                                payload: [
                                    {
                                        prop: "pointForm.formvalues",
                                        value: res.data.data,
                                    },
                                    {
                                        prop: "pointForm.formvalues.company_id",
                                        value: res.data.data.company.id,
                                    },
                                ],
                            });
                        } else {
                            dispatch({
                                type: UPDATE_REQUESTS_PROPS,
                                payload: [
                                    {
                                        prop: "modaleView.data",
                                        value: res.data.data,
                                    },
                                    {
                                        prop: "modaleView.isOpen",
                                        value: true,
                                    },
                                    {
                                        prop: "modaleView.title",
                                        value: local.WorkFieldPoint,
                                    },
                                ],
                            });
                        }
                    } else {
                        let result = [
                            {label: res.data.data.name, value: res.data.data.id},
                        ];

                        dispatch({
                            type: UPDATE_REQUESTS_PROPS,
                            payload: [{prop: "companyWfOptions", value: result}],
                        });
                    }
                }
            }
        })
        .catch((err) => {
            console.log(err);
        })
        .finally();
};

export const loadpointsOptions =
    (name, loadedOptions, page, company_field_id) => async (dispatch) => {
        let url = null;
        var values = "";

        if (typeof company_field_id === "number") {
            values = "ids[]=" + company_field_id;
        }

        if (Array.isArray(company_field_id)) {
            company_field_id.map((d, i) => {
                if (i === 0) {
                    values = "ids[]=" + d.value;
                } else {
                    values += "&ids[]=" + d.value;
                }
            });
        }

        if (company_field_id !== null) {
            if (name == undefined) {
                url =
                    Select_Request.url +
                    "/companies/work_fields/points?" +
                    values +
                    "&page=" +
                    page.page;
            } else {
                if (name !== "") {
                    url =
                        Select_Request.url +
                        "/companies/work_fields/points?" +
                        values +
                        "&name=" +
                        name +
                        "&page=" +
                        page.page;
                } else {
                    url =
                        Select_Request.url +
                        "/companies/work_fields/points?" +
                        values +
                        "&page=" +
                        page.page;
                }
            }

            let response = await fetch(url, Select_Request.options);
            let responseOK = response && response.ok;

            if (responseOK) {
                let result = await response.json();

                let options = [];

                if (result.status === true) {
                    if (result.data.data !== null) {
                        result.data.data.map((r) => {
                            options.push({
                                label: r.name,
                                value: r.id,
                                id: r.id,
                                properties: r.properties,
                                location: r.location,
                                work_field_id: getDeafultItem(
                                    r.work_field_id,
                                    company_field_id
                                ),
                            });
                        });
                    }

                    // dispatch({
                    //   type: UPDATE_REQUESTS_PROPS,
                    //   payload: [{ prop: "pointsOptions", value: options }],
                    // });
                }

                let entry = {
                    result: result.data.data,
                    has_more:
                        result.data.pagination.current_page ===
                        result.data.pagination.total_pages
                            ? false
                            : true,
                };

                // if (entry.has_more) {
                //   dispatch({
                //     type: UPDATE_REQUESTS_PROPS,
                //     payload: [{ prop: "pointsOptions", value: loadedOptions }],
                //   });
                // } else {
                //   dispatch({
                //     type: UPDATE_REQUESTS_PROPS,
                //     payload: [
                //       {
                //         prop: "pointsOptions",
                //         value: [...loadedOptions, ...options],
                //       },
                //     ],
                //   });
                // }

                dispatch({
                    type: UPDATE_REQUESTS_PROPS,
                    payload: [
                        {
                            prop: "pointsOptions",
                            value: [...loadedOptions, ...options],
                        },
                    ],
                });

                return {
                    options: options,
                    hasMore: entry.has_more,
                    additional: {
                        page: page.page + 1,
                    },
                };
            }
        } else {
            return {
                options: [],
                hasMore: false,
                additional: {
                    page: 1,
                },
            };
        }
    };

export const getAllPoints =
    (work_fields, prop, action, coordinates) => async (dispatch) => {
        dispatch({
            type: UPDATE_REQUESTS_PROPS,
            payload: [
                {prop: "pointForm.work_fields_points", value: []},
                {prop: "pointForm.coordinates", value: []},
            ],
        });

        if (typeof work_fields === "number") {
            let url = "companies/work_fields/points/list/" + work_fields;

            await Request()
                .get(url)
                .then((res) => {
                    if (res.data.status === true) {
                        if (res.data.data !== null) {
                            if (prop === undefined) {
                                dispatch({
                                    type: UPDATE_REQUESTS_PROPS,
                                    payload: [
                                        {
                                            prop: "pointForm.work_fields_points",
                                            value: res.data.data,
                                        },
                                    ],
                                });
                            } else {
                                dispatch({
                                    type: action,
                                    payload: [
                                        {
                                            prop: prop,
                                            value: res.data.data,
                                        },
                                    ],
                                });
                            }
                        }
                    }
                })
                .catch((err) => {
                    console.log(err);
                })
                .finally();
        } else {
            return work_fields.map((workField) => {
                let url = "companies/work_fields/points/list/" + workField.value;
                Request()
                    .get(url)
                    .then((res) => {
                        if (res.data.status === true) {
                            if (res.data.data !== null) {
                                dispatch({
                                    type: action,
                                    payload: [
                                        {
                                            prop: prop,
                                            value: res.data.data,
                                        },
                                    ],
                                });
                            }
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    })
                    .finally();
            });
        }
    };
