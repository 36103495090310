import React, { useState, useEffect } from "react";
import { Form, Card, Col, Row, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { Mapy } from "mapy_sdk/lib";
import * as actions from "../../redux/actions/index";
import LightBoxFile from "../../utils/LightBoxFile";
import {
  filterEmployeesByWorkField,
  onSave,
  onWorkFieldChangeCB,
  ZoomToUserLocationBtn,
} from "./RequestsFunctions";
import { labelStyle, leftlStyle, selectStyle3 } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import icon_btn from "../../assets/img/icon_btn.png";

import { UPDATE_REQUESTS_PROPS } from "../../redux/actions/types";
import BoxLink from "../../utils/BoxLink";
import {
  renderGeneralInput,
  renderArraw,
  getMyCompany,
  checkHasOneCompany,
  getEmployeeOptions,
  handleError,
  isPointInPolygon,
  getDeafultItem,
} from "../../utils/GeneralFunctions";
import SearchBox from "../../utils/SearchBox";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderWorkFSelect } from "../CompainesWorkFields/CompanyWFFunctions";
import { unsetCompanyWFPListData } from "./RequestsFunctions";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
import GenericMapy from "../../components/GenericMapy/GenericMapy";
import { SelectPointsMarkers } from "../../redux/selectors/CompanyWFPointSelector";

import Wizard from "./Wizard/Wizard";

import {
  pointObjectToWKT,
  WKTPolygonToGeoJson,
} from "../../components/GenericMapy/utils";
import { visitTypeOptions } from "../../utils/visitTypeOptions";
import { placeTypeOptions } from "../../utils/placeTypeOptions";
import Select from "react-select";
let map = new Mapy();

const AddPoint = (props) => {
  useEffect(() => {
    prepareDate();
  }, []);
  const [mapBounds, setMapBounds] = useState(undefined);

  const MapBounds = (mapBounds) => {
    setMapBounds(mapBounds);
  };

  const onMapClick = (latLng, e) => {
    const lng = latLng.lng;
    const lat = latLng.lat;

    const { polygonItem, general } = props;

    if (polygonItem) {
      const geoJsonPolygon = WKTPolygonToGeoJson(polygonItem);
      if (isPointInPolygon(lng, lat, geoJsonPolygon)) {
        const WKTPoint = pointObjectToWKT(latLng);

        general(
          [{ prop: "pointForm.formvalues.location", value: WKTPoint }],
          UPDATE_REQUESTS_PROPS
        );
      } else {
        handleError(local.PointOutside);
      }
    }
  };

  // onWorkFieldChangeCB = (workField) => {
  //   const workFieldPolygon = workField.location;
  //   const geoJsonPoly = WKTtoGeoJson(workFieldPolygon);
  //   const latLngReversedPoly = reverseLatLngBoundsOrder(geoJsonPoly);
  //   this.setMapBounds(latLngReversedPoly);
  // };

  const prepareDate = () => {
    const { general } = props;
    unsetCompanyWFPListData(general);

    general(
      [{ prop: "pointForm.company", value: getMyCompany(general) }],
      UPDATE_REQUESTS_PROPS
    );
  };

  const {
    requestsObj,
    pointForm,
    createORUpdatePoint,
    general,
    polygonItem,
    companyWfOptions,
    history,
    companyOptions,
    lightbox,
    searchOptions,
    get_search,
    loadCompaniesOptions,
    loadCompaniesWFOptions,
    getAllPoints,
    branchOptions,
    loadBranchesOptions,
    lastCompanyWFPointAdded,
    setRequestsProps,
    markers,
  } = props;

  // return (
  //   <div className="page-wrapper">
  //     <LightBoxFile
  //       lightbox={lightbox}
  //       general={general}
  //       action={UPDATE_REQUESTS_PROPS}
  //     />

  //     <Row>
  //       <Col>
  //         <Form
  //           onSubmit={(e) =>
  //             onSave(e, createORUpdatePoint, pointForm, requestsObj, false)
  //           }
  //         >
  //           <Card className="cardCreate mt-4">
  //             <div className="mt-5 mr-5 ml-5">
  //               <p style={leftlStyle}>{local.AddCompanyWFPoint}</p>
  //               {renderArraw(history)}
  //             </div>

  //             <div className="mt-3">
  //               <p className="page_title" style={leftlStyle}>
  //                 {local.MainInfo}
  //               </p>
  //               <br />
  //               <Row>
  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   {renderGeneralInput(
  //                     "pointForm.formvalues.name",
  //                     pointForm.formvalues.name,
  //                     UPDATE_REQUESTS_PROPS,
  //                     general,
  //                     local.Name
  //                   )}
  //                 </Col>

  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   {renderGeneralInput(
  //                     "pointForm.formvalues.properties.address",
  //                     pointForm.formvalues.properties.address,
  //                     UPDATE_REQUESTS_PROPS,
  //                     general,
  //                     local.Address
  //                   )}
  //                 </Col>
  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   {renderGeneralInput(
  //                     "pointForm.formvalues.terminal_id",
  //                     pointForm.formvalues.terminal_id,
  //                     UPDATE_REQUESTS_PROPS,
  //                     general,
  //                     local.TerminalId,
  //                     "number"
  //                   )}
  //                 </Col>

  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   {renderGeneralInput(
  //                     "pointForm.formvalues.merchant_id",
  //                     pointForm.formvalues.merchant_id,
  //                     UPDATE_REQUESTS_PROPS,
  //                     general,
  //                     local.MerchantId,
  //                     "number"
  //                   )}
  //                 </Col>
  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   {renderGeneralInput(
  //                     "pointForm.formvalues.properties.point_radius",
  //                     pointForm.formvalues.properties.point_radius,
  //                     UPDATE_REQUESTS_PROPS,
  //                     general,
  //                     local.Radius,
  //                     "number"
  //                   )}
  //                 </Col>

  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   <span className="label_title" style={labelStyle}>
  //                     {local.PlaceType}
  //                   </span>
  //                   <Select
  //                     key={pointForm.formvalues.place_type}
  //                     options={placeTypeOptions}
  //                     value={getDeafultItem(
  //                       pointForm.formvalues.place_type,
  //                       placeTypeOptions
  //                     )}
  //                     placeholder={local.PlaceType}
  //                     className="mt-2"
  //                     getOptionLabel={(op) => op.label}
  //                     getOptionValue={(op) => op.value}
  //                     styles={selectStyle3}
  //                     isClearable={true}
  //                     onChange={(e) =>
  //                       e
  //                         ? general(
  //                             [
  //                               {
  //                                 prop: "pointForm.formvalues.place_type",
  //                                 value: e.value,
  //                               },
  //                             ],
  //                             UPDATE_REQUESTS_PROPS
  //                           )
  //                         : general(
  //                             [
  //                               {
  //                                 prop: "pointForm.formvalues.place_type",
  //                                 value: null,
  //                               },
  //                             ],
  //                             UPDATE_REQUESTS_PROPS
  //                           )
  //                     }
  //                   />
  //                 </Col>
  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   <span className="label_title" style={labelStyle}>
  //                     {local.VisitType}
  //                   </span>
  //                   <Select
  //                     key={pointForm.formvalues.visit_type}
  //                     options={visitTypeOptions}
  //                     value={getDeafultItem(
  //                       pointForm.formvalues.visit_type,
  //                       visitTypeOptions
  //                     )}
  //                     placeholder={local.VisitType}
  //                     className="mt-2"
  //                     getOptionLabel={(op) => op.label}
  //                     getOptionValue={(op) => op.value}
  //                     styles={selectStyle3}
  //                     isClearable={true}
  //                     onChange={(e) =>
  //                       e
  //                         ? general(
  //                             [
  //                               {
  //                                 prop: "pointForm.formvalues.visit_type",
  //                                 value: e.value,
  //                               },
  //                             ],
  //                             UPDATE_REQUESTS_PROPS
  //                           )
  //                         : general(
  //                             [
  //                               {
  //                                 prop: "pointForm.formvalues.visit_type",
  //                                 value: null,
  //                               },
  //                             ],
  //                             UPDATE_REQUESTS_PROPS
  //                           )
  //                     }
  //                   />
  //                 </Col>
  //                 {pointForm.formvalues.id === undefined ? (
  //                   <>
  //                     {checkHasOneCompany() === false ? (
  //                       <Col
  //                         xl={3}
  //                         lg={3}
  //                         md={3}
  //                         sm={12}
  //                         xs={12}
  //                         className="mt-2"
  //                       >
  //                         <span className="label_title" style={labelStyle}>
  //                           {local.Company}
  //                         </span>
  //                         {renderCompanySelect(
  //                           "pointForm.company",
  //                           pointForm.company,
  //                           UPDATE_REQUESTS_PROPS,
  //                           general,
  //                           local.Company,
  //                           loadCompaniesOptions,
  //                           companyOptions,
  //                           null,
  //                           [
  //                             {
  //                               p: "pointForm.formvalues.work_field_id",
  //                               v: null,
  //                             },
  //                             {
  //                               p: "pointForm.branch_id",
  //                               v: null,
  //                             },
  //                             {
  //                               p: "pointForm.work_fields_points",
  //                               v: [],
  //                             },

  //                             {
  //                               p: "pointForm.formvalues.workers",
  //                               v: null,
  //                             },
  //                           ],
  //                           "4",
  //                           pointForm.iscreate
  //                         )}
  //                       </Col>
  //                     ) : null}
  //                     <Col
  //                       xl={3}
  //                       lg={3}
  //                       md={3}
  //                       sm={12}
  //                       xs={12}
  //                       className="mt-2"
  //                     >
  //                       <span className="label_title" style={labelStyle}>
  //                         {local.Branch}
  //                       </span>
  //                       {renderBranchSelect(
  //                         "pointForm.branch_id",
  //                         pointForm.branch_id,
  //                         UPDATE_REQUESTS_PROPS,
  //                         general,
  //                         local.Branch,
  //                         loadBranchesOptions,
  //                         branchOptions,
  //                         pointForm.company,
  //                         null,
  //                         [
  //                           {
  //                             p: "pointForm.formvalues.work_field_id",
  //                             v: null,
  //                           },

  //                           {
  //                             p: "pointForm.work_fields_points",
  //                             v: [],
  //                           },
  //                           {
  //                             p: "pointForm.formvalues.workers",
  //                             v: null,
  //                           },
  //                         ],
  //                         "4",
  //                         pointForm.iscreate
  //                       )}
  //                     </Col>
  //                   </>
  //                 ) : null}
  //                 <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
  //                   <span className="label_title" style={labelStyle}>
  //                     {local.WorkPlaces}
  //                   </span>

  //                   {renderWorkFSelect(
  //                     "pointForm.formvalues.work_field_id",
  //                     pointForm.formvalues.work_field_id,
  //                     UPDATE_REQUESTS_PROPS,
  //                     general,
  //                     local.WorkPlaces,
  //                     loadCompaniesWFOptions,
  //                     companyWfOptions,
  //                     null,
  //                     ["pointForm.formvalues.workers"],
  //                     pointForm.branch_id,
  //                     "4",
  //                     "polygonItem",
  //                     pointForm.iscreate,
  //                     getAllPoints,
  //                     (workField) =>
  //                       onWorkFieldChangeCB(workField, MapBounds)
  //                   )}
  //                 </Col>

  //                 <CustomAsyncPaginate
  //                   loadOptions={getEmployeeOptions(pointForm.company)}
  //                   value={pointForm.formvalues.workers}
  //                   placeholder={local.Worker}
  //                   isMulti={true}
  //                   onChange={(e) => {
  //                     setRequestsProps([["pointForm.formvalues.workers", e]]);
  //                   }}
  //                   cacheUniqsArr={[pointForm.company]}
  //                   additional={{
  //                     page: 1,
  //                     companyId: pointForm.company,
  //                   }}
  //                   filterOption={
  //                     pointForm.formvalues.work_field_id
  //                       ? (option, rawInput) =>
  //                           filterEmployeesByWorkField(
  //                             option,
  //                             rawInput,
  //                             pointForm.formvalues.work_field_id
  //                           )
  //                       : undefined
  //                   }
  //                   className="react-select-div mt-2"
  //                   hasLabel={true}
  //                 />

  //                 <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
  //                   <SearchBox
  //                     searchOptions={searchOptions}
  //                     endPoint={get_search}
  //                     action={UPDATE_REQUESTS_PROPS}
  //                     general={general}
  //                     map={map}
  //                   />
  //                 </Col>

  //                 <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
  //                   <BoxLink
  //                     general={general}
  //                     prop="modale.mapItem.mapPoints"
  //                     action={UPDATE_REQUESTS_PROPS}
  //                     FormProp={"pointForm.formvalues.location"}
  //                     work_field_id={pointForm.formvalues.work_field_id}
  //                     polygonItem={polygonItem}
  //                     mapZoomProp={"requestsObj.mapZoom"}
  //                     saveLink="pointForm.formvalues.properties.link"
  //                     valueLink={pointForm.formvalues.properties.link}
  //                     map={map}
  //                   />
  //                 </Col>
  //               </Row>
  //               <Row className="mt-5">
  //                 {/* <Col>{renderMyLocationButton(general)}</Col> */}
  //                 <Col>
  //                   <ZoomToUserLocationBtn
  //                     setRequestsProps={setRequestsProps}
  //                   />
  //                 </Col>
  //               </Row>
  //               <Row className="mt-3">
  //                 <Col xl={12} lg={12} md={12} sm={12} xs={12}>
  //                   {lastCompanyWFPointAdded && (
  //                     <div className="mb-3">
  //                       {local.PreviousPlace}:{" "}
  //                       {lastCompanyWFPointAdded?.previousPlace}
  //                     </div>
  //                   )}
  //                   {/* <div
  //                       className="too-locations-btn"
  //                       style={{ width: "fit-content" }}
  //                       onClick={() =>
  //                         zoomToUserLocation(setRequestsProps)
  //                       }
  //                     >
  //                       {local.ZoomToMyLocation}
  //                     </div> */}
  //                   <Card style={{ borderRadius: "10px", height: "400px" }}>
  //                     {/* <GeneralMap
  //                         {...this.props}
  //                         general={general}
  //                         // mapZoom={requestsObj.mapZoom}
  //                         formvalues={pointForm.formvalues}
  //                         action={UPDATE_REQUESTS_PROPS}
  //                         modale={modale}
  //                         icClickable={true}
  //                         prop="pointForm.formvalues.location"
  //                         type="companywfpoint"
  //                         drawingPolygon={polygonItem}
  //                         bounds={mapBounds}
  //                         work_fields_points={
  //                           pointForm.work_fields_points
  //                         }
  //                       /> */}

  //                     <GenericMapy
  //                       map={map}
  //                       mapID="addWorkFieldPointMap"
  //                       singlePolygon={polygonItem}
  //                       onMapClick={onMapClick}
  //                       markers={markers}
  //                       zoomToMarkers={false}
  //                     />
  //                   </Card>
  //                 </Col>
  //               </Row>
  //               <center>
  //                 <button
  //                   type="submit"
  //                   disabled={pointForm.isLoading}
  //                   className="save_btn mt-3"
  //                   onClick={(e) =>
  //                     onSave(
  //                       e,
  //                       createORUpdatePoint,
  //                       pointForm,
  //                       requestsObj,
  //                       false,
  //                       companyWfOptions
  //                     )
  //                   }
  //                 >
  //                   {pointForm.isLoading === true ? (
  //                     <Spinner type="grow" color="dark" size="sm" />
  //                   ) : (
  //                     <>
  //                       <p style={{ display: "inline" }}>{local.Save}</p>
  //                       <img src={icon_btn} width="20" height="20" alt="img" />
  //                     </>
  //                   )}
  //                 </button>
  //               </center>
  //             </div>
  //           </Card>
  //         </Form>
  //         <br />
  //       </Col>
  //     </Row>
  //   </div>
  // );
  return <Wizard />;
};

const mapStateToProps = ({
  RequestsR,
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
  MapReducer,
}) => {
  return {
    pointForm: RequestsR.pointForm,
    jobTitleOptions: RequestsR.jobTitleOptions,
    modale: RequestsR.modale,
    requestsObj: RequestsR.requestsObj,
    typeOptions: RequestsR.typeOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    polygonItem: RequestsR.polygonItem,
    lightbox: RequestsR.lightbox,
    searchOptions: RequestsR.searchOptions,
    work_fields_points: RequestsR.work_fields_points,
    branchOptions: BranchR.branchOptions,
    lastCompanyWFPointAdded: RequestsR.lastCompanyWFPointAdded,
    MapReducer: MapReducer,
    markers: SelectPointsMarkers(
      RequestsR.pointForm.work_fields_points,
      RequestsR.pointForm.formvalues.location
    ),
  };
};

export default connect(mapStateToProps, actions)(AddPoint);
