import { Table } from "reactstrap";
import { renderTableHeadItem } from "../../utils/GeneralFunctions";
import { getLanguage, local } from "../../lang/local";
import ARROW_DOWN from "../../assets/img/tbl_sort.png";
import ARROW_UP from "../../assets/img/up_img.png";

const GiftsAndSamplesReport = ({
  tableHeaders,
  giftsReportData,
  sortGiftsReport,
  giftsReportSorting,
}) => {
  const renderTableBody = () => {
    let item = [];
    for (let i = 0; i <= giftsReportData.data.length - 1; i++) {
      console.log(giftsReportData.data[i].type === 0 ? "Gift" : "Sample");
      item.push(
        <tr>
          <td className="td_table">
            {giftsReportSorting.sortedArray[i].gift.type === 0
              ? local.Gift
              : local.Sample}
          </td>
          <td className="td_table">
            {giftsReportSorting.sortedArray[i].gift.name}
          </td>
          <td className="td_table">
            {giftsReportSorting.sortedArray[i].gift.details}
          </td>
          <td className="td_table">
            {getLanguage() === "ar"
              ? giftsReportSorting.sortedArray[i].gift.company.name.ar
              : giftsReportSorting.sortedArray[i].gift.company.name.en}
          </td>
          <td className="td_table">
            {getLanguage() === "ar"
              ? giftsReportSorting.sortedArray[i].work_field.name.ar
              : giftsReportSorting.sortedArray[i].work_field.name.en}
          </td>
          <td className="td_table">
            {giftsReportSorting.sortedArray[i].point.name}
          </td>
          <td className="td_table">
            {giftsReportSorting.sortedArray[i].worker.name}
          </td>
          <td className="td_table">
            {giftsReportSorting.sortedArray[i].amount}
          </td>
        </tr>
      );
    }
    return item;
  };

  return giftsReportData ? (
    <div className="mb-4">
      <div className="page_title my-3" style={{ textAlign: "start" }}>
        {local.GiftsAndSamplesReport}
      </div>
      <Table
        responsive
        className="table_list mt-4 mb-4"
        hover
        style={{ borderTopColor: "white", border: "none" }}
        id="state_table"
      >
        <thead>
          <tr className="table_head">
            <th className="td_table">
              {tableHeaders[0]}
              {giftsReportSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["gift", "type"],
                      "desc"
                    )
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["gift", "type"],
                      "asc"
                    )
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>
            <th className="td_table">
              {tableHeaders[1]}
              {giftsReportSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["gift", "name"],
                      "desc"
                    )
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["gift", "name"],
                      "asc"
                    )
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>

            {renderTableHeadItem(tableHeaders[2])}
            {renderTableHeadItem(tableHeaders[3])}
            {/* <th className="td_table">{tableHeaders[2]}
              {
                giftsReportSorting.order === "asc" ?
                <img onClick={() => sortGiftsReport(giftsReportData, ["gift", "type"], "desc")} src={ARROW_UP} alt="arrow down" width="13" height="13" className="mb-1 ml-1" role="button" /> :
                <img onClick={() => sortGiftsReport(giftsReportData, ["gift", "type"], "asc")} src={ARROW_DOWN} alt="arrow down" width="13" height="13" className="mb-1 ml-1" role="button" />
              }
            </th>
            <th className="td_table">{tableHeaders[3]}
              {
                giftsReportSorting.order === "asc" ?
                <img onClick={() => sortGiftsReport(giftsReportData, ["gift", "type"], "desc")} src={ARROW_UP} alt="arrow down" width="13" height="13" className="mb-1 ml-1" role="button" /> :
                <img onClick={() => sortGiftsReport(giftsReportData, ["gift", "type"], "asc")} src={ARROW_DOWN} alt="arrow down" width="13" height="13" className="mb-1 ml-1" role="button" />
              }
            </th> */}
            <th className="td_table">
              {tableHeaders[4]}
              {giftsReportSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["work_field", "name", "en"],
                      "desc"
                    )
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["work_field", "name", "en"],
                      "asc"
                    )
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>
            <th className="td_table">
              {tableHeaders[5]}
              {giftsReportSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["point", "name"],
                      "desc"
                    )
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["point", "name"],
                      "asc"
                    )
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>
            <th className="td_table">
              {tableHeaders[6]}
              {giftsReportSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["worker", "name"],
                      "desc"
                    )
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsReport(
                      giftsReportData.data,
                      ["worker", "name"],
                      "asc"
                    )
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>
            <th className="td_table">
              {tableHeaders[7]}
              {giftsReportSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsReport(giftsReportData.data, ["amount"], "desc")
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsReport(giftsReportData.data, ["amount"], "asc")
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>
            {/* renderTableHeadItem(tableHeaders[0]) */}
            {/* renderTableHeadItem(tableHeaders[1]) */}
            {/* renderTableHeadItem(tableHeaders[2]) */}
            {/* renderTableHeadItem(tableHeaders[3]) */}
            {/* renderTableHeadItem(tableHeaders[4]) */}
            {/* renderTableHeadItem(tableHeaders[5]) */}
            {/* renderTableHeadItem(tableHeaders[6]) */}
          </tr>
        </thead>
        <tbody>{giftsReportData ? renderTableBody() : ""}</tbody>
      </Table>
    </div>
  ) : (
    ""
  );
};

export default GiftsAndSamplesReport;
