import { CardBody, CardHeader } from "reactstrap";
import { useEffect, useState } from "react";
import { local } from "../../lang/local";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
  Chart,
  Interval,
  Axis,
  Coordinate,
  Interaction,
  Legend,
} from "bizcharts";

export const EranedType = ({ errandtypes, colors }) => {
  const [items, setItems] = useState([]);
  const isViewReset = () => {
    if (items.length > 0) {
      if (items.find((entry) => !entry.visable)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleReset = () => {
    let entries = [...items];
    entries.map((ent) => {
      ent.visable = true;
    });
    setItems(entries);
  };

  useEffect(() => {
    if (errandtypes.length > 0 && items.length === 0) {
      let newData = errandtypes.map((d, index) => {
        d.visable = true;
        d.color = colors[index];
        return d;
      });
      setItems(newData);
    }
  }, [errandtypes]);

  const cols = {
    percent: {
      formatter: (val) => {
        val = val * 100 + "%";
        return val;
      },
    },
  };

  const onClickList = (e, index) => {
    e.preventDefault();
    let entries = [...items];
    let entry = entries[index];
    entry.visable = !entry.visable;
    setItems(entries);
  };

  const getItem = (item, index) => {
    return (
      <Grid item xl={2} lg={3} md={4} sm={6} xs={6} key={index}>
        <Tooltip title={item.percent} arrow>
          <Paper elevation={3} sx={{ cursor: "pointer", height: "70px" }}>
            <div
              className="fullWH center referrals-item-container"
              style={{
                textDecoration: !item.visable ? "line-through" : "none",
              }}
              onClick={(e) => onClickList(e, index)}
            >
              <div>
                <p
                  className="margin0 text-center count"
                  style={{ color: item.color }}
                >
                  {item.count}
                </p>
                <p className="referrals-item-container-text">
                  <div
                    className="referrals-item-circle"
                    style={{ backgroundColor: item.color }}
                  />

                  <div className="title text-center">{item.type}</div>
                </p>
              </div>
            </div>
          </Paper>
        </Tooltip>
      </Grid>
    );
  };
  return (
    <div className="inner-container">
      <CardHeader className="header">
        <div>
          <h5>{local.EranedType}</h5>
        </div>
        {isViewReset() ? (
          <div>
            <a className="reset" onClick={handleReset}>
              {local.Reset}
            </a>
          </div>
        ) : null}
      </CardHeader>
      <CardBody className="body">
        <Grid container spacing={1}>
          <Grid container item spacing={1}>
            {items &&
              items.map((item, index) => {
                return getItem(item, index);
              })}
          </Grid>
        </Grid>

        <div className="center">
          {items.length > 0 ? (
            <Chart
              height={400}
              data={items}
              // width={400}
              scale={cols}
              autoFit
              onGetG2Instance={(c) => {
                c.geometries[0].elements.forEach((e, idx) => {
                  e.setState("selected", idx === 0 ? true : false);
                });
              }}
            >
              <Coordinate type="theta" radius={0.7} />
              {/* <Tooltip showTitle={false} /> */}
              <Axis visible={false} />
              <Interval
                position="percent"
                adjust="stack"
                color="type"
                style={{
                  lineWidth: 1,
                  stroke: "#fff",
                  color: "red",
                }}
              />
              <Legend visible={false} />
              <Interaction type="element-single-selected" />
            </Chart>
          ) : null}
        </div>
      </CardBody>
    </div>
  );
};
