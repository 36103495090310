import React from 'react';
import { Col, Row, CardBody, Spinner } from 'reactstrap';
import { getLanguage, local } from '../../lang/local';
import { UPDATE_REQUESTS_PROPS } from '../../redux/actions/types';
import icon_btn from '../../assets/img/icon_btn.png';
import Flatpickr from 'react-flatpickr';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import {
  getDeafultItem,
  getEmployeeOptions,
  renderGeneralInput,
} from '../../utils/GeneralFunctions';
import { labelStyle, selectStyle3 } from '../../utils/GeneralStyle';
import { filterEmployeesByWorkField, onSave } from './RequestsFunctions';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
import { placeTypeOptions } from '../../utils/placeTypeOptions';
import Select from 'react-select';

const FirstTab = (props) => {
  const {
    general,
    pointForm,
    setRequestsProps,
    requestsObj,
    history,
    createORUpdatePoint,
  } = props;


  return (
    <>
      <CardBody className='trans'>
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <Select
              key={pointForm.formvalues.place_type}
              options={placeTypeOptions}
              value={getDeafultItem(
                pointForm.formvalues.place_type,
                placeTypeOptions
              )}
              placeholder={local.PlaceType}
              className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={selectStyle3}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: 'pointForm.formvalues.place_type',
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: 'pointForm.formvalues.place_type',
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
        </Row>

        <Row className='mt-4'>
          <CustomAsyncPaginate
            columns='12'
            loadOptions={getEmployeeOptions(pointForm.company)}
            value={pointForm.formvalues.workers}
            placeholder={local.Worker}
            isMulti={true}
            onChange={(e) => {
              setRequestsProps([
                ['pointForm.formvalues.workers', e],
              ]);
            }}
            cacheUniqsArr={[pointForm.company]}
            additional={{
              page: 1,
              companyId: pointForm.company,
            }}
            filterOption={
              pointForm.formvalues.work_field_id
                ? (option, rawInput) =>
                    filterEmployeesByWorkField(
                      option,
                      rawInput,
                      pointForm.formvalues.work_field_id
                    )
                : undefined
            }
            fullWidth={true}
          />
        </Row>

        {pointForm.formvalues.place_type !== null ? (
          pointForm.formvalues.place_type !== 1 &&
          pointForm.formvalues.place_type !== 2 ? (
            <React.Fragment>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-4'>
                  <span className='label_title' style={labelStyle}>
                    {local.AvailableHours + ' (' + local.From + ')'}
                  </span>

                  <Flatpickr
                    className='dateStyle'
                    data-enable-time
                    value={
                      pointForm.formvalues.properties.available_time
                        ?.from
                    }
                    onChange={(time) => {
                      general(
                        [
                          {
                            prop: 'pointForm.formvalues.properties.available_time.from',
                            value: time[0].toLocaleTimeString(),
                          },
                        ],
                        UPDATE_REQUESTS_PROPS
                      );
                    }}
                    local={getLanguage() === 'en' ? null : Arabic}
                    placeholder={local.From}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'G:i:K',
                      allowInput: true,
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-4'>
                  <span className='label_title' style={labelStyle}>
                    {local.AvailableHours + ' (' + local.To + ')'}
                  </span>

                  <Flatpickr
                    className='dateStyle'
                    data-enable-time
                    value={
                      pointForm.formvalues.properties.available_time?.to
                    }
                    onChange={(time) => {
                      general(
                        [
                          {
                            prop: 'pointForm.formvalues.properties.available_time.to',
                            value: time[0].toLocaleTimeString(),
                          },
                        ],
                        UPDATE_REQUESTS_PROPS
                      );
                    }}
                    local={getLanguage() === 'en' ? null : Arabic}
                    placeholder={local.To}
                    options={{
                      enableTime: true,
                      noCalendar: true,
                      dateFormat: 'G:i:K',
                      allowInput: true,
                    }}
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : null
        ) : null}

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              'pointForm.formvalues.name',
              pointForm.formvalues.name,
              UPDATE_REQUESTS_PROPS,
              general,
              local.Name
            )}
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              'pointForm.formvalues.properties.address',
              pointForm.formvalues.properties.address,
              UPDATE_REQUESTS_PROPS,
              general,
              local.Address
            )}
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              'pointForm.formvalues.properties.point_radius',
              pointForm.formvalues.properties.point_radius,
              UPDATE_REQUESTS_PROPS,
              general,
              local.Radius,
              'number'
            )}
          </Col>
        </Row>

        <Row className='mt-4'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* {renderAddButton(
              general,
              "pointForm.formvalues.properties.mobiles",
              "",
              local.AddMobile,
              MERGE_REQUESTS_PROPS
            )} */}
          </Col>
        </Row>
        <Row className='mt-4'>
          {/* {renderPhones(
            pointForm.formvalues.properties.mobiles,
            "pointForm.formvalues.properties.mobiles.",
            UPDATE_REQUESTS_PROPS,
            general,
            "12",
            "4",
            DELETE_REQUESTS_PROPS
          )} */}
        </Row>

        {pointForm.formvalues.place_type !== 1 &&
          pointForm.formvalues.place_type !== 2 &&
          pointForm.formvalues.place_type !== 3 && (
            <center>
              <button
                type='submit'
                disabled={pointForm.isLoading}
                className='save_btn mt-3'
                onClick={(e) =>
                  onSave(
                    e,
                    createORUpdatePoint,
                    pointForm,
                    requestsObj,
                    true,
                    history
                  )
                }
              >
                {pointForm.isLoading === true ? (
                  <Spinner type='grow' color='dark' size='sm' />
                ) : (
                  <>
                    <p style={{ display: 'inline' }}>{local.Save}</p>
                    <img src={icon_btn} width='20' height='20' alt='img' />
                  </>
                )}
              </button>
            </center>
          )}
      </CardBody>
    </>
  );
};
export default FirstTab;
