import { CardBody, CardHeader } from "reactstrap";
import { useEffect, useState } from "react";
import { local } from "../../lang/local";
import Tooltip from "@mui/material/Tooltip";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
const statistics_circle_color = (percent, color) => {
  let newPercent = 100 - percent;
  if (percent > 100) {
    newPercent = percent - 100;
  }
  return `linear-gradient(transparent,transparent) padding-box,linear-gradient(to right, #F3F3F3 ${newPercent}%, ${color} ${percent}%) border-box`;
};

export const Referrals = ({ departments, colors }) => {
  const [items, setItems] = useState([]);

  useEffect(() => {
    if (departments.length > 0 && items.length === 0) {
      let newData = departments.map((d, index) => {
        d.visable = true;
        d.color = colors[index];
        return d;
      });
      setItems(newData);
    }
  }, [departments]);

  const isViewReset = () => {
    if (items.length > 0) {
      if (items.find((entry) => !entry.visable)) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleReset = () => {
    let entries = [...items];
    entries.map((ent) => {
      ent.visable = true;
    });
    setItems(entries);
  };

  const onClickList = (e, index) => {
    e.preventDefault();
    let entries = [...items];
    let entry = entries[index];
    entry.visable = !entry.visable;
    setItems(entries);
  };

  const renderCircle = (index) => {
    if (items.length > 0) {
      let entry = items[index];
      let value = entry.percent;

      return (
        <div
          className={
            index !== items.length - 1
              ? "statistics_item"
              : "statistics_item statistics_item_child"
          }
          style={{
            background: entry.visable
              ? value >= 100
                ? colors[index]
                : statistics_circle_color((value / 40) * 100, colors[index])
              : "#F3F3F3",
            // border: !entry.visable ? "none" : null,
          }}
        >
          <div className="statistics_item" style={{ backgroundColor: "#fff" }}>
            {index !== items.length - 1 ? renderCircle(index + 1) : "100%"}
          </div>
        </div>
      );
    }
  };

  const getItem = (item, index) => {
    return (
      <Grid item xl={2} lg={3} md={4} sm={6} xs={6} key={index}>
        <Tooltip title={item.percent} arrow>
          <Paper elevation={3} sx={{ cursor: "pointer" }}>
            <div
              className="fullWH center referrals-item-container"
              style={{
                textDecoration: !item.visable ? "line-through" : "none",
              }}
              onClick={(e) => onClickList(e, index)}
            >
              <div>
                <p
                  className="margin0 text-center count"
                  style={{ color: item.color }}
                >
                  {item.count}
                </p>
                <p className="referrals-item-container-text">
                  <div
                    className="referrals-item-circle"
                    style={{ backgroundColor: item.color }}
                  />

                  <div className="title">{item.type}</div>
                </p>
              </div>
            </div>
          </Paper>
        </Tooltip>
      </Grid>
    );
  };
  return (
    <div className="inner-container">
      <CardHeader className="header">
        <div>
          <h5>{local.Teams}</h5>
        </div>
        {isViewReset() ? (
          <div>
            <a className="reset" onClick={handleReset}>
              {local.Reset}
            </a>
          </div>
        ) : null}
      </CardHeader>
      <CardBody className="body mb-2">
        <Grid container spacing={1}>
          <Grid container item spacing={1}>
            {items &&
              items.map((item, index) => {
                return getItem(item, index);
              })}
          </Grid>
        </Grid>

        <div className="center mt-2">{renderCircle(0)}</div>
      </CardBody>
    </div>
  );
};
