import React, { useEffect, useState } from "react";

import EditControl from "react-leaflet-draw/dist/esm/EditControl";
import { FeatureGroup, Circle } from "react-leaflet";
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from "../redux/actions/types";
import { geoJsonToWKT } from "./GeneralFunctions";
const MapDrawing = (props) => {
  const { general, renderPolygon, formvalues, workerPolygons } = props;

  const [editableFG, setEditableFG] = useState(null);

  const onDraw = (e, x) => {
    const targets = e.sourceTarget._targets;
    if (Object.values(targets)[1] !== undefined) {
      if (Object.values(targets)[Object.values(targets).length - 1]._latlngs) {
        let item =
          Object.values(targets)[Object.values(targets).length - 1]._latlngs[
            Object.values(targets)[Object.values(targets).length - 1]._latlngs
              .length - 1
          ];

        if (item !== undefined) {
          let polygon = geoJsonToWKT(item);
          general(
            [{ prop: "companyWfForm.formvalues.location", value: polygon }],
            UPDATE_COMPANY_WORK_FIELD_PROPS
          );
        }
      }
    }
  };

  useEffect(() => {
    if (!formvalues.location) {
      if (editableFG) removeAllLayers();
    }
  }, [formvalues.location]);

  const onFeatureGroupReady = (reactFGref) => {
    // store the featureGroup ref for future access to content
    setEditableFG(reactFGref);
    console.log(`editableFG`, editableFG);
  };

  const onCreated = (e) => {
    console.log(e);
    console.log("editableFG", editableFG);

    // here you have all the stored layers
    const drawnItems = editableFG.leafletElement._layers;
    console.log(drawnItems);
    // if the number of layers is bigger than 1 then delete the first
    if (Object.keys(drawnItems).length > 1) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        if (index > 0) return;
        const layer = drawnItems[layerid];
        editableFG.leafletElement.removeLayer(layer);
      });
      console.log(drawnItems); // here you will get only the last one
    }
  };

  const removeAllLayers = () => {
    const drawnItems = editableFG.leafletElement._layers;
    if (Object.keys(drawnItems).length > 0) {
      Object.keys(drawnItems).forEach((layerid, index) => {
        const layer = drawnItems[layerid];
        editableFG.leafletElement.removeLayer(layer);
      });
    }
  };

  const onDelete = (e) => {
    // let newAllWorkFields = workerPolygons.filter((wf) => {
    //   return wf.id !== formvalues.id;
    // });

    general(
      [
        { prop: "companyWfForm.formvalues.location", value: null },
        // { prop: "allCompanyWorkFields", value: newAllWorkFields },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  const handleOnEdited = (e, x) => {
    if (Object.values(e.sourceTarget._targets)[1] !== undefined) {
      let item = Object.values(e.layers._layers)[0]._latlngs[0];

      if (item !== undefined) {
        let polygon = geoJsonToWKT(item);
        general(
          [{ prop: "companyWfForm.formvalues.location", value: polygon }],
          UPDATE_COMPANY_WORK_FIELD_PROPS
        );
      }
    }
  };

  const handleOnEditedEnhanced = (e, x) => {
    if (Object.values(e.sourceTarget._targets)[1] !== undefined) {
      let item = Object.values(e.sourceTarget._targets)[1].editing
        .latlngs[0][0];

      if (item !== undefined) {
        let polygon = geoJsonToWKT(item);
        general(
          [{ prop: "companyWfForm.formvalues.location", value: polygon }],
          UPDATE_COMPANY_WORK_FIELD_PROPS
        );
      }
    }
  };

  const onEdit = (e, x) => {
    const targets = e.sourceTarget._targets;
    if (Object.values(targets)[1] !== undefined) {
      let item = Object.values(Object.values(targets)[0]._layers).filter(
        (x) => x.editing
      )[0].editing.latlngs[0][0];

      if (item !== undefined) {
        let polygon = geoJsonToWKT(item);
        general(
          [{ prop: "companyWfForm.formvalues.location", value: polygon }],
          UPDATE_COMPANY_WORK_FIELD_PROPS
        );
      }
    }
  };
  return (
    <FeatureGroup
      ref={(featureGroupRef) => {
        onFeatureGroupReady(featureGroupRef);
      }}
    >
      <EditControl
        position="topright"
        // onDeleteStart={onDelete}
        onDeleted={onDelete}
        onDrawStop={onDraw}
        // onEditStop={onEdit}
        // onEdited={handleOnEdited}
        // onEdited={onEdit}
        onEdited={handleOnEditedEnhanced}
        onCreated={onCreated}
        draw={{
          rectangle: false,
          polyline: false,
          circle: false,
          circlemarker: false,
          marker: false,
          edit: true,
        }}
      />
      {renderPolygon()}
    </FeatureGroup>
  );
};

export default MapDrawing;
