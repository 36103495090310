import EMPTY_INFO_WINDOW from "../../assets/img/MapPin/empty-info-window.png";

// const CustomInfoWindow = ({placeName, placeType}) => {
//   return (
//     <div style={{position: "relative"}}>
//       <img src={EMPTY_INFO_WINDOW} />
//       <div id="infoWindow__text">
//         {`${placeType}: ${placeName}`}
//       </div>
//     </div>
//   )
// }

const CustomInfoWindow = ({ placeName, placeType }) => {
  // return `<div style="position:relative">
  return `<div>
            <img src=${EMPTY_INFO_WINDOW} />
            <div id="infoWindow__text">
              ${placeType}: ${placeName}
            </div>
          </div>`;
};

export default CustomInfoWindow;
