import { getLanguage, local } from "../../lang/local";
import {
  CLEAR_BRANCHES_STATE,
  UPDATE_BRANCHES_PROPS,
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_STATE_WORK_FIELD_PROPS,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import {
  selectStyle3,
  selectStyle4,
  selectStyle5,
} from "../../utils/GeneralStyle";
import { AsyncPaginate } from "react-select-async-paginate";
import {
  getDeafultItem,
  validatePhoneLength,
} from "../../utils/GeneralFunctions";

export const unsetForm = (general) => {
  general(
    [
      {
        prop: "branchForm",
        value: {
          isLoading: false,
          formvalues: {
            name: { ar: "", en: "" },
            address: "",
            mobile: "",
            manager_name: "",
            company_id: null,
          },
          index: null,
          isCreate: "create",
        },
      },
      { prop: "branchOptions", value: [] },

      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
          tabs_title: [
            {
              title:
                getLanguage() === "en"
                  ? "Main Information"
                  : "البيانات الرئيسية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
          ],
          activeIndex: 0,
          tabAction: "next",
        },
      },
    ],
    UPDATE_BRANCHES_PROPS
  );
};

export const validateInputs = (branchForm) => {
  if (branchForm.formvalues.company_id === null) {
    toaster.notify(local.ChooseCompany, {
      duration: 2000,
    });
  }

  if (branchForm.formvalues.address === "") {
    toaster.notify(local.AddressRequired, {
      duration: 2000,
    });
  }
  if (branchForm.formvalues.mobile === "") {
    toaster.notify(local.MobileRequired, {
      duration: 2000,
    });
  }
  if (branchForm.formvalues.mobile !== "") {
    if (validatePhoneLength(branchForm.formvalues.mobile) === false)
      toaster.notify(local.EgPhoneLength, {
        duration: 2000,
      });
  }

  if (branchForm.formvalues.name.en === "") {
    toaster.notify(local.EnglishNamequired, {
      duration: 2000,
    });
  }
  if (branchForm.formvalues.name.ar === "") {
    toaster.notify(local.ArabicNameRequired, {
      duration: 2000,
    });
  }

  if (
    branchForm.formvalues.name.ar !== "" &&
    branchForm.formvalues.name.en !== "" &&
    branchForm.formvalues.mobile !== "" &&
    validatePhoneLength(branchForm.formvalues.mobile) === true &&
    branchForm.formvalues.address !== "" &&
    branchForm.formvalues.company_id !== null
  ) {
    return true;
  }
};

export const onSave = (e, createORUpdateBranch, branchForm, isModal) => {
  e.preventDefault();
  if (validateInputs(branchForm) === true) {
    createORUpdateBranch(branchForm.formvalues, branchForm.index, isModal);
  }
};

export const renderBranchSelect = (
  prop,
  value,
  action,
  general,
  title,
  loadBranchesOptions,
  branchOptions,
  id,
  isMutli,
  dependProps,
  col,
  isCreate,
  endPoint,
  defaultOptions = null,
  parentBranchId,
  isDisabled = false
) => {
  const isParent = parentBranchId == 0 ? true : false;
  return (
    <>
      <AsyncPaginate
        isDisabled={isDisabled}
        key={value}
        options={
          isCreate !== undefined
            ? isCreate === "update"
              ? branchOptions
              : null
            : null
        }
        value={isMutli === null ? getDeafultItem(value, branchOptions) : value}
        placeholder={title}
        className={col !== null ? "mt-" + col : null}
        isMulti={isMutli === null ? false : true}
        loadOptions={(name, loadedOptions, page) =>
          loadBranchesOptions(name, loadedOptions, page, id, parentBranchId)
        }
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
        styles={{ ...selectStyle4, ...selectStyle5 }}
        isClearable={true}
        cacheUniqs={[id]}
        defaultOptions={defaultOptions || null}
        onFocus={() =>
          typeof parentBranchId != "number"
            ? toaster.notify(local.selectRegion, {
                duration: 2000,
              })
            : null
        }
        onChange={(e) => {
          onChangeBranch(
            e,
            isMutli,
            prop,
            action,
            general,
            dependProps,
            isParent
          );
          if (endPoint !== undefined) {
            endPoint.action(e.value, endPoint.action_create, endPoint.prop);
          }
        }}
      />
    </>
  );
};

export const onChangeBranch = (
  e,
  isMutli,
  prop,
  action,
  general,
  dependProps,
  isParent
) => {
  if (e !== null) {
    unsetDependProp(dependProps, general, action);

    // general([{ prop: "workerOptions", value: [] }], UPDATE_WORKER_PROPS);

    general(
      [{ prop: "companyWfOptions", value: [] }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
    general([{ prop: "pointsOptions", value: [] }], UPDATE_REQUESTS_PROPS);
    if (isParent) {
      general([{ prop: "branchOptions", value: [] }], UPDATE_BRANCHES_PROPS);
    }

    if (isMutli === null) {
      general([{ prop: prop, value: e.value }], action);
    } else {
      general([{ prop: prop, value: e }], action);
    }
  } else {
    unsetDependProp(dependProps, general, action);

    // general([{ prop: "workerOptions", value: [] }], UPDATE_WORKER_PROPS);

    // general(
    //   [{ prop: "companyWfOptions", value: [] }],
    //   UPDATE_COMPANY_WORK_FIELD_PROPS
    // );
    // general(
    //   [{ prop: "pointsOptions", value: [] }],
    //   UPDATE_REQUESTS_PROPS
    // );

    // general([{ prop: "branchOptions", value: [] }], UPDATE_BRANCHES_PROPS);

    general([{ prop: prop, value: null }], action);
  }
};

export const unsetDependProp = (dependProps, general, action) => {
  if (dependProps !== null) {
    dependProps.map((prop) => {
      general([{ prop: prop.p, value: prop.v }], action);
    });
  }
};

export const unsetBranchListData = (general) => {
  general([], CLEAR_BRANCHES_STATE);
  general(
    [
      {
        prop: "stateWFOptions",
        value: [],
      },
    ],
    UPDATE_STATE_WORK_FIELD_PROPS
  );
};
