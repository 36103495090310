import React, { useEffect } from 'react';
import { Col, Row, CardBody, Card } from 'reactstrap';
import { local } from '../../lang/local';
import { UPDATE_REQUESTS_PROPS } from '../../redux/actions/types';
import BoxLink from '../../utils/BoxLink';
import SearchBox from '../../utils/SearchBox';
import { labelStyle } from '../../utils/GeneralStyle';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { renderBranchSelect } from '../Branches/BranchesFunction';
import {
  onWorkFieldChangeCB,
  ZoomToUserLocationBtn,
} from './RequestsFunctions';
import {
  checkHasOneCompany,
  getWorkFieldOptions,
  handleError,
  handleSelectChangeEnhanced,
  isPointInPolygon,
} from '../../utils/GeneralFunctions';
import GenericMapy from '../../components/GenericMapy/GenericMapy';
import {
  pointObjectToWKT,
  WKTPolygonToGeoJson,
} from '../../components/GenericMapy/utils';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';

const FirstTab = (props) => {
  const {
    general,
    pointForm,
    modale,
    companyOptions,
    polygonItem,
    searchOptions,
    endPoint,
    loadCompaniesOptions,

    iscreate,
    loadBranchesOptions,
    branchOptions,
    setRequestsProps,
    map,
    markers,
  } = props;

  // const [bounds, setBounds] = useState(undefined);

  useEffect(() => {
    console.log(`markers`, markers);
  });

  const onMapClick = (latLng, e) => {
    const lng = latLng.lng;
    const lat = latLng.lat;

    if (polygonItem) {
      const geoJsonPolygon = WKTPolygonToGeoJson(polygonItem);
      if (isPointInPolygon(lng, lat, geoJsonPolygon)) {
        const WKTPoint = pointObjectToWKT(latLng);

        general(
          [{ prop: 'pointForm.formvalues.location', value: WKTPoint }],
          UPDATE_REQUESTS_PROPS
        );
      } else {
        handleError(local.PointOutside);
      }
    }
  };

  return (
    <CardBody className='trans'>
      {pointForm.formvalues.id === undefined ? (
        <>
          {checkHasOneCompany() === false ? (
            <Row className='mb-5'>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <span className='label_title' style={labelStyle}>
                  {local.Company}
                </span>
                {renderCompanySelect(
                  'pointForm.company',
                  pointForm.company,
                  UPDATE_REQUESTS_PROPS,
                  general,
                  local.Company,
                  loadCompaniesOptions,
                  companyOptions,
                  null,
                  [
                    { p: 'pointForm.formvalues.work_field_id', v: null },
                    { p: 'pointForm.branch_id', v: null },

                    {
                      p: 'pointForm.work_fields_points',
                      v: [],
                    },
                  ],
                  '4',
                  iscreate
                )}
              </Col>
            </Row>
          ) : null}
          <Row className='mb-5'>
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <span className='label_title' style={labelStyle}>
                {local.Branch}
              </span>
              {renderBranchSelect(
                'pointForm.branch_id',
                pointForm.branch_id,
                UPDATE_REQUESTS_PROPS,
                general,
                local.Branch,
                loadBranchesOptions,
                branchOptions,
                pointForm.company,
                null,
                [
                  {
                    p: 'pointForm.formvalues.work_field_id',
                    v: null,
                  },

                  {
                    p: 'pointForm.work_fields_points',
                    v: [],
                  },
                  {
                    p: 'pointForm.formvalues.workers',
                    v: null,
                  },
                ],
                '4',
                pointForm.iscreate
              )}
            </Col>
          </Row>

          {/* <Row className="mt-4">
            <Col xl={12} lg={12} md={12} sm={12} xs={12}>
              <span className="label_title" style={labelStyle}>
                {local.WorkPlaces}
              </span>
              {renderWorkFSelect(
                "pointForm.formvalues.work_field_id",
                pointForm.formvalues.work_field_id,
                UPDATE_REQUESTS_PROPS,
                general,
                local.WorkPlaces,
                loadCompaniesWFOptions,
                // companyWfOptions,
                [],
                null,
                ["pointForm.formvalues.workers"],
                pointForm.branch_id,
                "4",
                "polygonItem",
                iscreate,
                getAllPoints,
                modale.actionType === "AddCompanyWFPoint"
                  ? (workField) => onWorkFieldChangeCB(workField, setBounds)
                  : undefined
              )}
            </Col>
          </Row> */}
          <Row>
            <CustomAsyncPaginate
              columns={12}
              loadOptions={getWorkFieldOptions(pointForm.branch_id)}
              value={pointForm.formvalues.work_field_id}
              placeholder={local.WorkField}
              cacheUniqsArr={[pointForm.branch_id]}
              hasLabel={true}
              fullWidth={true}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  'pointForm.formvalues.work_field_id',
                  setRequestsProps,
                  [],
                  () => onWorkFieldChangeCB(e, setRequestsProps)
                )
              }
              additional={{
                page: 1,
              }}
            />
          </Row>
        </>
      ) : (
        null

        // <Row>
        //   <Col xl={12} lg={12} md={12} sm={12} xs={12} className="center">
        //     <FormControlLabel
        //       value={pointForm.need_visit === true ? true : false}
        //       control={<Switch color='primary' />}
        //       label={local.Activatevisit}
        //       labelPlacement='top'
        //       onChange={(e) => {
        //         e.target.checked
        //           ? general(
        //               [
        //                 {
        //                   prop: 'pointForm.formvalues.need_visit',
        //                   value: true,
        //                 },
        //               ],
        //               UPDATE_REQUESTS_PROPS
        //             )
        //           : general(
        //               [
        //                 {
        //                   prop: 'pointForm.formvalues.need_visit',
        //                   value: null,
        //                 },
        //               ],
        //               UPDATE_REQUESTS_PROPS
        //             );
        //       }}
        //     />
        //   </Col>
        // </Row>
      )}
      <Row className='mt-4'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <BoxLink
            general={general}
            prop='modale.mapItem.mapPoints'
            action={UPDATE_REQUESTS_PROPS}
            FormProp={'pointForm.formvalues.location'}
            work_field_id={pointForm.formvalues.work_field_id}
            polygonItem={polygonItem}
            mapZoomProp={'requestsObj.mapZoom'}
            saveLink='pointForm.formvalues.properties.link'
            map={map}
          />
        </Col>
      </Row>

      <Row className='mt-4'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <SearchBox
            searchOptions={searchOptions}
            endPoint={endPoint}
            action={UPDATE_REQUESTS_PROPS}
            general={general}
            map={map}
          />
        </Col>
      </Row>

      {modale.actionType === 'AddCompanyWFPoint' && (
        <Row className='mt-5'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {/* {renderMyLocationButton(general)} */}
            <ZoomToUserLocationBtn
              setRequestsProps={setRequestsProps}
            />
          </Col>
        </Row>
      )}

      {/* {modale.actionType === "AddCompanyWFPoint" && (
        <div
          className="too-locations-btn"
          style={{ width: "fit-content" }}
          onClick={() => zoomToUserLocation(setRequestsProps)}
        >
          {local.ZoomToMyLocation}
        </div>
      )} */}
      <Row className='mt-4'>
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <Card style={{ width: '100%', height: '300px' }}>
            {/* <GeneralMap
              {...props}
              general={general}
              formvalues={pointForm.formvalues}
              action={UPDATE_REQUESTS_PROPS}
              modale={modale}
              icClickable={true}
              prop="pointForm.formvalues.location"
              type="companywfpoint"
              drawingPolygon={polygonItem}
              work_fields_points={work_fields_points}
              bounds={bounds}
            /> */}

            {modale.actionType === 'EditCompanyWFPoint' ? (
              <GenericMapy
                map={map}
                mapID='editWorkFieldPointMap'
                singlePolygon={polygonItem}
                onMapClick={onMapClick}
                singleMarker={pointForm.formvalues.location}
                zoomToMarker={false}
              />
            ) : modale.actionType === 'AddCompanyWFPoint' ? (
              <GenericMapy
                map={map}
                mapID='addWorkFieldPointMap'
                singlePolygon={polygonItem}
                onMapClick={onMapClick}
                markers={markers}
                zoomToMarkers={false}
              />
            ) : null}
          </Card>
        </Col>
      </Row>
    </CardBody>
  );
};
export default FirstTab;
