import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Header from "../../../routing/Layout/Header";
import { getUser } from "../../../utils/GeneralFunctions";
import "./usage-home.css";
import UsageHomeHeader from "./UsageHomeHeader/UsageHomeHeader";
import UsageHomeBody from "./UsageHomeBody";

const Sidebar = React.lazy(() => import("../../../routing/Layout/Sidebar"));

class UsageHome extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { history } = this.props;
    return (
      <div className="conatiner">
        {getUser() !== null ? (
          <div>
            <Header history={history} />
          </div>
        ) : null}

        <Row className={getUser() === null ? "ml-3 mr-3 mb-3 mt-3" : ""}>
          <div className="mt-2">
            <Sidebar />
          </div>

          <Col className="mt-2 body-container">
            <UsageHomeHeader />

            <UsageHomeBody history={history} />
          </Col>
        </Row>
      </div>
    );
  }
}

export default UsageHome;
