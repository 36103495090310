import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import WokerSearch from './WokerSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateWorker from './CreateUpdateWorker';
import { UPDATE_WORKER_PROPS } from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
import { unsetForm, unsetWorkerListData } from './WorkerFunctions';
import DoughnutChart from '../../utils/DoughnutChart';
import BarChartStacked from '../../utils/BarChartStacked';
import DoughnutChartLabeled from '../../utils/DoughnutChartLabeled';
class Workers2 extends Component {
  componentDidMount() {
    this.renderWorkers();
  }

  renderModalChildren = () => {
    const { history } = this.props;
    return <CreateUpdateWorker history={history} />;
  };

  renderWorkers = () => {
    const { getCompainesForDropDown } = this.props;

    unsetWorkerListData(this.props.general);
    getCompainesForDropDown();
    // getWorkers(false, null);
  };
  renderModal = () => {
    const { modale, general, workerForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={3}
        // tabs_title={modale.worker}
        modale={modale}
        general={general}
        action={UPDATE_WORKER_PROPS}
        formvalues={workerForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,

      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getWorkers,
      getCompainesWFForDropDown,

      loadCompaniesOptions,
      loadCompaniesWFOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_WORKER_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <WokerSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getWorkers={getWorkers}
              general={general}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              loadCompaniesOptions={loadCompaniesOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
            />

            <div className='row p-0 bg-white p-4 rounded'>
              <div className='col-12 col-md-6 p-0 row'>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Grey']}
                      doughnutData={[12, 19]}
                      colors={['#12a9cc', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Grey']}
                      doughnutData={[12, 19]}
                      colors={['#12a9cc', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Grey']}
                      doughnutData={[12, 19]}
                      colors={['#12a9cc', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Grey']}
                      doughnutData={[12, 19]}
                      colors={['#12a9cc', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Grey']}
                      doughnutData={[12, 19]}
                      colors={['#12a9cc', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
                <div className='col-12 col-md-6 row'>
                  <div className='col-6 p-0'>
                    <DoughnutChartLabeled
                      doughnutLabels={['Blue', 'Grey']}
                      doughnutData={[12, 19]}
                      colors={['#12a9cc', '#cecece']}
                    />
                  </div>
                  <div className='col-6 p-0 row'>
                    <div className='pl-4'>
                      City Cairo <br />
                      50 visits
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 col-md-6 p-0'>
                <div className='row mb-5'>
                  <div className='col-4'>
                    <DoughnutChart
                      doughnutData={[55, 45]}
                      colors={['#09c0aa', '#cecece']}
                    />
                  </div>
                  <div className='col-4'>
                    <DoughnutChart
                      doughnutData={[55, 45]}
                      colors={['#09c0aa', '#cecece']}
                    />
                  </div>
                  <div className='col-4'>
                    <DoughnutChart
                      doughnutData={[55, 45]}
                      colors={['#09c0aa', '#cecece']}
                    />
                  </div>
                </div>
                <div className='row'>
                  <BarChartStacked
                    barThickness={10}
                    barLabels={[
                      'Jan',
                      'Feb',
                      'March',
                      'April',
                      'May',
                      'June',
                      'July',
                      'Aug',
                    ]}
                    datasetLabels={['Active', 'Not Active', 'Total']}
                    datasets={[
                      [10, 20, 30, 40, 50, 45, 70, 80],
                      [10, 20, 30, 40, 50, 45, 70, 80],
                      [10, 20, 30, 40, 50, 45, 70, 80],
                    ]}
                    colors={['#cecece', '#ffc33c', '#14a9cc', '#09c0aa']}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ WorkerR, CompanyWorkFieldR, CompanyR }) => {
  return {
    workerObj: WorkerR.workerObj,
    modale: WorkerR.modale,
    workerForm: WorkerR.workerForm,
    sorting: WorkerR.sorting,
    lightbox: WorkerR.lightbox,
    searchForm: WorkerR.searchForm,
    isLoadingSearch: WorkerR.isLoadingSearch,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
  };
};

export default connect(mapStateToProps, actions)(Workers2);
