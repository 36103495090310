import { Request, Select_Request } from "../Api/index";
import {
  DELETE_STATE_PROPS,
  MERGE_STATE_PROPS,
  UPDATE_STATE_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
} from "../../utils/GeneralFunctions";
export const getStates = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_STATE_PROPS,
    payload: [{ prop: "stateObj.stateItem", value: null }],
  });
  let url = "/states";
  if (isSearching === true) {
    dispatch({
      type: UPDATE_STATE_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_STATE_PROPS,
      payload: [{ prop: "stateObj.isLoading", value: true }],
    });
  }

  if (searchForm !== null) {
    let newSearchForm = removeUnsetValues(searchForm);
    let param = null;
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      param = getQueryString(newSearchForm);
      url += param;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_STATE_PROPS,
            payload: [{ prop: "stateObj.stateItem", value: res.data.data }],
          });
        }

        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_STATE_PROPS,
        payload: [
          { prop: "stateObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_STATE_PROPS,
        payload: [
          { prop: "stateObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getStatesForDropDown = (name) => (dispatch) => {
  Request()
    .get("/states")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: getLanguage() === "en" ? r.name.en : r.name.ar,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_STATE_PROPS,
            payload: [{ prop: "stateOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateState =
  (formValues, index, isModal) => (dispatch) => {
    dispatch({
      type: UPDATE_STATE_PROPS,
      payload: [{ prop: "stateForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues.id === undefined) {
      url = "/states/store";
    } else {
      url = "/states/update/" + formValues.id;
    }

    Request()
      .post(url, formValues)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);

            if (isModal === true) {
              // dispatch({
              //   type: MERGE_STATE_PROPS,
              //   payload: [
              //     { prop: "stateObj.stateItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);

            if (isModal === true) {
              dispatch({
                type: UPDATE_STATE_PROPS,
                payload: [
                  {
                    prop: "stateObj.stateItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }
          dispatch({
            type: UPDATE_STATE_PROPS,
            payload: [
              {
                prop: "stateForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    name: { ar: "", en: "" },
                    parent_id: null,
                  },
                  index: null,
                },
              },
              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }

        dispatch({
          type: UPDATE_STATE_PROPS,
          payload: [{ prop: "stateForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_STATE_PROPS,
          payload: [{ prop: "stateForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getState = (id, dropDownItem, noParent) => (dispatch) => {
  Request()
    .get("/states/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            if (noParent !== undefined) {
              dispatch(getState(res.data.data.parent.id, true));
            }
            dispatch({
              type: UPDATE_STATE_PROPS,
              payload: [
                {
                  prop: "stateForm.formvalues.name",
                  value: res.data.data.name,
                },
                {
                  prop: "stateForm.formvalues.id",
                  value: res.data.data.id,
                },
              ],
            });

            if (res.data.data.parent !== null) {
              dispatch({
                type: UPDATE_STATE_PROPS,
                payload: [
                  {
                    prop: "stateForm.formvalues.parent_id",
                    value: res.data.data.parent.id,
                  },
                ],
              });
            }
          } else {
            let result = [
              {
                label:
                  getLanguage() === "en"
                    ? res.data.data.name.en
                    : res.data.data.name.ar,
                value: res.data.data.id,
              },
            ];

            dispatch({
              type: UPDATE_STATE_PROPS,
              payload: [{ prop: "stateOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const saveDataFromFile = (items, headers, amanaText) => (dispatch) => {
  let data = items;
  let englishIndex = headers.indexOf("EnglishName");
  let arabicIndex = headers.indexOf("ArabicName");
  let parentIndex = headers.indexOf(amanaText);

  dispatch({
    type: UPDATE_STATE_PROPS,
    payload: [{ prop: "fileOptions.isLoading", value: true }],
  });
  data.map((row, index) => {
    let formValues = {
      name: { en: row[englishIndex], ar: row[arabicIndex] },
      parent_id: row[parentIndex],
    };

    Request()
      .post("/states/store", formValues)
      .then((res) => {
        if (res.data.status === true) {
          dispatch({
            type: UPDATE_STATE_PROPS,
            payload: [
              {
                prop: "fileOptions.importedData." + index + "." + row.length,
                value: true,
              },
            ],
          });

          dispatch({
            type: DELETE_STATE_PROPS,
            payload: [{ prop: "fileOptions.savedData." + index }],
          });

          if (index === data.length - 1) {
            confirmSunccessed(local.SuccessfullyAdded);
            dispatch({
              type: UPDATE_STATE_PROPS,
              payload: [
                { prop: "fileOptions.savedData", value: null },
                { prop: "fileOptions.isLoading", value: false },
              ],
            });
          }
        } else {
          dispatch({
            type: UPDATE_STATE_PROPS,
            payload: [{ prop: "fileOptions.isLoading", value: false }],
          });
        }
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_STATE_PROPS,
          payload: [{ prop: "fileOptions.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  });
};

export const loadStateOptions =
  (name, loadedOptions, page) => async (dispatch) => {
    let url = null;

    if (name === undefined) {
      url = Select_Request.url + "/states?page=" + page.page + "";
    } else {
      if (name !== "") {
        url =
          Select_Request.url +
          "/states?name=" +
          name +
          "&page=" +
          page.page +
          "";
      } else {
        url = Select_Request.url + "/states?page=" + page.page + "";
      }
    }

    let response = await fetch(url, Select_Request.options);
    let responseOK = response && response.ok;
    if (responseOK) {
      let result = await response.json();

      let options = [];

      if (result.status === true) {
        if (result.data.data !== null) {
          result.data.data.map((d) => {
            options.push({
              label: getLanguage() === "en" ? d.name.en : d.name.ar,
              value: d.id,
            });
          });
        }
      }

      let entry = {
        result: result.data.data,
        has_more:
          result.data.pagination.current_page ===
          result.data.pagination.total_pages
            ? false
            : true,
      };

      dispatch({
        type: UPDATE_STATE_PROPS,
        payload: [
          {
            prop: "stateOptions",
            value: [...loadedOptions, ...options],
          },
        ],
      });

      return {
        options: options,
        hasMore: entry.has_more,
        additional: {
          page: page.page + 1,
        },
      };
    }
  };
