import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { UPDATE_REQUESTS_PROPS } from "../../../../redux/actions/types";

import * as actions from "../../../../redux/actions/index";
import Input from "../../../../components/Input/Input";
import StaticDropdown from "../../../../components/StaticDropdown/StaticDropdown";
import { placeTypeOptions } from "../../../../utils/placeTypeOptions";
import "./step.css";
import { local } from "../../../../lang/local";
import {
  getMerchantOptions,
  handleSelectChangeEnhanced,
} from "../../../../utils/GeneralFunctions";
import GenericPhoneInput from "../../../../components/GenericPhoneInput/GenericPhoneInput";
import CustomAsyncPaginate from "../../../../components/CustomAsyncPaginate/CustomAsyncPaginate";

const MerchantDetails = ({ pointForm, general, getMerchants }) => {
  const [userData, setUserData] = useState("");

  useEffect(
    () => setUserData(JSON.parse(localStorage.getItem("SERVICE_USER"))),
    []
  );

  const searchForMerchant = (e) => {
    const workFields = userData.work_fields.map((workField) => workField.id);
    console.log(e);
    getMerchants({ merchant_id: pointForm.formvalues.merchant_id, workFields });
  };

  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto lg:w-2/3 gap-x-7 gap-y-12 mt-16 ">
      <Input
        label={local.MercantName}
        value={pointForm.formvalues.name}
        action={UPDATE_REQUESTS_PROPS}
        prop="pointForm.formvalues.name"
        general={general}
      />
      <div className="flex">
        <div className="grow">
          <Input
            type="number"
            label={local.MerchantId}
            value={pointForm.formvalues.merchant_id}
            action={UPDATE_REQUESTS_PROPS}
            prop="pointForm.formvalues.merchant_id"
            general={general}
          />
        </div>
        <button className="mt-4 btn btn-primary" onClick={searchForMerchant}>search</button>
      </div>
      {/* <CustomAsyncPaginate
        label={local.MerchantId}
        value={pointForm.formvalues.merchant_id}
        loadOptions={getMerchantOptions()}
        fullWidth
        hasLabel
        placeholder=''
        onChange={e=>{
          handleSelectChangeEnhanced(e, )
        }}
        additional={{
          page: 1,
        }}
      /> */}
      <Input
        label={local.UserName}
        value={pointForm.formvalues.merchant_username}
        action={UPDATE_REQUESTS_PROPS}
        prop="pointForm.formvalues.merchant_username"
        general={general}
      />
      <Input
        type="number"
        label={local.TerminalId}
        value={pointForm.formvalues.terminal_id}
        action={UPDATE_REQUESTS_PROPS}
        prop="pointForm.formvalues.terminal_id"
        general={general}
      />
      <GenericPhoneInput
        title={local.Mobile}
        prop={"pointForm.formvalues.properties.mobile"}
        value={pointForm.formvalues.properties.mobile}
        action={UPDATE_REQUESTS_PROPS}
        general={general}
        fullWidth
      />

      {/* <Input
        label="Merchant Address"
        value={pointForm.formvalues.properties.address}
        action={UPDATE_REQUESTS_PROPS}
        prop="pointForm.formvalues.properties.address"
        general={general}
      /> */}
      <StaticDropdown
        title={local.PlaceType}
        label={local.PlaceType}
        prop="pointForm.formvalues.place_type"
        value={pointForm.formvalues.place_type}
        action={UPDATE_REQUESTS_PROPS}
        options={placeTypeOptions}
      />

      {/* <Input label="Company Name / Partner Name" />
      <Input label="Place Type" />
      <Input label="Visit Type" />
      <Dropdown label="Merchant Name" hasLabel/> */}
    </div>
  );
};

const mapStateToProps = ({ RequestsR }) => {
  return {
    pointForm: RequestsR.pointForm,
  };
};

export default connect(mapStateToProps, actions)(MerchantDetails);
