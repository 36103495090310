import { useState } from "react";
import { local } from "../../lang/local";
import MarkersGuideItem from "./GuideItem";
import { PLACE_TYPE_OPTIONS } from "../StaticOptionsSelect/staticOptions";
import { getSelectOptionsArr } from "../StaticOptionsSelect/StaticOptionsSelect";

import CLINIC_PIN from "../../assets/img/MapPin/clinic-pin.png";

const MarkersGuide = () => {
  const [isShown, setIsShown] = useState(true);

  const toggleIsShown = () => {
    setIsShown((prevState) => !prevState);
  };

  let placeTypeOptions = PLACE_TYPE_OPTIONS;

  placeTypeOptions = getSelectOptionsArr(PLACE_TYPE_OPTIONS);

  const guideItemsArr = placeTypeOptions?.map((option) => {
    return (
      <MarkersGuideItem
        markerIcon={option.icon}
        title={option.label}
        filterValue={option.value}
        isVisible={isShown}
      />
    );
  });
  return (
    <div
      style={{
        maxHeight: "400px",
        overflow: "auto",
        zIndex: 50,
        position: "absolute",
        bottom: 10,
        right: 10,
      }}
    >
      <MarkersGuideItem
        markerIcon={CLINIC_PIN}
        title={local.All}
        filterValue={null}
        isVisible={isShown}
      />

      {guideItemsArr}

      <MarkersGuideItem
        markerIcon={CLINIC_PIN}
        title={local.VisitsPointsFilters}
        action={toggleIsShown}
      />
    </div>
  );
};

export default MarkersGuide;
