import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
import {
  handleSelectChange,
  getWorkFieldpointsOptions,
  isArray,
} from '../../utils/GeneralFunctions';
import { local } from '../../lang/local';
import {
  general,
  getCompanyWorkFieldOptions,
  getPointsOptions,
  // getWorkFieldpointsOptions,
  loadCompaniesWFOptions,
  loadpointsOptions,
  setWorkerProps,
} from '../../redux/actions';

const WorkersPointsList = ({
  workerForm,
  getCompanyWorkFieldOptions,
  setWorkerProps,
  fullWidth,
  modale,
}) => {
  const getWorkFields = (workFields) => {
    if (!workFields) return null;
    if (!isArray(workFields)) return null;
    const workFieldsArr = workFields.map((workField) => workField.id);
    return workFieldsArr;
  };
  return (
    <>
      <CustomAsyncPaginate
        fullWidth={fullWidth}
        loadOptions={getCompanyWorkFieldOptions}
        value={workerForm.formvalues.work_field_id}
        placeholder={local.Zones}
        cacheUniqsArr={[workerForm.formvalues.branch_id]}
        isMulti={true}
        onChange={(e) =>
          handleSelectChange(
            e,
            'workerForm.formvalues.work_field_id',
            setWorkerProps,
            ['workerForm.formvalues.points'],
            true
          )
        }
        additional={{
          page: 1,
          branchId: workerForm.formvalues.branch_id?.id,
        }}
        hasLabel={true}
        isDisabled={!!!workerForm.formvalues.branch_id}
      />

      {/* {modale.actionType !== 'EditWorker' && (
        <CustomAsyncPaginate
          fullWidth={fullWidth}
          loadOptions={getWorkFieldpointsOptions(
            getWorkFields(workerForm.formvalues.work_field_id),
            workerForm.formvalues.id
              ? { worker_unauthorized_points: workerForm.formvalues.id }
              : {}
          )}
          value={workerForm.formvalues.points}
          placeholder={local.WorkFieldPoint}
          isMulti={true}
          hasLabel={true}
          label={local.AddCompanyWFPoints}
          onChange={(e) =>
            handleSelectChange(
              e,
              'workerForm.formvalues.points',
              setWorkerProps,
              [],
              true
            )
          }
          cacheUniqsArr={[workerForm.formvalues.work_field_id]}
          additional={{
            page: 1,
            companyId: workerForm.formvalues.company_id,
            workFields: workerForm.formvalues.work_field_id,
          }}
        />
      )} */}
    </>
  );
};

const mapStateToProps = ({ WorkerR, CompanyWorkFieldR, RequestsR }) => {
  return {
    workerForm: WorkerR.workerForm,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    pointsOptions: RequestsR.pointsOptions,
    modale: WorkerR.modale,
  };
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
      loadCompaniesWFOptions,
      loadpointsOptions,
      getCompanyWorkFieldOptions,
      getPointsOptions,
      setWorkerProps,
      getWorkFieldpointsOptions,
    },
    dispatch
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkersPointsList);
