import React from "react";
import { Card, Col, Row, CardBody, Spinner } from "reactstrap";
import icon_btn from "../../assets/img/icon_btn.png";
import { local } from "../../lang/local";
import GeneralMap from "../../utils/GeneralMap";
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from "../../redux/actions/types";
import BoxLink from "../../utils/BoxLink";
import { onSave } from "./CompanyWFFunctions";
import { FiXCircle } from "react-icons/fi";
import SearchBox from "../../utils/SearchBox";
import { Mapy } from "mapy_sdk/lib";
import GenericMapy from "../../components/GenericMapy/GenericMapy";

let map = new Mapy();

const SecondTab = (props) => {
  const {
    general,
    companyWfForm,
    modale,
    createORUpdateCompanyWF,
    companyWfObj,
    searchOptions,
    endPoint,
    allCompanyWorkFields,
    selectedCompanyWFPoints,
  } = props;

  const onPolygonDraw = (polygon) => {
    general(
      [{ prop: "companyWfForm.formvalues.location", value: polygon }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  const onPolygonEdit = (polygon) => {
    general(
      [{ prop: "companyWfForm.formvalues.location", value: polygon }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  const onPolygonDelete = () => {
    general(
      [{ prop: "companyWfForm.formvalues.location", value: null }],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
  };

  return (
    <>
      <CardBody className="trans">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <BoxLink
              general={general}
              prop="modale.mapItem.mapPoints"
              action={UPDATE_COMPANY_WORK_FIELD_PROPS}
              map={map}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <SearchBox
              searchOptions={searchOptions}
              endPoint={endPoint}
              action={UPDATE_COMPANY_WORK_FIELD_PROPS}
              general={general}
              map={map}
            />
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {companyWfForm.formvalues.location !== null ? (
              <a
                style={{
                  cursor: "pointer",
                  fontSize: "13px",
                  color: "#00beac",
                }}
                onClick={() =>
                  general(
                    [
                      {
                        prop: "companyWfForm.formvalues.location",
                        value: null,
                      },
                    ],
                    UPDATE_COMPANY_WORK_FIELD_PROPS
                  )
                }
              >
                <FiXCircle /> {" " + local.RemovePolygon}
              </a>
            ) : null}
            <Card
              style={{ width: "100%", borderRadius: "10px", height: "300px" }}
              className="mt-2"
            >
              {/* <GeneralMap
                {...props}
                general={general}
                formvalues={companyWfForm.formvalues}
                isDrawing={true}
                action={UPDATE_COMPANY_WORK_FIELD_PROPS}
                modale={modale}
                prop="companyWfForm.formvalues.location"
                drawingPolygon={companyWfForm.formvalues.location}
                editPolygon={true}
                workerPolygons={allCompanyWorkFields}
                workerPoints={
                  selectedCompanyWFPoints?.length > 0
                    ? [selectedCompanyWFPoints]
                    : null
                }
              /> */}

              {modale.actionType === "EditCompanyWF" ? (
                <GenericMapy
                  map={map}
                  mapID="editWorkFieldMap"
                  singlePolygon={companyWfForm.formvalues.location}
                  drawingControls={true}
                  isPolygonEditable={true}
                  onPolygonDraw={onPolygonDraw}
                  onPolygonEdit={onPolygonEdit}
                  onPolygonDelete={onPolygonDelete}
                />
              ) : (
                <GenericMapy
                  map={map}
                  mapID="addWorkFieldMap"
                  singlePolygon={companyWfForm.formvalues.location}
                  drawingControls={true}
                  isPolygonEditable={true}
                  onPolygonDraw={onPolygonDraw}
                  onPolygonEdit={onPolygonEdit}
                  onPolygonDelete={onPolygonDelete}
                />
              )}
            </Card>
          </Col>
        </Row>
      </CardBody>
      <center>
        <button
          type="submit"
          disabled={companyWfForm.isLoading}
          className="save_btn mt-3"
          onClick={(e) =>
            onSave(
              e,
              createORUpdateCompanyWF,
              companyWfForm,
              companyWfObj,
              true
            )
          }
        >
          {companyWfForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center>
    </>
  );
};

export default SecondTab;
