import React from 'react';
import { Card, Col, Row, Button, Spinner, Form } from 'reactstrap';
import {
  renderDateInput,
  renderArraw,
  checkHasOneCompany,
} from '../../utils/GeneralFunctions';
import { leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import { UPDATE_REPORT_PROPS } from '../../redux/actions/types';
import toaster from 'toasted-notes';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { renderWorkFSelect } from '../CompainesWorkFields/CompanyWFFunctions';
import { renderWorkerSeelct } from '../Workers/WorkerFunctions';
import { renderBranchSelect } from '../Branches/BranchesFunction';

const ReportSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    companyWfOptions,
    companyOptions,
    getReports,
    general,
    workerOptions,
    loadCompaniesWFOptions,
    loadCompaniesOptions,
    history,
    loadWorkerOptions,
    loadBranchesOptions,
    branchOptions,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();
    searchForm.page = 1;
    if (searchForm.ids === null) {
      toaster.notify(local.ChooseCompanyWorkField, {
        duration: 2000,
      });
    } else {
      getReports(true, searchForm);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {local.DayReports}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
              {renderCompanySelect(
                'searchForm.company',
                searchForm.company,
                UPDATE_REPORT_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: 'searchForm.ids', v: null },
                  { p: 'searchForm.worker_id', v: null },
                  { p: 'searchForm.branch_id', v: null },
                ],
                null
              )}
            </Col>
          ) : null}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderBranchSelect(
              'searchForm.branch_id',
              searchForm.branch_id,
              UPDATE_REPORT_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              [{ p: 'searchForm.ids', v: null }],
              null
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkFSelect(
              'searchForm.ids',
              searchForm.ids,
              UPDATE_REPORT_PROPS,
              general,
              local.WorkPlaces,
              loadCompaniesWFOptions,
              companyWfOptions,
              true,
              null,
              searchForm.branch_id,
              null,
              null,
              undefined,
              undefined,
              undefined
            )}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkerSeelct(
              'searchForm.worker_id',
              searchForm.worker_id,
              UPDATE_REPORT_PROPS,
              general,
              loadWorkerOptions,
              workerOptions,
              searchForm.company,
              null
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_REPORT_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_REPORT_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col>
          <Col xl={3}>
            <Button
              type='submit'
              style={leftlStyle}
              disabled={isLoadingSearch}
              className='btn_search_style mt-4'
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default ReportSearch;
