import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderDate,
  renderArrayInTable,
  rednerImageInTable,
  renderPagination,
  renderStatusButton,
  getPermissionValue,
  renderEditButton,
  renderCreateButton,
  renderStatusTitle,
  renderEditTitle,
  onOpenProfile,
} from '../../utils/GeneralFunctions';
import {
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_WORKER_PROPS,
} from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import WorkerProfile from './WorkerProfile';
import { leftlStyle } from '../../utils/GeneralStyle';

const WorkersTable = (props) => {
  const {
    workerObj,
    general,
    sorting,
    updateWorkerStatus,
    searchForm,
    getWorkers,
    getWorker,
    companyOptions,
    modaleView,
  } = props;

  const onEdit = (e, item, index) => {
    e.preventDefault();
    general(
      [
        { prop: 'workerForm.isLodingEdit', value: true },
        { prop: 'workerForm.iscreate', value: 'update' },
        { prop: 'workerForm.index', value: index },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );
    getWorker(item.id, false, index, searchForm.company);
  };

  const onCreate = (e) => {
    e.preventDefault();

    general(
      [
        { prop: 'modale.title', value: local.AddWorker },
        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddWorker' },
      ],
      UPDATE_WORKER_PROPS
    );
  };

  const renderBody = () => {
    if (workerObj.workerItem !== null) {
      return workerObj.workerItem.data.map((worker, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(worker.id)}</td>
            <td className='td_table text-center'>
              {rednerImageInTable(worker.image, UPDATE_WORKER_PROPS, general)}
            </td>

            <td
              className='td_table'
              style={{ cursor: 'pointer', textDecoration: 'underline' }}
              onClick={() =>
                onOpenProfile(
                  worker,
                  local.Worker,
                  general,
                  UPDATE_WORKER_PROPS,
                  true,
                  'workers/show/{id}'
                )
              }
            >
              {worker.name}
            </td>
            <td className='td_table'>{worker.user_name}</td>
            <td className='td_table'>{renderNumber(worker.mobile)}</td>
            <td className='td_table'>{worker.email}</td>
            <td className='td_table'>{worker.address}</td>
            <td className='td_table'>{local.OERATION}</td>
            <td className='td_table'>{searchForm.company}</td>

            <td className='td_table'>
              {worker.parent == null ? local.NoSupervisor : worker.parent.name}
            </td>

            <td className='td_table'>
              {renderArrayInTable(worker.work_fields)}
            </td>

            <td className='td_table'>{renderDate(worker.created_at)}</td>

            {renderStatusButton(
              getPermissionValue()['workers/changeStatus/{id}'],
              worker,
              index,
              updateWorkerStatus,
              'isWorker',
              searchForm.company,
              companyOptions
            )}

            {renderEditButton(
              getPermissionValue()['workers/update/{id}'],
              onEdit,
              worker,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <WorkerProfile modaleView={modaleView} general={general} />
      {renderCreateButton(
        getPermissionValue()['workers/store'],
        onCreate,
        local.AddWorker
      )}

      <Row>
        <Col xl={12}>
          <Table
            responsive
            id='worker_table'
            hover
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr
                className='table_head'
                style={{
                  zIndex: '10000000000',
                  backgroundColor: 'red !important',
                }}
              >
                {renderTableHeadItem(
                  local.Id,
                  workerObj.workerItem,
                  'id',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Picture,
                  workerObj.workerItem,
                  'job_title',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Name,
                  workerObj.workerItem,
                  'name',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.UserName,
                  workerObj.workerItem,
                  'user_name',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Mobile,
                  workerObj.workerItem,
                  'mobile',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Email,
                  workerObj.workerItem,
                  'email',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Address,
                  workerObj.workerItem,
                  'address',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.JobTitle,
                  workerObj.workerItem,
                  'job_title',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.CompanyNumber,
                  workerObj.workerItem,
                  'company',
                  'workerObj.workerItem.id',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Supervisor,
                  workerObj.workerItem,
                  'parent.name',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.WorkPlaces,
                  workerObj.workerItem,
                  'job_title',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.DateAdded,
                  workerObj.workerItem,
                  'created_at',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}

                {renderStatusTitle(
                  'workers/changeStatus/{id}',
                  workerObj.workerItem,
                  'active',
                  'workerObj.workerItem.data',
                  UPDATE_WORKER_PROPS,
                  general,
                  sorting
                )}

                {renderEditTitle('workers/update/{id}')}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {workerObj.workerItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='worker_table'
              filename={local.Workers}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {workerObj.workerItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}
      {renderPagination(workerObj.workerItem, searchForm, getWorkers)}
    </Card>
  );
};

export default WorkersTable;
