import { Request } from "../Api/index";
import { UPDATE_ARCHIVES_PROPS } from "./types";
import toaster from "toasted-notes";
import {
  confirmSunccessed,
  getQueryString,
  removeUnsetValues,
} from "../../utils/GeneralFunctions";
import { local } from "../../lang/local";

export const setArchiveProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for(let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1]
    })
  }

  dispatch({
    type: UPDATE_ARCHIVES_PROPS,
    payload
  })
}

export const getArchives = (isSearching, searchForm) => (dispatch) => {
  let url = "archives/";
  dispatch({
    type: UPDATE_ARCHIVES_PROPS,
    payload: [{ prop: "archivesObj.archivesItem", value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_ARCHIVES_PROPS,
      payload: [{ prop: "archivesObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_ARCHIVES_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  if (searchForm !== null) {
    var values = "";
    searchForm.ids.map((d, i) => {
      if (i === 0) {
        values = "ids[]=" + d.value;
      } else {
        values += "&ids[]=" + d.value;
      }
    });

    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      worker_id: searchForm.worker_id,
      page: searchForm.page,
    };

    let newSearchForm = removeUnsetValues(searchItems);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param + "&" + values;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_ARCHIVES_PROPS,
            payload: [
              { prop: "archivesObj.archivesItem", value: res.data.data },
            ],
          });

          if (isSearching === true && res.data.data.data.length === 0) {
            confirmSunccessed(local.NoSearch);
          }
        }
      } else {
        Object.values(res.data.error).map((msg) => {
          toaster.notify(msg[0], {
            duration: 2000,
          });
        });
      }
      dispatch({
        type: UPDATE_ARCHIVES_PROPS,
        payload: [
          { prop: "archivesObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_ARCHIVES_PROPS,
        payload: [
          { prop: "archivesObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};
