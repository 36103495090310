import Mobile_Application_Icon from "../../../assets/img/mobile - usage.jpeg";
import Dashboard_Icon from "../../../assets/img/mobile pins - usage.jpeg";
import { local, getLanguage } from "../../../lang/local";

const UsageHomeBody = ({history}) => {
  return (
    <div
      id="usage-home-body-container"
      style={{direction: getLanguage() === "ar" ? "rtl" : "ltr"}}
      className="row mx-3 mt-3 px-5 py-5 bg-white justify-content-center"
    >
      <div
        onClick={() => history.push('/usage-links-dashboard')}
        className="usage-home-body-card"
        role="button"
      >
        <div className="icon-container">
          <img src={Dashboard_Icon} height="150px" />
        </div>
        <div id="mobile-app-icon-caption">{local.Dashboard}</div>
      </div>

      <div
        onClick={() => history.push('/usage-links-mobile_app')}
        className="usage-home-body-card"
        role="button"
      >
        <div className="icon-container">
          <img src={Mobile_Application_Icon} height="150px" />
        </div>
        <div id="mobile-app-icon-caption">{local.MobileApplication}</div>
      </div>
    </div>
  )
}

export default UsageHomeBody;