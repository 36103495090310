import React from "react";
import { Card, Col, Row, Form, Spinner, Button } from "reactstrap";
import { leftlStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import {
  checkHasOneCompany,
  getDeafultItem,
  getEmployeeOptions,
  getMyCompany,
  getWorkFieldOptions,
  handleSelectChangeEnhanced,
  renderArraw,
  workFieldPermissionedForDropDown,
} from "../../utils/GeneralFunctions";
import { UPDATE_LIVE_TRACKING_PROPS } from "../../redux/actions/types";
import toaster from "toasted-notes";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
import { filterEmployeesByWorkField } from "../Requests/RequestsFunctions";

const TrackingSearch = (props) => {
  const {
    general,
    searchForm,
    companyOptions,
    companyWfOptions,
    isLoadingSearch,
    getAllWorkers,
    history,
    loadCompaniesOptions,
    loadBranchesOptions,
    branchOptions,
    setWorkers,
    socketMessage,
    setSocketMessage,
    setLiveTrackingProps,
  } = props;

  const openLine = (e) => {
    e.preventDefault();
    general(
      [
        {
          prop: "sidebarData",
          value: {
            polyline: null,
            isOpen: true,
            from: "",
            to: "",
            worker_id: null,
            // company: null,
            company: getMyCompany(general),
            isLoading: false,
          },
        },
      ],
      UPDATE_LIVE_TRACKING_PROPS
    );
  };

  const onSearch = (e) => {
    e.preventDefault();

    setWorkers([]);
    if (socketMessage) {
      socketMessage.close();
      setSocketMessage(null);
    }

    if (searchForm.work_field === null) {
      toaster.notify(local.ChooseCompanyWorkField, {
        duration: 2000,
      });
    } else {
      let item = getDeafultItem(searchForm.work_field, companyWfOptions);

      getAllWorkers(searchForm, item);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
    <Form onSubmit={onSearch} className='mt-4 mb-4'>
      <Row>
          <Col>
            <p className="page_title" style={leftlStyle}>
              {local.LiveTracking}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>

        <Row className="ml-5 mr-5 mb-4">
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
              {renderCompanySelect(
                "searchForm.company",
                searchForm.company,
                UPDATE_LIVE_TRACKING_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: "searchForm.work_field", v: null },
                  { p: "searchForm.branch_id", v: null },
                  { p: "searchForm.worker_id", v: null },
                ],
                null
              )}
            </Col>
          ) : null}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderBranchSelect(
              "searchForm.branch_id",
              searchForm.branch_id,
              UPDATE_LIVE_TRACKING_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              [{ p: "searchForm.work_field", v: null }],
              null
            )}
          </Col>
          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkFSelect(
              "searchForm.work_field",
              searchForm.work_field,
              UPDATE_LIVE_TRACKING_PROPS,
              general,
              local.WorkPlaces,
              loadCompaniesWFOptions,
              companyWfOptions,
              null,
              null,
              searchForm.branch_id,
              null,
              null
            )}
          </div> */}

          <CustomAsyncPaginate
            loadOptions={getWorkFieldOptions(searchForm.branch_id)}
            value={searchForm.work_field}
            placeholder={local.workPlaces}
            cacheUniqsArr={[searchForm.branch_id]}
            onChange={(e) => {
              handleSelectChangeEnhanced(
                e,
                "searchForm.work_field",
                setLiveTrackingProps,
                ["searchForm.worker_id"]
              );
            }}
            filterOption={(option, rawInput) =>
              workFieldPermissionedForDropDown({ option, rawInput })
            }
            additional={{
              page: 1,
            }}
          />

          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkerSeelct(
              "searchForm.worker_id",
              searchForm.worker_id,
              UPDATE_LIVE_TRACKING_PROPS,
              general,
              loadWorkerOptions,
              workerOptions,
              searchForm.company,
              null,
              undefined,
              true
            )}
          </div> */}
          <CustomAsyncPaginate
            loadOptions={getEmployeeOptions(searchForm.company)}
            value={searchForm.worker_id}
            placeholder={local.workPlaces}
            cacheUniqsArr={[searchForm.company]}
            onChange={(e) => {
              handleSelectChangeEnhanced(
                e,
                "searchForm.worker_id",
                setLiveTrackingProps,
              );
            }}
            additional={{
              page: 1,
              companyID: searchForm.company,
            }}
            filterOption={
              searchForm.work_field
                ? (option, rawInput) =>
                    filterEmployeesByWorkField(
                      option,
                      rawInput,
                      searchForm.work_field.id
                    )
                : undefined
            }
          />
        </Row>

        <Row className="ml-5 mr-5 mb-4 justify-content-between">
          <Button
            type="submit"
            style={leftlStyle}
            disabled={isLoadingSearch}
            className="btn_search_style my-2"
            onClick={(e) => onSearch(e)}
          >
            {isLoadingSearch === true ? (
              <Spinner type="grow" color="dark" size="sm" />
            ) : (
              local.Search
            )}
          </Button>
          <Button
            type="submit"
            style={leftlStyle}
            className="btn_search_style my-2"
            onClick={(e) => openLine(e)}
          >
            {local.ShowLine}
          </Button>
        </Row>
      </Form>
    </Card>
  );
};

export default TrackingSearch;
