import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "./redux/store";
import { DevSupport } from "@react-buddy/ide-toolbox";
import { ComponentPreviews, useInitial } from "./dev";

const preventCopying = () => {
  // prevent copying tables except for user test@test.com
  const user = JSON.parse(localStorage.getItem("SERVICE_USER"));
  if (
    user?.api_token !==
    "$2y$10$ERWf3ZJKGjpAWvKyxSZoDOuuJhFvPoLok8D3JrgcMOoAS8Z/Pa1Ka"
  ) {
    let tableElements = document.getElementsByTagName("table");
    for (let i = 0; i < tableElements.length; i++) {
      tableElements[i].oncopy = (e) => e.preventDefault();
    }
  }
};

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <div onLoad={preventCopying}>
        <DevSupport
          ComponentPreviews={ComponentPreviews}
          useInitialHook={useInitial}
        >
          <App />
        </DevSupport>
      </div>
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
