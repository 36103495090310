import {
    UPDATE_Ticket_PROPS,
    UPDATE_Ticket_PROPS_Action
  } from "../actions/types";
const Ticket_State={
    Ticket_details:null,
    Actions_Ticket_details:null
    }
const Ticket_reducer=(state=Ticket_State,action)=>{
switch(action.type){

    case UPDATE_Ticket_PROPS:
        return{
        ...state,
        Ticket_details: action.payload,
    }
    case UPDATE_Ticket_PROPS_Action:
        return{
        ...state,
        Actions_Ticket_details: action.payload,
    }
    
    default :return state
}}

export default Ticket_reducer
 