import {
  UPDATE_TABLE_PROPS,
  MERGE_TABLE_PROPS,
  DELETE_TABLE_PROPS,
  CLEAR_TABLE_PROPS,
} from "../actions/types";
import * as general from "./methods";

import dotProp from "dot-prop-immutable";

export const TABLE = "TABLE/";

const INITIAL_STATE = {
  data: [],
  fullData: null,
  loadingOverlayIsActive: false,
  loadingOverlayIsActive2: false,
  modaleView: {
    isOpen: false,
    data: null,
  },
};

const TableReducer = (state = INITIAL_STATE, action) => {
  const setState = (prop, value) => {
    return dotProp.set(state, prop, value);
  };

  switch (action.type) {
    case UPDATE_TABLE_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_TABLE_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_TABLE_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_TABLE_PROPS: {
      return INITIAL_STATE;
    }
    default:
      return state;
  }
};

export default TableReducer;
