import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import "react-alice-carousel/lib/scss/alice-carousel.scss";
import config from "../../config.json";
import right_slide_img from "../../assets/img/right_slide_img.png";
import left_slide_img from "../../assets/img/left_slide_img.png";
const ImageSlider = (props) => {
  const { images } = props;

  const handleDragStart = (e) => e.preventDefault();

  const renderImage = () => {
    if (images !== undefined) {
      if (images !== null) {
        let photos = [];

        images.map((img) => {
          return photos.push(
            <div>
              <img
                src={config.img_url + img[1]}
                style={{
                  width: "300px",
                  height: "300px",
                }}
                onDragStart={handleDragStart}
                alt=""
              />
            </div>
          );
        });
        return photos;
      }
    }
  };

  const items = renderImage();
  const responsive = {
    0: {
      items: 1,
    },
  };

  const stagePadding = {
    paddingLeft: 50,
    paddingRight: 100,
  };
  return images !== undefined ? (
    images !== null ? (
      <AliceCarousel
        stagePadding={stagePadding}
        infinite={false}
        responsive={responsive}
        mouseTracking
        items={items}
        // touchTracking={false}
        disableDotsControls={true}
        autoPlayInterval={200}
        renderPrevButton={() => (
          <img src={left_slide_img} width="18" height="18" />
        )}
        renderNextButton={() => (
          <img src={right_slide_img} width="20" height="20" />
        )}
      />
    ) : null
  ) : null;
};

export default ImageSlider;
