import { Form, Card, Row, Col, Spinner, Input } from 'reactstrap';
import dotProp from 'dot-prop-immutable';
import readXlsxFile from 'read-excel-file';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { local } from '../../lang/local';
import { leftlStyle } from '../../utils/GeneralStyle';
import LightBoxFile from '../../utils/LightBoxFile';
import { UPDATE_WORKER_PROPS } from '../../redux/actions/types';
import { renderArraw, handleError } from '../../utils/GeneralFunctions';
import icon_btn from '../../assets/img/icon_btn.png';
import { addWorkersData } from './WorkerFunctions';
import { useEffect, useState } from 'react';
import WorkerFileTable from './WorkerFileTable';

const AddWorkerFile = (props) => {
  const { general, lightbox, history } = props;

  const INITIAL_STATE = {
    tableData: null,
    isLoading: 0,
  };

  const [state, setState] = useState(INITIAL_STATE);

  const isLoading =
    state.isLoading !== 0 && state.isLoading !== state.tableData.length;

  useEffect(() => {
    setState(INITIAL_STATE);
  }, []);

  const setEmployeeDataState = (prop, value) => {
    setState((prevState) => {
      let tempValue = typeof value === 'function' ? value(prevState) : value;
      const newState = dotProp.set(prevState, prop, tempValue);
      return newState;
    });
  };

  const requiredHeaders = [
    'Name',
    'UserName',
    'Email',
    'Address',
    'Password',
    'ConfirmPassword',
    'Mobile',
    'NationalId',
  ];

  const validateImportedHeaders = (importedData) => {
    const headersArray = importedData[0];

    const requiredHeadersExist = requiredHeaders.every((header) => {
      const headerIndex = headersArray.findIndex(
        (headerItem) => headerItem === header
      );
      const headerExist = headerIndex !== -1;
      return headerExist;
    });

    if (!requiredHeadersExist) return false;
    else return true;
  };

  const findIndexByProp = (arr, prop) => {
    const index = arr.findIndex((item) => item === prop);
    return index;
  };

  const formatDataForTable = (importedData) => {
    const headersArray = importedData[0];
    const actualData = importedData.slice(1);

    const nameIndex = findIndexByProp(headersArray, 'Name');
    const userNameIndex = findIndexByProp(headersArray, 'UserName');
    const emailIndex = findIndexByProp(headersArray, 'Email');
    const addressIndex = findIndexByProp(headersArray, 'Address');
    const passwordIndex = findIndexByProp(headersArray, 'Password');
    const confirmPasswordIndex = findIndexByProp(
      headersArray,
      'ConfirmPassword'
    );
    const mobileIndex = findIndexByProp(headersArray, 'Mobile');
    const nationalIDIndex = findIndexByProp(headersArray, 'NationalId');

    const formattedTableData = [];

    if (actualData.length > 0) {
      for (let i = 0; i < actualData.length; i++) {
        const currentEmployee = actualData[i];
        const employeeObj = {
          name: currentEmployee[nameIndex],
          userName: currentEmployee[userNameIndex],
          email: currentEmployee[emailIndex],
          address: currentEmployee[addressIndex],
          password: currentEmployee[passwordIndex],
          confirmPassword: currentEmployee[confirmPasswordIndex],
          nationalID: currentEmployee[nationalIDIndex],
          mobile: currentEmployee[mobileIndex],
          company: null,
          branch: null,
          workFields: null,
          jobTitle: null,
          employeeType: null,
          status: false,
          index: i,
        };

        formattedTableData.push(employeeObj);
      }
      return formattedTableData;
    } else return null;
  };

  const onRead = (e) => {
    setState(INITIAL_STATE);

    if (e.target.value.includes('xlsx') || e.target.value.includes('xls')) {
      readXlsxFile(e.target.files[0]).then((rows) => {
        if (!validateImportedHeaders(rows)) {
          handleError(local.SomethingWentWrong);
          return;
        }
        const data = formatDataForTable(rows);
        setEmployeeDataState('tableData', data);
      });
    } else {
      handleError(local.NotExcelFile);
    }
  };

  const onSubmit = (e) => {
    e.preventDefault();
    setEmployeeDataState('isLoading', 0);

    addWorkersData(state, setEmployeeDataState);
  };

  const BulletPoint = ({ info }) => {
    return (
      <Row className='mt-2'>
        <Col>
          <li style={leftlStyle}>{info}</li>
        </Col>
      </Row>
    );
  };

  const renderTitle = () => {
    if (history.location.pathname.includes('/worker_profile')) {
      return local.ViewWorker;
    } else {
      return local.AddWorker;
    }
  };

  return (
    <div className='page-wrapper'>
      <LightBoxFile
        lightbox={lightbox}
        general={general}
        action={UPDATE_WORKER_PROPS}
      />

      <Row style={{ height: '100%' }}>
        <Col className='body-container'>
          <Form>
            <Card className='cardCreate ml-2 mt-4 mr-2'>
              <div className='mt-5 mr-5 ml-5'>
                <Row>
                  <Col>
                    <p style={leftlStyle}>{renderTitle()}</p>
                  </Col>

                  {renderArraw(history)}
                </Row>
                <br />
                <Row>
                  <Col>
                    <p className='page_title' style={leftlStyle}>
                      {local.MainInfo}
                    </p>
                  </Col>
                </Row>
                <Row className='mt-4'>
                  <Col>
                    <ul style={{ fontSize: '12px' }}>
                      <BulletPoint info={local.ExcelFile} />
                      <BulletPoint info={local.NameColumn} />
                      <BulletPoint info={local.UserNameColumn} />
                      <BulletPoint info={local.AddressColumn} />
                      <BulletPoint info={local.MobileColumn} />
                      <BulletPoint info={local.EmailColumn} />
                      <BulletPoint info={local.NationalIdColumn} />
                      <BulletPoint info={local.PasswordColumn} />
                      <BulletPoint info={local.ConfirmPasswordColumn} />
                    </ul>
                  </Col>
                </Row>

                {/* {fileOptions.errorData !== null ? (
                  fileOptions.errorData.length > 0 ? (
                    <Row className="mt-2">
                      <Col>
                        <ErrorCard errorMessage={fileOptions.errorData} />
                      </Col>
                    </Row>
                  ) : null
                ) : null} */}

                <Row className='mt-2'>
                  <Col xl={2}>
                    <a
                      href
                      class='btn btn-primary btn_link'
                      style={{ color: 'white', cursor: 'pointer' }}
                    >
                      {local.ChooseFile}
                      <Input
                        type='file'
                        style={{ height: '100%', cursor: 'pointer' }}
                        className='file_create'
                        onChange={onRead}
                        onClick={(e) => (e.target.value = null)}
                      />
                    </a>
                  </Col>
                </Row>

                {state.tableData && (
                  <Row className='mt-1'>
                    <WorkerFileTable
                      {...props}
                      employeeDataState={state}
                      setEmployeeDataState={setEmployeeDataState}
                    />
                  </Row>
                )}

                {state.tableData && (
                  <center>
                    <button
                      disabled={isLoading}
                      className='save_btn mt-5'
                      onClick={onSubmit}
                    >
                      {isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <>
                          <p style={{ display: 'inline' }}>{local.Save} </p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </>
                      )}
                    </button>
                  </center>
                )}
                <br />
              </div>
            </Card>
          </Form>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = ({
  WorkerR,
  CompanyR,
  CompanyWorkFieldR,
  RequestsR,
  BranchR,
}) => {
  return {
    fileOptions: WorkerR.fileOptions,
    pointsOptions: WorkerR.pointsOptions,
    companyOptions: CompanyR.companyOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    workFieldsNewOptions: WorkerR.workFieldsNewOptions,
    pointsOptions: RequestsR.pointsOptions,
    workerOptions: WorkerR.workerOptions,
    jobTitlesOptions: WorkerR.jobTitlesOptions,
    workerTypeOption: WorkerR.workerTypeOption,
    workerObj: WorkerR.workerObj,
    lightbox: WorkerR.lightbox,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(AddWorkerFile);
