import React from "react";
import { Col, Row, CardBody } from "reactstrap";
import { local } from "../../lang/local";
import { UPDATE_USER_PROPS } from "../../redux/actions/types";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { labelStyle } from "../../utils/GeneralStyle";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import { renderAddButton, RenderTable } from "./UserFunctions";
import { renderWorkFSelect } from "../CompainesWorkFields/CompanyWFFunctions";
import {
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
const SecondTab = (props) => {
  const {
    general,
    userForm,
    companyOptions,
    loadCompaniesOptions,
    loadBranchesOptions,
    branchOptions,
    loadCompaniesWFOptions,
    companyWfOptions,
    setUserProps
  } = props;
  return (
    <CardBody className="trans">
      {checkHasOneCompany() === false ? (
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <span className="label_title" style={labelStyle}>
              {local.Compaines}
            </span>
            {renderCompanySelect(
              "userForm.arrayOptions.company",
              userForm.arrayOptions.company,
              UPDATE_USER_PROPS,
              general,
              local.Compaines,
              loadCompaniesOptions,
              companyOptions,
              null,
              [
                { p: "userForm.arrayOptions.branch_parent", v: null },
                { p: "userForm.arrayOptions.branch", v: null },
              ],
              "4",
              // userForm.iscreate
              null
            )}
          </Col>
        </Row>
      ) : null}

      <Row className="mt-4">
        {/* <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className="label_title" style={labelStyle}>
            {local.Regions}
          </span>

          {renderBranchSelect(
             "userForm.arrayOptions.branch_parent",
             userForm.arrayOptions.branch_parent,
             UPDATE_USER_PROPS,
             general,
             local.Regions,
             loadBranchesOptions,
             branchOptions,
             userForm.arrayOptions.company,
             null,
             [
               { p: "userForm.arrayOptions.branch", v: null },
               { p: "userForm.formvalues.work_fields", v: null },
             ],
             "4",
             userForm.iscreate,
             undefined,
             undefined,
             0,
             !!!userForm.arrayOptions.company
          )}
        </Col> */}
        <CustomAsyncPaginate
          loadOptions={getBranchOptions(userForm.arrayOptions.company, {
            parent_id: 0,
          })}
          value={userForm.arrayOptions.branch_parent}
          label={local.Regions}
          placeholder={local.Regions}
          fullWidth
          hasLabel
          cacheUniqsArr={[userForm.arrayOptions.company]}
          isClearable
          onChange={(e) =>
            handleSelectChangeEnhanced(
              e,
              "userForm.arrayOptions.branch_parent",
              setUserProps,
              ["userForm.arrayOptions.branch"]
            )
          }
          additional={{
            page: 1,
          }}
          isDisabled={!!!userForm.arrayOptions.company}
        />
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className="label_title" style={labelStyle}>
            {local.Cities}
          </span>

          {renderBranchSelect(
            "userForm.arrayOptions.branch",
            userForm.arrayOptions.branch,
            UPDATE_USER_PROPS,
            general,
            local.Cities,
            loadBranchesOptions,
            branchOptions,
            userForm.arrayOptions.company,
            null,
            [{ p: "userForm.formvalues.work_fields", v: null }],
            "4",
            userForm.iscreate,
            undefined,
            undefined,
            userForm.arrayOptions?.branch_parent?.id,
            !!!userForm.arrayOptions.branch_parent?.id
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <span className="label_title" style={labelStyle}>
            {local.Zones}
          </span>
          {renderWorkFSelect(
            "userForm.formvalues.work_fields",
            userForm.formvalues.work_fields,
            UPDATE_USER_PROPS,
            general,
            local.Zones,
            loadCompaniesWFOptions,
            companyWfOptions,
            true,
            null,
            userForm.arrayOptions.branch,
            "4",
            undefined,
            userForm.iscreate,
            undefined,
            undefined,
            !!!userForm.arrayOptions.branch
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderAddButton(
            general,
            userForm.arrayOptions,
            userForm.formvalues.work_fields,
            companyOptions,
            branchOptions
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          <RenderTable
            arrayOptions={userForm.arrayOptions}
            general={general}
            loadCompaniesWFOptions={loadCompaniesWFOptions}
          />
        </Col>
      </Row>
    </CardBody>
  );
};
export default SecondTab;
