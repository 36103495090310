import { Card, Table } from "reactstrap";
import ARROW_DOWN from "../../assets/img/tbl_sort.png";
import ARROW_UP from "../../assets/img/up_img.png";
import {
  renderDate,
  renderTableHeadItem,
} from "../../utils/GeneralFunctions";
import { getLanguage, local } from "../../lang/local";
import EDIT_ICON from "../../assets/img/edit_icon.png";

const GiftsAndSamplesTable = ({
  tableHeaders,
  giftsTableData,
  sortGiftsTable,
  giftsTableSorting,
  setGiftsProps,
}) => {
  const renderTableBody = () => {
    let item = [];
    for (let i = 0; i <= giftsTableData.data.length - 1; i++) {
      let giftObject = {
        id: giftsTableData.data[i].id,
        name: giftsTableData.data[i].name,
        type: giftsTableData.data[i].type,
        details: giftsTableData.data[i].details,
      };
      item.push(
        <tr>
          <td className="td_table">
            {giftsTableSorting.sortedArray[i].type === 0
              ? local.Gift
              : local.Sample}
          </td>
          <td className="td_table">{giftsTableSorting.sortedArray[i].name}</td>
          <td className="td_table">
            {giftsTableSorting.sortedArray[i].details}
          </td>
          <td className="td_table">
            {getLanguage() === "ar"
              ? giftsTableSorting.sortedArray[i].company.name.ar
              : giftsTableSorting.sortedArray[i].company.name.en}
          </td>
          <td className="td_table">
            {renderDate(giftsTableSorting.sortedArray[i].created_at)}
          </td>
          <td className="td_table">
            <img
              onClick={() => {
                setGiftsProps([
                  ["editGiftsModal.isOpen", true],
                  ["editGiftsModal.gift", giftObject],
                ]);
              }}
              src={EDIT_ICON}
              role="button"
              height="20px"
              alt="edit"
            />
          </td>
        </tr>
      );
    }
    return item;
  };

  return (
    <Card className='cardStyle mt-2'>
      <Table
        responsive
        className="table_list mt-4 mb-4"
        hover
        style={{ borderTopColor: "white", border: "none" }}
        id="state_table"
      >
        <thead>
          <tr className="table_head">
            <th className="td_table">
              <div style={{ display: "flex", minWidth: "100px" }}>
                <div className="mx-2">{tableHeaders[0]}</div>
                {giftsTableSorting.order === "asc" ? (
                  <img
                    onClick={() =>
                      sortGiftsTable(giftsTableData.data, "type", "desc")
                    }
                    src={ARROW_UP}
                    alt="arrow down"
                    width="13"
                    height="13"
                    className="mb-1 ml-1"
                    role="button"
                  />
                ) : (
                  <img
                    onClick={() =>
                      sortGiftsTable(giftsTableData.data, "type", "asc")
                    }
                    src={ARROW_DOWN}
                    alt="arrow down"
                    width="13"
                    height="13"
                    className="mb-1 ml-1"
                    role="button"
                  />
                )}
              </div>
            </th>
            <th className="td_table">
              <span className="mx-2">{tableHeaders[1]}</span>
              {giftsTableSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsTable(giftsTableData.data, "name", "desc")
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsTable(giftsTableData.data, "name", "asc")
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>

            {renderTableHeadItem(tableHeaders[2])}
            {renderTableHeadItem(tableHeaders[3])}
            <th className="td_table">
              <span className="mx-1">{tableHeaders[4]}</span>
              {giftsTableSorting.order === "asc" ? (
                <img
                  onClick={() =>
                    sortGiftsTable(giftsTableData.data, "created_at", "desc")
                  }
                  src={ARROW_UP}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              ) : (
                <img
                  onClick={() =>
                    sortGiftsTable(giftsTableData.data, "created_at", "asc")
                  }
                  src={ARROW_DOWN}
                  alt="arrow down"
                  width="13"
                  height="13"
                  className="mb-1 ml-1"
                  role="button"
                />
              )}
            </th>
            {renderTableHeadItem(tableHeaders[5])}
          </tr>
        </thead>
        <tbody>{giftsTableData ? renderTableBody() : ""}</tbody>
      </Table>
    </Card>
  );
};

export default GiftsAndSamplesTable;
