import {
  CLEAR_AMOUNT_PRODUCT_STATE,
  UPDATE_AMOUNT_PRODUCT_PROPS,
  MERGE_AMOUNT_PRODUCT_PROPS,
  DELETE_AMOUNT_PRODUCT_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  amountObj: {
    amountItem: null,
    isLoading: false,
  },
  sorting: { column: "", order: "desc" },

  lightbox: { isOpen: false, image: null },

  searchForm: {
    company: null,
    branch_id: null,
    ids: null,
    from: "",
    to: "",
    pointID: null,
    workerID: null,
    page: 1,
  },
  isLoadingSearch: false,
  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_AMOUNT_PRODUCT_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_AMOUNT_PRODUCT_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_AMOUNT_PRODUCT_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_AMOUNT_PRODUCT_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
