import React, { useEffect } from "react";
import { Col, Row, Button } from "reactstrap";
import { getLanguage, local } from "../lang/local";
import nocon from "../assets/img/nocon.jpg";
const NoConnection = ({ history }) => {
  useEffect(() => {
    window.scrollTo(0, 0)
  })
  return (
    <div className="error_div">
      <Row
        style={{
          width: "100%",
          marginRight: getLanguage() === "en" ? "0.5px" : null,
          marginLeft: getLanguage() === "ar" ? "0.5" : null,
        }}
      >
        <Col className="text-center" xl={12}>
          <img src={nocon} />
        </Col>
        <Col className="text-center" xl={12}>
          <h3>{local.NoInternet}</h3>
        </Col>

        <Col className="text-center mt-5" xl={12}>
          <Button className="btn_add_wfield" onClick={() => history.goBack()}>
            <p style={{ marginBlock: "5px", marginInline: "20px" }}>
              {local.Refresh}
            </p>
          </Button>
        </Col>
      </Row>
    </div>
  );
};

export default NoConnection;
