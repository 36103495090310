import { FaSearch } from 'react-icons/fa';
import "./usage-home-header.css";
import { local, getLanguage } from '../../../../lang/local';

const UsageHomeHeader = () => {
  return (
    <div style={{direction: getLanguage() === "ar" ? "rtl" : "ltr"}} id="page-header" className="row flex-column-reverse flex-lg-row mx-3 mt-5 px-5 py-5">
      <div style={{fontSize: "20px"}}>{local.UsageInstructions}</div>
      <div style={{position: "relative", display: "flex"}} className="my-3">
        <input placeholder={local.Search} id="search-input"/>
        <FaSearch
          style={getLanguage() === "ar" ? {left: "10px"} : {right: "10px"}}
          id="page-header_search-icon"
        />
      </div>
    </div>
  )
}

export default UsageHomeHeader;