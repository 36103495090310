import { getLanguage, local } from "../../lang/local";
import {
  UPDATE_COMPANY_PROPS,
  MERGE_USER_PROPS,
  UPDATE_USER_PROPS,
  DELETE_USER_PROPS,
  CLEAR_USER_STATE,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import {
  checkHasOneCompany,
  getDeafultItem,
  getMyCompany,
  getUser,
} from "../../utils/GeneralFunctions";
import { Button, Table } from "reactstrap";
import { FiXSquare } from "react-icons/fi";
import React, { useState } from "react";
import edit_icon from "../../assets/img/edit_icon.png";
import { selectStyle3 } from "../../utils/GeneralStyle";
import { AsyncPaginate } from "react-select-async-paginate";
export const unsetForm = (general) => {
  general(
    [
      {
        prop: "userForm",
        value: {
          isLoading: false,
          formvalues: {
            password: "",
            name: "",
            email: "",
            password_confirmation: "",
            companies: null,
            branches: null,
            work_fields: null,
            permissions: [],
          },
          arrayOptions: {
            companies: [],
            branches: [],
            work_fields: [],
            company: null,
            branch: null,
          },
          index: null,
          iscreate: "create",
        },
      },
      { prop: "userOptions", value: [] },

      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
          tabs_title: [
            {
              title:
                getLanguage() === "en"
                  ? "Main Information"
                  : "البيانات الرئيسية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
            {
              title:
                getLanguage() === "en"
                  ? "Main Information"
                  : "البيانات الرئيسية",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
            {
              title:
                getLanguage() === "en"
                  ? "System Data"
                  : "البيانات الخاصة بالنظام",
              active: "active",
              dots_pagination_active: "dots_pagination_active",
            },
          ],

          activeIndex: 0,
          tabAction: "next",
        },
      },
    ],
    UPDATE_USER_PROPS
  );
};

export const validateInputs = (userForm, arrayOptions, permissionsOptions) => {
  if (userForm.formvalues.name === "") {
    toaster.notify(local.NameRequired, {
      duration: 2000,
    });
  }
  if (userForm.formvalues.password !== undefined) {
    if (userForm.formvalues.password === "") {
      toaster.notify(local.PasswordRequired, {
        duration: 2000,
      });
    }
  }
  if (userForm.formvalues.email === "") {
    toaster.notify(local.EmailRequired, {
      duration: 2000,
    });
  }
  if (userForm.formvalues.password_confirmation !== undefined) {
    if (userForm.formvalues.password_confirmation === "") {
      toaster.notify(local.PasswordConfrimRequired, {
        duration: 2000,
      });
    }
  }

  // if (arrayOptions.companies.length === 0) {
  //   toaster.notify(local.ChooseCompany, {
  //     duration: 2000,
  //   });
  // }

  // if (arrayOptions.branches.length === 0) {
  //   toaster.notify(local.selectBranch, {
  //     duration: 2000,
  //   });
  // }
  // if (arrayOptions.work_fields.length === 0) {
  //   toaster.notify(local.SelectWorkField, {
  //     duration: 2000,
  //   });
  // }

  const validateSpecialPermissions = (permission, arrOptProp) => {
    if (
      permissionsOptions.findIndex(
        (perOpt) => perOpt.permission === permission
      ) !== -1
    ) {
      return permissionsOptions.find(
        (perOpt) => perOpt.permission === permission
      ).isSelected === true || arrayOptions.work_fields.length > 0
        ? true
        : false;
    } else {
      return arrayOptions[arrOptProp].length > 0 ? true : false;
    }
  };

  if (
    userForm.formvalues.name !== "" &&
    userForm.formvalues.email !== "" &&
    userForm.formvalues.password !== "" &&
    userForm.formvalues.password_confirmation !== ""
    // permissionsOptions.findIndex(
    //   (perOpt) => perOpt.permission === "all_work_fields"
    // ) !== -1
    //   ? permissionsOptions.find(
    //       (perOpt) => perOpt.permission === "all_work_fields"
    //     ).isSelected === true || arrayOptions.work_fields.length > 0
    //   : arrayOptions.work_fields.length > 0 &&
    //     permissionsOptions.findIndex(
    //       (perOpt) => perOpt.permission === "all_branches"
    //     ) !== -1
    //   ? permissionsOptions.find(
    //       (perOpt) => perOpt.permission === "all_branches"
    //     ).isSelected === true || arrayOptions.work_fields.length > 0
    //   : arrayOptions.branches.length > 0 &&
    //     permissionsOptions.findIndex(
    //       (perOpt) => perOpt.permission === "all_companies"
    //     ) !== -1
    //   ? permissionsOptions.find(
    //       (perOpt) => perOpt.permission === "all_companies"
    //     ).isSelected === true || arrayOptions.work_fields.length > 0
    //   : arrayOptions.companies.length > 0

    // arrayOptions.companies.length > 0 &&
    // arrayOptions.branches.length > 0 &&
    // arrayOptions.work_fields.length > 0
  ) {
    if (!validateSpecialPermissions("all_companies", "companies")) {
      toaster.notify(local.SpecialPermissionsError, {
        duration: 2000,
      });
      return false;
    }

    if (!validateSpecialPermissions("all_branches", "branches")) {
      toaster.notify(local.SpecialPermissionsError, {
        duration: 2000,
      });
      return false;
    }

    if (!validateSpecialPermissions("all_work_fields", "work_fields")) {
      toaster.notify(local.SpecialPermissionsError, {
        duration: 2000,
      });
      return false;
    }

    return true;
  }
};
export const onSave = (
  e,
  createORUpdateUser,
  userForm,
  permissionsOptions,
  userObj,
  isModal,
  arrayOptions
) => {
  e.preventDefault();

  if (validateInputs(userForm, arrayOptions, permissionsOptions) === true) {
    createORUpdateUser(
      userForm.formvalues,
      userForm.index,
      permissionsOptions,
      userObj.userItem,
      isModal,
      arrayOptions
    );
  }
};

export const renderMyPermissionInCreate = (history) => {
  if (getUser() !== null) {
    let user = getUser()
    let permissions = [];
    let sortedPermissions = [];
    console.log("user.",user)
    user.permissions.map((per) => {
      if (
        !per.permission.includes("exceptions") &&
        !per.permission.includes("login") &&
        !per.permission.includes("updatePushToken") &&
        !per.permission.includes("startWork") &&
        !per.permission.includes("endWork") &&
        !per.permission.includes("todayWork") &&
        !per.permission.includes("updateWorkTime") &&
        !per.permission.includes("reportTemplate") &&
        !per.permission.includes("addArchive") &&
        !per.permission.includes("reportTemplate") &&
        !per.permission.includes("WorkHistory") &&
        !per.permission.includes("pointAction") &&
        !per.permission.includes("profile") &&
        !per.permission.includes("authChannel") &&
        !per.permission.includes("login") &&
        !per.permission.includes("logs")
      ) {
        per.isSelected = false;
        permissions.push(per);
      }
    });

    return permissions.reverse();
  }
};
//City Owner Permissions
// export const renderCityOwnerPermissionInCreate = (history) => {
//   //    let permissions = 
  
//   //    [
//   //     {permission:"city_requests",isSelected:true},
//   //     {permission:"requests/store",isSelected:true},
//   //     {permission:"requests",isSelected:true},
//   //     {permission:"all_work_fields",isSelected:true},
//   //     {permission:"points/actions",isSelected:true},
//   //     {permission:"workers_points/store/{id}",isSelected:true},
//   //     {permission:"workers/{company_id}",isSelected:true},
//   //     {permission:"companies/work_fields/points/list/{id}",isSelected:true},
//   //     {permission:"companies/work_fields/points/show/{id}",isSelected:true},
//   //     {permission:"companies/work_fields/points/store",isSelected:true},
//   //     {permission:"companies/work_fields/points",isSelected:true},
//   //     {permission:"companies/{id}/work_fields",isSelected:true},
//   //     {permission:"companies/{id}/work_fields/all",isSelected:true},
//   //     {permission:"companies/branches/{company_id}",isSelected:true},
//   //     {permission:"states/work_fields",isSelected:true},
//   //     {permission:"states",isSelected:true},
//   //     {permission:"home",isSelected:true}
      
//   // ]
//   if (getUser() !== null) {
//     let user = getUser()
//     let permissions = [];
//     let sortedPermissions = [];
//     console.log("user.",user)
//     user.permissions.map((per) => {
//       if (
//         !per.permission.includes("exceptions") &&
//         !per.permission.includes("login") &&
//         !per.permission.includes("updatePushToken") &&
//         !per.permission.includes("startWork") &&
//         !per.permission.includes("endWork") &&
//         !per.permission.includes("todayWork") &&
//         !per.permission.includes("updateWorkTime") &&
//         !per.permission.includes("reportTemplate") &&
//         !per.permission.includes("addArchive") &&
//         !per.permission.includes("reportTemplate") &&
//         !per.permission.includes("WorkHistory") &&
//         !per.permission.includes("pointAction") &&
//         !per.permission.includes("profile") &&
//         !per.permission.includes("authChannel") &&
//         !per.permission.includes("login") &&
//         !per.permission.includes("logs")
//       ) {
//         per.isSelected = false;
//         permissions.push(per);
//       }
//     });

//     return permissions.reverse();
//   }

//    }
//City Owner Permissions
export const unsetUserListData = (general) => {
  general([], CLEAR_USER_STATE);

  general(
    [
      {
        prop: "companyOptions",
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};

export const renderAddButton = (
  general,
  arrayOptions,
  work_fields,
  companyOptions,
  branchOptions,
  disabled = false
) => {
  return (
    <Button
    disabled={disabled}
      style={{
        backgroundColor: "#003595",
        border: "none",
        borderRadius: "10px",
        float: getLanguage() === "ar" ? "right" : "left",
      }}
      className="mt-4"
      onClick={(e) => {
        onAdd(
          e,
          general,
          arrayOptions,
          work_fields,
          companyOptions,
          branchOptions
        );
      }}
    >
      <p style={{ margin: "4px", fontSize: "12px" }}>{local.AddToTable}</p>
    </Button>
  );
};

export const onAdd = (
  e,
  general,
  arrayOptions,
  work_fields,
  companyOptions,
  branchOptions
) => {
  const defaultCompanyID = checkHasOneCompany() ? getMyCompany() : null;
  e.preventDefault();

  if (arrayOptions.company !== null) {
    let item = getDeafultItem(arrayOptions.company, companyOptions);
    if (item !== undefined) {
      general(
        [
          {
            prop: "userForm.arrayOptions.companies",
            value: item,
          },
        ],
        MERGE_USER_PROPS
      );

      general(
        [
          {
            prop: "userForm.arrayOptions.company",
            value: defaultCompanyID,
          },
        ],
        UPDATE_USER_PROPS
      );
    }
  }
  if (arrayOptions.branch !== null) {
    console.log("branch options", branchOptions);
    let item = getDeafultItem(arrayOptions.branch, branchOptions);
    if (item !== undefined) {
      general(
        [
          {
            prop: "userForm.arrayOptions.branches",
            value: item,
          },
        ],
        MERGE_USER_PROPS
      );
      general(
        [
          {
            prop: "userForm.arrayOptions.branch",
            value: null,
          },
        ],
        UPDATE_USER_PROPS
      );
    }
  }
  if (work_fields !== null) {
    work_fields.map((wf) => {
      general(
        [
          {
            prop: "userForm.arrayOptions.work_fields",
            value: { ...wf, index: arrayOptions.branches.length },
          },
        ],
        MERGE_USER_PROPS
      );
    });

    general(
      [
        {
          prop: "userForm.formvalues.work_fields",
          value: null,
        },
      ],
      UPDATE_USER_PROPS
    );
  }
};

const onAddWorkFields = (e, general, options, branches) => {
  if (e) {
    if (!options.find((item) => item.value === e.value)) {
      general(
        [
          {
            prop: "userForm.arrayOptions.work_fields",
            value: {
              label: e.label,
              value: e.value,
              branch: branches[0].value,
              index: options[0].index,
            },
          },
        ],
        MERGE_USER_PROPS
      );
    }
  }
};

export const RenderTable = ({
  arrayOptions,
  general,
  loadCompaniesWFOptions,
}) => {
  const [isEdit, setEdit] = useState(false);
  const renderEditTable = (options, branches) => {
    if (options) {
      if (options.length > 0) {
        return (
          <React.Fragment>
            <AsyncPaginate
              className="mt-2 mb-5"
              placeholder={local.WorkPlaces}
              isMulti={false}
              styles={selectStyle3}
              loadOptions={(name, loadedOptions, page) =>
                loadCompaniesWFOptions(
                  name,
                  loadedOptions,
                  page,
                  branches[0].value
                )
              }
              isClearable={true}
              onChange={(e) => onAddWorkFields(e, general, options, branches)}
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              additional={{
                page: 1,
              }}
              cacheUniqs={[branches[0].value]}
            />
            <Table
              responsive
              id="stateWf_table"
              hover
              className="table_list mt-5 mb-4"
              style={{
                borderTopColor: "white",
                border: "none",
                position: "relative",
              }}
            >
              <thead>
                <tr className="table_head">
                  <td>{local.WorkField}</td>
                  <td>{local.Remove}</td>
                </tr>
              </thead>

              <tbody>
                {options.map((op, index) => {
                  return (
                    <tr>
                      <td>{op.label}</td>
                      <td className="td_table">
                        <a
                          style={{
                            cursor: "pointer",
                            textDecoration: "underline",
                          }}
                          onClick={(e) =>
                            general(
                              [
                                {
                                  prop:
                                    "userForm.arrayOptions.work_fields." +
                                    index,
                                },
                              ],
                              DELETE_USER_PROPS
                            )
                          }
                        >
                          {local.Remove}
                        </a>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </React.Fragment>
        );
      }
    }
  };
  return (
    <Table
      responsive
      id="stateWf_table"
      hover
      className="table_list mt-4 mb-4"
      style={{
        borderTopColor: "white",
        border: "none",
        position: "relative",
      }}
    >
      <thead>
        <tr className="table_head">
          <td>{local.Company}</td>
          <td>{local.Cities}</td>
          <td>{local.Zones}</td>
          <td>{local.Edit}</td>
          <td>{local.Remove}</td>
        </tr>
      </thead>

      {arrayOptions.companies.length > 0
        ? arrayOptions.branches.map((op, index) => {
            return (
              <tr key={index}>
                <td className="td_table">
                  {getDeafultItem(op.company_id, arrayOptions.companies)?.label}
                </td>
                <td className="td_table">
                  {arrayOptions.branches.length > 0
                    ? arrayOptions.branches[index]?.label
                    : null}
                </td>
                <td className="td_table">
                  {arrayOptions.branches.length > 0 &&
                  arrayOptions.work_fields.length > 0
                    ? isEdit === true
                      ? renderEditTable(
                          arrayOptions.work_fields,
                          arrayOptions.branches
                        )
                      : renderArray(
                          // arrayOptions.branches[index]?.value,
                          index,
                          arrayOptions.work_fields
                        )
                    : null}
                </td>

                <td className="td_table">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => setEdit(!isEdit)}
                  >
                    {isEdit === true ? (
                      <p style={{ textDecoration: "underline" }}>
                        {local.Save}
                      </p>
                    ) : (
                      <img src={edit_icon} width="22" height="22" />
                    )}
                  </a>
                </td>
                <td className="td_table">
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={(e) => onDelete(e, general, index, arrayOptions)}
                  >
                    <FiXSquare style={{ color: "red", fontSize: "25px" }} />
                  </a>
                </td>
              </tr>
            );
          })
        : null}
    </Table>
  );
};

export const renderArray = (index, items) => {
  if (items.length > 0) {
    // let filterd_items = items.filter((wf) => wf.branch === id);
    let filterd_items = items.filter((wf) => wf.index === index);

    if (filterd_items.length > 0) {
      return filterd_items.map((fwf, index) => {
        if (index === 0) {
          return fwf.label;
        } else {
          return " | " + fwf.label;
        }
      });
    }
  }
};

export const onDelete = (e, general, index, arrayOptions) => {
  e.preventDefault();
  if (arrayOptions.branches.length > 0) {
    const filteredWorkFields = arrayOptions.work_fields.filter((wf) => {
      return wf.index !== index;
    });

    console.log("1 filteredWorkFields", filteredWorkFields);

    for (let i = 0; i < filteredWorkFields.length; i++) {
      if (filteredWorkFields[i].index > index) {
        filteredWorkFields[i].index--;
      }
    }

    console.log("2 filteredWorkFields", filteredWorkFields);

    general(
      [
        {
          prop: "userForm.arrayOptions.work_fields",
          value: filteredWorkFields,
        },
      ],
      UPDATE_USER_PROPS
    );

    // let item = arrayOptions.branches[index].value;
    // arrayOptions.work_fields.map((st) => {
    //   if (st.branch === item) {
    //     let item_index = arrayOptions.work_fields.findIndex(
    //       (x) => x.value === st.value
    //     );
    //     if (item_index !== undefined) {
    //       if (item_index !== null) {
    //         general(
    //           [{ prop: "userForm.arrayOptions.work_fields." + item_index }],
    //           DELETE_USER_PROPS
    //         );
    //       }
    //     }
    //   }
    // });
  }

  general(
    [
      // { prop: "userForm.arrayOptions.companies." + index },
      { prop: "userForm.arrayOptions.branches." + index },
    ],
    DELETE_USER_PROPS
  );
};
