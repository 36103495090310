import { Request, Select_Request } from "../Api/index";
import {
  DELETE_BRANCHES_PROPS,
  MERGE_BRANCHES_PROPS,
  UPDATE_BRANCHES_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  renderFormData,
  confirmSunccessed,
  rmUnsetValsAndGetQryStr,
  setterGenerator,
} from "../../utils/GeneralFunctions";

export const setBranchProps = (propsArr) =>
  setterGenerator(UPDATE_BRANCHES_PROPS, propsArr);

export const getBranches = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_BRANCHES_PROPS,
    payload: [{ prop: "branchObj.branchItem", value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_BRANCHES_PROPS,
      payload: [{ prop: "branchObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_BRANCHES_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  let url = "companies/branches/" + searchForm.company_id;
  if (searchForm !== null) {
    let searchValues = {
      mobile: searchForm.mobile,
      name: searchForm.name,
      page: searchForm.page,
      from: searchForm.from,
      to: searchForm.to,
    };
    let newSearchForm = removeUnsetValues(searchValues);
    delete newSearchForm.company_id;
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }
  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_BRANCHES_PROPS,
            payload: [{ prop: "branchObj.branchItem", value: res.data.data }],
          });
        }

        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [
          { prop: "branchObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_BRANCHES_PROPS,
        payload: [
          { prop: "branchObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getBranchesForDropDown = (value) => (dispatch) => {
  Request()
    .get("companies/branches/" + value)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: getLanguage() === "en" ? r.name.en : r.name.ar,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_BRANCHES_PROPS,
            payload: [{ prop: "branchOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateBranch =
  (formValues, index, isModal) => (dispatch) => {
    dispatch({
      type: UPDATE_BRANCHES_PROPS,
      payload: [{ prop: "branchForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues.id === undefined) {
      url = "/companies/branches/store/";
    } else {
      url = "/companies/branches/update/" + formValues.id;
    }

    if (formValues.parent_id) {
      formValues.parent_id = formValues.parent_id.id;
    }

    let newFormValues = Object.assign({}, formValues);

    Request()
      .post(url, newFormValues)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);

            if (isModal === true) {
              // dispatch({
              //   type: MERGE_BRANCHES_PROPS,
              //   payload: [
              //     { prop: "branchObj.branchItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);
            if (isModal === true) {
              dispatch({
                type: UPDATE_BRANCHES_PROPS,
                payload: [
                  {
                    prop: "branchObj.branchItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }

          dispatch({
            type: UPDATE_BRANCHES_PROPS,
            payload: [
              {
                prop: "branchForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    name: { ar: "", en: "" },
                    address: "",
                    mobile: "",
                    manager_name: "",
                    company_id: null,
                  },
                  index: null,
                },
              },
              { prop: "branchOptions", value: [] },

              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],
                  activeIndex: 0,
                  tabAction: "next",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }
        dispatch({
          type: UPDATE_BRANCHES_PROPS,
          payload: [{ prop: "branchForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_BRANCHES_PROPS,
          payload: [{ prop: "branchForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getBranch = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("/companies/branches/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_BRANCHES_PROPS,
              payload: [
                {
                  prop: "branchForm.formvalues",
                  value: res.data.data,
                },
              ],
            });
          } else {
            let result = [
              {
                label:
                  getLanguage() === "en"
                    ? res.data.data.name.en
                    : res.data.data.name.ar,
                value: res.data.data.id,
              },
            ];

            dispatch({
              type: UPDATE_BRANCHES_PROPS,
              payload: [{ prop: "branchOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const saveCompanyDataFromFile =
  (items, headers, logo, stateWF, state) => (dispatch) => {
    let data = items;
    let englishIndex = headers.indexOf("EnglishName");
    let arabicIndex = headers.indexOf("ArabicName");
    let addressIndex = headers.indexOf("Address");
    let mobileIndex = headers.indexOf("Mobile");
    let taxIndex = headers.indexOf("Tax");
    let logoIndex = headers.indexOf(logo);
    let statendex = headers.indexOf(state);
    let stateWFIndex = headers.indexOf(stateWF);

    dispatch({
      type: UPDATE_BRANCHES_PROPS,
      payload: [{ prop: "fileOptions.isLoading", value: true }],
    });

    data.map((row, index) => {
      let states = row[statendex];
      if (states !== null) {
        states = states.map((st) => st.value);
      }

      let statesWF = row[stateWFIndex];
      if (statesWF !== null) {
        statesWF = statesWF.map((stw) => stw.value);
      }

      let image = row[logoIndex];
      let formData = null;
      let formValues = {
        states: states,
        work_fields: statesWF,
        address: row[addressIndex],
        mobile: row[mobileIndex],
        identifier: row[taxIndex],
        name: { en: row[englishIndex], ar: row[arabicIndex] },
        logo: null,
      };

      if (image === null || image === false) {
        formData = formValues;
      } else {
        formValues.logo = image;
        formData = renderFormData(formValues);
      }
      Request()
        .post("/companies/store", formData)
        .then((res) => {
          if (res.data.status === true) {
            dispatch({
              type: UPDATE_BRANCHES_PROPS,
              payload: [
                {
                  prop: "fileOptions.importedData." + index + "." + row.length,
                  value: true,
                },
              ],
            });

            dispatch({
              type: DELETE_BRANCHES_PROPS,
              payload: [{ prop: "fileOptions.savedData." + index }],
            });

            if (index === data.length - 1) {
              confirmSunccessed(local.SuccessfullyAdded);
              dispatch({
                type: UPDATE_BRANCHES_PROPS,
                payload: [
                  { prop: "fileOptions.savedData", value: null },
                  { prop: "fileOptions.isLoading", value: false },
                ],
              });
            }
          } else {
            Object.values(res.data.error).map((msg) => {
              dispatch({
                type: MERGE_BRANCHES_PROPS,
                payload: [
                  {
                    prop: "fileOptions.errorData",
                    value: [msg[0] + local.Name + " : " + formValues.name],
                  },
                ],
              });
            });

            dispatch({
              type: UPDATE_BRANCHES_PROPS,
              payload: [{ prop: "fileOptions.isLoading", value: false }],
            });
          }
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_BRANCHES_PROPS,
            payload: [{ prop: "fileOptions.isLoading", value: false }],
          });
          console.log(err);
        })
        .finally();
    });
  };

export const loadBranchesOptions =
  (name, loadedOptions, page, company_id, parentBranchId) =>
  async (dispatch) => {
    if (company_id !== null) {
      let url = null;

      let searchForm = {
        name: name,
        page: page.page,
      };

      if (typeof parentBranchId == "number") {
        searchForm.parent_id = parentBranchId;
      }

      const searchParams = rmUnsetValsAndGetQryStr(searchForm);

      url = `${Select_Request.url}/companies/branches/${company_id}${searchParams}`;

      let response = await fetch(url, Select_Request.options);
      let responseOK = response && response.ok;
      if (responseOK) {
        let result = await response.json();

        let options = [];

        if (result.status === true && typeof parentBranchId == 'number') {
          if (result.data.data !== null) {
            result.data.data.map((d) => {
              options.push({
                label: getLanguage() === "en" ? d.name.en : d.name.ar,
                value: d.id,
                active: d.active,
                company: d.company,
                company_id: d.company.id,
              });
            });
          }
          // dispatch({
          //   type: UPDATE_BRANCHES_PROPS,
          //   payload: [{ prop: "branchOptions", value: options }],
          // });
          let entry = {
            result: result.data.data,
            has_more:
              result.data.pagination.current_page ===
              result.data.pagination.total_pages
                ? false
                : true,
          };
          if (parentBranchId == 0) {
            dispatch({
              type: UPDATE_BRANCHES_PROPS,
              payload: [
                {
                  prop: "parentBranchOptions",
                  value: [...loadedOptions, ...options],
                },
              ],
            });
          } else {
            dispatch({
              type: UPDATE_BRANCHES_PROPS,
              payload: [
                {
                  prop: "branchOptions",
                  value: [...loadedOptions, ...options],
                },
              ],
            });
          }

          return {
            options: options,
            hasMore: entry.has_more,
            additional: {
              page: page.page + 1,
            },
          };
        } else {
          return {
            options: [],
            hasMore: false,
            additional: {
              page: 1,
            },
          };
        }
      }
    }
  };
