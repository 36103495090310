import { Row, Spinner } from "reactstrap";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
import {
  getValueOrNull,
  handleError,
  handleSelectChange,
} from "../../utils/GeneralFunctions";
import { labelStyle, leftlStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import icon_btn from "../../assets/img/icon_btn.png";
import { UPDATE_COVERAGE_LIST_PROPS } from "../../redux/actions/types";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import StaticOptionsSelect from "../../components/StaticOptionsSelect/StaticOptionsSelect";

const RemoveFromCoverageList = ({
  CoverageListReducer,
  history,
  loadCompaniesOptions,
  companyOptions,
  workerOptions,
  loadBranchesOptions,
  branchOptions,
  getCompanyWorkFieldOptions,
  companyWfOptions,
  getPointsOptions,
  pointsOptions,
  setCoverageListProps,
  loadWorkerOptions,
  employeeWorkFields,
  removePointsFromEmployee,
  getEmployeePointsOptions,
  searchForm,
  clearCoverageListProps,
  general,
}) => {
  const { selectedCompany, selectedEmployee, removeFromCoverageListForm } =
    CoverageListReducer;

  const validateRemovingFromList = () => {
    const { workField, points } = removeFromCoverageListForm;

    if (!selectedEmployee) {
      handleError(local.SelectEmployee);
    } else if (!workField) {
      handleError(local.SelectWorkField);
    } else if (!points) {
      handleError(local.SelectWorkFieldPoint);
    }

    if ((!selectedEmployee || !workField, !points)) return false;
    if (points.length === 0) return false;
    else return true;
  };

  const removePointsFromEmployeeList = () => {
    if (validateRemovingFromList()) {
      const { selectedEmployee, removeFromCoverageListForm } =
        CoverageListReducer;
      const { workField, points } = removeFromCoverageListForm;
      const employeePoints = points.map((point) => point.id);
      removePointsFromEmployee(
        {
          employeeID: selectedEmployee,
          employeePoints,
          workField,
        },
        clearCoverageListProps
      );
    }
  };

  return (
    <>
      <div>
        <p className="page_title mt-3" style={leftlStyle}>
          {local.RemoveFromCoverageList}
        </p>
      </div>
      <Row className="justify-content-center justify-content-lg-start col-12">
        {/* <div className="react-select-div mt-4">
          <span className="label_title" style={labelStyle}>
            {local.Branch}
          </span>

          {renderBranchSelect(
            "removeFromCoverageListForm.branch",
            removeFromCoverageListForm.branch,
            UPDATE_COVERAGE_LIST_PROPS,
            general,
            local.Branch,
            loadBranchesOptions,
            branchOptions,
            selectedCompany,
            null,
            [
              { p: "removeFromCoverageListForm.workField", v: null },
              { p: "removeFromCoverageListForm.points", v: null },
            ],
            null
          )}
        </div> */}

        {/* <div className="mt-4">
          <span className="label_title" style={labelStyle}>
            {local.WorkField}
          </span>
          <CustomAsyncPaginate
            loadOptions={getCompanyWorkFieldOptions}
            value={getValueOrNull(
              removeFromCoverageListForm.workField,
              companyWfOptions
            )}
            placeholder={local.WorkField}
            cacheUniqsArr={[removeFromCoverageListForm.branch]}
            isMulti={false}
            onChange={(e) =>
              handleSelectChange(
                e,
                "removeFromCoverageListForm.workField",
                setCoverageListProps,
                ["removeFromCoverageListForm.points"],
                false
              )
            }
            additional={{
              page: 1,
              branchId: removeFromCoverageListForm.branch,
            }}
          />
        </div> */}

        <StaticOptionsSelect
          title={local.WorkField}
          prop="removeFromCoverageListForm.workField"
          value={removeFromCoverageListForm.workField}
          action={UPDATE_COVERAGE_LIST_PROPS}
          options={employeeWorkFields}
          hasLabel={true}
          dependentProps={["removeFromCoverageListForm.points"]}
          className="mt-4"
        />

        <div className="mt-4">
          <span className="label_title" style={labelStyle}>
            {local.WorkFieldPoint}
          </span>
          <CustomAsyncPaginate
            // loadOptions={getPointsOptions}
            loadOptions={getEmployeePointsOptions}
            value={removeFromCoverageListForm.points}
            placeholder={local.WorkFieldPoint}
            isMulti={true}
            onChange={(e) =>
              handleSelectChange(
                e,
                "removeFromCoverageListForm.points",
                setCoverageListProps,
                [],
                true
              )
            }
            cacheUniqsArr={[removeFromCoverageListForm.workField]}
            additional={{
              page: 1,
              filters: {
                employeeID: selectedEmployee,
                workField: removeFromCoverageListForm.workField,
                companyId: selectedCompany
              },
              // companyId: selectedCompany,
              // workFields: [removeFromCoverageListForm.workField],
            }}
          />
        </div>
      </Row>
      <br />
      <center>
        <button
          type="button"
          className="save_btn mt-5"
          onClick={removePointsFromEmployeeList}
          disabled={searchForm.isLoading || searchForm.isLoading2}
        >
          <>
            {searchForm.isLoading2 ? (
              <Spinner type="grow" color="dark" size="sm" />
            ) : (
              <p style={{ display: "inline" }}>{local.Remove}</p>
            )}
          </>
        </button>
      </center>
    </>
  );
};

export default RemoveFromCoverageList;
