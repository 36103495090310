import React from "react";
import dotProp from "dot-prop-immutable";
import { SERVICE_USER } from "./StaticKeys";
import tbl_sort from "../assets/img/tbl_sort.png";
import { confirmAlert } from "react-confirm-alert";
import up_img from "../assets/img/up_img.png";
import nologo from "../assets/img/nologo.png";
import { getLanguage, local } from "../lang/local";
import { AsyncPaginate } from "react-select-async-paginate";
import { numberToArabic } from "number-to-arabic";
import sortBy from "lodash/sortBy";
import { Col, Card, Input, Row, Button } from "reactstrap";
import logo_img from "../assets/img/logo_img.png";
import Dropzone from "react-dropzone";
import PhoneInput from "react-phone-input-2";
import ar from "react-phone-input-2/lang/ar.json";
import Style from "../assets/general-style/Custome_Style_OrderSearch.module.css";
import {
  labelStyle,
  selectStyle3,
  rightStyle,
  leftlStyle,
} from "./GeneralStyle";
import AsyncSelect from "react-select/async";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Switch from "@material-ui/core/Switch";
import Flatpickr from "react-flatpickr";
import { Arabic } from "flatpickr/dist/l10n/ar.js";
import { FiXSquare } from "react-icons/fi";
import PaginationEntry from "./PaginationEntry";
import toaster from "toasted-notes";
import {
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  UPDATE_WORKER_PROPS,
  UPDATE_COMPANY_PROPS,
} from "../redux/actions/types";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import edit_icon from "../assets/img/edit_icon.png";
import file_img from "../assets/img/file.png";
import axios from "axios";
import config from "../config.json";
import { getRequest, getToken } from "../redux/Api";
import {
  setBranchProps,
  setCompanyProps,
  setRequestsProps,
  setCompanyWorkFieldProps,
  setWorkerProps,
  setMerchantProps
} from "../redux/actions";
import moment from "moment";
import "../components/Input/input.css";

export const getUser = () => {
  let item = JSON.parse(localStorage.getItem(SERVICE_USER));

  if (item !== null) {
    return item;
  } else {
    return null;
  }
};

export const DateToArabic = (date1) => {
  if (date1 !== undefined && date1 !== "") {
    let date = new Date(date1);
    date = date.toGMTString();
    let day = date.split(",")[0];
    let result = null;
    if (day === "Sat") {
      result = "السبت";
    } else if (day === "Sun") {
      result = "الاحد";
    } else if (day === "Mon") {
      result = "الاتنين";
    } else if (day === "Tue") {
      result = "الثلاثاء";
    } else if (day === "Wed") {
      result = "الاربعاء";
    } else if (day === "Thu") {
      result = "الخميس";
    } else {
      result = "الجمعة";
    }

    let month = date.split(",")[1].split(" ")[2];

    let arabicMonth = null;
    if (month === "Jan") {
      arabicMonth = "يناير";
    } else if (month === "Feb") {
      arabicMonth = "فبراير";
    } else if (month === "Mar") {
      arabicMonth = "مارس";
    } else if (month === "Apr") {
      arabicMonth = "ابريل";
    } else if (month === "May") {
      arabicMonth = "مايو";
    } else if (month === "June") {
      arabicMonth = "يونيو";
    } else if (month === "Jun") {
      arabicMonth = "يونيو";
    } else if (month === "Jul") {
      arabicMonth = "يوليو";
    } else if (month === "Aug") {
      arabicMonth = "أغسطس";
    } else if (month === "Sep") {
      arabicMonth = "سبتمبر";
    } else if (month === "Oct") {
      arabicMonth = "اكتوبر";
    } else if (month === "Nov") {
      arabicMonth = "نوفمبر";
    } else {
      arabicMonth = "ديسمبر";
    }
    result =
      result +
      " ," +
      numberToArabic(Number(date.split(",")[1].split(" ")[1])) +
      " " +
      arabicMonth +
      " " +
      numberToArabic(Number(date.split(",")[1].split(" ")[3]));

    return result;
  }
};

export const euroFormattedDate = (date) => {
  let euroFormattedDateArr = new Date(date).toLocaleDateString().split("/");
  let newDateArr = [
    euroFormattedDateArr[1],
    euroFormattedDateArr[0],
    euroFormattedDateArr[2],
  ];
  let newFormattedDate = newDateArr.join("/");
  return newFormattedDate;
};

export const renderDate = (date) => {
  if (getLanguage() === "en") {
    return euroFormattedDate(date);
  } else {
    return DateToArabic(new Date(date));
  }
};

export const flatpickrDateFormat = (date) => {
  let dateFormatted = euroFormattedDate(date);
  let dateFormattedArr = dateFormatted.split("/").reverse();
  let reversedDate = dateFormattedArr.join("-");
  console.log("reversedDate", reversedDate);
  return reversedDate;
};

export const renderTime = (date) => {
  if (getLanguage() === "en") {
    return new Date(date).toLocaleTimeString("en", {
      hour: "2-digit",
      minute: "2-digit",
    });
  } else {
    return new Date(date).toLocaleTimeString("ar", {
      hour: "2-digit",
      minute: "2-digit",
    });
  }
};

export const renderNumber = (id) => {
  if (getLanguage() === "en") {
    return id;
  } else {
    return numberToArabic(id);
  }
};

export const getDeafultItem = (entry, options) => {
  if (entry !== undefined && options !== undefined) {
    if (entry !== null && options.length > 0) {
      let item = options.filter(
        (option) => option.id === entry || option.value === entry
      );
      if (item.length > 0) {
        return item[0];
      } else {
        return null;
      }
    } else {
      return null;
    }
  }
};

export const getValueOrNull = (value, options) => {
  if (value) {
    return getDeafultItem(value, options);
  } else return null;
};

export const filterOption = (options) => {
  if (options.length > 0) {
    return options.filter((i) => i.label.toLowerCase());
  }
};

export const promiseOptions = (options) =>
  new Promise((resolve) => {
    setTimeout(() => {
      resolve(filterOption(options));
    }, 1000);
  });

export const renderTableHeadItem = (
  title,
  options,
  column,
  prop,
  action,
  general,
  sorting,
  span,
  length
) => {
  if (title === "") {
    return <th className="td_table"></th>;
  } else if (options === undefined) {
    return <th className="td_table">{title}</th>;
  } else {
    return (
      <th
        className="td_table"
        style={{ verticalAlign: "top", textAlign: "center" }}
        colSpan={span !== undefined ? length : null}
      >
        <a
          href
          style={{ cursor: "pointer", display: "block" }}
          onClick={() =>
            sortTable(options, column, prop, action, general, sorting)
          }
        >
          {sorting.order === "asc" ? (
            <img
              src={tbl_sort}
              alt=""
              width="13"
              height="13"
              className="mb-1"
            />
          ) : (
            <img src={up_img} alt="" width="13" height="13" className="mb-1" />
          )}
        </a>
        {/* &nbsp; */}
        <p style={{ display: "block" }}> {title}</p>
      </th>
    );
  }
};

export const sortTable = (options, column, prop, action, general, sorting) => {
  if (options !== null) {
    let newFilteredArr = sortBy(options.data, column);
    if (sorting.order !== "asc") {
      newFilteredArr = newFilteredArr.reverse();

      general(
        [
          { prop: prop, value: newFilteredArr },
          { prop: "sorting", value: { column: column, order: "asc" } },
        ],
        action
      );
    } else {
      general(
        [
          { prop: prop, value: newFilteredArr },
          { prop: "sorting", value: { column: column, order: "desc" } },
        ],
        action
      );
    }
  }
};

export const renderArrayInTable = (items) => {
  if (items !== null) {
    if (items.length > 0) {
      if (items.length === 1) {
        if (typeof items[0].name === "object") {
          return items[0].name[local.lang];
        } else {
          return items[0].name;
        }
      } else {
        return items.map((entry, index) => {
          if (index === 0) {
            if (typeof entry.name === "object") {
              return entry.name[local.lang];
            } else {
              return entry.name;
            }
          } else if (index === items.length - 1) {
            if (typeof entry.name === "object") {
              return " | " + entry.name[local.lang];
            } else {
              return " | " + entry.name;
            }
          } else {
            if (typeof entry.name === "object") {
              return " | " + entry.name[local.lang];
            } else {
              return " | " + entry.name;
            }
          }
        });
      }
    }
  }
};

export const rednerImageInTable = (photo, action, general) => {
  if (photo !== undefined) {
    if (photo !== null) {
      return (
        <div class="text-center">
          <a
            href
            style={{ cursor: "pointer" }}
            onClick={() =>
              general(
                [
                  { prop: "lightbox.image", value: photo },
                  { prop: "lightbox.isOpen", value: true },
                ],
                action
              )
            }
          >
            <img
              src={photo}
              className="rounded img-fluid"
              alt="img"
              width="50"
              height="50"
            />
          </a>
        </div>
      );
    } else {
      return (
        <div class="text-center">
          <img
            src={nologo}
            className="rounded img-fluid"
            alt="img"
            width="50"
            height="50"
          />
        </div>
      );
    }
  } else {
    return (
      <div class="text-center">
        <img
          src={nologo}
          className="rounded img-fluid"
          alt="img"
          width="50"
          height="50"
        />
      </div>
    );
  }
};

export const renderStatusInTable = (
  item,
  index,
  updateStatus,
  type,
  id,
  option
) => {
  if (type === "isCompany") {
    return (
      <FormControlLabel
        checked={item.active}
        onChange={(e) => updateStatus(item.id, !item.active, index)}
        control={<Switch color="primary" />}
        label={item.active === true ? local.Active : local.InActive}
        labelPlacement="top"
      />
    );
  } else {
    let entry = getDeafultItem(id, option);
    return (
      <FormControlLabel
        checked={item.active}
        onChange={(e) =>
          entry !== undefined
            ? entry !== null
              ? entry.active === true
                ? updateStatus(item.id, !item.active, index)
                : toaster.notify(local.activiteCompany, {
                    duration: 2000,
                  })
              : null
            : null
        }
        control={<Switch color="primary" />}
        label={item.active === true ? local.Active : local.InActive}
        labelPlacement="top"
      />
    );
  }
};

export const renderFormData = (formObj) => {
  let newItems = Object.entries(formObj);
  let formdata = new FormData();
  newItems.map((entry) => {
    if (
      entry[0] === "logo" ||
      entry[0] === "image" ||
      (entry[0] === "pdf_file" && (entry[1] !== null || entry[1] !== undefined))
    ) {
      if (entry[1][0] instanceof File) {
        formdata.append(entry[0], entry[1][0]);
      }
    } else if (entry[0] === "active") {
      if (entry[1] === true) {
        formdata.append(entry[0], 1);
      } else {
        formdata.append(entry[0], 0);
      }
    } else if (entry[0] === "points") {
      entry[1].map((po, index) => {
        Object.entries(po).map((ob) => {
          formdata.append(
            entry[0] + "[" + [index] + "]" + "[" + [ob[0]] + "]",
            ob[1]
          );
        });
      });
    } else if (Array.isArray(entry[1])) {
      entry[1].map((e, index) => {
        formdata.append(entry[0] + "[" + index + "]", e);
      });
    } else if (entry[1] === null) {
      formdata.append(entry[0], entry[1]);
    } else if (entry[1] === undefined) {
      formdata.append(entry[0], null);
    } else if (typeof entry[1] === "object") {
      Object.entries(entry[1]).map((ob) => {
        if (ob[0] === "image" || entry[0] === "pdf_file") {
          formdata.append(entry[0] + "[" + ob[0] + "]", ob[1][0]);
        } else {
          if (Array.isArray(ob[1])) {
            ob[1].map((e, index) => {
              if (typeof e === "object") {
                Object.entries(e).map((s, ii) => {
                  if (Array.isArray(s[1])) {
                    s[1].map((b, i) => {
                      if (typeof b === "object") {
                        Object.entries(b).map((a) => {
                          formdata.append(
                            entry[0] +
                              "[" +
                              ob[0] +
                              "]" +
                              "[" +
                              index +
                              "]" +
                              "[" +
                              s[0] +
                              "]" +
                              "[" +
                              i +
                              "]" +
                              "[" +
                              a[0] +
                              "]",
                            a[1]
                          );
                        });
                      } else {
                        formdata.append(
                          entry[0] +
                            "[" +
                            ob[0] +
                            "]" +
                            "[" +
                            index +
                            "]" +
                            "[" +
                            s[0] +
                            "]" +
                            "[" +
                            i +
                            "]" +
                            "[" +
                            b[0] +
                            "]",
                          b[1]
                        );
                      }
                    });
                  } else {
                    formdata.append(
                      entry[0] +
                        "[" +
                        ob[0] +
                        "]" +
                        "[" +
                        index +
                        "]" +
                        "[" +
                        s[0] +
                        "]",
                      s[1]
                    );
                  }
                });
              } else {
                formdata.append(
                  entry[0] + "[" + ob[0] + "]" + "[" + index + "]",
                  e
                );
              }
            });
          } else {
            formdata.append(entry[0] + "[" + ob[0] + "]", ob[1]);
          }
        }
      });
    } else {
      formdata.append(entry[0], entry[1]);
    }
  });
  for (var pair of formdata.entries()) {
    console.log(pair[0] + ", " + pair[1]);
  }

  return formdata;
};

export const storeImageFile = (file, prop, general, action, prop2) => {
  if (prop2 === undefined) {
    general([{ prop: prop, value: file }], action);
  } else {
    general(
      [
        { prop: prop, value: file },
        { prop: prop2, value: file },
      ],
      action
    );
  }
};

export const renderPhotoCard = (
  general,
  prop,
  action,
  photo,
  prop2,
  renderInTable
) => {
  if (photo !== undefined) {
    if (photo === null || photo === false) {
      return (
        <center>
          {renderInTable === undefined ? (
            <span className="label_title">{local.Image}</span>
          ) : null}
          {getLanguage() === "en" ? null : <br />}
          <Card className="dropzone_card mt-1">
            <Dropzone
              onDrop={(acceptedFiles) =>
                storeImageFile(
                  acceptedFiles,
                  prop,
                  general,
                  action,
                  prop2,
                  renderInTable
                )
              }
            >
              {({ getRootProps, getInputProps, acceptedFiles }) => (
                <div {...getRootProps()} className="dropzone_div">
                  <input
                    {...getInputProps()}
                    id="my_file"
                    name="my_file"
                    multiple={false}
                    accept="image/*"
                    defaultValue={acceptedFiles}
                  />

                  <Col className="text-center">
                    <div>
                      <img src={logo_img} className="dropzone_logo" alt="img" />
                      <br />
                      <span
                        className="mt-1"
                        style={{ color: "#CECECE", fontSize: "10px" }}
                      >
                        {local.Image}
                      </span>
                    </div>
                  </Col>
                </div>
              )}
            </Dropzone>
          </Card>
        </center>
      );
    } else {
      let image = null;
      if (photo[0] instanceof File) {
        image = URL.createObjectURL(photo[0]);
      } else {
        image = photo;
      }
      return (
        <center>
          <span className="label_title">{local.Logo}</span>
          {" " + " "}
          <a
            style={{ cursor: "pointer" }}
            onClick={() =>
              general(
                [
                  {
                    prop: prop,
                    value: renderInTable === undefined ? null : false,
                  },
                ],
                action
              )
            }
          >
            <span className="label_title">
              <FiXSquare style={{ fontSize: "15px", marginTop: "-1" }} />
            </span>
          </a>
          {getLanguage() === "en" ? null : <br />}
          <Card className="dropzone_card mt-1">
            <a
              style={{ cursor: "pointer" }}
              onClick={() =>
                general(
                  [
                    { prop: "lightbox.image", value: photo },
                    { prop: "lightbox.isOpen", value: true },
                  ],
                  action
                )
              }
            >
              <img
                src={image}
                alt=""
                style={{
                  width: "118px",
                  height: "118px",
                  borderRadius: "10px",
                }}
              />
            </a>
          </Card>
        </center>
      );
    }
  }
};

export const renderPhoneInput = (
  prop,
  value,
  action,
  general,
  title,
  index,
  isCountryCodeEditable = false
) => {
  return (
    <div style={{ direction: "ltr" }}>
      <span className="label_title" style={labelStyle}>
        {title === undefined ? local.Mobile2 : local.Mobile}
      </span>
      <br />

      <PhoneInput
        key={index}
        placeholder={title === undefined ? local.Mobile : local.Mobile2}
        country={"eg"}
        containerClass="phone_container"
        dropdownClass="phone_dropdown"
        inputClass="phone_input"
        buttonClass="phone_btn"
        onlyCountries={["eg", "sa", "ae", "kw", "lb"]}
        localization={getLanguage() === "ar" ? ar : false}
        autoFormat={true}
        countryCodeEditable={isCountryCodeEditable}
        value={value}
        isValid={(value, country) => {
          if (value.length - country.countryCode.length > 10) {
            return false;
          } else return true;
        }}
        onChange={(phone) => {
          if (phone === "20") {
            // phone = "";
          }
          general(
            [
              {
                prop: prop,
                value: phone,
              },
            ],
            action
          );
        }}
      />
    </div>
  );
};

export const renderEnglishNameInput = (prop, value, action, general) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {local.EnglishName}
      </span>

      <Input
        placeholder={local.EnglishName}
        className="inputStyle mt-4"
        value={value}
        onChange={(e) => englishFieldText(e, general, action, prop)}
      />
    </>
  );
};

export const renderArabicNameInput = (prop, value, action, general) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {local.ArabicName}
      </span>

      <Input
        placeholder={local.ArabicName}
        className="inputStyle mt-4"
        value={value}
        onChange={(e) => arabicFieldText(e, general, action, prop)}
      />
    </>
  );
};

export const checkSearch = (value, options, searchEndPoint) => {
  let data = options.filter((op) => op.label === value);
  if (data.length === 0) {
    searchEndPoint(value);
  }
};

export const renderSingleSelect = (
  prop,
  value,
  action,
  general,
  title,
  options,
  isDisabled,
  endpoint,
  dependentProps = []
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>

      <AsyncSelect
        isSearchable={true}
        isDisabled={
          isDisabled === undefined ? false : isDisabled === true ? true : false
        }
        className="mt-4"
        placeholder={title}
        styles={selectStyle3}
        value={getDeafultItem(value, options)}
        key={value}
        isClearable={false}
        onChange={(e) => {
          if (dependentProps.length > 0) {
            const dispatchArr = [];
            for (let i = 0; i < dependentProps.length; i++) {
              dispatchArr.push({ prop: dependentProps[i], value: null });
            }
            general(dispatchArr, action);
          }
          return e !== null
            ? endpoint === undefined
              ? general(
                  [
                    {
                      prop: prop,
                      value: e.value,
                    },
                  ],
                  action
                )
              : renderAnothrSelect(prop, e.value, general, action, endpoint)
            : null;
        }}
        cacheOptions
        defaultOptions={options}
        loadOptions={() => promiseOptions(options)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
    </>
  );
};

export const renderWorkFieldSelect = (
  prop,
  value,
  action,
  general,
  title,
  options,
  depend_id,
  errorMessage,
  endpoint
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>

      <AsyncSelect
        className="mt-4"
        placeholder={title}
        styles={selectStyle3}
        value={
          typeof value === "object" ? value : getDeafultItem(value, options)
        }
        isClearable={false}
        onChange={(e) =>
          e !== null
            ? onChangeWorkField(prop, e, e.points, action, general, endpoint)
            : null
        }
        cacheOptions
        defaultOptions={options}
        loadOptions={() => promiseOptions(options)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        onFocus={() =>
          depend_id === null
            ? toaster.notify(errorMessage, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const onChangeWorkField = (
  prop,
  value,
  points,
  action,
  general,
  endpoint
) => {
  let newPoints = [];

  if (points.length > 0) {
    points = points.map((p, index) => {
      newPoints.push({
        label: p.name,
        work_field_id: p.work_field_id,
        id: p.id,
        value: p.id,
        location: p.location,
        properties: p.properties,
      });
    });
    general(
      [
        {
          prop: "pointsOptions",
          value: newPoints,
        },
      ],
      UPDATE_REQUESTS_PROPS
    );
  } else {
    if (endpoint !== undefined) {
      endpoint(value);
    }
  }
  general(
    [
      {
        prop: prop,
        value: value,
      },

      {
        prop: "polygonItem",
        value: value.location,
      },
    ],
    action
  );
};

export const renderAnothrSelect = (prop, value, general, action, endPoint) => {
  if (value !== null) {
    general(
      [
        {
          prop: prop,
          value: value,
        },
      ],
      action
    );
  }

  if (Array.isArray(endPoint)) {
    endPoint.map((end) => {
      end(value);
    });
  } else {
    endPoint(value);
  }
};

export const renderMultiSelect = (
  prop,
  value,
  action,
  general,
  title,
  options,
  work_field_id_value,
  isPoint
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>
      <AsyncSelect
        className="mt-4"
        placeholder={title}
        styles={selectStyle3}
        isMulti={true}
        value={value}
        isClearable={false}
        onChange={(e) =>
          isPoint === undefined
            ? general(
                [
                  {
                    prop: prop,
                    value: e,
                  },
                ],
                action
              )
            : work_field_id_value !== null
            ? general(
                [
                  {
                    prop: prop,
                    value: e,
                  },
                ],
                action
              )
            : null
        }
        cacheOptions
        defaultOptions={options}
        loadOptions={() => promiseOptions(options)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
    </>
  );
};

export const renderMultiSelectAsyncPaginate = (
  prop,
  value,
  action,
  general,
  title,
  options,
  work_field_id_value,
  isPoint
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>
      <AsyncPaginate
        className="mt-4"
        placeholder={title}
        styles={selectStyle3}
        isMulti={true}
        value={value}
        isClearable={false}
        onChange={(e) =>
          isPoint === undefined
            ? general(
                [
                  {
                    prop: prop,
                    value: e,
                  },
                ],
                action
              )
            : work_field_id_value !== null
            ? general(
                [
                  {
                    prop: prop,
                    value: e,
                  },
                ],
                action
              )
            : null
        }
        cacheOptions
        defaultOptions={options}
        loadOptions={() => promiseOptions(options)}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
      />
    </>
  );
};

export const renderGeneralInput = (
  prop,
  value,
  action,
  general,
  title,
  type,
  maxLength,
  pattern
) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>

      <Input
        type={type !== undefined ? type : "text"}
        placeholder={title}
        className="inputStyle mt-4"
        value={value}
        maxLength={maxLength}
        onChange={(e) => {
          if (type !== undefined) {
            general(
              [
                {
                  prop: prop,
                  value: Number(e.target.value),
                },
              ],
              action
            );
          } else {
            if (pattern) {
              if (!e.target.value || pattern.test(e.target.value)) {
                general(
                  [
                    {
                      prop: prop,
                      value: e.target.value,
                    },
                  ],
                  action
                );
              }
            } else {
              general(
                [
                  {
                    prop: prop,
                    value: e.target.value,
                  },
                ],
                action
              );
            }
          }
        }}
      />
    </>
  );
};

export const getAge = (prop, date, action, general) => {
  // var today = new Date();
  // var birthDate = new Date(date);
  // var age = today.getFullYear() - birthDate.getFullYear();
  // var m = today.getMonth() - birthDate.getMonth();
  // if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
  //   age--;
  // }

  storeDate(prop, date, action, general);
};

export const renderGeneralBirhDay = (prop, value, action, general, title) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>

      <Flatpickr
        className="dateStyle mt-4"
        data-enable-time
        value={value}
        onChange={(date) => {
          storeDate(prop, date, action, general);
        }}
        local={getLanguage() === "en" ? null : Arabic}
        placeholder={title}
        options={{
          enableTime: false,
          altInput: true,
          altFormat: "F j, Y",
          dateFormat: "Y-m-d",
          maxDate: new Date(),
          defaultDate: null,
        }}
      />
    </>
  );
};

export const validateNumber = (value) => {
  let nums = "123456789";

  let item = nums.includes(value);
  if (item === false) {
    toaster.notify(local.PhoneNumberEnglish, {
      duration: 2000,
    });
  } else {
    return true;
  }
};

export const renderStatusSwitch = (general, action, prop, value) => {
  return (
    <>
      <span className="label_title" style={{ display: "block" }}>
        {local.CompanyStatus}
      </span>

      <FormControlLabel
        style={{
          color: "#00beac",
          fontWeight: "bold",
          direction: getLanguage() === "ar" ? "rtl" : "ltr",
          float: getLanguage() === "ar" ? "right" : "left",
        }}
        control={
          <Switch
            checked={value}
            onChange={(e) =>
              general([{ prop: prop, value: e.target.checked }], action)
            }
            name="checkedA"
            style={{
              color: "#00beac",
              fontWeight: "bold",
            }}
          />
        }
        label={value === true ? local.Active : local.InActive}
      />
    </>
  );
};

export const renderPagination = (item, searchForm, endPoint) => {
  if (item !== null) {
    if (item.data !== undefined) {
      if (item.data.length > 0) {
        if (item.pagination !== undefined) {
          if (item.pagination !== null) {
            return (
              <center>
                <PaginationEntry
                  paginationItem={item.pagination}
                  searchForm={searchForm}
                  endPoint={endPoint}
                />
              </center>
            );
          }
        }
      }
    }
  }
};

export const renderPasswordInput = (prop, value, action, general, title) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>

      <Input
        type="password"
        placeholder={title}
        className="inputStyle mt-4"
        value={value}
        onChange={(e) =>
          general(
            [
              {
                prop: prop,
                value: e.target.value,
              },
            ],
            action
          )
        }
      />
    </>
  );
};

export const isValidURL = (string) => {
  var res = string.match(
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

export const renderGeneralArea = (prop, value, action, general, title) => {
  return (
    <>
      <span className="label_title" style={labelStyle}>
        {title}
      </span>

      <Input
        type="textarea"
        placeholder={title}
        rows="8"
        cols="25"
        className="inputStyle mt-4"
        value={value}
        onChange={(e) =>
          general(
            [
              {
                prop: prop,
                value: e.target.value,
              },
            ],
            action
          )
        }
      />
    </>
  );
};

export const renderDateInput = (
  prop,
  value,
  action,
  general,
  title,
  hasTime,
  minimumDate,
  maximumDate,
  noMaxDate,
  mergeAction
) => {
  let options = {
    enableTime: hasTime === undefined ? false : true,
    altInput: true,
    // altFormat: hasTime === undefined ? "F j, Y" : "",
    // dateFormat: hasTime === undefined ? "Y-m-d" : "",
    maxDate: maximumDate ? new Date(maximumDate) : new Date(),
    locale: getLanguage() === "en" ? null : Arabic,
  };
  if (minimumDate) {
    options.minDate = new Date(minimumDate);
  }
  if (!noMaxDate) {
    options.maxDate = new Date(maximumDate);
  }

  const clearBtnStyles = {
    background: "none",
    color: "white",
    fontFamily: "buckra",
    fontSize: "14px",
    border: "none",
    position: "absolute",
    textAlign: "end",
    top: "50%",
    transform: "translateY(-50%)",
  };

  const altPlaceholderStyles = {
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
    color: "#757575",
    pointerEvents: "none",
    fontsize:"14px"
  };

  if (getLanguage() === "en") {
    clearBtnStyles.right = "10px";
  } else {
    clearBtnStyles.left = "10px";
  }

  const clearDate = () => {
    if (value == undefined) {
      storeDate(prop, null, mergeAction, general, hasTime );
    } else {
      storeDate(prop, null, action, general, hasTime );
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Flatpickr
        className="dateStyle"
        data-enable-time
        value={value}
        onChange={(date) => {
          if(value == undefined) {
            storeDate(prop, date, mergeAction, general, hasTime);
          } else {
            storeDate(prop, date, action, general, hasTime);
          }
        }}
        local={getLanguage() === "en" ? null : Arabic}
        placeholder={title}
        default
        options={options}
      />
      {/* {!value && <div style={altPlaceholderStyles}>{title}</div>} */}
      <button type="button" style={{ ...clearBtnStyles }} onClick={clearDate}>
        X
      </button>
    </div>
  );
};

export const isArray = (valueToCheck) => {
  return Array.isArray(valueToCheck);
};

export const HasArabicCharacters = (text) => {
  let arregex = /[\u0600-\u06FF]/;
  if (arregex.test(text) === true) {
    return true;
  } else return false;
};

export const hasEnglishCharacters = (text) => {
  let EnRegex = /[a-zA-Z]/;
  if (EnRegex.test(text) === true) {
    return true;
  } else return false;
};

export const getQueryString = (obect) => {
  let query = "";

  Object.keys(obect).forEach(function (key, index) {
    if (index === 0) {
      query += "?";
    } else {
      query += "&";
    }
    query += key + "=" + obect[key];
  });
  return query;
};

export const getQueryStringEnhanced = (object) => {
  let query = "";

  Object.keys(object).forEach(function (key, index) {
    if (index === 0) {
      query += "?";
    } else {
      query += "&";
    }
    if (isArray(object[key])) {
      query += arrToQryParam(object[key], key);
    } else {
      query += key + "=" + object[key];
    }
  });
  return query;
};

export const arrToQryParam = (arr, paramKey, index = 0, qryStr = "") => {
  if (index === arr.length) {
    return qryStr;
  } else {
    if (index < arr.length - 1) {
      qryStr += `${paramKey}[]=${arr[index]}&`;
    } else {
      qryStr += `${paramKey}[]=${arr[index]}`;
    }
    return arrToQryParam(arr, paramKey, index + 1, qryStr);
  }
};

export const rmUnsetValsAndGetQryStr = (object) => {
  const objectFiltered = removeUnsetValues(object);
  return getQueryStringEnhanced(objectFiltered);
};

export const storeDate = (prop, value, action, general, hasTime) => {
  // if (value.length > 0) {
  //   var newDate = new Date(value[0]);

  //   if (hasTime === undefined) {
  //     const day = newDate.toString().split(" ")[2];
  //     const month = newDate.getMonth() + 1;
  //     const year = newDate.getFullYear();
  //     var filterDate = `${year}-${month}-${day}`;
  //   } else {
  //     var filterDate = newDate.toISOString();
  //   }
  // } else {
  //   var filterDate = "";
  // }

  // general([{ prop: prop, value: filterDate }], action);

  // const filterDate = formatDateForRequest(value);
  let filterDate = null;

  if (!hasTime) {
    filterDate = formatDateForRequest(value);
  } else {
    if (value) {
      filterDate = value[0].toISOString();
    }
  }
  general([{ prop: prop, value: filterDate }], action);
};

export const renderSearchSelcet = (
  title,
  prop,
  value,
  action,
  general,
  options,
  endPoint,
  id,
  errorMessage,
  allItem
  // depenon = 0
) => {
  return (
    <AsyncSelect
      key={value}
      placeholder={title}
      onFocus={() =>
        id === null
          ? toaster.notify(errorMessage, {
              duration: 2000,
            })
          : null
      }
      styles={selectStyle3}
      value={getDeafultItem(value, options)}
      isClearable={true}
      onChange={(e) => onChangeC(prop, e, action, general, endPoint, allItem)}
      defaultOptions={options}
      loadOptions={() => promiseOptions(options)}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
    />
  );
};

export const renderSearchTrackingSelcet = (
  title,
  prop,
  value,
  action,
  general,
  options,
  endPoint,
  id,
  errorMessage,
  allItem
) => {
  return (
    <AsyncSelect
      key={value}
      placeholder={title}
      onFocus={() =>
        id === null
          ? toaster.notify(errorMessage, {
              duration: 2000,
            })
          : null
      }
      styles={selectStyle3}
      value={value}
      isClearable={true}
      onChange={(e) => onChangeC(prop, e, action, general, endPoint, allItem)}
      defaultOptions={options}
      loadOptions={() => promiseOptions(options)}
      getOptionLabel={(option) => option.label}
      getOptionValue={(option) => option.value}
    />
  );
};

export const onChangeC = (prop, e, action, general, endPoint, allItem) => {
  if (e !== null) {
    endPoint !== null
      ? getAnotherSelectSearch(prop, e.value, action, general, endPoint)
      : allItem === undefined
      ? general(
          [
            {
              prop: prop,
              value: e.value,
            },
          ],
          action
        )
      : general(
          [
            {
              prop: prop,
              value: e,
            },
          ],
          action
        );
  } else {
    general(
      [
        {
          prop: prop,
          value: null,
        },
      ],
      action
    );

    general(
      [
        {
          prop: "companyWfOptions",
          value: [],
        },
      ],
      UPDATE_COMPANY_WORK_FIELD_PROPS
    );

    general(
      [
        {
          prop: "workerOptions",
          value: [],
        },
      ],
      UPDATE_WORKER_PROPS
    );

    general(
      [
        {
          prop: "pointsOptions",
          value: [],
        },
      ],
      UPDATE_REQUESTS_PROPS
    );
  }
};

export const getAnotherSelectSearch = (
  prop,
  value,
  action,
  general,
  endPoint
) => {
  if (typeof endPoint === "object") {
    Object.entries(endPoint).map((end) => {
      end[1](value);
    });
  } else {
    endPoint(value);
  }
  general(
    [
      {
        prop: prop,
        value: value,
      },
    ],
    action
  );
};

export const removeUnsetValues = (obj) => {
  for (var propName in obj) {
    if (
      obj[propName] === null ||
      obj[propName] === undefined ||
      obj[propName] === "" ||
      obj[propName] === [] ||
      obj[propName] === {} ||
      obj[propName] === "null"
      //  ||
      // obj[propName].length === 0
      // || Object.keys(obj[propName]).length === 0
    ) {
      delete obj[propName];
    }
  }
  return obj;
};

export const renderPhoneSearch = (prop, value, action, general) => {
  return (
    <PhoneInput
      placeholder={local.Mobile}
      country={"eg"}
      containerClass="phone_container"
      dropdownClass="phone_dropdown"
      inputClass="phone_input"
      buttonClass="phone_btn"
      onlyCountries={["eg", "sa", "ae", "kw", "lb"]}
      localization={getLanguage() === "ar" ? ar : false}
      autoFormat={true}
      countryCodeEditable={false}
      value={value}
      // isValid={(inputNumber, country, countries) => {
      //   return countries.some((country) => {
      //     return (
      //       startsWith(inputNumber, country.dialCode) ||
      //       startsWith(country.dialCode, inputNumber)
      //     );
      //   });
      // }}
      isValid={(value, country) => {
        if (value.length - country.countryCode.length > 10) {
          return false;
        } else return true;
      }}
      onChange={(phone) =>
        general(
          [
            {
              prop: prop,
              value: phone,
            },
          ],
          action
        )
      }
    />
  );
};

export const renderGeneralInputSearch = (
  prop,
  value,
  action,
  general,
  title
) => {
  return (
    <Input
        // className={Style.myInputs}
      placeholder={title}
      className="inputStyle"
      value={value}
      onChange={(e) =>
        general(
          [
            {
              prop: prop,
              value: e.target.value,
            },
          ],
          action
        )
      }
    />
  );
};

export const isPointInPolygon = (latitude, longitude, polygon) => {
  if (typeof latitude !== "number" || typeof longitude !== "number") {
    throw new TypeError("Invalid latitude or longitude. Numbers are expected");
  } else if (!polygon || !Array.isArray(polygon)) {
    throw new TypeError("Invalid polygon. Array with locations expected");
  } else if (polygon.length === 0) {
    throw new TypeError("Invalid polygon. Non-empty Array expected");
  }

  const x = longitude;
  const y = latitude;

  let inside = false;
  for (let i = 0, j = polygon.length - 1; i < polygon.length; j = i++) {
    const xi = polygon[i][0];
    const yi = polygon[i][1];
    const xj = polygon[j][0];
    const yj = polygon[j][1];

    const intersect =
      yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
};

export const renderGooglePolygon = (polygon) => {
  let newPolygons = [];
  let removedBracts = polygon.split("(")[2].split(")")[0].split(",");

  removedBracts.map((poly) => {
    newPolygons.push({
      lat: +poly.split(" ")[1],
      lng: +poly.split(" ")[0],
    });
  });

  return newPolygons;
};

export const prepareQueryString = (url, searchForm, hasId) => {
  if (hasId === null) {
    url += searchForm.company + "/work_fields";
  }

  let searchItems = {
    from: searchForm.from,
    to: searchForm.to,
    mobile: searchForm.mobile,
  };
  let newSearchForm = removeUnsetValues(searchItems);
  let param = null;

  if (
    Object.keys(newSearchForm).length !== 0 &&
    newSearchForm.constructor === Object
  ) {
    param = getQueryString(newSearchForm);
    url += param;
  }

  return url;
};

export const getCenterOfPolygon = (polygon) => {
  let coords = renderGooglePolygon(polygon);
  const arrAvg = (arr) => arr.reduce((a, b) => a + b, 0) / arr.length;
  const centerLat = arrAvg(coords.map((c) => c.lat));
  const centerLon = arrAvg(coords.map((c) => c.lng));

  if (isNaN(centerLat) || isNaN(centerLon)) return null;
  else return { latitude: centerLat, longitude: centerLon };
};

export const confirmSunccessed = (title) => {
  return confirmAlert({
    title: title, // Title dialog
    overlayClassName: "overlay-custom-class-name", // Custom overlay class name

    buttons: [
      {
        label: local.Ok,
      },
    ],
  });
};

export const renderArraw = (history) => {
  return (
    <Col>
      <div
        style={{
          float: getLanguage() === "en" ? "right" : "left",
        }}
      >
        {getLanguage() === "en" ? (
          <>
            <a
              style={{ cursor: "pointer", color: "#00beac" }}
              onClick={() => history.goForward(1)}
            >
              <FaArrowLeft />
            </a>{" "}
            <a
              style={{ cursor: "pointer", color: "#00beac" }}
              onClick={() => history.goBack(-1)}
            >
              <FaArrowRight />
            </a>
          </>
        ) : (
          <>
            <a
              href
              style={{ cursor: "pointer", color: "#00beac" }}
              onClick={() => history.goForward(1)}
            >
              <FaArrowRight />
            </a>{" "}
            <a
              href
              style={{ cursor: "pointer", color: "#00beac" }}
              onClick={() => history.goBack(-1)}
            >
              <FaArrowLeft />
            </a>
          </>
        )}
      </div>
    </Col>
  );
};

export const validateEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const HasEnglishCharactor = (text) => {
  let english = /^[A-Za-z0-9]*$/;
  if (english.test(text) === true) {
    return true;
  } else {
    return false;
  }
};

export const checkLanguageIsEnglish = (value) => {
  if (HasArabicCharacters(value) === true && value !== "") {
    return false;
  } else {
    return true;
  }
};
export const checkLanguageIsArabic = (value) => {
  if (hasEnglishCharacters(value) === true && value !== "") {
    return false;
  } else {
    return true;
  }
};
export const arabicFieldText = (e, general, action, prop) => {
  if (checkLanguageIsArabic(e.target.value) === false) {
    confirmSunccessed(local.ArabicLang);
  } else {
    if (checkLanguageIsArabic(e.target.value) === true && e !== null) {
      general(
        [
          {
            prop: prop,
            value: e.target.value,
          },
        ],
        action
      );
    } else {
      general(
        [
          {
            prop: prop,
            value: "",
          },
        ],
        action
      );
    }
  }
};

export const englishFieldText = (e, general, action, prop) => {
  if (checkLanguageIsEnglish(e.target.value) === false) {
    confirmSunccessed(local.EnglishLang);
  } else {
    if (checkLanguageIsEnglish(e.target.value) === true && e !== null) {
      general(
        [
          {
            prop: prop,
            value: e.target.value,
          },
        ],
        action
      );
    } else {
      general(
        [
          {
            prop: prop,
            value: "",
          },
        ],
        action
      );
    }
  }
};

export const validatenumberIsArabic = (value) => {
  let isArabic = /[\u0600-\u06FF\u0750-\u077F]/;
  if (isArabic.test(value)) {
    alert("sdf");
  }
};

export const getPreparedPermissions = () => {
  let item = JSON.parse(localStorage.getItem(SERVICE_USER));

  if (item !== null) {
    return item.permissions;
  } else {
    return null;
  }
};
export const convertArrayToObject = (array) => {
  if (array !== undefined) {
    if (array !== null) {
      const initialValue = {};
      return array.reduce((obj, item) => {
        return {
          ...obj,
          [item.permission]: item,
        };
      }, initialValue);
    }
  }
};

export const getPermissionValue = () => {
  let item = convertArrayToObject(getPreparedPermissions());
  return item;
};

export const renderEditTitle = (value) => {
  if (getPermissionValue()[value] !== undefined) {
    return renderTableHeadItem(local.Edit);
  }
};

export const renderDeleteTitle = (value) => {
  if (getPermissionValue()[value] !== undefined) {
    return renderTableHeadItem(local.Remove);
  }
};

export const renderStatusTitle = (
  value,
  array,
  activeProp,
  arrayProp,
  action,
  general,
  sorting
) => {
  if (getPermissionValue()[value] !== undefined) {
    return renderTableHeadItem(
      local.Status,
      array,
      activeProp,
      arrayProp,
      action,
      general,
      sorting
    );
  }
};

export const renderStatusButton = (
  value,
  item,
  index,
  updateStatus,
  type,
  id,
  option
) => {
  if (value !== undefined) {
    return (
      <td className="td_table">
        {renderStatusInTable(item, index, updateStatus, type, id, option)}
      </td>
    );
  }
};

export const renderCreateButton = (value, onCreate, title) => {
  if (value !== undefined) {
    return (
      <Row>
        <Col>
          <Button
            className="place_btn mt-4 mb-4"
            style={leftlStyle}
            onClick={(e) => onCreate(e)}
          >
            {title}
          </Button>
        </Col>
      </Row>
    );
  }
};

export const renderEditButton = (value, onEdit, item, index) => {
  if (value !== undefined) {
    return (
      <td className="td_table">
        <a
          style={{ cursor: "pointer" }}
          onClick={(e) => onEdit(e, item, index)}
        >
          <img src={edit_icon} alt="" width="22" height="22" />
        </a>
      </td>
    );
  }
};

export const renderDeleteButton = (
  value,
  onDelete,
  item,
  index,
  setModalsProps
) => {
  if (value !== undefined) {
    return (
      <td className="td_table">
        <a
          style={{ cursor: "pointer" }}
          onClick={
            setModalsProps !== undefined
              ? (e) =>
                  setModalsProps([
                    [
                      "areYouSureModal.action",
                      {
                        method: function () {
                          onDelete(e, item, index);
                        },
                      },
                    ],
                    ["areYouSureModal.isOpen", true],
                  ])
              : (e) => onDelete(e, item, index)
          }
        >
          <FiXSquare style={{ color: "red", fontSize: "20px" }} />
        </a>
      </td>
    );
  }
};

export const checkConnection = (history) => {
  var ifConnected = window.navigator.onLine;
  if (ifConnected) {
  } else {
    history.push("/no_connection");
  }
};

export const renderInDependentSearchSelcet = (
  title,
  prop,
  value,
  action,
  general,
  loadOptions
) => {
  return (
    <AsyncPaginate
      placeholder={title}
      isClearable={true}
      styles={selectStyle3}
      loadOptions={loadOptions}
      onChange={(e) =>
        e !== null
          ? general([{ prop: prop, value: e.value }], action)
          : general([{ prop: prop, value: null }], action)
      }
      getOptionLabel={(op) => op.label}
      getOptionValue={(op) => op.value}
      additional={{
        page: 1,
      }}
    />
  );
};

export const renderFileCard = (
  general,
  prop,
  action,
  file,
  prop2,
  renderInTable
) => {
  if (file !== undefined) {
    if (file === null || file === false) {
      return (
        <center>
          {renderInTable === undefined ? (
            <span className="label_title">{local.PdfFile}</span>
          ) : null}
          {getLanguage() === "en" ? null : <br />}
          <Card className="dropzone_card mt-1">
            <Dropzone
              onDrop={(acceptedFiles) =>
                storeImageFile(
                  acceptedFiles,
                  prop,
                  general,
                  action,
                  prop2,
                  renderInTable
                )
              }
            >
              {({ getRootProps, getInputProps, acceptedFiles }) => (
                <div {...getRootProps()} className="dropzone_div">
                  <input
                    {...getInputProps()}
                    id="my_file"
                    name="my_file"
                    multiple={false}
                    accept=".pdf"
                    defaultValue={acceptedFiles}
                  />

                  <Col className="text-center">
                    <div>
                      <img
                        src={file_img}
                        className="dropzone_logo"
                        alt="imgs"
                      />
                      <br />
                      <span
                        className="mt-1"
                        style={{ color: "#CECECE", fontSize: "10px" }}
                      >
                        {local.PdfFile}
                      </span>
                    </div>
                  </Col>
                </div>
              )}
            </Dropzone>
          </Card>
        </center>
      );
    } else {
      let doc = null;
      if (file[0] instanceof File) {
        doc = URL.createObjectURL(file[0]);
      } else {
        doc = file;
      }
      return (
        <center>
          <span className="label_title">{local.PdfFile}</span>
          {" " + " "}
          <a
            style={{ cursor: "pointer" }}
            onClick={() =>
              general(
                [
                  {
                    prop: prop,
                    value: renderInTable === undefined ? null : false,
                  },
                ],
                action
              )
            }
          >
            <span className="label_title">
              <FiXSquare style={{ fontSize: "15px", marginTop: "-1" }} />
            </span>
          </a>
          {getLanguage() === "en" ? null : <br />}
          <Card className="dropzone_card mt-1">
            <a
              href={doc}
              style={{
                textDecoration: "underline",
                color: "black",
              }}
              target="_blank"
              className="text-center"
              rel="noreferrer"
            >
              <p className="mt-5">{local.Preview}</p>
            </a>
          </Card>
        </center>
      );
    }
  }
};

export const CreatableSelect = (
  e,
  general,
  prop,
  propOptions,
  options,
  action,
  merge_action,
  isModal
) => {
  if (e !== null) {
    if (e.__isNew__ !== undefined) {
      let item = options.find((op) => op.value === e.value);

      if (item === undefined) {
        general([{ prop: propOptions, value: e }], merge_action);
      }
    }
    general([{ prop: prop, value: e.value }], action);
  } else {
    general([{ prop: prop, value: null }], action);
  }
};

export const renderVisitStatus = (visitStatus, action, isFutureVisit) => {
  let btnStyle = {
    padding: "5px",
    // marginLeft: "5px",
    // marginRight: "5px",
    margin: "5px",
    color: "white",
    borderRadius: "10px",
    border: "none",
  };
  let approveBtn = (
    <button
      style={{
        ...btnStyle,
        backgroundColor: "green",
        opacity: visitStatus === true ? 1 : 0.7,
      }}
      onClick={() => action(true)}
    >
      {local.Approve}
    </button>
  );

  let rejectBtn = (
    <button
      style={{
        ...btnStyle,
        backgroundColor: "red",
        opacity: visitStatus === false ? 1 : 0.7,
      }}
      onClick={() => action(false)}
    >
      {local.Reject}
    </button>
  );
  if (visitStatus === null) {
    return (
      <div style={{ textAlign: "center" }}>
        {/* {approveBtn}
        {rejectBtn} */}
        {isFutureVisit ? [approveBtn, rejectBtn] : local.NoDecisionHasBeenTaken}
      </div>
    );
  } else {
    if (visitStatus === false) {
      return (
        <div
          style={{
            color: "red",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div style={{ marginBottom: "10px" }}>{local.Rejected}</div>
          {isFutureVisit && approveBtn}
        </div>
      );
    } else {
      return (
        <div
          style={{
            color: "green",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
          }}
        >
          <div style={{ marginBottom: "10px" }}>{local.Approved}</div>
          {isFutureVisit && rejectBtn}
        </div>
      );
    }
  }
};

export const onOpenProfile = (
  item,
  title,
  general,
  action,
  hasPermission,
  permission,
  { getGiftsReportData = undefined, getAmounts = undefined } = {}
) => {
  if (getGiftsReportData) {
    let searchForm = {
      workFields: [{ value: item.point.work_field_id }],
      workFieldsPoints: item.point.id,
      date: {
        // from: item.created_at,
        from: formatDateForRequest([new Date(item.created_at)]),
        // to: item.created_at,
        to: formatDateForRequest([addDaysToDate(new Date(item.created_at), 1)]),
      },
    };
    getGiftsReportData(searchForm, 1);
  }

  if (getAmounts) {
    if (item.point.place_type === 4) {
      const searchForm = {
        ids: [{ value: item.point.work_field_id }],
        pointID: item.point.id,
        workerID: item.worker.id,
        from: moment(item.created_at).startOf("day").toISOString(),
        to: moment(item.created_at).endOf("day").toISOString(),
      };
      getAmounts(false, searchForm);
    }
  }

  if (hasPermission === false) {
    general(
      [
        {
          prop: "modaleView",
          value: {
            isOpen: true,
            data: item,
            title: title,
          },
        },
      ],
      action
    );
  } else {
    if (getPermissionValue()[permission] !== undefined) {
      general(
        [
          {
            prop: "modaleView",
            value: {
              isOpen: true,
              data: item,
              title: title,
            },
          },
        ],
        action
      );
    }
  }
};

export const renderWorkType = (type) => {
  if (type === 1 || type === "1") {
    return local.Marketing;
  } else {
    return local.Sales;
  }
};

export const validatePhoneLength = (phoneNumber) => {
  if (phoneNumber.startsWith("20") && phoneNumber.length !== 12) {
    return false;
  } else {
    return true;
  }
};

export const renderProfileRow = (
  firstTitle,
  firstValue,
  firstImg,
  secondTitle,
  secondValue,
  secondImg,
  leftSide
) => {
  return (
    <Row className="text-center">
      <Col xl={6} className="text-center mt-4">
        <Row style={{ alignItems: "center" }}>
          <Col>
            <Row style={{ alignItems: "center" }}>
              <Col>
                <Card
                  className="icon_profile_card"
                  style={{
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <img
                    className="icon_profile_img"
                    src={firstImg}
                    alt="user"
                    style={{
                      width: "20px",
                      marginBlock: "8px",
                    }}
                  />
                </Card>
              </Col>
              <Col>
                <span className="green_text title_profile">{firstTitle}</span>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row style={{ alignItems: "center" }}>
              <span
                className="blue_text title_profile"
                style={{ marginInline: "5px" }}
              >
                {firstValue}
              </span>
            </Row>
          </Col>
        </Row>
      </Col>
      {leftSide === undefined ? (
        <Col xl={6} className="text-center mt-4">
          <Row style={{ alignItems: "center" }}>
            <Col>
              <Row style={{ alignItems: "center" }}>
                <Col>
                  <Card
                    className="icon_profile_card"
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <img
                      className="icon_profile_img"
                      src={secondImg}
                      alt="user"
                      style={{
                        width: "20px",
                        marginBlock: "8px",
                      }}
                    />
                  </Card>
                </Col>
                <Col>
                  <span
                    className="green_text title_profile"
                    style={{ marginInline: "5px" }}
                  >
                    {secondTitle}
                  </span>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row style={{ alignItems: "center" }}>
                <span
                  className="blue_text title_profile"
                  style={{ marginInline: "5px" }}
                >
                  {secondValue}
                </span>
              </Row>
            </Col>
          </Row>
        </Col>
      ) : null}
    </Row>
  );
};

export const renderConvertedDate = (date) => {
  if (getLanguage() === "en") {
    new Date(date).toLocaleString();
  } else {
    new Date(date).toLocaleString("ar");
  }
};

export const formatResponseDate = (date) => {
  if (date) {
    let dateArr = date.split("-").reverse();
    dateArr[0] = dateArr[0].substring(0, 2);
    let formattedDate = dateArr.join(" / ");

    return formattedDate;
  }
};

export const getAllWorkFields = (branch_id, action, prop) => {
  try {
    axios
      .get(`${config.api_url}/companies/${branch_id}/work_fields/all`, {
        headers: {
          Authorization: "Bearer " + getToken(),
          contentType: "application/json",
        },
      })
      .then((response) => {
        action([[prop, response.data.data]]);
        console.log(response.data.data);
      });
  } catch (err) {
    console.log(err);
  }
};

export const isTodayOrFutureDate = (dateToCheck) => {
  if (dateToCheck) {
    let dateToCheckArray = dateToCheck.split("-");
    let dateToCheckArr = [
      dateToCheckArray[2],
      dateToCheckArray[1],
      dateToCheckArray[0],
    ];
    const currentDate = new Date();
    // const currentDay = currentDate.getDay() + 1;
    const currentDay = currentDate.toString().split(" ")[2];
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    const todayDateArr = [currentDay, currentMonth, currentYear];

    if (Number(dateToCheckArr[2]) > Number(todayDateArr[2])) {
      return true;
    } else if (Number(dateToCheckArr[2]) === Number(todayDateArr[2])) {
      if (Number(dateToCheckArr[1]) > Number(todayDateArr[1])) {
        return true;
      } else if (Number(dateToCheckArr[1]) === Number(todayDateArr[1])) {
        if (Number(dateToCheckArr[0]) >= Number(todayDateArr[0])) {
          return true;
        } else return false;
      }
    } else return false;
  }
};

export const geoJsonToWKT = (geoJson) => {
  let WKT = "POLYGON((";

  if (geoJson !== undefined) {
    const isLatLngArr = isArray(geoJson[0]);
    geoJson.map((poly, index) => {
      // const isLatLngArr = isArray(poly);
      if (index === 0) {
        // WKT = WKT + poly.lng + " " + poly.lat;
        WKT = `${WKT}${isLatLngArr ? poly[0] : poly.lng} ${
          isLatLngArr ? poly[1] : poly.lat
        }`;
      } else {
        // WKT = WKT + "," + poly.lng + " " + poly.lat;
        WKT = `${WKT},${isLatLngArr ? poly[0] : poly.lng} ${
          isLatLngArr ? poly[1] : poly.lat
        }`;
      }
    });
    // WKT = WKT + "," + geoJson[0].lng + " " + geoJson[0].lat;
    WKT = `${WKT},${isLatLngArr ? geoJson[0][0] : geoJson[0].lng} ${
      isLatLngArr ? geoJson[0][1] : geoJson[0].lat
    }`;
    WKT = WKT + "))";
    return WKT;
  }
};

export const setterGenerator = (actionType, propsArr) => {
  return (dispatch) => {
    let payload = [];
    for (let i = 0; i < propsArr.length; i++) {
      payload.push({
        prop: propsArr[i][0],
        value: propsArr[i][1],
      });
    }

    dispatch({
      type: actionType,
      payload,
    });
  };
};

export const destroyerGenerator = (actionType, propsArr) => {
  return (dispatch) => {
    let payload = [];
    for (let i = 0; i < propsArr.length; i++) {
      payload.push({
        prop: propsArr[i],
      });
    }

    dispatch({
      type: actionType,
      payload,
    });
  };
};

export const formatDateForRequest = (date) => {
  let filteredDate = "";
  if (date) {
    var newDate;
    if (isArray(date)) {
      newDate = new Date(date[0]);
    } else {
      newDate = new Date(date);
    }

    const day = newDate.toString().split(" ")[2];
    const month = newDate.getMonth() + 1;
    const monthIsOneCharcter = month < 10;
    const year = newDate.getFullYear();
    filteredDate = `${year}-${monthIsOneCharcter ? `0${month}` : month}-${day}`;
  } else {
    filteredDate = "";
  }

  return filteredDate;
};

export const addDaysToDate = (date, days) => {
  return new Date(date.setDate(date.getDate() + days));
};

export const removeDaysFromDate = (date, days) => {
  return new Date(date.setDate(date.getDate() - days));
};

export const validateForm = (formArray) => {
  for (let i = 0; i < formArray.length; i++) {
    if (
      formArray[i].value === undefined ||
      formArray[i].value === null ||
      formArray[i].value === ""
    ) {
      toaster.notify(`${formArray[i].label} is required`);
      return false;
    }
  }

  return true;
};

export const isObject = (val) => {
  if (val === null) {
    return false;
  } else if (isArray(val)) {
    return false;
  } else if (typeof val !== "object") {
    return false;
  } else return true;
};

export const hasMorePages = (currentPage, totalPages) => {
  if (currentPage < totalPages) {
    return true;
  } else return false;
};

export const getAsyncSelectOptions = (
  response,
  labelProp,
  valueProp,
  isMultiLangs = false
) => {
  let payload, hasMore;
  let options = [];

  payload = response.data.data;
  if (isMultiLangs) {
    options = payload.data.map((item) => {
      return {
        ...item,
        // label: getLanguage() === "en" ? item[labelProp].en : item[labelProp].ar,
        // value: item[valueProp],
        label:
          getLanguage() === "en"
            ? dotProp.get(item, `${labelProp}.en`)
            : dotProp.get(item, `${labelProp}.ar`),
        value: dotProp.get(item, valueProp),
      };
    });
  } else {
    options = payload.data.map((item) => {
      return {
        ...item,
        // label: item[labelProp],
        // value: item[valueProp],
        label: dotProp.get(item, labelProp),
        value: dotProp.get(item, valueProp),
      };
    });
  }

  const pagination = payload.pagination;
  hasMore = hasMorePages(pagination.current_page, pagination.total_pages);

  return {
    options,
    hasMore,
  };
};

export const getOptionsGenerator =
  (
    url,
    prop,
    action,
    labelProp = "name",
    queryObject = {},
    isMultiLangs = true,
    searchParam= 'name'
  ) =>
  async (searchValue, loadedOptions, additional) => {
    let hasMore;
    let options = [];

    const searchForm = {
      [searchParam]: searchValue,
      page: additional.page,
      ...queryObject,
    };

    try {
      await getRequest(url, searchForm).then((response) => {
        const optionsObject = getAsyncSelectOptions(
          response,
          labelProp || "name",
          "id",
          isMultiLangs
        );
        options = optionsObject.options;
        hasMore = optionsObject.hasMore;
        return optionsObject;
      });
    } catch (err) {
      console.log(err);
    }

    // additional.dispatch(action([[prop, [...loadedOptions, ...options]]]));

    return {
      options: options,
      hasMore: hasMore,
      additional: {
        page: hasMore ? additional.page + 1 : additional.page,
        dispatch: additional.dispatch,
      },
    };
  };

export const getCompanyOptions = getOptionsGenerator(
  "companies",
  "companyOptions",
  setCompanyProps
);

export const getBranchOptions = (companyID, queryObject) => {
  return getOptionsGenerator(
    `companies/branches/${companyID}`,
    "branchOptions",
    setBranchProps,
    undefined,
    queryObject
  );
};

export const getWorkFieldOptions = (branchID) => {
  return getOptionsGenerator(
    `companies/${branchID}/work_fields`,
    "companyWfOptions",
    setCompanyWorkFieldProps
  );
};

export const getWorkFieldpointsOptions = (workFieldIDs, queryObject = {}) => {
  return getOptionsGenerator(
    "companies/work_fields/points",
    "pointsOptions",
    setRequestsProps,
    "name",
    { ids: workFieldIDs, ...queryObject },
    false
  );
};

export const getEmployeeOptions = (companyID, work_field_id, active = true) => {
  return getOptionsGenerator(
    `workers/${companyID}`,
    "workerOptions",
    setWorkerProps,
    "name",
    { work_field_id: work_field_id, active: active },
    false
  );
};

export const getMerchantOptions = (queryObject = {}) => {
  const userData = localStorage.getItem("SERVICE_USER")
  const workFieldIds = JSON.parse(userData)?.work_fields.map(workField=> workField.id)
  return getOptionsGenerator(
    "companies/work_fields/points",
    "merchantsOptions",
    setMerchantProps,
    "name",
    { ids: workFieldIds, ...queryObject },
    false,
    "merchant_id"
  );
};

export const cycleBetweenArrayValues = (arrayToCycleOver, index) => {
  const arrayLength = arrayToCycleOver.length;
  const remainder = index % arrayLength;
  return remainder;
};

export const WKTtoGeoJson = (wkt) => {
  const arrayOfPoints = wkt.split("(")[2].split(",");
  let geoJson = [];
  for (let i = 0; i < arrayOfPoints.length; i++) {
    geoJson.push(arrayOfPoints[i].split(" "));
  }
  geoJson[geoJson.length - 1][1] = geoJson[geoJson.length - 1][1].split(")")[0];
  for (let i = 0; i < geoJson.length; i++) {
    for (let j = 0; j < geoJson[i].length; j++) {
      geoJson[i][j] = +geoJson[i][j];
    }
  }
  return geoJson;
};

export const WKTPointToGeoJson = (WKTPoint) => {
  const lat = WKTPoint.split("(")[1].split(")")[0].split(" ")[0];
  const lng = WKTPoint.split("(")[1].split(")")[0].split(" ")[1];
  const geoJson = [+lng, +lat];
  return geoJson;
};

export const swap = (arr, firstIndex, secondIndex) => {
  [arr[firstIndex], arr[secondIndex]] = [arr[secondIndex], arr[firstIndex]];
};

export const reverseLatLngBoundsOrder = (latLngBounds) => {
  const boundsSchema = "Array of Arrays";
  const boundsSchemaErr = `expected latLngBounds to be ${boundsSchema} but got ${latLngBounds}`;
  if (!latLngBounds) throw boundsSchemaErr;
  if (!isArray(latLngBounds)) throw boundsSchemaErr;
  const latLngBoundsCopy = JSON.parse(JSON.stringify(latLngBounds));
  for (let i = 0; i < latLngBoundsCopy.length; i++) {
    const currentArr = latLngBoundsCopy[i];
    swap(currentArr, 0, 1);
  }
  return latLngBoundsCopy;
};

export const handleSelectChange = (
  e,
  prop,
  action,
  dependentProp = [],
  isMulti = false
) => {
  if (dependentProp.length > 0) {
    let convertDependentPropToDispatch = [];
    for (let i = 0; i < dependentProp.length; i++) {
      convertDependentPropToDispatch.push([dependentProp[i], null]);
    }

    if (e) {
      action([
        [prop, isMulti ? e : e.value],
        ...convertDependentPropToDispatch,
      ]);
    } else action([[prop, null], ...convertDependentPropToDispatch]);
  } else {
    if (e) {
      action([[prop, isMulti ? e : e.value]]);
    } else action([[prop, null]]);
  }
};

export const handleSelectChangeEnhanced = (
  e,
  prop,
  action,
  dependentProp = [],
  callBackFunc
) => {
  if (dependentProp.length > 0) {
    let convertDependentPropToDispatch = [];
    for (let i = 0; i < dependentProp.length; i++) {
      convertDependentPropToDispatch.push([dependentProp[i], null]);
    }

    if (e) {
      action([[prop, e], ...convertDependentPropToDispatch]);
    } else action([[prop, null], ...convertDependentPropToDispatch]);
  } else {
    if (e) {
      action([[prop, e]]);
    } else action([[prop, null]]);
  }

  if (callBackFunc) callBackFunc();
};

export const antdSorter = (a, b, sortOrder, property) => {
  if (a[property] > b[property]) {
    return 1;
  } else if (b[property] > a[property]) {
    return -1;
  } else return 0;
};

export const renderAntdColumn = (
  title,
  prop,
  renderCell,
  sort = true,
  width = null
) => {
  return {
    title,
    dataIndex: prop,
    key: prop,
    width,
    render: renderCell
      ? (data, item, index) => {
          return renderCell(data, item, index);
        }
      : null,
    sorter: sort
      ? (a, b, sortOrder) => antdSorter(a, b, sortOrder, prop)
      : null,
  };
};

export const renderAntdColumnGroup = (parentTitle, children) => {
  const childrenArr = [];
  for (let i = 0; i < children.length; i++) {
    const currentChild = children[i];
    const { title, prop, renderCell = null, sort = true } = currentChild;
    childrenArr.push(
      renderAntdColumn(
        title,
        prop,
        renderCell,
        // children[i].sort !== undefined ? children[i].sort : true
        sort
      )
    );
  }
  return {
    title: parentTitle,
    children: childrenArr,
  };
};

export const handleError = (errorMessage) => {
  toaster.notify(errorMessage, {
    duration: 2000,
  });
};

export const getBegOrEndOfDate = (date, begOrEnd = "beg") => {
  if (begOrEnd !== "beg" && begOrEnd !== "end") {
    throw `begOrEnd must has a value of 'beg' or 'end' but it currently has a value of ${begOrEnd}!`;
  }
  let time;
  if (begOrEnd === "beg") {
    time = "T00:00:00";
  } else {
    time = "T23:59:59";
  }

  const newDate = `${date}${time}`;
  return newDate;
};

export const getMyCompany = (general) => {
  if (getUser()) {
    let item = getUser();
    if (item.companies.length === 1) {
      if (general) {
        general(
          [
            {
              prop: "companyOptions",
              value: [
                {
                  ...item.companies[0],
                  value: item.companies[0].id,
                  label: item.name,
                },
              ],
            },
          ],
          UPDATE_COMPANY_PROPS
        );
      }
      return item.companies[0].id;
    } else {
      return null;
    }
  } else {
    return null;
  }
};

export const checkHasOneCompany = () => {
  if (getUser()) {
    let item = getUser();
    if (item.companies.length === 1) {
      return true;
    } else {
      return false;
    }
  } else {
    return false;
  }
};

export const getMonthDaysArray = (year, month) => {
  // set current year & current month as default values
  // if year or month isn't set
  if (!year) year = new Date().getFullYear();
  if (!month) month = new Date().getMonth() + 1;
  const monthIndex = month - 1; // 0..11 instead of 1..12
  const names = Object.freeze([
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ]);
  const date = new Date(year, monthIndex, 1);
  const result = [];
  while (date.getMonth() === monthIndex) {
    // result.push(date.getDate() + '-' + names[date.getDay()]);
    result.push({
      date: new Date(date),
      day: date.getDate(),
      weekDay: names[date.getDay()],
    });
    date.setDate(date.getDate() + 1);
  }
  return result;
};

export const indexArrOfObjects = (arr = [], indexKey = "id") => {
  const indexedObj = {};
  for (let i = 0; i < arr.length; i++) {
    const currentObj = arr[i];
    indexedObj[currentObj[indexKey]] = currentObj;
  }

  return indexedObj;
};

export const workFieldPermissionedForDropDown = ({ option, rawInput }) => {
  const locaData = JSON.parse(localStorage.getItem("SERVICE_USER"));
  const allWorkFieldPermission = locaData.allWorkFieldAccess;
  const workFieldHavePermission = locaData.work_fields;

  if (allWorkFieldPermission) {
    return true;
  } else {
    return workFieldHavePermission.some((wf) => wf.id === option.value);
  }
};
