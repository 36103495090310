import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import Header from "../../../routing/Layout/Header";
import { getUser } from "../../../utils/GeneralFunctions";
import { getLanguage } from "../../../lang/local";
import UsageHeader from "../UsageHeader";
const Sidebar = React.lazy(() => import("../../../routing/Layout/Sidebar"));

class UsageVideo extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    const { history, match } = this.props;
    console.log(match);
    return (
      <div className="conatiner " style={{ direction: "ltr" }}>
        {getUser() !== null ? (
          <div>
            <Header history={history} />
          </div>
        ) : null}

        <Row
          className={getUser() === null ? "ml-3 mr-3 mb-3 mt-3" : ""}
          style={{
            flexDirection: getLanguage() === "en" ? "row" : "row-reverse",
          }}
        >
          <div className="mt-4">
            <Sidebar />
          </div>

          <Col className="mt-4 body-container">
            <UsageHeader {...this.props} />

            <div id="usage-body-container" className="row mx-3 mt-3 px-5 py-5">
              <div
                style={{ direction: getLanguage() === "ar" ? "rtl" : "ltr" }}
                className="row col-12"
              >
                <div className="col-12 col-lg-4 d-flex align-items-center">
                  <ul
                    id={getLanguage() === "ar" ? "arabic-ul" : "english-ul"}
                    className="my-0"
                  >
                    <li>sdf</li>
                    <li>sdf</li>
                    <li>sdf</li>
                  </ul>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default UsageVideo;
