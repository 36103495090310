import React, { useState } from "react";
import { Card, Col, Row, Form, Button, Input } from "reactstrap";
import { leftlStyle } from "../../utils/GeneralStyle";
import { getLanguage, local } from "../../lang/local";
import { renderArraw } from "../../utils/GeneralFunctions";
import { UPDATE_LIVE_TRACKING_PROPS } from "../../redux/actions/types";
import toaster from "toasted-notes";
import { numberToArabic } from "number-to-arabic";

const OuterWorkerSearch = (props) => {
  const { trackingObj, general, history } = props;
  const [name, setName] = useState("");
  const [number_of_out, setNumberOFOut] = useState(0);

  const onClear = (e) => {
    e.preventDefault();
    if (trackingObj.outerWorkers !== null) {
      let result = 0;

      trackingObj.outerWorkers.map((work) => {
        if (work.isFound !== undefined) {
          delete work.isFound;
          result = 1;
        }

        if (result === 1) {
          general(
            [{ prop: "trackingObj.isOuterWorkerSearch", value: false }],
            UPDATE_LIVE_TRACKING_PROPS
          );
          general(
            [
              {
                prop: "trackingObj.outerWorkers",
                value: trackingObj.outerWorkers,
              },
            ],
            UPDATE_LIVE_TRACKING_PROPS
          );
          setName("");
          setNumberOFOut(0);
        }
      });
    }
  };
  const onSearch = (e) => {
    e.preventDefault();

    let result = 0;

    if (name !== "") {
      if (trackingObj.outerWorkers === null) {
        toaster.notify(local.NoWorkerOutside, {
          duration: 2000,
        });
      } else {
        trackingObj.outerWorkers.map((worker, index) => {
          if (worker.name === name) {
            setNumberOFOut(number_of_out + 1);
            general(
              [
                {
                  prop: "trackingObj.outerWorkers." + index + ".isFound",
                  value: true,
                },
              ],
              UPDATE_LIVE_TRACKING_PROPS
            );
            result = 1;
          }
        });

        if (result === 0) {
          toaster.notify(local.NoSearch, {
            duration: 2000,
          });
        } else {
          general(
            [{ prop: "trackingObj.isOuterWorkerSearch", value: true }],
            UPDATE_LIVE_TRACKING_PROPS
          );
        }
      }
    } else {
      toaster.notify(local.NameRequired, {
        duration: 2000,
      });
    }
  };
  return (
    <Card className="cardStyle mt-1">
      <Form onSubmit={onSearch}>
        <Row className="mt-5 ml-5 mr-5">
          <Col>
            <p className="page_title" style={leftlStyle}>
              {local.Outside}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>

        <Row className="mt-4 mb-4 ml-5 mr-5">
          <Col xl={6}>
            <Input
              placeholder={local.worker_name}
              className="inputStyle"
              onChange={(e) => setName(e.target.value)}
              value={name}
            />
          </Col>

          <Col xl={2}>
            <Button
              type="submit"
              style={leftlStyle}
              className="btn_search_style"
              onClick={(e) => onSearch(e)}
            >
              {local.Search}
            </Button>
          </Col>
          <Col xl={2}>
            <Button
              type="submit"
              style={leftlStyle}
              className="btn_search_style"
              onClick={(e) => onClear(e)}
            >
              {local.Clear}
            </Button>
          </Col>
        </Row>
        <Row className="mt-5">
          <Col className="text-center">
            <p>
              {trackingObj.isOuterWorkerSearch === true &&
              trackingObj.outerWorkers !== null
                ? getLanguage() === "en"
                  ? local.WorkerTimesOut +
                    " : " +
                    trackingObj.outerWorkers.filter(
                      (w) => w.isFound !== undefined
                    ).length
                  : local.WorkerTimesOut +
                    " : " +
                    numberToArabic(
                      trackingObj.outerWorkers.filter(
                        (w) => w.isFound !== undefined
                      ).length
                    )
                : null}
            </p>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default OuterWorkerSearch;
