import { Card } from 'reactstrap';

const CardItem = ({ count, title }) => {
  return (
    <Card className='card-wrapper center'>
      <div className='card-container center'>
        <div className='text-center'>
          <p className='count-number'>
            1223
            <p className='title'>Request sefew s</p>
          </p>
        </div>
      </div>
    </Card>
  );
};

export default CardItem;
