import React from "react";
import { Card, Col, Form, Row, Button, Spinner } from "reactstrap";
import {
  renderDateInput,
  renderArraw,
  renderGeneralInputSearch,
  checkHasOneCompany,
  getBranchOptions,
  handleSelectChangeEnhanced,
} from "../../utils/GeneralFunctions";
import { leftlStyle, labelStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import toaster from "toasted-notes";
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from "../../redux/actions/types";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import GenericInputComponent from "../../components/GenericInputComponent/GenericInputComponent";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
const CompainesWorkFieldsSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    getCompainesWF,
    general,
    companyOptions,
    history,
    loadCompaniesOptions,
    setCompanyWorkFieldProps,
    loadBranchesOptions,
    branchOptions,
    companyWfForm,
  } = props;

  const onSearch = (e) => {
    searchForm.page = 1;
    e.preventDefault();
    if (searchForm.branch_id === null) {
      toaster.notify(local.selectCity, {
        duration: 2000,
      });
    } else {
      getCompainesWF(true, searchForm);
    }
  };
  return (
    <Card className="cardStyle cardSearch mt-1">
      <Form onSubmit={onSearch} className="mt-4 mb-4">
        <Row>
          <Col>
            <p className="page_title" style={leftlStyle}>
              {local.CompaniesWorkField}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.id",
              searchForm.id,
              UPDATE_COMPANY_WORK_FIELD_PROPS,
              general,
              local.Id
            )}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.workFieldName",
              searchForm.workFieldName,
              UPDATE_COMPANY_WORK_FIELD_PROPS,
              general,
              local.Name
            )}
          </Col>

          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
              {renderCompanySelect(
                "searchForm.company",
                searchForm.company,
                UPDATE_COMPANY_WORK_FIELD_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: "searchForm.branch_id", v: null },
                  { p: "searchForm.parent_branch", v: null },
                ],
                null
              )}
            </Col>
          ) : null}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <CustomAsyncPaginate
              placeholder={local.Regions}
              fullWidth
              loadOptions={getBranchOptions(searchForm.company, {
                parent_id: 0,
              })}
              cacheUniqsArr={[searchForm.company]}
              value={searchForm.parent_branch}
              // cacheUniqsArr={[companyWfForm.company]}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  "searchForm.parent_branch",
                  setCompanyWorkFieldProps,
                  ["searchForm.branch_id"]
                )
              }
              additional={{
                page: 1,
              }}
              isDisabled={!!!searchForm.company}
            />
            {/* <div>
              {renderBranchSelect(
                "companyWfForm.parent_branch",
                companyWfForm.parent_branch,
                UPDATE_COMPANY_WORK_FIELD_PROPS,
                general,
                local.Regions,
                loadBranchesOptions,
                branchOptions,
                searchForm.company,
                null,
                [{ p: "searchForm.branch_id", v: null }],
                null,
                undefined,
                undefined,
                undefined,
                0
              )}
            </div> */}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <CustomAsyncPaginate
              fullWidth
              cacheUniqsArr={[searchForm.parent_branch]}
              placeholder={local.Cities}
              isDisabled={!!!searchForm.parent_branch}
              loadOptions={getBranchOptions(searchForm.company, {
                parent_id: searchForm.parent_branch?.id,
              })}
              value={searchForm.branch_id}
              // cacheUniqsArr={[companyWfForm.company]}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  "searchForm.branch_id",
                  setCompanyWorkFieldProps
                )
              }
              additional={{
                page: 1,
              }}
            />
            {/* {renderBranchSelect(
                "searchForm.branch_id",
                searchForm.branch_id,
                UPDATE_COMPANY_WORK_FIELD_PROPS,
                general,
                local.Cities,
                loadBranchesOptions,
                branchOptions,
                searchForm.company,
                null,
                [{ p: "searchForm.branch_id", v: null }],
                null,
                undefined,
                undefined,
                undefined,
                companyWfForm?.parent_branch
              )} */}
          </Col>
          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderBranchSelect(
              'searchForm.branch_id',
              searchForm.branch_id,
              UPDATE_COMPANY_WORK_FIELD_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              null,
              null
            )}
          </Col> */}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderDateInput(
              "searchForm.from",
              searchForm.from,
              UPDATE_COMPANY_WORK_FIELD_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderDateInput(
              "searchForm.to",
              searchForm.to,
              UPDATE_COMPANY_WORK_FIELD_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Button
              type="submit"
              style={leftlStyle}
              disabled={isLoadingSearch}
              className="btn_search_style"
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type="grow" color="dark" size="sm" />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default CompainesWorkFieldsSearch;
