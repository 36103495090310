import { Doughnut } from 'react-chartjs-2';
import "./DoughnutChartLabeled.css";

// var originalDoughnutDraw = Chart.controllers.doughnut.prototype.draw;
// Chart.helpers.extend(Chart.controllers.doughnut.prototype, {
//   draw: function() {
//     originalDoughnutDraw.apply(this, arguments);
    
//     var chart = this.chart.chart;
//     var ctx = chart.ctx;
//     var width = chart.width;
//     var height = chart.height;

//     var fontSize = (height / 114).toFixed(2);
//     ctx.font = fontSize + "em Verdana";
//     ctx.textBaseline = "middle";

//     var text = chart.config.data.text,
//         textX = Math.round((width - ctx.measureText(text).width) / 2),
//         textY = height / 2.8;

//     ctx.fillText(text, textX, textY);
//   }
// });

const DoughnutChartLabeled = ({ doughnutLabels, doughnutData, colors }) => {
  const backgroundColor = colors;

  const borderColor = colors;

  const renderBackgroundColor = () => {
    let backgroundColorArr = [];
    for (let i = 0; i < doughnutData.length; i++) {
      backgroundColorArr.push(backgroundColor[i]);
    }
    if (backgroundColorArr.length > 0) {
      return backgroundColorArr;
    }
  }

  const renderBorderColor = () => {
    let borderColorArr = [];
    for (let i = 0; i < doughnutData.length; i++) {
      borderColorArr.push(borderColor[i]);
    }
    if (borderColorArr.length > 0) {
      return borderColorArr;
    }
  }

  const calculateTotalData = () => {
    let total = 0
    for (let i = 0; i < doughnutData.length; i++) {
      total += doughnutData[i];
    }
    return total.toString();
  }

  return (
    <div style={{"width": "10rem", "height": "8rem"}}>
      <div className="chart-labeled-text-centered">{calculateTotalData() ? calculateTotalData() : ""}</div>
      <Doughnut
        data={
          {
            labels: doughnutLabels ,
            datasets: [{
                label: '# of Votes',
                data: doughnutData,
                backgroundColor: colors,
                borderWidth: 1,
                pointRadius: 8,
                pointStyle: 'star',
                rotation: 5
            }],
            text: calculateTotalData()
        }
        }
        options={{
          maintainAspectRatio: false,
          responsive: true,
          cutoutPercentage: 80,
          legend: {
            position: 'bottom',
            labels: {
              boxWidth: 5,
              fontSize: 9,
              usePointStyle: true
            }
          }
        }}
      />
    </div>
  )
}

export default DoughnutChartLabeled;