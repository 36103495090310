import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Mapy } from 'mapy_sdk';
import { Card, Col, Row } from 'reactstrap';
import * as actions from '../../redux/actions/index';
import {  getMyCompany } from '../../utils/GeneralFunctions';
import HistorySearch from './HistorySearch';
import {
  UPDATE_HISTORIES_PROPS,
  CLEAR_HISTORIES_STATE,
} from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
// import HistoryMap from "./HistoryMap";
import HistoryMapy from './HistoryMapy';

let map = new Mapy();

class Histories extends Component {
  componentDidMount() {
    this.renderHistories();
  }

  componentWillUnmount() {
    this.props.general([], CLEAR_HISTORIES_STATE);
  }

  renderHistories = () => {
    const { general,  } = this.props;
    general([], CLEAR_HISTORIES_STATE);
    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_HISTORIES_PROPS
    );
  };

  render() {
    const {
      history,
      general,
      historyObj,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyOptions,
      workerOptions,
      loadWorkerOptions,
      loadCompaniesOptions,
      getRouting,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_HISTORIES_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <HistorySearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              general={general}
              workerOptions={workerOptions}
              loadWorkerOptions={loadWorkerOptions}
              getRouting={getRouting}
              loadCompaniesOptions={loadCompaniesOptions}
              companyOptions={companyOptions}
              historyObj={historyObj}
            />

            <Card className='cardStyle mt-2'>
              {/* <HistoryMap historyObj={historyObj} /> */}
              <HistoryMapy map={map} historyObj={historyObj} />
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ HistoryR, CompanyR, WorkerR }) => {
  return {
    historyObj: HistoryR.historyObj,
    lightbox: HistoryR.lightbox,
    searchForm: HistoryR.searchForm,
    sorting: HistoryR.sorting,
    isLoadingSearch: HistoryR.isLoadingSearch,
    workerOptions: WorkerR.workerOptions,
    companyOptions: CompanyR.companyOptions,
  };
};

export default connect(mapStateToProps, actions)(Histories);
