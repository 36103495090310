import React from "react";
import { Card, Col, Row, Button, Spinner ,Form} from "reactstrap";
import toaster from "toasted-notes";
import {
  renderDateInput,
  renderGeneralInputSearch,
  renderPhoneSearch,
  renderArraw,
  renderInDependentSearchSelcet,
} from "../../utils/GeneralFunctions";
import { leftlStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import { UPDATE_STATE_WORK_FIELD_PROPS } from "../../redux/actions/types";
const StateWorkFieldSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    general,
    getStatesWorkFields,
    history,
    loadStateOptions,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();

    if (
      searchForm.state_id === null &&
      searchForm.name === "" &&
      searchForm.mobile === "" &&
      searchForm.from === "" &&
      searchForm.to === ""
    ) {
      toaster.notify(local.SearchValidation, {
        duration: 2000,
      });
    } else {
      getStatesWorkFields(true, searchForm);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
    <Form onSubmit={onSearch} className="mt-4 mb-4">
      <Row>
        <Col>
          <p className="page_title" style={leftlStyle}>
            {local.StateWorkFields}
          </p>
        </Col>
        {renderArraw(history)}
      </Row>
      <Row className="ml-5 mr-5 justify-content-center justify-content-lg-start">
      <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderGeneralInputSearch(
            "searchForm.name",
            searchForm.name,
            UPDATE_STATE_WORK_FIELD_PROPS,
            general,
            local.Name
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderPhoneSearch(
            "searchForm.mobile",
            searchForm.mobile,
            UPDATE_STATE_WORK_FIELD_PROPS,
            general
          )}
        </Col>

        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderInDependentSearchSelcet(
            local.Provinces,
            "searchForm.state_id",
            searchForm.state_id,
            UPDATE_STATE_WORK_FIELD_PROPS,
            general,
            loadStateOptions
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderDateInput(
            "searchForm.from",
            searchForm.from,
            UPDATE_STATE_WORK_FIELD_PROPS,
            general,
            local.FromDate,
            undefined,
            null,
            searchForm.to
          )}
        </Col>

        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderDateInput(
            "searchForm.to",
            searchForm.to,
            UPDATE_STATE_WORK_FIELD_PROPS,
            general,
            local.ToDate,
            undefined,
            searchForm.from
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          <Button
            style={leftlStyle}
            type="submit"
            disabled={isLoadingSearch}
            className="btn_search_style"
            onClick={(e) => onSearch(e)}
          >
            {isLoadingSearch === true ? (
              <Spinner type="grow" color="dark" size="sm" />
            ) : (
              local.Search
            )}
          </Button>
        </Col>
      </Row>
      </Form>
    </Card>
  );
};

export default StateWorkFieldSearch;
