import React from "react";
import { Card, Row, Table, Col } from "reactstrap";
import { local } from "../../lang/local";
import {
  renderTableHeadItem,
  renderNumber,
  renderDate,
  renderPagination,
  getPermissionValue,
  renderEditButton,
  renderCreateButton,
  renderEditTitle,
  onOpenProfile,
} from "../../utils/GeneralFunctions";
import { UPDATE_BRANCHES_PROPS } from "../../redux/actions/types";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import BranchProfile from "./BranchProfile";
import { leftlStyle } from "../../utils/GeneralStyle";

const BranchTable = (props) => {
  const {
    branchObj,
    general,
    sorting,
    getBranches,
    searchForm,
    modaleView,
  } = props;

  const onEdit = (e, item, index) => {
    e.preventDefault();

    general(
      [
        { prop: "branchForm.formvalues", value: item },
        { prop: "branchForm.isLoading", value: false },
      ],
      UPDATE_BRANCHES_PROPS
    );

    general(
      [
        { prop: "branchForm.index", value: index },
        { prop: "modale.actionType", value: "EditBranch" },
        { prop: "modale.title", value: local.updateBranch },
        { prop: "branchForm.isCreate", value: "update" },
        { prop: "modale.isOpen", value: true },
      ],
      UPDATE_BRANCHES_PROPS
    );
  };

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: "modale.title", value: local.addBranch },
        { prop: "modale.isOpen", value: true },
        { prop: "modale.actionType", value: "AddBranch" },
      ],
      UPDATE_BRANCHES_PROPS
    );
  };

  const renderBody = () => {
    if (branchObj.branchItem !== null) {
      return branchObj.branchItem.data.map((branch, index) => {
        return (
          <tr key={index}>
            <td className="td_table">{renderNumber(branch.id)}</td>

            <td
              className="td_table"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                onOpenProfile(
                  branch,
                  local.Branch,
                  general,
                  UPDATE_BRANCHES_PROPS,
                  true,
                  "companies/branches/show/{id}"
                )
              }
            >
              {branch.name[local.lang]}
            </td>
            <td className="td_table">{renderNumber(branch.mobile)}</td>
            <td className="td_table">{branch.address}</td>
            <td className="td_table">{branch.manager_name}</td>

            <td className="td_table">{renderDate(branch.created_at)}</td>

            {renderEditButton(
              getPermissionValue()["companies/branches/update/{id}"],
              onEdit,
              branch,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <BranchProfile modaleView={modaleView} general={general} />
      {renderCreateButton(
        getPermissionValue()["companies/branches/store"],
        onCreate,
        local.AddBranch
      )}

      <Row className="ml-5 mr-5">
        <Table
          id="company_table"
          responsive
          hover
          className="table_list mt-4 mb-4"
          style={{
            borderTopColor: "white",
            border: "none",
            position: "relative",
          }}
        >
          <thead>
            <tr className="table_head">
              {renderTableHeadItem(
                local.Id,
                branchObj.branchItem,
                "id",
                "branchObj.branchItem.data",
                UPDATE_BRANCHES_PROPS,
                general,
                sorting
              )}

              {renderTableHeadItem(
                local.Name,
                branchObj.branchItem,
                "name",
                "branchObj.branchItem.data",
                UPDATE_BRANCHES_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.Mobile,
                branchObj.branchItem,
                "mobile",
                "branchObj.branchItem.data",
                UPDATE_BRANCHES_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.Address,
                branchObj.branchItem,
                "address",
                "branchObj.branchItem.data",
                UPDATE_BRANCHES_PROPS,
                general,
                sorting
              )}
              {renderTableHeadItem(
                local.BranchSupervisor,
                branchObj.branchItem,
                "identifier",
                "branchObj.branchItem.data",
                UPDATE_BRANCHES_PROPS,
                general,
                sorting
              )}

              {renderTableHeadItem(
                local.DateAdded,
                branchObj.branchItem,
                "created_at",
                "branchObj.branchItem.data",
                UPDATE_BRANCHES_PROPS,
                general,
                sorting
              )}

              {renderEditTitle("companies/branches/update/{id}")}
            </tr>
          </thead>
          <tbody>{renderBody()}</tbody>
        </Table>
      </Row>

      {branchObj.branchItem !== null ? (
          <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id="test-table-xls-button"
              className="download-table-xls-button"
              table="company_table"
              filename={local.Compaines}
              sheet="tablexls"
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
            <span>{local.Total + " : "}</span>
            <span className="green_text">
              {branchObj.branchItem.pagination.total}
            </span>
            </div>
          </Col>
        </Row>
      ) : null}

      {renderPagination(branchObj.branchItem, searchForm, getBranches)}
    </Card>
  );
};

export default BranchTable;
