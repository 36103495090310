import { Request } from "../Api/index";
import { MERGE_STATE_PROPS, UPDATE_NOTIFICATIONS_PROPS } from "./types";
import toaster from "toasted-notes";
import { local } from "../../lang/local";
import {
  confirmSunccessed,
  getQueryString,
  removeUnsetValues,
} from "../../utils/GeneralFunctions";
export const getNotifications = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_NOTIFICATIONS_PROPS,
    payload: [{ prop: "notificationObj.notificationItem", value: null }],
  });
  let url = "/notifications";
  if (isSearching === true) {
    dispatch({
      type: UPDATE_NOTIFICATIONS_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_NOTIFICATIONS_PROPS,
      payload: [{ prop: "notificationObj.isLoading", value: true }],
    });
  }

  if (searchForm !== null) {
    let newSearchForm = removeUnsetValues(searchForm);
    let param = null;
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      param = getQueryString(newSearchForm);
      url += param;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_NOTIFICATIONS_PROPS,
            payload: [
              {
                prop: "notificationObj.notificationItem",
                value: res.data.data,
              },
            ],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }

      dispatch({
        type: UPDATE_NOTIFICATIONS_PROPS,
        payload: [
          { prop: "notificationObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_NOTIFICATIONS_PROPS,
        payload: [
          { prop: "notificationObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const sendNotification = (formValues, index, options, unsetForm) => (
  dispatch
) => {
  dispatch({
    type: UPDATE_NOTIFICATIONS_PROPS,
    payload: [{ prop: "notificationForm.isLoading", value: true }],
  });
  let url = null;

  if (formValues.id === undefined) {
    url = "workers/notify";
  } else {
    // url = "/states/update/" + formValues.id;
  }

  let newFormValue = Object.assign({}, formValues);
  newFormValue.ids = newFormValue.ids.map((id) => id.value);
  Request()
    .post(url, newFormValue)
    .then((res) => {
      if (res.data.status === true) {
        if (formValues.id === undefined) {
          confirmSunccessed(local.SuccessfullySend);

          if (options !== null) {
            dispatch({
              type: MERGE_STATE_PROPS,
              payload: [
                {
                  prop: "notificationObj.notificationItem.data",
                  value: res.data.data,
                },
              ],
            });
          }
          unsetForm();
        } else {
          confirmSunccessed(local.SuccessfullyEdit);

          if (options !== null) {
            dispatch({
              type: UPDATE_NOTIFICATIONS_PROPS,
              payload: [
                {
                  prop: "notificationObj.notificationItem.data." + index,
                  value: res.data.data,
                },
              ],
            });
          }
          unsetForm();
        }
      } else {
        Object.values(res.data.error).map((msg) => {
          toaster.notify(msg[0], {
            duration: 2000,
          });
        });
      }

      dispatch({
        type: UPDATE_NOTIFICATIONS_PROPS,
        payload: [{ prop: "notificationForm.isLoading", value: false }],
      });
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_NOTIFICATIONS_PROPS,
        payload: [{ prop: "notificationForm.isLoading", value: false }],
      });
      console.log(err);
    })
    .finally();
};
