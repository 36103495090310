import React, { Component } from 'react';
import { Card, Col, Row, Spinner, Form } from 'reactstrap';
import { Mapy } from 'mapy_sdk/lib';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { UPDATE_VISITS_PROPS } from '../../redux/actions/types';
import {
  onSave,
  renderAddButton,
  renderPoints,
  renderWorkFieldSelects,
  renderVisitDate,
  renderNewfieldsandPoints,
  renderWorkerSelect,
} from './VisitFunction';
import { renderBranchSelect } from '../Branches/BranchesFunction';
import general_marker from '../../assets/img/MapPin/general marker.png';
import { labelStyle, leftlStyle } from '../../utils/GeneralStyle';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import {
  checkHasOneCompany,
  getDeafultItem,
  getMyCompany,
} from '../../utils/GeneralFunctions';
import CustomInfoWindow from '../../components/InfoWindow/InfoWindow';
import GenericMapy from '../../components/GenericMapy/GenericMapy';
import { placeTypeOptions } from '../../utils/placeTypeOptions';

const map = new Mapy();

class CreateUpdateVisit extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { getVisitForDropDown, modale, general } = this.props;
    if (modale.actionType === 'AddVisit') {
      getVisitForDropDown();
      general(
        [
          {
            prop: 'visitForm.company_id',
            value: getMyCompany(general),
          },
        ],
        UPDATE_VISITS_PROPS
      );
    }
  };

  renderPolygon = () => {
    const { visitForm } = this.props;

    const workField = visitForm.formvalues.work_field_id[0];
    if (workField) {
      return {
        ...workField,
        id: workField.value,
        boundaries: workField.location,
      };
    }
  };

  renderMarkers = () => {
    const { visitForm } = this.props;

    const points = visitForm.formvalues.points[0];
    if (points) {
      return points.map((point) => {
        const markerPopup = CustomInfoWindow({
          placeName: point.name,
          placeType: getDeafultItem(point.place_type, placeTypeOptions)?.label,
        });

        return {
          ...point,
          latLng: point.location,
          markerIcon: {
            url: general_marker,

            imgSize: [30, 43],
          },
          popup: markerPopup,
        };
      });
    }
  };

  render() {
    const {
      visitForm,
      general,
      createORUpdateVisit,
      companyOptions,
      workerOptions,
      companyWfOptions,
      getCompainesWFPointForDropDown,
      pointsOptions,
      modale,
      loadCompaniesOptions,
      loadWorkerOptions,
      branchOptions,
      loadBranchesOptions,
    } = this.props;
    return (
      <Form
        onSubmit={(e) => onSave(e, createORUpdateVisit, visitForm, true)}
        style={{ height: '100%' }}
      >
        <Card className='cardCreate ml-2 mr-2'>
          <div className='mt-2 mr-5 mt-3 ml-5'>
            <Row>
              <Col className='text-center'>
                <p>{modale.title}</p>
              </Col>
            </Row>
            {visitForm.formvalues.id === undefined ? (
              <>
                {checkHasOneCompany() === false ? (
                  <Row className='mt-3'>
                    <Col xl={12}>
                      {renderCompanySelect(
                        'visitForm.company_id',
                        visitForm.company_id,
                        UPDATE_VISITS_PROPS,
                        general,
                        local.Company,
                        loadCompaniesOptions,
                        companyOptions,
                        null,
                        [
                          // {
                          //   p: 'visitForm.formvalues.work_field_id',
                          //   v: [null],
                          // },
                          { p: 'visitForm.worker_id', v: null },
                          { p: 'visitForm.branch_id', v: null },
                        ],
                        '4',
                        visitForm.iscreate
                      )}
                    </Col>
                  </Row>
                ) : null}
                <Row className='mt-3'>
                  <Col xl={12}>
                    <span className='label_title' style={labelStyle}>
                      {local.Branch}
                    </span>
                    {renderBranchSelect(
                      'visitForm.branch_id',
                      visitForm.branch_id,
                      UPDATE_VISITS_PROPS,
                      general,
                      local.Branch,
                      loadBranchesOptions,
                      branchOptions,
                      visitForm.company_id,
                      null,
                      [
                        // {
                        //   p: 'visitForm.formvalues.work_field_id',
                        //   v: [null],
                        // },
                      ],
                      '4',
                      visitForm.iscreate
                    )}
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <Col xl={12}>
                    {renderWorkerSelect(
                      general,
                      workerOptions,
                      visitForm.worker_id,
                      visitForm.company_id,
                      '4',
                      loadWorkerOptions,
                      visitForm.iscreate
                    )}
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <Col>
                    <div style={leftlStyle}>{renderAddButton(general)}</div>
                  </Col>
                </Row>

                <Row className='mt-5'>
                  <Col xl={12}>
                    {renderWorkFieldSelects(
                      0,
                      visitForm.formvalues.work_field_id[0],
                      general,
                      companyWfOptions,
                      getCompainesWFPointForDropDown,
                      visitForm.worker_id,
                      local.SelectWorkers,
                      visitForm.worker_type
                    )}
                  </Col>
                </Row>
              </>
            ) : null}
            {visitForm.formvalues.work_field_id[0] !== undefined ? (
              visitForm.formvalues.work_field_id[0] !== null ? (
                <Row className='mt-3'>
                  <Col xl={6} className='mt-5'>
                    {renderPoints(
                      0,
                      visitForm.formvalues.points[0],
                      general,
                      pointsOptions,
                      visitForm.formvalues.work_field_id[0],
                      local.SelectWorkField,
                      visitForm.formvalues.id,
                      getCompainesWFPointForDropDown,
                      visitForm.worker_type
                    )}
                  </Col>
                  {visitForm.formvalues.points[0] !== undefined
                    ? visitForm.formvalues.points[0] !== null
                      ? visitForm.formvalues.points[0].map((d, index) => {
                          return (
                            <>
                              <Col xl={6} className='mt-5'>
                                {renderVisitDate(
                                  'visitForm.formvalues.points.' +
                                    0 +
                                    '.' +
                                    index +
                                    '.date',
                                  visitForm.formvalues.points[0][index],
                                  general,
                                  d.label,
                                  new Date()
                                )}
                              </Col>
                            </>
                          );
                        })
                      : null
                    : null}
                </Row>
              ) : null
            ) : null}
            {renderNewfieldsandPoints(
              visitForm,
              general,
              companyWfOptions,
              getCompainesWFPointForDropDown,
              pointsOptions
            )}
            <Row className='mt-5'>
              <Col xl={12} lg={12} md={12} sm={12} xs={12}>
                <Card
                  style={{
                    borderRadius: '10px',
                    height: '400px',
                    width: '100%',
                  }}
                >
                  {/* <GeneralMap
                    {...this.props}
                    general={general}
                    formvalues={visitForm.formvalues}
                    action={UPDATE_VISITS_PROPS}
                    modale={modale}
                    type="visitPage"
                  /> */}

                  <GenericMapy
                    map={map}
                    mapID='AddVisitMap'
                    singlePolygon={this.renderPolygon()}
                    markers={this.renderMarkers()}
                  />
                </Card>
              </Col>
            </Row>
            <center>
              <button
                disabled={visitForm.isLoading}
                className='save_btn mt-5'
                onClick={(e) => onSave(e, createORUpdateVisit, visitForm, true)}
              >
                {visitForm.isLoading === true ? (
                  <Spinner type='grow' color='dark' size='sm' />
                ) : (
                  <>
                    <p style={{ display: 'inline' }}>{local.Save} </p>
                    <img src={icon_btn} width='20' height='20' alt='img' />
                  </>
                )}
              </button>
            </center>
            <br /> <br />
          </div>
        </Card>
      </Form>
    );
  }
}
const mapStateToProps = ({
  VisitR,
  CompanyR,
  WorkerR,
  CompanyWorkFieldR,
  RequestsR,
  BranchR,
  MapReducer,
}) => {
  return {
    visitForm: VisitR.visitForm,
    stateOptions: VisitR.stateOptions,
    companyOptions: CompanyR.companyOptions,
    workerOptions: WorkerR.workerOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    pointsOptions: RequestsR.pointsOptions,
    modale: VisitR.modale,
    branchOptions: BranchR.branchOptions,
    MapReducer: MapReducer,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateVisit);
