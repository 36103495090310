import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import WorkReportTable from './WorkReportTable';
import * as actions from '../../redux/actions/index';
import { getMyCompany } from '../../utils/GeneralFunctions';
import WorkReportSearch from './WorkReportSearch';
import {
  UPDATE_WORK_REPORT_PROPS,
  CLEAR_WORK_REPORT_STATE,
} from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
class WorkReport extends Component {
  componentDidMount() {
    this.renderHistories();
  }

  renderHistories = () => {
    const { general } = this.props;
    general([], CLEAR_WORK_REPORT_STATE);
    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_WORK_REPORT_PROPS
    );
  };

  render() {
    const {
      history,
      general,
      historyObj,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getHistories,
      workerOptions,
      loadCompaniesWFOptions,
      loadWorkerOptions,
      loadCompaniesOptions,
      loadBranchesOptions,
      branchOptions,
      sorting,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_WORK_REPORT_PROPS}
        />
        <Row>
          <Col>
            <WorkReportSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getHistories={getHistories}
              general={general}
              workerOptions={workerOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadWorkerOptions={loadWorkerOptions}
              loadCompaniesOptions={loadCompaniesOptions}
              loadBranchesOptions={loadBranchesOptions}
              branchOptions={branchOptions}
            />

            <WorkReportTable
              general={general}
              historyObj={historyObj}
              getHistories={getHistories}
              searchForm={searchForm}
              companyWfOptions={companyWfOptions}
              sorting={sorting}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  WorkReportR,
  CompanyWorkFieldR,
  CompanyR,
  WorkerR,
  BranchR,
}) => {
  return {
    historyObj: WorkReportR.historyObj,
    lightbox: WorkReportR.lightbox,
    searchForm: WorkReportR.searchForm,
    sorting: WorkReportR.sorting,
    isLoadingSearch: WorkReportR.isLoadingSearch,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    workerOptions: WorkerR.workerOptions,
    companyOptions: CompanyR.companyOptions,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(WorkReport);
