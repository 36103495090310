import React from "react";
import { Card, Col, Row, Button, Spinner, Form } from "reactstrap";
import {
  leftlStyle,
  selectStyle4,
  selectStyle5,
} from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import toaster from "toasted-notes";
import { UPDATE_REQUESTS_PROPS } from "../../redux/actions/types";
import { PLACE_TYPE_OPTIONS } from "../../components/StaticOptionsSelect/staticOptions";
import {
  orderStatus,
  errandChannel,
  requesterFunction,
  posType,
} from "../../utils/staticOptions";
import { visitTypeOptions } from "../../utils/visitTypeOptions";
import Select from "react-select";

import {
  renderArraw,
  renderGeneralInputSearch,
  renderPhoneSearch,
  renderDateInput,
  checkHasOneCompany,
  handleSelectChangeEnhanced,
  getWorkFieldOptions,
  workFieldPermissionedForDropDown,
  getDeafultItem,
  getEmployeeOptions,
  getBranchOptions,
} from "../../utils/GeneralFunctions";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import { getSelectOptionsArr } from "../../components/StaticOptionsSelect/StaticOptionsSelect";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
import CheckBoxMaterialUI from "../../components/CheckBoxMaterialUI";
import { placeTypeOptions } from "../../utils/placeTypeOptions";
import GenericPhoneInput from "../../components/GenericPhoneInput/GenericPhoneInput";
import { filterEmployeesByWorkField } from "./RequestsFunctions";

const RequestSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    getRequests,
    general,
    companyOptions,
    history,
    loadCompaniesOptions,
    parent_branch,
    loadBranchesOptions,
    branchOptions,
    getAllPoints,
    requestsObj,
    setRequestsProps,
    getCompainesWFPoint,
  } = props;

  const placeOptions = getSelectOptionsArr(PLACE_TYPE_OPTIONS);

  const onCheckBoxChange = (e, prop) => {
    if (e.target.check) {
      setRequestsProps([[prop, "0"]]);
    }
  };
  const Load_Add_Order = () => {
    history.push("/dashboard/addpoint");
  };
  const onSearch = (e) => {
    searchForm.page = 1;

    // getCompanyWFTotals(searchForm);
    e.preventDefault();
    // if (searchForm.ids === null) {
    //   toaster.notify(local.ChooseCompanyWorkField, {
    //     duration: 2000,
    //   });
    // } else {
    // if (searchForm.merchant_id) {
    // getCompainesWFPoint(true, searchForm);
    // }
    getRequests(searchForm);
    // getRequests(true, searchForm);
    // getAllPoints(
    //   searchForm.ids,
    //   'requestsObj.coordinates',
    //   // MERGE_REQUESTS_PROPS,
    //   UPDATE_REQUESTS_PROPS,
    //   requestsObj.coordinates
    // );
  };
  return (
    <Card className="cardStyle cardSearch mt-1">
      <Form onSubmit={onSearch} className="mt-4 mb-4">
        <Row>
          <Col>
            <p className="page_title" style={leftlStyle}>
              {local.CompaniesWorkFieldPoint}
            </p>
          </Col>
          {/* {renderArraw(history)} */}
        </Row>

        <Row className="mt-2" style={{width:"80%"}}>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.id",
              searchForm.id,
              UPDATE_REQUESTS_PROPS,
              general,
              local.TicketId
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.terminal_id",
              searchForm.terminal_id,
              UPDATE_REQUESTS_PROPS,
              general,
              local.TerminalId
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.merchant_id",
              searchForm.merchant_id,
              UPDATE_REQUESTS_PROPS,
              general,
              local.MerchantId
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.merchant_name",
              searchForm.merchant_name,
              UPDATE_REQUESTS_PROPS,
              general,
              local.MerchantName
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderGeneralInputSearch(
              "searchForm.email",
              searchForm.email,
              UPDATE_REQUESTS_PROPS,
              general,
              local.Email
            )}
          </Col>

          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderGeneralInputSearch(
              'searchForm.name',
              searchForm.name,
              UPDATE_REQUESTS_PROPS,
              general,
              local.Name
            )}
          </Col> */}

          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
              {renderCompanySelect(
                "searchForm.company",
                searchForm.company,
                UPDATE_REQUESTS_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: "searchForm.ids", v: null },
                  { p: "searchForm.branch_id", v: null },
                ],
                null
              )}
            </Col>
          ) : null}
          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderBranchSelect(
              "searchForm.branch_id",
              searchForm.branch_id,
              UPDATE_REQUESTS_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              [{ p: "searchForm.ids", v: null }],
              null
            )}
          </Col> */}
          <CustomAsyncPaginate
            className="mt-2"
            placeholder={local.Regions}
            loadOptions={getBranchOptions(searchForm.company, {
              parent_id: 0,
            })}
            cacheUniqsArr={[searchForm.company]}
            value={parent_branch}
            onChange={(e) =>
              handleSelectChangeEnhanced(e, "parent_branch", setRequestsProps, [
                "searchForm.ids",
                "searchForm.branch_id",
              ])
            }
            additional={{
              page: 1,
            }}
            isDisabled={!!!searchForm.company}
          />
          <CustomAsyncPaginate
            className=" mt-2"
            placeholder={local.Cities}
            loadOptions={getBranchOptions(searchForm.company, {
              parent_id: parent_branch?.id,
            })}
            cacheUniqsArr={[parent_branch]}
            value={searchForm.branch_id}
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                "searchForm.branch_id",
                setRequestsProps,
                ["searchForm.ids"]
              )
            }
            isDisabled={!!!parent_branch}
            additional={{
              page: 1,
            }}
          />
          {/* <div className="react-select-div">
          {renderWorkFSelect(
            "searchForm.ids",
            searchForm.ids,
            UPDATE_REQUESTS_PROPS,
            general,
            local.WorkPlaces,
            loadCompaniesWFOptions,
            companyWfOptions,
            true,
            null,
            searchForm.branch_id,
            null,
            null,
            undefined,
            undefined,
            undefined
          )}
        </div> */}

          <CustomAsyncPaginate
            loadOptions={getWorkFieldOptions(searchForm.branch_id?.id)}
            className=" mt-2"
            value={searchForm.work_field_id}
            placeholder={local.Zones}
            cacheUniqsArr={[searchForm.branch_id]}
            // isMulti={true}
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                "searchForm.work_field_id",
                setRequestsProps,
                []
              )
            }
            filterOption={(option, rawInput) =>
              workFieldPermissionedForDropDown({ option, rawInput })
            }
            additional={{
              page: 1,
              branchId: searchForm.branch_id,
            }}
            isDisabled={!!!searchForm.branch_id}
          />

          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_REQUESTS_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_REQUESTS_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col> */}
          <CustomAsyncPaginate
            loadOptions={getEmployeeOptions(searchForm.company)}
            className=" mt-2"
            value={searchForm.worker_id}
            placeholder={local.Employees}
            cacheUniqsArr={[searchForm.company]}
            onChange={(e) => {
              handleSelectChangeEnhanced(
                e,
                "searchForm.worker_id",
                setRequestsProps
              );
            }}
            additional={{
              page: 1,
              companyID: searchForm.company,
            }}
            filterOption={
              searchForm.work_field
                ? (option, rawInput) =>
                    filterEmployeesByWorkField(
                      option,
                      rawInput,
                      searchForm.work_field.id
                    )
                : undefined
            }
          />
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Select
              key={searchForm.place_type}
              options={placeTypeOptions}
              value={getDeafultItem(searchForm.place_type, placeTypeOptions)}
              placeholder={local.PlaceType}
              // className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={{ ...selectStyle4, ...selectStyle5 }}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: "searchForm.place_type",
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.place_type",
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Select
              key={searchForm.order_status}
              options={orderStatus}
              value={getDeafultItem(searchForm.order_status, orderStatus)}
              placeholder={local.OrderStatus}
              // className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={{ ...selectStyle4, ...selectStyle5 }}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: "searchForm.order_status",
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.order_status",
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Select
              key={searchForm.requester_function}
              options={requesterFunction}
              value={getDeafultItem(
                searchForm.requester_function,
                requesterFunction
              )}
              placeholder={local.Requester_Functon}
              // className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={{ ...selectStyle4, ...selectStyle5 }}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: "searchForm.requester_function",
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.requester_function",
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Select
              key={searchForm.errand_channel}
              options={errandChannel}
              value={getDeafultItem(searchForm.errand_channel, errandChannel)}
              placeholder={"Errand Channel"}
              // className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={{ ...selectStyle4, ...selectStyle5 }}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: "searchForm.errand_channel",
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.errand_channel",
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Select
              key={searchForm.pos_type}
              options={posType}
              value={getDeafultItem(searchForm.pos_type, posType)}
              placeholder={"POS type"}
              // className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={{ ...selectStyle4, ...selectStyle5 }}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: "searchForm.pos_type",
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.pos_type",
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <Select
              key={searchForm.visit_type}
              options={visitTypeOptions}
              value={getDeafultItem(searchForm.visit_type, visitTypeOptions)}
              placeholder={"Errand type"}
              // className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={{ ...selectStyle4, ...selectStyle5 }}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: "searchForm.visit_type",
                          value: e.value,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.visit_type",
                          value: null,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
              }
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <GenericPhoneInput
              prop={"searchForm.mobile"}
              value={searchForm.mobile}
              action={UPDATE_REQUESTS_PROPS}
              general={general}
              fullWidth
            />
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderDateInput(
              "searchForm.from",
              searchForm.from,
              UPDATE_REQUESTS_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderDateInput(
              "searchForm.to",
              searchForm.to,
              UPDATE_REQUESTS_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            <div
              style={{ display: "flex", justifyContent: "space-around" }}
              className="mt-2 col-12"
            >
              <Button
                type="submit"
                // style={leftlStyle}
                disabled={isLoadingSearch}
                className="btn_search_style mt-1"
                onClick={(e) => onSearch(e)}
              >
                {isLoadingSearch === true ? (
                  <Spinner type="grow" color="dark" size="sm" />
                ) : (
                  local.Search
                )}
              </Button>
              <Button
                type="submit"
                // style={leftlStyle}
                // disabled={isLoadingSearch}
                className="btn_Add_order_style mt-1"
                onClick={Load_Add_Order}
              >
                {local.AddOrder}
              </Button>
            </div>
          </Col>

          {/* <Col xl={3} className="mt-4">
          {renderSearchSelcet(
            local.Class,
            "searchForm.class",
            searchForm.title,
            UPDATE_REQUESTS_PROPS,
            general,
            degreeOptions,
            null,
            searchForm.class,
            undefined
          )}
        </Col> */}
          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderPhoneSearch(
              'searchForm.mobile',
              searchForm.mobile,
              UPDATE_REQUESTS_PROPS,
              general
            )}
          </Col> */}

          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2 flex">
            <CheckBoxMaterialUI
              checked={searchForm.visit_action}
              onChange={(e) =>
                onCheckBoxChange(e, 'searchForm.visit_action')
              }
              label={local.NoAssignedEmployees}
            />
            <CheckBoxMaterialUI
              checked={searchForm.visit_action}
              onChange={(e) => {
                e.target.checked === true
                  ? general(
                      [
                        {
                          prop: "searchForm.visit_action",
                          value: 0,
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: "searchForm.visit_action",
                          value: "",
                        },
                      ],
                      UPDATE_REQUESTS_PROPS
                    );
              }}
              label={local.NoAssignedEmployees}
            />
          </Col> */}
          {/* <Col xl={12} lg={12} md={12} sm={12} xs={12} className='mt-3'>
            <span>{local.DeepSearch}</span>
            {renderGeneralInputSearch(
              'searchForm.merchant_id',
              searchForm.merchant_id,
              UPDATE_REQUESTS_PROPS,
              general,
              local.MerchantId
            )}
          </Col> */}
        </Row>

        {/*<Row>*/}
        {/*</Row>*/}
      </Form>
    </Card>
  );
};

export default RequestSearch;
