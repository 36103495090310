import React, { Component } from 'react';
import { Form, Card, Col, Row, Spinner } from 'reactstrap';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { labelStyle, leftlStyle, selectStyle3 } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { UPDATE_REQUESTS_PROPS } from '../../redux/actions/types';
import { renderArraw, getDeafultItem } from '../../utils/GeneralFunctions';
import { visitTypeOptions } from '../../utils/visitTypeOptions';
import Select from 'react-select';
import toaster from 'toasted-notes';

class AddOrderVisit extends Component {
  handleSave = (e) => {
    const { requestsObj, requestForm, addRequest } = this.props;
    if (requestForm.formvalues.visit_type) {
      addRequest(
        requestForm.formvalues,
        requestForm.index,
        requestsObj.requestsItem
      );
    } else {
      toaster.notify(local.VisitTypeRequired, {
        duration: 2000,
      });
    }
  };
  render() {
    const { requestForm, general,  } = this.props;

    return (
      <div className='page-wrapper'>
        <Row>
          <Col>
            <Form onSubmit={this.handleSave}>
              <Card className='cardCreate'>
                <div className='mt-3'>
                  <Row>
                    <Col
                      xl={12}
                      lg={12}
                      md={12}
                      sm={12}
                      xs={12}
                      className='mt-2'
                    >
                      <span className='label_title' style={labelStyle}>
                        {local.VisitType}
                      </span>
                      <Select
                        key={requestForm.formvalues.visit_type}
                        options={visitTypeOptions}
                        value={getDeafultItem(
                          requestForm.formvalues.visit_type,
                          visitTypeOptions
                        )}
                        placeholder={local.VisitType}
                        className='mt-2'
                        getOptionLabel={(op) => op.label}
                        getOptionValue={(op) => op.value}
                        styles={selectStyle3}
                        isClearable={true}
                        onChange={(e) =>
                          e
                            ? general(
                                [
                                  {
                                    prop: 'requestForm.formvalues.visit_type',
                                    value: e.value,
                                  },
                                ],
                                UPDATE_REQUESTS_PROPS
                              )
                            : general(
                                [
                                  {
                                    prop: 'requestForm.formvalues.visit_type',
                                    value: null,
                                  },
                                ],
                                UPDATE_REQUESTS_PROPS
                              )
                        }
                      />
                    </Col>
                  </Row>
                  <center>
                    <button
                      type='submit'
                      disabled={requestForm.isLoading}
                      className='save_btn mt-3'
                      onClick={this.handleSave}
                    >
                      {requestForm.isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <>
                          <p style={{ display: 'inline' }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
            <br />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ RequestsR }) => {
  return {
    requestForm: RequestsR.requestForm,
    jobTitleOptions: RequestsR.jobTitleOptions,
    modale: RequestsR.modale,
    requestsObj: RequestsR.requestsObj,
    typeOptions: RequestsR.typeOptions,
    work_fields_points: RequestsR.work_fields_points,
  };
};

export default connect(mapStateToProps, actions)(AddOrderVisit);
