import React from 'react';
import {Modal} from 'antd'
import { getLanguage, local } from "../../lang/local";

const ReasonModal =({modalIsOpen,handleCancelMOdal_reason,DATA,createORUpdateVisit,setReason,Reason})=>{
  const change_reason=(e)=>{
    setReason(e.target.value)
  }
  
  const handleOk = async() => {
    DATA.formValues.points[0][0].comment=Reason
     await createORUpdateVisit(DATA.formValues, DATA.employeeId, DATA.index, null, DATA.workerName)
    // console.log("reasssons",DATA.formValues.points[0][0])
    handleCancelMOdal_reason()

    };
return(
<Modal title={`${local.leaveAcommenT}`} visible={modalIsOpen} 

        onOk={handleOk} 
        onCancel={handleCancelMOdal_reason}
        >
        <textarea 
        // value={Reason}
        // onChange={e=>change_reason(e)}
        onBlur={e=>change_reason(e)}

        style={{
            width: "100%",
             border: "1px solid black",
            borderRadius: "7px",
            boxShadow: "4px 4px 4px gre",
            height: "160px",
            fontSize: "large",


        }}>

        </textarea>
      </Modal>
)
}
export default ReasonModal;