import {getLanguage} from "../lang/local";

export const selectStyle = {
    container: (base, state) => ({
        ...base,
        transition:
            "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
        "&:hover": {
            boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
        },
        borderRadius: "10px",
        width: "90%",
    }),
    input: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "10px",
        width: "100%",
        // textAlign: "center",
    }),

    indicatorSeparator: (base, state) => ({
        ...base,
        display: "none",
    }),

    singleValue: (base, state) => ({
        ...base,
        direction: "ltr",
        color: "red",
    }),
    control: (base, state) => ({
        ...base,
        background: "#00beac;",
        borderRadius: "10px",
        direction: getLanguage() === "en" ? "ltr" : "rtl",
        height: 35,
        minHeight: 35,
    }),

    indicatorsContainer: (base, state) => ({
        ...base,
        color: "white",
    }),
    clearIndicator: (base, state) => ({
        ...base,
        color: "white",
    }),

    valueContainer: (base, state) => ({
        ...base,
        background: "#00beac;",
        borderRadius: "10px",
        paddingTop: "2.5%",
        paddingBottom: "2.5%",
        fontSize: "10px",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: "white",
        // textAlign: "center",
        fontSize:"14px",
        width: "100%",
    }),
    menu: (base, state) => ({
        ...base,
        fontSize: "10px",
        zIndex: 100000,
    }),
    option: (base, state) => ({
        ...base,
        background: "none",
        // textAlign: "center",
        // borderBottom: "2px solid gray",
    }),
};

export const selectStyle2 = {
    container: (base, state) => ({
        ...base,
        transition:
            "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
        "&:hover": {
            boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
        },
        borderRadius: "10px",
        width: "80%",
    }),
    input: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "10px",
        width: "100%",
        // textAlign: "center",
    }),
    clearIndicator: (base, state) => ({
        ...base,
        color: "white",
    }),

    indicatorSeparator: (base, state) => ({
        ...base,
        display: "none",
    }),

    singleValue: (base, state) => ({
        ...base,
        direction: getLanguage() === "en" ? "ltr" : "rtl",
    }),
    control: (base, state) => ({
        ...base,
        background: "#00beac;",
        borderRadius: "10px",
        direction: getLanguage() === "en" ? "ltr" : "rtl",
        height: 35,
        minHeight: 35,
    }),

    indicatorsContainer: (base, state) => ({
        ...base,
        color: "white",
    }),

    valueContainer: (base, state) => ({
        ...base,
        background: "#00beac;",
        borderRadius: "10px",
        paddingTop: "2%",
        paddingBottom: "2%",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "14px",
        // textAlign: "center",
        width: "100%",
    }),
    menu: (base, state) => ({
        ...base,
        fontSize: "10px",
        color: "white",
        zIndex: 100000,
    }),
    option: (base, state) => ({
        ...base,
        background: "none",
        // textAlign: "center",
    }),
};

export const labelStyle = {
    float: getLanguage() === "en" ? "left" : "right",
};

export const labelSelectStyle = {
    float: getLanguage() === "en" ? null : "right",
};

export const leftlStyle = {
    float: getLanguage() === "en" ? "left" : "right",
};

export const rightStyle = {
    float: getLanguage() === "en" ? "right" : "left",
};

export const sidebarStyle = {
    float: getLanguage() === "en" ? "left" : "right",
    direction: getLanguage() === "en" ? "ltr" : "rtl",
    background: "white",
    height: "100%",
    borderRadius: "10px",
};

export const chatStyle = {
    float: getLanguage() === "en" ? "right" : "left",
    direction: getLanguage() === "en" ? "rtl" : "ltr",
    background: "white",
    height: "100%",
    borderRadius: "10px",
    width: "5%",
};

export const selectStyleSearch = {
    container: (base, state) => ({
        ...base,
        transition:
            "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
        "&:hover": {
            boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
        },
        borderRadius: "10px",
        width: "100% !important",
    }),
    input: (base, state) => ({
        ...base,
        color: "#495057",
        fontSize: "10px",
    }),
    clearIndicator: (base, state) => ({
        ...base,
        color: "white",
    }),

    indicatorSeparator: (base, state) => ({
        ...base,
        display: "none",
    }),

    singleValue: (base, state) => ({
        ...base,
        direction: getLanguage() === "en" ? "ltr" : "rtl",
        color: "#495057",
        fontSize: "10px",
    }),
    control: (base, state) => ({
        ...base,
        borderColor: "rgb(122, 134, 154);",
        borderRadius: "10px",
        direction: getLanguage() === "en" ? "ltr" : "rtl",
        height: 35,
        minHeight: 35,
    }),

    valueContainer: (base, state) => ({
        ...base,
        borderRadius: "10px",
        color: "#495057",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: "#495057",

        fontSize: "14px",
    }),
    menu: (base, state) => ({
        ...base,
        fontSize: "10px",
        zIndex: 100000,
    }),
    option: (base, state) => ({
        ...base,
        borderBottom: "1px solid #00beac;",
        color: "#3b3b3b",
        padding: 10,
        borderRadius: "5px",
        // textAlign: "center",
        background: "none",
    }),
};

export const selectStyle3 = {
    container: (base, state) => ({
        ...base,
        transition:
            "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
        "&:hover": {
            boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
        },
        borderRadius: "10px",
        // minHeight: 35,
    }),
    input: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "10px",
    }),
    clearIndicator: (base, state) => ({
        ...base,
        color: "white",
    }),

    indicatorSeparator: (base, state) => ({
        ...base,
        display: "none",
    }),

    loadingIndicator: (base, state) => ({
        ...base,
        ">span": {
            fontSize: "10px",
        },
    }),

    singleValue: (base, state) => ({
        ...base,
        direction: getLanguage() === "en" ? "ltr" : "rtl",
        color: "white",
        fontSize: "14px",
    }),
    control: (base, state) => ({
        ...base,
        background: "#0052CC;",
        borderRadius: "10px",
        direction: getLanguage() === "en" ? "ltr" : "rtl",
        width: "100%",
        // minHeight: 35,
    }),

    indicatorsContainer: (base, state) => ({
        ...base,
        color: "white",
    }),

    valueContainer: (base, state) => ({
        ...base,
        background: "#0052CC;",
        borderRadius: "10px",
        color: "white",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "14px",
        // textAlign: "center",
        width: "100%",
    }),
    menu: (base, state) => ({
        ...base,
        fontSize: "10px",
        zIndex: 100000,
    }),
    option: (base, state) => ({
        ...base,
        borderBottom: "1px solid #00beac;",
        color: "#3b3b3b",
        padding: 10,
        fontSize: "14px",
        borderRadius: "5px",
        // textAlign: "center",
        background: "none",
    }),
    multiValue: (base, state) => ({
        ...base,
        background: "transparent",
        color: "white",
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        color: "white",
    }),
};

export const selectStyle4 = {
    container: (base, state) => ({
        ...base,
        transition:
            "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
        // backgroundColor:"red"
        // "&:hover": {
        //   boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
        // },
        // borderRadius: "10px",
        // minHeight: 35,
    }),
    input: (base, state) => ({
        ...base,
        // color: "white",
        fontSize: "16px",
        padding: ".4rem .25rem",
        zIndex: 0,
    }),

    indicatorSeparator: (base, state) => ({
        ...base,
        display: "none",
    }),

    loadingIndicator: (base, state) => ({
        ...base,
        ">span": {
            fontSize: "10px",
        },
    }),

    singleValue: (base, state) => ({
        ...base,
        color: "#757575",
        fontSize: "14px",
    }),

    indicatorsContainer: (base, state) => ({
        ...base,
        color: "#757575",
        zIndex: 0,
    }),

    menu: (base, state) => ({
        ...base,
        fontSize: "10px",
        zIndex: 99999,
    }),
    option: (base, state) => ({
        ...base,
        // borderBottom: "1px solid #00beac;",
        color: "#3b3b3b",
        padding: 10,
        fontSize: "14px",
        // borderRadius: "5px",
        // textAlign: "center",
        background: "none",
        zIndex: 99999,
    }),
    multiValue: (base, state) => ({
        ...base,
        background: "transparent",
        color: "#3b3b3b",
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        color: "#3b3b3b",
    }),
};

export const selectStyle5 = {
    control: (base, state) => ({
        ...base,
        background: state.isDisabled? "#75757575": "#EEEEEE;",
        borderRadius: "0px",
        border: "none",
        width: "100%",
        // minHeight: 35,
        // "&:hover": {
        //   borderColor: "transparent",
        //   // boxShadow: "0 0 0 1px #757575",
        // },
    }),
    valueContainer: (base, state) => ({
        ...base,
        // background: state.isDisabled? "#75757575": "#EEEEEE;",
        // borderRadius: "10px",
        color: "white",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: state.isDisabled?"white":"#757575",
        fontSize: "14px",
        // textAlign: "center",
        width: "100%",
    }),
    // indicatorsContainer: (base, state) => ({
    //   ...base,
    //   color: "#757575",
    // }),
    clearIndicator: (base, state) => ({
        ...base,
        color: "#757575",
    }),
};
export const selet_new_Style = {
    control: (base, state) => ({
        ...base,
        background: state.isDisabled ? "#75757575" : "#0052CC",
        borderRadius: "0px",
        border: "none",
        width: "100%",
        color: "white",
    }),
    valueContainer: (base, state) => ({
        ...base,
        // background: "",
        // borderRadius: "10px",
        color: "white",
    }),
    placeholder: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "14px",
        // textAlign: "center",
        width: "100%",
    }),
    // indicatorsContainer: (base, state) => ({
    //   ...base,
    //   color: "#757575",
    // }),
    clearIndicator: (base, state) => ({
        ...base,
        color: "white",
    }),
};
export const Select_new_Style_2 = {
    container: (base, state) => ({
        ...base,
        transition:
            "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
        // backgroundColor:"red"
        // "&:hover": {
        //   boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
        // },
        // borderRadius: "10px",
        // minHeight: 35,
    }),
    input: (base, state) => ({
        ...base,
        // color: "white",
        fontSize: "16px",
        padding: ".4rem .25rem",
        zIndex: 0,
    }),

    indicatorSeparator: (base, state) => ({
        ...base,
        display: "none",
    }),

    loadingIndicator: (base, state) => ({
        ...base,
        ">span": {
            fontSize: "10px",
        },
        color: "white",
    }),

    singleValue: (base, state) => ({
        ...base,
        color: "white",
        fontSize: "14px",
    }),

    indicatorsContainer: (base, state) => ({
        ...base,
        color: "white",
        zIndex: 0,
    }),

    menu: (base, state) => ({
        ...base,
        fontSize: "10px",
        position: "absolute",
        top: "100%",
        left: "0",
        zIndex: 999999,
        minHeight: "100px",
    }),
    option: (base, state) => ({
        ...base,
        // borderBottom: "1px solid #00beac;",
        color: "#3b3b3b",
        padding: 10,
        fontSize: "14px",
        // borderRadius: "5px",
        // textAlign: "center",
        background: "none",
        zIndex: 99999,
    }),
    multiValue: (base, state) => ({
        ...base,
        background: "transparent",
        color: "#3b3b3b",
    }),
    multiValueLabel: (base, state) => ({
        ...base,
        color: "#3b3b3b",
    }),
};
export const polygonStyle = [
    // ACTIVE (being drawn)
    // line stroke
    {
        id: "gl-draw-line",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["!=", "mode", "static"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": "#00FFE7",
            // "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
    },
    // polygon fill
    {
        id: "gl-draw-polygon-fill",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        paint: {
            "fill-color": "#009F90",
            "fill-outline-color": "#009F90",
            "fill-opacity": 0.1,
        },
    },
    // polygon outline stroke
    // This doesn't style the first edge of the polygon, which uses the line stroke styling instead
    {
        id: "gl-draw-polygon-stroke-active",
        type: "line",
        filter: ["all", ["==", "$type", "Polygon"], ["!=", "mode", "static"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": "#00FFE7",
            // "line-dasharray": [0.2, 2],
            "line-width": 2,
        },
    },
    // vertex point halos
    {
        id: "gl-draw-polygon-and-line-vertex-halo-active",
        type: "circle",
        filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "circle-radius": 5,
            "circle-color": "#009F90",
        },
    },
    // vertex points
    {
        id: "gl-draw-polygon-and-line-vertex-active",
        type: "circle",
        filter: [
            "all",
            ["==", "meta", "vertex"],
            ["==", "$type", "Point"],
            ["!=", "mode", "static"],
        ],
        paint: {
            "circle-radius": 3,
            "circle-color": "#009F90",
        },
    },

    // INACTIVE (static, already drawn)
    // line stroke
    {
        id: "gl-draw-line-static",
        type: "line",
        filter: ["all", ["==", "$type", "LineString"], ["==", "mode", "static"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": "#009F90",
            "line-width": 3,
        },
    },
    // polygon fill
    {
        id: "gl-draw-polygon-fill-static",
        type: "fill",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        paint: {
            "fill-color": "#009F90",
            "fill-outline-color": "#009F90",
            "fill-opacity": 1,
        },
    },
    // polygon outline
    {
        id: "gl-draw-polygon-stroke-static",
        type: "line",
        filter: ["all", ["==", "$type", "Polygon"], ["==", "mode", "static"]],
        layout: {
            "line-cap": "round",
            "line-join": "round",
        },
        paint: {
            "line-color": "#009F90",
            "line-width": 3,
        },
    },
];

export const centerElement = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
};
