import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
  getQueryStringEnhanced,
  handleError,
  
  onOpenProfile,
} from '../../utils/GeneralFunctions';
import { Request } from '../Api/index';
import { UPDATE_ACTION_POINT_PROPS } from './types';
import { local } from '../../lang/local';
import { getPointActions } from '../Api/apiCallsMethods';
import { getGiftsReportData } from '.';

export const setActionPointsProps = (propsArr) => async (dispatch) => {
  let payload = [];
  for (let i = 0; i < propsArr.length; i++) {
    payload.push({
      prop: propsArr[i][0],
      value: propsArr[i][1],
    });
  }

  dispatch({
    type: UPDATE_ACTION_POINT_PROPS,
    payload,
  });
};

export const getPointActionsForVisit = (searchForm) => (dispatch) => {
  const queries = {
    ids: searchForm.companyID,
    work_field_id: searchForm.workFieldIDs,
    worker_id: searchForm.workerID,
    point_id: searchForm.pointID,
    from: searchForm.dayStart,
    to: searchForm.dayEnd,
  };
  try {
    getPointActions(queries).then((res) => {
      console.log('response', res.data.data);
      const data = res.data.data.data[0];
      if (data) {
        dispatch({
          type: UPDATE_ACTION_POINT_PROPS,
          // payload: [{ prop: "pointActionForVisit", value: res.data.data }],
          payload: [{ prop: 'modaleView', value: { isOpen: true, data } }],
        });
      }

      let giftQueries = {
        workFields: [{ value: searchForm.workFieldIDs }],
        workFieldsPoints: searchForm.pointID,
        date: {
          from: searchForm.dayStart,
          to: searchForm.dayEnd,
        },
      };
      dispatch(getGiftsReportData(giftQueries, 1));
    });
  } catch (err) {
    handleError(err);
  }
};

export const getActionPoints = (isSearching, searchForm) => (dispatch) => {
  let url = '/points/actions';

  dispatch({
    type: UPDATE_ACTION_POINT_PROPS,
    payload: [{ prop: 'actionPointObj.actionPointObjItem', value: null }],
  });
  if (isSearching === false) {
    dispatch({
      type: UPDATE_ACTION_POINT_PROPS,
      payload: [{ prop: 'actionPointObj.isLoading', value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_ACTION_POINT_PROPS,
      payload: [{ prop: 'isLoadingSearch', value: true }],
    });
  }

  if (searchForm !== null) {
    // url += searchForm.work_field_id;

    let searchItems = null;
    if (searchForm.action === 'all') {
      searchItems = {
        from: searchForm.from,
        to: searchForm.to,
        worker_id: searchForm.worker_id,
        job_title: searchForm.job_title,

        action: 'true',

        page: searchForm.page,
      };
    } else {
      searchItems = {
        from: searchForm.from,
        to: searchForm.to,
        worker_id: searchForm.worker_id,
        job_title: searchForm.job_title,
        action: searchForm.action,
        page: searchForm.page,
        point_id: searchForm.point_id,
      };
    }

    if (searchForm.ids?.length > 0) {
      searchItems.work_field_id = searchForm.ids[0].id;
    }

    let newSearchForm = removeUnsetValues(searchItems);
    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryStringEnhanced(newSearchForm);
      if (searchForm.action === 'all') {
        param += '&action=false&action=not_take';
      }
      // var values = "";
      // searchForm.ids.map((d, i) => {
      //   if (i === 0) {
      //     values = "ids[]=" + d.value;
      //   } else {
      //     values += "&ids[]=" + d.value;
      //   }
      // });

      let values = `ids[]=${searchForm.company}`;
      url += param + '&' + values;
    }
  }

  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_ACTION_POINT_PROPS,
            payload: [
              {
                prop: 'actionPointObj.visit_successed',
                value: res.data.data.data.filter(
                  (c) => c.action.point === 'true'
                ).length,
              },
              {
                prop: 'actionPointObj.visit_failed',
                value: res.data.data.data.filter(
                  (c) => c.action.point === 'false'
                ).length,
              },
              {
                prop: 'actionPointObj.actionPointObjItem',
                value: res.data.data,
              },
            ],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_ACTION_POINT_PROPS,
        payload: [
          { prop: 'actionPointObj.isLoading', value: false },
          { prop: 'isLoadingSearch', value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_ACTION_POINT_PROPS,
        payload: [
          { prop: 'actionPointObj.isLoading', value: false },
          { prop: 'isLoadingSearch', value: false },
        ],
      });
    })
    .finally();
};
