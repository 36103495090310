import { Request } from "../Api/index";
import {
  DELETE_PRODUCTS_PROPS,
  MERGE_PRODUCTS_PROPS,
  UPDATE_PRODUCTS_PROPS,
} from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
  renderFormData,
  checkHasOneCompany,
  getMyCompany,
} from "../../utils/GeneralFunctions";

export const getProducts = (isSearching, searchForm) => (dispatch) => {
  dispatch({
    type: UPDATE_PRODUCTS_PROPS,
    payload: [{ prop: "productObj.productItem", value: null }],
  });

  let url = "/companies/products/";
  if (isSearching === false) {
    dispatch({
      type: UPDATE_PRODUCTS_PROPS,
      payload: [{ prop: "productObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_PRODUCTS_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  if (searchForm !== null) {
    url += searchForm.company;
    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      page: searchForm.page,
    };
    let newSearchForm = removeUnsetValues(searchItems);

    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }
  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_PRODUCTS_PROPS,
            payload: [{ prop: "productObj.productItem", value: res.data.data }],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_PRODUCTS_PROPS,
        payload: [
          { prop: "productObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_PRODUCTS_PROPS,
        payload: [
          { prop: "productObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getProductsForDropDown = (name) => (dispatch) => {
  Request()
    .get("/companies/products/")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: r.name,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_PRODUCTS_PROPS,
            payload: [{ prop: "productsOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateProduct =
  (formValues, index, options, isModal) => (dispatch) => {
    dispatch({
      type: UPDATE_PRODUCTS_PROPS,
      payload: [{ prop: "productForm.isLoading", value: true }],
    });
    let url = null;

    if (formValues.id === undefined) {
      url = "companies/products/store";
    } else {
      url = "companies/products/update/" + formValues.id;
    }

    let newFormValues = Object.assign({}, formValues);
    newFormValues = removeUnsetValues(newFormValues);

    let newFormData = renderFormData(newFormValues);

    const defaultCompanyID = checkHasOneCompany() ? getMyCompany() : null;

    Request()
      .post(url, newFormData)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);

            if (options !== null && isModal === true) {
              // dispatch({
              //   type: MERGE_PRODUCTS_PROPS,
              //   payload: [
              //     { prop: "productObj.productItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);

            if (options !== null && isModal === true) {
              dispatch({
                type: UPDATE_PRODUCTS_PROPS,
                payload: [
                  {
                    prop: "productObj.productItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }
          dispatch({
            type: UPDATE_PRODUCTS_PROPS,
            payload: [
              {
                prop: "productForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    name: "",
                    company_id: defaultCompanyID,
                    pharmaceutical_form: "",
                    composition: "",
                    pack: "",
                    indication: "",
                    price: "",
                    image: null,
                    pdf_file: null,
                  },
                  index: null,
                },
              },
              { prop: "productsOptions", value: [] },

              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],

                  activeIndex: 0,
                  tabAction: "next",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }

        dispatch({
          type: UPDATE_PRODUCTS_PROPS,
          payload: [{ prop: "productForm.isLoading", value: false }],
        });
      })
      .catch((err) => {
        dispatch({
          type: UPDATE_PRODUCTS_PROPS,
          payload: [{ prop: "productForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getProduct = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("'companies/products/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_PRODUCTS_PROPS,
              payload: [
                {
                  prop: "productForm.formvalues",
                  value: res.data.data,
                },
              ],
            });
          } else {
            let result = [
              { label: res.data.data.name, value: res.data.data.id },
            ];

            dispatch({
              type: UPDATE_PRODUCTS_PROPS,
              payload: [{ prop: "productsOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const deleteProduct = (id, index) => (dispatch) => {
  Request()
    .post("companies/products/delete/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        confirmSunccessed(local.SuccessfulDeleted);

        dispatch({
          type: DELETE_PRODUCTS_PROPS,
          payload: [
            {
              prop: "productObj.productItem.data." + index,
              value: res.data.data,
            },
          ],
        });
      } else {
        Object.values(res.data.error).map((msg) => {
          confirmSunccessed(msg[0]);
        });
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};
