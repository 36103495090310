import React, { Component } from 'react';
import { Card, Col, Row, Spinner, Form, Button } from 'reactstrap';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { UPDATE_STATE_WORK_FIELD_PROPS } from '../../redux/actions/types';
import {
  renderArabicNameInput,
  renderEnglishNameInput,
  renderPhoneInput,
  renderGeneralInput,
  renderArraw,
} from '../../utils/GeneralFunctions';
import { onSave } from './StateWorkFieldFunctions';
import { leftlStyle, rightStyle } from '../../utils/GeneralStyle';
import { FaFileExcel } from 'react-icons/fa';
import { renderStateSelect } from '../States/StateFunction';
import { unsetStateWFListData } from './StateWorkFieldFunctions';

class AddStateWF extends Component {
  componentDidMount() {
    unsetStateWFListData(this.props.general);

    this.props.getStatesForDropDown();
  }

  render() {
    const {
      stateWFForm,
      general,
      stateOptions,
      createORUpdateStateWF,
      history,
      loadStateOptions,
    } = this.props;

    return (
      <div className='page-wrapper'>
        <Row style={{ height: '100%' }}>
          <Col className='mt-4 body-container'>
            <Form
              onSubmit={(e) =>
                onSave(e, createORUpdateStateWF, stateWFForm, false)
              }
              style={{ height: '100%' }}
            >
              <Card className='cardCreate ml-2 mt-4 mr-2'>
                <div className='mt-5 mr-5 mt-3 ml-5'>
                  <Row>
                    <Col>
                      <p style={leftlStyle}>{local.AddStateWorkField}</p>
                    </Col>
                    {renderArraw(history)}
                  </Row>
                  <Row className='mt-5'>
                    <Col>
                      <p className='page_title mt-3' style={leftlStyle}>
                        {local.MainInfo}
                      </p>
                    </Col>
                    <Col>
                      <div style={rightStyle}>
                        <Button
                          className='place_btn'
                          onClick={(e) => history.push('/dashboard/add_area_from_file')}
                        >
                          <span style={{ fontSize: '10px' }}>
                            {local.AddFile}
                          </span>
                          &nbsp;
                          <span>
                            <FaFileExcel />
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>

                  <Row className='mt-5 justify-content-center justify-content-lg-start'>
                    <div className='mt-4 react-select-div'>
                      {renderEnglishNameInput(
                        'stateWFForm.formvalues.name.en',
                        stateWFForm.formvalues.name.en,
                        UPDATE_STATE_WORK_FIELD_PROPS,
                        general
                      )}
                    </div>
                    <div className='mt-4 react-select-div'>
                      {renderArabicNameInput(
                        'stateWFForm.formvalues.name.ar',
                        stateWFForm.formvalues.name.ar,
                        UPDATE_STATE_WORK_FIELD_PROPS,
                        general
                      )}
                    </div>
                    <div className='mt-4 react-select-div'>
                      {renderGeneralInput(
                        'stateWFForm.formvalues.address',
                        stateWFForm.formvalues.address,
                        UPDATE_STATE_WORK_FIELD_PROPS,
                        general,
                        local.Address
                      )}
                    </div>
                  </Row>
                  <Row className='justify-content-center justify-content-lg-start'>
                    <div className='mt-4 react-select-div'>
                      {renderPhoneInput(
                        'stateWFForm.formvalues.mobile',
                        stateWFForm.formvalues.mobile,
                        UPDATE_STATE_WORK_FIELD_PROPS,
                        general
                      )}
                    </div>
                    <div className='mt-4 react-select-div'>
                      {renderStateSelect(
                        'stateWFForm.formvalues.state_id',
                        stateWFForm.formvalues.state_id,
                        UPDATE_STATE_WORK_FIELD_PROPS,
                        general,
                        loadStateOptions,
                        stateOptions,
                        stateWFForm.isCreate
                      )}
                    </div>
                  </Row>

                  <center className='mt-5'>
                    <button
                      type='submit'
                      disabled={stateWFForm.isLoading}
                      className='save_btn mt-5'
                      onClick={(e) =>
                        onSave(e, createORUpdateStateWF, stateWFForm, false)
                      }
                    >
                      {stateWFForm.isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <div>
                          <p style={{ display: 'inline' }}>{local.Save} </p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </div>
                      )}
                    </button>
                  </center>
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ StateWFR, StateR }) => {
  return {
    stateWFForm: StateWFR.stateWFForm,
    stateOptions: StateR.stateOptions,
  };
};

export default connect(mapStateToProps, actions)(AddStateWF);
