import React, { Component } from "react";
import { Form, Card } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import FirstTab from "./FirstTab";
import { onSave } from "./BranchesFunction";
import { getMyCompany } from "../../utils/GeneralFunctions";
import { UPDATE_BRANCHES_PROPS } from "../../redux/actions/types";
class CreateUpdateBranch extends Component {
  componentDidMount() {
    // if (this.props.modale.actionType === "AddBranch") {
      this.props.general(
        [
          {
            prop: "branchForm.formvalues.company_id",
            value: getMyCompany(this.props.general),
          },
          {
            prop: "searchForm.company",
            value: getMyCompany(this.props.general),
          },
        ],
        UPDATE_BRANCHES_PROPS
      );
    // }
  }

  renderTabs = () => {
    const {
      branchForm,
      general,
      companyOptions,
      loadCompaniesOptions,
      createORUpdateBranch,
      setBranchProps
    } = this.props;

    return (
      <FirstTab
        general={general}
        branchForm={branchForm}
        loadCompaniesOptions={loadCompaniesOptions}
        companyOptions={companyOptions}
        createORUpdateBranch={createORUpdateBranch}
        setBranchProps={setBranchProps}
      />
    );
  };
  render() {
    const { modale, createORUpdateBranch, branchForm } = this.props;
    return (
      <Form onSubmit={(e) => onSave(e, createORUpdateBranch, branchForm, true)}>
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ BranchR, CompanyR }) => {
  return {
    branchForm: BranchR.branchForm,
    modale: BranchR.modale,
    companyObj: BranchR.companyObj,
    companyOptions: CompanyR.companyOptions,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateBranch);
