import React, { useState, Suspense, useEffect } from "react";
import "./layout.scss";
import { connect } from "react-redux";
import { Switch, Route } from "react-router-dom";
import routes from "../routes";
import * as actions from "../../redux/actions/index";
import { isAuth } from "../../utils/AuthenticateFunctions";
import { SERVICE_USER } from "../../utils/StaticKeys";
import logo from "../../assets/img/footer.png";
import { BreadCrumbs } from "./NewHeader/BreadCrumbs";

// const Header = React.lazy(() => import("./Header"));
const NewHeader = React.lazy(() => import("./NewHeader/NewHeader"));

const Sidebar = React.lazy(() => import("./Sidebar"));

function Layout({ history }) {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const [active, setActive] = useState("");

  const logout = (_e) => {
    // e.preventDefault();
    localStorage.removeItem(SERVICE_USER);
    window.location.reload("/");
  };

  useEffect(() => {
    if (history.location.pathname !== "/") {
      setActive(
        history.location.pathname.split("/")[
          history.location.pathname.split("/").length - 1
        ]
      );
    }
  }, []);

  useEffect(() => {
    history.listen((location) => {
      if (!isAuth() && location.pathname !== "") {
        logout();
      }
    });
  }, [history]);

  const handleCollapsedChange = () => {
    setCollapsed(!collapsed);
  };

  const handleToggleSidebar = () => {
    setToggled(!toggled);
  };
  const loading = () => (
    <div className="animated fadeIn pt-1 text-center">{/* <Loader /> */}</div>
  );
  const goToPage = (e, path, value) => {
    e.preventDefault();
    if (path) {
      history.push(path);
      setActive(value);
    }
  };

  const renderSideBar = () => {
    // if (history.location.pathname !== '/dashboard/home') {
    return (
      <Suspense fallback={loading()}>
        <div>
          <Sidebar
            collapsed={collapsed}
            toggled={toggled}
            handleToggleSidebar={handleToggleSidebar}
            active={active}
            goToPage={goToPage}
          />
          {/* <SideBar history={history} activeMenuItem='' /> */}
        </div>
      </Suspense>
    );
    // }
  };
  // const handleDisplyMenu = () => {
  //   // if (history.location.pathname === '/dashboard/home') {
  //   return true;
  //   // } else {
  //   //   return false;
  //   // }
  // };

  return (
    <div style={{ minHeight: "100vh" }}>
      <Suspense fallback={loading()}>
        <NewHeader
          history={history}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          logout={logout}
        />
        {/* <Header
          history={history}
          handleToggleSidebar={handleToggleSidebar}
          handleCollapsedChange={handleCollapsedChange}
          displayMenuBtn={handleDisplyMenu()}
          logout={logout}
        /> */}
      </Suspense>
      <div
        className={`app flex flex-col justify-between ${
          toggled ? "toggled" : ""
        }`}
        style={{ minHeight: "90vh" }}
      >
        <div className="flex">
          {renderSideBar()}

          <main className="ml-3 mr-3">
            <BreadCrumbs history={history} />
            <div className="block">
              <Switch>
                {routes.map((route) => {
                  let Component = route.component;
                  return (
                    <Route
                      key={route.url}
                      path={route.url}
                      name={route.key}
                      render={(props) => <Component {...props} />}
                      exact
                    />
                  );
                })}
              </Switch>
            </div>
          </main>
        </div>
        <footer className="flex flex-row-reverse">
          <div className="w-80">
            <img src={logo} alt="logo" className="w-full opacity-30" />
          </div>
        </footer>
      </div>
    </div>
  );
}

export default connect(null, actions)(Layout);
