import toaster from "toasted-notes";
import { local } from "../../lang/local";
import { UPDATE_STATE_PROPS } from "../../redux/actions/types";
import { AsyncPaginate } from "react-select-async-paginate";
import { labelStyle, selectStyle3 } from "../../utils/GeneralStyle";
import { getDeafultItem } from "../../utils/GeneralFunctions";
export const validateInputs = (stateForm) => {
  if (stateForm.formvalues.name.en === "") {
    toaster.notify(local.EnglishName, {
      duration: 2000,
    });
  }
  if (stateForm.formvalues.name.ar === "") {
    toaster.notify(local.ArabicName, {
      duration: 2000,
    });
  } else {
    return true;
  }
};

export const onSave = (e, createORUpdateState, stateForm, isModal) => {
  e.preventDefault();
  if (validateInputs(stateForm) === true) {
    createORUpdateState(stateForm.formvalues, stateForm.index, isModal);
  }
};

export const unsetForm = (general) => {
  general(
    [
      {
        prop: "stateForm",
        value: {
          isLoading: false,
          formvalues: {
            name: { ar: "", en: "" },
            parent_id: null,
          },
          index: null,
        },
      },
      { prop: "stateOptions", value: [] },
      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
        },
      },
    ],
    UPDATE_STATE_PROPS
  );
};

export const renderStateSelect = (
  prop,
  value,
  action,
  general,
  loadOptions,
  stateOptions,
  isCreate
) => {
  return (
    <>
      <AsyncPaginate
        key={value}
        options={
          isCreate !== undefined
            ? isCreate === "update"
              ? stateOptions
              : null
            : null
        }
        value={getDeafultItem(value, stateOptions)}
        placeholder={local.Province}
        className="mt-4"
        styles={selectStyle3}
        loadOptions={loadOptions}
        isClearable={true}
        onChange={(e) =>
          e !== null
            ? general([{ prop: prop, value: e.value }], action)
            : general([{ prop: prop, value: null }], action)
        }
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
      />
    </>
  );
};

export const unsetStateListData = (general) => {
  general(
    [
      {
        prop: "stateObj",
        value: { stateItem: null, isLoading: false, page: 1 },
      },
      {
        prop: "searchForm",
        value: { from: "", to: "", name: "", page: 1 },
      },
      { prop: "stateOptions", value: [] },
      { prop: "isLoadingSearch", value: false },
    ],
    UPDATE_STATE_PROPS
  );
};
