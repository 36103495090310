import { Request } from "../Api/index";
import { MERGE_USER_PROPS, UPDATE_USER_PROPS } from "./types";
import toaster from "toasted-notes";
import { getLanguage, local } from "../../lang/local";
import {
  getQueryString,
  removeUnsetValues,
  confirmSunccessed,
  checkHasOneCompany,
  setterGenerator,
  getMyCompany,
} from "../../utils/GeneralFunctions";
import { renderMyPermissionInCreate } from "../../pages/Users/UserFunctions";
console.log({ name: "mohab" });

export const setUserProps = (propsArr) =>
  setterGenerator(UPDATE_USER_PROPS, propsArr);

export const getUsers = (searchForm, isSearching) => (dispatch) => {
  dispatch({
    type: UPDATE_USER_PROPS,
    payload: [{ prop: "userObj.userItem", value: null }],
  });
  let url = "/users/";
  if (isSearching === false) {
    dispatch({
      type: UPDATE_USER_PROPS,
      payload: [{ prop: "userObj.isLoading", value: true }],
    });
  } else {
    dispatch({
      type: UPDATE_USER_PROPS,
      payload: [{ prop: "isLoadingSearch", value: true }],
    });
  }

  if (searchForm !== null) {
    url += searchForm.company;
    let searchItems = {
      from: searchForm.from,
      to: searchForm.to,
      page: searchForm.page,
    };
    let newSearchForm = removeUnsetValues(searchItems);

    if (
      Object.keys(newSearchForm).length !== 0 &&
      newSearchForm.constructor === Object
    ) {
      let param = getQueryString(newSearchForm);
      url += param;
    }
  }
  Request()
    .get(url)
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          dispatch({
            type: UPDATE_USER_PROPS,
            payload: [{ prop: "userObj.userItem", value: res.data.data }],
          });
        }
        if (isSearching === true && res.data.data.data.length === 0) {
          confirmSunccessed(local.NoSearch);
        }
      }
      dispatch({
        type: UPDATE_USER_PROPS,
        payload: [
          { prop: "userObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .catch((err) => {
      console.log(err);
      dispatch({
        type: UPDATE_USER_PROPS,
        payload: [
          { prop: "userObj.isLoading", value: false },
          { prop: "isLoadingSearch", value: false },
        ],
      });
    })
    .finally();
};

export const getWUsersForDropDown = (name) => (dispatch) => {
  Request()
    .get("/users")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          let result = [];
          res.data.data.data.map((r) => {
            result.push({
              label: r.name,
              value: r.id,
            });
          });
          dispatch({
            type: UPDATE_USER_PROPS,
            payload: [{ prop: "userOptions", value: result }],
          });
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};

export const createORUpdateUser =
  (formValues, index, permissions, options, isModal, arrayOptions) =>
  (dispatch) => {
    const defaultCompanyID = checkHasOneCompany() ? getMyCompany() : null;
    dispatch({
      type: UPDATE_USER_PROPS,
      payload: [{ prop: "userForm.isLoading", value: true }],
    });
    let url = null;

    let new_permissions = permissions.filter((per) => per.isSelected === true);
    new_permissions = new_permissions.map((per) => per.permission);

    let newFormValues = Object.assign({}, formValues);
    newFormValues.permissions = new_permissions;
    // newFormValues.companies = arrayOptions.companies.map((comp) => comp.value);
    let companiesSet = new Set(
      arrayOptions.companies.map((comp) => comp.value)
    );
    newFormValues.companies = [...companiesSet];
    // newFormValues.branches = arrayOptions.branches.map((bra) => bra.value);
    let branchesSet = new Set(arrayOptions.branches.map((bra) => bra.value));
    newFormValues.branches = [...branchesSet];
    // newFormValues.work_fields = arrayOptions.work_fields.map((wf) => wf.value);
    let workFieldsSet = new Set(arrayOptions.work_fields.map((wf) => wf.value));
    newFormValues.work_fields = [...workFieldsSet];

    if (formValues.id === undefined) {
      url = "/users/store";
    } else {
      url = "/users/update/" + newFormValues.id;
      delete newFormValues.api_token;
    }

    Request()
      .post(url, newFormValues)
      .then((res) => {
        if (res.data.status === true) {
          if (formValues.id === undefined) {
            confirmSunccessed(local.SuccessfullyAdded);

            if (options !== null && isModal === true) {
              // dispatch({
              //   type: MERGE_USER_PROPS,
              //   payload: [
              //     { prop: "userObj.userItem.data", value: res.data.data },
              //   ],
              // });
            }
          } else {
            confirmSunccessed(local.SuccessfullyEdit);

            if (options !== null && isModal === true) {
              dispatch({
                type: UPDATE_USER_PROPS,
                payload: [
                  {
                    prop: "userObj.userItem.data." + index,
                    value: res.data.data,
                  },
                ],
              });
            }
          }
          dispatch({
            type: UPDATE_USER_PROPS,
            payload: [
              {
                prop: "userForm",
                value: {
                  isLoading: false,
                  formvalues: {
                    password: "",
                    name: "",
                    email: "",
                    password_confirmation: "",
                    companies: null,
                    branches: null,
                    work_fields: null,
                    permissions: [],
                  },
                  arrayOptions: {
                    companies: [],
                    branches: [],
                    work_fields: [],
                    company: defaultCompanyID,
                    branch: null,
                  },
                  index: null,
                  iscreate: "create",
                },
              },
              {
                prop: "permissionsOptions",
                value: renderMyPermissionInCreate(),
              },
              // {
              //   prop: "CityOwnerPermissions",
              //   value: {name:"mohab"},
              // },
              { prop: "userOptions", value: [] },

              {
                prop: "modale",
                value: {
                  isOpen: false,
                  tobeConfirmed: false,
                  title: "",
                  actionType: "",
                  tabs_title: [
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                    {
                      title:
                        getLanguage() === "en"
                          ? "Main Information"
                          : "البيانات الرئيسية",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                    {
                      title:
                        getLanguage() === "en"
                          ? "System Data"
                          : "البيانات الخاصة بالنظام",
                      active: "active",
                      dots_pagination_active: "dots_pagination_active",
                    },
                  ],

                  activeIndex: 0,
                  tabAction: "next",
                },
              },
            ],
          });
        } else {
          Object.values(res.data.error).map((msg) => {
            toaster.notify(msg[0], {
              duration: 2000,
            });
          });
        }

        dispatch({
          type: UPDATE_USER_PROPS,
          payload: [{ prop: "userForm.isLoading", value: false }],
        });
      })
      .catch((err, x) => {
        dispatch({
          type: UPDATE_USER_PROPS,
          payload: [{ prop: "userForm.isLoading", value: false }],
        });
        console.log(err);
      })
      .finally();
  };

export const getUser = (id, dropDownItem) => (dispatch) => {
  Request()
    .get("/users/show/" + Number(id))
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (dropDownItem === false) {
            dispatch({
              type: UPDATE_USER_PROPS,
              payload: [
                {
                  prop: "userForm.formvalues",
                  value: res.data.data,
                },
              ],
            });
          } else {
            let result = [
              { label: res.data.data.name, value: res.data.data.id },
            ];

            dispatch({
              type: UPDATE_USER_PROPS,
              payload: [{ prop: "userOptions", value: result }],
            });
          }
        }
      }
    })
    .catch((err) => {
      console.log(err);
    })
    .finally();
};
