import { getLanguage, local } from '../../lang/local';
import {
  CLEAR_REQUESTS_STATE,
  UPDATE_COMPANY_PROPS,
  UPDATE_REQUESTS_PROPS,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
} from '../../redux/actions/types';
import toaster from 'toasted-notes';
import Flatpickr from 'react-flatpickr';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { labelStyle, selectStyle3, leftlStyle } from '../../utils/GeneralStyle';

import { getDeafultItem, handleError } from '../../utils/GeneralFunctions';
import { AsyncPaginate } from 'react-select-async-paginate';
import { Col, Button } from 'reactstrap';
import Select from 'react-select';
import { MdMyLocation } from 'react-icons/md';

export const unsetForm = (general) => {
  general(
    [
      {
        prop: 'pointForm',
        value: {
          isLoading: false,
          formvalues: {
            visit_type: null,
            point_id: null,
          },
        },
      },
      {
        prop: 'pointForm',
        value: {
          isLoading: false,
          formvalues: {
            work_field_id: null,
            location: null,
            terminal_id: null,
            place_type: null,
            merchant_id: null,
            name: '',
            workers: null,
            visit_type: null,
            properties: {
              type: null,
              address: '',
              point_radius: '',
              mobile: [],
              link: '',
            },
            data: [], // array of {id: number, name: string, workingDays: {day: {from: string, to: string}}} I can change this to whatever I want
          },
          index: null,
          company: null,
          branch_id: null,

          iscreate: 'create',
          work_fields_points: [],
        },
      },
      { prop: 'pointsOptions', value: [] },

      {
        prop: 'modale',
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: '',
          actionType: '',
          tabs_title: [
            {
              title: local.MainInfo,
              active: 'active',
              dots_pagination_active: 'dots_pagination_active',
            },
            {
              title: local.WorkPlaces,
              active: '',
              dots_pagination_active: 'dots_pagination_active',
            },
            {
              title:
                getLanguage() === 'en' ? 'Point Properties' : 'خصائص النقطة',

              active: '',
              dots_pagination_active: 'dots_pagination_active',
            },
          ],
          activeIndex: 0,
          tabAction: 'next',
          mapItem: {
            polygon: [],
            mapPoints: [31.4177636, 30.00475],
          },
        },
      },
    ],
    UPDATE_REQUESTS_PROPS
  );
};

export const validateInputs = (pointForm) => {
  if (pointForm.formvalues.location === null) {
    toaster.notify(local.LocationPointRequired, {
      duration: 2000,
    });
  }
  if (pointForm.formvalues.work_field_id === null) {
    toaster.notify(local.SelectWorkField, {
      duration: 2000,
    });
  }

  if (pointForm.formvalues.name === '') {
    toaster.notify(local.NameRequired, {
      duration: 2000,
    });
  }

  if (!pointForm.formvalues.merchant_id) {
    toaster.notify(local.MerchantRequired, {
      duration: 2000,
    });
  }

  if (!pointForm.formvalues.terminal_id) {
    toaster.notify(local.TerminalRequired, {
      duration: 2000,
    });
  }
  if (pointForm.formvalues.properties.mobile.length <= 11) {
    toaster.notify(local.MobileRequired, {
      duration: 2000,
    });
  }

  if (
    pointForm.formvalues.name !== '' &&
    pointForm.formvalues.work_field_id !== null &&
    pointForm.formvalues.location !== null &&
    pointForm.formvalues.merchant_id &&
    pointForm.formvalues.terminal_id &&
    pointForm.formvalues.properties.mobile.length > 11
  ) {
    return true;
  }
};

export const onSave = (
  e,
  createORUpdatePoint,
  pointForm,
  requestsObj,
  isModal,
  companyWFOptions
) => {
  e.preventDefault();

  if (validateInputs(pointForm) === true) {
    pointForm.formvalues.properties.type = 'status';

    createORUpdatePoint(
      pointForm.formvalues,
      pointForm.index,
      requestsObj.requestsItem,
      isModal,
      companyWFOptions
    );
  }
};

export const unsetCompanyWFPListData = (general) => {
  general([], CLEAR_REQUESTS_STATE);
  general(
    [
      {
        prop: 'companyWfOptions',
        value: [],
      },
    ],
    UPDATE_COMPANY_WORK_FIELD_PROPS
  );
  general(
    [
      {
        prop: 'companyOptions',
        value: [],
      },
    ],
    UPDATE_COMPANY_PROPS
  );
};

export const renderWorkFieldPointSelect = (
  prop,
  value,
  action,
  general,
  loadpointsOptions,
  pointsOptions,
  isMutli,
  id,
  col,
  emptyProp
) => {
  console.log('value', value);
  return (
    <>
      <AsyncPaginate
        // options={pointsOptions}
        key={value}
        value={
          isMutli === null
            ? value !== null && value !== undefined
              ? typeof value === 'number'
                ? getDeafultItem(value, pointsOptions)
                : getDeafultItem(value?.value, pointsOptions)
              : value
            : value
        }
        placeholder={local.WorkFieldPoint}
        className={col !== null ? 'mt-' + col : null}
        isMulti={isMutli === null ? false : true}
        styles={selectStyle3}
        loadOptions={(name, loadedOptions, page) =>
          Array.isArray(id) === true
            ? loadpointsOptions(name, loadedOptions, page, id)
            : typeof id == 'number'
            ? loadpointsOptions(name, loadedOptions, page, id)
            : loadpointsOptions(name, loadedOptions, page, id.value)
        }
        isClearable={true}
        onChange={(e) =>
          onChangePoint(e?.value, prop, action, general, emptyProp)
        }
        getOptionLabel={(op) => op.label}
        getOptionValue={(op) => op.value}
        additional={{
          page: 1,
        }}
        cacheUniqs={[id]}
        onFocus={() =>
          id === null
            ? toaster.notify(local.SelectWorkField, {
                duration: 2000,
              })
            : null
        }
      />
    </>
  );
};

export const onChangePoint = (e, prop, action, general, emptyProp) => {
  if (e !== null) {
    general([{ prop: prop, value: e }], action);
  } else {
    general([{ prop: prop, value: emptyProp }], action);
  }
};

export const renderDays = (prop, items, general, dayOptions) => {
  if (items !== undefined) {
    if (items !== null) {
      if (items.length > 0) {
        return items.map((item, index) => {
          return (
            <>
              <div className='react-select-div d-flex flex-column' key={index}>
                <span
                  className='label_title mx-2'
                  style={{ ...labelStyle, textAlign: 'start' }}
                >
                  {item.label}
                </span>
                <Select
                  className='mt-1'
                  value={item.data.days}
                  key={item.data.days}
                  options={dayOptions}
                  styles={selectStyle3}
                  isMulti={true}
                  placeholder={local.WorkDays}
                  onChange={(e) => onChangeDay(prop, e, general, index)}
                />
              </div>
              {item.data.days?.length > 0
                ? item.data.days.map((day, i) => {
                    console.log('day', day);
                    return (
                      <div className='d-flex'>
                        <Col xl={3} key={index} className='mt-2'>
                          <span className='label_title' style={labelStyle}>
                            {item.label} - {day.label} - {local.WorkingHours}
                          </span>

                          <Flatpickr
                            className='dateStyle mt-2'
                            data-enable-time
                            value={day.from}
                            onChange={(time) => {
                              general(
                                [
                                  {
                                    prop:
                                      prop +
                                      index +
                                      '.data.days.' +
                                      i +
                                      '.from',
                                    value: time[0].toLocaleTimeString(),
                                  },
                                ],
                                UPDATE_REQUESTS_PROPS
                              );
                            }}
                            local={getLanguage() === 'en' ? null : Arabic}
                            placeholder={local.From}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'G:i:K',
                              allowInput: true,
                            }}
                          />
                        </Col>
                        <Col xl={3} key={index} className='mt-2'>
                          <span className='label_title' style={labelStyle}>
                            {item.label} - {day.label} - {local.WorkingHours}
                          </span>
                          <Flatpickr
                            className='dateStyle mt-2'
                            data-enable-time
                            value={day.to}
                            onChange={(time) => {
                              general(
                                [
                                  {
                                    prop:
                                      prop + index + '.data.days.' + i + '.to',
                                    value: time[0].toLocaleTimeString(),
                                  },
                                ],
                                UPDATE_REQUESTS_PROPS
                              );
                            }}
                            local={getLanguage() === 'en' ? null : Arabic}
                            placeholder={local.To}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'G:i:K',
                              allowInput: true,
                            }}
                          />
                        </Col>

                        <Col xl={3} key={index} className='mt-2'>
                          <span className='label_title' style={labelStyle}>
                            {item.label} - {day.label} - {local.AvailableHours}
                          </span>

                          <Flatpickr
                            className='dateStyle mt-2'
                            data-enable-time
                            value={day.availableHours_from}
                            onChange={(time) => {
                              general(
                                [
                                  {
                                    prop:
                                      prop +
                                      index +
                                      '.data.days.' +
                                      i +
                                      '.availableHours_from',
                                    value: time[0].toLocaleTimeString(),
                                  },
                                ],
                                UPDATE_REQUESTS_PROPS
                              );
                            }}
                            local={getLanguage() === 'en' ? null : Arabic}
                            placeholder={local.From}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'G:i:K',
                              allowInput: true,
                            }}
                          />
                        </Col>
                        <Col xl={3} key={index} className='mt-2'>
                          <span className='label_title' style={labelStyle}>
                            {item.label} - {day.label} - {local.AvailableHours}
                          </span>
                          <Flatpickr
                            className='dateStyle mt-2'
                            data-enable-time
                            value={day.availableHours_to}
                            onChange={(time) => {
                              general(
                                [
                                  {
                                    prop:
                                      prop +
                                      index +
                                      '.data.days.' +
                                      i +
                                      '.availableHours_to',
                                    value: time[0].toLocaleTimeString(),
                                  },
                                ],
                                UPDATE_REQUESTS_PROPS
                              );
                            }}
                            local={getLanguage() === 'en' ? null : Arabic}
                            placeholder={local.To}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: 'G:i:K',
                              allowInput: true,
                            }}
                          />
                        </Col>
                      </div>
                    );
                  })
                : null}
            </>
          );
        });
      }
    }
  }
};

export const onChangeDay = (prop, e, general, index) => {
  if (e !== null) {
    let days = [];

    e.map((d) => {
      d.from = '';
      d.to = '';
      days.push(d);
    });
    general(
      [{ prop: prop + index + '.data.days', value: days }],
      UPDATE_REQUESTS_PROPS
    );
  } else {
    general(
      [{ prop: prop + index + '.data.days', value: [] }],
      UPDATE_REQUESTS_PROPS
    );
  }
};

export const getMyLocation = (general) => {
  navigator.geolocation.getCurrentPosition(function (position) {
    general(
      [
        {
          prop: 'pointForm.formvalues.location',
          value:
            'POINT(' +
            position.coords.longitude +
            ' ' +
            position.coords.latitude +
            ')',
        },
      ],
      UPDATE_REQUESTS_PROPS
    );
  }, showError);
};
function showError(error) {
  switch (error.code) {
    case error.PERMISSION_DENIED:
      toaster.notify(local.UserDenied, {
        duration: 2000,
      });
      break;
    case error.POSITION_UNAVAILABLE:
      toaster.notify(local.UnavailableLoc, {
        duration: 2000,
      });
      break;
    case error.TIMEOUT:
      toaster.notify(local.LocationTimedOut, {
        duration: 2000,
      });
      break;
    case error.UNKNOWN_ERROR:
      toaster.notify(local.UnknownError, {
        duration: 2000,
      });
      break;
    default:
      return;
  }
}

export const filterEmployeesByWorkField = (option, rawInput, workFieldID) => {
  const employeeBelongsToWorkField = option?.work_fields.some(
    (workField) => workField.id === workFieldID
  );
  return employeeBelongsToWorkField;
};

export const renderMyLocationButton = (general) => {
  return (
    <div style={{ display: window.innerWidth < 720 ? null : 'none' }}>
      <Button
        className='btn_add_wfield location_btn'
        onClick={(e) => getMyLocation(general)}
        style={leftlStyle}
      >
        <div>
          <MdMyLocation style={{ fontSize: '25px' }} />
        </div>
      </Button>
    </div>
  );
};

export const zoomToUserLocation = (setRequestsProps) => {
  const onSuccess = (geoLocationPosition) => {
    const { latitude, longitude } = geoLocationPosition.coords;
    setRequestsProps([['modale.mapItem.mapPoints', [31.4177636, 30.00475]]]);
    setRequestsProps([['modale.mapItem.mapPoints', [latitude, longitude]]]);
  };
  const onError = (geoLocationPositionError) => {
    handleError(geoLocationPositionError.message);
  };
  navigator.geolocation.getCurrentPosition(onSuccess, onError, {
    enableHighAccuracy: true,
    timeout: 5000,
  });
};

export const ZoomToUserLocationBtn = ({ setRequestsProps }) => {
  return (
    <div>
      <Button
        className='btn_add_wfield location_btn'
        onClick={(e) => zoomToUserLocation(setRequestsProps)}
        style={leftlStyle}
      >
        <div>
          <MdMyLocation style={{ fontSize: '25px' }} />
        </div>
      </Button>
    </div>
  );
};

// export const onWorkFieldChangeCB = (workField, setMapBounds) => {
//   const workFieldPolygon = workField.location;
//   const geoJsonPoly = WKTtoGeoJson(workFieldPolygon);
//   const latLngReversedPoly = reverseLatLngBoundsOrder(geoJsonPoly);
//   setMapBounds(latLngReversedPoly);
// };

export const onWorkFieldChangeCB = (workField, setRequestsProps) => {
  if (workField) {
    const workFieldPolygon = workField.location;
    setRequestsProps([['polygonItem', workFieldPolygon]]);
  } else {
    setRequestsProps([
      ['polygonItem', ''],
      ['pointForm.formvalues.location', ''],
    ]);
  }
};
