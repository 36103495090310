import { isArray } from "../../utils/GeneralFunctions";
import { getLanguage } from "../../lang/local";

export const SelectEmployeeWorkFields = (
  coverageListReducer,
  workerReducer
) => {
  const employeeOptions = workerReducer.workerOptions;
  const employeeID = coverageListReducer.selectedEmployee;
  if (!employeeID) return [];
  if (!employeeOptions) return [];
  if (!isArray(employeeOptions)) return [];
  if (employeeOptions.length === 0) return [];

  const selectedEmployee = employeeOptions.filter(
    (employee) => employeeID === employee.value
  );
  if (selectedEmployee.length === 0) return [];
  const employeeWorkFields = selectedEmployee[0].work_fields;
  const employeeWorkFieldsOptions = employeeWorkFields.map((workField) => ({
    ...workField,
    label: workField.name[getLanguage()],
    value: workField.id,
  }));
  return employeeWorkFieldsOptions;
};
