import Home from "../pages/Home/Home";
import State from "../pages/States/States";
import AddState from "../pages/States/AddState";
import AddStateFile from "../pages/States/AddStateFile";
import StateWorkFields from "../pages/StateWorkFields/StateWorkFields";
import AddStateWF from "../pages/StateWorkFields/AddStateWF";
import AddStateWFFile from "../pages/StateWorkFields/AddStateWFFile";
import Compaines from "../pages/Compaines/Compaines";
import AddCompany from "../pages/Compaines/AddCompany";
import AddCompanyFile from "../pages/Compaines/AddCompanyFile";
import CompainesWorkFields from "../pages/CompainesWorkFields/CompainesWorkFields";
import AddCompainesWF from "../pages/CompainesWorkFields/AddCompainesWF";
import Requests from "../pages/Requests/Requests";
import AddPoint from "../pages/Requests/AddPoint";
import Workers from "../pages/Workers/Workers";
import AddWorker from "../pages/Workers/AddWorker";
// import AddWorkerFile from "../pages/Workers/AddWorkerFile";
import AddWorkerFile from "../pages/Workers/AddWorkerFile";
import WorkerProfile from "../pages/Workers/WorkerProfile";
import Reports from "../pages/Reports/Reports";
import ReportTemplete from "../pages/ReportTemplete/ReportTemplete";
import AddReport from "../pages/ReportTemplete/AddReport";
import Users from "../pages/Users/Users";
import AddUser from "../pages/Users/AddUser";
import Archives from "../pages/Archives/Archives";
import LiveTracking from "../pages/LiveTracking/LiveTracking";
import OuterWorker from "../pages/LiveTracking/OuterWorker";
import ActionPoints from "../pages/ActionPoints/ActionPoints";
import ShowReport from "../pages/Reports/ShowReport";
import Visits2 from "../pages/Visits/Visits2";
import Branches from "../pages/Branches/Branches";
import AddBranch from "../pages/Branches/AddBranch";
import Workers2 from "../pages/Workers/Workers2";
import Histories from "../pages/Histories/Histories";
import WorkReport from "../pages/WorkReport/WorkReport";
import CoverageList from "../pages/CoverageList/CoverageList";
import AddOrRemoveFromCoverageList from "../pages/CoverageList/AddOrRemoveFromCoverageList";
import home_inactive from "../assets/img/home-new-icons/home.jpeg";
import cities from "../assets/img/cities.jpeg";
import employees from "../assets/img/employees.jpeg";
import orders from "../assets/img/orders.png";
import place_icon_inactive from "../assets/img/place_icon_inactive.png";
import company_inactive from "../assets/img/compaines.jpeg";
import work_field from "../assets/img/work-field.jpeg";
import user_icon_inactive from "../assets/img/users.jpeg";
import track_inactive from "../assets/img/track_inactive.png";
import product from "../assets/img/product.png";
import visit from "../assets/img/visit.png";
import NewPage from "../pages/NewPage/NewPage";
import Ticket from "../pages/Requests/Ticket";
import NewHome from "../pages/NewHome/NewHome";
import BusinessIcon from "@mui/icons-material/Business";
import GridViewIcon from "@mui/icons-material/GridView";
import CropIcon from "@mui/icons-material/Crop";
import ApartmentIcon from "@mui/icons-material/Apartment";
import RoomIcon from "@mui/icons-material/Room";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import PersonIcon from "@mui/icons-material/Person";
import GroupIcon from "@mui/icons-material/Group";
import ListAltIcon from "@mui/icons-material/ListAlt";

const routes = [
  {
    url: "/dashboard/home",
    component: NewHome,
    permissions: ["home"],
    key: "home",
    toSidebar: true,
    name: "Dashboard",
    // icon: <img src={home_inactive} alt="" width="15" height="15" />,
    icon: <GridViewIcon />,
  },
  //rendering Ticket
  {
    url: "/dashboard/Ticket",
    component: Ticket,
    // permissions: ["Ticket"],
    key: "Ticket",
    toSidebar: false,
    name: "Ticket",
    // icon: <img src={home_inactive} alt="" width="15" height="15" />,
  },

  {
    url: "/dashboard/states",
    component: State,
    permissions: ["states"],
    key: "states",
    toSidebar: true,
    children: true,
    mainName: "States",
    name: "ViewStates",
    // icon: <img src={cities} width="20" height="20" alt="" />,
    icon: <BusinessIcon />,
  },
  {
    url: "/dashboard/addstate",
    component: AddState,
    permissions: ["states/store"],
    key: "addstate",
    toSidebar: true,
    parent: "states",
    name: "AddState",
  },
  {
    url: "/dashboard/viewstate",
    component: AddState,
    permissions: ["states/show/{id}"],
    key: "viewstate",
    toSidebar: false,
  },
  {
    url: "/dashboard/areas",
    component: StateWorkFields,
    permissions: ["states/work_fields"],
    key: "areas",
    toSidebar: true,
    parent: "states",
    name: "ViewStateWorkFields",
  },
  {
    url: "/dashboard/addarea",
    component: AddStateWF,
    permissions: ["states/work_fields/store"],
    key: "addarea",
    toSidebar: true,
    parent: "states",
    name: "AddStateWorkField",
  },
  {
    url: "/dashboard/viewarea",
    component: AddStateWF,
    permissions: ["states/work_fields/show/{id}"],
    key: "viewarea",
    toSidebar: false,
  },
  {
    url: "/dashboard/compaines",
    component: Compaines,
    permissions: ["companies", "all_companies"],
    key: "compaines",
    toSidebar: true,
    children: true,
    mainName: "Branches",
    name: "ViewCompaines",
    // icon: <img src={company_inactive} alt="" width="15" height="15" />,
    icon: <ApartmentIcon />,
  },
  {
    url: "/dashboard/addcompany",
    component: AddCompany,
    permissions: ["companies/store"],
    key: "addcompany",
    toSidebar: true,
    parent: "compaines",
    name: "AddCompany",
  },
  {
    url: "/dashboard/viewcompany",
    component: AddCompany,
    permissions: ["companies/show/{id}"],
    key: "viewcompany",
    toSidebar: false,
  },
  {
    url: "/dashboard/branches",
    component: Branches,
    permissions: ["companies/branches/{company_id}"],
    key: "branches",
    toSidebar: true,
    parent: "compaines",
    name: "ViewBranches",
  },
  {
    url: "/dashboard/addbranch",
    component: AddBranch,
    permissions: ["companies/branches/store"],
    key: "addbranch",
    toSidebar: true,
    parent: "compaines",
    name: "AddBranch",
  },
  {
    url: "/dashboard/company_work_fields",
    component: CompainesWorkFields,
    permissions: ["companies/{id}/work_fields"],
    key: "company_work_fields",
    toSidebar: true,
    children: true,
    mainName: "Zones",
    name: "Zones",
    // icon: <img src={work_field} width="20" height="20" alt="" />,
    icon: <CropIcon />,
  },
  {
    url: "/dashboard/add_company_work_field",
    component: AddCompainesWF,
    permissions: ["companies/work_fields/store"],
    key: "add_company_work_field",
    toSidebar: true,
    parent: "company_work_fields",
    name: "AddACompanyWF",
  },
  {
    url: "/dashboard/view_company_work_field",
    component: AddCompainesWF,
    permissions: ["companies/work_fields/show/{id}"],
    key: "view_company_work_field",
    toSidebar: false,
  },

  {
    url: "/dashboard/view_worker",
    component: WorkerProfile,
    permissions: ["workers/show/{id}"],
    key: "view_worker",
    toSidebar: false,
  },

  {
    url: "/dashboard/view_place_visit",
    component: AddPoint,
    permissions: ["companies/work_fields/points/show/{id}"],
    key: "view_place_visit",
    toSidebar: false,
  },

  {
    url: "/dashboard/new_page",
    component: NewPage,
    permissions: [],
    key: "new_page",
    toSidebar: true,
    parent: "visits",
    name: "NewPage",
  },

  {
    url: "/dashboard/visits2",
    component: Visits2,
    permissions: ["workers_points/{company_id}"],
    key: "visits2",
    toSidebar: false,
    // parent:true
    mainName: "Visits",
    name: "ViewVisits",
    icon: <img src={visit} alt="" width="15" height="15" />,
  },

  // {
  //   url: '/dashboard/products',
  //   component: Products,
  //   permissions: ['companies/products/{company_id}'],
  //   key: 'products',
  //   toSidebar: true,
  //   children: true,
  //   mainName: 'Products',
  //   name: 'ViewProducts',
  //   icon: <img src={product} alt='' width='15' height='15' />,
  // },

  // {
  //   url: '/dashboard/add_product',
  //   component: AddProduct,
  //   permissions: ['companies/products/store'],
  //   key: 'add_product',
  //   toSidebar: true,
  //   parent: 'products',
  //   name: 'AddProduct',
  // },

  // {
  //   url: '/dashboard/products_amount',
  //   component: Amount,
  //   permissions: ['products/amounts'],
  //   key: 'products_amount',
  //   toSidebar: true,
  //   parent: 'products',
  //   name: 'ProductAmount',
  // },

  // {
  //   url: '/dashboard/gifts-and-samples',
  //   component: GiftsAndSamples,
  //   permissions: ['companies/gifts/{company_id}'],
  //   key: 'gifts-and-samples',
  //   toSidebar: true,
  //   children: true,
  //   mainName: 'Gifts_And_Samples',
  //   name: 'Gifts_And_Samples',
  //   icon: <img src={GIFTS_ICON} alt='' width='15' height='15' />,
  // },
  // {
  //   url: '/dashboard/add-gifts-and-samples',
  //   component: AddGiftPage,
  //   permissions: ['companies/gifts/{company_id}', 'companies/gifts/store'],
  //   key: 'add-gifts-and-samples',
  //   toSidebar: true,
  //   parent: 'gifts-and-samples',
  //   name: 'AddGiftOrSample',
  // },

  {
    url: "/dashboard/live_tracking",
    component: LiveTracking,
    permissions: ["histories/active/{work_field_id}"],
    key: "live_tracking",
    toSidebar: true,
    children: true,
    mainName: "TrackingAndLog",
    name: "LiveTracking",
    // icon: <img src={track_inactive} alt="" width="20" height="20" />,
    icon: <RoomIcon />,
  },
  {
    url: "/dashboard/histories",
    component: Histories,
    permissions: ["histories/worker/{worker_id}"],
    key: "histories",
    toSidebar: true,
    parent: "live_tracking",
    name: "HistoryTracking",
  },

  {
    url: "/dashboard/view_user",
    component: AddUser,
    permissions: ["users/show/{id}"],
    key: "view_user",
    toSidebar: false,
  },

  {
    url: "/dashboard/worker_out_of_area",
    component: OuterWorker,
    permissions: ["histories/active/{work_field_id}"],
    key: "worker_out_of_area",
    toSidebar: false,
  },
  // {
  //   url: '/dashboard/notifications',
  //   component: Notifications,
  //   permissions: ['workers/notify'],
  //   toSidebar: true,
  //   key: 'notifications',
  //   name: 'Notifications',
  //   icon: <img src={Notification_inactive} alt='' width='15' height='15' />,
  // },

  {
    url: "/dashboard/add_state_from_file",
    component: AddStateFile,
    permissions: ["states/store"],
    key: "add_state_from_file",
    toSidebar: false,
  },
  {
    url: "/dashboard/add_company_from_file",
    component: AddCompanyFile,
    permissions: ["companies/store"],
    key: "add_company_from_file",
    toSidebar: false,
  },
  {
    url: "/dashboard/add_area_from_file",
    component: AddStateWFFile,
    permissions: ["states/store"],
    key: "add_area_from_file",
    toSidebar: false,
  },
  {
    url: "/dashboard/add_worker_from_file",
    component: AddWorkerFile,
    permissions: ["workers/store"],
    key: "add_worker_from_file",
    toSidebar: false,
  },

  {
    url: "/dashboard/coverage-list",
    component: CoverageList,
    permissions: ["workers-work-points/{company_id}"],
    key: "coverage-list",
    toSidebar: true,
    children: true,
    name: "CoverageList",
    // icon: <img src={product} alt="" width="15" height="15" />,
    icon: <FactCheckIcon />,
  },
  {
    url: "/dashboard/addTo_or_removeFrom_coverageList",
    component: AddOrRemoveFromCoverageList,
    permissions: ["workers-work-points/{company_id}"],
    key: "/addTo_or_removeFrom_coverageList",
    toSidebar: true,
    parent: "coverage-list",
    name: "AddToOrRemoveFromCoverageList",
    icon: <img src={place_icon_inactive} width="20" height="20" alt="" />,
  },
  {
    url: "/dashboard/workers",
    component: Workers,
    permissions: ["workers/{company_id}"],
    key: "workers",
    toSidebar: true,
    children: true,
    mainName: "Workers",
    name: "ViewEmployees",
    // icon: <img src={employees} alt="" width="15" height="15" />,
    icon: <GroupIcon />,
  },
  {
    url: "/dashboard/workers2",
    component: Workers2,
    permissions: ["workers/{company_id}"],
    key: "workers2",
    toSidebar: false,
    children: true,
    mainName: "Workers",
    name: "ViewEmployees",
    // icon: <img src={user_icon_inactive} alt="" width="15" height="15" />,
    icon: <PersonIcon />,
  },
  {
    url: "/dashboard/add_worker",
    component: AddWorker,
    permissions: ["workers/store"],
    key: "add_worker",
    toSidebar: true,
    parent: "workers",
    name: "AddWorker",
  },
  {
    url: "/dashboard/users",
    component: Users,
    permissions: ["users/{company_id}"],
    key: "users",
    toSidebar: true,
    children: true,
    mainName: "Users",
    name: "ViewUser",
    // icon: <img src={user_icon_inactive} alt="" width="15" height="15" />,
    icon: <PersonIcon />,
  },
  {
    url: "/dashboard/add_user",
    component: AddUser,
    permissions: ["users/store"],
    key: "add_user",
    toSidebar: true,
    parent: "users",
    name: "AddUser",
  },
  {
    url: "/dashboard/requests",
    component: Requests,
    permissions: ["requests", "companies/work_fields/points"],
    key: "requests",
    toSidebar: true,
    children: true,
    mainName: "CompaniesWorkFieldPoint",
    name: "ViewCompaniesWorkFieldPoint",
    // icon: <img src={orders} width="20" height="20" alt="" />,
    icon: <ListAltIcon />,
  },

  {
    url: "/dashboard/addpoint",
    component: AddPoint,
    permissions: ["requests/store", "companies/work_fields/points/store"],
    key: "addpoint",
    toSidebar: true,
    parent: "requests",
    name: "AddCompanyWFPoint",
  },

  // {
  //   url: "/dashboard/visits",
  //   component: Visits,
  //   permissions: ["workers_points/{company_id}"],
  //   key: "visits",
  //   toSidebar: true,
  //   children: true,
  //   mainName: "Visits",
  //   name: "ViewVisits",
  //   icon: <img src={visit} alt="" width="15" height="15" />,
  // },

  // {
  //   url: "/dashboard/add_visit",
  //   component: AddVisit,
  //   permissions: ["workers_points/store/{id}"],
  //   key: "add_visit",
  //   toSidebar: false,
  //   parent: "visits",
  //   name: "AddVisit",
  // },
  // {
  //   url: "/dashboard/visits_plan",
  //   component: VisitsPlan,
  //   permissions: ["workers_points/{company_id}"],
  //   key: "visits_plan",
  //   toSidebar: true,
  //   parent: "visits",
  //   name: "ViewVisitsPlan",
  // },
  // {
  //   url: "/dashboard/place_visits_report",
  //   component: VisitsSpotsReport,
  //   permissions: ["companies/work_fields/points"],
  //   key: "place_visits_report",
  //   toSidebar: true,
  //   children: true,
  //   mainName: "Reports",
  //   name: "VisitSpotsReport",
  //   icon: <img src={report_icon_inactive} alt="" width="20" height="20" />,
  // },
  {
    url: "/dashboard/view_daily_report",
    component: ShowReport,
    permissions: ["reports"],
    key: "view_daily_report",
    toSidebar: false,
    parent: "place_visits_report",
  },

  {
    url: "/dashboard/action_points",
    component: ActionPoints,
    permissions: ["points/actions"],
    key: "action_points",
    toSidebar: true,
    parent: "place_visits_report",
    name: "ActionPoints",
  },
  {
    url: "/dashboard/daily_report",
    component: Reports,
    permissions: ["reports"],
    key: "daily_report",
    toSidebar: true,
    parent: "place_visits_report",
    name: "DayReports",
  },

  {
    url: "/dashboard/archives",
    component: Archives,
    permissions: ["archives"],
    key: "archives",
    toSidebar: true,
    parent: "place_visits_report",
    name: "FastReport",
  },

  {
    url: "/dashboard/work_report",
    component: WorkReport,
    permissions: ["histories"],
    key: "work_report",
    toSidebar: true,
    parent: "place_visits_report",
    name: "WorkReport",
  },

  {
    url: "/dashboard/report_templete",
    component: ReportTemplete,
    permissions: ["companies/work_fields/{id}/reports"],
    key: "report_templete",
    toSidebar: true,
    parent: "place_visits_report",
    name: "ViewReportTemplete",
  },
  {
    url: "/dashboard/add_report_templete",
    component: AddReport,
    permissions: ["companies/work_fields/{id}/reports/store"],
    key: "add_report_templete",
    toSidebar: true,
    parent: "place_visits_report",
    name: "addReport",
  },
];

export default routes;
