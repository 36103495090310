import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import UsersTable from './UsersTable';
import * as actions from '../../redux/actions/index';
import { getMyCompany } from '../../utils/GeneralFunctions';
import UserSearch from './UserSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateUser from './CreateUpdateUser';
import { UPDATE_USER_PROPS } from '../../redux/actions/types';
import { unsetForm, unsetUserListData } from './UserFunctions';
class Users extends Component {
  componentDidMount() {
    this.renderUsers();
  }

  renderModalChildren = () => {
    return <CreateUpdateUser />;
  };

  renderUsers = () => {
    const {  general, searchForm, getUsers, userForm } = this.props;
    unsetUserListData(general);

    searchForm.company = getMyCompany(general);
    userForm.arrayOptions.company = getMyCompany(general);
    getUsers(searchForm, false);
  };
  renderModal = () => {
    const { modale, general, userForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={3}
        tabs_title={modale.tabs_title}
        modale={modale}
        general={general}
        action={UPDATE_USER_PROPS}
        formvalues={userForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      userObj,
      sorting,
      searchForm,
      isLoadingSearch,
      getUsers,
      companyOptions,
      loadCompaniesOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}

        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <UserSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              getUsers={getUsers}
              general={general}
              companyOptions={companyOptions}
              loadCompaniesOptions={loadCompaniesOptions}
            />

            <UsersTable
              general={general}
              userObj={userObj}
              sorting={sorting}
              getUsers={getUsers}
              searchForm={searchForm}
              history={history}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ UserR, CompanyR }) => {
  return {
    userObj: UserR.userObj,
    modale: UserR.modale,
    userForm: UserR.userForm,
    sorting: UserR.sorting,
    searchForm: UserR.searchForm,
    isLoadingSearch: UserR.isLoadingSearch,
    companyOptions: CompanyR.companyOptions,
  };
};

export default connect(mapStateToProps, actions)(Users);
