import React, { Component } from 'react';
import { Form, Card } from 'reactstrap';
import { connect } from 'react-redux';
import { Mapy } from 'mapy_sdk/lib';
import * as actions from '../../redux/actions/index';
import FirstTab from './FirstTab';
import SecondTab from './SecondTab';
import ThirdTab from './ThirdTab';

import { onSave } from './RequestsFunctions';
import { UPDATE_REQUESTS_PROPS } from '../../redux/actions/types';
import { getMyCompany } from '../../utils/GeneralFunctions';
import { SelectPointsMarkers } from '../../redux/selectors/CompanyWFPointSelector';

let map = new Mapy();

class CreateUpdatePoint extends Component {
  componentDidMount() {
    this.fetchData();
  }

  fetchData = () => {
    const { pointForm, modale, getPoint, general } = this.props;
    if (modale.actionType === 'AddCompanyWFPoint') {
      general(
        [{ prop: 'pointForm.company', value: getMyCompany(general) }],
        UPDATE_REQUESTS_PROPS
      );
    } else {
      if (
        modale.actionType === 'EditCompanyWFPoint' &&
        pointForm.formvalues.id !== null
      ) {
        getPoint(pointForm.formvalues.id, true);
      }
    }
  };

  renderTabs = () => {
    const {
      pointForm,
      general,
      modale,
      companyWfOptions,
      companyOptions,
      getCompainesWFForDropDown,
      polygonItem,
      requestsObj,
      createORUpdatePoint,
      history,
      searchOptions,
      get_search,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      getAllPoints,
      dayOptions,
      loadBranchesOptions,
      branchOptions,
      currencyOptions,
    } = this.props;

    if (modale.activeIndex === 0) {
      return (
        <FirstTab
          {...this.props}
          map={map}
          general={general}
          pointForm={pointForm}
          companyWfOptions={companyWfOptions}
          modale={modale}
          companyOptions={companyOptions}
          getCompainesWFForDropDown={getCompainesWFForDropDown}
          polygonItem={polygonItem}
          searchOptions={searchOptions}
          endPoint={get_search}
          loadCompaniesOptions={loadCompaniesOptions}
          loadCompaniesWFOptions={loadCompaniesWFOptions}
          iscreate={pointForm.iscreate}
          work_fields_points={pointForm.work_fields_points}
          getAllPoints={getAllPoints}
          loadBranchesOptions={loadBranchesOptions}
          branchOptions={branchOptions}
        />
      );
    } else if (modale.activeIndex === 1) {
      return (
        <SecondTab
          general={general}
          pointForm={pointForm}
          dayOptions={dayOptions}
          {...this.props}
        />
      );
    } else {
      return (
        <ThirdTab
          general={general}
          pointForm={pointForm}
          createORUpdatePoint={createORUpdatePoint}
          requestsObj={requestsObj}
          currencyOptions={currencyOptions}
          history={history}
        />
      );
    }
  };
  render() {
    const {
      modale,
      requestsObj,
      pointForm,
      createORUpdatePoint,
      history,
    } = this.props;
    return (
      <Form
        onSubmit={(e) =>
          onSave(
            e,
            createORUpdatePoint,
            pointForm,
            requestsObj,
            true,
            history
          )
        }
      >
        <div className='conatiner'>
          <Card className='cardStyle'>
            <div className='ml-3 mr-3'>
              <p className='page_title text-center'>{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({
  RequestsR,
  CompanyWorkFieldR,
  CompanyR,
  BranchR,
  MapReducer,
}) => {
  return {
    pointForm: RequestsR.pointForm,
    modale: RequestsR.modale,
    dayOptions: RequestsR.dayOptions,
    jobTitleOptions: RequestsR.jobTitleOptions,
    requestsObj: RequestsR.requestsObj,
    typeOptions: RequestsR.typeOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    polygonItem: RequestsR.polygonItem,
    searchOptions: RequestsR.searchOptions,
    titleOptions: RequestsR.titleOptions,
    currencyOptions: RequestsR.currencyOptions,
    work_fields_points: RequestsR.work_fields_points,
    branchOptions: BranchR.branchOptions,
    MapReducer: MapReducer,
    markers: SelectPointsMarkers(
      RequestsR.pointForm.work_fields_points,
      RequestsR.pointForm.formvalues.location
    ),
  };
};

export default connect(mapStateToProps, actions)(CreateUpdatePoint);
