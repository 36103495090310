import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import WorkersTable from './WorkersTable';
import * as actions from '../../redux/actions/index';
import {  getMyCompany } from '../../utils/GeneralFunctions';
import WokerSearch from './WokerSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateWorker from './CreateUpdateWorker';
import { UPDATE_WORKER_PROPS } from '../../redux/actions/types';
import LightBoxFile from '../../utils/LightBoxFile';
import { unsetForm, unsetWorkerListData } from './WorkerFunctions';
class Workers extends Component {
  componentDidMount() {
    this.renderWorkers();
  }

  renderModalChildren = () => {
    const { history } = this.props;
    return <CreateUpdateWorker history={history} />;
  };

  renderWorkers = () => {
    const { general, getWorkers, searchForm } = this.props;
    unsetWorkerListData(general);
    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_WORKER_PROPS
    );
    searchForm.company = getMyCompany(general);
    getWorkers(searchForm, false);
  };
  renderModal = () => {
    const { modale, general, workerForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={2}
        tabs_title={modale.tabs_title}
        modale={modale}
        general={general}
        action={UPDATE_WORKER_PROPS}
        formvalues={workerForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      workerObj,
      sorting,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getWorkers,
      getCompainesWFForDropDown,
      updateWorkerStatus,
      getWorker,
      workerForm,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      modaleView,
      branchOptions,
      loadBranchesOptions,
      jobTitlesOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_WORKER_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <WokerSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getWorkers={getWorkers}
              general={general}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              loadCompaniesOptions={loadCompaniesOptions}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              branchOptions={branchOptions}
              loadBranchesOptions={loadBranchesOptions}
              jobTitlesOptions={jobTitlesOptions}
              {...this.props}
            />

            <WorkersTable
              general={general}
              workerObj={workerObj}
              sorting={sorting}
              updateWorkerStatus={updateWorkerStatus}
              searchForm={searchForm}
              getWorkers={getWorkers}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              getWorker={getWorker}
              workerForm={workerForm}
              history={history}
              companyOptions={companyOptions}
              modaleView={modaleView}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ WorkerR, CompanyWorkFieldR, CompanyR, BranchR }) => {
  return {
    workerObj: WorkerR.workerObj,
    modale: WorkerR.modale,
    workerForm: WorkerR.workerForm,
    modaleView: WorkerR.modaleView,
    sorting: WorkerR.sorting,
    lightbox: WorkerR.lightbox,
    searchForm: WorkerR.searchForm,
    isLoadingSearch: WorkerR.isLoadingSearch,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    branchOptions: BranchR.branchOptions,
    jobTitlesOptions: WorkerR.jobTitlesOptions,
  };
};

export default connect(mapStateToProps, actions)(Workers);
