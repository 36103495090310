import React from 'react';
import { Table, Row, Col, Button } from 'reactstrap';
import {
  renderProfileRow,
  renderDate,
} from '../../utils/GeneralFunctions';
import ModalView from '../../utils/ModalView';
import {  local } from '../../lang/local';
import { UPDATE_COMPANY_WORK_FIELD_PROPS } from '../../redux/actions/types';
import user_profile from '../../assets/img/user_profile.png';

const WorkFieldProfile = ({ modaleView, general }) => {
  const renderBody = () => {
    return (
      <div className='text-center'>
        {renderProfileRow(
          local.EnglishName,
          modaleView.data.name.en,
          user_profile,
          local.ArabicName,
          modaleView.data.name.ar,
          user_profile
        )}

        <p className='text-center mt-5'>{local.Branch}</p>

        <Table
          striped
          responsive
          className='table_list'
          style={{
            borderTopColor: 'white',
            border: 'none',
            position: 'relative',
          }}
        >
          <thead>
            <tr>
              <th>{local.EnglishName}</th>
              <th>{local.ArabicName}</th>
              <th>{local.Address}</th>
              <th>{local.Mobile}</th>
              <th>{local.ManagerName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>{modaleView.data.branch.name.en}</th>
              <th>{modaleView.data.branch.name.ar}</th>
              <th>{modaleView.data.branch.address}</th>
              <th>{modaleView.data.branch.mobile}</th>
              <th>{modaleView.data.branch.manager_name}</th>
            </tr>
          </tbody>
        </Table>

        {modaleView.data.points !== undefined ? (
          modaleView.data.points.length > 0 ? (
            <>
              <p className='text-center mt-5'>{local.WorkFieldPoint}</p>

              <Table
                striped
                responsive
                className='table_list mt-4 mb-4'
                style={{
                  borderTopColor: 'white',
                  border: 'none',
                  position: 'relative',
                }}
              >
                <thead>
                  <tr>
                    <th>{local.WorkFieldName}</th>
                  </tr>
                </thead>
                <tbody>
                  {modaleView.data.points.map((point) => {
                    return (
                      <tr>
                        <th>{point.name}</th>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </>
          ) : null
        ) : null}
      </div>
    );
  };
  const renderChildren = () => {
    return (
      <div>
        {modaleView.data !== null ? (
          <>
            <Row>
              <Col xl={1}>
                <p className='green_text'>{local.Id}</p>
              </Col>
              <Col xl={1}>
                <p className='blue_text'>{modaleView.data.id}</p>
              </Col>
              <Col>
                <Button className='sing_report_btn_blue'>
                  <div>
                    <p style={{ display: 'inline', fontSize: '10px' }}>
                      {renderDate(modaleView.data.created_at)}
                    </p>
                  </div>
                </Button>
              </Col>
            </Row>
            <Row>
              <Col xl={12} lg={12} className='mt-3'>
                {renderBody()}
              </Col>
            </Row>
          </>
        ) : null}
      </div>
    );
  };
  return (
    <ModalView
      children={renderChildren}
      modaleView={modaleView}
      general={general}
      action={UPDATE_COMPANY_WORK_FIELD_PROPS}
    />
  );
};

export default WorkFieldProfile;
