import React from "react";
import { Row, Col, Input, Form, Label, Spinner, Button } from "reactstrap";
import { labelStyle, rightStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import { UPDATE_LOGIN_PROPS } from "../../redux/actions/types";
import eya_h from "../../assets/img/eya_h.png";
import eya_v from "../../assets/img/eya_v.png";
import { confirmSunccessed } from "../../utils/GeneralFunctions";

const LoginForm = (props) => {
  const { loginObj, login, history, general } = props;

  const onClickLogin = (e) => {
    e.preventDefault();

    if (loginObj.formValue.email === "" && loginObj.formValue.password === "") {
      confirmSunccessed(local.EmailPasswordRequired);
    } else {
      if (loginObj.formValue.email === "") {
        confirmSunccessed(local.EmailRequired);
      }
      if (loginObj.formValue.email === "") {
        confirmSunccessed(local.EmailRequired);
      }
      if (loginObj.formValue.password === "") {
        confirmSunccessed(local.PasswordRequired);
      }

      if (
        loginObj.formValue.email !== "" &&
        loginObj.formValue.password !== ""
      ) {
        login(loginObj.formValue, history);
      }
    }
  };

  return (
    <Form type="post" onSubmit={onClickLogin} className="fullwidth">
      <Row>
        <Col className="mx-auto" xl={5} lg={5} md={5} sm={12} xs={12}>
          {/* <Label className="label_title" style={labelStyle}>
            {local.UserName}
          </Label> */}
          {/* <Input
            className="input_field"
            placeholder={local.Email}
            value={loginObj.formValue.email}
            onChange={(e) =>
              general(
                [{ prop: "loginObj.formValue.email", value: e.target.value }],
                UPDATE_LOGIN_PROPS
              )
            }
          /> */}
          <Input
            className="login_input"
            placeholder={local.Email}
            value={loginObj.formValue.email}
            onChange={(e) =>
              general(
                [{ prop: "loginObj.formValue.email", value: e.target.value }],
                UPDATE_LOGIN_PROPS
              )
            }
          />
        </Col>
      </Row>

      <Row>
        <Col className="mx-auto mt-5" xl={5} lg={5} md={5} sm={12} xs={12}>
          {/* <Label className="label_title" style={labelStyle}>
            {local.Password}
          </Label> */}
          <div className="input_field center">
            <Input
              // style={{ zIndex: "1000" }}
              type={loginObj.isViewPassword === true ? "text" : "password"}
              className="input_field"
              style={{ border: "none", height: "48px" }}
              placeholder={local.PasswordPlacholder}
              value={loginObj.formValue.password}
              onChange={(e) =>
                general(
                  [
                    {
                      prop: "loginObj.formValue.password",
                      value: e.target.value,
                    },
                  ],
                  UPDATE_LOGIN_PROPS
                )
              }
            />
            <span
              className="cursor"
              style={rightStyle}
              onClick={() =>
                general(
                  [
                    {
                      prop: "loginObj.isViewPassword",
                      value: !loginObj.isViewPassword,
                    },
                  ],
                  UPDATE_LOGIN_PROPS
                )
              }
            >
              {loginObj.isViewPassword === true ? (
                <img src={eya_v} width="30" height="30" />
              ) : (
                <img src={eya_h} width="30" height="30" />
              )}
            </span>
          </div>
        </Col>
      </Row>

      <Row>
        <Col
          className="mx-auto mt-5 center"
          xl={5}
          lg={5}
          md={5}
          sm={12}
          xs={12}
        >
          <button
            type="submit"
            className="btn_login"
            onClick={(e) => onClickLogin(e)}
            disabled={loginObj.isLoading}
          >
            {loginObj.isLoading === false ? (
              local.Login
            ) : (
              <Spinner type="grow" color="light" />
            )}
          </button>
        </Col>
      </Row>
    </Form>
  );
};

export default LoginForm;
