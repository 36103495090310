import React, { Component } from "react";
import { Form, Card } from "reactstrap";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import FirstTab from "./FirstTab";
import SecondTab from "./SecondTab";
import { onSave } from "./ReportTempleteFunctions";
import { getMyCompany } from "../../utils/GeneralFunctions";
import { UPDATE_REPORT_TEMPLETE_PROPS } from "../../redux/actions/types";
class CreateUpdateReport extends Component {
  componentDidMount() {
    this.props.general(
      [
        {
          prop: "reportForm.company_id",
          value: getMyCompany(this.props.general),
        },
      ],
      UPDATE_REPORT_TEMPLETE_PROPS
    );
  }
  renderTabs = () => {
    const {
      reportForm,
      general,
      companyOptions,
      modale,
      getCompainesWFForDropDown,
      companyWfOptions,
      templeteOptions,
      createORUpdateReport,
      reportTempleteObj,
      loadCompaniesOptions,
      loadCompaniesWFOptions,
      branchOptions,
      loadBranchesOptions,
    } = this.props;

    if (modale.activeIndex === 0) {
      return (
        <FirstTab
          general={general}
          reportForm={reportForm}
          templeteOptions={templeteOptions}
        />
      );
    } else if (modale.activeIndex === 1) {
      return (
        <SecondTab
          general={general}
          reportForm={reportForm}
          companyOptions={companyOptions}
          getCompainesWFForDropDown={getCompainesWFForDropDown}
          companyWfOptions={companyWfOptions}
          createORUpdateReport={createORUpdateReport}
          reportTempleteObj={reportTempleteObj}
          loadCompaniesOptions={loadCompaniesOptions}
          loadCompaniesWFOptions={loadCompaniesWFOptions}
          branchOptions={branchOptions}
          loadBranchesOptions={loadBranchesOptions}
        />
      );
    }
  };
  render() {
    const { modale, createORUpdateReport, reportForm, reportTempleteObj } =
      this.props;
    return (
      <Form
        onSubmit={(e) =>
          onSave(e, createORUpdateReport, reportForm, reportTempleteObj, true)
        }
      >
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({
  ReportTempleteR,
  CompanyR,
  CompanyWorkFieldR,
  BranchR,
}) => {
  return {
    reportForm: ReportTempleteR.reportForm,
    reportTempleteObj: ReportTempleteR.reportTempleteObj,
    modale: ReportTempleteR.modale,
    companyOptions: CompanyR.companyOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    templeteOptions: ReportTempleteR.templeteOptions,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateReport);
