import { getLanguage } from "../lang/local";

export const errandChannel = [
  { label: "PAYMOB - Merchant", value: 0 },
  { label: "Vodafone - Partner", value: 1 },
  { label: "KAYZON - Partner", value: 2 },
  { label: "PREMIUM - Partner", value: 3 },
  { label: "SOUHOOLA - Partner", value: 4 },
  { label: "GETGO - Partner", value: 5 },
  { label: "SYMPL - Partner", value: 6 },
  { label: "FORSA - Partner", value: 7 },
  { label: "VALU - Partner", value: 8 },
  { label: "AMAN - Partner", value: 9 },
];

export const requesterFunction = [
  { label: "Sales Team", value: 0 },
  { label: "DAM Team", value: 1 },
  { label: "Online Team", value: 2 },
  { label: "PartnerShip Team", value: 3 },
  { label: "Tele-Sales Team", value: 4 },
  { label: "Referral - Growth Engine", value: 5 },
  { label: "Field Service Team", value: 6 },
  { label: "Partner Operation Team", value: 7 },
  { label: "Marketing Team", value: 8 },
  { label: "Social Media Team", value: 9 },
  { label: "Customer Service Team", value: 10 },
  { label: "MRM Team", value: 11 },
  { label: "Public Sector Team", value: 12 },
  { label: "OPS - Audit Team", value: 13 },
];

export const city = [
  { label: "Cairo & Giza", value: "Cairo & Giza" },
  { label: "Alex", value: "Alex" },
  { label: "Mansoura", value: "Mansoura" },
  { label: "Tanta", value: "Tanta" },
  { label: "Banha", value: "Banha" },
  { label: "Kafr ElSheikh", value: "Kafr ElSheikh" },
  { label: "Behira", value: "Behira" },
  { label: "Zagazig", value: "Zagazig" },
  { label: "Port Said", value: "Port Said" },
  { label: "Ismalia", value: "Ismalia" },
  { label: "Damietta", value: "Damietta" },
  { label: "Suez", value: "Suez" },
  { label: "Sohag", value: "Sohag" },
  { label: "Qena", value: "Qena" },
  { label: "Luxor", value: "Luxor" },
  { label: "Aswan", value: "Aswan" },
  { label: "Asyut", value: "Asyut" },
  { label: "Minya", value: "Minya" },
  { label: "Bani suef", value: "Bani suef" },
  { label: "Fayoum", value: "Fayoum" },
  { label: "Hurghada", value: "Hurghada" },
  { label: "Sharm ElSheikh", value: "Sharm ElSheikh" },
  { label: "Elwadi El Gedid", value: "Elwadi El Gedid" },
];

export const posType = [
  { label: "BM - Mobadra", value: 0 },
  { label: "CIB - Mobadra", value: 1 },
  { label: "Special - Free", value: 2 },
  { label: "Special - Insurance", value: 3 },
  {
    label: "Special - Installment (540)",
    value: 4,
  },
  {
    label: "Special - Installment (1080)",
    value: 5,
  },
  {
    label: "Special - Installment (1667)",
    value: 6,
  },
  { label: "Special - Purchase", value: 7 },
  {
    label: "Special - Partner / Vodafone",
    value: 8,
  },
  {
    label: "Special - Partner / Premium",
    value: 9,
  },
  {
    label: "Special - Partner / Premium2030",
    value: 10,
  },
  { label: "Special - Partner / GetGo", value: 11 },
  {
    label: "Special - Partner / Souhoola",
    value: 12,
  },
  { label: "Special - Partner / Forsa", value: 13 },
  { label: "Special - Partner / Symbl", value: 14 },
  { label: "Special - Partner / Valu", value: 15 },
  { label: "Special - Partner / Aman", value: 16 },
  { label: "Special - Partner / Kazyon", value: 17 },
  { label: "Special - Partner", value: 18 },
];

export const numOfPos = [
  { label: 1, value: 0 },
  { label: 2, value: 1 },
  { label: 3, value: 2 },
  { label: 4, value: 3 },
  { label: 5, value: 4 },
  { label: 6, value: 5 },
  { label: 7, value: 6 },
  { label: 8, value: 7 },
  { label: 9, value: 8 },
  { label: 10, value: 9 },
  { label: "+10", value: 10 },
];

export const orderStatus = [
  {
    label: "Pending",
    value: 0,
  },
  {
    label: "Done",
    value: 1,
  },
  {
    label: "Deployed",
    value: 2,
  },
  {
    label: "Cancelled Now",
    value: 3,
  },
  {
    label: "Working Normally",
    value: 4,
  },
  {
    label: "New Activation",
    value: 5,
  },
  {
    label: "Postponed",
    value: 6,
  },
  {
    label: "Unreachable",
    value: 7,
  },
  {
    label: "Merchant Closed",
    value: 8,
  },
  {
    label: "Merchant Reject",
    value: 9,
  },
  {
    label: "Invalid",
    value: 10,
  },
  {
    label: "Not Live",
    value: 11,
  },
  {
    label: "Already Done before",
    value: 12,
  },
  {
    label: "Not Qualified",
    value: 13,
  },
  {
    label: "Out of zone",
    value: 14,
  },
  {
    label: "Docs",
    value: 15,
  },
  {
    label: "Balance",
    value: 16,
  },
  {
    label: "Insurance",
    value: 17,
  },
  {
    label: "Notice Period",
    value: 18,
  },
  {
    label: "Sales/DAM Reject",
    value: 19,
  },
  {
    label: "DAM Reject",
    value: 20,
  },
  {
    label: "Sales Reject",
    value: 21,
  },
  {
    label: "Didn't Receive It",
    value: 22,
  },
  {
    label: "Stolen/Missing",
    value: 23,
  },
  {
    label: "Already Cancelled Before",
    value: 24,
  },
  {
    label: "Change Area",
    value: 25,
  },
  {
    label: "Change City",
    value: 26,
  },
  {
    label: "Seasonal Business",
    value: 27,
  },
  {
    label: "Assigned",
    value: 28,
  },
];
