import {
  EditableableShapes,
  iconPostion,
  ZoomableShapes,
} from "mapy_sdk/lib/mapyTypes";
import {
  isArray,
  isWKTPoint,
  pointHasID,
  renderGooglePolygon,
  WKTPointToGeoJson,
} from "./utils";

const MapyStyles = {
  color: "#00FFE7",
  fillColor: "#009F90",
  fillOpacity: 0.4,
};

export const renderMapyPolygons = (
  map,
  polygons,
  {
    groupName = "Polygon Group",
    onClick = (id, properties) => console.log(id, properties),
    zoom = true,
  }
) => {
  if (!map) {
    throw new Error(`map is ${map}`);
  }
  if (!polygons) {
    throw new Error(`polygons are ${polygons}`);
  }
  if (isArray(polygons) === false) {
    throw new Error("polygons must be an array");
  }
  map.removeGroup(groupName);
  if (polygons.length === 0) return;
  polygons.map((poly, index) => {
    let polygon = renderGooglePolygon(poly.boundaries);
    map.AddPolygon(
      {
        boundaries: polygon,
        color: MapyStyles.color,
        fillColor: MapyStyles.fillColor,
        fillOpacity: MapyStyles.fillOpacity,
        weight: 1,
        groupName: poly.groupName || groupName,
        properties: poly,
        popup: poly.popup,
        onClick: poly.onClick || onClick,
      },
      poly.id,
      false
    );
  });

  if (zoom) {
    map.zoomToFeatures([{ id: groupName, type: ZoomableShapes.group }]);
  }
};

export const renderSingleMapyPolygon = (
  map,
  polygon,
  {
    isEditable = false,
    onClick = (id, properties) => console.log(id, properties),
  }
) => {
  if (!map) {
    throw new Error(`map is ${map}`);
  }
  if (!polygon) {
    throw new Error(`polygon is ${polygon}`);
  }

  const polygonIsObject = typeof polygon === "object";

  map.deleteAllPolygons();

  let poly = polygonIsObject
    ? renderGooglePolygon(polygon.boundaries)
    : renderGooglePolygon(polygon);
  map.AddPolygon(
    {
      boundaries: poly,
      color: MapyStyles.color,
      fillColor: MapyStyles.fillColor,
      fillOpacity: MapyStyles.fillOpacity,
      weight: 1,
      isEditable: poly.isEditable || isEditable,
      properties: poly,
      popup: poly.popup,
      onClick: poly.onClick || onClick,
    },
    polygon.id || "NewPolygon",
    true
  );
};

export const renderMapyMarkers = (
  map,
  markers,
  markerIcon,
  {
    groupName = "Markers Group",
    clusterMarkers = true,
    onClick = (id, properties) => console.log(id, properties),
    zoom = true,
  }
) => {
  if (!map) {
    throw new Error(`map is ${map}`);
  }
  if (!markers) {
    throw new Error(`markers are ${markers}`);
  }
  if (isArray(markers) === false) {
    throw new Error("markers must be an array");
  }
  map.removeGroup(groupName);
  if (markers.length === 0) return;
  markers.map((point, index) => {
    if (point) {
      const pointWithID = pointHasID(point);
      let pointLocation;
      if (pointWithID) {
        pointLocation = isWKTPoint(point.latLng)
          ? WKTPointToGeoJson(point.latLng)
          : point.latLng;
      } else {
        pointLocation = isWKTPoint(point) ? WKTPointToGeoJson(point) : point;
      }
      const markerImg = point.markerIcon;
      const imgUrl = markerImg ? markerImg.url : markerIcon;
      const imgSize = markerImg ? markerImg.imgSize : [60, 48];
      const { zoom = false } = point;
      map.AddMarker(
        {
          imgUrl,
          location: pointLocation,
          imgSize,
          iconAnchor: iconPostion.bottom_center,
          groupName: groupName,
          clusterName: clusterMarkers ? groupName : undefined,
          properties: point,
          popup: point.popup,
          onClick: point.onClick || onClick,
        },
        point.id || index,
        zoom
      );
    }
  });
  if (zoom) map.zoomToFeatures([{ id: groupName, type: ZoomableShapes.group }]);
};

export const renderSingleMapyMarker = ({
  map,
  marker,
  markerIcon,
  zoom = true,
}) => {
  if (!map) {
    throw new Error(`map is ${map}`);
  }
  if (!marker) {
    throw new Error(`marker is ${marker}`);
  }

  const defaultOnClick = (id, properties) => console.log(id, properties);

  const markerWithID = pointHasID(marker);

  map.deleteAllMarkers();

  let pointLocation;

  if (markerWithID) {
    pointLocation = isWKTPoint(marker.latLng)
      ? WKTPointToGeoJson(marker.latLng)
      : marker.latLng;
  } else {
    pointLocation = isWKTPoint(marker) ? WKTPointToGeoJson(marker) : marker;
  }

  map.AddMarker(
    {
      imgUrl: markerIcon,
      location: pointLocation,
      imgSize: [60, 40],
      iconAnchor: iconPostion.bottom_center,
      properties: marker,
      popup: marker.popup,
      onClick: marker.onClick || defaultOnClick,
    },
    marker.id || "NewMarker",
    zoom
  );
};

export const renderSingleMapyPolyline = (map, polyLine, { zoom = true }) => {
  if (!map) {
    throw new Error(`map is ${map}`);
  }
  if (!polyLine) {
    throw new Error(`polyLine is ${polyLine}`);
  }

  map.deleteAllPolylines();

  map.AddPolyline(
    {
      boundaries: polyLine.points,
      color: MapyStyles.color,
      fillColor: MapyStyles.fillColor,
      fillOpacity: 0.6,
      weight: 1,
    },
    polyLine.id || "NewPolyLine",
    zoom
  );
};
