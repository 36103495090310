import { getLanguage } from "../../lang/local";
import {
  CLEAR_VISITS_STATE,
  UPDATE_VISITS_PROPS,
  MERGE_VISITS_PROPS,
  DELETE_VISITS_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  visitObj: {
    visitItem: null,
    isLoading: false,
    page: 1,
    lat: null,
    lng: null,
    isViewed: false,
  },
  sorting: { column: "", order: "desc" },
  polygonItem: null,
  lightbox: { isOpen: false, image: null },

  visitForm: {
    isLoading: false,
    formvalues: {
      points: [null],
      work_field_id: [null],
    },
    index: null,
    company_id: null,
    branch_id: null,
    worker_id: null,
    worker_type: null,
    iscreate: "create",
  },
  visitsOptions: [],
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],

    mapItem: {
      polygon: [],
      mapPoints: [31.4177636, 30.00475],
    },
    activeIndex: 0,
    tabAction: "next",
  },
  searchForm: {
    from: "",
    to: "",
    page: 1,
    work_field: null,
    company: null,
    branch_id: null,
    worker_id: null,
    date: "",
    to_date: "",
    visitType: null,
  },
  isLoadingSearch: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_VISITS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_VISITS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_VISITS_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_VISITS_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
