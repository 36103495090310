import React, { Component } from "react";
import { connect } from "react-redux";
import { Row, Col, Card, Button } from "reactstrap";
import { renderProfileRow, renderDate } from "../../utils/GeneralFunctions";
import ModalView from "../../utils/ModalView";
import { local } from "../../lang/local";
import { UPDATE_REQUESTS_PROPS } from "../../redux/actions/types";
import * as actions from "../../redux/actions/index";
import Header from "../../routing/Layout/Header";
import user_profile from "../../assets/img/user_profile.png";
import report_icon_inactive from "../../assets/img/report_icon_inactive.png";
import track_inactive from "../../assets/img/track_inactive.png";

const Sidebar = React.lazy(() => import("../../routing/Layout/Sidebar"));

class PointProfile extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.history !== undefined) {
      this.props.getPoint(
        this.props.history.location.pathname.split("/")[2],
        false,
        "profile"
      );
    }
  }

  getData = () => {
    const { history } = this.props;
    console.log(history);
    if (history !== undefined) {
      return history.location.pathname.split("/")[2];
    }
  };

  renderBody = () => {
    const { modaleView, searchForm } = this.props;
    let workField = null;
    if (searchForm.ids !== null && modaleView.data !== null) {
      workField = searchForm.ids.find(
        (d) => d.value === modaleView.data.work_field_id
      );
    }
    return (
      <div>
        {renderProfileRow(
          local.WorkFieldName,
          modaleView.data.name,
          user_profile,
          local.WorkFieldType,
          report_icon_inactive
        )}
        {workField !== null
          ? renderProfileRow(
              local.WorkPlaces,
              workField.label,
              report_icon_inactive,
              local.Address,
              modaleView.data.properties.address,
              track_inactive
            )
          : renderProfileRow(
              local.WorkPlaces,
              null,
              report_icon_inactive,
              local.Address,
              modaleView.data.properties.address,
              track_inactive
            )}
      </div>
    );
  };

  renderChildren = () => {
    const { modaleView, general } = this.props;

    if (modaleView.data !== null) {
      return (
        <div>
          {modaleView.data !== null ? (
            <>
              <Row>
                <Col xl={1}>
                  <p className="green_text">{local.Id}</p>
                </Col>
                <Col xl={1}>
                  <p className="blue_text">{modaleView.data.id}</p>
                </Col>
                <Col>
                  <Button className="sing_report_btn_blue">
                    <div>
                      <p style={{ display: "inline", fontSize: "10px" }}>
                        {renderDate(modaleView.data.created_at)}
                      </p>
                    </div>
                  </Button>
                </Col>
              </Row>
              <Row>
                <Col xl={3} className="mt-4">
                  {modaleView.data.image ? (
                    <Card
                      style={{
                        border: "none",
                      }}
                    >
                      <a
                        href
                        onClick={() =>
                          general(
                            [
                              {
                                prop: "lightbox.image",
                                value: modaleView.data.image,
                              },
                              { prop: "lightbox.isOpen", value: true },
                            ],
                            UPDATE_REQUESTS_PROPS
                          )
                        }
                      >
                        <img
                          src={modaleView.data.image}
                          alt="img"
                          style={{ width: "90%", height: "180px" }}
                          className="img-fluid rounded"
                        />
                      </a>
                    </Card>
                  ) : null}
                </Col>
                <Col>{this.renderBody()}</Col>
              </Row>
            </>
          ) : null}
        </div>
      );
    }
  };
  render() {
    const { modaleView, general, history } = this.props;

    return this.getData() === undefined ? (
      <ModalView
        children={this.renderChildren}
        modaleView={modaleView}
        general={general}
        action={UPDATE_REQUESTS_PROPS}
      />
    ) : (
      <div className="conatiner">
        <Header history={history} />
        <div>
          <Sidebar />
        </div>

        <Row style={{ height: "100%" }}>
          <Col xl={12} lg={12} md={12} sm={12} xs={12} className="mt-4">
            <Card className="cardCreate ml-2 mr-2 mt-4">
              <div className="mt-5 mr-5 ml-5">{this.renderChildren()}</div>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
const mapStateToProps = ({ RequestsR }) => {
  return {
    modaleView: RequestsR.modaleView,
    searchForm: RequestsR.searchForm,
  };
};

export default connect(mapStateToProps, actions)(PointProfile);
