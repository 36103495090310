import React from 'react';
import { Card, Col, Row, Button, Form, Spinner } from 'reactstrap';
import moment from 'moment';
import { leftlStyle, selectStyle3 } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import { renderBranchSelect } from '../Branches/BranchesFunction';
import {
  renderArraw,
  formatDateForRequest,
  checkHasOneCompany,
  removeDaysFromDate,
  getDeafultItem,
} from '../../utils/GeneralFunctions';
import { UPDATE_VISITS_PROPS } from '../../redux/actions/types';
import toaster from 'toasted-notes';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import { renderWorkFSelect } from '../CompainesWorkFields/CompanyWFFunctions';
import { renderWorkerSeelct } from '../Workers/WorkerFunctions';
import DateInput from '../../components/DateInput/DateInput';
import { visitTypeOptions } from '../../utils/visitTypeOptions';
import Select from 'react-select';

const VisitSearch = (props) => {
  const {
    history,
    general,
    searchForm,
    isLoadingSearch,
    getVisits,
    companyOptions,
    companyWfOptions,
    workerOptions,
    loadCompaniesWFOptions,
    loadWorkerOptions,
    loadCompaniesOptions,
    branchOptions,
    loadBranchesOptions,
    page,
  } = props;
  const onSearch = (e) => {
    e.preventDefault();
    searchForm.page = 1;

    if (page === 'visitsPlan') {
      if (!searchForm.date) {
        searchForm.date = formatDateForRequest(new Date());
      }
    } else {
      if (!searchForm.to_date) {
        searchForm.to_date = moment().subtract(1, 'day').format('YYYY-MM-DD');
      }
    }

    if (searchForm.company === null) {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    } else if (searchForm.branch_id && !searchForm.work_field) {
      toaster.notify(local.ChooseCompanyWorkField, {
        duration: 2000,
      });
    } else {
      getVisits(true, searchForm);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {page === 'visitsPlan' ? local.VisitsPlan : local.Visits}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
              {renderCompanySelect(
                'searchForm.company',
                searchForm.company,
                UPDATE_VISITS_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: 'searchForm.work_field', v: null },
                  { p: 'searchForm.branch_id', v: null },
                  { p: 'searchForm.worker_id', v: null },
                ],
                null
              )}
            </Col>
          ) : null}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderBranchSelect(
              'searchForm.branch_id',
              searchForm.branch_id,
              UPDATE_VISITS_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              [{ p: 'searchForm.ids', v: null }],
              null
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkFSelect(
              'searchForm.work_field',
              searchForm.work_field,
              UPDATE_VISITS_PROPS,
              general,
              local.CompaniesWorkField,
              loadCompaniesWFOptions,
              companyWfOptions,
              null,
              null,
              searchForm.branch_id,
              null,
              null
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderWorkerSeelct(
              'searchForm.worker_id',
              searchForm.worker_id,
              UPDATE_VISITS_PROPS,
              general,
              loadWorkerOptions,
              workerOptions,
              searchForm.company,
              null
            )}
          </Col>

          <DateInput
            prop='searchForm.date'
            value={searchForm.date ? searchForm.date : undefined}
            action={UPDATE_VISITS_PROPS}
            title={local.From}
            // minimumDate={page === "visitsPlan" ? new Date() : null}
            minimumDate={page === 'visitsPlan' ? moment().startOf('day') : null}
            maximumDate={
              searchForm.to_date
                ? new Date(searchForm.to_date)
                : page === 'visitsPlan'
                ? null
                : removeDaysFromDate(new Date(), 1)
            }
            columns={3}
          />

          <DateInput
            prop='searchForm.to_date'
            value={searchForm.to_date}
            action={UPDATE_VISITS_PROPS}
            title={local.To}
            minimumDate={
              searchForm.date
                ? moment(searchForm.date).startOf('day')
                : page === 'visitsPlan'
                ? moment().startOf('day')
                : null
            }
            maximumDate={
              page === 'visitsPlan' ? null : removeDaysFromDate(new Date(), 1)
            }
            columns={3}
          />

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            <Select
              key={searchForm.visitType}
              options={visitTypeOptions}
              value={getDeafultItem(searchForm.visitType, visitTypeOptions)}
              placeholder={local.PlaceType}
              className='mt-2'
              getOptionLabel={(op) => op.label}
              getOptionValue={(op) => op.value}
              styles={selectStyle3}
              isClearable={true}
              onChange={(e) =>
                e
                  ? general(
                      [
                        {
                          prop: 'searchForm.visitType',
                          value: e.value,
                        },
                      ],
                      UPDATE_VISITS_PROPS
                    )
                  : general(
                      [
                        {
                          prop: 'searchForm.visitType',
                          value: null,
                        },
                      ],
                      UPDATE_VISITS_PROPS
                    )
              }
            />
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            <Button
              style={leftlStyle}
              type='submit'
              className='btn_search_style'
              disabled={isLoadingSearch}
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default VisitSearch;
