import {
  Clear_NOTIFICATIONS_PROPS,
  UPDATE_NOTIFICATIONS_PROPS,
  MERGE_NOTIFICATIONS_PROPS,
  DELETE_NOTIFICATIONS_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  notificationObj: { notificationItem: null, isLoading: false },
  sorting: { column: "", order: "desc" },
  notificationForm: {
    isLoading: false,
    formvalues: {
      message: { title: "", body: "" },
      ids: null,
    },
    index: null,
    company: null,
  },
  searchForm: { from: "", to: "", page: 1 },
  modale: { isOpen: false, tobeConfirmed: false, title: "", actionType: "" },
  isLoadingSearch: false,
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_NOTIFICATIONS_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_NOTIFICATIONS_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_NOTIFICATIONS_PROPS: {
      return general.deleteProps(state, action);
    }
    case Clear_NOTIFICATIONS_PROPS: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
