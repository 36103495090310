import React from "react";
import { Card, Col, Row, Button, Spinner, Form } from "reactstrap";
import { leftlStyle, selectStyle3 } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import toaster from "toasted-notes";
import Select from "react-select";
import {
  renderDateInput,
  renderPhoneSearch,
  renderArraw,
  checkHasOneCompany,
  getWorkFieldOptions,
  handleSelectChangeEnhanced,
  workFieldPermissionedForDropDown,
  getBranchOptions,
} from "../../utils/GeneralFunctions";
import { UPDATE_WORKER_PROPS } from "../../redux/actions/types";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
const WorkerSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    companyOptions,
    getWorkers,
    general,
    history,
    loadCompaniesOptions,
    branchOptions,
    loadBranchesOptions,
    jobTitlesOptions,
    workerForm,
    setWorkerProps,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();
    searchForm.page = 1;
    if (searchForm.company === null) {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    } else {
      getWorkers(searchForm, true);
    }
  };
  return (
    <Card className="cardStyle cardSearch mt-1">
      <Form onSubmit={onSearch} className="mt-4 mb-4">
        <Row>
          <Col>
            <p className="page_title" style={leftlStyle}>
              {local.Workers}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
              {renderCompanySelect(
                "searchForm.company",
                searchForm.company,
                UPDATE_WORKER_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [
                  { p: "searchForm.branch_id", v: null },
                  { p: "searchForm.parent_branch", v: null },
                  { p: "searchForm.work_field_id", v: null },
                ],
                null
              )}
            </Col>
          ) : null}
          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderBranchSelect(
              "searchForm.branch_id",
              searchForm.branch_id,
              UPDATE_WORKER_PROPS,
              general,
              local.Branch,
              loadBranchesOptions,
              branchOptions,
              searchForm.company,
              null,
              [{ p: "searchForm.work_field_id", v: null }],
              null
            )}
          </Col> */}
          <CustomAsyncPaginate
            loadOptions={getBranchOptions(searchForm.company, { parent_id: 0 })}
            value={searchForm.parent_branch}
            placeholder={local.Regions}
            cacheUniqsArr={[searchForm.company]}
            className="mt-2"
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                "searchForm.parent_branch",
                setWorkerProps,
                ["searchForm.branch_id", "searchForm.work_field_id"]
              )
            }
            filterOption={(option, rawInput) =>
              workFieldPermissionedForDropDown({ option, rawInput })
            }
            additional={{
              page: 1,
            }}
            isDisabled={!!!searchForm.company}
          />
          <CustomAsyncPaginate
            loadOptions={getBranchOptions(searchForm.company, {
              parent_id: searchForm.parent_branch?.id,
            })}
            value={searchForm.branch_id}
            placeholder={local.Cities}
            cacheUniqsArr={[searchForm.parent_branch]}
            className=" mt-2"
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                "searchForm.branch_id",
                setWorkerProps,
                ["searchForm.work_field_id"]
              )
            }
            additional={{
              page: 1,
            }}
            isDisabled={!!!searchForm.parent_branch}
          />

          {/*<Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderWorkFSelect(
            "searchForm.work_field_id",
            searchForm.work_field_id,
            UPDATE_WORKER_PROPS,
            general,
            local.WorkPlaces,
            loadCompaniesWFOptions,
            companyWfOptions,
            null,
            null,
            searchForm.branch_id,
            null,
            null
          )}
        </div> */}

          <CustomAsyncPaginate
            loadOptions={getWorkFieldOptions(searchForm.branch_id?.id)}
            value={searchForm.work_field_id}
            placeholder={local.Zones}
            cacheUniqsArr={[searchForm.branch_id]}
            className=" mt-2"
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                "searchForm.work_field_id",
                setWorkerProps
              )
            }
            filterOption={(option, rawInput) =>
              workFieldPermissionedForDropDown({ option, rawInput })
            }
            additional={{
              page: 1,
            }}
            isDisabled={!!!searchForm.branch_id}
          />

          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_WORKER_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_WORKER_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col> */}
          {/* <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2"> */}
          {/* {renderGeneralInputSearch(
            "searchForm.job_title",
            searchForm.job_title,
            UPDATE_WORKER_PROPS,
            general,
            local.JobTitle
          )} */}

          {/* <Select
              options={jobTitlesOptions}
              styles={selectStyle3}
              placeholder={local.JobTitle}
              onChange={(e) => {
                setWorkerProps([['searchForm.job_title', e.value]]);
              }}
            /> */}
          {/* </Col> */}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {renderPhoneSearch(
              "searchForm.mobile",
              searchForm.mobile,
              UPDATE_WORKER_PROPS,
              general
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className="mt-2">
            {" "}
            <Button
              style={leftlStyle}
              type="submit"
              disabled={isLoadingSearch}
              className="btn_search_style"
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type="grow" color="dark" size="sm" />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default WorkerSearch;
