import {
  UPDATE_STATE_PROPS,
  UPDATE_STATE_WORK_FIELD_PROPS,
} from "../../redux/actions/types";
import toaster from "toasted-notes";
import { local } from "../../lang/local";

export const validateInputs = (stateWFForm) => {
  if (stateWFForm.formvalues.name.en === "") {
    toaster.notify(local.EnglishNamequired, {
      duration: 2000,
    });
  }
  if (stateWFForm.formvalues.name.ar === "") {
    toaster.notify(local.ArabicNameRequired, {
      duration: 2000,
    });
  }
  if (stateWFForm.formvalues.state_id === null) {
    toaster.notify(local.SelectProvince, {
      duration: 2000,
    });
  }
  if (stateWFForm.formvalues.address === "") {
    toaster.notify(local.AddressRequired, {
      duration: 2000,
    });
  }
  // if (stateWFForm.formvalues.mobile === "") {
  //   toaster.notify(local.MobileRequired, {
  //     duration: 2000,
  //   });
  // }

  // if (stateWFForm.formvalues.mobile !== "") {
  //   if (validatePhoneLength(stateWFForm.formvalues.mobile) === false)
  //     toaster.notify(local.EgPhoneLength, {
  //       duration: 2000,
  //     });
  // }

  if (
    stateWFForm.formvalues.name.en !== "" &&
    stateWFForm.formvalues.name.ar !== "" &&
    stateWFForm.formvalues.state_id !== null &&
    stateWFForm.formvalues.address !== ""
    // &&
    // stateWFForm.formvalues.mobile !== "" &&
    // validatePhoneLength(stateWFForm.formvalues.mobile) === true
  ) {
    return true;
  }
};

export const onSave = (e, createORUpdateStateWF, stateWFForm, isModal) => {
  e.preventDefault();
  if (validateInputs(stateWFForm) === true) {
    createORUpdateStateWF(stateWFForm.formvalues, stateWFForm.index, isModal);
  }
};

export const unsetForm = (general) => {
  general(
    [
      {
        prop: "stateWFForm",
        value: {
          isLoading: false,
          formvalues: {
            name: { ar: "", en: "" },
            state_id: null,
            address: "",
            mobile: "",
          },
          index: null,
        },
      },
      { prop: "stateWFOptions", value: [] },
      {
        prop: "modale",
        value: {
          isOpen: false,
          tobeConfirmed: false,
          title: "",
          actionType: "",
        },
      },
    ],
    UPDATE_STATE_WORK_FIELD_PROPS
  );
};

export const unsetStateWFListData = (general) => {
  general(
    [
      { prop: "stateWFObj", value: { stateWFItem: null, isLoading: false } },
      {
        prop: "stateWFOptions",
        value: [],
      },
      {
        prop: "isLoadingSearch",
        value: false,
      },
      {
        prop: "searchForm",
        value: {
          state_id: null,
          name: "",
          mobile: "",
          from: "",
          to: "",
          page: 1,
        },
      },
    ],
    UPDATE_STATE_WORK_FIELD_PROPS
  );

  general([{ prop: "stateOptions", value: [] }], UPDATE_STATE_PROPS);
};
