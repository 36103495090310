import { useEffect } from 'react';
import { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import ar from 'react-phone-input-2/lang/ar.json';
import { connect } from 'react-redux';
import { Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getLanguage, local } from '../../lang/local';
import { general } from '../../redux/actions';
import { labelStyle } from '../../utils/GeneralStyle';

const GenericPhoneInput = ({
  prop,
  value,
  action,
  title,
  general,
  classes,
  fullWidth
}) => {
  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [value]);

  const defaultCountry = {
    countryCode: 'eg',
    dialCode: '20',
    format: '+.. ... ... ... ... ..',
    name: 'Egypt',
  };

  const [inputValue, setInputValue] = useState('');
  const [country, setCountry] = useState(defaultCountry);

  return (
    <Col xl={3} lg={3} md={3} sm={12} xs={12} className='' style={ fullWidth ? { width:'100%'} : '' }  >
      {title && (
        <>
          <span className='label_title'  style={{...labelStyle, color:"rgb(122, 134, 154)"}}>
            {title === undefined ? local.Mobile : title}
          </span>
          <br />
        </>
      )}

      <PhoneInput
        placeholder={title === undefined ? local.Mobile : local.Mobile2}
        country={'eg'}
        containerClass='phone_container'
        dropdownClass='phone_dropdown'
        inputClass='phone_input'
        buttonClass='phone_btn'
        // onlyCountries={['eg', 'sa', 'ae', 'kw', 'lb']}
        onlyCountries={['eg']}
        localization={getLanguage() === 'ar' ? ar : false}
        autoFormat={true}
        countryCodeEditable={false}
        value={!inputValue ? `+${country.dialCode}` : inputValue}
        isValid={(value, country) => {
          if (value.length - country.countryCode.length > 10) {
            return false;
          } else return true;
        }}
        onChange={(phone, country) => {
          console.log(`country`, country);
          setInputValue(phone);
          setCountry(country);
        }}
        onBlur={() => {
          general(
            [
              {
                prop: prop,
                value: inputValue === country.dialCode ? '' : inputValue,
              },
            ],
            action
          );
        }}
      />
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(GenericPhoneInput);
