import React, { Component } from 'react';
import { Card, Col, Row, Spinner, Form, Button } from 'reactstrap';
import { local } from '../../lang/local';
import icon_btn from '../../assets/img/icon_btn.png';
import { connect } from 'react-redux';
import * as actions from '../../redux/actions/index';
import { UPDATE_STATE_PROPS } from '../../redux/actions/types';
import { FaFileExcel } from 'react-icons/fa';

import {
  checkConnection,
  renderArabicNameInput,
  renderArraw,
  renderEnglishNameInput,
} from '../../utils/GeneralFunctions';
import { onSave, unsetStateListData } from './StateFunction';
import { leftlStyle, rightStyle } from '../../utils/GeneralStyle';
class AddState extends Component {
  componentDidMount() {
    checkConnection(this.props.history);
    unsetStateListData(this.props.general);

    this.props.getStatesForDropDown();
  }

  render() {
    const { stateForm, general, createORUpdateState, history } = this.props;
    return (
      <div className='page-wrapper'>
        <Row>
          <Col className='mt-4 body-container'>
            <Form
              onSubmit={(e) => onSave(e, createORUpdateState, stateForm, false)}
            >
              <Card className='cardCreate mt-4 ml-2 mr-2'>
                <div className='mt-5 mr-5 mt-3 ml-5'>
                  <Row>
                    <Col>
                      <p style={leftlStyle}>{local.AddState}</p>
                    </Col>
                    {renderArraw(history)}
                  </Row>
                  <Row className='mt-5'>
                    <Col>
                      <p className='page_title mt-3' style={leftlStyle}>
                        {local.MainInfo}
                      </p>
                    </Col>
                    <Col>
                      <div style={rightStyle}>
                        <Button
                          className='place_btn'
                          onClick={(e) =>
                            history.push('/dashboard/add_state_from_file')
                          }
                        >
                          <span style={{ fontSize: '10px' }}>
                            {local.AddFile}
                          </span>
                          &nbsp;
                          <span>
                            <FaFileExcel />
                          </span>
                        </Button>
                      </div>
                    </Col>
                  </Row>
                  <Row className='mt-5 justify-content-center justify-content-lg-start'>
                    <div xl={3} className='mt-5 react-select-div'>
                      {renderEnglishNameInput(
                        'stateForm.formvalues.name.en',
                        stateForm.formvalues.name.en,
                        UPDATE_STATE_PROPS,
                        general
                      )}
                    </div>
                    <div xl={3} className='mt-5 react-select-div'>
                      {renderArabicNameInput(
                        'stateForm.formvalues.name.ar',
                        stateForm.formvalues.name.ar,
                        UPDATE_STATE_PROPS,
                        general
                      )}
                    </div>
                  </Row>
                  <center className='mt-3'>
                    <button
                      disabled={stateForm.isLoading}
                      className='save_btn mt-5'
                      onClick={(e) =>
                        onSave(e, createORUpdateState, stateForm, false)
                      }
                    >
                      {stateForm.isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <>
                          <p style={{ display: 'inline' }}>{local.Save} </p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </>
                      )}
                    </button>
                  </center>
                  <br /> <br />
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ StateR }) => {
  return {
    stateForm: StateR.stateForm,
    stateOptions: StateR.stateOptions,
  };
};

export default connect(mapStateToProps, actions)(AddState);
