import { property } from "lodash";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col } from "reactstrap";
import {
  labelStyle,
  selectStyle3,
  selectStyle4,
  selectStyle5,
  selet_new_Style,
  Select_new_Style_2,
} from "../../utils/GeneralStyle";

const CustomAsyncPaginate = ({
  loadOptions,
  value,
  placeholder,
  cacheUniqsArr = undefined,
  onChange,
  additional,
  isMulti = false,
  isClearable = true,
  onFocus,
  fullWidth,
  className = "",
  labelStyles = {},
  hasLabel = false,
  label = "",
  filterOption, // (option, rawInput) =>
  isDisabled = false,
  noGrid,
  columns,
  my_style,
}) => {
  const mystyle = {
    backgroundColor: "red",
  };
  return (
    <Col
      xl={!columns ? 3 : 12}
      lg={!columns ? 3 : 12}
      md={!columns ? 3 : 12}
      sm={12}
      xs={12}
      style={fullWidth && { width: "100%" }}
    >
      {hasLabel && (
        <span className="label_title" style={{ ...labelStyle, ...labelStyles }}>
          {label || placeholder}
        </span>
      )}
      <AsyncPaginate
        className={className}
        loadOptions={loadOptions}
        styles={
          my_style
            ? { ...selet_new_Style, ...Select_new_Style_2 }
            : { ...selectStyle4, ...selectStyle5 }
        }
        value={value}
        placeholder={placeholder}
        cacheUniqs={cacheUniqsArr}
        onChange={onChange}
        additional={additional}
        onFocus={onFocus}
        isMulti={isMulti}
        isClearable={isClearable}
        filterOption={filterOption}
        closeMenuOnSelect={!isMulti}
        isDisabled={isDisabled}
      />
    </Col>
  );
};

export default CustomAsyncPaginate;
