import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderPagination,
  renderCreateButton,
  getPermissionValue,
} from '../../utils/GeneralFunctions';
import { UPDATE_REPORT_TEMPLETE_PROPS } from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { leftlStyle } from '../../utils/GeneralStyle';

const ReportTable = (props) => {
  const {
    reportTempleteObj,
    general,
    sorting,
    getTempleteReports,
    searchForm,
  } = props;

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: 'modale.title', value: local.AddReportTemplete },
        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddReportTemplete' },
      ],
      UPDATE_REPORT_TEMPLETE_PROPS
    );
  };

  const renderBody = () => {
    if (reportTempleteObj.reportTempleteItem !== null) {
      return reportTempleteObj.reportTempleteItem.data.map((report, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(report.id)}</td>
            <td className='td_table'>{report.work_field.name[local.lang]}</td>
            <td className='td_table'>
              <Table
                responsive
                hover
                id='report_templete_table'
                className='table_list mt-4 mb-4'
                style={{
                  borderTopColor: 'white',
                  border: 'none',
                  position: 'relative',
                }}
              >
                <thead>
                  <tr className='table_head'>
                    <th className='td_table'>{local.Name}</th>
                    <th className='td_table'>{local.Type}</th>
                  </tr>
                </thead>
                {report.template.map((temp, i) => {
                  return (
                    <tr key={i}>
                      <td className='td_table'>{temp.name}</td>
                      <td className='td_table'>{temp.type}</td>
                    </tr>
                  );
                })}
              </Table>
            </td>
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      {renderCreateButton(
        getPermissionValue()['companies/work_fields/{id}/reports/store'],
        onCreate,
        local.AddReportTemplete
      )}

      <Row>
        <Col xl={12}>
        
          <Table
            responsive
            hover
            id='report_templete_table'
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  reportTempleteObj.reportTempleteItem,
                  'id',
                  'reportTempleteObj.reportTempleteItem.data',
                  UPDATE_REPORT_TEMPLETE_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.WorkField,
                  reportTempleteObj.reportTempleteItem,
                  'work_field.name',
                  'reportTempleteObj.reportTempleteItem.data',
                  UPDATE_REPORT_TEMPLETE_PROPS,
                  general,
                  sorting
                )}

                {reportTempleteObj.reportTempleteItem !== null
                  ? renderTableHeadItem(
                      local.ReportTemplete,
                      reportTempleteObj.reportTempleteItem,
                      'work_field.name',
                      'reportTempleteObj.reportTempleteItem.data',
                      UPDATE_REPORT_TEMPLETE_PROPS,
                      general,
                      sorting,
                      true,
                      Math.max(
                        ...reportTempleteObj.reportTempleteItem.data.map(
                          (edge) => edge.template.length
                        )
                      )
                    )
                  : null}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {reportTempleteObj.reportTempleteItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='report_templete_table'
              filename={local.ReportTemplete}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {reportTempleteObj.reportTempleteItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}
      {renderPagination(
        reportTempleteObj.reportTempleteItem,
        searchForm,
        getTempleteReports
      )}
    </Card>
  );
};

export default ReportTable;
