import React from 'react';
import { Card, Row, Table, Col } from 'reactstrap';
import { getLanguage, local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderDate,
  renderArrayInTable,
  rednerImageInTable,
  renderPagination,
  getPermissionValue,
  renderEditButton,
  renderCreateButton,
  renderEditTitle,
  renderStatusTitle,
  renderStatusButton,
  onOpenProfile,
} from '../../utils/GeneralFunctions';
import {
  DELETE_COMPANY_PROPS,
  UPDATE_COMPANY_PROPS,
  UPDATE_STATE_PROPS,
  UPDATE_STATE_WORK_FIELD_PROPS,
} from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import CompanyProfile from './CompanyProfile';
import { leftlStyle } from '../../utils/GeneralStyle';

const CompainesTable = (props) => {
  const {
    companyObj,
    general,
    sorting,
    updateStatus,
    getCompaines,
    searchForm,
    modaleView,
  } = props;

  const onEdit = (e, item, index) => {
    e.preventDefault();

    if (item.parent !== undefined) {
      if (item.parent === null) {
        item.parent_id = item.id;
      } else {
        item.parent_id = item.parent.id;
      }
    } else {
      item.parent_id = null;
    }

    general(
      [
        { prop: 'companyForm.formvalues', value: item },
        { prop: 'companyForm.isLoading', value: false },
      ],
      UPDATE_COMPANY_PROPS
    );
    if (item.states !== null) {
      let newStates = [];
      let extractedState = [];

      if (item.states.length > 0) {
        item.states.map((stat) => {
          newStates.push({
            label: getLanguage() === 'en' ? stat.name.en : stat.name.ar,
            value: stat.id,
          });
        });
      } else if (
        item.states.length === 0 &&
        item.state_work_fields.length > 0
      ) {
        item.state_work_fields.map((wf) => {
          extractedState.push(wf.state);
        });

        let uniqueArray = extractedState.filter(function (item, pos) {
          return extractedState.indexOf(item) == pos;
        });
        uniqueArray.map((un) => {
          newStates.push({
            label: getLanguage() === 'en' ? un.name.en : un.name.ar,
            value: un.id,
          });
        });
      }

      general([{ prop: 'stateValue', value: newStates }], UPDATE_COMPANY_PROPS);
      general([{ prop: 'stateOptions', value: newStates }], UPDATE_STATE_PROPS);
    }
    if (item.state_work_fields !== null) {
      let newWorkField = [];
      item.state_work_fields.map((wf) => {
        newWorkField.push({
          label: getLanguage() === 'en' ? wf.name.en : wf.name.ar,
          value: wf.id,
          state_id: wf.state.id,
        });
      });
      general(
        [{ prop: 'stateWFValue', value: newWorkField }],
        UPDATE_COMPANY_PROPS
      );
      general(
        [{ prop: 'companyForm.formvalues.state_work_fields' }],
        DELETE_COMPANY_PROPS
      );

      general(
        [{ prop: 'stateWFOptions', value: newWorkField }],
        UPDATE_STATE_WORK_FIELD_PROPS
      );
    }

    general(
      [
        { prop: 'companyForm.formvalues.states', value: null },
        { prop: 'companyForm.formvalues.work_fields', value: null },
        { prop: 'companyForm.index', value: index },
        { prop: 'modale.actionType', value: 'EditCompany' },
        { prop: 'modale.title', value: local.updateComapny },
        { prop: 'companyForm.isCreate', value: 'update' },
        { prop: 'modale.isOpen', value: true },
      ],
      UPDATE_COMPANY_PROPS
    );
  };

  const onCreate = (e) => {
    e.preventDefault();
    general(
      [
        { prop: 'modale.title', value: local.addCompany },
        { prop: 'modale.isOpen', value: true },
        { prop: 'modale.actionType', value: 'AddCompany' },
      ],
      UPDATE_COMPANY_PROPS
    );
  };

  const renderBody = () => {
    if (companyObj.companyItem !== null) {
      return companyObj.companyItem.data.map((company, index) => {
        return (
          <tr key={index}>
            <td className='td_table'>{renderNumber(company.id)}</td>
            <td className='td_table'>
              {rednerImageInTable(company.logo, UPDATE_COMPANY_PROPS, general)}
            </td>
            <td
              className='td_table'
              style={{ textDecoration: 'underline', cursor: 'pointer' }}
              onClick={() =>
                onOpenProfile(
                  company,
                  local.Company,
                  general,
                  UPDATE_COMPANY_PROPS,
                  true,
                  'companies/show/{id}'
                )
              }
            >
              {company.name[local.lang]}
            </td>
            <td className='td_table'>{renderNumber(company.mobile)}</td>
            <td className='td_table'>{company.address}</td>
            <td className='td_table'>{renderNumber(company.identifier)}</td>
            <td className='td_table'>{renderArrayInTable(company.states)}</td>
            <td className='td_table'>
              {renderArrayInTable(company.state_work_fields)}
            </td>

            <td className='td_table'>{renderDate(company.created_at)}</td>
            {renderStatusButton(
              getPermissionValue()['companies/changeStatus/{id}'],
              company,
              index,
              updateStatus,
              'isCompany'
            )}
            {renderEditButton(
              getPermissionValue()['companies/update/{id}'],
              onEdit,
              company,
              index
            )}
          </tr>
        );
      });
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <CompanyProfile modaleView={modaleView} general={general} />
      {renderCreateButton(
        getPermissionValue()['companies/store'],
        onCreate,
        local.AddCompany
      )}

      <Row>
        <Col xl={12}>
          <Table
            id='company_table'
            responsive
            hover
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  companyObj.companyItem,
                  'id',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.Logo,
                  companyObj.companyItem,
                  'logo',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Name,
                  companyObj.companyItem,
                  'name',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Mobile,
                  companyObj.companyItem,
                  'mobile',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Address,
                  companyObj.companyItem,
                  'address',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Identifier,
                  companyObj.companyItem,
                  'identifier',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Provinces,
                  companyObj.companyItem,
                  'states',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.StateWorkFields,
                  companyObj.companyItem,
                  'state_work_fields',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.DateAdded,
                  companyObj.companyItem,
                  'created_at',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}

                {renderStatusTitle(
                  'companies/changeStatus/{id}',
                  companyObj.companyItem,
                  'active',
                  'companyObj.companyItem.data',
                  UPDATE_COMPANY_PROPS,
                  general,
                  sorting
                )}

                {renderEditTitle('companies/update/{id}')}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </Table>
        </Col>
      </Row>

      {companyObj.companyItem !== null ? (
         <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
         <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='company_table'
              filename={local.Compaines}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
            <span>{local.Total + ' : '}</span>
            <span className='green_text'>
              {companyObj.companyItem.pagination.total}
            </span>
            </div>
          </Col>
        </Row>
      ) : null}

      {renderPagination(companyObj.companyItem, searchForm, getCompaines)}
    </Card>
  );
};

export default CompainesTable;
