import React from "react";
import { Card, Col, Row, Button, Spinner, Form } from "reactstrap";
import toaster from "toasted-notes";
import {
  renderArraw,
  renderDateInput,
  renderGeneralInputSearch,
  renderPhoneSearch,
  renderSearchSelcet,
} from "../../utils/GeneralFunctions";
import { leftlStyle } from "../../utils/GeneralStyle";
import { local } from "../../lang/local";
import { UPDATE_COMPANY_PROPS } from "../../redux/actions/types";
const CompanySearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    general,
    getCompaines,
    stateusOption,
    history,
  } = props;

  const onSearch = (e) => {
    searchForm.page = 1;
    e.preventDefault();

    if (
      searchForm.states === null &&
      searchForm.work_fields === null &&
      searchForm.mobile === "" &&
      searchForm.active === null &&
      searchForm.from === "" &&
      searchForm.to === "" &&
      searchForm.name === ""
    ) {
      toaster.notify(local.SearchValidation, {
        duration: 2000,
      });
    } else {
      getCompaines(true, searchForm);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
    <Form onSubmit={onSearch} className="mt-4 mb-4">
      <Row>
        <Col>
          <p className="page_title" style={leftlStyle}>
            {local.Compaines}
          </p>
        </Col>
        {renderArraw(history)}
      </Row>
      <Row>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderGeneralInputSearch(
            "searchForm.name",
            searchForm.name,
            UPDATE_COMPANY_PROPS,
            general,
            local.Name
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderPhoneSearch(
            "searchForm.mobile",
            searchForm.mobile,
            UPDATE_COMPANY_PROPS,
            general
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderDateInput(
            "searchForm.from",
            searchForm.from,
            UPDATE_COMPANY_PROPS,
            general,
            local.FromDate,
            undefined,
            null,
            searchForm.to
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderDateInput(
            "searchForm.to",
            searchForm.to,
            UPDATE_COMPANY_PROPS,
            general,
            local.ToDate,
            undefined,
            searchForm.from
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          {renderSearchSelcet(
            local.Status,
            "searchForm.active",
            searchForm.active,
            UPDATE_COMPANY_PROPS,
            general,
            stateusOption,
            null,
            true,
            null
          )}
        </Col>
        <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
          <Button
            style={leftlStyle}
            disabled={isLoadingSearch}
            type="submit"
            className="btn_search_style"
            onClick={(e) => onSearch(e)}
          >
            {isLoadingSearch === true ? (
              <Spinner type="grow" color="dark" size="sm" />
            ) : (
              local.Search
            )}
          </Button>
        </Col>
      </Row>
      </Form>
    </Card>
  );
};

export default CompanySearch;
