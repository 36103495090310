import React from "react";
import { Col, Row, CardBody } from "reactstrap";
import {
  rednerAddButton,
  renderTempleteInput,
  renderRemoveButton,
  renderTempleteSelect,
} from "./ReportTempleteFunctions";
const FirstTab = (props) => {
  const { general, reportForm, templeteOptions } = props;

  return (
    <CardBody className="trans">
      {rednerAddButton(general)}

      {reportForm.formvalues.template.length > 0
        ? reportForm.formvalues.template.map((temp, index) => {
            return (
              <>
                <div className="mt-4">
                  {index !== 0 && index !== 1 && index !== 2
                    ? renderRemoveButton(index, general)
                    : null}
                </div>
                <Row className="mt-2">
                  <Col xl={6} lg={6}>
                    {renderTempleteInput(
                      "reportForm.formvalues.template." + index + ".name",
                      reportForm.formvalues.template[index].name,
                      general
                    )}
                  </Col>
                  <Col xl={6} lg={6}>
                    {renderTempleteSelect(
                      "reportForm.formvalues.template." + index + ".type",
                      reportForm.formvalues.template[index].type,
                      "reportForm.formvalues.template." + index + ".key",
                      templeteOptions,
                      general,
                      +index
                    )}
                  </Col>
                </Row>
              </>
            );
          })
        : null}
    </CardBody>
  );
};
export default FirstTab;
