import React from "react";
import { UPDATE_COMPANY_PROPS } from "../../redux/actions/types";
import {
  renderGeneralInput,
  renderPhoneInput,
} from "../../utils/GeneralFunctions";
import {
  onSave,
  renderAddButton,
  renderCompanySelect,
  renderMultStateSelect,
  renderMultStateWFSelect,
  renderTable,
} from "./CompainesFunction";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { local } from "../../lang/local";
import icon_btn from "../../assets/img/icon_btn.png";
import { labelStyle } from "../../utils/GeneralStyle";
const SecondTab = (props) => {
  const {
    general,
    companyForm,
    stateOptions,
    stateWFOptions,
    createORUpdateCompany,
    companyOptions,
    loadStateOptions,
    loadStateWFOptions,
    loadCompaniesOptions,
    stateValue,
    stateWFValue,
  } = props;

  return (
    <>
      <CardBody className="trans">
        <Row>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderMultStateSelect(
              "companyForm.formvalues.states",
              companyForm.formvalues.states,
              UPDATE_COMPANY_PROPS,
              general,
              stateOptions,
              loadStateOptions,
              "companyForm.formvalues.work_fields",
              companyForm.isCreate,
              stateValue
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderMultStateWFSelect(
              "companyForm.formvalues.work_fields",
              companyForm.formvalues.work_fields,
              UPDATE_COMPANY_PROPS,
              general,
              stateWFOptions,
              loadStateWFOptions,
              companyForm.formvalues.states,
              companyForm.isCreate,
              stateWFValue
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderAddButton(
              stateValue,
              stateWFValue,
              general,
              companyForm.formvalues.states,
              companyForm.formvalues.work_fields
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderTable(stateValue, stateWFValue, general)}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <span className="label_title" style={labelStyle}>
              {local.MajorCompany}
            </span>
            {renderCompanySelect(
              "companyForm.formvalues.parent_id",
              companyForm.formvalues.parent_id,
              UPDATE_COMPANY_PROPS,
              general,
              local.MajorCompany,
              loadCompaniesOptions,
              companyOptions,
              null,
              null,
              "4"
            )}
          </Col>
        </Row>

        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderGeneralInput(
              "companyForm.formvalues.address",
              companyForm.formvalues.address,
              UPDATE_COMPANY_PROPS,
              general,
              local.Address
            )}
          </Col>
        </Row>
        <Row className="mt-4">
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            {renderPhoneInput(
              "companyForm.formvalues.mobile",
              companyForm.formvalues.mobile,
              UPDATE_COMPANY_PROPS,
              general
            )}
          </Col>
        </Row>
      </CardBody>
      <center>
        <button
          type="submit"
          disabled={companyForm.isLoading}
          className="save_btn mt-5"
          onClick={(e) =>
            onSave(
              e,
              createORUpdateCompany,
              companyForm,
              true,
              stateValue,
              stateWFValue
            )
          }
        >
          {companyForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center>
    </>
  );
};

export default SecondTab;
