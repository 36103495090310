import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Select from "react-select";

import { getLanguage } from "../../lang/local";
import { general } from "../../redux/actions";
import { getDeafultItem } from "../../utils/GeneralFunctions";

const lang = getLanguage();

const selectStyle = {
  container: (base, state) => ({
    ...base,
    transition:
      "border-color 0.2s ease, box-shadow 0.2s ease, padding 0.2s ease",
    // "&:hover": {
    //   boxShadow: "0 2px 4px 0 rgba(41, 56, 78, 0.1)",
    // },
    // borderRadius: "10px",
    // minHeight: 35,
  }),
  input: (base, state) => ({
    ...base,
    // color: "white",
    fontSize: "16px",
    padding: ".75rem",
  }),

  indicatorSeparator: (base, state) => ({
    ...base,
    display: "none",
  }),

  loadingIndicator: (base, state) => ({
    ...base,
    ">span": {
      fontSize: "10px",
    },
  }),

  singleValue: (base, state) => ({
    ...base,
    color: "#757575",
    fontSize: "14px",
  }),

  indicatorsContainer: (base, state) => ({
    ...base,
    color: "white",
  }),

  menu: (base, state) => ({
    ...base,
    fontSize: "10px",
    zIndex: 999,
  }),
  option: (base, state) => ({
    ...base,
    zIndex: 999,
    borderBottom: "1px solid #00beac;",
    color: "#3b3b3b",
    padding: 10,
    fontSize: "14px",
    // borderRadius: "5px",
    textAlign: "center",
    background: "none",
  }),
  multiValue: (base, state) => ({
    ...base,
    background: "transparent",
    color: "white",
  }),
  multiValueLabel: (base, state) => ({
    ...base,
    color: "white",
  }),
};

const mainStyle = {
  control: (base, state) => ({
    ...base,
    background: "#EEEEEE;",
    borderRadius: "0px",
    border: "none",
    width: "100%",
    // minHeight: 35,
    // "&:hover": {
    //   borderColor: "transparent",
    //   // boxShadow: "0 0 0 1px #757575",
    // },
  }),
  valueContainer: (base, state) => ({
    ...base,
    background: "#EEEEEE;",
    // borderRadius: "10px",
    color: "white",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "#757575",
    fontSize: "16px",
    textAlign: "center",
    width: "100%",
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: "#757575",
  }),
};

const blueStyle = {
  control: (base, state) => ({
    ...base,
    background: "#0052CC;",
    borderRadius: "0px",
    width: "100%",
    // minHeight: 35,
    "&:hover": {
      borderColor: "#0052CC",
      boxShadow: "0 0 0 1px #0052CC",
    },
  }),
  valueContainer: (base, state) => ({
    ...base,
    background: "#0052CC",
    // borderRadius: "10px",
    color: "white",
  }),
  placeholder: (base, state) => ({
    ...base,
    color: "white",
    fontSize: "16px",
    textAlign: "center",
    width: "100%",
  }),
  clearIndicator: (base, state) => ({
    ...base,
    color: "white",
  }),
};

const StaticDropdown = ({
  fullWidth,
  label,
  title,
  prop,
  value,
  action,
  options,
  isMulti = false,
  dependentProps = [],
  general,
  className,
  styles = {},
  containerStyles = {},
  placeholder= " ",
  labelStyles = {},
  onChange,
  isClearable = true,
  filterOption,
  hasLabel = false,
  selectLabelStyle = {},
  onChangeCB, // on change callback function
}) => {
  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(true);

  const defaultOnChange = (option, actionType) => {
    if (dependentProps.length > 0) {
      let convertDependentPropToDispatch = [];
      for (let i = 0; i < dependentProps.length; i++) {
        convertDependentPropToDispatch.push({
          prop: dependentProps[i],
          value: null,
        });
      }
      general([...convertDependentPropToDispatch], action);
    }
    if (option) {
      isMulti
        ? general([{ prop, value: option }], action)
        : general([{ prop, value: option.value }], action);

      onChangeCB && onChangeCB(option);
    } else general([{ prop, value: null }], action);
  };
  const onFocus = () => setDefaultMenuIsOpen(true);
  return (
    <div className={className ? className : ""}>
      {label && (
        <p
          className="text-xs font-semibold"
          style={{
            ...labelStyles,
            color: "#7A869A",
            fontWeight: 600,
            fontSize: "14px",
            height: "auto",
            marginBottom:".7rem"
          }}
        >
          {label}
        </p>
      )}
      <Select
        key={value}
        placeholder={placeholder}
        styles={{ ...selectStyle, ...mainStyle, styles }}
        value={isMulti ? value : getDeafultItem(value, options)}
        isClearable={isClearable}
        onChange={onChange ? onChange : defaultOnChange}
        // onChange={onChange ? { onChange } : defaultOnChange}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isMulti={isMulti}
        filterOption={filterOption}
        closeMenuOnSelect={!isMulti}
        // defaultMenuIsOpen={defaultMenuIsOpen}
        onFocus={onFocus}
        autoFocus={true}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(StaticDropdown);

export const getSelectOptionsArr = (optionsArr) => {
  const options = [];
  for (let i = 0; i < optionsArr.length; i++) {
    const currentOption = optionsArr[i];
    options.push({
      ...currentOption,
      label: currentOption.label[lang],
      value: currentOption.value,
    });
  }
  return options;
};
