import {
  CLEAR_STATE,
  UPDATE_LOGIN_PROPS,
  MERGE_LOGIN_PROPS,
  DELETE_LOGIN_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  loginObj: {
    isViewPassword: false,
    isLoading: false,
    formValue: { email: "", password: "" },
    errorMessage: { email: "", password: "" },
  },
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_LOGIN_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_LOGIN_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_LOGIN_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
