import React from 'react';
import { Card, Col, Row, Button, Spinner, Form } from 'reactstrap';
import toaster from 'toasted-notes';
import GenericPhoneInput from '../../components/GenericPhoneInput/GenericPhoneInput';
import {
  renderArraw,
  renderDateInput,
  renderGeneralInputSearch,
  checkHasOneCompany,
  renderPhoneSearch,
} from '../../utils/GeneralFunctions';
import { leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import { UPDATE_BRANCHES_PROPS } from '../../redux/actions/types';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
const BranchSearch = (props) => {
  const {
    searchForm,
    isLoadingSearch,
    general,
    getBranches,
    history,
    loadCompaniesOptions,
    companyOptions,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();

    if (searchForm.company_id === null) {
      toaster.notify(local.ChooseCompany, {
        duration: 2000,
      });
    } else {
      getBranches(true, searchForm);
    }
  };
  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {local.Branches}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>
        <Row>
          {checkHasOneCompany() === false ? (
            <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
              {renderCompanySelect(
                'searchForm.company_id',
                searchForm.company_id,
                UPDATE_BRANCHES_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                null,
                null
              )}
            </Col>
          ) : null}
          <div className="mt-4 react-select-div">
          {renderPhoneSearch(
            "searchForm.mobile",
            searchForm.mobile,
            UPDATE_BRANCHES_PROPS,
            general
          )}
        </div>
          {/* <GenericPhoneInput
            prop='searchForm.mobile'
            value={searchForm.mobile}
            action={UPDATE_BRANCHES_PROPS}
            classes='mt-4 react-select-div'
          /> */}
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.from',
              searchForm.from,
              UPDATE_BRANCHES_PROPS,
              general,
              local.FromDate,
              undefined,
              null,
              searchForm.to
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderDateInput(
              'searchForm.to',
              searchForm.to,
              UPDATE_BRANCHES_PROPS,
              general,
              local.ToDate,
              undefined,
              searchForm.from
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            {renderGeneralInputSearch(
              'searchForm.name',
              searchForm.name,
              UPDATE_BRANCHES_PROPS,
              general,
              local.Name
            )}
          </Col>
          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            <Button
              style={leftlStyle}
              disabled={isLoadingSearch}
              type='submit'
              className='btn_search_style'
              onClick={(e) => onSearch(e)}
            >
              {isLoadingSearch === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default BranchSearch;
