import React, { useEffect, useState } from "react";
import { UPDATE_USER_PROPS } from "../../redux/actions/types";
import * as actions from "../../redux/actions/index";
import { connect } from "react-redux";
import { Col, Row, CardBody, Spinner } from "reactstrap";
import { local } from "../../lang/local";
import { Card,Spin  } from 'antd';
import icon_btn from "../../assets/img/icon_btn.png";
import { onSave } from "./UserFunctions";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";


const ThirdTab = (props) => {
  const [Flage, Setflage] = useState(false)

  const {
    general,
    allPer,
    permissionsOptions,
    CityOwnerPermissions,
    userObj,
    userForm,
    createORUpdateUser
  } = props;
console.log("test",permissionsOptions)
//   const EditPermission = () => {
//     for (let i = 0; i < permissionsOptions.length; i++) {
//       permissionsOptions[i].isSelected = false

//       for (let j = 0; j < CityOwnerPermissions.length; j++) {
//         if (permissionsOptions[i].permission == CityOwnerPermissions[j].permission) {
//           permissionsOptions[i].isSelected = true
//           console.log("perperperpepreprepr",permissionsOptions[i])
//         }

//       }

//     }
//   }
   

  return (
    <>
    {/* {Flage ? */}
     <CardBody className="trans">
      <Row>
      <Card    style={{
      width: "100%",
    }}>
      <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {permissionsOptions.length > 0
              ? permissionsOptions.map((per, index) => {
                  return (
                    <Col xl={4} lg={4} className="text-center">
                      <p>{per.permission}</p>
                      <FormControlLabel
                        checked={per.isSelected}
                        onChange={(e) =>{
                          console.log("e.target.checked",e.target.checked)
                          general(
                            [
                              {
                                prop:
                                  "permissionsOptions." + index + ".isSelected",
                                value: e.target.checked,
                              },
                            ],
                            UPDATE_USER_PROPS
                          )
                        }
                          
                        }
                        control={<Switch color="primary" />}
                        label={local[per.permission]}
                        labelPlacement="top"
                      />
                    </Col>
                  );
                })
              : null}
          </FormGroup></FormControl> 
      </Card>
        
      </Row>

      <center className="mt-5">
        <button
          type="submit"
          disabled={userForm.isLoading}
          className="save_btn mt-5"
          onClick={(e) =>
            onSave(
              e,
              createORUpdateUser,
              userForm,
              permissionsOptions,
              userObj,
              true,
              userForm.arrayOptions
            )
          }
        >
          {userForm.isLoading === true ? (
            <Spinner type="grow" color="dark" size="sm" />
          ) : (
            <>
              <p style={{ display: "inline" }}>{local.Save}</p>
              <img src={icon_btn} width="20" height="20" alt="img" />
            </>
          )}
        </button>
      </center>
    </CardBody> 
    
    {/* : <div style={{
      margin: "20px 0",
  marginBottom: "20px",
  padding: "30px 50px",
  textAlign: "center",
  background: "rgba(0, 0, 0, 0.05)",
  borderRadius: "4px",}}><Spin /></div>} */}
  </>
  )
 
}

export default connect(null, actions)(ThirdTab);