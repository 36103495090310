import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import StateWorkFieldsTable from './StateWorkFieldsTable';
import * as actions from '../../redux/actions/index';
import StateWorkFieldSearch from './StateWorkFieldSearch';
import GeneralModel from '../../utils/GeneralModel';
import CreateUpdateStateWF from './CreateUpdateStateWF';
import { local } from '../../lang/local';
import { UPDATE_STATE_WORK_FIELD_PROPS } from '../../redux/actions/types';
import { unsetForm, unsetStateWFListData } from './StateWorkFieldFunctions';
class StateWorkFields extends Component {
  componentDidMount() {
    unsetStateWFListData(this.props.general);
    this.renderState();
  }

  renderModalChildren = () => {
    const { modale } = this.props;
    return <CreateUpdateStateWF modale={modale} />;
  };

  renderState = () => {
    const { getStatesWorkFields, searchForm } = this.props;
    getStatesWorkFields(false, searchForm);
  };
  renderModal = () => {
    const { modale, general, stateWFForm } = this.props;
    return (
      <GeneralModel
        children={this.renderModalChildren}
        number_of_tabs={1}
        tabs_title={[{ title: local.MainInfo }]}
        modale={modale}
        general={general}
        action={UPDATE_STATE_WORK_FIELD_PROPS}
        formvalues={stateWFForm.formvalues}
        unsetForm={unsetForm}
      />
    );
  };
  componentWillUnmount() {
    unsetForm(this.props.general);
  }

  render() {
    const {
      history,
      general,
      stateWFObj,
      sorting,
      searchForm,
      getStatesWorkFields,
      isLoadingSearch,
      loadStateOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        {this.renderModal()}

        <Row>
          <Col className='mt-2'>
            <StateWorkFieldSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              general={general}
              loadStateOptions={loadStateOptions}
              getStatesWorkFields={getStatesWorkFields}
            />

            <StateWorkFieldsTable
              general={general}
              stateWFObj={stateWFObj}
              sorting={sorting}
              searchForm={searchForm}
              getStatesWorkFields={getStatesWorkFields}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ StateWFR }) => {
  return {
    stateWFObj: StateWFR.stateWFObj,
    modale: StateWFR.modale,
    stateWFForm: StateWFR.stateWFForm,
    sorting: StateWFR.sorting,
    searchForm: StateWFR.searchForm,
    isLoadingSearch: StateWFR.isLoadingSearch,
  };
};

export default connect(mapStateToProps, actions)(StateWorkFields);
