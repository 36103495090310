import Modal from "react-modal";
import Select from "react-select";
import { FaTimes } from "react-icons/fa";
import { local } from "../../lang/local";
import { selectStyle3 } from "../../utils/GeneralStyle";
import "./styles/EditGiftsModal/edit-gifts-modal.css";
import { validateForm } from "../../utils/GeneralFunctions";

const EditGiftsModal = ({
  editGiftsModal,
  clearAndCloseEditGiftsModal,
  editGiftOrSample,
  searchForm,
  getGiftsTableData,
  setGiftsProps,
}) => {
  const customStyles = {
    content: {
      backgroundColor: "white",
      borderRadius: "10px",
    },
    overlay: {
      zIndex: 10000,
      backgroundColor: "rgba(30, 30, 30, .6)",
    },
  };

  return (
    <Modal
      isOpen={editGiftsModal.isOpen}
      style={customStyles}
      className="edit-gifts-modal"
    >
      <div>
        <div className="edit-gifts-modal_modal-title">
          <div onClick={clearAndCloseEditGiftsModal} id="exit-icon">
            <FaTimes />
          </div>
          {local.EditGiftOrSample}
        </div>
        <div className="d-flex flex-column mx-5 my-4">
          <div className="edit-gifts-modal_label mb-2">Name</div>
          <div className="mb-2">
            <input
              onChange={(e) => {
                setGiftsProps([["editGiftsModal.gift.name", e.target.value]]);
              }}
              value={editGiftsModal.gift.name}
              className="col-12"
            />
          </div>
          <div className="edit-gifts-modal_label mb-2">Type</div>
          <div className="mb-2">
            <Select
              value={
                editGiftsModal.gift.type === 0
                  ? { label: "Gift", value: 0 }
                  : { label: "Sample", value: 1 }
              }
              onChange={(e) =>
                setGiftsProps([["editGiftsModal.gift.type", e.value]])
              }
              styles={selectStyle3}
              options={[
                { label: "Gift", value: 0 },
                { label: "Sample", value: 1 },
              ]}
            />
          </div>
          <div className="edit-gifts-modal_label mb-2">Details</div>
          <div className="mb-5">
            <input
              value={editGiftsModal.gift.details}
              onChange={(e) =>
                setGiftsProps([["editGiftsModal.gift.details", e.target.value]])
              }
              className="col-12"
            />
          </div>
          <div className="d-flex justify-content-center">
            <button
              onClick={async () => {
                if (
                  validateForm([
                    {
                      label: local.Company,
                      value: editGiftsModal.gift.id,
                    },
                    {
                      label: local.Name,
                      value: editGiftsModal.gift.name,
                    },
                    {
                      label: local.Type,
                      value: editGiftsModal.gift.type,
                    },
                    {
                      label: local.Details,
                      value: editGiftsModal.gift.details,
                    },
                  ])
                ) {
                  await editGiftOrSample(
                    editGiftsModal.gift.id,
                    editGiftsModal.gift.name,
                    editGiftsModal.gift.type,
                    editGiftsModal.gift.details,
                    () => getGiftsTableData(searchForm, 1)
                  );
                  clearAndCloseEditGiftsModal();
                }
              }}
              id="edit-gifts-modal_save-button"
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default EditGiftsModal;
