import React from 'react';

const StepModel = (props) => {
  const { tabs_title, activeIndex } = props;
  const renderDots = () => {
    return tabs_title.map((dot, index) => {
      if (activeIndex === index) {
        return (
          <div
            className={tabs_title[activeIndex].dots_pagination_active}
            key={index}
          />
        );
      } else {
        return <div className='dots_pagination' key={index}></div>;
      }
    });
  };
  return <div className='text-center mt-4 mb-3'>{renderDots()}</div>;
};

export default StepModel;
