import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import Flatpickr from 'react-flatpickr';
import { Arabic } from 'flatpickr/dist/l10n/ar.js';
import { getLanguage } from '../../lang/local';
import { general } from '../../redux/actions';
import { storeDate } from '../../utils/GeneralFunctions';
import { Col } from 'reactstrap';

const DateInput = ({
  prop,
  value,
  action,
  general,
  title,
  hasTime = false,
  minimumDate,
  maximumDate = new Date(),
  customOnChange,
  customOnClear,
  columns,
}) => {
  let options = {
    enableTime: hasTime,
    altInput: true,
    // altFormat: hasTime === undefined ? "F j, Y" : "",
    // dateFormat: hasTime === undefined ? "Y-m-d" : "",
    maxDate: maximumDate ? new Date(maximumDate) : maximumDate,
    locale: getLanguage() === 'en' ? null : Arabic,
  };
  if (minimumDate) {
    options.minDate = new Date(minimumDate);
  } else if (minimumDate === null) {
    options.minDate = null;
  }

  const clearBtnStyles = {
    background: 'none',
    color: 'white',
    fontFamily: 'buckra',
    fontSize: '12px',
    border: 'none',
    position: 'absolute',
    textAlign: 'end',
    top: '50%',
    transform: 'translateY(-50%)',
  };

  const altPlaceholderStyles = {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)',
    color: 'white',
    pointerEvents: 'none',
  };

  if (getLanguage() === 'en') {
    clearBtnStyles.right = '10px';
  } else {
    clearBtnStyles.left = '10px';
  }

  const clearDate = () => {
    storeDate(prop, null, action, general, hasTime);
  };

  return (
    <Col
      xl={columns ? columns : 3}
      lg={columns ? columns : 3}
      md={columns ? columns : 3}
      sm={12}
      xs={12}
      className='mt-2'
    >
      <Flatpickr
        className='dateStyle'
        data-enable-time
        value={value}
        onChange={
          customOnChange
            ? customOnChange
            : (date) => {
                storeDate(prop, date, action, general, hasTime);
              }
        }
        local={getLanguage() === 'en' ? null : Arabic}
        placeholder={title}
        options={options}
      />
      {/* {!value && <div style={altPlaceholderStyles}>{title}</div>} */}
      <button
        type='button'
        style={{ ...clearBtnStyles }}
        onClick={customOnClear ? customOnClear : clearDate}
      >
        X
      </button>
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(DateInput);
