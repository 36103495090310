import {
  CLEAR_STATE,
  UPDATE_COVERAGE_LIST_PROPS,
  MERGE_COVERAGE_LIST_PROPS,
  DELETE_COVERAGE_LIST_PROPS,
  CLEAR_COVERAGE_LIST_PROPS,
} from "../actions/types";
import * as general from "./methods";

import dotProp from "dot-prop-immutable";

export const COVERAGE_LIST = "COVERAGE_LIST/";

const INITIAL_STATE = {
  coverageList: [],
  fullCoverageList: [],
  selectedCompany: null,
  selectedEmployee: null,
  employeeCoverageListOptions: [],
  addToCoverageListForm: {
    branch: null,
    workField: null,
    points: [],
  },
  removeFromCoverageListForm: {
    branch: null,
    workField: null,
    points: [],
  },
};

export default (state = INITIAL_STATE, action) => {
  const setState = (prop, value) => {
    return dotProp.set(state, prop, value);
  };

  switch (action.type) {
    case UPDATE_COVERAGE_LIST_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_COVERAGE_LIST_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_COVERAGE_LIST_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_COVERAGE_LIST_PROPS: {
      return INITIAL_STATE;
    }
    case CLEAR_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }
    default:
      return state;
  }
};
