import React from 'react';
import Sidebar from 'react-sidebar';
import { Col, Button, Row, Spinner } from 'reactstrap';
import { renderWorkerSeelct } from '../Workers/WorkerFunctions';
import { renderCompanySelect } from '../Compaines/CompainesFunction';
import {
  MERGE_LIVE_TRACKING_PROPS,
  UPDATE_LIVE_TRACKING_PROPS,
} from '../../redux/actions/types';
import { getLanguage, local } from '../../lang/local';
import toaster from 'toasted-notes';
import DateInput from '../../components/DateInput/DateInput';
import { checkHasOneCompany } from '../../utils/GeneralFunctions';
const TrackingMenu = ({
  sidebarData,
  general,
  loadCompaniesOptions,
  companyOptions,
  workerOptions,
  loadWorkerOptions,
  getRouting,
  clearTrackingSearchForm,
}) => {
  const onSetSidebarOpen = () => {
    general(
      [{ prop: 'sidebarData.isOpen', value: !sidebarData.isOpen }],
      UPDATE_LIVE_TRACKING_PROPS
    );
    clearTrackingSearchForm();
  };

  const onSearch = (e) => {
    e.preventDefault();

    if (
      sidebarData.company === null ||
      sidebarData.worker_id === null ||
      sidebarData.from === '' ||
      sidebarData.to === ''
    ) {
      toaster.notify(local.EnterData, {
        duration: 2000,
      });
    } else {
      getRouting(
        sidebarData.worker_id,
        sidebarData.from,
        sidebarData.to,
        UPDATE_LIVE_TRACKING_PROPS,
        MERGE_LIVE_TRACKING_PROPS,
        'sidebarData.polyline',
        'sidebarData.isLoading',
        null,
        null,
        true
      );
    }
  };

  const renderContent = () => {
    return (
      <div style={{ margin: '20px' }} className='mt-4'>
        <p className='text-center' style={{ textDecoration: 'underline' }}>
          {local.ShowLine}
        </p>

        {checkHasOneCompany() === false ? (
          <Row className='mt-5'>
            <Col xl={12}>
              {renderCompanySelect(
                'sidebarData.company',
                sidebarData.company,
                UPDATE_LIVE_TRACKING_PROPS,
                general,
                local.Company,
                loadCompaniesOptions,
                companyOptions,
                null,
                [{ p: 'sidebarData.worker_id', v: null }],
                null
              )}
            </Col>
          </Row>
        ) : null}
        <Row className='mt-5'>
          <Col xl={12}>
            {renderWorkerSeelct(
              'sidebarData.worker_id',
              sidebarData.worker_id,
              UPDATE_LIVE_TRACKING_PROPS,
              general,
              loadWorkerOptions,
              workerOptions,
              sidebarData.company,
              null
            )}
          </Col>
        </Row>
        <Row className='mt-5'>
          {/* <Col xl={12}>
            <Flatpickr
              className="dateStyle"
              data-enable-time
              value={sidebarData.from}
              onChange={(date) => {
                general(
                  [
                    {
                      prop: "sidebarData.from",
                      value: new Date(date[0]).toISOString(),
                    },
                  ],
                  UPDATE_LIVE_TRACKING_PROPS
                );
              }}
              local={getLanguage() === "en" ? null : Arabic}
              placeholder={local.FromDate}
              options={{
                enableTime: true,
                altInput: true,
                maxDate: new Date(),
              }}
            />
          </Col> */}
          <DateInput
            prop='sidebarData.from'
            value={sidebarData.from}
            action={UPDATE_LIVE_TRACKING_PROPS}
            maximumDate={sidebarData.to || new Date()}
            title={local.DateFrom}
            hasTime={true}
            classes='mt-0'
          />
        </Row>
        <Row className='mt-5'>
          <DateInput
            prop='sidebarData.to'
            value={sidebarData.to}
            action={UPDATE_LIVE_TRACKING_PROPS}
            minimumDate={sidebarData.from || null}
            title={local.DateTo}
            hasTime={true}
            classes='mt-0'
          />
          {/* <Col xl={12}>
            <Flatpickr
              className="dateStyle"
              data-enable-time
              value={sidebarData.to}
              onChange={(date) => {
                general(
                  [
                    {
                      prop: "sidebarData.to",
                      value: new Date(date[0]).toISOString(),
                    },
                  ],
                  UPDATE_LIVE_TRACKING_PROPS
                );
              }}
              local={getLanguage() === "en" ? null : Arabic}
              placeholder={local.ToDate}
              options={{
                enableTime: true,
                altInput: true,
                maxDate: new Date(),
              }}
            />
          </Col> */}
        </Row>
        <Row className='mt-5'>
          <Col xl={12} className='text-center'>
            <Button
              type='submit'
              className='btn_search_style'
              onClick={(e) => onSearch(e)}
            >
              {sidebarData.isLoading === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </div>
    );
  };

  return (
    <Sidebar
      rootClassName='mt-5'
      sidebarClassName='mt-5 tracking_sidebar'
      sidebar={renderContent()}
      open={sidebarData.isOpen}
      onSetOpen={onSetSidebarOpen}
      styles={{ sidebar: { background: 'white' } }}
      pullRight={getLanguage() === 'en' ? true : false}
    ></Sidebar>
  );
};

export default TrackingMenu;
