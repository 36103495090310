import { Table } from 'antd';
import { Card, Col, Row } from 'reactstrap';
import { FiXSquare } from 'react-icons/fi';
import {
  isArray,
  renderAntdColumn,
  renderNumber,
} from '../../utils/GeneralFunctions';
import PaginationComponent from '../../utils/Pagination';
import { getLanguage, local } from '../../lang/local';
import { DELETE_COVERAGE_LIST_PROPS } from '../../redux/actions/types';

const CoverageListTable = ({
  CoverageListReducer,
  searchForm,
  getCoverageList,
  removePointsFromEmployee,
  general,
}) => {
  const { coverageList } = CoverageListReducer;
  const { filters } = searchForm;

  const onPageChange = (pageNumber) => {
    filters.page = pageNumber;
    getCoverageList(filters);
  };

  const renderTable = () => {
    const coverageListData = coverageList.data;
    if (coverageListData) {
      if (isArray(coverageListData)) {
        let tableData = [];

        for (let i = 0; i < coverageListData.length; i++) {
          const coverageListItem = coverageListData[i];
          const employee = coverageListItem.worker;
          const workField = coverageListItem.work_field;
          const point = coverageListItem.point;
          tableData.push({
            key: i + 1,
            item: coverageListItem,
            id: coverageListItem.id,
            employeeImage: employee.image,
            employeeName: employee.name,
            employeeMobile: employee.mobile,
            employeeTitle: employee.job_title,
            pointName: point.name,
            pointAddress: point.properties.address,
            workFieldName: workField.name[getLanguage()],
            workFieldAddress: workField.address,
          });
        }
        return tableData;
      }
    }
  };

  const renderEmployeeImage = (employeeImage, coverageListItem, index) => {
    return <img src={employeeImage} alt='' width={20} height={20} />;
  };

  const renderRemoveEmployeePointColumn = (
    employeePointID,
    coverageListItem,
    index
  ) => {
    const workerPointItem = coverageListItem.item;
    const { worker, work_field: workField } = workerPointItem;
    const employeePointData = {
      employeeID: worker.id,
      employeePoints: [employeePointID],
      workField: workField.id,
    };
    const removeRow = () => {
      general(
        [{ prop: `coverageList.data.${index}` }],
        DELETE_COVERAGE_LIST_PROPS
      );
    };
    return (
      <div
        role='button'
        style={{ width: 'fit-content' }}
        onClick={() => removePointsFromEmployee(employeePointData, removeRow)}
      >
        <FiXSquare color='red' fontSize='20px' />
      </div>
    );
  };

  const columns = [
    renderAntdColumn(local.Id, 'id', renderNumber, true, '70px'),
    renderAntdColumn(
      local.EmployeeImage,
      'employeeImage',
      renderEmployeeImage,
      false,
      '90px'
    ),
    renderAntdColumn(local.Worker, 'employeeName'),
    renderAntdColumn(local.Mobile, 'employeeMobile', null, false),
    renderAntdColumn(local.EmployeeRole, 'employeeTitle'),
    renderAntdColumn(local.WorkFieldPoint, 'pointName'),
    renderAntdColumn(local.PointType, 'pointType'),
    renderAntdColumn(local.PointAddress, 'pointAddress'),
    renderAntdColumn(local.WorkField, 'workFieldName'),
    renderAntdColumn(local.WorkFieldAddress, 'workFieldAddress'),
    renderAntdColumn(local.Remove, 'id', renderRemoveEmployeePointColumn),
  ];

  return (
    <Card className='cardStyle mt-2'>
      <Row>
        <Col xl={12}>
          <Table
            dataSource={renderTable() && renderTable()}
            // scroll={{ x:'100px', y: 600 }}
            pagination={{
              pageSize:
                coverageList.pagination && +coverageList.pagination.per_page,
              position: ['none'],
            }}
            size='small'
            columns={columns}
          />
        </Col>
      </Row>
      {coverageList.pagination && (
        <div className='mb-4'>
          <PaginationComponent
            onPageChange={onPageChange}
            count={coverageList.pagination.total_pages || 1}
            currentPage={coverageList.pagination.current_page}
          />
        </div>
      )}
    </Card>
  );
};

export default CoverageListTable;
