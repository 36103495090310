import React, { Component } from "react";
import { Card, Row, Col } from "reactstrap";
import { getLanguage, local } from "../../lang/local";
import marker from "../../assets/img/marker.svg";
import GenericMapy from "../../components/GenericMapy/GenericMapy";

const HistoryMapy = (props) => {
  const { historyObj, map } = props;

  const drawPolyline = () => {
    if (historyObj.polyline !== null) {
      if (historyObj.polyline.length > 0) {
        return {
          points: historyObj.polyline,
        };
      }
    }
  };

  const renderMarker = (lat, lng, type, { zoom = false } = {}) => {
    return {
      latLng: { lat, lng },
      id: type,
      popup: type,
      markerIcon: {
        url: marker,
        imgSize: [30, 30],
      },
      zoom,
    };
  };

  const renderMarkers = () => {
    const markers = [];
    if (historyObj.polyline) {
      if (historyObj.polyline.length > 0) {
        const firstMarker = renderMarker(
          historyObj.polyline[0].lat,
          historyObj.polyline[0].lng,
          local.StartWork,
          { zoom: true }
        );
        markers.push(firstMarker);

        if (historyObj.polyline.length > 1) {
          const lastMarker = renderMarker(
            historyObj.polyline[historyObj.polyline.length - 1].lat,
            historyObj.polyline[historyObj.polyline.length - 1].lng,
            local.EndWork
          );
          markers.push(lastMarker);
        }
      }
    }
    return markers;
  };

  return (
    <Card
      className="cardStyle"
      style={{
        position: "relative",
        width: "100%",
        height: "100%",
      }}
    >
      <Row className={getLanguage() === "en" ? "mr-3" : "ml-3"}>
        <Col style={{ height: "450px" }}>
          <GenericMapy
            map={map}
            mapID="historyMap"
            initialCenter={
              historyObj.lat === ""
                ? {
                    lat: 27.1946646,
                    lng: 33.1485576,
                  }
                : { lat: historyObj.lat, lng: historyObj.lng }
            }
            markers={renderMarkers()}
            zoomToMarkers={false}
            singlePolyLine={drawPolyline()}
            zoomToPolyline={false}
          />
        </Col>
      </Row>
    </Card>
  );
};

export default HistoryMapy;
