import React, { Component, useEffect } from "react";
import { Form, Card, Row, Col, Spinner, CardBody } from "reactstrap";
import icon_btn from "../../assets/img/icon_btn.png";
import Switch from "@material-ui/core/Switch";
import FormGroup from "@material-ui/core/FormGroup";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import CityOwner from "./CityOwner";
import MainPermissions from "./MainPermissions";
import Requester from "./Requester";
import { Tabs } from "antd";

import {
  onSave,
  renderAddButton,
  renderMyPermissionInCreate,
  unsetUserListData,
} from "./UserFunctions";
import { local } from "../../lang/local";
import { labelStyle, leftlStyle } from "../../utils/GeneralStyle";
import {
  checkHasOneCompany,
  getBranchOptions,
  getMyCompany,
  handleSelectChangeEnhanced,
  renderArraw,
  renderGeneralInput,
  renderPasswordInput,
} from "../../utils/GeneralFunctions";
import { UPDATE_USER_PROPS } from "../../redux/actions/types";
import { renderCompanySelect } from "../Compaines/CompainesFunction";
import { renderBranchSelect } from "../Branches/BranchesFunction";
import { RenderTable } from "./UserFunctions";
import { renderWorkFSelect } from "../CompainesWorkFields/CompanyWFFunctions";
import GenericPhoneInput from "../../components/GenericPhoneInput/GenericPhoneInput";
import CustomAsyncPaginate from "../../components/CustomAsyncPaginate/CustomAsyncPaginate";
const { TabPane } = Tabs;

class AddUser extends Component {
  componentDidMount() {
    unsetUserListData(this.props.general);

    this.props.general(
      [
        {
          prop: "permissionsOptions",
          value: renderMyPermissionInCreate(this.props.history),
        },
        {
          prop: "userForm.arrayOptions.company",
          value: getMyCompany(this.props.general),
        },
      ],
      UPDATE_USER_PROPS
    );
    this.props.getCompainesForDropDown();
  }

  renderPermissionLabel = (label) => {
    let title = local[label];
    // if (title === undefined) {
    //   debugger;
    // }
    return title;
  };
  render() {
    const {
      createORUpdateUser,
      userForm,
      permissionsOptions,
      userObj,
      history,
      general,
      companyOptions,
      loadCompaniesOptions,
      loadBranchesOptions,
      branchOptions,
      loadCompaniesWFOptions,
      companyWfOptions,
      CityOwnerPermissions,
      RequesterPermissions,
      setUserProps,
      parent_branch,
      parentBranchOptions
    } = this.props;
    console.log("ima here ", permissionsOptions);
    return (
      <div className="conatiner">
        <Row style={{ height: "100%" }}>
          <Col className="body-container">
            <Form
              onSubmit={(e) =>
                onSave(
                  e,
                  createORUpdateUser,
                  userForm,
                  permissionsOptions,
                  userObj,
                  false,
                  userForm.arrayOptions
                )
              }
              style={{ height: "100%" }}
            >
              <Card className="cardCreate ml-2 mr-2 mt-4">
                <div className="mt-5 mr-5 mt-3 ml-5">
                  <p style={leftlStyle}>{local.AddUser}</p>
                  {renderArraw(history)}
                  <br /> <br />
                  <p className="page_title mt-3" style={leftlStyle}>
                    {local.MainInfo}
                  </p>
                  <br /> <br />
                  <br />
                  <br />
                  <Row className="justify-content-center justify-content-lg-start">
                    <div className="react-select-div mt-4">
                      {renderGeneralInput(
                        "userForm.formvalues.name",
                        userForm.formvalues.name,
                        UPDATE_USER_PROPS,
                        general,
                        local.Name
                      )}
                      <input
                        type="text"
                        style={{ position: "absolute", zIndex: "-1" }}
                      />
                    </div>
                    <div className="react-select-div mt-4">
                      <input
                        type="password"
                        style={{ position: "absolute", zIndex: "-1" }}
                      />
                      {renderPasswordInput(
                        "userForm.formvalues.password",
                        userForm.formvalues.password,
                        UPDATE_USER_PROPS,
                        general,
                        local.Password
                      )}
                    </div>
                    <div className="react-select-div mt-4">
                      {renderPasswordInput(
                        "userForm.formvalues.password_confirmation",
                        userForm.formvalues.password_confirmation,
                        UPDATE_USER_PROPS,
                        general,
                        local.ConfirmPassword
                      )}
                    </div>
                  </Row>
                  <Row className="justify-content-center justify-content-lg-start">
                    <div className="react-select-div mt-4">
                      {renderGeneralInput(
                        "userForm.formvalues.email",
                        userForm.formvalues.email,
                        UPDATE_USER_PROPS,
                        general,
                        local.Email
                      )}
                    </div>
                    <div className="react-select-div mt-4">
                      <GenericPhoneInput
                        prop="userForm.formvalues.mobile"
                        value={userForm.formvalues.mobile}
                        action={UPDATE_USER_PROPS}
                        title={local.Mobile2}
                        general={general}
                        fullWidth
                      />
                    </div>

                    {checkHasOneCompany() === false ? (
                      <div className="react-select-div mt-4">
                        <span className="label_title" style={labelStyle}>
                          {local.Compaines}
                        </span>

                        {renderCompanySelect(
                          "userForm.arrayOptions.company",
                          userForm.arrayOptions.company,
                          UPDATE_USER_PROPS,
                          general,
                          local.Compaines,
                          loadCompaniesOptions,
                          companyOptions,
                          null,
                          [
                            { p: "userForm.arrayOptions.branch_parent", v: null },
                            { p: "parent_branch", v: null },
                            { p: "userForm.formvalues.branches", v: null },
                            { p: "userForm.formvalues.work_fields", v: null },
                          ],
                          "4",
                          userForm.iscreate
                        )}
                      </div>
                    ) : null}

                    {/* <div className="react-select-div mt-4">
                      <span className="label_title" style={labelStyle}>
                        {local.Branches}
                      </span>

                      {renderBranchSelect(
                        "userForm.arrayOptions.branch",
                        userForm.arrayOptions.branch,
                        UPDATE_USER_PROPS,
                        general,
                        local.Branches,
                        loadBranchesOptions,
                        branchOptions,
                        userForm.arrayOptions.company,
                        null,
                        [
                          { p: "userForm.formvalues.work_fields", v: null },
                          { p: "userForm.arrayOptions.branch", v: null },
                        ],
                        "4",
                        userForm.iscreate
                      )}
                    </div> */}
                    <div className="react-select-div mt-4">
                      <CustomAsyncPaginate
                        hasLabel
                        fullWidth
                        loadOptions={getBranchOptions(
                          userForm.arrayOptions.company,
                          { parent_id: 0 }
                        )}
                        placeholder={local.Regions}
                        cacheUniqsArr={[userForm.arrayOptions.company]}
                        value={parent_branch}
                        onChange={(e) => {
                          handleSelectChangeEnhanced(
                            e,
                            "parent_branch",
                            setUserProps,
                            ["userForm.arrayOptions.branch"]
                          );
                        }}
                        additional={{
                          page: 1,
                        }}
                        isDisabled={!!!userForm.arrayOptions.company}
                      />
                      {/* <span className="label_title" style={labelStyle}>
                        {local.Regions}
                      </span>

                      {renderBranchSelect(
                        "userForm.arrayOptions.branch_parent",
                        userForm.arrayOptions.branch_parent,
                        UPDATE_USER_PROPS,
                        general,
                        local.Regions,
                        loadBranchesOptions,
                        parentBranchOptions,
                        userForm.arrayOptions.company,
                        null,
                        [
                          { p: "userForm.arrayOptions.branch", v: [] },
                          { p: "userForm.formvalues.work_fields", v: null },
                        ],
                        "4",
                        userForm.iscreate,
                        undefined,
                        undefined,
                        0
                      )} */}
                    </div>
                    <div className="react-select-div mt-4">
                      {/* <span className="label_title" style={labelStyle}>
                        {local.Cities}
                      </span>

                      {renderBranchSelect(
                        "userForm.arrayOptions.branch",
                        userForm.arrayOptions.branch,
                        UPDATE_USER_PROPS,
                        general,
                        local.Cities,
                        loadBranchesOptions,
                        branchOptions,
                        userForm.arrayOptions.company,
                        null,
                        [
                          { p: "userForm.formvalues.work_fields", v: null },
                        ],
                        "4",
                        userForm.iscreate,
                        undefined,
                        undefined,
                        parent_branch?.id,
                        !!! parent_branch?.id
                      )} */}
                      <CustomAsyncPaginate
                        hasLabel
                        fullWidth
                        loadOptions={getBranchOptions(
                          userForm.arrayOptions.company,
                          { parent_id: parent_branch?.id }
                        )}
                        placeholder={local.Cities}
                        cacheUniqsArr={[parent_branch]}
                        value={userForm.arrayOptions.branch}
                        onChange={(e) => {
                          handleSelectChangeEnhanced(
                            e,
                            "userForm.arrayOptions.branch",
                            setUserProps,
                            ["userForm.formvalues.work_fields"]
                          );
                        }}
                        additional={{
                          page: 1,
                        }}
                        isDisabled={!!!parent_branch}
                      />
                    </div>
                  </Row>
                  <Row className="justify-content-center justify-content-lg-start">
                    <div className="react-select-div mt-4">
                      <span className="label_title" style={labelStyle}>
                        {local.Zones}
                      </span>

                      {renderWorkFSelect(
                        "userForm.formvalues.work_fields",
                        userForm.formvalues.work_fields,
                        UPDATE_USER_PROPS,
                        general,
                        local.Zones,
                        loadCompaniesWFOptions,
                        companyWfOptions,
                        true,
                        null,
                        userForm.arrayOptions.branch?.id,
                        "4",
                        undefined,
                        userForm.iscreate,
                        undefined,
                        undefined,
                        !!!userForm.arrayOptions.branch?.id
                      )}
                    </div>
                    <Col xl={3} className="mt-4">
                      {renderAddButton(
                        general,
                        userForm.arrayOptions,
                        userForm.formvalues.work_fields,
                        companyOptions,
                        branchOptions,
                        !!!userForm.formvalues?.work_fields
                      )}
                    </Col>
                  </Row>
                  <Row className="mt-5">
                    <Col xl={12}>
                      <RenderTable
                        arrayOptions={userForm.arrayOptions}
                        general={general}
                        loadCompaniesWFOptions={loadCompaniesWFOptions}
                      />
                    </Col>
                  </Row>
                  {/* Map Permissions */}
                  <CardBody className="trans">
                    <Row>
                      <Tabs defaultActiveKey="1">
                        <TabPane tab="Main Permissions" key="1">
                          <MainPermissions
                            permissionsOptions={permissionsOptions}
                            userObj={userObj}
                            createORUpdateUser={createORUpdateUser}
                            userForm={userForm}
                          />
                          {/* <FormControl component="fieldset">
          <FormGroup aria-label="position" row>
            {permissionsOptions.length > 0
              ? permissionsOptions.map((per, index) => {
                  return (
                    <Col xl={4} lg={4} className="text-center">
                      <p>{per.permission}</p>
                      <FormControlLabel
                        checked={per.isSelected}
                        onChange={(e) =>{
                          console.log("e.target.checked",e.target.checked)
                          general(
                            [
                              {
                                prop:
                                  "permissionsOptions." + index + ".isSelected",
                                value: e.target.checked,
                              },
                            ],
                            UPDATE_USER_PROPS
                          )
                        }
                          
                        }
                        control={<Switch color="primary" />}
                        label={local[per.permission]}
                        labelPlacement="top"
                      />
                    </Col>
                  );
                })
              : null}
          </FormGroup>
        </FormControl>   */}
                        </TabPane>
                        <TabPane tab="City Owner" key="2">
                          <CityOwner
                            permissionsOptions={permissionsOptions}
                            userObj={userObj}
                            createORUpdateUser={createORUpdateUser}
                            userForm={userForm}
                            CityOwnerPermissions={CityOwnerPermissions}
                          />
                        </TabPane>
                        <TabPane tab="Requester" key="3">
                          <Requester
                            userObj={userObj}
                            createORUpdateUser={createORUpdateUser}
                            userForm={userForm}
                            permissionsOptions={permissionsOptions}
                            RequesterPermissions={RequesterPermissions}
                          />
                        </TabPane>
                      </Tabs>
                    </Row>
                  </CardBody>
                  {/* <Row className='mt-5'>
                    <FormControl component='fieldset'>
                      <FormGroup
                        aria-label='position'
                        row
                        className='text-center'
                      >
                        {permissionsOptions.length > 0
                          ? permissionsOptions.map((per, index) => {
                              if (local[per.permission]) {
                                return (
                                  <Col
                                    xl={2}
                                    lg={2}
                                    md={4}
                                    sm={4}
                                    xs={4}
                                    className='mt-3'
                                  >
                                    <p>{per.permission}</p>
                                    <FormControlLabel
                                      checked={per.isSelected}
                                      onChange={(e) =>
                                        general(
                                          [
                                            {
                                              prop:
                                                'permissionsOptions.' +
                                                index +
                                                '.isSelected',
                                              value: e.target.checked,
                                            },
                                          ],
                                          UPDATE_USER_PROPS
                                        )
                                      }
                                      control={<Switch color='primary' />}
                                      label={this.renderPermissionLabel(
                                        per.permission
                                      )}
                                      labelPlacement='top'
                                    />
                                  </Col>
                                );
                              }
                            })
                          : null}
                      </FormGroup>
                    </FormControl>
                  </Row> */}
                  {/* <center className='mt-5'>
                    <button
                      type='submit'
                      disabled={userForm.isLoading}
                      className='save_btn mt-5'
                      onClick={(e) =>
                        onSave(
                          e,
                          createORUpdateUser,
                          userForm,
                          permissionsOptions,
                          userObj,
                          false,
                          userForm.arrayOptions
                        )
                      }
                    >
                      {userForm.isLoading === true ? (
                        <Spinner type='grow' color='dark' size='sm' />
                      ) : (
                        <>
                          <p style={{ display: 'inline' }}>{local.Save}</p>
                          <img
                            src={icon_btn}
                            width='20'
                            height='20'
                            alt='img'
                          />
                        </>
                      )}
                    </button>
                  </center> */}
                  <br />
                </div>
              </Card>
            </Form>
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({ UserR, CompanyR, BranchR, CompanyWorkFieldR }) => {
  return {
    parent_branch: UserR.parent_branch,
    userForm: UserR.userForm,
    modale: UserR.modale,
    companyOptions: CompanyR.companyOptions,
    permissionsOptions: UserR.permissionsOptions,
    userObj: UserR.userObj,
    parentBranchOptions: BranchR.parentBranchOptions,
    branchOptions: BranchR.branchOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    RequesterPermissions: UserR.RequesterPermissions,
    CityOwnerPermissions: UserR.CityOwnerPermissions,
  };
};

export default connect(mapStateToProps, actions)(AddUser);
