import { useHistory } from "react-router-dom"
import Mobile_Application_Icon from "../../assets/img/mobile - usage.jpeg";
import Dashboard_Icon from "../../assets/img/mobile pins - usage.jpeg";
import { local, getLanguage } from "../../lang/local";

const UsageHeader = ({ history }) => {
  
  const renderPlatformType = () => {
    if (history.location.pathname === "/usage-links-mobile_app") {
      return local.MobileApplication;
    }
    return local.Dashboard;
  }

  const platformType = renderPlatformType();

  return (
    <div
    id="page-header"
    style={{direction: getLanguage() === "ar" ? "rtl" : "ltr"}}
    className="row flex-column-reverse flex-lg-row mx-3 mt-5 px-5 py-3"
    >
      <div style={{fontSize: "20px"}}>
        {local.HowToUse} {platformType}
      </div>
      <div className="my-3">
        <img src={platformType === "Mobile App" ? Mobile_Application_Icon : Dashboard_Icon} height="100px" />
      </div>
    </div>
  )
}

export default UsageHeader;