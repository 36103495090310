import { combineReducers } from "redux";
import LoginReducer from "./LoginReducer";
import StateReducer from "./StateReducer";
import StateWFReducer from "./StateWFReducer";
import CompanyReducer from "./CompanyReducer";
import CompanyWorkFieldReducer from "./CompanyWorkFieldReducer ";
import RequestsR from "./RequestsR";
import WorkerReducer from "./WorkerReducer";
import ReportReducer from "./ReportReducer";
import UserReducer from "./UserReducer";
import ArchivesReducer from "./ArchivesReducer";
import LiveTrackingReducer from "./LiveTrackingReducer";
import ReportTempleteReducer from "./ReportTempleteReducer";
import NotificationsReducer from "./NotificationsReducer";
import HistoryReducer from "./HistoryReducer";
import ActionPointReducer from "./ActionPointReducer";
import VisitReducer from "./VisitReducer";
import HomeReducer from "./HomeReducer";
import ProductReducer from "./ProductReducer";
import GiftsAndSamplesReducer from "./GiftsAndSamplesReducer.js";
import AmountReducer from "./AmountReducer";
import BranchReducer from "./BranchReducer";
import MapReducer from "./MapReducer";
import WorkReportReducer from "./WorkReportReducer";
import ModalsReducer from "./ModalsReducer";
import SearchFormReducer from "./SearchFormReducer";
import CoverageListReducer from "./CoverageListReducer";
import TableReducer from "./TableReducer";
import Ticket_reducer from "./TicketReducer";


export default combineReducers({
  LoginR: LoginReducer,
  StateR: StateReducer,
  StateWFR: StateWFReducer,
  CompanyR: CompanyReducer,
  CompanyWorkFieldR: CompanyWorkFieldReducer,
  RequestsR,
  WorkerR: WorkerReducer,
  //Ticket_reducer
  TicketR:Ticket_reducer,
  ReportR: ReportReducer,
  UserR: UserReducer,
  ArchivesR: ArchivesReducer,
  LiveTrackingR: LiveTrackingReducer,
  ReportTempleteR: ReportTempleteReducer,
  NotificationsR: NotificationsReducer,
  HistoryR: HistoryReducer,
  ActionPointR: ActionPointReducer,
  VisitR: VisitReducer,
  HomeR: HomeReducer,
  ProductR: ProductReducer,
  GiftsAndSamplesReducer,
  AmountR: AmountReducer,
  BranchR: BranchReducer,
  MapReducer,
  WorkReportR: WorkReportReducer,
  ModalsReducer,
  SearchFormReducer,
  TableReducer,
  CoverageListReducer,
});
