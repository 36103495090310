import { useState } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { Col } from 'reactstrap';
import { bindActionCreators } from 'redux';
import { getLanguage } from '../../lang/local';
import { general } from '../../redux/actions';
import { getDeafultItem } from '../../utils/GeneralFunctions';
import { labelStyle, selectStyle3 } from '../../utils/GeneralStyle';

const lang = getLanguage();

const StaticOptionsSelcet = ({
  title,
  prop,
  value,
  action,
  options,
  isMulti = false,
  dependentProps = [],
  general,
  styles = {},
  columns,
  onChange,
  isClearable = true,
  filterOption,
  hasLabel = false,
  selectLabelStyle = {},
  onChangeCB, // on change callback function
}) => {
  const [defaultMenuIsOpen, setDefaultMenuIsOpen] = useState(false);

  const defaultOnChange = (option, actionType) => {
    if (dependentProps.length > 0) {
      let convertDependentPropToDispatch = [];
      for (let i = 0; i < dependentProps.length; i++) {
        convertDependentPropToDispatch.push({
          prop: dependentProps[i],
          value: null,
        });
      }
      general([...convertDependentPropToDispatch], action);
    }
    if (option) {
      isMulti
        ? general([{ prop, value: option }], action)
        : general([{ prop, value: option.value }], action);

      onChangeCB && onChangeCB(option);
    } else general([{ prop, value: null }], action);
  };
  const onFocus = () => setDefaultMenuIsOpen(true);

  return (
    <Col
      id={prop}
      xl={columns ? columns : 3}
      lg={columns ? columns : 3}
      md={columns ? columns : 3}
      sm={12}
      xs={12}
      className='mt-2'
    >
      {hasLabel && (
        <span
          className='label_title'
          style={{ ...labelStyle, ...selectLabelStyle }}
        >
          {title}
        </span>
      )}
      <Select
        key={value}
        placeholder={title}
        styles={{ ...selectStyle3, styles }}
        value={isMulti ? value : getDeafultItem(value, options)}
        isClearable={isClearable}
        onChange={onChange ? onChange : defaultOnChange}
        // onChange={onChange ? { onChange } : defaultOnChange}
        options={options}
        getOptionLabel={(option) => option.label}
        getOptionValue={(option) => option.value}
        isMulti={isMulti}
        filterOption={filterOption}
        closeMenuOnSelect={!isMulti}
        defaultMenuIsOpen={defaultMenuIsOpen}
        onFocus={onFocus}
        autoFocus={true}
      />
    </Col>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(StaticOptionsSelcet);

export const getSelectOptionsArr = (optionsArr) => {
  if (optionsArr) {
    const options = [];
    for (let i = 0; i < optionsArr.length; i++) {
      const currentOption = optionsArr[i];
      options.push({
        ...currentOption,
        label: currentOption.label[lang],
        value: currentOption.value,
      });
    }
    return options;
  }
};
