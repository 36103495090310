export const isArray = (valueToCheck) => {
  return Array.isArray(valueToCheck);
};

export const geoJsonToWKT = (geoJson) => {
  let WKT = "POLYGON((";

  if (geoJson !== undefined) {
    geoJson.map((poly, index) => {
      if (index === 0) {
        WKT = WKT + poly.lng + " " + poly.lat;
      } else {
        WKT = WKT + "," + poly.lng + " " + poly.lat;
      }
    });
    WKT = WKT + "," + geoJson[0].lng + " " + geoJson[0].lat;
    WKT = WKT + "))";
    return WKT;
  }
};

export const WKTPolygonToGeoJson = (WKTPolygon) => {
  if (!WKTPolygon) {
    throw `polygon is ${WKTPolygon}`;
  }

  let geoJsonPolygon = [];
  let polygon = WKTPolygon;
  let removedBracts = polygon.split("(")[2].split(")")[0].split(",");
  removedBracts.map((poly) => {
    geoJsonPolygon.push([+poly.split(" ")[1], +poly.split(" ")[0]]);
  });
  return geoJsonPolygon;
};

export const pointObjectToWKT = (pointObj) => {
  const lat = +pointObj.lat;
  const lng = +pointObj.lng;
  const WKTPoint = "POINT(" + lng + " " + lat + ")";
  return WKTPoint;
};

export const WKTPointToGeoJson = (WKTPoint) => {
  const lat = WKTPoint.split("(")[1].split(")")[0].split(" ")[0];
  const lng = WKTPoint.split("(")[1].split(")")[0].split(" ")[1];
  const geoJson = [+lng, +lat];
  return geoJson;
};

export const isLatLngLiteral = (latLngLiteral) => {
  if (!latLngLiteral) return false;
  if (typeof latLngLiteral !== "object") return false;
  if (
    !latLngLiteral.hasOwnProperty("lat") ||
    !latLngLiteral.hasOwnProperty("lng")
  )
    return false;
  return true;
};

export const isLatLngTuple = (latLngTuple) => {
  if (!latLngTuple) return false;
  if (isArray(latLngTuple) === false) return false;
  if (latLngTuple.length < 2) return false;
  return true;
};

export const isWKTPoint = (WKTPoint) => {
  if (typeof WKTPoint !== "string") return false;
  if (!WKTPoint.startsWith("POINT(")) return false;
  return true;
};

export const renderGooglePolygon = (polygon) => {
  if (!polygon) {
    throw `polygon is ${polygon}`;
  }
  let newPolygons = [];
  let removedBracts = polygon.split("(")[2].split(")")[0].split(",");

  removedBracts.map((poly) => {
    newPolygons.push({
      lat: +poly.split(" ")[1],
      lng: +poly.split(" ")[0],
    });
  });

  return newPolygons;
};

export const pointHasID = (point) => {
  if (typeof point !== "object") return false;
  if (!point.hasOwnProperty("id")) return false;
  if (!point.hasOwnProperty("latLng")) return false;
  return true;
};
