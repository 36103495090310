import { getLanguage } from "../../lang/local";
import {
  CLEAR_COMPANY_WORK_FIELD_STATE,
  UPDATE_COMPANY_WORK_FIELD_PROPS,
  MERGE_COMPANY_WORK_FIELD_PROPS,
  DELETE_COMPANY_WORK_FIELD_PROPS,
} from "../actions/types";
import * as general from "./methods";

const INITIAL_STATE = {
  companyWfObj: { companyWfItem: null, isLoading: false, lat: null, lng: null },
  sorting: { column: "", order: "desc" },
  searchCircle: [],
  searchForm: {
    parent_branch: null,
    page: 1,
    company: null,
    branch_id: null,
    workFieldName: "",
    mobile: "",
    from: "",
    to: "",
    name: "",
    id: "",
  },

  companyWfForm: {
    isLoading: false,
    formvalues: {
      parent_branch: null,
      branch_id: null,
      name: { ar: "", en: "" },
      address: "",
      location: null,
      company: null,
    },
    index: null,
    iscreate: "create",
    reloadDrawing: false,
  },
  companyWfOptions: [],
  modaleView: {
    isOpen: false,
    data: null,
    title: "",
  },
  allCompanyWorkFields: null,
  modale: {
    isOpen: false,
    tobeConfirmed: false,
    title: "",
    actionType: "",
    tabs_title: [
      {
        title:
          getLanguage() === "en" ? "Main Information" : "البيانات الرئيسية",
        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
      {
        title: getLanguage() === "en" ? "Work Places" : "اماكن العمل",

        active: "active",
        dots_pagination_active: "dots_pagination_active",
      },
    ],

    mapItem: {
      polygon: [],
      mapPoints: [31.4177636, 30.00475],
    },
    activeIndex: 0,
    tabAction: "next",
  },

  isLoadingSearch: false,
  searchOptions: [],
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_COMPANY_WORK_FIELD_PROPS: {
      return general.updateProps(state, action);
    }
    case MERGE_COMPANY_WORK_FIELD_PROPS: {
      return general.mergeProps(state, action);
    }
    case DELETE_COMPANY_WORK_FIELD_PROPS: {
      return general.deleteProps(state, action);
    }
    case CLEAR_COMPANY_WORK_FIELD_STATE: {
      let newState = state;
      newState = INITIAL_STATE;
      return newState;
    }

    default:
      return state;
  }
};
