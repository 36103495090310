import { getToken, HEADERS, RequestLogin, Select_Request } from "../Api/index";
import { UPDATE_LOGIN_PROPS, UPDATE_USER_PROPS } from "./types";
import { SERVICE_USER } from "../../utils/StaticKeys";
import {
  checkHasOneCompany,
  confirmSunccessed,
} from "../../utils/GeneralFunctions";
import { local } from "../../lang/local";
import config from "../../config.json";
import { getCompaines } from ".";

export const login = (formValues, history) => (dispatch) => {
  dispatch({
    type: UPDATE_LOGIN_PROPS,
    payload: [{ prop: "loginObj.isLoading", value: true }],
  });
  RequestLogin()
    .post("/login", formValues)
    .then((res) => {
      if (res.data.status === true) {
        let allBranchesAccess = false;
        let allWorkFieldAccess = false;

        res.data.data.permissions.forEach((per) => {
          if (per.permission === "all_branches") {
            allBranchesAccess = true;
          } else if (per.permission === "all_work_fields") {
            allWorkFieldAccess = true;
          }
        });

        const newLocaldata = {
          ...res.data.data,
          allBranchesAccess,
          allWorkFieldAccess,
        };
        
        localStorage.setItem(SERVICE_USER, JSON.stringify(newLocaldata));
        // let newRequest = Select_Request;
        Select_Request.options.headers.Authorization = "Bearer " + getToken();
        HEADERS.Authorization = "Bearer " + getToken();
        history.push("/dashboard/home");
        console.log(Select_Request);
        // window.location.reload();
      } else {
        confirmSunccessed(local.LoginErrorMessage);
        // Object.entries(res.data.error.message).map((msg) => {
        //   toaster.notify(msg[1]);
        // });
      }

      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: "loginObj.isLoading", value: false }],
      });
    })
    .then((res) => {
      if (checkHasOneCompany()) {
        dispatch(getCompaines());
      }
    })
    .catch((err) => {
      dispatch({
        type: UPDATE_LOGIN_PROPS,
        payload: [{ prop: "loginObj.isLoading", value: false }],
      });
      console.log(err);
    })
    .finally();
};
