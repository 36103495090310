import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Col, Row } from 'reactstrap';
import ArchivesView from './ArchivesView';
import * as actions from '../../redux/actions/index';
import {  getMyCompany } from '../../utils/GeneralFunctions';
import ArchiveSearch from './ArchiveSearch';
import {
  CLEAR_ARCHIVES_STATE,
  UPDATE_ARCHIVES_PROPS,
} from '../../redux/actions/types';
import { local } from '../../lang/local';
import LightBoxFile from '../../utils/LightBoxFile';
class Archives extends Component {
  componentDidMount() {
    this.renderArchives();
  }

  renderArchives = () => {
    const { general } = this.props;
    general([], CLEAR_ARCHIVES_STATE);
    general(
      [{ prop: 'searchForm.company', value: getMyCompany(general) }],
      UPDATE_ARCHIVES_PROPS
    );
  };

  componentWillUnmount() {
    this.unsetForm();
  }
  unsetForm = () => {
    const { general } = this.props;
    general(
      [
        { prop: 'lightbox', value: { isOpen: false, image: null } },
        {
          prop: 'modale',
          value: {
            isOpen: false,
            tobeConfirmed: false,
            title: '',
            actionType: '',
            tabs_title: [
              {
                title: local.ReportInfo,
                active: 'active',
                dots_pagination_active: 'dots_pagination_active',
              },
              {
                title: local.ReportContent,
                active: 'active',
                dots_pagination_active: 'dots_pagination_active',
              },
            ],
            activeIndex: 0,
            tabAction: 'next',
          },
        },
      ],
      UPDATE_ARCHIVES_PROPS
    );
  };
  render() {
    const {
      history,
      general,
      archivesObj,
      sorting,
      lightbox,
      searchForm,
      isLoadingSearch,
      companyWfOptions,
      companyOptions,
      getArchives,
      getCompainesWFForDropDown,
      workerOptions,
      getWorkersForDropDown,
      loadCompaniesWFOptions,
      loadWorkerOptions,
      loadCompaniesOptions,
      loadBranchesOptions,
      branchOptions,
    } = this.props;
    return (
      <div className='page-wrapper'>
        <LightBoxFile
          lightbox={lightbox}
          general={general}
          action={UPDATE_ARCHIVES_PROPS}
        />
        <Row className='mt-2'>
          <Col xl={12} lg={12} md={12} sm={12} xs={12}>
            <ArchiveSearch
              history={history}
              searchForm={searchForm}
              isLoadingSearch={isLoadingSearch}
              companyWfOptions={companyWfOptions}
              companyOptions={companyOptions}
              getArchives={getArchives}
              getCompainesWFForDropDown={getCompainesWFForDropDown}
              general={general}
              workerOptions={workerOptions}
              getWorkersForDropDown={getWorkersForDropDown}
              loadCompaniesWFOptions={loadCompaniesWFOptions}
              loadWorkerOptions={loadWorkerOptions}
              loadCompaniesOptions={loadCompaniesOptions}
              loadBranchesOptions={loadBranchesOptions}
              branchOptions={branchOptions}
            />

            <ArchivesView
              general={general}
              archivesObj={archivesObj}
              sorting={sorting}
              lightbox={lightbox}
              searchForm={searchForm}
              getArchives={getArchives}
              {...this.props}
            />
          </Col>
        </Row>
      </div>
    );
  }
}

const mapStateToProps = ({
  ArchivesR,
  CompanyWorkFieldR,
  CompanyR,
  WorkerR,
  BranchR,
}) => {
  return {
    archivesObj: ArchivesR.archivesObj,
    modale: ArchivesR.modale,
    sorting: ArchivesR.sorting,
    lightbox: ArchivesR.lightbox,
    searchForm: ArchivesR.searchForm,
    isLoadingSearch: ArchivesR.isLoadingSearch,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    companyOptions: CompanyR.companyOptions,
    workerOptions: WorkerR.workerOptions,
    branchOptions: BranchR.branchOptions,
  };
};

export default connect(mapStateToProps, actions)(Archives);
