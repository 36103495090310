import React from 'react';
import { Card, Col, Row, Button, Form, Spinner } from 'reactstrap';
import Select from 'react-select';
import { leftlStyle } from '../../utils/GeneralStyle';
import { local } from '../../lang/local';
import {
  checkHasOneCompany,
  handleSelectChangeEnhanced,
  renderArraw,
} from '../../utils/GeneralFunctions';
import { selectStyle3 } from '../../utils/GeneralStyle';
import CustomAsyncPaginate from '../../components/CustomAsyncPaginate/CustomAsyncPaginate';
import DateInput from '../../components/DateInput/DateInput';
import { UPDATE_GIFTS_PROPS } from '../../redux/actions/types';

const GiftsSearch = (props) => {
  const {
    history,
    isLoadingSearch,
    searchForm,
    getCompanyOptions,
    getBranchesOptions,
    getWorkFieldOptions,
    getWorkFieldpointsOptions,
    getGiftsTableData,
    getGiftsReportData,
    giftsTableData,
    setGiftsProps,
  } = props;

  const onSearch = (e) => {
    e.preventDefault();
    getGiftsTableData(searchForm, 1);
    getGiftsReportData(searchForm, 1);
    console.log('searching');
    console.log(giftsTableData?.pagination);
  };

  return (
    <Card className='cardStyle cardSearch mt-1'>
      <Form onSubmit={onSearch} className='mt-4 mb-4'>
        <Row>
          <Col>
            <p className='page_title' style={leftlStyle}>
              {local.Gifts_And_Samples}
            </p>
          </Col>
          {renderArraw(history)}
        </Row>

        <Row>
          {checkHasOneCompany() === false ? (
            <CustomAsyncPaginate
              loadOptions={getCompanyOptions}
              value={searchForm.company}
              placeholder={local.Company}
              onChange={(e) =>
                handleSelectChangeEnhanced(
                  e,
                  'searchForm.company',
                  setGiftsProps,
                  [
                    'searchForm.branch',
                    'searchForm.workFields',
                    'searchForm.workFieldsPoints',
                  ]
                )
              }
              additional={{
                page: 1,
              }}
            />
          ) : null}

          <CustomAsyncPaginate
            loadOptions={getBranchesOptions}
            placeholder={local.Branch}
            value={searchForm.branch}
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                'searchForm.branch',
                setGiftsProps,
                ['searchForm.workFields', 'searchForm.workFieldsPoints']
              )
            }
            cacheUniqsArr={[searchForm.company]}
            additional={{
              page: 1,
              companyId: searchForm.company?.id,
            }}
          />

          <CustomAsyncPaginate
            loadOptions={getWorkFieldOptions}
            value={searchForm.workFields}
            placeholder={local.WorkField}
            cacheUniqsArr={[searchForm.branch]}
            isMulti={true}
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                'searchForm.workFields',
                setGiftsProps,
                ['searchForm.workFieldsPoints']
              )
            }
            additional={{
              page: 1,
              branchId: searchForm.branch?.id,
            }}
          />

          <CustomAsyncPaginate
            loadOptions={getWorkFieldpointsOptions}
            value={searchForm.workFieldsPoints}
            placeholder={local.WorkFieldPoint}
            onChange={(e) =>
              handleSelectChangeEnhanced(
                e,
                'searchForm.workFieldsPoints',
                setGiftsProps
              )
            }
            cacheUniqsArr={[searchForm.workFields]}
            additional={{
              page: 1,
              companyId: searchForm.company?.id,
              workFields: searchForm.workFields,
            }}
          />

          <CustomAsyncPaginate
            loadOptions={[]}
            cacheUniqsArr={[searchForm.company]}
            additional={{
              page: 1,
              companyId: searchForm.company?.id,
            }}
          />

          <DateInput
            prop='searchForm.date.from'
            value={searchForm.date.from}
            action={UPDATE_GIFTS_PROPS}
            maximumDate={searchForm.date.to || new Date()}
            title={local.DateFrom}
          />

          <DateInput
            prop='searchForm.date.to'
            value={searchForm.date.to}
            action={UPDATE_GIFTS_PROPS}
            minimumDate={searchForm.date.from || null}
            title={local.DateTo}
          />

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            <Select
              options={[
                { label: local.Gift, value: 0 },
                { label: local.Sample, value: 1 },
              ]}
              value={
                searchForm.type === null
                  ? null
                  : {
                      label: searchForm.type === 0 ? local.Gift : local.Sample,
                      value: searchForm.type,
                    }
              }
              styles={selectStyle3}
              placeholder={local.Type}
              onChange={(e) => {
                setGiftsProps([['searchForm.type', e.value]]);
              }}
              additional={{
                page: 1,
              }}
            />
          </Col>

          <Col xl={3} lg={3} md={3} sm={12} xs={12} className='mt-2'>
            <Button
              style={leftlStyle}
              type='submit'
              className='btn_search_style'
              disabled={isLoadingSearch}
              onClick={(e) => onSearch(e)}
            >
              {searchForm.isSearching === true ? (
                <Spinner type='grow' color='dark' size='sm' />
              ) : (
                local.Search
              )}
            </Button>
          </Col>
        </Row>
      </Form>
    </Card>
  );
};

export default GiftsSearch;
