import React from 'react';
import { Card, Col, Row, Table as ReactStrapTable } from 'reactstrap';
import { Table } from 'antd';
import { getLanguage, local } from '../../lang/local';
import {
  renderTableHeadItem,
  renderNumber,
  renderDate,
  renderPagination,
  renderTime,
  onOpenProfile,
  renderAntdColumn,
} from '../../utils/GeneralFunctions';
import { UPDATE_ACTION_POINT_PROPS } from '../../redux/actions/types';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import ActionPointProfile from './ActionPointProfile';
import { leftlStyle } from '../../utils/GeneralStyle';

const AactionPointTable = (props) => {
  const {
    actionPointObj,
    general,
    sorting,
    getActionPoints,
    searchForm,
    modaleView,
    getGiftsReportData,
    getAmounts,
    map,
    onMarkerClick,
  } = props;

  const onViewLocation = (e, point, pointItem) => {
    e.preventDefault();

    if (point !== null) {
      let lat = point.split('(')[1].split(')')[0].split(' ')[1];

      let lng = point.split('(')[1].split(')')[0].split(' ')[0];
      // general(
      //   [
      //     { prop: "actionPointObj.lat", value: lat },
      //     { prop: "actionPointObj.lng", value: lng },
      //   ],
      //   UPDATE_ACTION_POINT_PROPS
      // );

      console.log(`pointItem`, pointItem);

      map.setCenter([lat, lng], 18);
      onMarkerClick(pointItem.item.point.id, pointItem.item.point);
    }
  };

  const getTableData = () => {
    if (actionPointObj.actionPointObjItem !== null) {
      let tableData = [];

      for (let i = 0; i < actionPointObj.actionPointObjItem.data.length; i++) {
        const actionPoint = actionPointObj.actionPointObjItem.data[i];
        console.log('actionPoint', actionPoint);
        const employee = actionPoint.worker;
        const point = actionPoint.point;
        const action = actionPoint.action;

        tableData.push({
          key: i + 1,
          item: actionPoint,
          id: actionPoint.id,
          employeeName: employee.name,
          employeeImage: employee.image,
          workField: point.work_field,
          pointName: point.name,
          pointType: point.place_type,
          pointAddress: point.properties.address,
          products: action.products,
          visitStatus: action.point,
          moderator: actionPoint.moderator,
          createdAt: actionPoint.created_at,
          arrivalTime: action.arriveTime,
          notes: action.data,
          pointLocation: point.location,
        });
      }
      console.log('tableData', tableData);
      return tableData;
    }
  };

  const renderEmployeeName = (name, actionPointData) => {
    return (
      <div
        className='td_table'
        style={{ cursor: 'pointer', textDecoration: 'underline' }}
        onClick={() =>
          onOpenProfile(
            actionPointData.item,
            local.ActionPoints,
            general,
            UPDATE_ACTION_POINT_PROPS,
            false,
            undefined,
            {
              getGiftsReportData,
              getAmounts,
            }
          )
        }
      >
        {name}
      </div>
    );
  };

  const renderWorkField = (workField) => {
    return <div className='td_table'>{workField.name[getLanguage()]}</div>;
  };

  const renderVisitStatus = (visitStatus) => {
    return (
      <div className='td_table'>
        {visitStatus === 'false' || visitStatus === false
          ? local.DidNotSucceed
          : local.Succeeded}
      </div>
    );
  };

  const renderProducts = (products) => {
    if (products.length === 0) return '-';
    const productsDiv = [];
    for (let i = 0; i < products.length; i++) {
      const currentProduct = products[i];
      const productDiv = <div>{currentProduct.name}</div>;
      productsDiv.push(productDiv);
    }
    return productsDiv;
  };

  const renderPointLocation = (location, record) => {
    return (
      <div className='td_table'>
        <a
          href
          style={{ cursor: 'pointer', textDecoration: 'underline' }}
          onClick={(e) => onViewLocation(e, location, record)}
        >
          {local.View}
        </a>
      </div>
    );
  };

  const columns = [
    renderAntdColumn(local.Id, 'id', (id) => renderNumber(id)),
    renderAntdColumn(local.worker_name, 'employeeName', renderEmployeeName),
    // renderAntdColumn(
    //   local.worker_image,
    //   "employeeImage",
    //   (image) => rednerImageInTable(image),
    //   false
    // ),
    renderAntdColumn(local.WorkField, 'workField', renderWorkField),
    renderAntdColumn(local.Address, 'pointAddress'),
    renderAntdColumn(local.VisitStatus, 'visitStatus', renderVisitStatus),
    renderAntdColumn(local.VisitType, 'moderator', (moderator) =>
      moderator !== null ? local.Double : local.Single
    ),
    renderAntdColumn(local.Products, 'products', renderProducts),
    // renderAntdColumn(
    //   local.Supervisor,
    //   "moderator",
    //   (moderator) => moderator !== null && moderator.name
    // ),
    renderAntdColumn(local.DateAdded, 'createdAt', (date) => renderDate(date)),
    renderAntdColumn(
      local.Time,
      'arrivalTime',
      (time) => renderTime(`${time} UTC`),
      false
    ),
    renderAntdColumn(local.Notes, 'notes'),
    renderAntdColumn(
      local.PointOnMap,
      'pointLocation',
      renderPointLocation,
      false
    ),
  ];

  const renderBody = () => {
    if (actionPointObj.actionPointObjItem !== null) {
      return actionPointObj.actionPointObjItem.data.map(
        (action_point, index) => {
          return (
            <tr key={index}>
              <td className='td_table'>{renderNumber(action_point.id)}</td>
              <td
                className='td_table'
                style={{ cursor: 'pointer', textDecoration: 'underline' }}
                onClick={() =>
                  onOpenProfile(
                    action_point,
                    local.ActionPoints,
                    general,
                    UPDATE_ACTION_POINT_PROPS,
                    false,
                    undefined,
                    getGiftsReportData
                  )
                }
              >
                {action_point.worker.name}
              </td>

              <td className='td_table'>
                {action_point.point.work_field.name[getLanguage()]}
              </td>

              <td className='td_table'>
                {action_point.point.properties.address}
              </td>

              <td className='td_table'>
                {action_point.action.point === 'false' ||
                action_point.action.point === false
                  ? local.DidNotSucceed
                  : local.Succeeded}
              </td>
              <td className='td_table'>
                {action_point.moderator !== null ? local.Double : local.Single}
              </td>

              <td className='td_table'>
                {renderProducts(action_point.action.products)}
              </td>

              <td className='td_table'>
                {renderDate(action_point.created_at)}
              </td>
              <td className='td_table'>
                {renderTime(action_point.action.arriveTime)}
              </td>
              <td className='td_table'>{action_point.action.data}</td>
              <td className='td_table'>
                <a
                  href
                  style={{ cursor: 'pointer', textDecoration: 'underline' }}
                  onClick={(e) =>
                    onViewLocation(e, action_point.point.location)
                  }
                >
                  {local.View}
                </a>
              </td>
            </tr>
          );
        }
      );
    }
  };

  return (
    <Card className='cardStyle mt-2'>
      <ActionPointProfile
        {...props}
        modaleView={modaleView}
        general={general}
      />
      <Row>
        <Col xl={12}>
          <Table
            dataSource={getTableData() && getTableData()}
            columns={columns}
            // scroll={{ x: 'calc(100px + 20%)', y: 600 }}
            pagination={{
              pageSize:
                actionPointObj.actionPointObjItem &&
                +actionPointObj.actionPointObjItem.pagination.per_page,
              position: ['none'],
            }}
            size='small'
            // style={{ textAlign: 'start' ,width:'1150px'}}
          />
          <ReactStrapTable
            responsive
            hover
            id='action_point_table'
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
              display: 'none',
            }}
          >
            <thead>
              <tr className='table_head'>
                {renderTableHeadItem(
                  local.Id,
                  actionPointObj.actionPointObjItem,
                  'id',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.worker_name,
                  actionPointObj.actionPointObjItem,
                  'worker.name.en',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {/* {renderTableHeadItem(
                local.worker_image,
                actionPointObj.actionPointObjItem,
                "worker.image",
                "actionPointObj.actionPointObjItem.data",
                UPDATE_ACTION_POINT_PROPS,
                general,
                sorting
              )} */}
                {renderTableHeadItem(
                  local.WorkPlaces,
                  actionPointObj.actionPointObjItem,
                  'dayily_report.point.work_field_id',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.WorkFieldPoint,
                  actionPointObj.actionPointObjItem,
                  'point.name',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Address,
                  actionPointObj.actionPointObjItem,
                  'point.properties.address',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.VisitStatus,
                  actionPointObj.actionPointObjItem,
                  'action.point',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}

                {renderTableHeadItem(
                  local.VisitType,
                  actionPointObj.actionPointObjItem,
                  'action.point',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Products,
                  actionPointObj.actionPointObjItem,
                  'action.products',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.DateAdded,
                  actionPointObj.actionPointObjItem,
                  'created_at',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Time,
                  actionPointObj.actionPointObjItem,
                  'created_at',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.Notes,
                  actionPointObj.actionPointObjItem,
                  'action.data',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
                {renderTableHeadItem(
                  local.ViewonMap,
                  actionPointObj.actionPointObjItem,
                  'location',
                  'actionPointObj.actionPointObjItem.data',
                  UPDATE_ACTION_POINT_PROPS,
                  general,
                  sorting
                )}
              </tr>
            </thead>
            <tbody>{renderBody()}</tbody>
          </ReactStrapTable>
          <div style={{ minWidth: '350px' }}>
            {actionPointObj.visit_successed !== null &&
            actionPointObj.visit_failed !== null ? (
              <Row className='mt-4'>
                <Col>
                  <Row>
                    <Col>
                      <p className='green_text' style={{ textAlign: 'center' }}>
                        {actionPointObj.actionPointObjItem &&
                          `${actionPointObj.actionPointObjItem.additional.successful} ${local.Successfulvisit}`}
                      </p>
                    </Col>

                    <Col>
                      <p style={{ color: 'red', textAlign: 'center' }}>
                        {actionPointObj.actionPointObjItem &&
                          `${actionPointObj.actionPointObjItem.additional.failed} ${local.NotSuccessfulVisit}`}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </Row>
            ) : null}
          </div>
        </Col>
      </Row>

      {actionPointObj.actionPointObjItem !== null ? (
        <Row className='mt-3 mb-1 alignItem' style={{ direction: 'ltr' }}>
          <Col xl={6}>
            <ReactHTMLTableToExcel
              id='test-table-xls-button'
              className='download-table-xls-button'
              table='action_point_table'
              filename={local.ActionPoints}
              sheet='tablexls'
              buttonText={local.downloadAsEcelFile}
            />
          </Col>
          <Col xl={6}>
            <div style={leftlStyle}>
              <span>{local.Total + ' : '}</span>
              <span className='green_text'>
                {actionPointObj.actionPointObjItem.pagination.total}
              </span>
            </div>
          </Col>
        </Row>
      ) : null}
      {renderPagination(
        actionPointObj.actionPointObjItem,
        searchForm,
        getActionPoints
      )}
    </Card>
  );
};

export default AactionPointTable;
