import React from "react";
import { connect } from "react-redux";
import * as action from "../../../../redux/actions/types";
import { UPDATE_REQUESTS_PROPS } from "../../../../redux/actions/types";
import Dropdown from "../../../../components/AsyncPaginate/Dropdown";
import Input from "../../../../components/Input/Input";
import StaticDropdown from "../../../../components/StaticDropdown/StaticDropdown";
import {
  city,
  errandChannel,
  numOfPos,
  posType,
  requesterFunction,
} from "../../../../utils/staticOptions";
import { visitTypeOptions } from "../../../../utils/visitTypeOptions";
import "./step.css";

const ErrandDetails = ({ pointForm, general }) => {
  return (
    <div className="grid grid-cols-1 lg:grid-cols-2 mx-auto lg:w-2/3 gap-x-7 gap-y-12 mt-16 ">
      <StaticDropdown
        label="Errand Channel"
        options={errandChannel}
        prop="pointForm.formvalues.properties.errand_channel"
        value={pointForm.formvalues.properties.errand_channel}
        action={UPDATE_REQUESTS_PROPS}
      />
      <StaticDropdown
        label="POS Type"
        options={posType}
        prop="pointForm.formvalues.properties.pos_type"
        value={pointForm.formvalues.properties.pos_type}
        action={UPDATE_REQUESTS_PROPS}
      />
      {/* <StaticDropdown
        label="Number Of items"
        options={numOfPos}
        prop="pointForm.formvalues.properties.num_of_pos"
        value={pointForm.formvalues.properties.num_of_pos}
        action={UPDATE_REQUESTS_PROPS}
      /> */}
      <Input
        type="number"
        label={"Number Of items"}
        value={pointForm.formvalues.properties.num_of_pos}
        prop="pointForm.formvalues.properties.num_of_pos"
        action={UPDATE_REQUESTS_PROPS}
        general={general}
      />
      <StaticDropdown
        label="Department"
        options={requesterFunction}
        prop="pointForm.formvalues.properties.requester_function"
        value={pointForm.formvalues.properties.requester_function}
        action={UPDATE_REQUESTS_PROPS}
      />

      <StaticDropdown
        // title={local.VisitType}
        // label={local.VisitType}
        title={"Errand Type"}
        label={"Errand Type"}
        prop="pointForm.formvalues.visit_type"
        value={pointForm.formvalues.visit_type}
        action={UPDATE_REQUESTS_PROPS}
        options={visitTypeOptions}
      />
      {/* <Dropdown label="POS Type" />
      <Dropdown label="FST" />
      <Dropdown label="Number Of POSs / Sim / Paper Rol" /> */}
    </div>
  );
};

const mapStateToProps = ({ RequestsR }) => {
  return {
    pointForm: RequestsR.pointForm,
  };
};

export default connect(mapStateToProps, action)(ErrandDetails);
