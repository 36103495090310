import React, { Component, useEffect } from "react";
import { Form, Card } from "reactstrap";
// import {general} from '../../redux/actions/GeneralAction'
import { connect } from "react-redux";
import * as actions from "../../redux/actions/index";
import FirstTab from "./FirstTab";
import SecondTab from "./SecondTab";
import ThirdTab from "./ThirdTab";
import { onSave } from "./UserFunctions";
import { getMyCompany } from "../../utils/GeneralFunctions";
import { UPDATE_USER_PROPS } from "../../redux/actions/types";
// import {renderCityOwnerPermissionInCreate} from './UserFunctions'
 
class CreateUpdateUser extends Component {
   componentDidMount() {
    this.fetchData();
    // this.props.general(
    //   [
    //     {
    //       prop: 'CityOwnerPermissions',
    //       value:[this.props.permissionsOptions[0]]

    //     },
         
    //   ],
    //   UPDATE_USER_PROPS
    // )   
  }

  fetchData = () => {
    const { modale, general } = this.props;
    if (modale.actionType === "AddSupervisor" || modale.actionType === "EditSupervisor") {
      general(
        [
          {
            prop: "userForm.arrayOptions.company",
            value: getMyCompany(general),
          },
        ],
        UPDATE_USER_PROPS
      );
    }
  };

  renderTabs = () => {
    const {
      userForm,
      general,
      companyOptions,
      modale,
      permissionsOptions,
      createORUpdateUser,
      userObj,
      loadCompaniesOptions,
      loadBranchesOptions,
      branchOptions,
      loadCompaniesWFOptions,
      companyWfOptions,
      CityOwnerPermissions,
      RequesterPermissions,
      setUserProps
    } = this.props;

    if (modale.activeIndex === 0) {
      return (
        <FirstTab
          general={general}
          userForm={userForm}
          iscreate={userForm.iscreate}
        />
      );
    } else if (modale.activeIndex === 1) {
      return (
        <SecondTab
          general={general}
          userForm={userForm}
          companyOptions={companyOptions}
          iscreate={userForm.iscreate}
          loadCompaniesOptions={loadCompaniesOptions}
          loadBranchesOptions={loadBranchesOptions}
          branchOptions={branchOptions}
          loadCompaniesWFOptions={loadCompaniesWFOptions}
          companyWfOptions={companyWfOptions}
          setUserProps={setUserProps}
        />
      );
    } else {
    
          
       return (
        <ThirdTab
          general={general}
          userForm={userForm}
          userObj={userObj}
          createORUpdateUser={createORUpdateUser}
          permissionsOptions={permissionsOptions}
          CityOwnerPermissions={CityOwnerPermissions}
          RequesterPermissions={RequesterPermissions}
        />
      );
    }
  };
  render() {
    const {
      modale,
      createORUpdateUser,
      userForm,
      permissionsOptions,
      CityOwnerPermissions,
      userObj,
    } = this.props;
    return (
      <Form
        onSubmit={(e) =>
          onSave(
            e,
            createORUpdateUser,
            userForm,
            CityOwnerPermissions,
            userObj,
            true,
            userForm.arrayOptions
          )
        }
      >
        <div className="conatiner">
          <Card className="cardStyle">
            <div className="ml-3 mr-3">
              <p className="page_title text-center">{modale.title}</p>
              {this.renderTabs()}
            </div>
          </Card>
        </div>
      </Form>
    );
  }
}

const mapStateToProps = ({ UserR, CompanyR, BranchR, CompanyWorkFieldR }) => {
  return {
    userForm: UserR.userForm,
    modale: UserR.modale,
    companyOptions: CompanyR.companyOptions,
    permissionsOptions: UserR.permissionsOptions,
    CityOwnerPermissions: UserR.CityOwnerPermissions,
    
    userObj: UserR.userObj,
    branchOptions: BranchR.branchOptions,
    companyWfOptions: CompanyWorkFieldR.companyWfOptions,
    RequesterPermissions:UserR.RequesterPermissions
    
  };
};

export default connect(mapStateToProps, actions)(CreateUpdateUser);
