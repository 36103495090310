import React, { useState, useEffect, useRef } from "react";
import Dropdown from "../../components/AsyncPaginate/Dropdown";
import Camer_View from "../../assets/img/Group 48095475.png";
import MicroPhone from "../../assets/img/Group 48095502.svg";
import File from "../../assets/img/Vector.png";
import Camera from "../../assets/img/Group 48095503.svg";
import { local, getLanguage } from "../../lang/local";
import { placeTypeOptions } from "../../utils/placeTypeOptions";
import { Modal } from "antd";
import { connect } from "react-redux";
import { useReactToPrint } from "react-to-print";
import config from '../../config';

const TicketInfo = ({ title, data }) => (
  <div className="flex gap-2 text-sm">
    <p className="text-gray-500">{title}</p>
    <p className="font-bold text-gray-400">{data}</p>
  </div>
);

// console.log("any thing")
const Ticket = ({
  ticketData,
  requestData,
  pointData,
  company,
  pointId,
  getTicketDetails_Actions,
  Updatedat,
}) => {
  const [action, setAction] = useState(undefined);
  const [singleImage, setSingleImage] = useState("");
  const [OpenAudio, setOpenAudio] = useState(false);
console.log("ticketDataticketDataticketData",ticketData,requestData)
  const ToEditDate = (date) => {
    const Reassign_CreatedAt = new Date(date).toLocaleString("en-US", {
      timeZone: "Africa/Cairo",
    });
    return Reassign_CreatedAt;
  };
  const created_at = new Date(requestData?.created_at).toLocaleString("en-US", {
    timeZone: "Africa/Cairo",
  });

  const updated_at = new Date(Updatedat).toLocaleString("en-US", {
    timeZone: "Africa/Cairo",
  });
  const assign_at = requestData?.visit_created_at
    ? new Date(requestData?.visit_created_at).toLocaleString("en-US", {
        timeZone: "Africa/Cairo",
      })
    : "not assigned";
  const visit_at = getTicketDetails_Actions[0]?.created_at
    ? new Date(getTicketDetails_Actions[0]?.created_at).toLocaleString(
        "en-US",
        { timeZone: "Africa/Cairo" }
      )
    : "not visited";
  const wktString = requestData?.location;
  const coordFinder = /\(\s?(\S+)\s+(\S+)\s?\)/g;
  const allMatches = coordFinder.exec(wktString);
  const geolon = parseFloat(allMatches[1]);
  const geolat = parseFloat(allMatches[2]);
  // console.log("ticketData___ticketData",ticketData)
  const ComponentREf = useRef();

  const handle_PRint = useReactToPrint({
    content: () => ComponentREf.current,
    documentTitle: "emp-data",
    onAfterPrint: () => console.log("print success"),
  });
  const OpenSound = () => {
    setOpenAudio(true);
    //  var sound = new Howl({
    //   src: [` https://staging.too-app.com:9250/storage/uploads/${getTicketDetails_Actions[0]?.action?.voice[0]}`],
    //   html5: true,
    //   preload:true
    // });
    // sound.play();
  };
  const closeAudioModal = () => {
    setOpenAudio(false);
  };
  // useEffect(() => {
  //   if (pointData?.length > 0) {
  //     const filteredAction = pointData?.filter(
  //       (act) => act.point.id === pointId
  //     );
  //     setAction(filteredAction);
  //   }
  //   return () => setAction(undefined);
  // }, [pointId, pointData]);
  // const [orderData, setOrderData] = useState()

  // console.log("requestData from ticket",requestData)
  return (
    <>
      <div style={{ borderBottom: "3px solid rgb(0, 128, 249)" }}>
        <h3
          style={{
            fontFamily: "Tajawal",
            fontStyle: "normal",
            fontWeight: "900",
            fontSize: "20px",
            lineHeight: "16px",
            // textDecoration: "underline",
            // textAlign:"center"
          }}
        >
          {local.Ticket_name} # {requestData && requestData?.id}
        </h3>
        {/* <span>{ticketData?.agentName}</span> */}
      </div>

      <div
        ref={ComponentREf}
        class="flex gap-4 "
        style={{
          height: "92%",
          marginTop: "25px",
          overflow: "auto",
          width: "76rem",
        }}
      >
        <div
          id="Sub_div1"
          style={{
            borderColor: "#0080F9",
            minWidth: "50%",
            height: "100%",
            overflowY: "scroll",
          }}
          class="grid grid-cols-1 md:grid-cols-2 gap-4 
     md:px-4  ltr:border-r-2 rtl:border-l-2 "
        >
          <div className="space-y-3" style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "7px",
              boxShadow: "3px 3px 3px grey",
            }}>
            <span style={{ color: "#6B778C", alignSelf: "center" }}>
              {local.Requester_info}
            </span>

            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_name}:-
              </span>
              {ticketData && ticketData?.properties?.requester_name}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_Phone}:-
              </span>{" "}
              {requestData && requestData?.user?.mobile}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.Email}:-</span>{" "}
              {requestData && requestData?.properties?.requester_email}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_District}:-
              </span>{" "}
              {requestData && requestData?.work_field.name[getLanguage()]}
            </div>
          </div><div className="space-y-3" style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "7px",
              boxShadow: "3px 3px 3px grey",
            }}
          >
            <span style={{ color: "#6B778C", alignSelf: "center" }}>
              {local.agent_info}
            </span>

            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_name}:-
              </span>{" "}
              {requestData && requestData?.worker.name}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_Email}:-
              </span>{" "}
              {requestData && requestData?.worker.email}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_Phone}:-
              </span>{" "}
              {requestData && requestData?.worker.mobile}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_City}:-
              </span>{" "}
              {requestData && requestData?.worker.address}
            </div>
          </div>

          <div
            className="space-y-3"
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "7px",
              boxShadow: "3px 3px 3px grey",
            }}
          >
            <span style={{ color: "#6B778C", alignSelf: "center" }}>
              {local.Merchant}
            </span>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_name}:-
              </span>{" "}
              {ticketData?.name}
            </div>

            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.UserName}:-</span>{" "}
              {ticketData?.merchant_username}
            </div>
            {/* Phone */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_Phone}:-
              </span>{" "}
              {requestData?.properties?.mobile ??
                ticketData?.properties?.mobile}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.TicketInfo_Id}:-</span>{" "}
              {ticketData?.merchant_id}
            </div>
            {/* Terminal id */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.TerminalId}:-</span>{" "}
              {/* {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.terminal_id} */}
              {/*{requestData && requestData?.terminal_id}*/}
                {getTicketDetails_Actions &&
                getTicketDetails_Actions[0]?.action.terminal_id?getTicketDetails_Actions[0]?.action.terminal_id:
                    requestData && requestData?.terminal_id
                }
            </div>
            {/* Mcc */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{"mcc"}:-</span>{" "}
              {placeTypeOptions[ticketData?.place_type].label}
            </div>
          </div>

          <div
            className="space-y-3"
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "7px",
              boxShadow: "3px 3px 3px grey",
            }}
          >
            <span style={{ color: "#6B778C", alignSelf: "center" }}>
              {local.service_Location}
            </span>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.StateWorkFields}:-
              </span>{" "}
              {/* {getTicketDetails_Actions&& getTicketDetails_Actions[0]?.worker.address} */}
              {requestData &&
                requestData?.work_field.branch.name[getLanguage()]}
            </div>
            {/* Cities */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.WorkFields}:-</span>{" "}
              {requestData && requestData?.work_field.name[getLanguage()]}
            </div>
            {/* Merchant add */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                //  here
                // height: "20%",
                  wordWrap:" break-word"
              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.MerchantAddress}:-
              </span>
              {requestData?.properties?.address}
            </div>
            {/* land mark */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                  wordWrap:" break-word"              }}
            >
              <span style={{ color: "#8f8b8b" }}>
                {local.TicketInfo_Content}:-
              </span>{" "}
              {requestData?.properties?.landmark}
            </div>
            {/* Gps link */}
            {/* land mark */}
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <a
                target="_blank"
                href={`https://maps.google.com/?q=${geolat + "," + geolon}`}
                style={{ color: "#8f8b8b" }}
              >
                {"Gps link"}:- Click here
              </a>
            </div>
          </div>

          <div
            className="space-y-3"
            style={{
              display: "flex",
              flexDirection: "column",
              border: "1px solid gray",
              borderRadius: "15px",
              padding: "7px",
              boxShadow: "3px 3px 3px grey",
              marginBottom: "10px",
            }}
          >
            <span style={{ color: "#6B778C", alignSelf: "center" }}>
              {local.History_Dates}
            </span>
            {/* <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>
              {"POS Serial"}:-
            </span>{" "}
              {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.machine_serial}
          </div>
           <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>
              {"Imei"}:-
            </span>{" "}
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.imei_number}
          </div>
           <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>
              {"Sim Serial"}:-
            </span>
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.serial_theme}
          </div>
           <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>{`${local.TicketInfo_Id}`}:-</span>{" "}
            {ticketData?.merchant_id}
          </div>
          
          <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>{local.TerminalId}:-</span>{" "}
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.terminal_id}
          </div>


          <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>{local.Ticket_Notes}:-</span>{" "}
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.comment}
          </div> */}

            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.CreatedAt}:-</span>{" "}
              {created_at}
            </div>

            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.Updatedat}:-</span>{" "}
              {updated_at}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.assign_At}:-</span>{" "}
              {assign_at}
            </div>
            <div
              style={{
                backgroundColor: "#EEEEEE",

                padding: "9px",
                height: "20%",
              }}
            >
              <span style={{ color: "#8f8b8b" }}>{local.visit_At}:-</span>{" "}
              {visit_at}
            </div>
          </div>

          {requestData.reassign ? (
            <div
              className="space-y-3"
              style={{
                display: "flex",
                flexDirection: "column",
                border: "1px solid gray",
                borderRadius: "15px",
                padding: "7px",
                boxShadow: "3px 3px 3px grey",
                marginBottom: "10px",
                height: "109%",
                overflowY: "auto",
              }}
            >
              <span style={{ color: "#6B778C", alignSelf: "center" }}>
                {local.Reassign}
              </span>
              {requestData?.reassign?.map((dta, index) => (
                <div
                  style={{
                    height: "90%",
                    border: "1px solid",
                    borderRadius: "7px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "#EEEEEE",

                      padding: "9px",
                      height: "38%",
                    }}
                  >
                    <span style={{ color: "#8f8b8b" }}>{local.Comment}:-</span>{" "}
                    {dta.comment}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#EEEEEE",

                      padding: "9px",
                      height: "38%",
                    }}
                  >
                    <span style={{ color: "#8f8b8b" }}>
                      {local.reassign_requester_name}:-
                    </span>{" "}
                    {dta?.visit?.requester_name}
                  </div>
                  <div
                    style={{
                      backgroundColor: "#EEEEEE",

                      padding: "9px",
                      height: "38%",
                    }}
                  >
                    <span style={{ color: "#8f8b8b" }}>
                      {local.reassign_worker_name}:-
                    </span>{" "}
                    {dta?.visit?.worker_name}
                  </div>


                    <div
                        style={{
                            backgroundColor: "#EEEEEE",

                            padding: "9px",
                            height: "38%",
                        }}
                    >
                    <span style={{ color: "#8f8b8b" }}>
                      {local.Visit_date_dAt}:-
                    </span>{" "}
                        {ToEditDate(dta.visit.visit_date)}
                    </div>

                  <div
                    style={{
                      backgroundColor: "#EEEEEE",

                      padding: "9px",
                      height: "38%",
                    }}
                  >
                    <span style={{ color: "#8f8b8b" }}>
                      {local.CreatedAt}:-
                    </span>{" "}
                    {ToEditDate(dta.created_at)}
                  </div>
                </div>
              ))}

              {/* <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>
              {"POS Serial"}:-
            </span>{" "}
              {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.machine_serial}
          </div>
           <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>
              {"Imei"}:-
            </span>{" "}
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.imei_number}
          </div>
           <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>
              {"Sim Serial"}:-
            </span>
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.serial_theme}
          </div>
           <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>{`${local.TicketInfo_Id}`}:-</span>{" "}
            {ticketData?.merchant_id}
          </div>
          
          <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>{local.TerminalId}:-</span>{" "}
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.terminal_id}
          </div>


          <div
            style={{
              backgroundColor: "#EEEEEE",
              
              padding: "9px",
              height: "20%",
            }}
          >
            <span style={{ color: "#8f8b8b" }}>{local.Ticket_Notes}:-</span>{" "}
            {getTicketDetails_Actions && getTicketDetails_Actions[0]?.action.comment}
          </div> */}

              {/* <div
                style={{
                  backgroundColor: "#EEEEEE",

                  padding: "9px",
                  height: "20%",
                }}
              >
                <span style={{ color: "#8f8b8b" }}>{local.assign_at}:-</span>{" "}
                {assign_at}
              </div>
              <div
                style={{
                  backgroundColor: "#EEEEEE",

                  padding: "9px",
                  height: "20%",
                }}
              >
                <span style={{ color: "#8f8b8b" }}>{local.visit_at}:-</span>{" "}
                {visit_at}
              </div> */}
            </div>
          ) : null}



            {requestData?.properties?.comment?
            <div className="space-y-3"
                 style={{
                     display: "flex",
                     flexDirection: "column",
                     border: "1px solid gray",
                     borderRadius: "15px",
                     padding: "7px",
                     boxShadow: "3px 3px 3px grey",
                 }}
            >
            <span style={{ color: "#6B778C", alignSelf: "center" }}>
              {local.Comments_s}
            </span>


                 <textarea readOnly={true} style={{
                     border:"1px solid black",
                     height: "92px",
                     borderRadius: "2px"


                 }}>
                                          {requestData && requestData?.properties?.comment}
                     {/*{dta?.visit?.requester_name}*/}

                 </textarea>





            </div>:null}
        </div>

        <div id="Sub_div2" className="space-y-3 w-full">
          <div
            class="grid grid-cols-1 md:grid-cols-2 gap-4 justify-center content-center "
            style={{
              borderRadius: "12px",
              height: "50%",

              backgroundColor: "rgba(0, 128, 249, 0.05)",
              overflowY: "scroll",
              display: "flex",
              justifyContent: "space-evenly",
              alignItems: "center",
            }}
            id="MainBackGround"
          >
            {getTicketDetails_Actions &&
            getTicketDetails_Actions[0]?.action.image ? (
              getTicketDetails_Actions[0]?.action.image.map((ele, index) => (
                <div style={{ height: "60%", width: "87px" }}>
                  <img
                    style={{
                      border: "4px solid gray",
                      borderRadius: "6px",
                      cursor: "pointer",
                      boxShadow: "6px 6px 6px grey",
                    }}
                    onClick={() =>
                      setSingleImage(
                        `${config.img_url}${ele}`
                      )
                    }
                    className="w-full h-full object-cover"
                    src={`${config.img_url}${ele}`}
                  />
                </div>
              ))
            ) : (
              <img src={Camer_View} />
            )}
          </div>
          <div>
            <div
              className="py-2 mt-5"
              style={{
                display: "flex",
                justifyContent: "space-evenly",
                margin: "auto",
                marginTop: "20px",
                width: "60%",
                background: "rgba(0, 82, 204, 0.05)",
                padding: "0rem",
                  position: "relative",
              }}
              id="Nav_Div"
            >
              <div
                class="grid justify-center content-center justify-items-center"
                style={{ cursor: "pointer" }}
                onClick={OpenSound}
              >
                {" "}
                  <span style={{
                      top: "-5px",
                      // right: "64px",
                      width: "23px",
                      border: "1px solid white",
                      color: "white",
                      backgroundColor: "red",
                      borderRadius: "50%",
                      textAlign: "center",
                      position: "absolute",
                  }}>{getTicketDetails_Actions[0]?.action?.voice?getTicketDetails_Actions[0]?.action?.voice.length:null}</span>
                <img src={MicroPhone} />
                <span>{local.Ticket_listening}</span>
              </div>
              <div class="grid justify-center content-center justify-items-center">
                <img src={File} />
                <span>{local.Ticket_show_File} </span>
              </div>
              <div class="grid justify-center content-center justify-items-center">
                {" "}
                <img src={Camera} />
                <span> {local.Ticket_show_Image} </span>
              </div>
            </div>
            <div
              id="Content"
              style={{
                width: "60%",
                margin: "auto",
                marginTop: "20px",
                paddingBottom: "1rem",
              }}
              class="grid grid-cols-1 md:grid-cols-2 gap-4 "
            >
              <div
                class="grid justify-center content-center py-2"
                style={{
                  backgroundColor: "rgba(0, 82, 204, 0.05)",
                  border: "none",
                }}
              >
                {" "}
                {local.Ticket_Machine_Serial}
              </div>
              <div
                className="center"
                style={{
                  backgroundColor: "#EEEEEE",
                  border: "none",
                  padding: "20px",
                  paddingInline: "30px",
                }}
              >
                {getTicketDetails_Actions &&
                  getTicketDetails_Actions[0]?.action.machine_serial}
              </div>
              <div
                class="grid justify-center content-center py-2"
                style={{
                  backgroundColor: "rgba(0, 82, 204, 0.05)",
                  border: "none",
                }}
              >
                {local.Ticket_Email}
              </div>
              <div
                className="center"
                style={{
                  backgroundColor: "#EEEEEE",
                  border: "none",
                  padding: "20px",
                  paddingInline: "30px",
                }}
              >
                {getTicketDetails_Actions &&
                  getTicketDetails_Actions[0]?.action.imei_number}
              </div>
              <div
                class="grid justify-center content-center py-2"
                style={{
                  backgroundColor: "rgba(0, 82, 204, 0.05)",
                  border: "none",
                }}
              >
                {local.Ticket_Sim_SerialEmail}
              </div>
              <div
                className="center"
                style={{
                  backgroundColor: "#EEEEEE",
                  border: "none",
                  padding: "20px",
                  paddingInline: "30px",
                }}
              >
                {getTicketDetails_Actions &&
                  getTicketDetails_Actions[0]?.action.serial_theme}
              </div>
              <div
                class="grid justify-center content-center py-2"
                style={{
                  backgroundColor: "rgba(0, 82, 204, 0.05)",
                  border: "none",
                }}
              >
                {local.Ticket_Merchant_number}
              </div>
              <div
                className="center"
                style={{
                  backgroundColor: "#EEEEEE",
                  border: "none",
                  padding: "20px",
                  paddingInline: "30px",
                }}
              >
                {/* {getTicketDetails_Actions ? getTicketDetails_Actions[0]?.action.trader_number:ticketData?.merchant_id} */}
                {ticketData?.merchant_id}
              </div>
              <div
                class="grid justify-center content-center py-2"
                style={{
                  backgroundColor: "rgba(0, 82, 204, 0.05)",
                  border: "none",
                }}
              >
                {local.TerminalId}
              </div>
              <div
                className="center"
                style={{
                  backgroundColor: "#EEEEEE",
                  border: "none",
                  padding: "20px",
                  paddingInline: "30px",
                }}
              >
                {getTicketDetails_Actions &&
                  getTicketDetails_Actions[0]?.action.terminal_id?getTicketDetails_Actions[0]?.action.terminal_id:
                    requestData && requestData?.terminal_id
                }
              </div>
              <div
                class="grid justify-center content-center py-2"
                style={{
                  backgroundColor: "rgba(0, 82, 204, 0.05)",
                  border: "none",
                }}
              >
                {local.Ticket_Notes}
              </div>

              <div
                className="center text-center"
                style={{
                  backgroundColor: "#EEEEEE",
                  border: "none",
                  padding: "20px",
                  paddingInline: "30px",
                    wordBreak: "break-word",


                }}
              >
                {getTicketDetails_Actions &&
                  getTicketDetails_Actions[0]?.action.comment}
              </div>
            </div>
          </div>
        </div>

        {/* <div className="flex px-20 py-4">
      <div className="flex gap-x-2 items-center">
        <p className="text-gray-600 font-semibold">Ticket</p>
        <p className="text-xs text-white bg-green-600 rounded-md px-2">
          ASSIGNED
        </p>
      </div>
      <div className="w-2"></div>
    </div>
    <div className="bg-mainLight py-4">
      <div className="lg:w-4/5 mx-auto flex gap-8 justify-center flex-wrap w-full">
        <TicketInfo title="Ticket ID" data="1568" />
        <TicketInfo title="Ticket ID" data="1568" />
        <TicketInfo title="Ticket ID" data="1568" />
        <TicketInfo title="Ticket ID" data="1568" />
        <TicketInfo title="Ticket ID" data="1568" />
      </div>
    </div>
    <div className="container">
      <div>
        
      </div>
    </div> */}

        {/* <button style={{
      backgroundColor: "#0091da",
      width: "136px",
      height: "50px",
      border: "1px solid black",
      borderRadius: "9px",
      boxShadow: "7px 7px 7px grey",
      color: "white",
  
    }}>Print</button> */}
        {/* /Audio <audio controls>
<source src={`https://staging.too-app.com:9250/storage/uploads/${getTicketDetails_Actions[0]?.action?.voice[0]}`} type="audio/ogg"></source>
<source></source>/ */}
        <Modal
          visible={singleImage.length > 0}
          footer={null}
          onCancel={() => setSingleImage("")}
        >
          <img
            alt="image"
            style={{ width: "100%", height: 350 }}
            src={singleImage}
          />
        </Modal>
        {/* /Audio/ */}
        <Modal
          title={" "}
          visible={OpenAudio}
          footer={null}
          onCancel={() => setOpenAudio(false)}
          style={{ backgroundColor: "rgba(0, 128, 249, 0.05)" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "rgba(0, 128, 249, 0.05)",
              border: "1px solid black",
              borderRadius: "5px ",
            }}
          >
            {getTicketDetails_Actions[0]?.action?.voice?.map((ele, index) => {
              return (
                <audio style={{ padding: "1rem" }} controls>
                  <source
                    src={`${config.img_url}${ele}`}
                    type="audio/ogg"
                  ></source>
                </audio>
              );
            })}
          </div>
        </Modal>
      </div>
      <button
        style={{
          backgroundColor: "#0091da",
          width: "136px",
          height: "50px",
          border: "1px solid black",
          borderRadius: "9px",
          boxShadow: "7px 7px 7px grey",
          color: "white",
        }}
        onClick={handle_PRint}
      >
        Print this out!
      </button>
    </>
  );
};
const mapStateToProps = (state) => {
  return {
    Ticket_Details: state.TicketR.Ticket_details,
    Ticket_Details_Actions: state.TicketR.Actions_Ticket_details,
  };
};

//  const mapDispatchToProps=dispatch=>{
//   return{
//       getTicketDetails:(number)=>dispatch(UPDATE_Ticket_Details(number)),

//   }
// }
export default connect(mapStateToProps, null)(Ticket);
