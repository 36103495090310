import { Request } from "../Api/index";
import { UPDATE_LIVE_TRACKING_PROPS } from "./types";
import toaster from "toasted-notes";
import { local } from "../../lang/local";
import {
  confirmSunccessed,
  getCenterOfPolygon,
  getMyCompany,
  isArray,
  rmUnsetValsAndGetQryStr,
  setterGenerator,
} from "../../utils/GeneralFunctions";
import { getAllPoints } from "./PointsActions";
import { general } from "./GeneralAction";

export const setLiveTrackingProps = (propsArr) =>
  setterGenerator(UPDATE_LIVE_TRACKING_PROPS, propsArr);

export const clearTrackingSearchForm = () => (dispatch) => {
  const initalSearchForm = {
    page: 1,
    // company: null,
    company: getMyCompany(() => dispatch(general)),
    branch_id: null,
    work_field: null,
    worker_id: null,
  };
  dispatch(setLiveTrackingProps([["searchForm", initalSearchForm]]));
};

export const getAllWorkers =
  // (company_id, workField, page = 1) =>


    (searchForm, workField, page = 1) =>
    (dispatch) => {
      const newSearchForm = {
        page: page,
        workers_ids: isArray(searchForm.worker_id)
          ? searchForm.worker_id.map((worker) => worker.value)
          : null,
      };

      const searchFormParams = rmUnsetValsAndGetQryStr(newSearchForm);
      let workers = [];
      dispatch({
        type: UPDATE_LIVE_TRACKING_PROPS,
        payload: [
          { prop: "isLoadingSearch", value: true },
          // { prop: "trackingObj.trackingItem", value: null },
          // { prop: "trackingObj.trackingWorkers", value: null },
          // { prop: "trackingObj.outerWorkers", value: null },
          // { prop: "trackingObj.totalWorkers", value: null },
          {
            prop: "trackingObj",
            value: {
              trackingItem: null,
              workersResponse: null,
              isLoading: false,
              lat: null,
              lng: null,
              trackingWorkers: null,
              outerWorkers: null,
              isOuterWorkerSearch: false,
              totalWorkers: null,
              polygon: null,
            },
          },
        ],
      });
      dispatch(getTotalItems(searchForm.company, workField.value));

      Request()
        // .get("work_fields/" + workField.value + `/workers?page=${page}`)
        .get("work_fields/" + workField.value + `/workers${searchFormParams}`)
        .then(async (res) => {
          if (res.data.status === true) {
            console.log("tracking response ", res.data);
            dispatch({
              type: UPDATE_LIVE_TRACKING_PROPS,
              payload: [
                {
                  prop: "trackingObj.trackingItem",
                  value: [workField],
                },
                {
                  prop: "trackingObj.workersResponse",
                  value: res.data.data,
                },
              ],
            });
            if (res.data.data !== null) {
              if (res.data.data.data.length > 0) {
                workField.name = workField.label;
                workField.id = workField.value;
                workField.points = [];

                res.data.data.data.map((d) => {
                  d.new_location = null;
                  workers.push(d);
                });

                let center = getCenterOfPolygon(workField.location);

                dispatch({
                  type: UPDATE_LIVE_TRACKING_PROPS,
                  payload: [
                    { prop: "trackingObj.lat", value: center.latitude },
                    { prop: "trackingObj.lng", value: center.longitude },
                  ],
                });

                dispatch(
                  getAllPoints(
                    workField.value,
                    "trackingObj.trackingItem." + 0 + ".points",
                    UPDATE_LIVE_TRACKING_PROPS
                  )
                );
              }
              if (res.data.data.data.length === 0) {
                confirmSunccessed(local.NoSearch);
              }

              let pages = [];

              for (
                let count = res.data.data.pagination.current_page + 1;
                count <= res.data.data.pagination.total_pages;
                count++
              ) {
                pages.push(count);
              }

              if (pages.length > 0) {
                pages.map((page, index) => {
                  Request()
                    .get(
                      "histories/active/" + workField.value + "?page=" + page
                    )

                    .then((res) => {
                      if (res.data.status === true) {
                        if (res.data.data !== null) {
                          if (res.data.data.data.length > 0) {
                            res.data.data.data.map((d) => {
                              d.new_location = null;
                              workers.push(d);
                            });
                          }
                        }
                      }
                      dispatch({
                        type: UPDATE_LIVE_TRACKING_PROPS,
                        payload: [{ prop: "isLoadingSearch", value: false }],
                      });
                      if (workers.length > 0 && index === pages.length - 1) {
                        dispatch({
                          type: UPDATE_LIVE_TRACKING_PROPS,
                          payload: [
                            {
                              prop: "trackingObj.trackingWorkers",
                              value: workers,
                            },
                          ],
                        });
                      }
                    })
                    .catch((err) => {
                      dispatch({
                        type: UPDATE_LIVE_TRACKING_PROPS,
                        payload: [{ prop: "isLoadingSearch", value: false }],
                      });
                      console.log(err);
                    })
                    .finally();
                });
              } else {
                if (workers.length > 0) {
                  dispatch({
                    type: UPDATE_LIVE_TRACKING_PROPS,
                    payload: [
                      {
                        prop: "trackingObj.trackingWorkers",
                        value: workers,
                      },
                    ],
                  });
                }
              }
            }
          } else {
            toaster.notify(local.NoSearch, {
              duration: 2000,
            });
          }
          dispatch({
            type: UPDATE_LIVE_TRACKING_PROPS,
            payload: [{ prop: "isLoadingSearch", value: false }],
          });
        })
        .catch((err) => {
          dispatch({
            type: UPDATE_LIVE_TRACKING_PROPS,
            payload: [{ prop: "isLoadingSearch", value: false }],
          });
          console.log(err);
        })
        .finally();
    };

export const getTotalItems = (companyID, work_field_id) => (dispatch) => {
  Request()
    .get("/workers/" + companyID + "/" + work_field_id + "/count")
    .then((res) => {
      if (res.data.status === true) {
        if (res.data.data !== null) {
          if (res.data.data.total !== undefined) {
            dispatch({
              type: UPDATE_LIVE_TRACKING_PROPS,
              payload: [
                {
                  prop: "trackingObj.totalWorkers",
                  value: res.data.data.total,
                },
              ],
            });
          }
        }
      }
    })
    .catch((e) => {
      console.log(e);
    })
    .finally();
};
