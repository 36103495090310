import React from "react";
import { UPDATE_WORKER_PROPS } from "../../redux/actions/types";
import {
  renderGeneralInput,
  renderPasswordInput,
  renderSingleSelect,
} from "../../utils/GeneralFunctions";
import { Col, Row, CardBody } from "reactstrap";
import { local } from "../../lang/local";
const SecondTab = (props) => {
  const { general, workerForm, workerTypeOption } = props;

  return (
    <CardBody className="trans">
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderSingleSelect(
            "workerForm.formvalues.worker_type",
            workerForm.formvalues.worker_type,
            UPDATE_WORKER_PROPS,
            general,
            local.WorkerType,
            workerTypeOption
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderGeneralInput(
            "workerForm.formvalues.user_name",
            workerForm.formvalues.user_name,
            UPDATE_WORKER_PROPS,
            general,
            local.UserName
          )}
        </Col>
      </Row>

      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderPasswordInput(
            "workerForm.formvalues.password",
            workerForm.formvalues.password,
            UPDATE_WORKER_PROPS,
            general,
            local.Password
          )}
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xl={12} lg={12} md={12} sm={12} xs={12}>
          {renderPasswordInput(
            "workerForm.formvalues.password_confirmation",
            workerForm.formvalues.password_confirmation,
            UPDATE_WORKER_PROPS,
            general,
            local.ConfirmPassword
          )}
        </Col>
      </Row>
    </CardBody>
  );
};

export default SecondTab;
