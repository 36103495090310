import { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Input } from "reactstrap";
import { general } from "../../redux/actions";
import { labelStyle } from "../../utils/GeneralStyle";
import { useEffect } from "react";

const GeneralInputComponent = ({
  prop,
  action,
  value,
  title,
  hasLabel = true,
  className = "mt-4",
  type = "text",
  general,
}) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    if (value !== inputValue) {
      setInputValue(value);
    }
  }, [inputValue, value]);

  return (
    <>
      {hasLabel && (
        <span className="label_title" style={labelStyle}>
          {title}
        </span>
      )}

      <Input
        type={type}
        placeholder={title}
        className={`inputStyle ${className}`}
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
        onBlur={() => {
          general(
            [
              {
                prop: prop,
                value: inputValue,
              },
            ],
            action
          );
        }}
      />
    </>
  );
};

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      general,
    },
    dispatch
  );
};

export default connect(null, mapDispatchToProps)(GeneralInputComponent);
