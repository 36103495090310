import React from 'react';
import { Container, Table } from 'reactstrap';
import {
  rednerImageInTable,
  renderDate,
  renderTime,
} from '../../utils/GeneralFunctions';
import ModalView from '../../utils/ModalView';
import { local } from '../../lang/local';
import { UPDATE_ACTION_POINT_PROPS } from '../../redux/actions/types';
const ActionPointProfile = ({
  modaleView,
  general,
  giftsReportData,
  AmountR,
}) => {
  const renderArrayName = (array) => {
    if (array !== undefined) {
      if (array !== null) {
        return array.map((arr, index) => {
          if (index === 0 || arr.length - 1 === index) {
            return arr.name;
          } else {
          }
          return ' | ' + arr.name + ' | ';
        });
      }
    }
  };
  const renderGiftsAndSamples = (giftsArr) => {
    let renderGiftsArr = [];
    for (let i = 0; i < giftsArr.length; i++) {
      if (giftsArr[i].amount !== 0) {
        const giftType =
          giftsArr[i].gift.type === 0 ? local.Gift : local.Sample;
        renderGiftsArr.push(
          <div>
            {giftsArr[i].gift.name} ({giftsArr[i].amount}) - {giftType}
          </div>
        );
      }
    }
    return renderGiftsArr;
  };

  const renderProductsAmount = (products) => {
    if (products?.length > 0) {
      return products.map((product) => {
        return (
          <div>
            {product.product.name} ({product.amount})
          </div>
        );
      });
    }
  };

  const renderChildren = () => {
    if (modaleView.data !== null) {
      return (
        <Container>
          <Table
            responsive
            className='table_list mt-4 mb-4'
            style={{
              borderTopColor: 'white',
              border: 'none',
              position: 'relative',
            }}
          >
            <tbody>
              <tr>
                <td className='green_text'>{local.Id}</td>
                <td>{modaleView.data.id}</td>
              </tr>
              <tr>
                <td className='green_text'>{local.Worker}</td>
                <td>
                  <Table
                    responsive
                    className='table_list mt-4 mb-4'
                    style={{
                      borderTopColor: 'white',
                      border: 'none',
                      position: 'relative',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>{local.Image}</th>
                        <th>{local.Name}</th>
                        <th>{local.Address}</th>
                        <th>{local.Mobile}</th>
                        <th>{local.JobTitle}</th>
                        <th>{local.Identifier}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          {rednerImageInTable(
                            modaleView.data.worker.image,
                            UPDATE_ACTION_POINT_PROPS,
                            general
                          )}
                        </th>
                        <th>{modaleView.data.worker.name}</th>
                        <th>{modaleView.data.worker.address}</th>
                        <th>{modaleView.data.worker.mobile}</th>
                        <th>{modaleView.data.worker.job_title}</th>
                        <th>{modaleView.data.worker.ssn}</th>
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>

              <tr>
                <td className='green_text'>{local.PlaceDetails}</td>
                <td>
                  <Table
                    responsive
                    className='table_list mt-4 mb-4'
                    style={{
                      borderTopColor: 'white',
                      border: 'none',
                      position: 'relative',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>{local.Name}</th>
                        <th>{local.Type}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>{modaleView.data.point.name}</th>
                        
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>

              <tr>
                <td className='green_text'>{local.VisitDetails}</td>
                <td>
                  <Table
                    responsive
                    className='table_list mt-4 mb-4'
                    style={{
                      borderTopColor: 'white',
                      border: 'none',
                      position: 'relative',
                    }}
                  >
                    <thead>
                      <tr>
                        <th>{local.VisitStatus}</th>
                        <th>{local.Products}</th>
                        <th>{local.Notes}</th>
                        <th>{local.Gifts_And_Samples}</th>
                        <th>{local.VisitDateTime}</th>
                        {modaleView.data.point.place_type === 4 && (
                          <th>{local.ProductAmount}</th>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>
                          {modaleView.data.action.point === true ||
                          modaleView.data.action.point === 'true'
                            ? local.Succeeded
                            : local.Failed}
                        </th>

                        <th>
                          {renderArrayName(modaleView.data.action.products)}
                        </th>
                        <th>{modaleView.data.action.data}</th>
                        <th>
                          {giftsReportData &&
                            renderGiftsAndSamples(giftsReportData.data)}
                        </th>
                        <th>
                          {/* {getLanguage() === "en"
                            ? new Date(
                                modaleView.data.action.arriveTime
                              ).toLocaleString()
                            : new Date(
                                modaleView.data.action.arriveTime
                              ).toLocaleString("ar")} */}
                          {`
                          ${renderDate(
                            `${modaleView.data.action.arriveTime} UTC`
                          )} / 
                          ${renderTime(
                            `${modaleView.data.action.arriveTime} UTC`
                          )}`}
                        </th>
                        {modaleView.data.point.place_type === 4 && (
                          <th>
                            {renderProductsAmount(
                              AmountR.amountObj.amountItem?.data
                            )}
                          </th>
                        )}
                      </tr>
                    </tbody>
                  </Table>
                </td>
              </tr>
            </tbody>
          </Table>
        </Container>
      );
    }
  };
  return (
    <ModalView
      children={renderChildren}
      modaleView={modaleView}
      general={general}
      action={UPDATE_ACTION_POINT_PROPS}
    />
  );
};

export default ActionPointProfile;
