import React from 'react';
import { Card, Col, Row } from 'reactstrap';

import { getLanguage, local } from '../../lang/local';
import { UPDATE_ARCHIVES_PROPS } from '../../redux/actions/types';
const ArchiveCard = (props) => {
  const { arch_item, general } = props;

  return (
    <Col xl={3} lg={3} md={6} xs={12} sm={12} className='mt-4'>
      <Card className='archive_card'>
        <Row>
          <Col>
            <a
              style={{ cursor: 'pointer' }}
              href
              onClick={() =>
                general(
                  [
                    { prop: 'lightbox.isOpen', value: true },
                    { prop: 'lightbox.image', value: arch_item.image },
                  ],
                  UPDATE_ARCHIVES_PROPS
                )
              }
            >
              <img
                src={arch_item.image}
                alt=''
                style={{
                  width: '100%',
                  borderTopRightRadius: '10px',
                  borderTopLeftRadius: '10px',
                  height: '20em',
                }}
              />
            </a>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='text-center'>
            <p className='report_text_label blue_text green_text'>
              {arch_item.data}
            </p>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='text-center'>
            <p className='report_text_label green_text '>
              {arch_item.worker.job_title}
            </p>
          </Col>
          <Col className='text-center'>
            <p className='report_text_label blue_text'>
              {arch_item.worker.name}
            </p>
          </Col>
        </Row>
        <Row className='mt-4'>
          <Col className='text-center'>
            <p className='report_text_label green_text'>{local.date}</p>
          </Col>
          <Col className='text-center'>
            <p className='report_text_label blue_text green_text'>
              {getLanguage() === 'en'
                ? new Date(arch_item.created_at).toLocaleString('en')
                : new Date(arch_item.created_at).toLocaleString('ar')}
            </p>
          </Col>
        </Row>

        <Row className='mt-4 mb-4'>
          {/* <Col className="text-center">
            <p className="report_text_label green_text">{local.Audio}</p>
          </Col> */}
          <Col className='text-center mt-3'>
            <audio
              controls
              paused={false}
              onPlay={(e) => {
                let allAudioElements = document.getElementsByTagName('audio');
                for (let i = 0; i < allAudioElements.length; i++) {
                  if (allAudioElements[i].currentSrc !== e.target.currentSrc) {
                    allAudioElements[i].pause();
                  }
                }
              }}
            >
              <source src={arch_item.audio} type='audio/ogg' />
              <source src={arch_item.audio} type='audio/mpeg' />
              Your browser does not support the audio element.
            </audio>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

export default ArchiveCard;
