import React from "react";
import { Row } from "reactstrap";
import { renderPagination } from "../../utils/GeneralFunctions";
import ReportCard from "./ReportCard";

const ReportsView = (props) => {
  const {
    reportObj,
    general,
    lightbox,
    searchForm,
    getReports,
    companyWfOptions,
    history,
  } = props;

  const renderReports = () => {
    if (reportObj.reportItem !== null) {
      return reportObj.reportItem.data.map((repo_item, index) => {
        return (
          <ReportCard
            repo_item={repo_item}
            key={index}
            lightbox={lightbox}
            general={general}
            searchForm={searchForm}
            companyWfOptions={companyWfOptions}
            history={history}
            report_index={index}
          />
        );
      });
    }
  };
  const renderPage = () => {
    return (
      <>
        <Row className="mt-2">{renderReports()}</Row>

        {renderPagination(reportObj.reportItem, searchForm, getReports)}
      </>
    );
  };

  return <>{renderPage()}</>;
};

export default ReportsView;
