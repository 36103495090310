import { makeStyles, createStyles } from "@material-ui/core/styles";
import Pagination from "@material-ui/lab/Pagination";

const PaginationComponent = ({ onPageChange, count, currentPage = 1 }) => {
  const useStyles = makeStyles((theme) =>
    createStyles({
      root: {
        "& .MuiPaginationItem-page": {
          backgroundColor: "#0091da",
          color: "white",
        },
        "& .Mui-selected": {
          backgroundColor: "white",
          color: "#0091da",
        },
      },
    })
  );

  const classes = useStyles();

  return (
    <Pagination
      className={classes.root}
      count={count}
      variant="outlined"
      color="standard"
      showFirstButton
      showLastButton
      onChange={(e, page) => {
        onPageChange(page);
      }}
      page={currentPage}
      // defaultPage={1}
    />
  );
};

export default PaginationComponent;
