import React from "react";
const HomeCard2 = ({ title, count, photo, history, route_name }) => {
  const absoluteCenterStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    textAlign: "center",
  };
  return (
    <>
      <div
        className="home_card2Container m-3"
        onClick={(e) => history.push(route_name)}
      >
        <div className="home_card2">
          <div className="counter_circle">
            {/* <img src={COUNT_BACKGROUND} width={46} /> */}
            <div style={absoluteCenterStyle}>{count}</div>
          </div>
          <div style={absoluteCenterStyle}>
            {/* <img src={photo} width={66} /> */}
            <img src={photo} className="cardIcon" />
          </div>
          <div
            style={{
              fontSize: "15px",
              position: "absolute",
              top: "100px",
              left: "50%",
              transform: "translateX(-50%)",
              textAlign: "center",
              width: "fit-content",
              lineHeight: 1
            }}
          >
            {title}
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeCard2;
